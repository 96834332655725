import { message } from "antd";
import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";
import actions from "./actions";

const {
  getDriverList,
  getDriverDataById,
  getDriverRequestsData,
  getDriverDocumentsData,
  getCaptainDriverList,
  getDriverIdCardDataById,
  getDriverWallet,
  getTransactionList,
  getDriverWalletChart,
  getRideRequests,
  getRatingWiseRide,
  getDriverReverificationRequestsData,
  getDriverBankDetailsVerifyRequestData,
} = actions;

export const getAllDriversList = ({
  pageNo,
  pageSize,
  searchFilter,
  dutyStatus,
  selectedCity,
  vehicleType,
  key,
  sort,
  login_time,
}) => {
  let finaldata = "";
  let finalVehicleList = "";
  selectedCity !== "" &&
    selectedCity?.map((data) => (finaldata = finaldata + "&city_id=" + data));

  vehicleType !== "" &&
    vehicleType?.map(
      (data) => (finalVehicleList = finalVehicleList + "&vehicleType=" + data)
    );
  const documentStatus =
    "&document_verification_status=not_applied&document_verification_status=in_progress&document_verification_status=verified&document_verification_status=rejected";

  return async (dispatch) => {
    const queryParameter =
      "&page=" +
      pageNo +
      // "&status=" +
      // "verified" +
      "&limit=" +
      pageSize +
      "&search=" +
      searchFilter +
      "&duty_status=" +
      dutyStatus +
      finaldata +
      "&is_driver_approved=true" +
      documentStatus +
      finalVehicleList +
      (key && "&key=" + key) +
      (sort && "&value=" + sort) +
      (login_time && "&login_time=" + login_time);
    const resp = await DataService.get(API.drivers.getAll + queryParameter);

    if (!resp.data.error) {
      dispatch(getDriverList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getDriverById = (id) => {
  return async (dispatch) => {
    const resp = await DataService.get(API.drivers.getById + id);
    if (!resp.data.error) {
      dispatch(getDriverDataById(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getDriverRequests = (
  page,
  limit,
  searchFilter,
  document_verification_status,
  city,
  approvalStatus,
  key,
  sort,
  isDriverApproved,
  login_time
) => {
  let finaldata = "";

  city !== "" &&
    city?.map((data) => (finaldata = finaldata + "&city_id=" + data));

  let finalDocumentStatus = document_verification_status
    ? "&document_verification_status=" + document_verification_status
    : "&document_verification_status=not_applied&document_verification_status=in_progress&document_verification_status=verified&document_verification_status=rejected";

  return async (dispatch) => {
    const queryParameter =
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&is_driver_approved=" +
      isDriverApproved +
      "&search=" +
      searchFilter +
      finalDocumentStatus +
      finaldata +
      "&is_driver_rejected=" +
      approvalStatus +
      "&status=verified" +
      "&role=driver" +
      (key && "&key=" + key) +
      (sort && "&value=" + sort) +
      (login_time && "&login_time=" + login_time);
    const resp = await DataService.get(API.drivers.getRequest + queryParameter);
    if (!resp.data.error) {
      dispatch(getDriverRequestsData(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const getDriverReverificationRequests = (
  page,
  limit,
  searchFilter,
  document_verification_status,
  city,
  approvalStatus,
  key,
  sort,
  isDriverApproved,
  login_time
) => {
  let finaldata = "";

  city !== "" &&
    city?.map((data) => (finaldata = finaldata + "&city_id=" + data));

  let finalDocumentStatus = "";

  document_verification_status?.map(
    (data) =>
      (finalDocumentStatus =
        finalDocumentStatus + "&document_verification_status=" + data)
  );

  return async (dispatch) => {
    const queryParameter =
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&is_driver_approved=" +
      isDriverApproved +
      "&search=" +
      searchFilter +
      finalDocumentStatus +
      finaldata +
      "&is_driver_rejected=" +
      approvalStatus +
      "&status=verified" +
      "&role=driver" +
      (key && "&key=" + key) +
      (sort && "&value=" + sort);
    // (login_time && "&login_time=" + login_time);
    const resp = await DataService.get(API.drivers.getRequest + queryParameter);
    if (!resp.data.error) {
      dispatch(getDriverReverificationRequestsData(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const getDriverDocuments = (id) => {
  return async (dispatch) => {
    const resp = await DataService.get(API.drivers.getDocuments + id);

    if (!resp.data.error) {
      dispatch(getDriverDocumentsData(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const approveDocument = (id, payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.drivers.approveDocument + id,
      payload
    );

    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const rejectDocument = (id, payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.drivers.approveDocument + id,
      payload
    );

    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const approveDriver = (id, payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(API.drivers.approveDriver + id, payload);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const rejectDriver = (id, payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(API.drivers.approveDriver + id, payload);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const getDriverIdCardById = (id) => {
  return async (dispatch) => {
    const resp = await DataService.get(API.drivers.idcard + id);
    if (!resp.data.error) {
      dispatch(getDriverIdCardDataById(resp.data));
      return resp.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const getCaptainDriversListData = ({
  pageNo,
  pageSize,
  captainId,
  searchFilter,
  dutyStatus,
  key,
  sort,
}) => {
  return async (dispatch) => {
    const queryParameter =
      "?page=" +
      pageNo +
      "&limit=" +
      pageSize +
      "&captainId=" +
      captainId +
      "&search=" +
      searchFilter +
      "&duty_status=" +
      dutyStatus +
      "&key=" +
      key +
      "&value=" +
      sort;
    const resp = await DataService.get(
      API.drivers.getCaptainDriver + queryParameter
    );

    if (!resp.data.error) {
      dispatch(getCaptainDriverList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const driverWallet = (driverId) => {
  return async (dispatch) => {
    const queryParameter = "?user_id=" + driverId;
    const resp = await DataService.get(API.drivers.wallet + queryParameter);
    if (!resp.data.error) {
      dispatch(getDriverWallet(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const transactionList = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.drivers.transactionList, payload);
    if (!resp.data.error) {
      dispatch(getTransactionList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const driverWalletChart = (driverId, year) => {
  return async (dispatch) => {
    const queryParameter = "?year=" + year + "&user_id=" + driverId;
    const resp = await DataService.get(
      API.drivers.walletChart + queryParameter
    );
    if (!resp.data.error) {
      dispatch(getDriverWalletChart(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getDriverAllRideRequests = (
  page,
  limit,
  driverId,
  fromDate,
  toDate,
  status
) => {
  return async (dispatch) => {
    const queryParameter =
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&driver_id=" +
      driverId +
      "&from_date=" +
      fromDate +
      "&to_date=" +
      toDate +
      "&status=" +
      status;
    const resp = await DataService.get(
      API.drivers.rideRequestOfDriver + queryParameter
    );
    if (!resp.data.error) {
      dispatch(getRideRequests(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const deactivateDriver = (driverId, text) => {
  console.log("driverId on action creater", driverId, text);

  return async () => {
    console.log(
      "driverId",
      API.drivers.deactivate + driverId + `?is_driver_active=${text}`
    );
    const resp = await DataService.put(
      API.drivers.deactivate +
        driverId +
        `?is_driver_active=${text}

    `
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const multiDeleteDriverRequests = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.delete(
      API.drivers.deleteDriverRequest,
      payload
    );

    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const multiDeleteDrivers = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.drivers.deleteDrivers, payload);

    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const addRatingWiseRideAccept = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.drivers.addRaingWiseRide, payload);

    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const getRatingWiseRideAccept = () => {
  return async (dispatch) => {
    const resp = await DataService.get(API.drivers.getRaingWiseRide);

    if (!resp.data.error) {
      dispatch(getRatingWiseRide(resp.data.data));

      return resp.data.data;
    } else {
      console.log("called", resp.data.statusCode);
      // message.error(resp.data.message);
      return resp.data;
    }
  };
};
export const editRatingWiseRideAccept = (payload, id) => {
  return async (dispatch) => {
    const resp = await DataService.put(API.drivers.editRaingWiseRide, payload);

    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const deleteRatingWiseRideAccept = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.drivers.deleteRaingWiseRide + id);

    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const updateDriverRequest = (id, payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.drivers.updateDriverRequest + id,
      payload
    );

    if (!resp.data.error) {
      return resp.data.data;
    } else {
      // message.error(resp.data.message);
      return false;
    }
  };
};

export const captainDriverMigration = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(API.drivers.driverMigration, payload);
    if (!resp.data.error) {
      return true;
    } else {
      return false;
    }
  };
};

export const getDriverBankDetailsVerifyRequest = (
  page,
  limit,
  searchFilter,

  city,
  status,

  sort
) => {
  let finaldata = "";

  city !== "" &&
    city?.map((data) => (finaldata = finaldata + "&city_id=" + data));

  return async (dispatch) => {
    let queryParameter =
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&search=" +
      searchFilter +
      finaldata +
      "&key=updated_at" +
      "&value=" +
      sort;
    if (status) {
      queryParameter += `&bankDetailsStatus=${status}`;
    }

    const resp = await DataService.get(
      API.drivers.driverBankDetailRequests + queryParameter
    );
    if (!resp.data.error) {
      dispatch(getDriverBankDetailsVerifyRequestData(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const updateBankDetailRequest = (id, payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.drivers.updateBankDetailRequest + id,
      payload
    );

    if (!resp.data.error) {
      return true;
    } else {
      // message.error(resp.data.message);
      return false;
    }
  };
};
