import {
  Button, Card,
  Col, DatePicker, Divider, Form, Input, Row, Select
} from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCityData } from "../../../redux/Masters/City/actionCreatore";

const { Option } = Select;
const ScheduleBooking = () => {
  const {t}=useTranslation()
  const dispatch=useDispatch()
  const onFinish = (values) => {
    values.date = moment(values.date).format("DD-MM-YYYY");
    values.time = moment(values.time).format("HH:mm");
    console.log(values);
  };
  useEffect(() => {
    reteriveCityList();
  }, []);
  const reteriveCityList = async () => {
    await dispatch(getCityData(""));
  };
  const cityList = useSelector((state) => state.city.cityData);
  const disabledDate = (current) => {
    // Disable dates before today's date
    return current && current < moment().startOf("day");
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">Schedule Booking</h1>
          </Col>
        </Row>
        <Divider />
        <Card style={{ marginBottom: 10 }} className="shadowCardBox">
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row gutter={[16, 16]}>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label={t("name")}
                  name="name"
                  className="schedule-input margin_auto"
                  rules={[{ required: true, message: "Please Enter a Name" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Email"
                  name="email"
                  className="schedule-input margin_auto"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please Enter Valid Email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Mobile No."
                  name="phoneNumber"
                  className="schedule-input margin_auto"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter a Mobile No.",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label="City"
                  className="schedule-input margin_auto"
                  rules={[{ required: true, message: "Please Select City" }]}
                  name="city"
                >
                  <Select showSearch placeholder="Select city">
                    {cityList.length > 0 &&
                      cityList.map((data) => {
                        return (
                          <Select.Option value={data._id}>
                            {data.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Pickup Point"
                  className="schedule-input margin_auto"
                  rules={[{ required: true, message: "Enter Pickup Point" }]}
                  name="pickup"
                >
                  <Select showSearch placeholder="Enter Pickup Point">
                    <Select.Option value="area">area</Select.Option>
                    <Select.Option value="area">area</Select.Option>
                    <Select.Option value="area">area</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Destination"
                  className="schedule-input margin_auto"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Where You Wanna Go.",
                    },
                  ]}
                  name="Destination"
                >
                  <Select showSearch placeholder="Enter Yout Destination">
                    <Select.Option value="area">area</Select.Option>
                    <Select.Option value="area">area</Select.Option>
                    <Select.Option value="area">area</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Date"
                  name="date"
                  className="schedule-input margin_auto"
                  rules={[{ required: true, message: "Please select a date" }]}
                >
                  <DatePicker
                    disabledDate={disabledDate}
                    style={{ height: "40px", width: "150px" }}
                  />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Pickup Time"
                  name="time"
                  className="schedule-input margin_auto"
                  rules={[{ required: true, message: "Please select a time" }]}
                >
                  {/* <TimePicker
                    style={{ height: "40px", width: "50px" }}
                    format="HH:mm"
                    dropdownAlign="top"
                  /> */}
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Available Ride"
                  name="Ride"
                  className="schedule-input margin_auto"
                  rules={[{ required: true, message: "Please select a Ride" }]}
                >
                  <Select showSearch>
                    <Option value="service1">Ride 1</Option>
                    <Option value="service2">Ride 2</Option>
                    <Option value="service3">Ride 3</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Form.Item style={{ width: "85.5%" }} className="margin_auto">
                  <Button type="primary" htmlType="submit">
                    Schedule
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default ScheduleBooking;
