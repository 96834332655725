import { message } from "antd";
import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";
import actions from "./actions";

const {
  fetchSmsTemplateDbList,
  fetchSmsTemplateList,
  fetchTemplatesList,
} = actions;

export const getSmsTemplateFromList = () => {
  return async (dispatch) => {
    const resp = await DataService.get(API.templates.getBySmsBazar);
    if (!resp.data.error) {
      dispatch(fetchSmsTemplateList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getSmsTemplateFromDbList = ({ pageNo, limit, type }) => {
  return async (dispatch) => {
    let queryParamter = "?page=" + pageNo + "&limit=" + limit + "&type=" + type;
    const resp = await DataService.get(API.templates.getByDb + queryParamter);

    if (!resp.data.error) {
      dispatch(fetchSmsTemplateDbList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const createSmsTemplate = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.templates.add, payload);

    if (!resp.error) {
      return true;
    } else {
      // message.error(resp.messageCode);
      return false;
    }
  };
};

export const removeSmsTemplate = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.templates.delete + id);

    if (!resp.error) {
      return true;
    } else {
      // message.error(resp.data.message);
      return false;
    }
  };
};
export const updateNotificationTemplate = (id, payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(API.templates.edit + id, payload);

    if (!resp.error) {
      return true;
    } else {
      // message.error(resp.messageCode);
      return false;
    }
  };
};

export const getTemplatesList = ({ type }) => {
  return async (dispatch) => {
    let queryParamter = "?type=" + type;
    const resp = await DataService.get(API.templates.get + queryParamter);

    if (!resp.data.error) {
      dispatch(fetchTemplatesList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
