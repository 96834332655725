import {
  FileExcelOutlined,
  FileOutlined,
  FilePdfOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Input,
  message,
  Rate,
  Row,
  Select,
  Table,
  Tag,
} from "antd";

import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ADMIN, SUPERADMIN } from "../../../config/dataService/dataService";
import {
  getBookingsList,
  multiDeleteRides,
} from "../../../redux/Bookings/actionCreatore";
import { getCityData } from "../../../redux/Masters/City/actionCreatore";
import { getVehicleTypesData } from "../../../redux/VehicleTypes/actionCreatore";
import { getItem } from "../../../utility/localStorageControl";
import BreadCrumb from "../../BreadCrumb.js/BreadCrumb";
import Loader from "../../loader/loader";
import ReportModal from "../../Modal/ReportModal";
import VerifyOtpModal from "../../Modal/VerifyOtpModal";
import loadingGif from "../../loader/carLoader.gif";
const { RangePicker } = DatePicker;
const ManageBooking = () => {
  const { t } = useTranslation();
  const user = getItem("user_details");
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(50);
  const [status, setStatus] = useState([""]);
  const [searchFilter, setSearchFilter] = useState("");
  const [selectedCity, setSelectedCity] = useState(
    user.role == ADMIN ? [user?.city_id?._id] : ""
  );
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [selectedVehicleType, setSelectedVehicleType] = useState("");
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [showReportModal, setShowReportModal] = useState(false);
  const [docType, setDocType] = useState("");
  const [docName, setDocName] = useState("Rides Report");
  const [showVerify, setShowVerify] = useState(false);
  const reportKeys = [
    "Rider Name",
    "Rider Number",
    "Driver Name",
    "Driver Number",
    "Source",
    "Destination",
    "City",
    "Distance (KM)",
    "Fare (₹)",
    "Status",
    "Rider Rating",
    "Driver Rating",
    "Date",
    "Vehicle",
  ];
  // console.log("moment", moment().format("YYYY-MM-DD"));
  // const rangePresets = {
  //   Today: [dayjs(), dayjs()],
  //   "Last 7 Days": [dayjs().add(-7, "days"), dayjs()],
  //   "Last Month": [dayjs().add(-1, "month"), dayjs()],
  //   "Last 6 Month": [dayjs().add(-6, "month"), dayjs()],
  //   "Last Year": [dayjs().add(-1, "year"), dayjs()],
  // };
  const rangePresets = [
    { label: "Today", value: [dayjs(), dayjs()] },
    { label: "Last 7 Days", value: [dayjs().add(-7, "days"), dayjs()] },
    { label: "Last Month", value: [dayjs().add(-1, "month"), dayjs()] },
    { label: "Last 6 Month", value: [dayjs().add(-6, "month"), dayjs()] },
    { label: "Last Year", value: [dayjs().add(-1, "year"), dayjs()] },
  ];

  //For The Export File as PDF, EXCEL OR CSV
  // const [headerArray, setHeaderArray] = useState([
  //   "id",
  //   "driver_name",
  //   "driver_city",
  //   "status",
  //   "date",
  // ]);
  useEffect(() => {
    // reteriveBookingList();
    // reteriveCityList();
    window.scrollTo(0, 0);
  }, []);

  const { data, isLoading } = useQuery(
    "bookingList",
    () => {
      return reteriveBookingList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataCity, isLoading: isLoadingCity } = useQuery(
    "cityList",
    () => {
      return reteriveCityList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: vehicleData, isLoading: isLoadingVehicleType } = useQuery(
    "vehicleTypelist",
    () => {
      return fetchVehicleTypes();
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    if (!isLoading) {
      setTableLoading(false);
    }
  }, [isLoading]);
  const reteriveBookingList = async () => {
    const resp = await dispatch(
      getBookingsList(
        pageNo,
        limit,
        status,
        searchFilter,
        selectedCity,
        selectedVehicleType,
        fromDate,
        toDate
      )
    );
    if (resp) {
      setExportData(resp?.list);
    }
  };
  let bookingList = useSelector((state) => state.booking.bookingListData);

  const [exportData, setExportData] = useState(bookingList?.list);
  const modifiedExportData = exportData?.map((item, index) => ({
    "Sr. No.": index + 1,
    "Rider Name": item.rider_id?.name || "-",
    "Rider Number": item.rider_id?.number || "-",
    City: item?.pickup_city_details?.name
      ? item?.pickup_city_details?.name
      : "-",
    "Driver Name": item.driver_id?.name || "-",
    "Driver Number": item.driver_id?.number || "-",
    Vehicle: item?.vehicle_details?.name ? item?.vehicle_details?.name : "-",
    Source: item?.pickup_location?.location_name || "-",
    Destination: item?.destination_location?.location_name || "-",

    // MobileNo: item?.number || "-",
    // cityName: item?.city_id?.name || "-",
    Date: item?.created_at
      ? new Date(item?.created_at).toLocaleDateString()
      : "-",
    "Distance (KM)": item?.ride_distance || "-",
    "Fare (₹)": item?.fare || "-",
    Status: item?.status,
    "Rider Rating": item?.rating_rider_to_driver?.rating || "-",
    "Driver Rating": item?.rating_driver_to_rider?.rating || "-",
  }));
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setExportData(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedRows(selectedRowKeys);
    },
  };

  const handleMultiDelete = async () => {
    setTableLoading(true);
    const resp = await dispatch(multiDeleteRides({ rides_id: selectedRows }));
    if (resp) {
      message.success("Rides deleted successfully");
      setSelectedRows([]);
      setTableLoading(false);
      reteriveBookingList();
    }
  };
  //

  const reteriveCityList = async () => {
    await dispatch(getCityData(""));
  };
  let cityList = useSelector((state) => state.city.cityData);
  cityList = cityList?.sort((a, b) => a.name?.localeCompare(b.name));

  const fetchVehicleTypes = async () => {
    await dispatch(getVehicleTypesData());
  };

  const vehicleTypes = useSelector((state) => state.vehicleType.vehicleData);

  const dispatch = useDispatch();
  const handleSearch = async (value) => {
    // setSearch(value);

    await dispatch(getCityData(value));
  };
  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    setTableLoading(true);
    const resp = await dispatch(
      getBookingsList(
        page,
        pageSizes,
        status,
        searchFilter,
        selectedCity,
        selectedVehicleType,
        fromDate,
        toDate
      )
    );
    if (resp) {
      setTableLoading(false);
    }
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setLimit(pageSizes);
    setTableLoading(true);

    const resp = await dispatch(
      getBookingsList(
        current,
        pageSizes,
        status,
        searchFilter,
        selectedCity,
        selectedVehicleType,
        fromDate,
        toDate
      )
    );
    if (resp) {
      setTableLoading(false);
    }
  };
  const handleSelectStatus = async (value) => {
    setStatus([value]);
    setTableLoading(true);
    setPageNo(1);

    const resp = await dispatch(
      getBookingsList(
        1,
        limit,
        [value],
        searchFilter,
        selectedCity,
        selectedVehicleType,
        fromDate,
        toDate
      )
    );
    console.log("resp", resp);
    if (resp) {
      setTableLoading(false);
    }
    // if (value == "accepted") {
    //   setStatus([value, "on-going"]);
    //   const resp=await dispatch(
    //     getBookingsList(
    //       1,
    //       limit,
    //       [value, "on-going"],
    //       searchFilter,
    //       selectedCity
    //     )
    //   );
    //   if(resp){
    //     setTableLoading(false)
    //   }
    // } else {
    //   setStatus([value]);
    //   const resp=await dispatch(
    //     getBookingsList(1, limit, [value], searchFilter, selectedCity)
    //   );
    //   console.log("resp",resp);
    //   if(resp){
    //     setTableLoading(false)
    //   }
    // }
  };

  const handleSearchFilter = async (value) => {
    setSearchFilter(value);
    setPageNo(1);
    setTableLoading(true);

    const resp = await dispatch(
      getBookingsList(
        1,
        limit,
        status,
        value,
        selectedCity,
        selectedVehicleType,
        fromDate,
        toDate
      )
    );
    if (resp) {
      setTableLoading(false);
    }
  };

  const handleSelectedCity = async (value) => {
    setSelectedCity(value);
    setPageNo(1);
    setTableLoading(true);
    const resp = await dispatch(
      getBookingsList(
        1,
        limit,
        status,
        searchFilter,
        value,
        selectedVehicleType,
        fromDate,
        toDate
      )
    );
    if (resp) {
      setTableLoading(false);
    }
  };

  const handleVehicleFilter = async (e) => {
    setSelectedVehicleType(e);
    console.log(e);
    setTableLoading(true);

    const resp = await dispatch(
      getBookingsList(
        1,
        limit,
        status,
        searchFilter,
        selectedCity,
        e,
        fromDate,
        toDate
      )
    );
    if (resp) {
      setTableLoading(false);
    }
  };
  const onRangeChange = async (dates, dateStrings) => {
    if (dates) {
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setFromDate(dates[0]);
      setToDate(dates[1]);
      setTableLoading(true);
      const resp = await dispatch(
        getBookingsList(
          1,
          limit,
          status,
          searchFilter,
          selectedCity,
          selectedVehicleType,
          dates[0],
          dates[1]
        )
      );
      if (resp) {
        setTableLoading(false);
      }
    } else {
      setFromDate("");
      setToDate("");
      setTableLoading(true);
      const resp = await dispatch(
        getBookingsList(
          1,
          limit,
          status,
          searchFilter,
          selectedCity,
          selectedVehicleType,
          "",
          ""
        )
      );
      if (resp) {
        setTableLoading(false);
      }
    }
  };

  const columns = [
    {
      title: t("srNo"),
      width: 80,
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * limit,
    },
    {
      title: "Trip Id",
      // dataIndex: ["pickup_city_details", "name"],
      key: "tripId",
      align: "center",
      render: (data) => {
        return <span>{data?.tripId ? data?.tripId : "-"}</span>;
      },
    },
    {
      title: "TimeStamp",

      key: "date",
      align: "center",
      render: (data) => {
        const date = new Date(data?.created_at);
        const finaldate = date.toLocaleDateString();
        const finalTime = date.toLocaleTimeString();

        return (
          <span>
            {finaldate}
            <br /> {finalTime}{" "}
          </span>
        );
      },
    },

    {
      title: "City",
      dataIndex: ["pickup_city_details", "name"],
      key: "driver_city",
      align: "center",
    },
    {
      title: "Vehicle",
      // dataIndex: ["pickup_city_details", "name"],
      key: "vehicle",
      align: "center",
      render: (data) => {
        return (
          <span>
            {data?.vehicle_details?.name ? data?.vehicle_details?.name : "-"}
          </span>
        );
      },
    },
    {
      title: "Name / Phone Number",
      key: "namePhone",
      children: [
        {
          title: <div> Rider</div>,
          // <Checkbox style={{ color: "white" }}>Driver Name</Checkbox>

          key: "Rider_name",
          align: "center",
          render: (data) => {
            return (
              <span>
                {data?.rider_id ? (
                  <>
                    {data?.rider_id ? data?.rider_id.name : "N.A."} <br />
                    {data?.rider_id ? data?.rider_id.number : "N.A."}
                  </>
                ) : (
                  "N.A"
                )}
              </span>
            );
          },
        },
        {
          title: "Driver",
          // <Checkbox style={{ color: "white" }}>Driver Name</Checkbox>
          // dataIndex: ["driver_id","name"],
          key: "driver_name",
          align: "center",
          render: (data) => {
            return (
              <span>
                {data.driver_id ? (
                  <>
                    {data?.driver_id ? data?.driver_id.name : "N.A."}
                    <br />
                    {data?.driver_id ? data?.driver_id?.number : "N.A."}
                  </>
                ) : (
                  "N.A"
                )}
              </span>
            );
          },
        },
      ],
    },

    {
      title: "Driver",
      // <Checkbox style={{ color: "white" }}>Driver Name</Checkbox>
      // dataIndex: ["driver_id","name"],
      key: "driver_name",
      align: "center",
      render: (data) => {
        return (
          <span>
            {data?.driver_id ? data?.driver_id.name : "N.A."} <br />
            {data?.driver_id ? data?.driver_id?.number : "N.A."}
          </span>
        );
      },
    },

    {
      title: "Pick Up Point",
      // dataIndex: ["pickup_location", "location_name"],
      key: "pickup",
      render: (data) => {
        return (
          <span className="booking-address">
            {data?.pickup_location ? data?.pickup_location.location_name : ""}{" "}
            <br />
          </span>
        );
      },
    },
    {
      title: "Destination",
      // dataIndex: ["destination_location", "location_name"],
      key: "Destination",
      render: (data) => {
        return (
          <span className="booking-address">
            {data?.destination_location
              ? data?.destination_location.location_name
              : ""}{" "}
            <br />
          </span>
        );
      },
    },

    {
      title: "Current Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status) => {
        return (
          <Tag
            style={{ width: "100px", textAlign: "center" }}
            color={
              status.toLowerCase() === "accepted"
                ? "orange"
                : status.toLowerCase() === "completed"
                ? "blue"
                : status.toLowerCase() === "pending"
                ? "yellow"
                : status.toLowerCase() === "on-going"
                ? "green"
                : "red"
            }
            key={status}
          >
            {/* {status.toUpperCase() } */}

            {status == "expired" ? "No Action" : status.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "Stoppages",
      // dataIndex: ["numberOfStoppages"],
      key: "stoppages",
      render: (data) => {
        return (
          <span>{data?.numberOfStoppages ? data?.numberOfStoppages : 0}</span>
        );
      },
    },
    {
      title: "Rating",
      children: [
        {
          title: "Driver",
          render: (data) => {
            return (
              <span>
                {data.rating_rider_to_driver ? (
                  <Rate
                    defaultValue={data?.rating_rider_to_driver.rating}
                    disabled
                  />
                ) : (
                  <Rate defaultValue={0} disabled />
                )}{" "}
              </span>
            );
          },
        },
        {
          title: "Rider",
          render: (data) => {
            return (
              <span>
                {data.rating_driver_to_rider ? (
                  <Rate
                    defaultValue={data?.rating_driver_to_rider.rating}
                    disabled
                  />
                ) : (
                  <Rate defaultValue={0} disabled />
                )}{" "}
              </span>
            );
          },
        },
      ],
    },
  ];
  console.log("bookingList", bookingList);

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">{t("booking")}</h1>
            {/* <h1 className="heading">Manage Booking</h1> */}
            <BreadCrumb />
          </Col>
          {/* <Col lg={12} md={12} sm={12} xs={12}>
            {user.role == SUPERADMIN && (
              <Button
                style={{ float: "right" }}
                type="danger"
                // disabled={selectedRows.length == 0 ? true : false}
                disabled
                onClick={handleMultiDelete}
              >
                {" "}
                <DeleteOutlined /> Delete
              </Button>
            )}
          </Col> */}
        </Row>
        <Divider />

        {isLoading & isLoadingCity ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <Card
              style={{ marginBottom: 10 }}
              bodyStyle={{ padding: "10px" }}
              className="shadowCardBox totalCard"
            >
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col xxl={4} lg={4} md={8} sm={24} xs={24} className="total">
                  <span className="total">
                    Total {bookingList.total_counts}
                  </span>
                </Col>
                {user?.role != SUPERADMIN && <Col xxl={6}></Col>}
                <Col xxl={3} lg={5} md={8} sm={12} xs={24}>
                  <Select
                    placeholder="Filter By Status"
                    style={{
                      width: "100%",
                      // marginRight: "10px",
                      // marginLeft: "10px",

                      //  float: "right"
                    }}
                    onSelect={(value) => handleSelectStatus(value)}
                    defaultValue={""}
                  >
                    <Select.Option value="" className="selectOptions">
                      <Tag
                        color="#b5f2b5"
                        style={{
                          width: "100%",
                          fontSize: "12px",
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        All
                      </Tag>
                    </Select.Option>
                    <Select.Option value="pending" className="selectOptions">
                      <Tag color="yellow" style={{ width: "100%" }}>
                        PENDING
                      </Tag>
                    </Select.Option>
                    <Select.Option
                      value="accepted"
                      key="accepted"
                      className="selectOptions"
                    >
                      <Tag
                        color="orange"
                        style={{
                          width: "100%",
                          fontSize: "10px",

                          fontWeight: "bold",
                        }}
                      >
                        ACCEPTED
                      </Tag>
                    </Select.Option>
                    <Select.Option value="on-going" className="selectOptions">
                      <Tag color="green" style={{ width: "100%" }}>
                        ON GOING
                      </Tag>
                    </Select.Option>
                    <Select.Option
                      value="completed"
                      key="completed"
                      className="selectOptions"
                    >
                      {" "}
                      <Tag
                        color="blue"
                        style={{
                          width: "100%",
                          fontSize: "10px",

                          fontWeight: "bold",
                        }}
                      >
                        COMPLETED
                      </Tag>
                    </Select.Option>
                    <Select.Option
                      value="cancelled"
                      key="cancelled"
                      className="selectOptions"
                    >
                      <Tag
                        color="red"
                        style={{
                          width: "100%",
                          fontSize: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        CANCELLED
                      </Tag>
                    </Select.Option>
                  </Select>
                </Col>
                <Col xxl={3} lg={6} md={8} sm={12} xs={24}>
                  <Input
                    placeholder="Search By Name Or Phone"
                    style={{
                      width: "100%",
                      // marginLeft: "10px",

                      //  float: "right"
                    }}
                    prefix={<SearchOutlined />}
                    onChange={(event) => handleSearchFilter(event.target.value)}
                  />
                </Col>{" "}
                <Col xxl={3} lg={5} md={8} sm={12} xs={24}>
                  <Select
                    style={{
                      width: "100%",
                      maxHeight: "45px",
                      overflowY: "auto",
                      marginTop: "6px",
                    }}
                    placeholder="Choose Vehicle"
                    allowClear
                    mode="multiple"
                    onChange={handleVehicleFilter}
                  >
                    {vehicleTypes &&
                      vehicleTypes.length > 0 &&
                      vehicleTypes.map((vehicle) => (
                        <Select.Option
                          value={vehicle._id}
                          key={vehicle._id}
                          className="selectOptions"
                        >
                          {vehicle.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Col>{" "}
                {user?.role == SUPERADMIN && (
                  <Col xxl={3} lg={4} md={8} sm={12} xs={24}>
                    <Select
                      placeholder="Choose city"
                      style={{
                        width: "100%",
                        maxHeight: "45px",
                        overflowY: "auto",
                        marginTop: "6px",
                      }}
                      mode="multiple"
                      showSearch
                      filterOption={false}
                      onSearch={(e) => handleSearch(e)}
                      allowClear
                      onChange={handleSelectedCity}
                    >
                      {cityList.length > 0 &&
                        cityList?.map((data) => {
                          return (
                            <Select.Option
                              value={data._id}
                              key={data._id}
                              className="selectOptions"
                            >
                              {data.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Col>
                )}
                <Col xxl={5} lg={8} md={8} sm={12} xs={24}>
                  <RangePicker
                    presets={rangePresets}
                    onChange={onRangeChange}
                    defaultValue={[fromDate, toDate]}
                    style={{
                      borderRadius: "0",
                      // marginLeft: "10px",
                      // width: "10vw",
                      width: "100%",
                    }}
                    format={"DD/MM/YYYY"}
                    allowClear={true}
                    disabledDate={(current) => current.isAfter(moment())}

                    // style={{display:"flex"}}
                  />
                </Col>{" "}
                {user?.role == SUPERADMIN && (
                  <Col xxl={3} lg={4} md={8} sm={12} xs={24}>
                    <Select
                      placeholder="Export Report"
                      style={{
                        width: "100%",
                        // marginLeft: "50px",

                        // float: "right",
                      }}
                      value={null}
                      onSelect={(value) => {
                        // setShowReportModal(true);
                        setShowVerify(true);
                        // deleteKeys(value);
                        setDocType(value);
                      }}
                    >
                      <Select.Option value="csv" className="selectOptions">
                        {" "}
                        <FileOutlined /> CSV
                      </Select.Option>
                      <Select.Option value="excel" className="selectOptions">
                        {" "}
                        <FileExcelOutlined /> Excel (XLSX)
                      </Select.Option>
                      <Select.Option value="pdf" className="selectOptions">
                        {" "}
                        <FilePdfOutlined /> Pdf
                      </Select.Option>
                    </Select>
                  </Col>
                )}
              </Row>
            </Card>
            <Card className="shadowCardBox">
              <Row>
                <Col md={24} sm={24} xs={24} lg={24}>
                  <Table
                    rowClassName={(record, index) =>
                      record?.isRead ? "read" : "unread"
                    }
                    rowSelection={
                      user.role == SUPERADMIN && {
                        type: Checkbox,
                        ...rowSelection,
                      }
                    }
                    rowKey={"_id"}
                    dataSource={tableLoading ? [] : bookingList.list}
                    columns={
                      user.role == SUPERADMIN
                        ? columns.filter((data) => data.key != "driver_name")
                        : user.role == ADMIN
                        ? columns.filter(
                            (key) =>
                              key.title != "City" && key.key != "driver_name"
                          )
                        : columns.filter((data) => data.key != "namePhone")
                    }
                    searchable
                    scroll={{ x: 500, y: 500 }}
                    loading={
                      tableLoading
                      // && {
                      //   indicator: (
                      //     <div
                      //       style={{
                      //         justifyContent: "center",
                      //       }}
                      //     >
                      //       <img src={loadingGif} alt="loading" />
                      //     </div>
                      //   ),
                      // }
                    }
                    onRow={(record) => {
                      return {
                        onDoubleClick: () => {
                          navigate(`${record._id}`);
                        },
                      };
                    }}
                    pagination={{
                      showSizeChanger: true,
                      pageSize: limit,
                      current: pageNo,
                      total: bookingList?.total_counts,
                      onShowSizeChange: onShowSizeChange,
                      onChange: onPageChange,
                      pageSizeOptions: ["50", "100", "200"],
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </>
        )}
        <ReportModal
          showReportModal={showReportModal}
          setShowReportModal={setShowReportModal}
          modifiedExportData={modifiedExportData}
          docType={docType}
          reportKeys={reportKeys}
          docName={docName}
        />
        <VerifyOtpModal
          showVerify={showVerify}
          setShowVerify={setShowVerify}
          setShowReportModal={setShowReportModal}
          type={"Bookings"}
        />
      </div>
    </>
  );
};

export default ManageBooking;
