import { Menu } from "antd";
import FeatherIcon from "feather-icons-react";
import propTypes from "prop-types";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { getItem } from "../utility/localStorageControl";
// import { getItem } from "../utility/localStorageControl";
import { useTranslation } from "react-i18next";
import { CALLCENTER, SUPERADMIN } from "../config/dataService/dataService";
import { CheckRoles } from "../utility/commonFunction";
// console.log("userDetails ==> ", userDetails);
// import versions from '../demoData/changelog.json';

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, events }) => {
  const path = getItem("user_details")?.role;
  // console.log("path", path);
  const { t } = useTranslation();
  const pathName = window.location.pathname;
  // console.log("pathName", pathName);
  const pathArray = pathName.split(path);
  // console.log("pathArray", pathArray);
  const mainPath = pathArray[1];
  // console.log("mainPath", mainPath);
  const mainPathSplit = pathArray.length > 1 && mainPath.split("/");
  // console.log("mainPathSplit", mainPathSplit);
  // const { onRtlChange, onLtrChange, modeChangeDark, modeChangeLight, modeChangeTopNav, modeChangeSideNav } = events;
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
      : []
    // !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );
  const onOpenChange = (keys) => {
    setOpenKeys([keys.length && keys[keys.length - 1]]);
  };
  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };
  const [selectedKeys, setSelectedKeys] = useState(["dashboard"]);
  const navigate = useNavigate();
  const handleClick = (e) => {
    setSelectedKeys([e.key]);
    if (e.key === "dashboard") {
      navigate(`${path}/`);
    } else {
      navigate(`${path}/${e.key}`);
    }
  };
  const userDetails = getItem("user_details");

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      // selectedKeys={selectedKeys}
      // theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      // selectedKeys={
      //   !topMenu
      //     ? [`${`${mainPathSplit[1] ? mainPathSplit[1] : "dashboard"}`}`]
      //     : []
      // }
      selectedKeys={
        pathArray.length > 1 && !topMenu
          ? [`${`${mainPath != "/" ? mainPath.substring(1) : "dashboard"}`}`]
          : []
      }
      // defaultOpenKeys={
      //   !topMenu
      //     ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
      //     : []
      // }
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      // openKeys={selectedKeys}
      style={{ userSelect: "none" }}
    >
      <Menu.Item
        icon={
          !topMenu && <FeatherIcon icon="activity" style={{ color: "blue" }} />
        }
        key="dashboard"
        onClick={handleClick}
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/`}>
          {t("dashboard")}
        </NavLink>
      </Menu.Item>

      {userDetails?.role === "super_admin" && (
        <Menu.Item
          icon={
            !topMenu && <FeatherIcon icon="award" style={{ color: "red" }} />
          }
          key="rolemanagement"
          onClick={handleClick}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/rolemanagement`}>
            {t("roleManagement")}
          </NavLink>
        </Menu.Item>
      )}

      {userDetails?.role === "super_admin" && (
        <Menu.Item
          icon={
            !topMenu && (
              <img
                src="https://api.iconify.design/wpf/administrator.svg?color=%238fbce3&width=19&height=24"
                alt="admin"
              />
            )
          }
          key="manageAdmin"
          onClick={handleClick}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/manageAdmin`}>
            {t("admin")}
          </NavLink>
        </Menu.Item>
      )}

      {userDetails?.role !== CALLCENTER && (
        <Menu.Item
          icon={
            !topMenu && (
              <img
                src="https://api.iconify.design/fluent/video-person-call-20-filled.svg?color=%237ae17c&width=19&height=40"
                alt="Call"
              />
            )
          }
          key="manageCallCenter"
          onClick={handleClick}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/manageCallCenter`}>
            {t("callCenter")}
          </NavLink>
        </Menu.Item>
      )}

      {userDetails?.role != "captain" && (
        <Menu.Item
          icon={
            !topMenu && (
              <img
                src="https://api.iconify.design/mdi/account-tie-hat.svg?color=%23f5eb15&width=19&height=24"
                alt="reviews"
              />
            )
          }
          key="manageCaptains"
          onClick={handleClick}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/manageCaptains`}>
            Captain
          </NavLink>
        </Menu.Item>
      )}

      {CheckRoles("booking_booking") ? (
        // ||CheckRoles("booking_scheduleBooking")
        <Menu.SubMenu
          icon={
            !topMenu && <FeatherIcon icon="book" style={{ color: "#f48484" }} />
          }
          key="Booking"
          title={t("booking")}
        >
          {CheckRoles("booking_booking") && (
            <Menu.Item key="manageBooking" onClick={handleClick}>
              <NavLink onClick={toggleCollapsed} to={`${path}/manageBooking`}>
                {t("booking")}
              </NavLink>
            </Menu.Item>
          )}
          {/* {CheckRoles("booking_scheduleBooking") && (
            <Menu.Item key="scheduleBooking" onClick={handleClick}>
              <NavLink onClick={toggleCollapsed} to={`${path}/scheduleBooking`}>
                Schedule Booking
              </NavLink>
            </Menu.Item>
          )} */}
        </Menu.SubMenu>
      ) : (
        ""
      )}
      {CheckRoles("driver_request") ||
      CheckRoles("driver_drivers") ||
      CheckRoles("driver_insurance ") ? (
        <Menu.SubMenu
          icon={
            !topMenu && (
              <img
                src="https://api.iconify.design/mdi/cab.svg?color=green&width=24&height=30"
                alt="master"
              />
            )
          }
          key="Driver"
          title={t("driverMenu")}
        >
          {CheckRoles("driver_request") && (
            <Menu.Item
              // icon={
              //   !topMenu && <FeatherIcon icon="search"  color="#31e1f7"/>
              // }
              key="manageDriver/driverRequests"
              onClick={handleClick}
            >
              <NavLink
                onClick={toggleCollapsed}
                to={`${path}/manageDriver/driverRequests`}
              >
                New {t("driver")} {t("request")}
              </NavLink>
            </Menu.Item>
          )}

          <Menu.Item
            // icon={
            //   !topMenu && <FeatherIcon icon="search"  color="#31e1f7"/>
            // }
            key="manageDriver/driverReverficationRequest"
            onClick={handleClick}
          >
            <NavLink
              onClick={toggleCollapsed}
              to={`${path}/manageDriver/driverReverficationRequest`}
            >
              Reverfication {t("request")}
            </NavLink>
          </Menu.Item>
          {CheckRoles("driver_bank_details_request") && (
            <Menu.Item
              // icon={
              //   !topMenu && <FeatherIcon icon="search"  color="#31e1f7"/>
              // }
              key="manageDriver/driverBankDetailsVerficationRequest"
              onClick={handleClick}
            >
              <NavLink
                onClick={toggleCollapsed}
                to={`${path}/manageDriver/driverBankDetailsVerficationRequest`}
              >
                Bank Details {t("request")}
              </NavLink>
            </Menu.Item>
          )}
          {CheckRoles("driver_drivers") && (
            <Menu.Item
              // icon={
              //   !topMenu && <FeatherIcon icon="book" color="#f48484"/>
              // }
              key="manageDriver/driver"
              onClick={handleClick}
            >
              <NavLink
                onClick={toggleCollapsed}
                to={`${path}/manageDriver/driver`}
              >
                {t("driver")}
              </NavLink>
            </Menu.Item>
          )}
          {CheckRoles("driver_insurance") && (
            <Menu.Item
              // icon={
              //   !topMenu && <FeatherIcon icon="book" color="#f48484"/>
              // }
              key="insurance"
              onClick={handleClick}
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/insurance`}>
                Insurance
              </NavLink>
            </Menu.Item>
          )}

          <Menu.Item
            // icon={
            //   !topMenu && <FeatherIcon icon="book" color="#f48484"/>
            // }
            key="manageDriver/ratingWiseRideAccept"
            onClick={handleClick}
          >
            <NavLink
              onClick={toggleCollapsed}
              to={`${path}/manageDriver/ratingWiseRideAccept`}
            >
              Rating Wise Ride
            </NavLink>
          </Menu.Item>

          {CheckRoles("vehicles") && (
            <Menu.Item
              // icon={
              //   !topMenu && (
              //     <img
              //       src="https://api.iconify.design/emojione/sport-utility-vehicle.svg?width=19&height=24"
              //       alt="vehicle"
              //     />
              //   )
              // }
              key="manageDriver/vehicles"
              onClick={handleClick}
            >
              <NavLink
                onClick={toggleCollapsed}
                to={`${path}/manageDriver/vehicles`}
              >
                {t("vehicle")}
              </NavLink>
            </Menu.Item>
          )}
          {/* {!CheckRoles("live-drivers") && ( */}
          {/* <Menu.Item
            // icon={
            //   !topMenu && <FeatherIcon icon="book" color="#f48484"/>
            // }
            key="onDutyDrivers"
            onClick={handleClick}
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/onDutyDrivers`}>
              {t("onDuty")} {t("driver")}
            </NavLink>
          </Menu.Item> */}
          {/* )} */}
        </Menu.SubMenu>
      ) : (
        ""
      )}

      {CheckRoles("master_city") ||
      CheckRoles("master_vehicletype") ||
      CheckRoles("master_notiificateImage") ||
      CheckRoles("manageTips") ||
      CheckRoles("manageFare") ||
      CheckRoles("master_policeVerificationDoc") ? (
        <Menu.SubMenu
          icon={
            !topMenu && (
              <img
                src="https://api.iconify.design/logos/active-campaign-icon.svg?width=19&height=24"
                alt="master"
              />
            )
          }
          key="Master"
          title={t("master")}
        >
          {userDetails?.role === "super_admin" && (
            <Menu.Item
              // icon={
              //   !topMenu && <FeatherIcon icon="search"  color="#31e1f7"/>
              // }
              key="master/manageCity"
              onClick={handleClick}
            >
              <NavLink
                onClick={toggleCollapsed}
                to={`${path}/master/manageCity`}
              >
                {t("city")}
              </NavLink>
            </Menu.Item>
          )}

          {CheckRoles("master_notificateImage") && (
            <Menu.Item
              // icon={
              //   !topMenu && <FeatherIcon icon="search"  color="#31e1f7"/>
              // }
              key="master/notificationImage"
              onClick={handleClick}
            >
              <NavLink
                onClick={toggleCollapsed}
                to={`${path}/master/notificationImage`}
              >
                {t("notificationImage")}
              </NavLink>
            </Menu.Item>
          )}
          {CheckRoles("master_policeVerificationDoc") && (
            <Menu.Item
              // icon={
              //   !topMenu && <FeatherIcon icon="search"  color="#31e1f7"/>
              // }
              key="master/policeVerificationDoc"
              onClick={handleClick}
            >
              <NavLink
                onClick={toggleCollapsed}
                to={`${path}/master/policeVerificationDoc`}
              >
                {t("policeVerificationDoc")}
              </NavLink>
            </Menu.Item>
          )}

          {CheckRoles("manageTips") && (
            <Menu.Item
              // icon={
              //   !topMenu && <FeatherIcon icon="search"  color="#31e1f7"/>
              // }
              key="master/tips"
              onClick={handleClick}
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/master/tips`}>
                Tips
              </NavLink>
            </Menu.Item>
          )}

          {CheckRoles("manageFare") && (
            <Menu.Item
              // icon={
              //   !topMenu && (
              //     <img
              //       src="https://api.iconify.design/mdi/account-cash-outline.svg?color=purple&width=19&height=24"
              //       alt="fair"
              //     />
              //   )
              // }
              key="master/manageFare"
              onClick={handleClick}
            >
              <NavLink
                onClick={toggleCollapsed}
                to={`${path}/master/manageFare`}
              >
                {t("fare")}
              </NavLink>
            </Menu.Item>
          )}

          {CheckRoles("userDeleteReasons") && (
            <Menu.Item
              // icon={
              //   !topMenu && (
              //     <img
              //       src="https://api.iconify.design/mdi/account-cash-outline.svg?color=purple&width=19&height=24"
              //       alt="fair"
              //     />
              //   )
              // }
              key="master/profileDeleteReasons"
              onClick={handleClick}
            >
              <NavLink
                onClick={toggleCollapsed}
                to={`${path}/master/profileDeleteReasons`}
              >
                {t("profileDeleteReason")}
              </NavLink>
            </Menu.Item>
          )}
          {CheckRoles("bookingCancelReasons") && (
            <Menu.Item
              // icon={
              //   !topMenu && (
              //     <img
              //       src="https://api.iconify.design/mdi/account-cash-outline.svg?color=purple&width=19&height=24"
              //       alt="fair"
              //     />
              //   )
              // }
              key="master/bookingCancelReasons"
              onClick={handleClick}
            >
              <NavLink
                onClick={toggleCollapsed}
                to={`${path}/master/bookingCancelReasons`}
              >
                {t("booking")} {t("cancelReason")}
              </NavLink>
            </Menu.Item>
          )}
          {CheckRoles("driverRejectionReason") && (
            <Menu.Item
              // icon={
              //   !topMenu && (
              //     <img
              //       src="https://api.iconify.design/mdi/account-cash-outline.svg?color=purple&width=19&height=24"
              //       alt="fair"
              //     />
              //   )
              // }
              key="master/driverRejectionReasons"
              onClick={handleClick}
            >
              <NavLink
                onClick={toggleCollapsed}
                to={`${path}/master/driverRejectionReasons`}
              >
                {t("driverRejectReason")}
              </NavLink>
            </Menu.Item>
          )}

          {CheckRoles("ratingTags") && (
            <Menu.Item
              // icon={
              //   !topMenu && (
              //     <img
              //       src="https://api.iconify.design/mdi/account-cash-outline.svg?color=purple&width=19&height=24"
              //       alt="fair"
              //     />
              //   )
              // }
              key="master/ratingTags"
              onClick={handleClick}
            >
              <NavLink
                onClick={toggleCollapsed}
                to={`${path}/master/ratingTags`}
              >
                Rating Tags
              </NavLink>
            </Menu.Item>
          )}
        </Menu.SubMenu>
      ) : (
        ""
      )}

      <Menu.SubMenu
        title={"Template Master"}
        icon={
          !topMenu && (
            <img
              src="https://api.iconify.design/eos-icons/templates.svg?color=%23bedd7c&width=19&height=24"
              alt="vehicle"
            />
          )
        }
      >
        <Menu.Item
          // icon={
          //   !topMenu && <FeatherIcon icon="search"  color="#31e1f7"/>
          // }
          key="template/sms"
          onClick={handleClick}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/template/sms`}>
            SMS Template
          </NavLink>
        </Menu.Item>

        <Menu.Item
          // icon={
          //   !topMenu && <FeatherIcon icon="search"  color="#31e1f7"/>
          // }
          key="template/notification"
          onClick={handleClick}
        >
          <NavLink
            onClick={toggleCollapsed}
            to={`${path}/template/notification`}
          >
            Notification Template
          </NavLink>
        </Menu.Item>
      </Menu.SubMenu>

      {CheckRoles("master_vehicleType") || CheckRoles("MasterVehicleModel") ? (
        <Menu.SubMenu
          title={"Vehicle Master"}
          key="vehicleMaster"
          icon={
            !topMenu && (
              <img
                src="https://api.iconify.design/emojione/sport-utility-vehicle.svg?width=19&height=24"
                alt="vehicle"
              />
            )
          }
        >
          {CheckRoles("master_vehicleType") && (
            <Menu.Item
              // icon={
              //   !topMenu && <FeatherIcon icon="search"  color="#31e1f7"/>
              // }
              key="master/vehicletype"
              onClick={handleClick}
            >
              <NavLink
                onClick={toggleCollapsed}
                to={`${path}/master/vehicletype`}
              >
                {t("vehicleType")}
              </NavLink>
            </Menu.Item>
          )}
          {CheckRoles("MasterVehicleModel") && (
            <Menu.Item
              // icon={
              //   !topMenu && <FeatherIcon icon="search"  color="#31e1f7"/>
              // }
              key="master/vehicleModel"
              onClick={handleClick}
            >
              <NavLink
                onClick={toggleCollapsed}
                to={`${path}/master/vehicleModel`}
              >
                Vehicle Model
              </NavLink>
            </Menu.Item>
          )}
        </Menu.SubMenu>
      ) : (
        ""
      )}

      <Menu.SubMenu
        title="Support"
        key="Support"
        icon={
          !topMenu && (
            <img
              src="https://api.iconify.design/flat-color-icons/online-support.svg"
              alt="master"
            />
          )
        }
      >
        <Menu.Item key={"support/category"} onClick={handleClick}>
          <NavLink onClick={toggleCollapsed} to={`${path}/support/category`}>
            Category
          </NavLink>
        </Menu.Item>
        <Menu.Item key={"support/queries"} onClick={handleClick}>
          <NavLink onClick={toggleCollapsed} to={`${path}/support/queries`}>
            Sub Category
          </NavLink>
        </Menu.Item>
        <Menu.Item key={"support/answers"} onClick={handleClick}>
          <NavLink onClick={toggleCollapsed} to={`${path}/support/answers`}>
            Answers
          </NavLink>
        </Menu.Item>
      </Menu.SubMenu>
      {CheckRoles("partners") && (
        <Menu.Item
          icon={
            !topMenu && (
              <img
                src="https://api.iconify.design/emojione/handshake.svg?width=19&height=24"
                alt="Be our Partners"
              />
            )
          }
          key="BeOurAffiliatePartner"
          onClick={handleClick}
        >
          <NavLink
            onClick={toggleCollapsed}
            to={`${path}/BeOurAffiliatePartner`}
          >
            {t("beOurAffiliatePartner")}
          </NavLink>
        </Menu.Item>
      )}

      {/* {CheckRoles("coupons") && (
        <Menu.Item
          icon={
            !topMenu && (
              <img
                src="https://api.iconify.design/mdi/coupon.svg?color=%black&width=19&height=24"
                alt="Coupons"
              />
            )
          }
          key="manageCoupons"
          onClick={handleClick}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/manageCoupons`}>
            Coupons
          </NavLink>
        </Menu.Item>
      )} */}

      {CheckRoles("riders") && (
        <Menu.Item
          icon={
            !topMenu && (
              <img
                src="https://api.iconify.design/bxs/user-detail.svg?color=orange&width=20&height=24"
                alt="rider"
              />
            )
          }
          key="manageRider"
          onClick={handleClick}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/manageRider`}>
            {t("rider")}
          </NavLink>
        </Menu.Item>
      )}

      {userDetails?.role == SUPERADMIN && (
        <Menu.SubMenu
          icon={
            !topMenu && (
              <img
                src="https://api.iconify.design/wpf/statistics.svg?color=%23f492e2&width=20&height=24"
                alt="rider"
              />
            )
          }
          title="Map Statistics"
          key="MapStatistics"
        >
          <Menu.Item key="mapStatistics" onClick={handleClick}>
            <NavLink onClick={toggleCollapsed} to={`${path}/mapStatistics`}>
              Detailed Map Statistics
            </NavLink>
          </Menu.Item>
          <Menu.Item key="mapOverallStatistics" onClick={handleClick}>
            <NavLink
              onClick={toggleCollapsed}
              to={`${path}/mapOverallStatistics`}
            >
              Overall Map Statistics
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
      )}
      {userDetails?.role == SUPERADMIN && (
        <Menu.Item
          key="activityLogs"
          onClick={handleClick}
          icon={
            !topMenu && (
              <img
                src="https://api.iconify.design/radix-icons/activity-log.svg?color=%2379f785&width=20&height=24"
                alt="activityLog"
              />
            )
          }
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/activityLogs`}>
            Activity Logs
          </NavLink>
        </Menu.Item>
      )}
      {/* {userDetails?.role != CAPTAIN && (
        <Menu.Item
          key="exotelCalls"
          onClick={handleClick}
          icon={
            !topMenu && (
              <img
                src="https://api.iconify.design/healthicons/call-centre-negative.svg?color=%23de5723&width=20&height=24"
                alt="exotelCalls"
              />
            )
          }
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/exotelCalls`}>
            Exotel Call Logs
          </NavLink>
        </Menu.Item>
      )} */}
      {CheckRoles("feedback") && (
        <Menu.Item
          icon={
            !topMenu && (
              <img
                src="https://api.iconify.design/fluent-mdl2/feedback.svg?color=skyblue&width=19&height=24"
                alt="feedback"
              />
            )
          }
          key="feedbacks"
          onClick={handleClick}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/feedbacks`}>
            {t("appFeedback")}
          </NavLink>
        </Menu.Item>
      )}

      {CheckRoles("appData") && (
        <Menu.Item
          icon={
            !topMenu && (
              <img
                src="https://api.iconify.design/carbon/mobile-download.svg?color=green&width=19&height=24"
                alt="appData"
              />
            )
          }
          key="appData"
          onClick={handleClick}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/appData`}>
            {t("appData")}
          </NavLink>
        </Menu.Item>
      )}

      {CheckRoles("reviews") && (
        <Menu.Item
          icon={
            !topMenu && (
              <img
                src="https://api.iconify.design/carbon/star-review.svg?color=%23eda109&width=19&height=24"
                alt="reviews"
              />
            )
          }
          key="reviews"
          onClick={handleClick}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/reviews`}>
            {t("tripRating")}
          </NavLink>
        </Menu.Item>
      )}

      {CheckRoles("liveTracking") && (
        <Menu.Item
          icon={
            !topMenu && (
              <img
                src="https://api.iconify.design/subway/world-1.svg?color=skyblue&width=19&height=24"
                alt="liveDrivers"
              />
            )
          }
          key="liveDrivers"
          onClick={handleClick}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/liveDrivers`}>
            {t("liveTracking")}
          </NavLink>
        </Menu.Item>
      )}

      {CheckRoles("notification") && (
        <Menu.Item
          icon={
            !topMenu && (
              <img
                src="https://api.iconify.design/mdi/notification-settings-outline.svg?color=blue&width=19&height=24"
                alt="notification"
              />
            )
          }
          key="notification"
          onClick={handleClick}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}//notification`}>
            {t("notification")}
          </NavLink>
        </Menu.Item>
      )}
      {CheckRoles("contactUs") && (
        <Menu.Item
          icon={
            !topMenu && (
              <img
                src="https://api.iconify.design/healthicons/contact-support.svg?color=orange&width=19&height=24"
                alt="notification"
              />
            )
          }
          key="contactUs"
          onClick={handleClick}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/contactUs`}>
            Contact Us
          </NavLink>
        </Menu.Item>
      )}

      {/* {CheckRoles("aboutUs") && (
        <Menu.Item
          icon={!topMenu && <FeatherIcon icon="slack" color="#31e1f7" />}
          key="aboutUs"
          onClick={handleClick}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/aboutUs`}>
            About Us
          </NavLink>
        </Menu.Item>
      )} */}
      {/* {CheckRoles("Earning") && ( */}
      <Menu.Item
        icon={
          !topMenu && (
            <img
              src="https://api.iconify.design/mingcute/wallet-2-fill.svg?color=%23197219"
              alt="earning"
              style={{ width: "20px", height: "20px" }}
            />
          )
        }
        key="earning"
        onClick={handleClick}
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/earning`}>
          Earning
        </NavLink>
      </Menu.Item>
      {/* // )} */}
      {CheckRoles("settings") && (
        <Menu.Item
          key="settings"
          onClick={handleClick}
          icon={
            !topMenu && (
              <FeatherIcon icon="settings" style={{ color: "#5258f2" }} />
            )
          }
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/settings`}>
            Settings
          </NavLink>
        </Menu.Item>
      )}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
};

export default MenuItems;
