import { ArrowDownOutlined } from "@ant-design/icons";
import { Card, Col, DatePicker, Row, Spin } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardRideCount,
  getDashboardRideCountDrivers,
} from "../../redux/Dashboard/actionCreatore";
import { getItem } from "../../utility/localStorageControl";
import loadingGif from "../loader/loading.gif";
import "./style.css";

const { RangePicker } = DatePicker;
function CaptainRidesCount(props) {
  const dispatch = useDispatch();
  const user = getItem("user_details");
  const [selectedCity, setSelectedCity] = useState(user?.city_id?._id);
  const [captainId, setCaptainId] = useState(user?._id);
  const [status, setStatus] = useState("");
  const [showPendindData, setShowPendingData] = useState(false);
  const [showAcceptedData, setShowAcceptedData] = useState(false);
  const [showCancelledData, setShowCancelledData] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [loading, setLoading] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState();
  const [driverLoading, setDriverLoading] = useState(false);

   const rangePresets = [
    { label: "Today", value: [dayjs(), dayjs()] },
    { label: "Last 7 Days", value: [dayjs().add(-7, "days"), dayjs()] },
    { label: "Last Month", value: [dayjs().add(-1, "month"), dayjs()] },
    { label: "Last 6 Month", value: [dayjs().add(-6, "month"), dayjs()] },
    { label: "Last Year", value: [dayjs().add(-1, "year"), dayjs()] },
  ];
  const payload = {
    fromDate,
    toDate,
    status,
    city: selectedCity,
    captain: captainId,
  };

  useEffect(() => {
    fetchRidesRequestCount(payload);
  }, []);

  const fetchRidesRequestCount = async (payload) => {
    setLoading(true);

    const res = await dispatch(getDashboardRideCount(payload));
    if (res) {
      setLoading(false);
    }
  };
  const ridesCount = useSelector((state) => state.dashboard.ridesRequestCount);

  const fetchRidesRequestDriversCount = async (payload) => {
    setDriverLoading(true);

    const res = await dispatch(getDashboardRideCountDrivers(payload));
    if (res) {
      setDriverLoading(false);
    }
  };
  const ridesDriversCount = useSelector(
    (state) => state.dashboard.ridesRequestDriversCount
  );

  const onRangeChange = async (dates, dateStrings) => {
    setStatus("");
    if (dates) {
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setFromDate(dates[0]);
      setToDate(dates[1]);
      payload.fromDate = dates[0];
      payload.toDate = dates[1];

      setLoading(true);
      const resp = await dispatch(getDashboardRideCount(payload));
      if (resp) {
        setLoading(false);
      }
    } else {
      setFromDate("");
      setToDate("");
      setLoading(true);
      payload.fromDate = "";
      payload.toDate = "";
      const resp = await dispatch(getDashboardRideCount(payload));
      if (resp) {
        setLoading(false);
      }
    }
  };
  const handleStatusSelected = async (value) => {
    setStatus(value);
    // setSelectedCaptain("");

    payload.status = value;
    fetchRidesRequestDriversCount(payload);
  };

  if (loading) {
    return (
      <div
        style={{
          height: "200px",
          // width: "100%",
          justifyContent: "center",
          display: "flex",
        }}
      >
        {" "}
        <img src={loadingGif} />{" "}
      </div>
    );
  }

  return (
    <div>
      <Card
        title={
          <span
            style={{
              fontWeight: "bold",
              justifyContent: "center",
              display: "flex",
            }}
          >
            Ride Request Counts {ridesCount?.count ? ridesCount?.count : 0}{" "}
          </span>
        }
        extra={
          <RangePicker
            presets={rangePresets}
            onChange={onRangeChange}
            defaultValue={[fromDate, toDate]}
            style={{
              borderRadius: "0",
            }}
            format={"DD/MM/YYYY"}
            allowClear={true}
            disabledDate={(current) => current.isAfter(moment())}
          />
        }
      >
        <Row gutter={20}>
          <Col xxl={8} lg={8} md={8} sm={8} xs={8}>
            <>
              <Card bodyStyle={{ background: "yellow" }}>
                <div
                  style={{
                    fontSize: "18px",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "500",
                    fontFamily: "sans-serif",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowPendingData(!showPendindData);
                    setShowAcceptedData(false);
                    setShowCancelledData(false);
                    setBackgroundColor("#fbfc90");

                    handleStatusSelected("expired");
                  }}
                >
                  No Action {ridesCount?.expired?.total}{" "}
                </div>
              </Card>
              {status == "expired" && (
                <ArrowDownOutlined
                  style={{ display: "flex", justifyContent: "center" }}
                />
              )}
            </>
          </Col>
          <Col xxl={8} lg={8} md={8} sm={8} xs={8}>
            <>
              <Card bodyStyle={{ background: "skyblue" }}>
                <div
                  style={{
                    fontSize: "18px",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "500",
                    fontFamily: "sans-serif",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setBackgroundColor("#b1edfc");

                    setShowPendingData(false);
                    setShowAcceptedData(!showAcceptedData);
                    setShowCancelledData(false);
                    handleStatusSelected("accepted");
                  }}
                >
                  Accepted {ridesCount?.accepted?.total}{" "}
                </div>
              </Card>
              {status == "accepted" && (
                <ArrowDownOutlined
                  style={{ display: "flex", justifyContent: "center" }}
                />
              )}
            </>
          </Col>{" "}
          <Col xxl={8} lg={8} md={8} sm={8} xs={8}>
            <>
              <Card bodyStyle={{ background: "#f75656" }}>
                <div
                  style={{
                    fontSize: "18px",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "500",
                    fontFamily: "sans-serif",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setBackgroundColor("#f75656");

                    setShowPendingData(false);
                    setShowAcceptedData(false);
                    setShowCancelledData(!showCancelledData);
                    handleStatusSelected("rejected");
                  }}
                >
                  Declined {ridesCount?.rejected?.count}{" "}
                </div>
              </Card>
              {status == "rejected" && (
                <ArrowDownOutlined
                  style={{ display: "flex", justifyContent: "center" }}
                />
              )}
            </>
          </Col>
        </Row>

        {status && (
          <Row
            gutter={20}
            style={{ border: "2px solid black" }}
            // className={`smooth-div ${showPendindData ? "open" : "close"}`}
          >
            {driverLoading ? (
              <Col span={24}>
                <Spin
                  size={"large"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    margin: "40px 0px",
                  }}
                />
              </Col>
            ) : (
              Array.isArray(ridesDriversCount) &&
              ridesDriversCount.map((data) => {
                return (
                  <Col
                    xxl={3}
                    lg={8}
                    style={{ marginBottom: "10px", marginTop: "10px" }}
                  >
                    <Card
                      bodyStyle={{
                        background: backgroundColor,
                        // cursor: "pointer",
                      }}
                      key={data.key}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                        onDoubleClick={() => {
                          // setSelectedCity("");
                        }}
                      >
                        <span
                          style={{ justifyContent: "center", display: "flex" }}
                        >
                          {data.name}
                        </span>

                        <span
                          style={{ justifyContent: "center", display: "flex" }}
                        >
                          {data.total}
                        </span>
                      </div>
                    </Card>
                  </Col>
                );
              })
            )}
          </Row>
        )}
      </Card>
    </div>
  );
}

export default CaptainRidesCount;
