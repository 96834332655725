const actions = {
  GET_CONTACTUS_LIST: "GET_CONTACTUS_LIST",
  contactUsList: (data) => {
    return {
      type: actions.GET_CONTACTUS_LIST,
      data,
    };
  },
 
};

export default actions;
