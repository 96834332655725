const actions = {
  GET_DRIVER_RATING_LIST: "GET_DRIVER_RATING_LIST",
  GET_RIDER_RATING_LIST: "GET_RIDER_RATING_LIST",
  GET_RIDER_RATING_LIST_BY_DRIVER_ID: "GET_RIDER_RATING_LIST_BY_DRIVER_ID",


  getDriverRatingList: (data) => {
    return {
      type: actions.GET_DRIVER_RATING_LIST,
      data,
    };
  },
  getRiderRatingList: (data) => {
    return {
      type: actions.GET_RIDER_RATING_LIST,
      data,
    };
  },


  getRiderRatingListByDriverId: (data) => {
    return {
      type: actions.GET_RIDER_RATING_LIST_BY_DRIVER_ID,
      data,
    };
  },
};

export default actions;
