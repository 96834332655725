const actions = {
  GET_PROFILE_REASONS: "GET_PROFILE_REASONS",
  GET_DRIVER_REJECT_REASONS: "GET_DRIVER_REJECT_REASONS",

  getProfileDeleteReasons: (data) => {
    return {
      type: actions.GET_PROFILE_REASONS,
      data,
    };
  },
  getDriverRejectReasons: (data) => {
    return {
      type: actions.GET_DRIVER_REJECT_REASONS,
      data,
    };
  },
};

export default actions;
