import { Button, Card, Col, Divider, Input, Radio, Row, Table } from "antd";
import { SwapOutlined } from "@ant-design/icons";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { getAppData } from "../../redux/AppData/actionCreatore";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import { WordFormate } from "../../utility/commonFunction";
const AppData = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [device, setDevice] = useState("");
  const [user, setUser] = useState("");

  const [sortKey, setSortKey] = useState("login_time");
  const [sortValue, setSortValue] = useState("DSC");
  const { t } = useTranslation();
  const payload = {
    pageNo,
    pageSize,
    device,
    sortKey,
    sortValue,
    user,
  };

  const { data, isLoading: isLoading } = useQuery(
    "appDatalist",
    () => {
      return retriveAppDataList(payload);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const retriveAppDataList = async (payload) => {
    const resp = await dispatch(getAppData(payload));
    if (resp) {
      setLoading(false);
    }
  };
  const appData = useSelector((state) => state.appData.appDataList);

  const handleSelectDevice = async (event) => {
    setDevice(event.target.value);
    payload.device = event.target.value;
    retriveAppDataList(payload);
  };
  const handleSelectUser = async (event) => {
    setUser(event.target.value);
    payload.user = event.target.value;
    retriveAppDataList(payload);
  };

  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    payload.pageNo = page;
    retriveAppDataList(payload);
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    payload.pageSize = pageSizes;
    retriveAppDataList(payload);
  };
  const handleSort = async (key) => {
    let sorting;
    setSortKey(key);
    if (sortValue === "ASC") {
      setSortValue("DSC");
      sorting = "DSC";
    } else {
      sorting = "ASC";
      setSortValue("ASC");
    }

    payload.sortValue = sorting;
    payload.pageNo = 1;
    payload.key = key;
    retriveAppDataList(payload);
  };
  const columns = [
    {
      title: "Sr. No.",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },

    // {
    //   title: "Gender",
    //   dataIndex: "gender",
    //   key: "gender",
    // },
    {
      title: t("name"),

      key: "name",
      render: (data) => {
        return <span>{data?.name ? data?.name : "-"}</span>;
      },
    },
    {
      title: "Mobile",
      // dataIndex: "number",
      key: "phone",
      render: (data) => {
        return <span>{data?.number ? data?.number : "-"}</span>;
      },
    },
    {
      title: "Email",

      key: "email",
      render: (data) => {
        return <span>{data?.email ? data?.email : "-"}</span>;
      },
    },
    {
      title: "Device ",

      key: "device",
      render: (data) => {
        return (
          <span>
            {data?.device_type ? WordFormate(data?.device_type) : "-"}
          </span>
        );
      },
    },
    {
      title: "App Version",

      key: "version",
      render: (data) => {
        return <span>{data?.appVersion ? data?.appVersion : "-"}</span>;
      },
    },
    {
      title: (
        <div>
          Last Login{" "}
          <Button
            style={{ background: "none", border: "none" }}
            onClick={() => handleSort("login_time")}
          >
            <SwapOutlined rotate={90} style={{ color: "black" }} />
          </Button>
        </div>
      ),
      key: "lastLogin",
      render: (data, index1, index) => {
        const date = new Date();
        if (data.login_time) {
          const date2 = new Date(data.login_time);
          const difference = date.getTime() - date2.getTime();
          const differenceInMinutes = difference / (1000 * 60);
          const differenceInHours = difference / (1000 * 60 * 60);
          const differenceInDays = difference / (1000 * 60 * 60 * 24);

          if (differenceInMinutes < 60) {
            return <span>{parseInt(differenceInMinutes)} minutes ago</span>;
          } else if (differenceInHours < 24) {
            return <span>{parseInt(differenceInHours)} hours ago</span>;
          } else {
            return <span>{parseInt(differenceInDays)} days ago</span>;
          }
        } else {
          return <span>-</span>;
        }
      },
    },
  ];
  return (
    <>
      <div className="common_layout content-container ">
        <h1 className="heading">{t("appData")}</h1>
        <BreadCrumb />

        <Divider />
        <Card style={{ marginBottom: 10 }} className="shadowCardBox">
          <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
            <Col
              xxl={12}
              xl={10}
              lg={8}
              md={8}
              sm={24}
              xs={24}
              className="total"
            >
              Total {appData?.total_counts}
            </Col>
            <Col xxl={7} xl={7} lg={8} md={8} sm={24} xs={24}>
              <div
                className="product-list-action d-flex justify-content-between align-items-center"
                style={{ float: "right" }}
              >
                <div className="product-list-action__tab">
                  <span className="toolbox-text"> Device:</span>
                  <Radio.Group defaultValue="" onChange={handleSelectDevice}>
                    <Radio.Button value="">All </Radio.Button>
                    <Radio.Button value="android">Android</Radio.Button>
                    <Radio.Button value="ios">Ios</Radio.Button>
                  </Radio.Group>
                </div>
              </div>
            </Col>
            <Col xxl={5} xl={7} lg={8} md={8} sm={24} xs={24}>
              <div
                className="product-list-action d-flex justify-content-between align-items-center"
                style={{ float: "right" }}
              >
                <div className="product-list-action__tab">
                  <span className="toolbox-text"> User:</span>
                  <Radio.Group defaultValue="" onChange={handleSelectUser}>
                    <Radio.Button value="">All </Radio.Button>
                    <Radio.Button value="rider">Rider</Radio.Button>
                    <Radio.Button value="driver">Driver</Radio.Button>
                  </Radio.Group>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
        <Card className="shadowCardBox">
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            dataSource={appData?.list}
            columns={columns}
            searchable
            scroll={{ x: "45vh" }}
            pageSize={pageSize}
            pagination={{
              showSizeChanger: true,
              pageSize: pageSize,
              total: appData?.total_counts,
              onShowSizeChange: onShowSizeChange,
              onChange: onPageChange,
              pageSizeOptions: ["50", "100", "500"],
            }}
          />
        </Card>
      </div>
    </>
  );
};

export default AppData;
