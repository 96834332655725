import {
  BellOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  FileExcelOutlined,
  FileOutlined,
  FilePdfOutlined,
  MessageOutlined,
  SearchOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tabs,
} from "antd";
import dayjs from "dayjs";
import FeatherIcon from "feather-icons-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  IMAGE_URL_PREFIX,
  SUPERADMIN,
} from "../../../config/dataService/dataService";
import { getAllInsuranceList } from "../../../redux/insurance/actionCreatore";
import { getCityData } from "../../../redux/Masters/City/actionCreatore";
import {
  getDateFormate,
  getRandomColor,
} from "../../../utility/commonFunction";
import { getItem } from "../../../utility/localStorageControl";
import BreadCrumb from "../../BreadCrumb.js/BreadCrumb";
import { ZIndex1001 } from "../../dashboard/style";
import ReportModal from "../../Modal/ReportModal";
import SmsNotificationModal from "../../Modal/SmsNotificationModal";
import VerifyOtpModal from "../../Modal/VerifyOtpModal";

const { RangePicker } = DatePicker;

const ManageInsurance = () => {
  const [searchCity, setSearchCity] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs().add(30, "days"));
  const [sort, setSort] = useState("ASC");
  const [sortkey, setSortkey] = useState("insurance_valid_till");

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [loading, setLoading] = useState(false);
  const user = getItem("user_details");

  const [searchFilter, setSearchFilter] = useState("");
  const [selectedCity, setSelectedCity] = useState(
    user.role != "super_admin" ? [user?.city_id?._id] : ""
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [docModal, setDocModal] = useState(false);
  const [docURL, setDocURL] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [showReportModal, setShowReportModal] = useState(false);
  const [docType, setDocType] = useState("");
  const [docName, setDocName] = useState("Drivers Insurance Report");

  const [showVerify, setShowVerify] = useState(false);
  const reportKeys = ["Name", "Mobile No.", "Expiration Date", "City"];
  const [modalTitle, setModalTitle] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalKey, setModalkey] = useState("");

  const [smsNotificationDataOrg, setSmsNotificationDataOrg] = useState([]);
  const [smsNotificationData, setSmsNotificationData] = useState([]);
  let payload = {
    pageNo,
    pageSize,
    searchFilter,

    selectedCity,

    key: sortkey,
    sort,
    fromDate,
    toDate,
  };
  const { data, isLoading } = useQuery(
    "driverlist",
    () => {
      return reteriveDriverList(payload);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataCity, isLoading: isLoadingCity } = useQuery(
    "cityList",
    () => {
      return reteriveCityList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const reteriveCityList = async () => {
    await dispatch(getCityData(""));
  };
  const cityList = useSelector((state) => state.city.cityData);

  const reteriveDriverList = async (payload) => {
    setLoading(true);

    const resp = await dispatch(getAllInsuranceList(payload));
    if (resp) {
      setLoading(false);
      setExportData(resp?.list);
    }
  };

  const driverList = useSelector((state) => state.insurance.insuranceListData);

  const [exportData, setExportData] = useState(
    driverList && driverList?.list ? driverList?.list : []
  );

  const modifiedExportData = exportData.map((item, index) => ({
    "Sr. No.": index + 1,
    Name: item.name || "-",
    "Mobile No.": item?.number || "-",
    City: item?.city_id?.name || "-",
    "Expiration Date": item?.insurance_valid_till
      ? getDateFormate(data?.insurance_valid_till)
      : "-",

    // item?.insurance_valid_till || "-",
  }));

  const rangePresets = [
    { label: "Upcoming 7 Days", value: [dayjs(), dayjs().add(7, "days")] },
    { label: "Upcoming 15 Days", value: [dayjs(), dayjs().add(15, "days")] },
    { label: "Upcoming Month", value: [dayjs(), dayjs().add(1, "months")] },
    { label: "Upcoming 6 Month", value: [dayjs(), dayjs().add(6, "months")] },
    // "Upcoming Year": [moment(), moment().add(1, "years")],
  ];

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setFromDate(dateStrings[0]);
      setToDate(dateStrings[1]);
      payload.fromDate = dates[0];
      payload.toDate = dates[1];
      reteriveDriverList(payload);
    } else {
      setFromDate("");
      setToDate("");
      payload.fromDate = "";
      payload.toDate = "";
      reteriveDriverList(payload);
    }
  };

  const handleSort = async (key) => {
    // setLoading(true);
    let sorting;
    setSortkey(key);
    if (sort === "ASC") {
      setSort("DSC");
      sorting = "DSC";
    } else {
      sorting = "ASC";
      setSort("ASC");
    }

    // setTableLoading(true);
    payload.sort = sorting;
    payload.pageNo = 1;
    payload.key = key;
    reteriveDriverList(payload);
  };
  let selectedData = [];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setExportData(selectedRows);

      selectedData.push(selectedRowKeys);
      setSelectedRows(...selectedData);
      setSmsNotificationData([...selectedRows]);
      setSmsNotificationDataOrg([...selectedRows]);
    },
  };
  const handleSearchFilter = async (value) => {
    setSearchFilter(value);
    payload.searchFilter = value;
    reteriveDriverList(payload);
  };

  const handleCitySelected = async (value) => {
    setSelectedCity(value);
    if (searchCity) {
      setSearchCity("");
      await dispatch(getCityData(""));
    }

    payload.selectedCity = value;
    reteriveDriverList(payload);
  };
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    payload.pageNo = page;
    payload.pageSize = pageSizes;
    reteriveDriverList(payload);
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    payload.pageNo = current;
    payload.pageSize = pageSizes;
    reteriveDriverList(payload);
  };

  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      align: "center",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },
    {
      title: "Image",

      key: "Image",
      render: (t, r) => {
        if (!r.profile_image) {
          return (
            <div>
              <Avatar
                icon={<FeatherIcon icon="user" size="30" />}
                style={{
                  backgroundColor: getRandomColor(),
                  height: "40px",
                  width: "40px",
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                }}
              />
            </div>
          );
        } else {
          return (
            <>
              <Image
                height={40}
                width={40}
                style={{ borderRadius: "50%" }}
                // crossOrigin="anonymous"
                alt=""
                src={`${IMAGE_URL_PREFIX}${r.profile_image}`}
              />
            </>
          );
        }
      },
    },
    {
      title: t("name"),
      // dataIndex: "name",
      key: "driver_name",
      align: "center",
      render: (data) => <span>{data && data?.name ? data?.name : "-"}</span>,
    },
    {
      title: "Phone Number",
      // dataIndex: "driver_nam",
      key: "phoneNumber",
      align: "center",
      render: (data) => (
        <span>{data && data?.number ? data?.number : "-"}</span>
      ),
    },
    {
      title: "City",
      key: "driver_city",
      align: "center",
      render: (data) => (
        <span>{data && data?.city_id ? data?.city_id?.name : "-"}</span>
      ),
    },
    // {
    //   title: "Purchase Date",
    //   dataIndex: "purchase_date",
    //   key: "purchase_date",
    //   sorter: true,
    // },
    {
      // title: "Insurance Validity Date",
      title: (
        <div>
          Insurance Validity Date{" "}
          <Button
            style={{ background: "none", border: "none" }}
            onClick={() => handleSort("insurance_valid_till")}
          >
            <SwapOutlined rotate={90} style={{ color: "black" }} />
          </Button>
        </div>
      ),
      render: (data) => (
        <span>
          {data &&
          data?.insurance_valid_till &&
          data?.insurance_valid_till != "null"
            ? getDateFormate(data?.insurance_valid_till)
            : "-"}
        </span>
      ),
      key: "insurance_valid_till",
      // sorter: (a, b, c) => handleSort(c),
      align: "center",
    },

    {
      title: "Document",
      align: "center",

      render: (data, record) => {
        // console.log("data",data.doc);
        let insurance = "";
        for (let val of data?.doc) {
          if (val.type == "insurance") insurance = val.url;
        }
        return (
          <span style={{ display: "flex", justifyContent: "center" }}>
            <a href={IMAGE_URL_PREFIX + insurance} target={"_blank"}>
              {" "}
              <Button>
                Insurance <FileOutlined />
              </Button>
            </a>

            {/* <Button
              style={{ paddingInline: "6px" }}
              type="primary"
              // onClick={() => {
              //   setDocModal(true), setDocURL(IMAGE_URL_PREFIX + insurance);
              // }}
            >
          
           
              Insurance
            </Button> */}
          </span>
        );
      },
    },
  ];

  const columns2 = [
    {
      title: t("srNo"),
      key: "_id",
      align: "center",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },

    {
      title: t("name"),
      // dataIndex: "name",
      key: "driver_name",
      align: "center",
      render: (data) => <span>{data && data?.name ? data?.name : "-"}</span>,
    },

    {
      title: "Phone Number",
      // dataIndex: "driver_nam",
      key: "phoneNumber",
      align: "center",
      render: (data) => (
        <span>{data && data?.number ? data?.number : "-"}</span>
      ),
    },
    {
      title: "City",
      key: "driver_city",
      align: "center",
      render: (data) => (
        <span>{data && data?.city_id ? data?.city_id?.name : "-"}</span>
      ),
    },
    // {
    //   title: "Purchase Date",
    //   dataIndex: "purchase_date",
    //   key: "purchase_date",
    //   sorter: true,
    // },
    {
      title: "Vehicle No.",
      dataIndex: "vehicleNo",
      key: "driver_name",
      align: "center",
      // render: (data) => <span>{data && data?.name ? data?.name : "-"}</span>,
    },

    {
      title: "New Insurance",
      align: "center",
      render: (data) => {
        return (
          <span>
            <a href={data.newInsurance} target={"_blank"}>
              <Button>
                <FileOutlined />
              </Button>
            </a>

            {/* <Button
            // onClick={() => {
            //   setDocModal(true), setDocURL(data.newInsurance);
            // }}
            >
              
            </Button> */}
          </span>
        );
      },
    },
    {
      title: "Insurance Validity Date",
      render: (data) => {
        return <span>{data?.date}</span>;
      },
    },
    {
      title: "Action",
      render: (data) => {
        return (
          <div>
            <Button type="primary" onClick={() => setIsModalOpen(true)}>
              <CheckCircleOutlined /> Verify
            </Button>
            <Button type="danger" style={{ marginLeft: "5px" }}>
              <CloseCircleOutlined /> Reject
            </Button>
          </div>
        );
      },
    },
  ];

  const handleSearch = async (value) => {
    setSearchCity(value);
    await dispatch(getCityData(value));
  };

  const tabItems = [
    {
      key: "all",
      label: "All Insurances",
      children: (
        <>
          <Card style={{ marginBottom: 10 }} className="shadowCardBox">
            <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={8}
                sm={12}
                xs={24}
                className="total"
              >
                Total {driverList?.total_counts}
              </Col>

              {user.role != SUPERADMIN && (
                <Col xxl={8} xl={8} lg={8} md={0} sm={12} xs={24}></Col>
              )}

              <Col xxl={4} xl={4} lg={4} md={8} sm={12} xs={24}>
                <Input
                  placeholder="Search By Name Or Phone"
                  // enterButton="Search"
                  prefix={<SearchOutlined />}
                  style={{ width: "100%" }}
                  onChange={(event) => handleSearchFilter(event.target.value)}
                />
              </Col>
              {user.role == SUPERADMIN && (
                <Col xxl={4} xl={4} lg={4} md={8} sm={12} xs={24}>
                  <Select
                    placeholder="Choose city"
                    style={{
                      width: "100%",
                      maxHeight: "45px",
                      overflowY: "auto",
                      marginTop: "6px",
                    }}
                    showSearch
                    filterOption={false}
                    mode="multiple"
                    onSearch={(e) => handleSearch(e)}
                    allowClear
                    onChange={handleCitySelected}
                  >
                    {cityList &&
                      cityList.map((data) => {
                        return (
                          <Select.Option
                            value={data._id}
                            key={data._id}
                            className="selectOptions"
                          >
                            {data.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Col>
              )}

              <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                <RangePicker
                  presets={rangePresets}
                  onChange={onRangeChange}
                  defaultValue={[fromDate, toDate]}
                  style={{ borderRadius: "0", width: "100%" }}
                  // disabledDate={(date) => date < moment()}
                  direction="vertical"
                  format={"DD/MM/YYYY"}

                  // style={{display:"flex"}}
                />
              </Col>

              {user?.role == SUPERADMIN && (
                <Col xxl={4} xl={4} lg={4} md={8} sm={12} xs={24}>
                  <Select
                    placeholder="Export Report"
                    style={{ width: "100%" }}
                    value={null}
                    // onSelect={(value) => {
                    //   downloadReports(
                    //     "Drivers Insurance Report",
                    //     modifiedExportData,
                    //     value
                    //   );
                    // }}
                    onSelect={(value) => {
                      // setShowReportModal(true);
                      setShowVerify(true);
                      // deleteKeys(value);
                      setDocType(value);
                    }}
                  >
                    <Select.Option value="csv" className="selectOptions">
                      <span>
                        <FileOutlined /> CSV
                      </span>
                    </Select.Option>
                    <Select.Option value="excel" className="selectOptions">
                      <span>
                        <FileExcelOutlined /> Excel (XLSX)
                      </span>
                    </Select.Option>
                    <Select.Option value="pdf" className="selectOptions">
                      <span>
                        <FilePdfOutlined /> Pdf
                      </span>
                    </Select.Option>
                  </Select>
                </Col>
              )}
            </Row>
          </Card>
          <Card className="shadowCardBox">
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              rowSelection={
                user.role == SUPERADMIN && {
                  type: Checkbox,
                  ...rowSelection,
                }
              }
              rowKey={"_id"}
              dataSource={driverList?.list}
              columns={
                user.role == SUPERADMIN
                  ? columns
                  : columns.filter((key) => key.title != "City")
              }
              searchable
              scroll={{ x: "70%", y: "52vh" }}
              pagination={{
                showSizeChanger: true,
                pageSize: pageSize,
                total: driverList?.total_counts,
                onShowSizeChange: onShowSizeChange,
                onChange: onPageChange,
                pageSizeOptions: ["50", "100", "500"],
              }}
            />
          </Card>
        </>
      ),
    },
    {
      key: "request",
      label: "Insurance Reverifiation Request",
      children: (
        <>
          <Card
            className="shadowCardBox"
            title="Driver Insurance Reverification"
          >
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              // dataSource={column2data}
              // columns={columns2}
              columns={
                user.role == SUPERADMIN
                  ? columns2
                  : columns2.filter((key) => key.title != "City")
              }
              searchable
              scroll={{ x: "45vh" }}
              pagination={{
                showSizeChanger: true,
                pageSize: pageSize,
                total: driverList?.total_counts,
                onShowSizeChange: onShowSizeChange,
                onChange: onPageChange,
                pageSizeOptions: ["50", "100", "500"],
              }}
            />
          </Card>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">Insurance</h1>
            <BreadCrumb />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            {user?.role == SUPERADMIN && (
              <Button
                style={{ float: "right", marginLeft: "20px" }}
                type="primary"
                className="ADD"
                disabled={selectedRows.length === 0 ? true : false}
                onClick={() => {
                  setModalOpen(true);
                  setModalTitle("Send Notification");
                  setModalkey("notification");
                }}
              >
                {" "}
                <BellOutlined /> Notification
              </Button>
            )}
            {user?.role == SUPERADMIN && (
              <Button
                style={{ float: "right" }}
                type="primary"
                className="ADD"
                disabled={selectedRows.length === 0 ? true : false}
                onClick={() => {
                  setModalOpen(true);
                  setModalTitle("Send SMS");
                  setModalkey("sms");
                }}
              >
                {" "}
                <MessageOutlined /> SMS
              </Button>
            )}
          </Col>
        </Row>
        <Divider />
        <Tabs items={tabItems}></Tabs>
      </div>
      <Modal
        open={isModalOpen}
        title={"Verify Insurance Document"}
        onCancel={() => setIsModalOpen(false)}
        maskClosable={false}
        centered
      >
        <Form layout="vertical">
          <Form.Item name={"renew"} label={"Renew From"}>
            <Input type="text" />
          </Form.Item>
          <Form.Item name={"validityData"} label={"Insurnace Validity Date"}>
            <Input type={"date"} min={new Date().toISOString().split("T")[0]} />
          </Form.Item>
          <Form.Item name={"document"} label={"Insurnace Document"}>
            <Button
              style={{ paddingInline: "6px" }}
              type=""
              onClick={() => {
                setDocModal(true);
                setDocURL(
                  "https://upload.wikimedia.org/wikipedia/commons/f/f2/Vehicle_Insurance_Certificate_in_India.pdf"
                );
              }}
            >
              <FileOutlined />
              Insurance
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <ZIndex1001>
        <Modal
          open={docModal}
          title={"Insurance Document"}
          onCancel={() => {
            setDocModal(false);
            setDocURL("");
          }}
          footer={null}
          bodyStyle={{ height: "76vh" }}
        >
          <embed
            src={
              // "https://upload.wikimedia.org/wikipedia/commons//f2f/Vehicle_Insurance_Certificate_in_India.pdf"
              docURL
            }
            width={"100%"}
            height="100%"
          />
        </Modal>
      </ZIndex1001>
      <ReportModal
        showReportModal={showReportModal}
        setShowReportModal={setShowReportModal}
        modifiedExportData={modifiedExportData}
        docType={docType}
        reportKeys={reportKeys}
        docName={docName}
      />
      <VerifyOtpModal
        showVerify={showVerify}
        setShowVerify={setShowVerify}
        setShowReportModal={setShowReportModal}
        type={"Insurance"}
      />
      <SmsNotificationModal
        modalOpen={modalOpen}
        modalTitle={modalTitle}
        setModalOpen={setModalOpen}
        smsNotificationData={smsNotificationData}
        setSmsNotificationData={setSmsNotificationData}
        smsNotificationDataOrg={smsNotificationDataOrg}
        modalKey={modalKey}
        role={"driver"}
      />
    </>
  );
};

export default ManageInsurance;
