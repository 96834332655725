import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStateRef from "react-usestateref";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { checkAction } from "../../utility/commonFunction";
import Loader from "../loader/loader";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import {
  addProfileDeleteReasons,
  deleteProfileDeleteReasons,
  getProfileDeleteReasonsData,
  updateProfileDeleteReasons,
} from "../../redux/Masters/deleteReasons/actionCreatore";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
const DeleteProfileReason = () => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [ModalTitle, setModalTitle] = useState("");
  const [updateFlow, setUpdateFlow, setUpdateFlowFileRef] = useStateRef("");
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    // fetchReasons();
    window.scrollTo(0, 0);
  }, []);

  const { data: data, isLoading: isLoading } = useQuery(
    "profileDeleteReasonList",
    () => {
      return fetchReasons();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchReasons = async () => {
    setLoading(true);
    const resp = await dispatch(getProfileDeleteReasonsData(pageNo, pageSize));
    if (resp) {
      setLoading(false);
    }
  };

  const reasonsList = useSelector(
    (state) => state.profileDeleteReason.profileDeleteReasonsData
  );
  // console.log(reasonsList);

  const deleteReasonFinal = async (id) => {
    setLoading(true);

    let remove = await dispatch(deleteProfileDeleteReasons(id));
    if (remove) {
      message.success("Reason deleted successfully");
      fetchReasons("");
    }
  };
  const deleteReason = (id) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this reason?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteReasonFinal(id);
      },
    });
  };
  const editReason = (data) => {
    setModalTitle("Edit Reason");
    setIsModalOpen(true);
    setUpdateFlow(data._id);
    console.log(data);

    form.resetFields();
    form.setFieldsValue({
      reason: data.reason,
    });
  };
  const handleSubmit = async (values) => {
    // setLoading(true);
    // form.resetFields();
    values.reason = values.reason.trim();
    if (setUpdateFlowFileRef.current == "") {
      const resp = await dispatch(
        addProfileDeleteReasons(JSON.stringify(values))
      );
      if (resp) {
        message.success("Reason added successfully");
        setIsModalOpen(false);
        fetchReasons("");
      }
    } else {
      const resp = await dispatch(
        updateProfileDeleteReasons(JSON.stringify(values), updateFlow)
      );
      if (resp) {
        message.success("Reason updated successfully");
        setIsModalOpen(false);
        fetchReasons("");
        setUpdateFlow("");
      }
    }
  };
  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Reason",

      render: (data, record) => {
        return <span>{data?.reason}</span>;
      },
    },

    {
      title: t("action"),
      // dataIndex: "documents",
      key: "action",
      render(data, record) {
        return (
          <div>
            {" "}
            {checkAction("userDeleteReasons", "edit") && (
              <Button
                onClick={() => editReason(data)}
                type="primary"
                // style={{ cursor: "pointer", color: "blue" }}
              >
                <EditOutlined />
                Edit
              </Button>
            )}
            {checkAction("userDeleteReasons", "delete") && (
              <Button
                onClick={() => deleteReason(data._id)}
                style={{ cursor: "pointer", marginLeft: 2 }}
                type="primary"
                danger
                disabled
              >
                <DeleteOutlined />
                Delete
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  const showModal = () => {
    form.resetFields();
    setModalTitle("Add New Reason");
    setIsModalOpen(true);

    // setSelectedId("")
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedId("")
    form.resetFields();
    setUpdateFlow("");
    // setSelectDep(0)
  };

  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    // await dispatch(getReasonsData(page, pageSizes));
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    // await dispatch(getReasonsData(pageNo, pageSizes));
  };
  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">{t("profileDeleteReason")}</h1>
            <BreadCrumb />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            {checkAction("userDeleteReasons", "add") && (
              <Button
                style={{ float: "right" }}
                className="ADD"
                key="1"
                size="large"
                type="primary"
                onClick={showModal}
              >
                <PlusOutlined /> Add New Reason
              </Button>
            )}
          </Col>
        </Row>
        <Divider />
        {!isLoading && (
          <>
            {/* <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col lg={12} md={12} sm={12} xs={12} className="total">
                  Total {reasonsList.total_counts}
                </Col>
              </Row>
            </Card> */}
            <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                dataSource={reasonsList?.list}
                columns={columns}
                searchable
                scroll={{ x: "45vh" }}
                pagination={
                  reasonsList?.total_counts > 10
                    ? {
                        showSizeChanger: true,
                        pageSize: pageSize,
                        total: reasonsList?.total_counts,
                        onShowSizeChange: onShowSizeChange,
                        onChange: onPageChange,
                        pageSizeOptions: ["10", "30", "50"],
                      }
                    : false
                }
              />
            </Card>
          </>
        )}
        {isLoading && <Loader />}
      </div>
      <Modal
        title={ModalTitle}
        open={isModalOpen}
        onOk={form.submit}
        // closable={true}
        // maskClosable={false}
        onCancel={handleCancel}
        okText="Add"
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            name="reason"
            label={"Reason"}
            rules={[
              {
                required: true,
                message: "Please Enter Reason",
                pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <Input type="text" placeholder="Enter Reason" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default DeleteProfileReason;
