import {
  DeleteOutlined,
  MenuUnfoldOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import {
  createRoleData,
  getRoleManagementData,
  removeRoleData,
} from "../../redux/rolemanagement/actionCreator";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import Loader from "../loader/loader";
import { useTranslation } from "react-i18next";

const RoleManRolement = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    roleManagementApi(currentPage);
    window.scrollTo(0, 0);
  }, [pagesize]);
  // const pagesize = 10;
  const handlePagination = (page) => {
    setCurrentPage(page);
    roleManagementApi(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };
  const option = [
    {
      value: "admin",
      label: "Admin",
    },
    {
      value: "call_center",
      label: "Call Center",
    },
    {
      value: "super_admin",
      label: "Super Admin",
    },
    {
      value: "captain",
      label: "Captain",
    },
  ];

  const handleSubmit = async (value) => {
    setLoading(true);
    // setIsModalOpen(false)
    // if (setSelectedIdRef.current == "") {
    const Add_roleData = await dispatch(createRoleData(value));
    if (Add_roleData) {
      setCurrentPage(currentPage);
      roleManagementApi(currentPage);
      setIsModalOpen(false);
      form.resetFields();
      setLoading(false);

      // setSelectedId("")
      // setSelectDep(0)
    }
    // } else {
    //   // console.log('edit form')
    //   let editRole = await DataService.patch(API.roleById.patch + "/" + setSelectedIdRef.current, value);
    //   if (editRole) {
    //     setCurrentPage(currentPage);
    //     roleManagementApi(currentPage);
    //     setIsModalOpen(false);
    //     setSelectedId("");
    //     form.resetFields();
    //     setSelectDep(0)
    //     // setCount();
    //   }
    // }
  };
  const deleteRoleFinal = async (values) => {
    let remove = await dispatch(removeRoleData(values));
    if (remove) {
      setCurrentPage(currentPage);
      roleManagementApi(currentPage);
    }
  };
  const deleteRole = (value) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this role?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteRoleFinal(value);
      },
    });
  };
  const roleManagementApi = async (Page) => {
    setLoading(true);

    let params = {
      page: Page,
      limit: pagesize,
    };
    // console.log("roleManagementResp1")

    const resp = await dispatch(getRoleManagementData(params));
    if (resp) {
      setLoading(false);
    }
  };
  const roleManagementData = useSelector(
    (state) => state?.roleManagementReducer?.role?.data
  );

  const rolePagination = useSelector(
    (state) => state?.roleManagementReducer?.role?.pagination
  );
  // console.log(roleManagementData,"roleManagementResp1")
  const showModal = () => {
    // setModalTitle('Add Role')
    setIsModalOpen(true);
    // setSelectedId("")
    form.resetFields();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedId("")
    form.resetFields();
    // setSelectDep(0)
  };
  const location = useLocation();
  const [rolesList, setRolesList] = useState([]);
  useEffect(() => {
    const data = roleManagementData?.list.filter((item) => {
      return (
        item.login_type !== "super_admin" &&
        item.login_type !== "driver" &&
        item.login_type !== "rider"
      );
    });
    setRolesList(data);
  }, [roleManagementData]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [location]);

  const navigate = useNavigate();
  // const { path } = useMatch();
  const editRole = (value) => {
    // history.push({
    //   pathname: `${path}/${value._id}`,
    // });
    navigate(`${value._id}`)
  };
  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1,
    },
    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("role"),
      dataIndex: "login_type",
      key: "login_type",
    },
    {
      title: t("action"),
      key: "action",
      render: (record, target) => {
        return (
          <div>
            <Button
              onClick={() => editRole(target)}
              type="primary"
              // style={{ cursor: "pointer", color: "blue" }}
            >
              <MenuUnfoldOutlined />
              Details
            </Button>
            <Button
              onClick={() => deleteRole(target._id)}
              style={{ marginLeft: 5 }}
              type="primary"
              danger
              disabled
            >
              <DeleteOutlined />
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="common_layout content-container">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">{t("roleManagement")}</h1>
            <BreadCrumb />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            {/* <Button
              style={{ float: "right" }}
              className="ADD"
              key="1"
              size="large"
              type="primary"
              onClick={showModal}
            >
              <PlusOutlined /> Add {t("role")}
            </Button> */}
          </Col>
        </Row>
        <Divider />
        {/* <Card
          className="titleCard role-table"
          style={{
            margin: "0 auto",
          }}
        ></Card> */}
        <Row gutter={[24, 24]}>
          <Col lg={24} md={24} sm={24} xs={24}>
            {!loading && (
              <Card className="shadowCardBox">
                <Table
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                  columns={columns}
                  dataSource={rolesList}
                  style={{
                    margin: "0 auto",
                  }}
                  rowKey="key"
                  className="role-table"
                  // bordered
                  pagination={false}
                  scroll={{ x: "45vh" }}
                />
              </Card>
            )}
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            {rolePagination && rolePagination !== undefined ? (
              <Pagination
                showSizeChanger
                className="role-table"
                style={{
                  margin: "0 auto",
                }}
                onShowSizeChange={onShowSizeChange}
                current={currentPage}
                total={rolePagination.total_counts}
                pageSizeOptions={["5", "10", "15", "20"]}
                pageSize={pagesize}
                onChange={handlePagination}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Modal
          title={"Add Role"}
          open={isModalOpen}
          onOk={form.submit}
          closable={true}
          maskClosable={false}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            labelCol={{ span: 5 }}
          >
            <Form.Item
              label="Title"
              rules={[{ required: true, message: "Please Enter Role" }]}
              name="title"
            >
              <Input type="text" placeholder="Enter Title"></Input>
            </Form.Item>
            <Form.Item
              label="Role Name"
              rules={[{ required: true, message: "Please Select Role Name" }]}
              name="login_type"
            >
              <Select
                mode="single"
                dropdownAlign={{ vertical: "center", horizontal: "center" }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                placeholder="Search Or Enter Role Name"
                options={option}
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default RoleManRolement;
