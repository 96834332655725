import { EyeOutlined, SwapOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  message,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getDriverReverificationRequests,
  multiDeleteDriverRequests,
} from "../../../redux/drivers/actionCreatore";
import { getCityData } from "../../../redux/Masters/City/actionCreatore";
import Loader from "../../loader/loader";

import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { SUPERADMIN } from "../../../config/dataService/dataService";
import { getItem } from "../../../utility/localStorageControl";
import BreadCrumb from "../../BreadCrumb.js/BreadCrumb";
const DriverReVerificationRequests = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchCity, setSearchCity] = useState(null);
  const [pageSize, setPageSize] = useState(50);
  const [pageNo, setPageNo] = useState(1);
  const [searchFilter, setSearchFilter] = useState("");
  const [documentStatus, setDocumnetStatus] = useState([
    "reverfication_in_progress",
    "rejected",
  ]);
  const user = getItem("user_details");
  const [selectedCity, setSelectedCity] = useState(
    user?.role == "admin" ? [user?.city_id?._id] : ""
  );
  const [approvalStatus, setApprovalStatus] = useState("");
  const [tableLoading, setTableLoading] = useState(true);
  const [sort, setSort] = useState("DSC");
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    // reteriveDriverList();
    // GetCity();
    window.scrollTo(0, 0);
  }, []);

  const { data, isLoading } = useQuery(
    "driveReverificationRequestsList",
    () => {
      return reteriveDriverList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataCity, isLoading: isLoadingCity } = useQuery(
    "cityList",
    () => {
      return GetCity();
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    if (!isLoading) {
      setTableLoading(false);
    }
  }, [isLoading]);
  const reteriveDriverList = async () => {
    setLoading(true);

    const response = await dispatch(
      getDriverReverificationRequests(
        pageNo,
        pageSize,
        searchFilter,
        documentStatus,
        selectedCity,
        approvalStatus,
        "updated_at",
        sort,
        "true"
      )
    );
    if (response) {
      setLoading(false);
    }
  };
  const driversData = useSelector(
    (state) => state.driver.reverificationRequest
  );
  // console.log("driverData", driversData);

  const GetCity = async () => {
    setLoading(true);

    const response = await dispatch(getCityData(search));
    if (response) {
      setLoading(false);
    }
  };
  let cityList = [];
  cityList = useSelector((state) => state?.city?.cityData);
  cityList = cityList?.sort((a, b) => a.name?.localeCompare(b.name));
  const { t } = useTranslation();
  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    await dispatch(
      getDriverReverificationRequests(
        page,
        pageSizes,
        searchFilter,
        documentStatus,
        selectedCity,
        approvalStatus,
        "updated_at",
        sort,
        "true"
      )
    );
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    await dispatch(
      getDriverReverificationRequests(
        current,
        pageSizes,
        searchFilter,
        documentStatus,
        selectedCity,
        approvalStatus,
        "updated_at",
        sort,
        "true"
      )
    );
  };

  const handleSort = async () => {
    setLoading(true);
    let sorting;
    if (sort == "ASC") {
      setSort("DSC");
      sorting = "DSC";
    } else {
      setSort("ASC");
      sorting = "ASC";
    }
    setTableLoading(true);
    // sort=sort=="ASC"?setSort("DSC"):setSort("ASC")
    const resp = await dispatch(
      getDriverReverificationRequests(
        1,
        pageSize,
        searchFilter,
        documentStatus,
        selectedCity,
        approvalStatus,
        "updated_at",
        sorting,
        "true"
      )
    );
    if (resp) {
      setLoading(false);
      setTableLoading(false);
    }
  };
  let selectedData = [];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );
      selectedData.push(selectedRowKeys);
      setSelectedRows(...selectedData);
    },
  };
  // console.log("selectedRows", selectedRows);

  const handleMultiDelete = async () => {
    setTableLoading(true);
    const resp = await dispatch(
      multiDeleteDriverRequests({ driver_request_ids: selectedRows })
    );
    if (resp) {
      message.success("Driver Requests deleted successfully");
      setSelectedRows([]);
      setTableLoading(false);
      reteriveDriverList();
    }
  };
  const returnTooltipTitle = (key) => {
    let status = "";
    key?.is_verified
      ? (status = " Verified")
      : key?.is_rejected
      ? (status = " Rejected")
      : key?.is_reupload_required && !key?.is_reupload
      ? (status = " Not uploaded")
      : (status = " No action performed");
    if (key?.type == "adhaar_card")
      return (
        <span>
          <strong> Aadhar Card </strong> {status}
        </span>
      );
    if (key?.type == "rc_book")
      return (
        <span>
          <strong> RC Book</strong> {status}
        </span>
      );
    if (key?.type == "driving_license")
      return (
        <span>
          {" "}
          <strong>Driving License</strong> {status}
        </span>
      );
    if (key?.type == "insurance")
      return (
        <span>
          {" "}
          <strong>Insurance</strong> {status}
        </span>
      );
    if (key?.type == "police_verification_card")
      return (
        <span>
          <strong>Police verification Document</strong>
          {status}
        </span>
      );
  };
  const returnTooltipBody = (key) => {
    if (key == "adhaar_card") return "AC";
    if (key == "rc_book") return "RC";
    if (key == "driving_license") return "DL";
    if (key == "insurance") return "IN";
    if (key == "police_verification_card") return "PD";
  };
  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },
    {
      title: "Driver Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Current Location City",
      key: "city",
      render: (data) => {
        return <span>{data?.city_id?.name ? data?.city_id?.name : "-"}</span>;
      },
    },
    {
      title: "Registered For City",
      key: "registeredcity",
      render: (data) => {
        return (
          <span>
            {data?.driverRegisteredCity?.name
              ? data?.driverRegisteredCity?.name
              : "-"}
          </span>
        );
      },
    },
    {
      title: t("phoneNo"),
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Updated Documents",
      // dataIndex: "updated_document",
      key: "updated_document",

      render: (data) => {
        // console.log("data", data);
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              // alignContent: "space-between",
            }}
          >
            {data?.doc.map((d) => {
              if (d.is_reupload || d.is_reupload_required)
                return (
                  <Tooltip title={returnTooltipTitle(d)} key={d.type}>
                    {" "}
                    <Button
                      style={{
                        marginLeft: "5px",
                        background: d.is_verified
                          ? "lightgreen"
                          : d.is_rejected
                          ? "#ff5151"
                          : d.is_reupload_required && !d.is_reupload
                          ? "#cecece"
                          : "#DBD050",
                      }}
                    >
                      {" "}
                      {returnTooltipBody(d.type)}
                    </Button>
                  </Tooltip>
                );
            })}

            {/* <Tooltip title="Driving Licence">
              {" "}
              <Button style={{ marginLeft: "5px" }}> DL</Button>
            </Tooltip>
            <Tooltip title="RC Book">
              {" "}
              <Button style={{ marginLeft: "5px" }}> RC</Button>
            </Tooltip>
            <Tooltip title="Insurance">
              <Button style={{ marginLeft: "5px" }}>IN </Button>
            </Tooltip>
            <Tooltip title="Aadhar Card">
              {" "}
              <Button style={{ marginLeft: "5px" }}> AC</Button>
            </Tooltip> */}
          </div>
        );
      },
    },
    {
      title: "Document Verification Status",
      dataIndex: "document_verification_status",
      key: "status",

      render: (status) => {
        return (
          <Tag color={"green"} key={status}>
            {status && "Reverification In Progress"}
          </Tag>
        );
      },
    },
    {
      title: "Approval Status",
      key: "approvalstatus",
      render: (data) => {
        return (
          <Tag
            color={data && data?.is_driver_rejected == true ? "red" : "#DBD050"}
            key={data}
          >
            {data?.is_driver_rejected == true ? "Rejected" : "Pending"}
          </Tag>
        );
      },
    },
    {
      title: (filters, sortOrder) => (
        <div>
          Requested Timestamp
          <Button
            style={{ background: "none", border: "none" }}
            onClick={() => handleSort()}
          >
            <SwapOutlined rotate={90} />
          </Button>
        </div>
      ),

      key: "timestamp",

      render: (data) => {
        const date = new Date(data?.updated_at);

        return (
          <span>
            <Tooltip title={date.toLocaleString()}>
              {" "}
              {data?.updated_at ? date.toLocaleString() : "-"}{" "}
            </Tooltip>
          </span>
        );
      },
    },
    {
      title: t("action"),
      dataIndex: "",
      key: "x",
      render: (record, target) => {
        return (
          <>
            <div className="action" onClick={() => {}}>
              <Button
                type="primary"
                onClick={() => navigate(`request/${record._id}`)}
              >
                <EyeOutlined style={{ marginRight: 5 }} />

                <strong>View</strong>
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  const handleSearchFilter = async (value) => {
    setSearchFilter(value);
    await dispatch(
      getDriverReverificationRequests(
        pageNo,
        pageSize,
        value,
        documentStatus,
        selectedCity,
        approvalStatus,
        "updated_at",
        sort,
        "true"
      )
    );
  };

  const handleSearch = async (value) => {
    setSearchCity(value);
    setSelectedCity("");
    await dispatch(getCityData(value));
    // await dispatch(
    //   getDriverReverificationRequests(
    //     pageNo,
    //     pageSize,
    //     searchFilter,
    //     documentStatus,
    //     "",
    //     approvalStatus,
    //     "updated_at",
    //     sort,
    //     "true"
    //   )
    // );
  };

  const handleSelectedCity = async (value) => {
    setSelectedCity(value);
    if (searchCity) {
      setSearchCity("");
      await dispatch(getCityData(""));
    }
    await dispatch(
      getDriverReverificationRequests(
        pageNo,
        pageSize,
        searchFilter,
        documentStatus,
        value,
        approvalStatus,
        "updated_at",
        sort,
        "true"
      )
    );
  };

  const chooseApprovalStatus = async (status) => {
    setApprovalStatus(status);
    await dispatch(
      getDriverReverificationRequests(
        pageNo,
        pageSize,
        searchFilter,
        documentStatus,
        selectedCity,
        status,
        "updated_at",
        sort,
        "true"
      )
    );
  };

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">
              {" "}
              {t("driver")} Reverfication {t("request")}
            </h1>
            <BreadCrumb />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            {/* <Button
              style={{ float: "right" }}
              type="primary"
                danger
              
              disabled={selectedRows.length == 0 ? true : false}
              onClick={handleMultiDelete}
            >
              {" "}
              <DeleteOutlined /> Delete
            </Button> */}
          </Col>
        </Row>
        <Divider />

        {isLoading & isLoadingCity ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={8}
                  sm={12}
                  xs={24}
                  className="total"
                >
                  Total {driversData?.total_counts}
                </Col>

                {user.role != SUPERADMIN && (
                  <Col xxl={0} xl={6} lg={6} md={0} sm={12} xs={0}></Col>
                )}

                <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                  <Input
                    placeholder="Search By Name Or Phone"
                    style={{ width: "100%" }}
                    // enterButton="Search"
                    // size="large"
                    onChange={(e) => handleSearchFilter(e.target.value)}
                    prefix={<SearchOutlined />}
                  />
                </Col>

                {user.role == SUPERADMIN && (
                  <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                    <Select
                      placeholder="Choose city"
                      style={{
                        width: "100%",
                        maxHeight: "45px",
                        overflowY: "auto",
                        marginTop: "6px",
                      }}
                      showSearch
                      mode="multiple"
                      filterOption={false}
                      onSearch={(e) => handleSearch(e)}
                      allowClear
                      onChange={handleSelectedCity}
                    >
                      {cityList.length > 0
                        ? cityList?.map((data) => {
                            return (
                              <Select.Option
                                value={data._id}
                                key={data._id}
                                className="selectOptions"
                              >
                                {data.name}
                              </Select.Option>
                            );
                          })
                        : ""}
                    </Select>
                  </Col>
                )}
                <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                  <Select
                    placeholder="Choose Approval Status"
                    style={{ width: "100%" }}
                    filterOption={false}
                    // onSearch={(e) => handleSearch(e)}
                    allowClear
                    onClear={(value) => chooseApprovalStatus("")}
                    onSelect={(value) => chooseApprovalStatus(value)}
                  >
                    {/* {cityList &&
                        cityList.map((data) => {
                          return ( */}

                    <Select.Option
                      value={false}
                      key={"pending"}
                      className="selectOptions"
                    >
                      <Tag color="#DBD050">Pending</Tag>
                    </Select.Option>
                    <Select.Option
                      value={true}
                      key={"rejected"}
                      className="selectOptions"
                    >
                      <Tag color={"red"}>Rejected</Tag>
                    </Select.Option>

                    {/* );
                        })} */}
                  </Select>
                </Col>
              </Row>
            </Card>
            <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  record?.isRead ? "read" : "unread"
                }
                // rowSelection={
                //   rowSelection

                // }
                dataSource={driversData?.list}
                columns={
                  user.role == SUPERADMIN
                    ? columns
                    : columns.filter((key) => key.title != "City")
                }
                searchable
                scroll={{ x: "45vh" }}
                loading={tableLoading}
                rowKey={"_id"}
                pagination={
                 {
                        showSizeChanger: true,
                        pageSize: pageSize,
                        total: driversData?.total_counts,
                        onShowSizeChange: onShowSizeChange,
                        onChange: onPageChange,
                        pageSizeOptions: ["50", "100", "500"],

                      }

                }
              />
            </Card>
          </>
        )}
      </div>
      {/* {loading && <Loader />} */}
    </>
  );
};

export default DriverReVerificationRequests;
