/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const API = {
  auth: {
    login: "admin/login",
    signUp: "/signup",
  },
  dashboard: {
    get: "dashboard/get",
    ridesRequestCount: "dashboard/getRideRequestCount?",
    ridesRequestCountCities: "dashboard/getRideRequestCount/city?",
    ridesRequestCountCaptains: "dashboard/getRideRequestCount/captain?",
    ridesRequestCountDrivers: "dashboard/getRideRequestCount/driver?",
    getRecentTransaction: "transactions/get-recent-transaction",
  },
  roleManagement: {
    get: "role/list",
    post: "role/create",
    delete: "role/delete",
  },
  superAdmin: {
    get: "users/get-profile/",
    edit: "users/update-profile/",
  },

  city: {
    get: "city/list",
    post: "city/save",
    delete: "city/delete",
    update: "city/update",
    getWithPagination: "city/listWithPagination",
  },
  state: {
    get: "state/get_data",
  },

  aboutus: {
    get: "aboutUs/list",
    post: "city/save",
    delete: "city/delete",
    update: "aboutUs/update",
  },
  PLDOC: {
    get: "policeVerification/getSampleDoc",
    post: "policeVerification/create",
    delete: "policeVerification/delete",
    update: "policeVerification/update/",
  },
  roleById: {
    get: "role/list",
    patch: "role/update",
  },
  vehicleTypes: {
    get: "vehicletypes/getvehicletypes",
    add: "/vehicletypes/savevehicletype",
    delete: "/vehicletypes/deletevehicletype/",
    update: "/vehicletypes/updatevehicletype/",
    getCategory: "vehicletypes/getvehiclecategories",
  },
  vehicleModel: {
    get: "vehiclemodels/getvehiclemodels",
    add: "vehiclemodels/savevehiclemodel",
    delete: "vehiclemodels/deletevehiclemodel/",
    update: "vehiclemodels/updatevehiclemodel/",
  },
  notificationMaster: {
    get: "notificationMaster/list",
    add: "notificationMaster/create",
    delete: "notificationMaster/delete",
    update: "notificationMaster/update",
  },
  mastermanageFare: {
    getAll: "fareMaster/",
    getById: "fareMaster/get/",
    add: "fareMaster/add",
    update: "fareMaster/update/",
    delete: "fareMaster/delete/",
    updateSericeAvailable: "fareMaster/update-service/",
  },

  riders: {
    getAll: "users/get-list?role=rider",
    delete: "users/riders-delete",
  },
  drivers: {
    getAll: "users/get-list?role=driver",
    getById: "users/get-profile/",
    getRequest: "users/get-list",
    getDocuments: "documents/list?user_id=",
    approveDriver: "users/approve-driver/",
    approveDocument: "documents/verify/",
    getCaptainDriver: "users/captains-driver-list",
    idcard: "users/getIdCard/",
    wallet: "wallet/get",
    transactionList: "transactions/gettransactions",
    walletChart: "transactions/month-year-list",
    rideRequestOfDriver: "rideRequestManagement/list",
    deactivate: "users/driver-status-update/",
    deleteDriverRequest: "users/driver-request-delete",
    deleteDrivers: "users/driver-delete",
    addRaingWiseRide: "ratingWiseRide/add",
    editRaingWiseRide: "ratingWiseRide/update/",
    deleteRaingWiseRide: "ratingWiseRide/delete/",
    getRaingWiseRide: "ratingWiseRide/list",
    updateDriverRequest: "users/driver-update/",
    driverMigration: "captain/migrate-driver",
    driverBankDetailRequests: "users/driver-get-list",
    updateBankDetailRequest: "users/approve-driver-by-admin/",
  },
  callCenter: {
    getAll: "callCenter/list",
    add: "callCenter/create",
    update: "callCenter/update/",
    delete: "callCenter/delete/",
  },
  tips: {
    get: "tipMaster/list/",
    add: "tipMaster/create",
    delete: "tipMaster/delete/",
    update: "tipMaster/update/",
  },
  admin: {
    get: "admin/list",
    add: "admin/create",
    update: "admin/update",
    delete: "admin/delete",
    updatePassword: "admin/change-password/",
    captains: "admin/adminCaptainList/",
    getWithPagination: "admin/adminList",
  },
  driverCenter: {
    getAll: "captain/list",
    add: "captain/create",
    update: "captain/update/",
    delete: "captain/delete/",
    getWithoutPagination: "captain/listWithoutPagination",
  },
  settings: {
    get: "master-setting/get-setting/",
    update: "master-setting/update/",
    updateDocument: "master-setting/updateDriverRequiredDocument",
    deleteDocument: "master-setting/deleteDriverRequiredDocument/",
    postQR: "master-setting/qrCodeSettingCreate",
    updateQR: "master-setting/qrCodeSettingUpdate",
    deleteQR: "master-setting/qrCodeSettingDelete/",
  },
  Feedback: {
    get: "feedback/list",
  },
  AppData: {
    get: "appData/list",
  },
  rating: {
    getRider: "rating/list",
    getDriver: "rate-for-rider/list",
  },
  partners: {
    get: "captain-requests/list",
    approve: "captain-requests/approve-captain/",
  },
  profileDeleteReason: {
    get: "userDeleteReasons/list",
    add: "userDeleteReasons/add",
    update: "userDeleteReasons/update/",
    delete: "userDeleteReasons/delete/",
  },
  driverRejectReason: {
    get: "driverRejectionReason/list",
    add: "driverRejectionReason/add",
    update: "driverRejectionReason/update/",
    delete: "driverRejectionReason/delete/",
  },
  cancelBookingReason: {
    get: "cancellation-reasons/",
    add: "cancellation-reasons//add",
    update: "cancellation-reasons//update/",
    delete: "cancellation-reasons/delete/",
  },

  contactUs: {
    get: "contact-us/list",
  },
  bookings: {
    // get: "rides/list",
    get: "rides/ride-list-for-web-panel",
    getById: "rides/list",
    delete: "rides/rides-delete",
    invoiceDownload: "rides/generate-invoice-pdf/",
  },
  tier: {
    get: "tires/gettires",
    update: "tires/updatetire/",
    delete: "tires/deletetire/",
    add: "tires/savetire",
  },

  ratingTags: {
    get: "ratingTags/getratingtags",
    add: "ratingTags/saveratingtag",
    delete: "ratingTags/deleteratingtag/",
    update: "ratingTags/updateratingtag/",
  },
  supportCategory: {
    getAll: "supportCategory/list",
    add: "supportCategory/add/",
    delete: "supportCategory/delete/",
    update: "supportCategory/update/",
    getWithoutPagination: "supportCategory/listWithoutPagination",
  },
  supportSubCategory: {
    getAll: "supportSubCategory/list",
    add: "supportSubCategory/add/",
    delete: "supportSubCategory/delete/",
    update: "supportSubCategory/update/",
  },
  supportSubCategoryAnswer: {
    getAll: "supportAnsSubCategory/list",
    add: "supportAnsSubCategory/create/",
    delete: "supportAnsSubCategory/delete/",
    update: "supportAnsSubCategory/update/",
  },
  smsNotification: {
    getDriver: "users/driver-tag-list",
    getRider: "users/rider-tag-list",
    sendSmsNotification: "notifications/send-notification-sms",
    getNotificationList: "notifications/notification-list",
    sendNoticeNotification: "notifications/send-multiple-notification",
    notificationNoticeList: "notifications/notification-notice-list",
  },
  templates: {
    getByDb: "template/getAll",
    getBySmsBazar: "template/list",
    delete: "template/delete/",
    add: "template/create",
    get: "template/get-templates",
    edit: "template/update/",
  },
  mapStatistics: {
    get: "googleMapStatistics/getlList",
    apiTypes: "googleMapStatistics/getApiTypes",
    add: "googleMapStatistics/save",
    getTotalCounts: "googleMapStatistics/group-by-sub-api-type",
    getDriverRider: "users/get-list",
  },
  activityLogs: {
    get: "activityLogs/list",
  },
  commission: {
    getForSuperAdmin: "additional-charges/total-charge",
    getForAdmin: "additional-charges/total-charge-for-admin",
    getForCaptain: "additional-charges/total-charge-for-captain",
  },
  reportOTP: {
    send: "users/report-download-send-otp",
    verify: "users/otp-verify-for-report-download",
  },
};

export { API };
