import actions from "./actions";

const { GET_PARTNERS_LIST, GET_PARTNER } = actions;

const initState = {
  partnersListData: {},
  partnerData: {},
};

const beOurPartnerReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_PARTNERS_LIST:
      return {
        ...state,
        partnersListData: data,
      };
    case GET_PARTNER:
      return {
        ...state,
        partnerData: data,
      };
    default:
      return state;
  }
};
export default beOurPartnerReducer;
