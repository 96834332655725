import actions from "./actions";

const { GET_INSURANCE_LIST } = actions;

const initState = {
  insuranceListData: {},
};

const InsuranceReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_INSURANCE_LIST:
      return {
        ...state,
        insuranceListData: data,
      };

    default:
      return state;
  }
};
export default InsuranceReducer;
