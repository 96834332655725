import { Checkbox, Form, Modal } from "antd";
import React from "react";
import { downloadReports } from "../Export Reports/Reports";

function ReportModal({
  showReportModal,
  setShowReportModal,
  modifiedExportData,
  docType,
  reportKeys,
  docName,
}) {
  const [form] = Form.useForm();
  const handleDownloadReport = async (values) => {
    values.columns.unshift("Sr. No.");
    const extractKeys = (array, selectedKeys) => {
      return array.map((obj) => {
        const extractedObj = {};

        selectedKeys.forEach((key) => {
          extractedObj[key] = obj[key];
        });

        return extractedObj;
      });
    };
    const filteredArray = extractKeys(modifiedExportData, values.columns);

    downloadReports(docName, filteredArray, docType);
    setShowReportModal(false);
    form.resetFields();
  };
  return (
    <div>
      <Modal
        open={showReportModal}
        title={"Export Report"}
        okText={"Download"}
        onOk={form.submit}
        onCancel={() => {
          setShowReportModal(false);
          form.resetFields();
        }}
      >
        <Form
          form={form}
          onFinish={handleDownloadReport}
          layout={"vertical"}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              form.submit();
            }
          }}
        >
          <Form.Item
            name={"columns"}
            rules={[
              { required: true, message: "Please select atleast one column" },
            ]}
            label={"Columns"}
          >
            <Checkbox.Group>
              <Checkbox value={"Sr. No."} style={{ display: "none" }}>
                Sr.No.
              </Checkbox>
              {reportKeys.map((key) => {
                return (
                  <Checkbox value={key} key={key}>
                    {key}
                  </Checkbox>
                );
              })}
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default ReportModal;
