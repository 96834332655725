import actions from "./actions";

const { GET_VEHICLE_TYPES,GET_VEHICLE_MODELS,GET_VEHICLE_CATEGORIES } = actions;

const initState = {
  vehicleData: {},
  vehicleModel:{},
  vehicleCategory:{}
};

const VehicleTypeReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_VEHICLE_TYPES:
      return {
        ...state,
        vehicleData: data,
      };
      case GET_VEHICLE_MODELS:
      return {
        ...state,
        vehicleModel: data,
      };
      case GET_VEHICLE_CATEGORIES:
        return {
          ...state,
          vehicleCategory: data,
        };
    default:
      return state;
  }
};
export default VehicleTypeReducer;
