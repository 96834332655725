import {
  BellOutlined,
  FileExcelOutlined,
  FileOutlined,
  FilePdfOutlined,
  MessageOutlined,
  SearchOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Input,
  message,
  Modal,
  Rate,
  Row,
  Select,
  Switch,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SUPERADMIN } from "../../config/dataService/dataService";
import { deactivateDriver } from "../../redux/drivers/actionCreatore";
import { getCityData } from "../../redux/Masters/City/actionCreatore";
import {
  getAllRiderList,
  multiDeleteRiders,
} from "../../redux/riders/actionCreatore";
import { getItem } from "../../utility/localStorageControl";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import ExportReports from "../Export Reports/ExportReports";
import { downloadReports } from "../Export Reports/Reports";
import Spinner from "../loader/Spinner";
import ReportModal from "../Modal/ReportModal";
import SmsNotificationModal from "../Modal/SmsNotificationModal";
import VerifyOtpModal from "../Modal/VerifyOtpModal";
const ManageRider = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [searchCity, setSearchCity] = useState(null);
  const [pageSize, setPageSize] = useState(50);
  const [pageNo, setPageNo] = useState(1);
  const user = getItem("user_details");
  const [selectedCity, setSelectedCity] = useState(
    user?.role !== SUPERADMIN ? user?.city_id?._id : ""
  );
  const [searchValue, setSearchValue] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [sort, setSort] = useState("DSC");
  const [sortKey, setSortKey] = useState("login_time");
  const [selectedTime, setSelectedTime] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalKey, setModalkey] = useState("");

  const [smsNotificationDataOrg, setSmsNotificationDataOrg] = useState([]);
  const [smsNotificationData, setSmsNotificationData] = useState([]);
  const [showReportModal, setShowReportModal] = useState(false);
  const [docType, setDocType] = useState("");
  const [docName, setDocName] = useState("Riders Report");
  const [showVerify, setShowVerify] = useState(false);
  const reportKeys = ["Name", "Mobile No.", "Joined Date", "Ratings"];
  useEffect(() => {
    // reteriveRiderList();
    // GetCity();
  }, []);

  const { data, isLoading } = useQuery(
    "riderlist",
    () => {
      return reteriveRiderList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataCity, isLoading: isLoadingCity } = useQuery(
    "cityList",
    () => {
      return GetCity();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const reteriveRiderList = async () => {
    setTableLoading(true);
    const resp = await dispatch(
      getAllRiderList(
        pageNo,
        pageSize,
        search,
        selectedCity,
        sortKey,
        sort,
        selectedTime
      )
    );
    if (resp) {
      setLoading(false);
      setExportData(resp.list);
      setTableLoading(false);
    }
  };

  const ridersList = useSelector((state) => state.rider.riderListData);
  // console.log(ridersList);

  const GetCity = async () => {
    const response = await dispatch(getCityData(search));
    // if (response) {
    //   setLoading(false);
    // }
  };
  let cityList = useSelector((state) => state?.city?.cityData);
  cityList = cityList?.sort((a, b) => a.name?.localeCompare(b.name));
  const { t } = useTranslation();

  const handleMultiDelete = async () => {
    setTableLoading(true);
    const resp = await dispatch(multiDeleteRiders({ rider_ids: selectedRows }));
    if (resp) {
      message.success("Riders deleted successfully");
      setSelectedRows([]);
      setTableLoading(false);
      reteriveRiderList();
    }
  };
  const handleSort = async (key) => {
    let sorting;
    setSortKey(key);
    if (sort === "ASC") {
      setSort("DSC");
      sorting = "DSC";
    } else {
      sorting = "ASC";
      setSort("ASC");
    }
    setTableLoading(true);
    // sort=sort=="ASC"?setSort("DSC"):setSort("ASC")

    const resp = await dispatch(
      getAllRiderList(
        1,
        pageSize,
        search,
        selectedCity,
        key,
        sorting,
        selectedTime
      )
    );
    if (resp) {
      setTableLoading(false);
    }
  };
  const handleSelectTime = async (value) => {
    let val;
    if (value) val = value.format("YYYY-MM-DD");
    else {
      val = "";
    }
    setSelectedTime(val);
    setPageNo(1);

    const resp = await dispatch(
      getAllRiderList(1, pageSize, search, selectedCity, sortKey, sort, val)
    );
    if (resp) {
      setTableLoading(false);
    }
  };

  const handleEditStatus = async (value, id) => {
    const displayText = value === true ? "activate" : "deactivate";

    const text = value === true ? "verified" : "deactivated";
    Modal.confirm({
      title: `Are you sure to ${displayText} rider`,
      okText: "Yes",
      onOk: () => {
        handleActivateDriver(id, text);
      },
    });
  };

  const handleActivateDriver = async (riderId, text) => {
    console.log("riderId on page", riderId, text);
    setTableLoading(true);
    const resp = await dispatch(deactivateDriver(riderId, text));
    if (resp) {
      await dispatch(
        getAllRiderList(
          pageNo,
          pageSize,
          search,
          selectedCity,
          sortKey,
          sort,
          selectedTime
        )
      );
      message.success(
        text == "verified"
          ? "Rider Activated successfully"
          : "Rider Deactivated successfully"
      );
      setTableLoading(false);
    }
  };

  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },
    {
      title: "Name",
      render: (data, record) => {
        return <span>{data?.name ? data?.name : "-"}</span>;
      },
    },

    // {
    //   title: "Email Address",
    //   render: (data) => {
    //     return <span>{data?.email ? data?.email : "-"}</span>;
    //   },
    // },
    {
      title: t("phoneNo"),
      render: (data) => {
        return <span>{data?.number ? data?.number : "-"}</span>;
      },
    },
    {
      title: "Average Rating",
      render: (data) => {
        return (
          <>
            <Rate value={data.average_rating} allowHalf disabled />{" "}
            <span style={{ color: "#ccc" }}>
              ({parseFloat(data.average_rating).toFixed(1)})
            </span>
          </>
        );
      },
    },
    {
      title: "City",
      render: (data, record) => {
        return <span>{data?.city_id?.name ? data?.city_id?.name : "-"}</span>;
      },
    },
    {
      title: "Total Rides",
      render: (data, record) => {
        return <span>{data?.totalTrips}</span>;
      },
    },
    {
      title: "Current Status",
      // dataIndex: "duty_status",
      key: "duty_status",
      render: (status) => {
        return (
          <Tooltip
            title={
              status?.rideStatus == "in_ride"
                ? `Rider is ${status?.rideStatus} you can't change status`
                : ""
            }
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="DeActive"
              style={{
                backgroundColor:
                  status.status !== "deactivated" ? "green" : "grey",
              }}
              disabled={status?.rideStatus == "in_ride"}
              checked={status.status !== "deactivated" ? true : false}
              onChange={(e) => {
                handleEditStatus(e, status._id);
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      title: (
        <div>
          Joined Date
          <Button
            style={{ background: "none", border: "none" }}
            onClick={() => handleSort("created_at")}
          >
            <SwapOutlined rotate={90} style={{ color: "black" }} />
          </Button>
        </div>
      ),
      render: (data, record) => {
        const date = new Date(data?.created_at);
        return (
          <span>{data?.created_at ? date.toLocaleDateString() : "-"}</span>
        );
      },
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    // },
    {
      title: (
        <div>
          Last Login{" "}
          <Button
            style={{ background: "none", border: "none" }}
            onClick={() => handleSort("login_time")}
          >
            <SwapOutlined rotate={90} style={{ color: "black" }} />
          </Button>
        </div>
      ),
      key: "lastLogin",
      render: (data, index1, index) => {
        // console.log(data.login_time,index1);
        const date = new Date();
        if (data.login_time) {
          const date2 = new Date(data.login_time);
          const difference = date.getTime() - date2.getTime();
          const differenceInMinutes = difference / (1000 * 60);
          const differenceInHours = difference / (1000 * 60 * 60);
          const differenceInDays = difference / (1000 * 60 * 60 * 24);

          if (differenceInMinutes < 60) {
            return (
              <span>
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInMinutes)} minutes ago
                </Tooltip>
              </span>
            );
          } else if (differenceInHours < 24) {
            return (
              <span>
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInHours)} hours ago
                </Tooltip>
              </span>
            );
          } else {
            return (
              <span>
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInDays)} days ago
                </Tooltip>
              </span>
            );
          }
        } else {
          return <span>-</span>;
        }
      },
    },
    // {
    //   title: t("action"),
    //   dataIndex: "",
    //   key: "x",
    //   render: (record, target) => {
    //     return (
    //       <>
    //         <Button
    //           type="primary"
    //           onClick={() => navigate(`manageRider/rider/${record._id}`)}
    //         >
    //           <EyeOutlined style={{ marginRight: 5 }} />

    //           <strong>View</strong>
    //         </Button>
    //       </>
    //     );
    //   },
    // },
  ];
  const handleSearch = async (value) => {
    setSearchCity(value);
    await dispatch(getCityData(value));
  };
  const [showExport, setshowExport] = useState(false);
  const [exportData, setExportData] = useState(ridersList?.list);
  const [fileType, setFileType] = useState("");
  const modifiedExportData =
    exportData &&
    exportData.map((item, index) => ({
      "Sr. No.": index + 1,
      Name: item.name || "-",
      "Mobile No.": item?.number || "-",
      "Joined Date": item?.created_at || "-",
      // cityName: item?.city_id?.name || "-",
      // EmailAddress: item?.email || "-",
      Ratings: item?.average_rating || "-",
      // DutyStatus: item?.duty_status || "-",
    }));
  const handleExport =
    exportData && ExportReports(modifiedExportData, fileType, "Riders Report");

  let selectedData = [];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log("selected rows");
      setExportData(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      selectedData.push(selectedRowKeys);
      setSelectedRows(...selectedData);
      setSmsNotificationData([...selectedRows]);
      setSmsNotificationDataOrg([...selectedRows]);
    },
    // getCheckboxProps: (record) => ({
    //   disabled: record.name === 'Disabled User',
    //   // Column configuration not to be checked
    //   name: record.name,
    // }),
  };
  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    // await dispatch(getAdminData(searchValue, page, pageSizes));
    await dispatch(
      getAllRiderList(
        page,
        pageSizes,
        searchValue,
        selectedCity,
        sortKey,
        sort,
        selectedTime
      )
    );
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    // await dispatch(getAdminData(searchValue, pageNo, pageSizes));
    await dispatch(
      getAllRiderList(
        current,
        pageSizes,
        searchValue,
        selectedCity,
        sortKey,
        sort,
        selectedTime
      )
    );
  };

  const handleSelectCity = async (value) => {
    setSelectedCity(value);
    if (searchCity) {
      setSearchCity("");
      await dispatch(getCityData(""));
    }
    await dispatch(
      getAllRiderList(
        1,
        pageSize,
        searchValue,
        value,
        sortKey,
        sort,
        selectedTime
      )
    );
  };
  const handleSearchFilter = async (value) => {
    setSearchValue(value);
    await dispatch(
      getAllRiderList(
        1,
        pageSize,
        value,
        selectedCity,
        sortKey,
        sort,
        selectedTime
      )
    );
  };
  return (
    <>
      <div className="common_layout content-container">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">{t("rider")}</h1>
            <BreadCrumb />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            {/* {user?.role == SUPERADMIN && (
              <Button
                style={{ float: "right" }}
                type="danger"
                // disabled={selectedRows.length == 0 ? true : false}
                disabled
                onClick={handleMultiDelete}
              >
                {" "}
                <DeleteOutlined /> Delete
              </Button>
            )}
            <br/>
            <br/> */}

            {user?.role == SUPERADMIN && (
              <Button
                style={{ float: "right", marginLeft: "20px" }}
                type="primary"
                className="ADD"
                disabled={selectedRows.length === 0 ? true : false}
                // onClick={handleMultiDelete}
                onClick={() => {
                  setModalOpen(true);
                  setModalTitle("Send Notification");
                  setModalkey("notification");
                }}
              >
                {" "}
                <BellOutlined /> Notification
              </Button>
            )}
            {user?.role == SUPERADMIN && (
              <Button
                style={{ float: "right" }}
                type="primary"
                className="ADD"
                disabled={selectedRows.length === 0 ? true : false}
                // onClick={handleMultiDelete}
                onClick={() => {
                  setModalOpen(true);
                  setModalTitle("Send SMS");
                  setModalkey("sms");
                }}
              >
                {" "}
                <MessageOutlined /> SMS
              </Button>
            )}
          </Col>
        </Row>
        <Divider />

        {isLoading & isLoadingCity ? (
          <Spinner />
        ) : (
          <>
            <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col
                  xxl={6}
                  xl={7}
                  lg={6}
                  md={8}
                  sm={12}
                  xs={24}
                  className="total"
                >
                  Total {ridersList?.total_counts}
                </Col>
                {user?.role != "super_admin" && (
                  <Col xxl={8} xl={6} lg={5} md={0} sm={0} xs={0}></Col>
                )}
                <Col xxl={4} xl={5} lg={6} md={8} sm={12} xs={24}>
                  <Input
                    placeholder="Search By Name or Email"
                    style={{ width: "100%" }}
                    prefix={<SearchOutlined />}
                    value={searchValue}
                    onChange={(event) => handleSearchFilter(event.target.value)}
                  />
                </Col>
                {user?.role == "super_admin" && (
                  <Col xxl={4} xl={5} lg={5} md={8} sm={12} xs={24}>
                    <Select
                      style={{
                        width: "100%",
                        maxHeight: "45px",
                        overflowY: "auto",
                        marginTop: "6px",
                      }}
                      placeholder="Search By City"
                      showSearch
                      mode="multiple"
                      filterOption={false}
                      onSearch={(e) => handleSearch(e)}
                      allowClear
                      onChange={handleSelectCity}
                    >
                      {cityList &&
                        cityList.length > 0 &&
                        cityList.map((data) => {
                          return (
                            <Select.Option
                              value={data._id}
                              className="selectOptions"
                              key={data._id}
                            >
                              {data.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Col>
                )}

                <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                  <DatePicker
                    showToday={false}
                    style={{
                      borderRadius: 0,

                      width: "100%",
                    }}
                    placeholder={"Last Login Date"}
                    onChange={(e) => handleSelectTime(e)}
                    format={"DD/MM/YYYY"}
                    disabledDate={(current) => current.isAfter(moment())}
                  />
                </Col>
                {user?.role == SUPERADMIN && (
                  <Col xxl={4} xl={6} lg={6} md={8} sm={12} xs={24}>
                    <Select
                      placeholder="Export Report"
                      style={{
                        width: "100%",
                      }}
                      value={null}
                      onSelect={(value) => {
                        // setShowReportModal(true);
                        setShowVerify(true);
                        // deleteKeys(value);
                        setDocType(value);
                      }}
                    >
                      <Select.Option value="csv" className="selectOptions">
                        <span>
                          <FileOutlined /> CSV
                        </span>
                      </Select.Option>
                      <Select.Option value="excel" className="selectOptions">
                        <span>
                          <FileExcelOutlined /> Excel (XLSX)
                        </span>
                      </Select.Option>
                      <Select.Option value="pdf" className="selectOptions">
                        <span>
                          <FilePdfOutlined /> Pdf
                        </span>
                      </Select.Option>
                    </Select>
                  </Col>
                )}
              </Row>
            </Card>
            <Card className="shadowCardBox">
              {/* <TopPagination>
                <p className="total">Total {ridersList?.total_counts}</p> */}
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                rowSelection={
                  user.role == SUPERADMIN && {
                    type: Checkbox,
                    ...rowSelection,
                  }
                }
                rowKey={"_id"}
                dataSource={ridersList?.list}
                loading={tableLoading}
                onRow={(record) => {
                  return {
                    onDoubleClick: () => {
                      navigate(`rider/${record._id}`);
                    },
                  };
                }}
                pagination={{
                  showSizeChanger: true,
                  pageSize: pageSize,
                  total: ridersList?.total_counts,
                  onShowSizeChange: onShowSizeChange,
                  onChange: onPageChange,
                  pageSizeOptions: ["50", "100", "500"],
                }}
                columns={columns}
                searchable
                scroll={{ x: "45vh" }}
              />
              {/* </TopPagination> */}
            </Card>
          </>
        )}
      </div>

      <ReportModal
        showReportModal={showReportModal}
        setShowReportModal={setShowReportModal}
        modifiedExportData={modifiedExportData}
        docType={docType}
        reportKeys={reportKeys}
        docName={docName}
      />
      <VerifyOtpModal
        showVerify={showVerify}
        setShowVerify={setShowVerify}
        setShowReportModal={setShowReportModal}
        type={"Rider"}
      />
      <SmsNotificationModal
        modalOpen={modalOpen}
        modalTitle={modalTitle}
        setModalOpen={setModalOpen}
        smsNotificationData={smsNotificationData}
        setSmsNotificationData={setSmsNotificationData}
        smsNotificationDataOrg={smsNotificationDataOrg}
        modalKey={modalKey}
        role={"rider"}
      />
    </>
  );
};

export default ManageRider;
