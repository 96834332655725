import { Button, Collapse, DatePicker, Rate, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { SearchOutlined, SwapOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { notificationsList } from "../../../../redux/SmsNotification/actionCreatore";
// import { getRidersRatingByDriverId } from "../../../../redux/NotificationList/actionCreatore";
const { Panel } = Collapse;
function NotificationList({ id, role }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { driverId } = useParams();
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showExtra, setShowExtra] = useState(true);
  const [sort, setSort] = useState("DSC");

  let params = {
    pageNo,
    pageSize: limit,
    search: "",
    role: role,
    key: "created_at",
    sort: "DSC",
    userId: id,
  };

  useEffect(() => {
    reteriveNotificationList(params);
  }, []);
  const reteriveNotificationList = async (params) => {
    setLoading(true);
    const resp = await dispatch(notificationsList(params));
    if (resp) {
      setLoading(false);
    }
  };
  const notificationList = useSelector(
    (state) => state.smsNotification.notificationList
  );

  const handleSort = () => {
    let sorting;
    if (sort === "ASC") {
      setSort("DSC");
      sorting = "DSC";
    } else {
      sorting = "ASC";
      setSort("ASC");
    }
    params.sort = sorting;
    setPageNo(1);
    params.pageNo = 1;
    reteriveNotificationList(params);
  };

  const handlePageChange = (page, pageSize) => {
    setPageNo(page);
    setLimit(pageSize);
    params.pageNo = page;
    params.pageSize = pageSize;
    reteriveNotificationList(params);
  };

  const notificationColumns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * limit,
    },
    {
      title: "Type",
      key: "Type",
      render: (data) => {
        return (
          <span>
            {data.type
              ? data.type.charAt(0).toUpperCase() + data.type.slice(1)
              : "-"}
          </span>
        );
      },
    },
    {
      title: "Title",
      key: "Title",
      render: (data) => {
        return <span>{data.title ? data.title : "-"}</span>;
      },
    },
    {
      title: "Description",
      key: "message",
      render: (data) => {
        return <span>{data.message ? data.message : "-"}</span>;
      },
    },

    {
      title: (
        <div>
          TimeStamp
          <Button
            style={{ background: "none", border: "none" }}
            onClick={() => handleSort("created_at")}
          >
            <SwapOutlined rotate={90} style={{ color: "black" }} />
          </Button>
        </div>
      ),
      // dataIndex: "date",
      key: "date",
      render: (data) => {
        return (
          <span>
            {data.created_at ? new Date(data.created_at).toLocaleString() : "-"}
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <Collapse
        style={{ border: "none" }}
        defaultActiveKey={"1"}
        onChange={(e) => setShowExtra(e.length == 0 ? false : true)}
      >
        <Panel
          header={`Notifications | Total ${notificationList?.total_counts}`}
          key="1"
          style={{
            background: "#00000047",
            // borderRadius: 10,
            fontWeight: 500,
          }}
          // extra={
          //   loading == false &&
          //   showExtra && (
          //     <div
          //       onClick={(event) => event.stopPropagation()}
          //       style={{ display: "flex" }}
          //     >
          //       <Select
          //         placeholder="Filter By Type"
          //         style={{
          //           width: 200,
          //           marginRight: "10px",
          //           float: "right",
          //           background: "white",
          //         }}
          //         // onSelect={(value) => handleSelectStatus(value)}
          //         // defaultValue={selectedStatus}
          //       >
          //         <Select.Option value="">All</Select.Option>
          //         <Select.Option value="wallet">Wallet</Select.Option>
          //         <Select.Option value="discount">Discount</Select.Option>
          //         <Select.Option value="payment">Payment</Select.Option>
          //         <Select.Option value="ride">Ride</Select.Option>
          //         <Select.Option value="account">Account</Select.Option>
          //         <Select.Option value="document">Document</Select.Option>
          //       </Select>
          //       <Select
          //         placeholder="Filter By Status"
          //         style={{
          //           width: 200,
          //           marginRight: "10px",
          //           float: "right",
          //           background: "white",
          //         }}
          //         // onSelect={(value) => handleSelectStatus(value)}
          //         // defaultValue={selectedStatus}
          //       >
          //         <Select.Option value="">All</Select.Option>
          //         <Select.Option value="failed">Failed</Select.Option>
          //         <Select.Option value="success">Success</Select.Option>
          //       </Select>
          //     </div>
          //   )
          // }
        >
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            dataSource={notificationList?.list}
            columns={notificationColumns}
            scroll={{ x: 10 }}
            loading={loading}
            pagination={{
              showSizeChanger: true,
              pageSize: limit,
              total: notificationList?.total_counts,
              // onShowSizeChange: onShowSizeChange,
              onChange: handlePageChange,
              pageSizeOptions: ["50", "100", "200"],
            }}
          />
        </Panel>
      </Collapse>
    </div>
  );
}

export default NotificationList;
