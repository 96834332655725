const actions = {
  GET_MAP_STATISTICS_LIST: "GET_MAP _STATISTICS_LIST",
  GET_MAP_API_TYPES: "GET_MAP_API_TYPES",

  GET_MAP_STATISTICS_COUNTS: "GET_MAP_STATISTICS_COUNTS",

  getMapStatisticsList: (data) => {
    return {
      type: actions.GET_MAP_STATISTICS_LIST,
      data,
    };
  },
  getMapApiTypeList: (data) => {
    return {
      type: actions.GET_MAP_API_TYPES,
      data,
    };
  },
  getMapStatisticsCounts: (data) => {
    return {
      type: actions.GET_MAP_STATISTICS_COUNTS,
      data,
    };
  },
};

export default actions;
