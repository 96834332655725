import actions from "./actions";

const {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_RIDE_COUNT_DATA,
  GET_DASHBOARD_RIDE_CITY_COUNT_DATA,
  GET_DASHBOARD_RIDE_CAPTAIN_COUNT_DATA,
  GET_DASHBOARD_RIDE_DRIVER_COUNT_DATA,
  GET_DASHBOARD_RECENT_TRANSACTIONS,
} = actions;

const initState = {
  dashboardData: {},
  ridesRequestCount: {},
  ridesRequestCityCount: {},
  ridesRequestCaptainsCount: {},
  ridesRequestDriversCount: {},
  recentTransaction: {},
};

const dashboardReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: data,
      };
    case GET_DASHBOARD_RIDE_COUNT_DATA:
      return {
        ...state,
        ridesRequestCount: data,
      };
    case GET_DASHBOARD_RIDE_CITY_COUNT_DATA:
      return {
        ...state,
        ridesRequestCityCount: data,
      };
    case GET_DASHBOARD_RIDE_CAPTAIN_COUNT_DATA:
      return {
        ...state,
        ridesRequestCaptainsCount: data,
      };
    case GET_DASHBOARD_RIDE_DRIVER_COUNT_DATA:
      return {
        ...state,
        ridesRequestDriversCount: data,
      };
    case GET_DASHBOARD_RECENT_TRANSACTIONS:
      return {
        ...state,
        recentTransaction: data,
      };
    default:
      return state;
  }
};
export default dashboardReducer;
