import React from "react";

const TermAndConditionDriver = () => {
  return (
    <>
      <div style={{ marginTop: "5vh" }}>
        <p
          style={{ fontWeight: "bold", fontSize: "24px", marginBottom: "8px" }}
        >
          Terms & Conditions
        </p>

        <p>
          <b>
            This document is an electronic record in terms of Information
            Technology Act, 2000 and rules there under as applicable and the
            amended provisions pertaining to electronic records in various
            statutes as amended by the Information Technology Act, 2000. This
            electronic record is generated by a computer system and does not
            require any physical or digital signatures.
          </b>
        </p>
        <p>
          <b>
            This document is published in accordance with the provisions of Rule
            3 (1) of the Information Technology (Intermediaries guidelines)
            Rules, 2011 that require publishing the rules and regulations, and
            Terms of Use for access or usage of oneway.cab website and its app.
          </b>
        </p>

        <p style={{ fontSize: "24px", marginBottom: "8px" }}>
          Site and Contents
        </p>
        <p>
          The content of the pages of this website is for your general
          information and use only. It is subject to change without prior
          notice. Neither we nor any third parties provide any warranty or
          guarantee as to the accuracy, timeliness, performance, completeness or
          suitability of the information and materials found or offered on this
          website for any particular purpose. You acknowledge that such
          information and materials may contain inaccuracies or errors and we
          expressly exclude liability for any such inaccuracies or errors to the
          fullest extent permitted by law. Your use of any information or
          materials on this website is entirely at your own risk, for which we
          shall not be liable. It shall be your own responsibility to ensure
          that any products, services or information available through this
          website meet your specific requirements.
        </p>

        <p style={{ fontSize: "24px", marginBottom: "8px" }}>Ownership</p>
        <p>
          This website contains material which is owned by or licensed to Baroda
          Taxi Cabs Pvt Ltd, 419, GANGOTRI ICON, OPP GOKUL PARTY PLOT, VASNA
          ROAD, VADODARA - 390007, Gujarat, India. This material includes, but
          is not limited to, the design, layout, look, appearance and graphics.
          Reproduction is prohibited other than in accordance with the copyright
          notice, which forms part of these terms and conditions.
        </p>
        <p>
          All trademarks reproduced in this website which are not the property
          of, or licensed to, the operator are acknowledged on the website.
        </p>
        <p>
          Unauthorized use of this website may give rise to a claim for damages
          and/or be a criminal offense.
        </p>
        <p>
          From time to time this website may also include links to other
          websites. These links are provided for your convenience to provide
          further information. They do not signify that we endorse the
          website(s). We have no responsibility for the content of the linked
          website(s).
        </p>
        <p>
          You may not create a link to this website from another website or
          document without oneway.cab prior written consent. Your use of this
          website and any dispute arising out of such use of the website is
          subject to the laws of India or other regulatory authority.
        </p>

        <p style={{ fontSize: "24px", marginBottom: "8px" }}>User Content</p>
        <p>
          You are prohibited from posting defamatory, obscene, pornographic,
          profane, threatening or unlawful material in our forums or our user
          feedback sections.
        </p>
        <p>
          oneway.cab will fully cooperate with any law enforcement authorities
          or court order requesting or directing oneway.cab to disclose the
          identity of anyone posting such materials.
        </p>
        <p>
          oneway.cab may change, edit, or remove any user material or
          conversations that are illegal, indecent, obscene or offensive, or
          that violates oneway.cab policies in any way.
        </p>
        <p>
          Booking can be made online by filling up the booking form provided
          here. Payment can be made through any of the credit/debit cards listed
          here. Booking can be made for any person other than cardholder also.
          For that you need to fill up details of cardholder for payment and
          details of user for booking. The use of credit card is totally safe,
          we use PayU payment gateway for payment. PayU has a 128 bits SSL
          encryption technology compliant solution certified by VeriSign. Your
          card details are not shared.
        </p>
        <p>
          <ol>
            <li>
              A booking voucher will be sent to the traveler over email when he
              completes an online car booking with Oneway Cab.
            </li>
            <li>
              Producing the booking voucher provided earlier is mandatory to
              avail the car rental services by Oneway Cab.If this is not done it
              entitles the driver to refuse service delivery without any
              liability on us to refund the booking amount.
            </li>
            <ii>
              When a customer produces the booking voucher provided to him it
              earlier, it implies that he have read and understood all terms and
              conditions mentioned herewith and agrees on all of them.
            </ii>
            <li>
              Duty slip has to be filled by the customer, if this has not been
              done by the customer no disputes will be entertained later. The
              start and end distance in Kms will be calculated from point of
              pick-up to point of destination. Driving speed of the vehicle as
              per the directions of the local transport authority only. Parking
              charges, toll charges, state taxes and entry fees etc. to be paid
              by the customer.
            </li>
            <li>
              Unless specified otherwise, all meter readings/distances/timings
              will be calculated to and fro from point of pick-up to point of
              destination.
            </li>
            <li>
              Distances between two locations (points) be it an intercity
              distance etc mentioned on the website are approximate. Actual
              distance may differ depending on the exact geographical location
              of the points under consideration.
            </li>
            <li>
              Car air-conditioner will not work on hilly routes & also when the
              vehicle is not in motion. The customer is also not expected to
              request driver to make the air condition operational in the afore
              mentioned cases.
            </li>
            <li>
              The voucher is mandatory to avail any applicable discounts (or) in
              the case of a cashback. In case of a cash back, the amount will be
              credited to your credit card/debit card account from which the
              payment was made to Oneway Cab. in approximately 15 days from the
              pick up date.
            </li>
            <li>
              Any form of discounts are only applicable only on the basic fare
              and cannot be made available on extra charges like toll, taxes,
              charges on for extra Kms consumed if applicable, charges for extra
              hours consumed if applicable, driver allowance, parking, night
              charges, unless specified.
            </li>
            <li>
              The vehicle booked will be subject to availability. In a case
              where the car booked is not available on the day of travel the
              customer will be provided with an alternative equivalent vehicle
              in the similar segment as applicable.
            </li>
          </ol>
          <p style={{ fontSize: "24px", marginBottom: "8px" }}>
            Cancellation policies:
          </p>
          <p>
            <ol>
              <li>
                If a traveler cancels 24 hours prior the pick-up time, 10% of
                the booked amount will be deducted.
              </li>
              <li>
                If a traveler cancels within 24 hours prior the pick-up time, no
                refund.
              </li>
              <li>
                The refunds will be made by online transfer to the customer's
                bank account. No cash refund requests will be entertained.
              </li>
              <li>
                The amount will be refunded approximately within 7 business
                days.
              </li>
            </ol>
          </p>

          <p style={{ fontSize: "24px", marginBottom: "8px" }}>
            Payments and Security
          </p>
          <p>
            <b>G-Auto </b> ensures that every transaction you conduct with us is
            in a safe and secure environment. We provide two payment options:
          </p>
          <p>
            <ol>
              <li>The PayTM wallet</li>
              <li>
                Payment through Debit cards, Credit cards, Net Banking, Payu
                Money Wallet via Payu Payment Gateway
              </li>
            </ol>
          </p>
          <p>
            Please keep the details of the card holder name, number, CVV and
            expiry date handy to carry out transactions. OAC/IPIN will also be
            required for credit and debit card transactions.
          </p>
          <p>
            Both PayTM and PayU that provide the wallet and payment gateway
            services are VeriSign Secured and PCI compliant. OneWay.Cab does not
            store any Credit Card, Debit Card Details.
          </p>

          <p style={{ fontSize: "24px", marginBottom: "8px" }}>
            Special Instructions:
          </p>
          <p>
            <ol>
              <li>
                Driver & Car details - These details will be sent to the
                customer by Oneway Cab. approximately one hour before the pick
                up time by SMS and email. In some cases the driver or an
                associate of Oneway Cab. may also call in advance to reconfirm
                with the customer.
              </li>
              <li>
                Non-receipt of driver and car details - In such cases the
                customer needs to contact the support team at Oneway Cab. on
                08000 247 247 and mention the booking confirmation number
                (Booking ID).
              </li>
              <li>
                Demand of money by the driver- The voucher provided to the
                customer clearly indicates the items including on his booking.
                Any amount over and above this both in terms for distance and
                duration and other items such as night charges, driver
                allowance, toll, taxes, state taxes, charges on for extra Kms
                consumed if applicable, charges for extra hours consumed if
                applicable parking charges etc which are not included in the
                booking amount have to be paid directly to the driver as
                mentioned below. In case of any dispute with the driver the
                customer should reach the support team of Oneway Cab. at 08000
                247 247 and mention the booking confirmation number (Booking ID)
                and mention the subject of dispute.
              </li>
              <li>
                Payment of extra charges - Any extra charges applicable has to
                be paid directly in cash to the driver.
              </li>
              <li>
                Refusal by the driver to deliver services - The customer to
                contact the support team at Oneway Cab. on 08000 247 247 and
                mention the booking confirmation number (Booking ID).
              </li>
              <li>
                We use text messages to inform guests who have used our services
                or have called at our helpline number 08000247247 of the latest
                OneWay.Cab updates.If you decide you no longer want to receive
                these updates, reply “STOP” to our helpline number 08000247247.
              </li>
              <li>
                It is expressly made clear to you hereby that OneWay.Cab does
                not own any taxis nor does it directly or indirectly employ any
                drivers for the taxis. Taxis and drivers are all supplied by
                Third Party Service Providers and OneWay.Cab disclaims any and
                all liability in respect of the drivers and the taxis alike.
              </li>
              <li>
                The quality of the transportation services requested through the
                use of the Service is entirely the responsibility of the Third
                Party Service Providers who ultimately provide such
                transportation services to you. OneWay.Cab promises its best
                endeavor to provide sufficient training, quality control
                procedures and processes to ensure best services by Third Party
                Service Providers. However, under no circumstance accepts
                liability in connection with and/or arising from the
                transportation services provided by the Third Party service
                providers or any acts, action, behavior, conduct, and/or
                negligence on the part of the Third Party service providers.
              </li>
              <br />
            </ol>
          </p>
        </p>
      </div>

      {/* </AuthWrapper> */}
    </>
  );
};

export default TermAndConditionDriver;
