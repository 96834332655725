const actions = {
  GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
  GET_DASHBOARD_RIDE_COUNT_DATA: "GET_DASHBOARD_RIDE_COUNT_DATA",
  GET_DASHBOARD_RIDE_CITY_COUNT_DATA: "GET_DASHBOARD_RIDE_CITY_COUNT_DATA",
  GET_DASHBOARD_RIDE_CAPTAIN_COUNT_DATA:
    "GET_DASHBOARD_RIDE_CAPTAIN_COUNT_DATA",
  GET_DASHBOARD_RIDE_DRIVER_COUNT_DATA: "GET_DASHBOARD_RIDE_DRIVER_COUNT_DATA",
  GET_DASHBOARD_RECENT_TRANSACTIONS: "GET_DASHBOARD_RECENT_TRANSACTIONS",

  getDashboard: (data) => {
    return {
      type: actions.GET_DASHBOARD_DATA,
      data,
    };
  },
  getRideCount: (data) => {
    return {
      type: actions.GET_DASHBOARD_RIDE_COUNT_DATA,
      data,
    };
  },
  getRideCountCities: (data) => {
    return {
      type: actions.GET_DASHBOARD_RIDE_CITY_COUNT_DATA,
      data,
    };
  },
  getRideCountCaptains: (data) => {
    return {
      type: actions.GET_DASHBOARD_RIDE_CAPTAIN_COUNT_DATA,
      data,
    };
  },
  getRideCountDrivers: (data) => {
    return {
      type: actions.GET_DASHBOARD_RIDE_DRIVER_COUNT_DATA,
      data,
    };
  },
  fetchRecentTransactions: (data) => {
    return {
      type: actions.GET_DASHBOARD_RECENT_TRANSACTIONS,
      data,
    };
  },
};

export default actions;
