import actions from "./actions";

const { GET_CANEL_BOOKING_REASONS } = actions;

const initState = {
  cancelBookingReasonsData: {},
  // newCityData: {},
};

const cancelBookingReasonsReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_CANEL_BOOKING_REASONS:
      return {
        ...state,
        cancelBookingReasonsData: data,
      };

    default:
      return state;
  }
};
export default cancelBookingReasonsReducer;
