import actions from "./actions";

const {
  GET_DRIVERCENTER_LIST,
  GET_DRIVERCENTER_BY_ID,
  GET_DRIVERCENTER_LIST_WITHOUT_PAGINATION,
} = actions;

const initState = {
  driverCenterListData: {},
  driverCenterData: {},
  driverCenterListDataWithoutPagination: {},
};

const driverCenterReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_DRIVERCENTER_LIST:
      return {
        ...state,
        driverCenterListData: data,
      };
    case GET_DRIVERCENTER_BY_ID:
      return {
        ...state,
        driverCenterData: data,
      };
    case GET_DRIVERCENTER_LIST_WITHOUT_PAGINATION:
      return {
        ...state,
        driverCenterListDataWithoutPagination: data,
      };
    default:
      return state;
  }
};
export default driverCenterReducer;
