import actions from "./actions";
import { message } from "antd";
import { API } from "../../../config/api";
import { DataService } from "../../../config/dataService/dataService";

const { getRatingTags } = actions;

export const getRatingTagsData = ({ role, status,rating }) => {
  return async (dispatch) => {
    let queryParamater =
   
      "?role=" +
      role ;
      if(status!==""){
        queryParamater=queryParamater+"&status="+status
      }
      if(rating!==""){
        queryParamater=queryParamater+"&rating="+rating
      }
    const resp = await DataService.get(
      API.ratingTags.get + queryParamater
    );

    if (!resp.data.error) {
      dispatch(getRatingTags(resp.data.data));
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const addRatingTags = (payload) => {
  return async () => {
    const resp = await DataService.post(API.ratingTags.add, payload);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const updateRatingTags = (payload, id) => {
  // console.log("update role payload", id, payload);
  return async (dispatch) => {
    const resp = await DataService.put(
      API.ratingTags.update + id,
      payload
    );
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const removeRatingTags = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.ratingTags.delete + id);
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
