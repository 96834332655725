import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Image,
  Rate,
  Row,
  Tag,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { H2, H3, H4, H5, H6 } from "../../../components/heading/style";
import {
  CAPTAIN,
  IMAGE_URL_PREFIX,
  SUPERADMIN,
} from "../../../config/dataService/dataService";
import {
  getBookingById,
  getInvoice,
} from "../../../redux/Bookings/actionCreatore";
import stop1 from "../../../static/img/ride/Group 239754.png";
import stop2 from "../../../static/img/ride/Group 239755.png";
import stop3 from "../../../static/img/ride/Group 239756.png";
import stop4 from "../../../static/img/ride/Group 239757.png";
import { FixedToNDecimals } from "../../../utility/commonFunction";
import { getItem } from "../../../utility/localStorageControl";
import BreadCrumb from "../../BreadCrumb.js/BreadCrumb";
import { SocketContext } from "../../socket/socketContext";
import coin from "./coin.png";
import pickup from "./pickupimage.png";
import Spinner from "../../loader/Spinner";
import { saveAs } from "file-saver";
import Processing from "../../ProcessModal/Processing";

// import { Document, Page } from 'react-pdf';
function BookingDetails(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const user = getItem("user_details");
  const socket = useContext(SocketContext);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const stops = [stop1, stop2, stop3, stop4];
  const [fare, setFare] = useState([
    {
      type: "tripFare",
      total: 0,
    },
    {
      type: "gauto",
      amount: 0,
      total: 0,
      tax: 0,
    },
    {
      type: "faciliTyFee",
      amount: 0,
      total: 0,
      tax: 0,
    },
    {
      type: "captainFee",
      amount: 0,
      total: 0,
      tax: 0,
    },
  ]);
  useEffect(() => {
    reteriveById();
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (socket) {
      socket.emit("readRecord", {
        userId: user._id,
        recordType: "rides_request",
        rideId: id,
      });
      socket.on("recordReaded", (data) => {
        if (data.isRead) {
          socket.emit("getUnreadRecordCount", { userId: user._id });
          socket.on("unreadRecordCount", (data) => {});
        }
      });
    }
  }, [socket]);
  const reteriveById = async () => {
    setLoading(true);
    const resp = await dispatch(getBookingById(id));
    if (resp) {
      setLoading(false);
    }
  };
  let bookingList = useSelector((state) => state.booking.bookingDataById);
  const downloadInvoice = async () => {
    setInvoiceLoading(true);
    const resp = await dispatch(getInvoice(id));
    if (resp) {
      setInvoiceLoading(false);
      const pdfBlob = await new Blob([resp.data], { type: "application/pdf" });
      saveAs(pdfBlob, `${bookingList?.tripId}Invoice.pdf`);
    }
  };
  useEffect(() => {
    let charges = [
      {
        type: "tripFare",
        total: 0,
      },
      {
        type: "gauto",
        amount: 0,
        total: 0,
        tax: 0,
      },
      {
        type: "faciliTyFee",
        amount: 0,
        total: 0,
        tax: 0,
      },
      {
        type: "captainFee",
        amount: 0,
        total: 0,
        tax: 0,
      },
    ];
    bookingList.additional_charges?.map((charge) => {
      // if (charge.type == "rideBaseFare") {
      //   charges[0].total += charge.total_amount;
      // }
      // if (charge.type == "rideFareByTime") {
      //   charges[0].total += charge.total_amount;
      // }
      if (charge.type == "facilityFee") {
        charges[2].total = charge.total_amount;
        charges[2].amount = charge.amount;
        charges[2].tax = charge.total_tax_amount;
      } else if (charge.type == "captainCharges") {
        charges[3].total = charge.total_amount;
        charges[3].amount = charge.amount;
        charges[3].tax = charge.total_tax_amount;
      } else if (charge.type == "adminCommission") {
        charges[1].total = charge.total_amount;
        charges[1].amount = charge.amount;
        charges[1].tax = charge.total_tax_amount ? charge.total_tax_amount : 0;
      } else {
        charges[0].total += charge.total_amount;
      }
    });
    setFare(charges);
  }, [bookingList]);
  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="common_layout content-container ">
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h1 className="heading">Booking Details</h1>
          {/* <h1 className="heading">Manage Booking</h1> */}
          <BreadCrumb detailpage={id} />
        </Col>
        {/* {user?.role === CAPTAIN && (
          <Col xxl={12} lg={10}>
            <Card
              style={{ marginBottom: 10 }}
              bodyStyle={{ justifyContent: "center", display: "flex" }}
              className="shadowCardBox totalCard"
            >
              <h1 style={{ fontSize: "20px" }}>
                <strong>{"Captain Commission : "}</strong>
                <span>
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWGD3_-5BftdXq3w5p5qaUQuN8ISxUwxIJqw&usqp=CAU"
                    style={{ width: "20px", height: "18px" }}
                    alt="rupeeicon"
                  />{" "}
                  {bookingList?.captainCommission?.toFixed(2)}
                </span>
              </h1>
            </Card>
          </Col>
        )} */}
      </Row>
      <Divider />
      <Row gutter={20}>
        {/* <Col xxl={6} lg={6}>
                  <strong>Total </strong>
                  {bookingList.total_counts}
                </Col> */}
        {/* {user?.role === SUPERADMIN && ( */}
        <>
          <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
            <Card
              bodyStyle={{ justifyContent: "center", display: "flex" }}
              className="shadowCardBox totalCard"
            >
              <h1 style={{ fontSize: "20px" }}>
                <strong>{"G-Auto Commission : "}</strong>
                <span>
                  <span style={{ color: "green", fontSize: "20px" }}>
                    {" "}
                    {"₹ "}
                  </span>
                  {/* <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWGD3_-5BftdXq3w5p5qaUQuN8ISxUwxIJqw&usqp=CAU"
                      style={{ width: "20px", height: "18px" }}
                    /> */}
                  {bookingList?.adminCommission}
                </span>
              </h1>
            </Card>
            <br />
          </Col>
          <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
            <Card
              bodyStyle={{ justifyContent: "center", display: "flex" }}
              className="shadowCardBox totalCard"
            >
              <h1 style={{ fontSize: "20px" }}>
                <strong>{"Facility Fee : "}</strong>
                <span>
                  <span style={{ color: "green", fontSize: "20px" }}>
                    {" "}
                    {"₹ "}
                  </span>
                  {/* <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWGD3_-5BftdXq3w5p5qaUQuN8ISxUwxIJqw&usqp=CAU"
                      style={{ width: "20px", height: "18px" }}
                    /> */}
                  {bookingList?.facilityFee?.toFixed(2)}
                </span>
              </h1>
            </Card>
            <br />
          </Col>
          <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
            <Card
              bodyStyle={{ justifyContent: "center", display: "flex" }}
              className="shadowCardBox totalCard"
            >
              <h1 style={{ fontSize: "20px" }}>
                <strong>{"Captain Commission : "}</strong>
                <span>
                  <span style={{ color: "green", fontSize: "20px" }}>
                    {" "}
                    {"₹ "}
                  </span>
                  {bookingList?.captainCommission?.toFixed(2)}
                </span>
              </h1>
            </Card>
            <br />
          </Col>
        </>
        {/* // )} */}
      </Row>
      <div
        style={{
          // justifyContent: "center",
          //   textAlign: "center",
          marginBottom: "20px",
        }}
      >
        <Card>
          <Row >
            {bookingList?.status === "pending" ? (
              <Col
                lg={6}
                className="margin_auto"
                style={{ fontSize: "18px", fontWeight: 800 }}
              >
                Waiting For Driver To Accept Ride...
              </Col>
            ) : (
              bookingList?.driver_id && (
                <Col lg={5} md={12} sm={4} xs={2} >
                  <Row justify={"center"}>
                    <Col>
                      <Avatar
                        size={100}
                        icon={
                          bookingList &&
                          bookingList.driver_id?.profile_image ? (
                            <img
                              src={
                                IMAGE_URL_PREFIX +
                                bookingList.driver_id?.profile_image
                              }
                              style={{ fontSize: "15px" }}
                              alt={"Driver"}
                              // crossOrigin="anonymous"
                            />
                          ) : (
                            <UserOutlined />
                          )
                        }
                      />
                    </Col>
                    <Col className="marginVerticle_auto">
                      <H6 style={{ marginBottom: "10px", marginLeft: "30px" }}>
                        Driver:- {bookingList?.driver_id?.name}
                      </H6>
                      <span className="timing" style={{ marginLeft: "25px" }}>
                        {bookingList?.driver_id?.number}
                      </span>
                    </Col>
                  </Row>
                </Col>
              )
            )}
            <Divider
              type="vertical"
              style={{ height: "100px", marginRight: "20px" }}
            />
            <Col lg={6} md={11} sm={4} xs={6}  >
              <Row justify={"center"}>
                <Col  >
                  <Avatar
                    size={100}
                    icon={
                      bookingList && bookingList.rider_id?.profile_image ? (
                        <img
                          src={
                            IMAGE_URL_PREFIX +
                            bookingList.rider_id?.profile_image
                          }
                          alt="riderImage"
                          // crossOrigin="anonymous"
                        />
                      ) : (
                        <UserOutlined />
                      )
                    }
                  />
                </Col>
                <Col className="marginVerticle_auto">
                  <H6 style={{ marginBottom: "10px", marginLeft: "30px" }}>
                    Rider: {bookingList?.rider_id?.name}
                  </H6>
                  <span className="timing" style={{ marginLeft: "25px" }}>
                    {bookingList?.rider_id?.number}
                  </span>
                  <div className="timing" style={{ marginLeft: "30px" }}>
                    Booked For : {bookingList?.booked_for}
                  </div>
                </Col>
              </Row>
            </Col>
            <Divider
              type="vertical"
              style={{ height: "100px", marginRight: "20px" }}
            />
            <Col lg={5} md={11} sm={4} xs={6} className="marginVerticle_auto" >
              <H2 style={{ marginTop: "17px" }}>Total Fare</H2>
              <span className="gautoCash">{`\u20B9${
                bookingList?.total_fare
                  ? FixedToNDecimals(bookingList?.total_fare)
                  : ""
              }`}</span>
            </Col>
            <Divider  
              type="vertical"
              style={{ height: "100px", marginRight: "20px" }}
            />
            <Col lg={5} md={11} sm={4} className="marginVerticle_auto">
              <H2 style={{ marginTop: "auto" }}>Booking Status</H2>

              <Tag
                style={{
                  width: "100px",
                  height: "30px",
                  // fontSize: "15px",
                  // margin: "auto",
                  lineHeight: "30px",
                }}
                color={
                  bookingList?.status
                    ? bookingList?.status?.toLowerCase() === "accepted"
                      ? "orange"
                      : bookingList?.status?.toLowerCase() === "completed"
                      ? "blue"
                      : bookingList?.status?.toLowerCase() === "pending"
                      ? "yellow"
                      : bookingList?.status?.toLowerCase() === "on-going"
                      ? "green"
                      : "red"
                    : "red"
                }
                key={bookingList?.status}
              >
                {bookingList?.status && bookingList?.status.toUpperCase()}
              </Tag>
            </Col>
          </Row>
        </Card>
      </div>

      <div style={{ justifyContent: "center", marginBottom: "20px" }}>
        <Card>
          <Row gutter={[16, 16]}>
            <Col xxl={7} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Row>
                <Col lg={12}>
                  <H5
                    style={{ marginBottom: "25px" }}
                    className="marginVerticle_auto"
                  >
                    Date And Time
                  </H5>
                </Col>
                <Col lg={12}>
                  <span
                    className="timing"
                    style={{ marginLeft: "25px", fontSize: "14px" }}
                  >
                    {moment(bookingList?.scheduled_time).format("DD/MM/YYYY")} ,
                    {moment(bookingList?.scheduled_time).format("HH:mm")}
                  </span>
                </Col>
              </Row>
              <Divider />

              <Row>
                <Col lg={12}>
                  <H5
                    style={{ marginBottom: "25px" }}
                    className="marginVerticle_auto"
                  >
                    Trip ID
                  </H5>
                </Col>
                <Col lg={12}>
                  <span
                    className="timing"
                    style={{ marginLeft: "25px", fontSize: "14px" }}
                  >
                    {bookingList?.tripId}
                  </span>
                </Col>
              </Row>
              <Divider />

              <Row>
                <Col style={{ zIndex: 10 }}>
                  <img
                    style={{ width: "30px" }}
                    src={pickup}
                    alt="pickupicon"
                  />
                </Col>
                <Col className="marginVerticle_auto">
                  <H5 style={{ marginLeft: "25px", marginTop: "auto" }}>
                    Pick Up Point
                  </H5>
                  <p
                    className="timing"
                    style={{
                      marginLeft: "10px",
                      fontSize: "14px",
                      width: "300px",
                    }}
                  >
                    {bookingList?.rideData?.legs[0].start_address}
                  </p>
                </Col>
              </Row>
              <Divider />
              {/* <img
                style={{
                  transform: "rotate(120deg) scaleX(-1)",
                  marginTop: "10px",
                  marginBottom: "-20px",
                  marginLeft: "-20px",
                  zIndex: -2,
                }}
                src={routeicon}
              /> */}
              {bookingList?.rideData?.legs?.length > 1 &&
                bookingList?.rideData?.legs.map((leg, index) => {
                  if (index > 1 && index < bookingList?.rideData?.legs?.length)
                    return (
                      <>
                        <Row>
                          <Col style={{ zIndex: 10 }}>
                            <img
                              style={{ width: "30px" }}
                              src={stops[index - 2]}
                              alt="stopageicon"
                            />
                          </Col>
                          <Col className="marginVerticle_auto">
                            <H5
                              style={{ marginLeft: "25px", marginTop: "0px" }}
                            >
                              Stoppage {index - 1}
                            </H5>
                            <p
                              className="timing"
                              style={{
                                marginLeft: "10px",
                                fontSize: "14px",
                                width: "300px",
                              }}
                            >
                              {bookingList?.rideData?.legs[index].start_address}
                            </p>
                          </Col>
                        </Row>
                        <Divider />
                      </>
                    );
                })}
              <Row>
                <Col style={{ zIndex: 10 }}>
                  <img style={{ width: "30px" }} src={pickup} alt="dropicon" />
                </Col>
                <Col className="marginVerticle_auto">
                  <H5 style={{ marginLeft: "25px", marginTop: "auto" }}>
                    Drop Off Point
                  </H5>
                  <p
                    className="timing"
                    style={{
                      marginLeft: "10px",
                      fontSize: "14px",
                      width: "300px",
                    }}
                  >
                    {bookingList?.isRouteUpdated
                      ? bookingList?.updateRouteDetails?.destination_location
                          ?.location_name
                      : bookingList?.rideData?.legs[
                          bookingList?.rideData?.legs?.length - 1
                        ].end_address}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col lg={24}>
                  <Button
                    style={{
                      background: "#FFEE4B",
                      width: "100%",
                      marginTop: "20px",
                    }}
                    onClick={() =>
                      navigate(`/${user?.role}/LiveTrip/${id}/map`)
                    }
                  >
                    Show in Map
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xxl={1} xl={1} lg={1} md={0} sm={0} xs={0}>
              <Divider type="vertical" style={{ height: "300px" }} />
            </Col>
            <Col
              xxl={7}
              xl={11}
              lg={11}
              md={24}
              sm={24}
              xs={24}
              style={{ marginLeft: "0px" }}
            >
              <H4>Vehicle Type</H4>
              <Image
                style={{ width: "50px" }}
                src={
                  bookingList &&
                  `${IMAGE_URL_PREFIX}${bookingList?.vehicle_details?.image}`
                }
                alt="Vehicleicon"
                // crossOrigin="anonymous"
              />
              <span
                className="timing"
                style={{ marginLeft: "25px", fontSize: "14px" }}
              >
                {bookingList?.vehicle_details?.name}
              </span>
              <span
                className="timing"
                style={{ marginLeft: "25px", fontSize: "14px" }}
              >
                {bookingList?.vehicle_details?.person_capacity} Seating Capacity
              </span>
              <span
                className="timing"
                style={{ marginLeft: "25px", fontSize: "14px" }}
              >
                {bookingList?.driver_id?.vehicle_number}
              </span>
              <Divider />
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  // src={data && `${IMAGE_URL_PREFIX}${data.image}`}
                  src={pickup}
                  style={{ width: "25px" }}
                  alt="pickupicon"
                  // crossOrigin="anonymous"
                />
                <span
                  className="timing"
                  style={{
                    marginLeft: "20px",
                    fontSize: "14px",
                  }}
                >
                  Trip Distance:
                  <strong style={{ marginLeft: "10px" }}>
                    {FixedToNDecimals(bookingList?.ride_distance)} km
                  </strong>
                </span>
              </div>
              <Divider />
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  // src={data && `${IMAGE_URL_PREFIX}${data.image}`}
                  src={coin}
                  style={{ width: "25px" }}
                  alt="tipicon"
                  // crossOrigin="anonymous"
                />
                <span
                  className="timing"
                  style={{
                    marginLeft: "20px",
                    fontSize: "15px",
                    // fontWeight: 700,
                  }}
                >
                  Tip:
                  <strong style={{ marginLeft: "20px" }}>{`\u20B9 ${
                    bookingList?.totalTip && bookingList?.totalTip
                  }`}</strong>
                </span>
              </div>

              {bookingList?.rating_rider_to_driver && (
                <>
                  <Divider />
                  <span className="timing" style={{ fontSize: "18px" }}>
                    Rider To Driver Rating
                    <Rate
                      style={{ marginLeft: "20px" }}
                      value={bookingList?.rating_rider_to_driver?.rating}
                      allowHalf
                      disabled
                    />
                  </span>
                </>
              )}
              {bookingList?.rating_driver_to_rider && (
                <>
                  <Divider />
                  <span className="timing" style={{ fontSize: "18px" }}>
                    Driver To Rider Rating
                    <Rate
                      style={{ marginLeft: "20px" }}
                      value={bookingList?.rating_driver_to_rider?.rating}
                      allowHalf
                      disabled
                    />
                  </span>
                </>
              )}
            </Col>

            <Col xxl={1} xl={0} lg={1} md={0} sm={0} xs={0}>
              <Divider type="vertical" style={{ height: "300px" }} />
            </Col>

            <Col xxl={8} xl={20} lg={20} md={24}>
              <Row justify={"space-evenly"}>
                <Col lg={10}>
                  <H5
                    style={{ marginBottom: "25px" }}
                    className="marginVerticle_auto"
                  >
                    Trip Fare
                  </H5>
                </Col>
                <Col lg={14}>
                  <span
                    className="timing"
                    style={{ marginLeft: "25px", fontSize: "16px" }}
                  >
                    {`\u20B9 ${FixedToNDecimals(fare[0]?.total)}`}
                  </span>
                </Col>
              </Row>
              <Divider />
              <Row justify={"space-evenly"}>
                <Col lg={24}>
                  <H5
                    style={{ marginBottom: "25px" }}
                    className="marginVerticle_auto"
                  >
                    Additional Charges
                  </H5>
                </Col>
              </Row>
              <Row justify={"space-evenly"}>
                <Col lg={10}>
                  <H6
                    style={{ marginBottom: "25px", fontWeight: 100 }}
                    className="marginVerticle_auto"
                  >
                    G-Auto Commission
                  </H6>
                </Col>
                <Col lg={14}>
                  <span
                    className="timing"
                    style={{ marginLeft: "25px", fontSize: "16px" }}
                  >
                    {`\u20B9 ${FixedToNDecimals(fare[1]?.total)} `}(
                    {`${FixedToNDecimals(fare[1]?.amount)} `}+
                    {` ${FixedToNDecimals(fare[1]?.tax)} Tax`})
                  </span>
                </Col>
              </Row>
              <Row justify={"space-evenly"}>
                <Col lg={10}>
                  <H6
                    style={{ marginBottom: "25px", fontWeight: 100 }}
                    className="marginVerticle_auto"
                  >
                    Facility Fee
                  </H6>
                </Col>
                <Col lg={14}>
                  <span
                    className="timing"
                    style={{ marginLeft: "25px", fontSize: "16px" }}
                  >
                    {`\u20B9 ${FixedToNDecimals(fare[2]?.total)} `}(
                    {`${FixedToNDecimals(fare[2]?.amount)} `}+
                    {` ${FixedToNDecimals(fare[2]?.tax)} Tax`})
                  </span>
                </Col>
              </Row>
              <Row justify={"space-evenly"}>
                <Col lg={10}>
                  <H6
                    style={{ marginBottom: "25px", fontWeight: 100 }}
                    className="marginVerticle_auto"
                  >
                    Captain Commission
                  </H6>
                </Col>
                <Col lg={14}>
                  <span
                    className="timing"
                    style={{ marginLeft: "25px", fontSize: "16px" }}
                  >
                    {`\u20B9 ${FixedToNDecimals(fare[3]?.total)} `}(
                    {`${FixedToNDecimals(fare[3]?.amount)} `}+
                    {` ${FixedToNDecimals(fare[3]?.tax)} Tax`})
                  </span>
                </Col>
              </Row>
              <Divider />

              <Row justify={"space-evenly"}>
                <Col lg={10}>
                  <H4
                    style={{ marginBottom: "25px" }}
                    className="marginVerticle_auto"
                  >
                    Total Fare
                  </H4>
                  <b style={{ color: "#757575" }}>
                    {" "}
                    (
                    {`Includes \u20B9 ${
                      fare[3].tax + fare[2].tax + fare[1].tax
                    }   Taxes`}
                    )
                  </b>
                </Col>
                <Col lg={14}>
                  <span
                    className="timing"
                    style={{ marginLeft: "25px", fontSize: "16px" }}
                  >
                    {`\u20B9 ${FixedToNDecimals(bookingList?.total_fare)}`}
                  </span>
                </Col>
              </Row>
              <Divider />

              <Row justify={"space-evenly"}>
                <Col lg={10}>
                  <H6
                    style={{ marginBottom: "25px" }}
                    className="marginVerticle_auto"
                  >
                    Payment Mode
                  </H6>
                </Col>
                <Col lg={14}>
                  <span
                    className="timing"
                    style={{ marginLeft: "25px", fontSize: "16px" }}
                  >
                    {bookingList?.payment_type?.toUpperCase()}
                  </span>
                </Col>
              </Row>
              <Divider />

              <Row justify={"space-evenly"}>
                <Col lg={10}>
                  <H6
                    style={{ marginBottom: "25px" }}
                    className="marginVerticle_auto"
                  >
                    Payment Status
                  </H6>
                </Col>
                <Col lg={14}>
                  <span
                    className="timing"
                    style={{ marginLeft: "25px", fontSize: "16px" }}
                  >
                    {bookingList?.paymentStatus?.toUpperCase()}
                  </span>
                </Col>
              </Row>
              <Divider />

              {bookingList?.status === "completed" && (
                <Row justify={"space-evenly"}>
                  <Col lg={10}>
                    <Button
                      type="primary"
                      style={{
                        background: "#FFEE4B",
                        color: "black",
                        fontWeight: "600",
                        alignItems: "center",
                        display: "flex",
                      }}
                      onClick={downloadInvoice}
                    >
                      {" "}
                      {/* <h1
                        // style={{ marginBottom: "25px", alignItems: "center" }}
                        // className="marginVerticle_auto"
                      > */}
                      {/* <a
                          href="https://slicedinvoices.com/pdf/wordpress-pdf-invoice-plugin-sample.pdf"
                          target={"_blank"}
                          rel="noreferrer"
                        > */}
                      <img
                        src="https://api.iconify.design/basil/invoice-solid.svg?color=black&width=20"
                        style={{ paddingRight: 5 }}
                        alt="invoiceicon"
                      />
                      Invoice
                      {/* </a> */}
                      {/* </h1> */}
                    </Button>
                  </Col>
                  <Col lg={8}></Col>
                </Row>
              )}

              {bookingList?.cancellation_reason && (
                <>
                  <Divider />
                  <Row justify={"space-around"}>
                    <Col lg={9}>
                      <H6
                        style={{ marginBottom: "25px", color: "red" }}
                        className="marginVerticle_auto"
                      >
                        Cancel Reason
                      </H6>
                    </Col>
                    <Col>
                      <H6
                        className="timing"
                        style={{
                          // marginLeft: "25px",
                          fontSize: "14px",
                        }}
                      >
                        {bookingList?.cancellation_reason?.message}
                      </H6>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Card>
      </div>
      {invoiceLoading && <Processing title={"Downloading Invoice..."} />}
    </div>
  );
}

export default BookingDetails;
