const actions = {
  
  GET_TIERS: "GET_TIERS",
 

  getTiers: (data) => {
    return {
      type: actions.GET_TIERS,
      data,
    };
  },
};

export default actions;
