import {
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useDispatch, useSelector } from "react-redux";
import useStateRef from "react-usestateref";
import {
  GOOGLE_MAP_API_KEY,
  GOOGLE_MAP_API_TYPE_PLACE,
  GOOGLE_MAP_API_SUBTYPE_AUTOCOMPLETE,
  GOOGLE_MAP_MODULE_NAME_CITY_MASTER,
} from "../../../config/dataService/dataService";
import {
  createCityData,
  deleteCityData,
  getCityDataPagination,
  getStateData,
  updateCityData,
} from "../../../redux/Masters/City/actionCreatore";
import { checkAction } from "../../../utility/commonFunction";
import Loader from "../../loader/loader";
import {
  getAllFareList,
  updateFareServiceAvailable,
} from "../../../redux/manageFare/actionCreatore";
import BreadCrumb from "../../BreadCrumb.js/BreadCrumb";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { addMapStatistics } from "../../../redux/MapStatistics/actionCreatore";
import { getItem } from "../../../utility/localStorageControl";
import { getAdminDataWithPagination } from "../../../redux/admin/actionCreatore";
const ManageCity = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [place, setPlace] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [updateFlow, setUpdateFlow, setUpdateFlowFileRef] = useStateRef("");
  const [placeData, setPlaceData, setPlaceDataRef] = useStateRef("");
  const [inputError, setInputError] = useState(false);
  const [ModalTitle, setModalTitle] = useState("Add City");
  const [placeSelected, setPlaceSelected] = useState(false);
  // const [search, setSearch] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(50);
  const [seachByCity, setSearchByCity] = useState("");
  const [serviceAvailable, setServiceAvailable] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [cityNameforModal, setCityNameForModal] = useState("");
  const [editModalOpen2, setEditModalOpen2] = useState(false);
  const user = getItem("user_details");
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: GOOGLE_MAP_API_KEY,
  });

  // useEffect(() => {
  //   // fetch place details for the first element in placePredictions array
  //   // if (placePredictions.length)
  //   //   placesService?.getDetails(
  //   //     {
  //   //       placeId: placePredictions[0].place_id,
  //   //     },
  //   //     (placeDetails) => savePlaceDetailsToState(placeDetails)
  //   //   );

  // }, [placePredictions]);

  useEffect(() => {
    // GetCity();
    // reteriveStateList();
    window.scrollTo(0, 0);
  }, []);

  const { data, isLoading } = useQuery("stateList", () => {
    return reteriveStateList();
  },
  {
    refetchOnWindowFocus: false,
  });

  const { data: dataCity, isLoading: isLoadingCity } = useQuery(
    "cityList",
    () => {
      return GetCity();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const retriveFareList = async (id) => {
    const resp = await dispatch(getAllFareList(1, "", [id], "", ""));
    if (resp) {
      // setLoading(false);
    }
    // console.log(resp);
  };

  const fareListData = useSelector((state) => state.fare.fareListData);
  const fareList = fareListData?.list?.filter((fare) => fare.city_id !== null);

  const retriveAdminList = async (id) => {
    const resp = await dispatch(getAdminDataWithPagination(id));
    if (resp) {
      // setLoading(false);
    }
    // console.log(resp);
  };

  const adminList = useSelector(
    (state) => state.admin.adminLstWithoutPagination
  );
  // const adminList = adminListData?.list?.filter(
  //   (fare) => fare.city_id !== null
  // );

  // console.log("fareList", fareList);
  var count = 0;
  var disabled = true;
  {
    fareList?.map((fare) => {
      if (fare.isServiceAvailable == true) {
        disabled = false;
      }
    });
  }
  // console.log("count",count);

  const updateSericeAvailable = async (id, value) => {
    const resp = await dispatch(
      updateFareServiceAvailable({ isServiceAvailable: value }, id)
    );
    if (resp) {
      retriveFareList(updateFlow);
    }
  };

  const GetCity = async () => {
    const response = await dispatch(
      getCityDataPagination(
        seachByCity,
        pageNo,
        limit,
        serviceAvailable,
        selectedState
      )
    );
    if (response) {
      setLoading(false);
    }
  };
  const CityData = useSelector((state) => state?.city?.cityDataWithPagination);

  const reteriveStateList = async () => {
    setLoading(true);
    const response = await dispatch(getStateData());
    if (response) {
      setLoading(false);
    }
  };
  let stateData = useSelector((state) => state?.city?.states);
  stateData = stateData.sort((a, b) => a.name.localeCompare(b.name));

  const deleteCityFinal = async (id) => {
    let remove = await dispatch(deleteCityData(id));
    if (remove) {
      if (CityData?.list.length == 1 && CityData.current_page > 1) {
        setPageNo(CityData.current_page - 1);
        await dispatch(
          getCityDataPagination(
            seachByCity,
            CityData.current_page - 1,
            limit,
            serviceAvailable,
            selectedState
          )
        );
      } else {
        await dispatch(
          getCityDataPagination(
            seachByCity,
            pageNo,
            limit,
            serviceAvailable,
            selectedState
          )
        );
      }
    }
  };
  const deleteCity = (value) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this City?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteCityFinal(value);
      },
    });
  };

  const handleSwitchChange = (data) => {
    if (data?.serviceAvailable) {
      setEditModalOpen2(true);
    } else {
      setEditModalOpen(true);
      retriveAdminList(data._id);
    }

    retriveFareList(data?._id);
    setUpdateFlow(data?._id);
    setCityNameForModal(data.name);
  };

  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * limit,
    },
    {
      title: "City",
      key: "city",
      render: (data) => {
        return <span>{data.name}</span>;
      },
    },
    {
      title: "State",
      dataIndex: ["state"],
      key: "state",
    },
    {
      title: "Country",
      dataIndex: ["country"],
      key: "country",
    },
    {
      title: "Service Available",
      key: "serviceAvailable",
      render: (data) => {
        return (
          <>
            <Switch
              checked={data && data?.serviceAvailable ? true : false}
              checkedChildren="Yes"
              unCheckedChildren="No"
              // disabled={!checkAction("master_city", "edit")}
              disabled
              onChange={(e) => {
                handleSwitchChange(data);
              }}
            >
              {" "}
            </Switch>
          </>
        );
      },
    },
    {
      title: t("action"),
      // dataIndex: "documents",
      key: "action",
      render(data) {
        // console.log("data", data);
        return (
          <div>
            {/* {checkAction("city", "edit") && (
              <Button
                onClick={() => {
                  showEditModal(data), setIsModalOpen(true);
                }}
                type="primary"
                // style={{ cursor: "pointer", color: "blue" }}
              >
                <EditOutlined />
                Edit
              </Button>
            )} */}
            {checkAction("master_city", "delete") && (
              <Button
                onClick={() => deleteCity(data._id)}
                style={{ cursor: "pointer", marginLeft: 2 }}
                type="primary"
                danger
                disabled
              >
                <DeleteOutlined />
                Delete
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const showModal = () => {
    // setModalTitle('Add Role')
    setIsModalOpen(true);
    // setSelectedId("")
    form.resetFields();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedId("")
    setInputError(false);
    setUpdateFlow("");
    form.resetFields();
    setEditModalOpen(false);
    setEditModalOpen2(false);

    // setSelectDep(0)
  };

  const handleEditCityStatus = async (id, value, condition) => {
    // console.log("data", data);
    setLoading(true);
    // {
    //   condition == "trueToFalse" &&
    //     fareList?.map((fare) => updateSericeAvailable(fare._id, false));
    // }

    const payload = {
      serviceAvailable: value,
    };

    // const name = value.city + ", " + value.state + ", " + value.country;
    // form.setFieldsValue({
    //   city: name,
    //   status: value.serviceAvailable,
    // });

    const response = await dispatch(updateCityData(payload, id));
    if (response) {
      GetCity();
      setLoading(false);
      message.success("Service availabiity changed successfully");
      setEditModalOpen(false);
      setEditModalOpen2(false);
    }
  };

  const onPlaceSelected = (place) => {
    // console.log("place", place);
    // console.log("location1", place.geometry.location.lat())
    // console.log("location2", place.geometry.location.lng())

    setPlaceSelected(true);
    setPlaceData(place);
    form.setFieldsValue({ city: place && place.formatted_address });
  };

  // console.log("data",data);
  const handleSubmit = async (value) => {
    let obj = [];
    if (!placeSelected) {
      setInputError(true);
      message.error("Please choose city from options");
    } else {
      setInputError(false);
      {
        placeData?.address_components.map((d) => {
          if (
            d.types.find(
              (ele) =>
                // ele == "sublocality" ||
                ele == "locality" ||
                // ele == "administrative_area_level_3" ||
                ele == "administrative_area_level_1" ||
                ele == "country"
            )
          ) {
            obj.push(d);
          }
        });
        // console.log(obj)
      }

      let finalData = {
        // locality: "",
        // sublocality: " ",
        name: "",
        state: "",
        country: "",
        placeId: "",
        // serviceAvailable: false,
        location: { coordinates: [] },
      };
      for (const pla in obj) {
        if (obj[pla].types[0] == "locality") {
          finalData.name = obj[pla].long_name;
        }
        // if (obj[pla].types[0] == "administrative_area_level_3") {
        //   finalData.name = obj[pla].long_name;
        // }
        else if (obj[pla].types[0] == "administrative_area_level_1") {
          finalData.state = obj[pla].long_name;
        } else if (obj[pla].types[0] == "country") {
          finalData.country = obj[pla].long_name;
        }
        //  else if (obj[pla].types[0] == "sublocality_level_2") {

        //   finalData.sublocality = obj[pla].long_name;
        // }
      }
      // finalData.push({placeId:data[0].place_id})
      finalData.placeId = placeData.place_id;
      // finalData.serviceAvailable = value.status;
      finalData.location.coordinates[0] = placeData.geometry.location.lat();

      finalData.location.coordinates[1] = placeData.geometry.location.lng();

      // console.log(finalData);
      if (setUpdateFlowFileRef.current == "") {
        const response = await dispatch(createCityData(finalData));
        if (response) {
          GetCity();
          setIsModalOpen(false);
          form.resetFields();
          // setSelectedId("")
          // setSelectDep(0)
        }
      } else {
        const response = await dispatch(updateCityData(finalData, updateFlow));
        if (response) {
          GetCity();
          setIsModalOpen(false);
          setUpdateFlow("");
          form.resetFields();
          // setSelectedId("")
          // setSelectDep(0)
        }
      }
    }
  };

  const handleSearch = async (e) => {
    setSearchByCity(e.target.value);
    await dispatch(
      getCityDataPagination(
        e.target.value,
        1,
        "",
        serviceAvailable,
        selectedState
      )
    );
  };

  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    await dispatch(
      getCityDataPagination(
        seachByCity,
        page,
        pageSizes,
        serviceAvailable,
        selectedState
      )
    );
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setLimit(pageSizes);
    await dispatch(
      getCityDataPagination(
        seachByCity,
        pageNo,
        pageSizes,
        serviceAvailable,
        selectedState
      )
    );
  };

  const handleStatusFilter = async (value) => {
    // console.log(value);
    setServiceAvailable(value);
    await dispatch(
      getCityDataPagination(seachByCity, 1, "", value, selectedState)
    );
  };

  const handleStateSeleceted = async (value) => {
    setSelectedState(value);
    await dispatch(
      getCityDataPagination(seachByCity, 1, "", serviceAvailable, value)
    );
  };

  const MapStatistics = async () => {
    // Map has loaded successfully
    await dispatch(
      addMapStatistics({
        user_id: user._id,
        googleApiType: GOOGLE_MAP_API_TYPE_PLACE,
        googleSubApiType: GOOGLE_MAP_API_SUBTYPE_AUTOCOMPLETE,
        moduleName: GOOGLE_MAP_MODULE_NAME_CITY_MASTER,
        platformType: "web",
        originFrom: user?.role,
        apiStatus: "success",
      })
    );
  };

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">{t("city")}</h1>
            <BreadCrumb />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            {checkAction("master_city", "add") && (
              <Button
                style={{ float: "right" }}
                className="ADD"
                key="1"
                size="large"
                type="primary"
                onClick={showModal}
              >
                <PlusOutlined /> Add City
              </Button>
            )}
          </Col>
        </Row>
        <Divider />
        {loading && <Loader />}

        {isLoading & isLoadingCity ? (
          <Loader />
        ) : (
          <>
            <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={20}>
                <Col lg={12} md={6} sm={12} xs={12} className="total">
                  Total {CityData?.total_counts}
                </Col>
                <Col xxl={4} lg={4} md={6} sm={12} xs={12}>
                  <div style={{ float: "right" }}>
                    <Input
                      placeholder="Search City"
                      // enterButton="Search"
                      // size="large"
                      onChange={handleSearch}
                      prefix={<SearchOutlined />}
                    />
                  </div>
                </Col>
                <Col xxl={4} lg={4} md={6} sm={12} xs={12}>
                  <div style={{ float: "right", width: "100%" }}>
                    <Select
                      placeholder="Choose State"
                      allowClear
                      style={{
                        // maxWidth: "200px",
                        marginLeft: "20px",
                        float: "right",
                        width: "100%",
                      }}
                      onClear={() => handleStateSeleceted("")}
                      onSelect={(value) => handleStateSeleceted(value)}
                    >
                      {stateData &&
                        stateData.map((state) => {
                          return (
                            <Select.Option
                              value={state._id}
                              key={state._id}
                              className="selectOptions"
                            >
                              {state.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </div>
                </Col>

                <Col lg={4} md={6} sm={12} xs={12}>
                  <div style={{ float: "right", width: "100%" }}>
                    <Select
                      placeholder="Service Available"
                      allowClear
                      style={{
                        // maxWidth: "200px",
                        marginLeft: "20px",
                        float: "right",
                        width: "100%",
                      }}
                      onSelect={(value) => handleStatusFilter(value)}
                      onClear={() => handleStatusFilter("")}
                    >
                      <Select.Option
                        value="true"
                        key="true"
                        className="selectOptions"
                      >
                        Available
                      </Select.Option>
                      <Select.Option
                        value="false"
                        key="false"
                        className="selectOptions"
                      >
                        Not Available
                      </Select.Option>
                    </Select>
                  </div>
                </Col>
              </Row>
            </Card>
            <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                dataSource={CityData.list}
                columns={columns}
                searchable
                scroll={{ x: "45vh" }}
                pagination={
                  // CityData?.total_counts > limit
                  //   ?
                  {
                    showSizeChanger: true,
                    pageSize: limit,
                    total: CityData?.total_counts,
                    onShowSizeChange: onShowSizeChange,
                    onChange: onPageChange,
                    pageSizeOptions: ["50", "100", "500"],
                  }
                  // : false
                }
              />
            </Card>
          </>
        )}
      </div>

      <Modal
        title={ModalTitle}
        open={isModalOpen}
        onOk={form.submit}
        closable={true}
        maskClosable={false}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          labelCol={{ span: 5 }}
        >
          <Form.Item
            label="City"
            rules={[
              {
                required: true,
                message: "Please Enter City Name",
                pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
            name="city"
          >
            {/* <Input
              placeholder="Enter City "
              onChange={(evt) => {
                getPlacePredictions({ input: evt.target.value });
              }}
              loading={isPlacePredictionsLoading}
            /> */}
            {/* <Select
              showSearch
              placeholder="Enter City "
              onSearch={(evt) => {
                console.log(evt);
                getPlacePredictions({ input: evt });
              }}
              loading={isPlacePredictionsLoading}
            >
              {placePredictions.map((item) => console.log(item))}
              {placePredictions.map((item) => {
                return (
                  <Select.Option key={item.place_id}>
                    {item.description}
                  </Select.Option>
                );
              })}
            </Select>  */}
            <Autocomplete
              // apiKey={"AIzaSyCEkQ_KW66M2BjP03QiJ3R4dyzPWcOfuvw"}
              placeholder="Enter City Name"
              autocomplete={false}
              style={{
                width: "100%",
                lineHeight: 3,
                border: inputError && "1px solid red",
              }}
              onPlaceSelected={onPlaceSelected}
              onKeyDown={(event) => {
                if (event.key == "Enter" && placeSelected == false) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => {
                MapStatistics();
                setPlaceSelected(false);
              }}
              options={{
                types: ["(cities)"],
                componentRestrictions: { country: "in" },
                // fields: ["address_components", "geometry", "name"],
                // strictBounds: false,
                // strictBounds:true,
              }}

              // defaultValue="Vadodara, Gujarat, India"
            />
          </Form.Item>
          {/* {!inputError && <span style={{color:"red" }}>Please Choose city from options</span>} */}
          {/* <Form.Item
            label="Status"
            rules={[{ required: true, message: "Please Select Status" }]}
            name="status"
          >
            <Radio.Group>
              <Radio value={true}>Active</Radio>
              <Radio value={false}>Inactive</Radio>
            </Radio.Group>
          </Form.Item> */}
        </Form>
      </Modal>

      <Modal
        open={editModalOpen}
        // title={"Need to add fare"}
        title={
          adminList?.length == 0 && fareList?.length == 0
            ? "Need to add fare and admin"
            : adminList?.length == 0
            ? "Need to add admin"
            : fareList?.length == 0
            ? "Need to add fare"
            : "Enable Service Available"
        }
        // title={"Disable Service Available"}
        onOk={form.submit}
        // okType={"huj"}
        footer={null}
        closable={true}
        maskClosable={false}
        onCancel={handleCancel}
      >
        <div>
          {adminList?.length == 0 && fareList?.length == 0 ? (
            <p>
              No Fare and Admin is available for{" "}
              <strong> {cityNameforModal} </strong>
              city, Please add fare and admin first, After that you can enable
              service for <strong>{cityNameforModal}</strong>
            </p>
          ) : adminList?.length == 0 ? (
            <p>
              No Admin is available for <strong> {cityNameforModal} </strong>
              city, Please add admin first, After that you can enable service
              for <strong>{cityNameforModal}</strong>
            </p>
          ) : fareList?.length == 0 ? (
            <p>
              No Fare is available for <strong> {cityNameforModal} </strong>
              city, Please add fare first, After that you can enable service for{" "}
              <strong>{cityNameforModal}</strong>
            </p>
          ) : (
            <>
              <p>
                Are you want to enable service for{" "}
                <strong>{cityNameforModal}</strong> city?
              </p>
              <p>Atleast one fare should be enable</p>

              {fareList &&
                fareList?.map((fare) => {
                  return (
                    <p>
                      {fare?.vehicle_type_id?.name}{" "}
                      <span>
                        <Switch
                          checked={fare?.isServiceAvailable}
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          onChange={(value) =>
                            updateSericeAvailable(fare._id, value)
                          }
                        />
                      </span>
                    </p>
                  );
                })}
            </>
          )}

          <div style={{ display: "flow-root" }}>
            {adminList.length !== 0 && fareList?.length !== 0 ? (
              <Button
                style={{ float: "right", marginLeft: 10 }}
                type="primary"
                disabled={disabled}
                onClick={() =>
                  handleEditCityStatus(updateFlow, true, "falseToTrue")
                }
              >
                OK
              </Button>
            ) : (
              <Button
                style={{ float: "right", marginLeft: 10 }}
                type="primary"
                disabled={false}
                onClick={() => setEditModalOpen(false)}
              >
                OK
              </Button>
            )}

            <Button
              style={{ float: "right" }}
              type={"default"}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={editModalOpen2}
        title={"Disable Service Available"}
        onOk={form.submit}
        // okType={"huj"}
        footer={null}
        closable={true}
        maskClosable={false}
        onCancel={handleCancel}
      >
        <div>
          <p>
            Are you sure to disable service for{" "}
            <strong>{cityNameforModal}</strong> city?
          </p>
          <p>Fares will be disable for vehicle:</p>
          {fareList &&
            fareList?.map((fare) => {
              return <p>{fare?.vehicle_type_id?.name} </p>;
            })}
          {/* <p>Auto, Bike, Micra </p> */}

          <div style={{ display: "flow-root" }}>
            <Button
              style={{ float: "right", marginLeft: 10 }}
              type="primary"
              disabled={false}
              onClick={() =>
                handleEditCityStatus(updateFlow, false, "trueToFalse")
              }
            >
              OK
            </Button>
            <Button
              style={{ float: "right" }}
              type={"default"}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ManageCity;
