import { message } from "antd";
import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";
import actions from "./actions";
const { getProfileData} = actions;


export const getProfileDataById = (id) => {
  // const history=useNavigate();
  return async (dispatch) => {
    const resp = await DataService.get(API.superAdmin.get + id);
    //  console.log(resp);
    if (!resp.data.error) {
      dispatch(getProfileData(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return "false";
    }
  };
};
export const updateProfileById = (payload,id) => {
  // const history=useNavigate();
  return async (dispatch) => {
    const resp = await DataService.put(API.superAdmin.edit ,payload);
    //  console.log(resp);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return "false";
    }
  };
};