import actions from "./actions";

const { GET_DRIVER_RATING_LIST, GET_RIDER_RATING_LIST,GET_RIDER_RATING_LIST_BY_DRIVER_ID } = actions;

const initState = {
  driverRatingListData: {},
  riderRatingListData: {},
  riderRatingListDataByDriverId:{}
};

const ratingReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_DRIVER_RATING_LIST:
      return {
        ...state,
        driverRatingListData: data,
      };
    case GET_RIDER_RATING_LIST:
      return {
        ...state,
        riderRatingListData: data,
      };
      case GET_RIDER_RATING_LIST_BY_DRIVER_ID:
        return {
          ...state,
          riderRatingListDataByDriverId: data,
        };
    default:
      return state;
  }
};
export default ratingReducer;
