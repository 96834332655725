import { Card, Col, Divider, Row, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";

function Exotelcalls(props) {
  const [domain, setDomain] = useState("https://api.exotel.com");
  const [path, setPath] = useState(
    "/v1/Accounts/gauto/Calls.json"
  );

  useEffect(() => {
    const url = domain + path;
    getCallDetails(url);
  }, []);

  const getCallDetails = async (url) => {
    const resp = await axios.get(url, {
      auth: {
        username: "81698b86faacfb83c3b8f1bc05bdd67229f7999d347800f0",
        password: "ca1976c2a542ef0148aada92a0a165ecfa6368cd8a5d6409",
      },
  
    });
    // console.log("response", resp);
  };

  const columns = [
    {
      title: "Sr.No",
      key: "srno",
      render: (data) => {
        return <span>Page</span>;
      },
    },
    {
      title: "From",
      key: "from",
      render: (data) => {
        return <span>phone</span>;
      },
    },
    {
      title: "To",
      key: "to",
      render: (data) => {
        return <span>phone to</span>;
      },
    },
    {
      title: "Start Time",
      key: "startTime",
      render: (data) => {
        //   return <span>phone to</span>;
      },
    },
    {
      title: "End Time",
      key: "endTime",
      render: (data) => {
        //   return <span>phone to</span>;
      },
    },
    {
      title: "Duration",
      key: "duration",
      render: (data) => {
        //   return <span>phone to</span>;
      },
    },
    {
      title: "Price",
      key: "price",
      render: (data) => {
        //   return <span>phone to</span>;
      },
    },
    {
      title: "Recording",
      key: "recording",
      render: (data) => {
        //   return <span>phone to</span>;
      },
    },
    {
      title: "Status",
      key: "status",
      render: (data) => {
        //   return <span>phone to</span>;
      },
    },
    {
      title: "TimeStamp",
      key: "timeStamp",
      render: (data) => {
        //   return <span>phone to</span>;
      },
    },
  ];

  return (
    <div className="common_layout content-container ">
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h1 className="heading">Exotel Call Logs</h1>
          <BreadCrumb />
        </Col>
      </Row>
      <Divider />
      <Card>
        <Table columns={columns} />
      </Card>
    </div>
  );
}

export default Exotelcalls;
