import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Card,
  Col,
  Collapse,
  DatePicker,
  Input,
  Row,
  Select,
  Table,
  Tag,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IMAGE_URL_PREFIX } from "../../config/dataService/dataService";
import { getRiderBookingsList } from "../../redux/Bookings/actionCreatore";
import { getDriverById } from "../../redux/drivers/actionCreatore";
import mapGif from "../../static/img/driverDetail/map1.gif";
import walletGif from "../../static/img/driverDetail/wallet1.gif";
import { FixedToNDecimals, WordFormate } from "../../utility/commonFunction";
import { getItem } from "../../utility/localStorageControl";
import NotificationList from "../Drivers/manageDriver/driverDetails/notificationsList";
import Loader from "../loader/loader";
import { Main } from "../styled";
const { Panel } = Collapse;
function Rider(props) {
  const { riderId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [ridesLoading, setRidesLoading] = useState(false);
  const user = getItem("user_details");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState("self");
  const [fromDate, setFromDate] = useState(dayjs().add(-7, "days"));

  const [toDate, setToDate] = useState(dayjs());
  useEffect(() => {
    getRiderData();
    reteriveRiderRides();
  }, []);

  const getRiderData = async () => {
    setLoading(true);

    const response = await dispatch(getDriverById(riderId));
    if (response) {
      setLoading(false);
    }
  };
  const userData = useSelector((state) => state.driver.driverById);
  // console.log(userData);

  const reteriveRiderRides = async () => {
    setRidesLoading(true);
    const resp = await dispatch(
      getRiderBookingsList(pageNo, limit, riderId, fromDate, toDate)
    );
    if (resp) {
      setRidesLoading(false);
    }
  };
  const riderRides = useSelector((state) => state.booking.riderBookingList);
  const rangePresets = [
    { label: "Today", value: [dayjs(), dayjs()] },
    { label: "Last 7 Days", value: [dayjs().add(-7, "days"), dayjs()] },
    { label: "Last Month", value: [dayjs().add(-1, "month"), dayjs()] },
    { label: "Last 6 Month", value: [dayjs().add(-6, "month"), dayjs()] },
    { label: "Last Year", value: [dayjs().add(-1, "year"), dayjs()] },
  ];
  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    setRidesLoading(true);

    const resp = await dispatch(
      getRiderBookingsList(page, pageSizes, riderId, fromDate, toDate)
    );
    if (resp) {
      setRidesLoading(false);
    }
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setLimit(pageSizes);
    setRidesLoading(true);

    const resp = await dispatch(
      getRiderBookingsList(current, pageSizes, riderId, fromDate, toDate)
    );
    if (resp) {
      setRidesLoading(false);
    }
  };
  const onRangeChange = async (dates, dateStrings) => {
    setFromDate(dates ? dates[0] : "");
    setToDate(dates ? dates[1] : " ");
    setRidesLoading(true);
    setPageNo(1);
    setLimit(10);
    const resp = await dispatch(
      getRiderBookingsList(
        1,
        10,
        riderId,
        dates ? dates[0] : "",
        dates ? dates[1] : " "
      )
    );
    if (resp) {
      setRidesLoading(false);
    }
  };

  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * limit,
    },

    // {
    //   title: "Driver Name",
    //   render(data, record) {
    //     console.log("dtaa",data);
    //     return {
    //       children: (
    //         <span>
    //           {/* {data && data?.pickup_location
    //             ? data?.pickup_location?.location_name
    //             : "-"} */}
    //         </span>
    //       ),
    //     };
    //   },
    // },
    {
      title: "Name",
      key: "name",
      render(data, record) {
        return {
          children: (
            <span>
              {/* {data && data.vehicle_details ? data.vehicle_details?.name : "-"} */}
            </span>
          ),
        };
      },
    },
    {
      title: "Phone Number",
      key: "phoneNumber",
      render(data, record) {
        return {
          children: (
            <span>
              {/* {data && data.vehicle_details ? data.vehicle_details?.name : "-"} */}
            </span>
          ),
        };
      },
    },
    {
      title: "Source",
      render(data, record) {
        return {
          children: (
            <span>
              {data && data?.pickup_location
                ? data?.pickup_location?.location_name
                : "-"}
            </span>
          ),
        };
      },
    },
    {
      title: "Destination",
      render(data, record) {
        return {
          children: (
            <span>
              {data && data?.destination_location?.location_name
                ? data?.destination_location?.location_name
                : "-"}
            </span>
          ),
        };
      },
    },
    {
      title: "Start Time ",
      render(data, record) {
        return {
          children: (
            <span>
              {data && data?.rideStartedAt
                ? new Date(data?.rideStartedAt).toLocaleTimeString()
                : "-"}
            </span>
          ),
        };
      },
    },
    {
      title: "End Time",
      render(data, record) {
        return {
          children: (
            <span>
              {data && data?.rideEndedAt
                ? new Date(data?.rideEndedAt).toLocaleTimeString()
                : "-"}
            </span>
          ),
        };
      },
    },

    {
      title: "Fare",
      render(data, record) {
        return {
          children: (
            <span>
              {data && data.total_fare
                ? FixedToNDecimals(data.total_fare)
                : "-"}
            </span>
          ),
        };
      },
    },
    {
      title: "Vehicle",
      render(data, record) {
        return {
          children: (
            <span>
              {data && data.vehicle_details ? data.vehicle_details?.name : "-"}
            </span>
          ),
        };
      },
    },
    {
      title: "Status",
      render(data, record) {
        return {
          children: (
            <span>
              <Tag
                color={
                  data?.status == "pending"
                    ? "yellow"
                    : data?.status == "completed"
                    ? "blue"
                    : data?.status == "accepted"
                    ? "orange"
                    : "red"
                }
              >
                {data && WordFormate(data?.status)}
              </Tag>
            </span>
          ),
        };
      },
    },
    // {
    //   title: "Vehicle Number",
    //   render(data, record) {
    //     return {
    //       children: <span>{data && data.vehicle_details ? data.vehicle_details?.name : "-"}</span>,
    //     };
    //   },
    // },
    // {
    //   title: "Coupon Code ",
    //   render(data, record) {
    //     return {
    //       children: <span>{data && data.coupon ? data.coupon : "-"}</span>,
    //     };
    //   },
    // },
  ];

  return (
    <div>
      <Main>
        {loading && <Loader />}
        {!loading && (
          <>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              <Card
                style={{
                  background: "rgb(133,209,143)",
                  background:
                    "linear-gradient(90deg, rgba(133,209,143,0.8127626050420168) 22%, rgba(83,218,198,0.6615021008403361) 56%, rgba(122,194,209,1) 83%)",
                }}
              >
                <div
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "24px", fontWeight: "700" }}>Profile</p>
                  <Avatar
                    size={150}
                    icon={
                      userData && userData.profile_image ? (
                        <img
                          src={IMAGE_URL_PREFIX + userData.profile_image}
                          // crossOrigin="anonymous"
                        />
                      ) : (
                        <UserOutlined />
                      )
                    }
                  />
                </div>

                <span
                  className="total toWallet"
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={mapGif}
                    width={55}
                    style={{ marginInline: "20px" }}
                    onClick={() => navigate(`map`)}
                    alt={"Map"}
                  />
                  <img
                    src={walletGif}
                    width={40}
                    onClick={() =>
                      navigate(
                        `/${user.role}/manageRider/riderWallet/${riderId}`
                      )
                    }
                    alt={"wallet"}
                  />
                </span>
              </Card>
            </div>
            <div style={{ justifyContent: "center", marginBottom: "20px" }}>
              <Card>
                <Row gutter={20} style={{ marginBottom: "30px" }}>
                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel"> Name</label>
                      <Input
                        defaultValue={userData && userData.name}
                        disabled
                        style={{
                          background: "white",
                          cursor: "default",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24}>
                    <div>
                      <label className="userlabel">Email </label>
                      <Input
                        defaultValue={userData && userData.email}
                        disabled
                        style={{
                          background: "white",
                          cursor: "default",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">{t("phoneNo")}</label>
                      <Input
                        defaultValue={
                          userData && userData.phoneNumber
                            ? userData.phoneNumber
                            : ""
                        }
                        disabled
                        style={{
                          background: "white",
                          cursor: "default",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">City</label>
                      <Input
                        defaultValue={
                          userData && userData.city_id
                            ? userData.city_id?.name
                            : ""
                        }
                        disabled
                        style={{
                          background: "white",
                          cursor: "default",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  </Col>

                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">Joined Date</label>
                      <Input
                        defaultValue={
                          userData && userData.created_at
                            ? new Date(userData.created_at).toLocaleDateString()
                            : ""
                        }
                        disabled
                        style={{
                          background: "white",
                          cursor: "default",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">Google Id</label>
                      <Input
                        defaultValue={
                          userData && userData.google_id
                            ? userData.google_id
                            : ""
                        }
                        disabled
                        style={{
                          background: "white",
                          cursor: "default",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  </Col>

                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">Facebook Id</label>
                      <Input
                        defaultValue={
                          userData && userData.facebook_id
                            ? userData.facebook_id
                            : ""
                        }
                        disabled
                        style={{
                          background: "white",
                          cursor: "default",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">Last Login</label>

                      <Input
                        defaultValue={
                          userData && userData?.login_time
                            ? userData?.login_time
                            : "-"
                        }
                        disabled
                        style={{
                          background: "white",
                          cursor: "default",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">Device Id</label>

                      <Input
                        defaultValue={
                          userData && userData?.device_id
                            ? userData?.device_id
                            : "-"
                        }
                        disabled
                        style={{
                          background: "white",
                          cursor: "default",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">Device Type</label>

                      <Input
                        defaultValue={
                          userData && userData?.device_type
                            ? userData?.device_type
                            : "-"
                        }
                        disabled
                        style={{
                          background: "white",
                          cursor: "default",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">Device Model</label>

                      <Input
                        defaultValue={
                          userData && userData?.modelName
                            ? userData?.modelName
                            : "-"
                        }
                        disabled
                        style={{
                          background: "white",
                          cursor: "default",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>
            <Collapse defaultActiveKey={"1"} style={{ border: "none" }}>
              <Panel
                header={`Total Rides ${
                  riderRides.total_counts ? riderRides.total_counts : ""
                } `}
                key="1"
                style={{
                  background: "#00000047",
                  borderRadius: 10,
                  fontWeight: 500,
                }}
                extra={
                  <div onClick={(event) => event.stopPropagation()}>
                    <Select
                      style={{
                        width: "200px",
                        background: "white",
                        marginRight: "10px",
                      }}
                      placeholder="Booking For"
                      onClick={(event) => event.stopPropagation()}
                      defaultValue={status}
                      onSelect={(e) => setStatus(e)}
                    >
                      <Select.Option value="self" key={"self"}>
                        Self
                      </Select.Option>
                      <Select.Option value="other" key={"other"}>
                        Other
                      </Select.Option>
                    </Select>
                    <DatePicker.RangePicker
                      presets={rangePresets}
                      onChange={onRangeChange}
                      defaultValue={[fromDate, toDate]}
                      style={{ borderRadius: "0" }}
                      allowClear={false}

                      // style={{display:"flex"}}
                    />
                  </div>
                }
              >
                <Table
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                  dataSource={ridesLoading ? [] : riderRides?.list}
                  columns={
                    status == "self"
                      ? columns.filter(
                          (col) =>
                            (col.key != "name") & (col.key != "phoneNumber")
                        )
                      : columns
                  }
                  loading={ridesLoading}
                  pagination={
                    //     rides?.data?.total_counts > 10
                    // ?
                    {
                      showSizeChanger: true,
                      pageSize: limit,
                      total: riderRides.total_counts,
                      onShowSizeChange: onShowSizeChange,
                      onChange: onPageChange,
                      pageSizeOptions: ["10", "50", "100"],
                      current: pageNo,
                    }
                    //       : false
                  }
                />
              </Panel>
            </Collapse>
            <br />
            <NotificationList id={riderId} role={"rider"} />
          </>
        )}
      </Main>
    </div>
  );
}

export default Rider;
