const actions = {
  GET_SETTING: "GET_SETTING",

  getSetting: (data) => {
    return {
      type: actions.GET_SETTING,
      data,
    };
  },

};

export default actions;
