import { Collapse, DatePicker, Select, Table, Tag } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDriverAllRideRequests } from "../../../../redux/drivers/actionCreatore";
import { FixedToNDecimals } from "../../../../utility/commonFunction";
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
function RideRequestTable() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { driverId } = useParams();
  const [loading, setLoading] = useState(true);

  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [fromDate, setFromDate] = useState(dayjs().add(-7, "days"));
  const [toDate, setToDate] = useState(dayjs());
  const [selectedStatus, setSelectedStatus] = useState("");
  const [showExtraRideRequest, setShowExtraRideRequest] = useState(true);

  const rangePresets = {
    Today: [dayjs(), dayjs()],
    Yesterday: [dayjs().add(-1, "days"), dayjs()],

    "Last 7 Days": [dayjs().add(-7, "days"), dayjs()],
    "Last Month": [dayjs().add(-1, "month"), dayjs()],
    "Last 6 Month": [dayjs().add(-6, "month"), dayjs()],
    "Last Year": [dayjs().add(-1, "year"), dayjs()],
  };
  const handleSelectStatus = async (value) => {
    setSelectedStatus(value);
    await dispatch(
      getDriverAllRideRequests(
        1,
        limit,
        driverId,
        fromDate.format("YYYY-MM-DD"),
        toDate.format("YYYY-MM-DD"),
        value
      )
    );
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setFromDate(dates[0]);
      setToDate(dates[1]);
    } else {
      console.log("Clear");
    }
  };

  useEffect(() => {
    reteriveRideRequests();
  }, [fromDate, toDate]);

  const reteriveRideRequests = async () => {
    const resp = await dispatch(
      getDriverAllRideRequests(
        pageNo,
        limit,
        driverId,
        fromDate.format("YYYY-MM-DD"),
        toDate.format("YYYY-MM-DD"),
        selectedStatus
      )
    );
    if (resp) {
      // loading(prev=>[...prev,5]);
      setLoading(false);
    }
  };
  const rideRequests = useSelector((state) => state.driver.rideRequests);

  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    await dispatch(
      getDriverAllRideRequests(
        page,
        pageSizes,
        driverId,
        fromDate.format("YYYY-MM-DD"),
        toDate.format("YYYY-MM-DD"),
        selectedStatus
      )
    );
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setLimit(pageSizes);
    await dispatch(
      getDriverAllRideRequests(
        current,
        pageSizes,
        driverId,
        fromDate.format("YYYY-MM-DD"),
        toDate.format("YYYY-MM-DD"),
        selectedStatus
      )
    );
  };

  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      align: "center",
      render: (text, object, index) => index + 1 + (pageNo - 1) * limit,
    },
    {
      title: "Pickup Location",
      align: "center",
      render(data, record) {
        return {
          children: (
            <span>
              {data && data?.rideId?.pickup_location
                ? data?.rideId?.pickup_location?.location_name
                : "-"}
            </span>
          ),
        };
      },
    },
    {
      title: "Destination Location",
      align: "center",

      render(data, record) {
        return {
          children: (
            <span>
              {data && data?.rideId?.destination_location?.location_name
                ? data?.rideId?.destination_location?.location_name
                : "-"}
            </span>
          ),
        };
      },
    },
    {
      title: "Start Time",
      align: "center",
      key: "startTime",
      render(data, record) {
        return {
          children: (
            <span>
              {" "}
              {data && data?.rideStartedAt
                ? new Date(data?.rideStartedAt).toLocaleDateString()
                : "-"}
            </span>
          ),
        };
      },
    },
    {
      title: "End Time",
      align: "center",
      key: "endTime",
      render(data, record) {
        return {
          children: (
            <span>
              {" "}
              {data && data?.rideEndedAt
                ? new Date(data?.rideEndedAt).toLocaleDateString()
                : "-"}
            </span>
          ),
        };
      },
    },
    {
      title: "Distance (Km)",
      align: "center",
      key: "ride_distance",
      render(data, record) {
        return {
          children: (
            <span>
              {data && data?.rideId?.ride_distance
                ? FixedToNDecimals(data?.rideId?.ride_distance)
                : "-"}
            </span>
          ),
        };
      },
    },

    {
      title: "Fare (₹)",
      align: "center",
      render(data, record) {
        return {
          children: (
            <span>
              {data && data?.rideId?.fare
                ? FixedToNDecimals(data?.rideId?.fare)
                : "-"}
            </span>
          ),
        };
      },
    },
    // {
    //   title: "Vehicle",
    //   render(data, record) {
    //     return {
    //       children: <span>{data && data.vehicle ? data.vehicle : "-"}</span>,
    //     };
    //   },
    // },
    // {
    //   title: "Coupon Code ",
    //   render(data, record) {
    //     return {
    //       children: <span>{data && data.coupon ? data.coupon : "-"}</span>,
    //     };
    //   },
    // },
    {
      title: "Scheduled At",
      align: "center",
      render(data, record) {
        const date = moment(data?.created_at);
        return {
          children: (
            <span>
              {data && data?.created_at
                ? date.format("DD-MM-YYYY hh:mm:ss")
                : "-"}
            </span>
          ),
        };
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status) => {
        return (
          <Tag
            style={{ width: "100px", textAlign: "center" }}
            color={
              status.toLowerCase() === "accepted"
                ? "#1b60f7"
                : status.toLowerCase() === "expired"
                ? "#fc8d49"
                : status.toLowerCase() == "cancelled"
                ? "#ce3b16"
                : "#ff0000"
            }
            key={status}
          >
            {status === "expired" && "No Action"}
            {status === "rejected" && "Declined"}
            {status === "accepted" && "Accepted"}
            {status == "cancelled" && "Cancelled"}
            {/* { status=="expired"? "No action":            status.toUpperCase()} */}
          </Tag>
        );
      },
    },
  ];
  return (
    <div>
      <Collapse
        style={{
          border: "none",
        }}
        defaultActiveKey={"3"}
        onChange={(e) => setShowExtraRideRequest(e.length === 0 ? false : true)}
      >
        <Panel
          // header={`Ride Requests | Total ${driverRides?.total_counts}`}
          header={`Ride Requests | Total ${rideRequests?.total_counts}`}
          key="3"
          style={{
            background: "#00000047",
            // borderRadius: 10,
            fontWeight: 500,
          }}
          extra={
            loading === false &&
            showExtraRideRequest && (
              <div
                onClick={(event) => event.stopPropagation()}
                style={{ display: "flex" }}
              >
                <Select
                  placeholder="Filter By Status"
                  style={{
                    width: 200,
                    marginRight: "10px",
                    float: "right",
                    background: "white",
                    // borderRadius: 10,
                  }}
                  onSelect={(value) => handleSelectStatus(value)}
                  defaultValue={selectedStatus}
                >
                  <Select.Option value="">
                    {" "}
                    <Tag
                      color="#359b09"
                      style={{
                        width: "165px",
                        fontSize: "12px",
                        // color: "black",
                      }}
                    >
                      {" "}
                      All
                    </Tag>
                  </Select.Option>

                  {/* <Select.Option value="pending">
                    <Tag
                      color="#bcba51"
                      style={{
                        width: "165px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Pending
                    </Tag>
                  </Select.Option> */}
                  <Select.Option value="accepted">
                    {" "}
                    <Tag
                      color="#1b60f7"
                      style={{
                        width: "170px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Accepted
                    </Tag>
                  </Select.Option>
                  <Select.Option value="rejected">
                    <Tag
                      color="#ff0000"
                      style={{
                        width: "170px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Declined
                    </Tag>
                  </Select.Option>
                  <Select.Option value="expired">
                    <Tag
                      color="#fc8d49"
                      style={{
                        width: "165px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      No Action
                    </Tag>
                  </Select.Option>

                  <Select.Option value="cancelled">
                    <Tag
                      color="#ce3b16"
                      style={{
                        width: "165px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Cancelled
                    </Tag>
                  </Select.Option>
                </Select>
                <RangePicker
                  ranges={rangePresets}
                  onChange={onRangeChange}
                  defaultValue={[fromDate, toDate]}
                  style={{ borderRadius: "0" }}
                  allowClear={false}

                  // style={{display:"flex"}}
                />
              </div>
            )
          }
        >
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            loading={loading}
            dataSource={rideRequests?.list}
            // columns={getItem("userDetails").role=="admin"?columns:columns.filter(col=>col.title!=t("action"))}
            columns={columns.filter(
              (col) => (col.key !== "startTime") & (col.key !== "endTime")
            )}
            scroll={{ x: 10 }}
            pagination={
              // rides?.data?.total_counts > 10
              //   ?
              {
                showSizeChanger: true,
                pageSize: limit,
                total: rideRequests?.total_counts,
                onShowSizeChange: onShowSizeChange,
                onChange: onPageChange,
                pageSizeOptions: ["10", "50", "100"],
              }
              // : false
            }
          />
        </Panel>
      </Collapse>
    </div>
  );
}

export default RideRequestTable;
