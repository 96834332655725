const actions = {
  GET_ROLE_ID: "GET_ROLE_ID",
  PATCH_ROLE_ID: "PATCH_ROLE_ID",

  getRoleById: (data) => {
    return {
      type: actions.GET_ROLE_ID,
      data,
    };
  },
  updateRoleById: (data) => {
    return {
      type: actions.PATCH_ROLE_ID,
      data,
    };
  },
  //   createRole: data => {
  //     return {
  //       type: actions.CREATE_ROLE,
  //       data,
  //     };
  //   },
  //   deleteRole: data => {
  //     return {
  //       type: actions.DELETE_ROLE,
  //       data,
  //     };
  //   },
};

export default actions;
