import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Input, Row, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  IMAGE_URL_PREFIX,
  SUPERADMIN,
} from "../../config/dataService/dataService";
import { getAllDriversList } from "../../redux/drivers/actionCreatore";
import { getCityData } from "../../redux/Masters/City/actionCreatore";
import { getVehicleTypesData } from "../../redux/VehicleTypes/actionCreatore";
import { getItem } from "../../utility/localStorageControl";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import Loader from "../loader/loader";

const VehicleList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [pageSize, setPageSize] = useState(50);
  const [pageNo, setPageNo] = useState(1);
  const [searchFilter, setSearchFilter] = useState("");
  const user = getItem("user_details");
  const [selectedCity, setSelectedCity] = useState(
    user.role != "super_admin" ? user?.city_id?._id : ""
  );
  const [searchCity, setSearchCity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedVehicleType, setSelectedVehicleType] = useState("");

  useEffect(() => {
    // reteriveCityList();
    // reteriveDriverList();
    // reteriveVehicleType()
  }, []);

  let payload = {
    pageNo,
    pageSize,
    searchFilter,
    dutyStatus: "",

    selectedCity,
    vehicleType: "",
    key: "",
    sort: "",
    login_time: "",
  };
  const { data, isLoading } = useQuery(
    "driverlist",
    () => {
      return reteriveDriverList(payload);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: vehicleData, isLoading: isLoadingVehicleType } = useQuery(
    "vehicleTypelist",
    () => {
      return reteriveVehicleType();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataCity, isLoading: isLoadingCity } = useQuery(
    "cityList",
    () => {
      return reteriveCityList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const reteriveCityList = async () => {
    await dispatch(getCityData(""));
  };
  const cityList = useSelector((state) => state.city.cityData);

  const reteriveDriverList = async (payload) => {
    setLoading(true);

    const resp = await dispatch(getAllDriversList(payload));
    if (resp) {
      setLoading(false);
    }
  };

  const driverList = useSelector((state) => state.driver.driverListData);
  // console.log("driverList ", driverList);

  const reteriveVehicleType = async () => {
    setLoading(true);
    const resp = await dispatch(getVehicleTypesData());
    if (resp) {
      setLoading(false);
    }
  };

  const vehicleTypes = useSelector((state) => state.vehicleType.vehicleData);
  // console.log(vehicleTypes);

  const handleSearch = async (value) => {
    setSearchCity(value);

    await dispatch(getCityData(value));
  };

  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    payload.pageNo = page;
    reteriveDriverList(payload);
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    payload.pageSize = pageSizes;
    reteriveDriverList(payload);
  };

  const handleSearchFilter = async (value) => {
    setSearchFilter(value);
    setPageNo(1);
    payload.searchFilter = value;
    payload.pageNo = 1;
    reteriveDriverList(payload);
  };

  const handleCitySelected = async (value) => {
    setSelectedCity(value);
    setPageNo(1);

    payload.selectedCity = value;
    payload.pageNo = 1;
    reteriveDriverList(payload);
  };
  const handleVehicleFilter = async (value) => {
    setSelectedVehicleType(value);
    setPageNo(1);

    payload.vehicleType = value;
    payload.pageNo = 1;
    reteriveDriverList(payload);
  };

  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      align: "center",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },
    {
      title: "Driver Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: ["vehicle_type_id", "name"],
      key: "type",
    },
    {
      title: "Vehicle Number",
      dataIndex: ["vehicle_number"],
      key: "number",
    },
    {
      title: "City",
      dataIndex: ["city_id", "name"],
      key: "city",
    },
    // {
    //   title: "Registration End Date",
    //   dataIndex: "endDate",
    //   key: "endDate",
    // },
    {
      title: "Total Rides",
      dataIndex: "rideCount",
      key: "rides",
    },
    {
      title: "Documents",
      // dataIndex: "documents",
      key: "documents",
      render(data, record) {
        const insurance = data?.doc.filter((doc) => doc.type == "insurance");
        const rc = data?.doc.filter((doc) => doc.type == "rc_book");
        return {
          children: (
            <span>
              <a href={IMAGE_URL_PREFIX + rc[0]?.url} target="_blank">
                <Button
                  type="primary"
                  disabled={rc[0] ? (rc[0].url ? false : true) : true}
                >
                  RC
                </Button>
              </a>{" "}
              <a href={IMAGE_URL_PREFIX + insurance[0]?.url} target="_blank">
                {" "}
                <Button
                  type="primary"
                  disabled={
                    insurance[0] ? (insurance[0].url ? false : true) : true
                  }
                >
                  Insurance
                </Button>
              </a>
            </span>
          ),
        };
      },
    },
  ];
  return (
    <>
      <div className="common_layout content-container ">
        <h1 className="heading">{t("vehicle")}</h1>
        <BreadCrumb />

        <Divider />
        {isLoading & isLoadingCity & isLoadingVehicleType ? (
          <Loader />
        ) : (
          <>
            <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={12}
                  sm={12}
                  xs={24}
                  className="total"
                >
                  Total {driverList?.total_counts}
                </Col>

                {user.role != SUPERADMIN && (
                  <Col xxl={6} xl={6} lg={6} md={0} sm={0} xs={0}></Col>
                )}

                <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={24}>
                  <Input
                    placeholder="Search By Driver Name"
                    // enterButton="Search"
                    style={{ width: "100%" }}
                    prefix={<SearchOutlined />}
                    onChange={(event) => handleSearchFilter(event.target.value)}
                  />
                </Col>
                {user.role == SUPERADMIN && (
                  <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={24}>
                    <Select
                      placeholder="Choose city"
                      style={{
                        width: "100%",
                        maxHeight: "45px",
                        overflowY: "auto",
                        marginTop: "6px",
                      }}
                      showSearch
                      mode="multiple"
                      filterOption={false}
                      onSearch={(e) => handleSearch(e)}
                      allowClear
                      onChange={handleCitySelected}
                    >
                      {cityList &&
                        cityList.map((data) => {
                          return (
                            <Select.Option
                              value={data._id}
                              key={data._id}
                              className="selectOptions"
                            >
                              {data.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Col>
                )}

                <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={24}>
                  <Select
                    style={{
                      width: "100%",
                      maxHeight: "45px",
                      overflowY: "auto",
                    }}
                    placeholder="Choose Vehicle"
                    allowClear
                    mode="multiple"
                    onChange={handleVehicleFilter}
                  >
                    {vehicleTypes &&
                      vehicleTypes.length > 0 &&
                      vehicleTypes.map((vehicle) => (
                        <Select.Option
                          value={vehicle._id}
                          key={vehicle._id}
                          className="selectOptions"
                        >
                          {vehicle.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Col>
              </Row>
            </Card>
            <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                dataSource={driverList.list}
                // columns={columns}
                columns={
                  user.role == SUPERADMIN
                    ? columns
                    : columns.filter((key) => key.title != "City")
                }
                searchable
                scroll={{ x: "45vh" }}
                pagination={{
                  showSizeChanger: true,
                  pageSize: pageSize,
                  total: driverList?.total_counts,
                  onShowSizeChange: onShowSizeChange,
                  onChange: onPageChange,
                  pageSizeOptions: ["50", "100", "500"],
                  current: pageNo,
                }}
              />
            </Card>
          </>
        )}
      </div>
    </>
  );
};

export default VehicleList;
