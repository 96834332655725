import { ArrowRightOutlined, DownOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Switch,
  Table,
  TimePicker,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getFareListById,
  updateFare,
} from "../../redux/manageFare/actionCreatore";
import { FixedToNDecimals } from "../../utility/commonFunction";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import Loader from "../loader/loader";
import Map from "./MapForUpdateFare";

const { Panel } = Collapse;

const ManageFareUpdate = () => {
  let id = window.location.pathname.split("/").pop();
  const [fareData, setFareData] = useState({});
  const navigate = useNavigate();
  const initialValues = fareData && fareData;

  const [dayEndTime, setDayEndTime] = useState("");
  const [nightEndTime, setNightEndTime] = useState("");
  const [freeWaitingTime, setfreeWaitingTime] = useState();
  const [waitingTime, setWaitingTime] = useState("");
  const [loading, setLoading] = useState(true);
  const [serviceAvailable, setServiceAvailable] = useState();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [tierDetails, setTierDetails] = useState([]);
  const [tierDetailsNight, setTierDetailsNight] = useState([]);
  const [cityLocation, setCityLocation] = useState([]);

  const [tierData, setTierData] = useState();
  const [tierDataNight, setTierDataNight] = useState();
  const [airportMap, setAirportMap] = useState(false);
  const [visible, setVisible] = useState(false);
  const [masterArrayData, setMasterArrayData] = useState();
  const [points, setPoints] = useState([]);
  const [airportLoationPoints, setAirportLocationPoints] = useState([]);
  const [railwayLoationPoints, setRailwayLocationPoints] = useState([]);
  const [showOkButton, setShowOkButton] = useState(true);
  const [masterArrayDayData, setMasterArrayDayData] = useState();
  const [masterArrayNightData, setMasterArrayNightData] = useState();
  const [dayToKmError, setDayToKmErrro] = useState(false);
  const [nightToKmError, setNightToKmErrro] = useState(false);
  const [dayToKmErrorIndex, setDayToKmErrorIndex] = useState(null);
  const [nightToKmErrorIndex, setNightToKmErrorIndex] = useState(null);

  useEffect(() => {
    getByid();
  }, []);
  const getByid = async () => {
    let result = await dispatch(getFareListById(id));
    if (result) {
      setFareData(result);
      // setMasterArrayData(result?.addFareMasterArray);
      setMasterArrayDayData(result?.dayFareMasterArray);
      setMasterArrayNightData(result?.nightFareMasterArray);

      setServiceAvailable(result?.isServiceAvailable);
      setCityLocation(result?.city_id?.location?.coordinates);
      setDayEndTime(result?.night_start_time);

      setNightEndTime(result?.day_start_time);
      const day = result?.tireDetails?.filter((tier) => tier.shift == "day");

      const night = result?.tireDetails?.filter(
        (tier) => tier.shift == "night"
      );

      setTierDetails(day);
      setTierDetailsNight(night);
      updateTierData(day, night);
      setAirportLocationPoints(result.airport?.latLongs);
      setRailwayLocationPoints(result.railway_station?.latLongs);

      form.setFieldsValue({
        airport_charges: result.airport.charges,
        railway_station_charges: result.railway_station.charges,
        airport_locations: result.airport.locations?.coordinates[0],
        railway_station_locations:
          result.railway_station.locations?.coordinates[0],
      });
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    // console.log("values", values);
    if (masterArrayDayData[masterArrayDayData.length - 1].toKm !== 100000) {
      setDayToKmErrro(true);
      message.error(
        <div>
          Last Value Of <b>To Km</b> in Day Charges should be <b>100000</b>
        </div>
      );
      setDayToKmErrro(false);
    }
    if (masterArrayNightData[masterArrayNightData.length - 1].toKm !== 100000) {
      setNightToKmErrro(true);
      message.error(
        <div>
          Last Value Of <b>To Km</b> in Night Charges should be <b>100000</b>
        </div>
      );
      setNightToKmErrro(false);
    }
    if (
      masterArrayDayData[masterArrayDayData.length - 1].toKm == 100000 &&
      masterArrayNightData[masterArrayNightData.length - 1].toKm === 100000
    ) {
      values.day_start_time = nightEndTime
        ? nightEndTime
        : initialValues?.day_start_time;
      values.city_id = fareData?.city_id?._id;
      values.vehicle_type_id = fareData?.vehicle_type_id?._id;
      values.night_start_time = dayEndTime
        ? dayEndTime
        : initialValues?.night_start_time;
      values.free_waiting_time = freeWaitingTime
        ? freeWaitingTime
        : initialValues?.free_waiting_time;
      values.waiting_time = waitingTime
        ? waitingTime
        : initialValues?.waiting_time;

      const tier1 = (({ name, radius }) => ({ name, radius }))(tierData[0]);
      tier1.shift = "day";
      const tier2 = (({ name, radius }) => ({ name, radius }))(tierData[1]);
      tier2.shift = "day";

      const tier3 = (({ name, radius }) => ({ name, radius }))(
        tierDataNight[0]
      );
      const tier4 = (({ name, radius }) => ({ name, radius }))(
        tierDataNight[1]
      );
      tier3.shift = "night";
      tier4.shift = "night";

      values.tierDetails = [tier1, tier2, tier3, tier4];
      // console.log(typeof values.airport_locations);
      values.airport = {
        charges: values.airport_charges,
        locations: airportLoationPoints,
      };
      values.railway_station = {
        charges: values.railway_station_charges,
        locations: railwayLoationPoints,
      };
      delete values.airport_charges;
      delete values.railway_station_charges;
      delete values.railway_station_locations;
      delete values.airport_locations;

      const FinalPayload = {
        ...values,
        dayFareMasterArray: masterArrayDayData.map(
          ({ _id, isBaseFare, ...rest }) => rest
        ),
        nightFareMasterArray: masterArrayNightData.map(
          ({ _id, isBaseFare, ...rest }) => rest
        ),
        // addFareMasterArray: masterArrayData,
        isServiceAvailable: serviceAvailable,
      };
      // console.log(FinalPayload);
      const resp = await dispatch(updateFare(FinalPayload, initialValues?._id));
      if (resp) {
        message.success("Fare Updated successfully");
        navigate(-1);
      }
    }
  };

  let data = [
    {
      key: 1,
      name: "Tier 1",
      radius: tierDetails[0]?.radius ? tierDetails[0]?.radius : 1500,
      from: 0.0,
      to: (tierDetails[0]?.radius ? tierDetails[0]?.radius : 1500) / 1000,
    },
    {
      key: 2,
      name: "Tier 2",
      radius: tierDetails[1]?.radius ? tierDetails[1]?.radius : 1500,
      from: (tierDetails[0]?.radius ? tierDetails[0]?.radius : 1500) / 1000,
      to:
        ((tierDetails[0]?.radius ? tierDetails[0]?.radius : 1500) +
          (tierDetails[1]?.radius ? tierDetails[1]?.radius : 1500)) /
        1000,
    },
  ];

  let dataNight = [
    {
      key: 1,
      name: "Tier 1",
      radius: tierDetailsNight[0]?.radius ? tierDetailsNight[0]?.radius : 1500,
      from: 0.0,
      to:
        (tierDetailsNight[0]?.radius ? tierDetailsNight[0]?.radius : 1500) /
        1000,
    },
    {
      key: 2,
      name: "Tier 2",
      radius: tierDetailsNight[1]?.radius ? tierDetailsNight[1]?.radius : 1500,
      from:
        (tierDetailsNight[0]?.radius ? tierDetailsNight[0]?.radius : 1500) /
        1000,
      to:
        ((tierDetailsNight[0]?.radius ? tierDetailsNight[0]?.radius : 1500) +
          (tierDetailsNight[1]?.radius ? tierDetailsNight[1]?.radius : 1500)) /
        1000,
    },
  ];

  const updateTierData = (tierDetails, tierDetailsNight) => {
    data = [
      {
        key: 1,
        name: "Tier 1",
        radius: tierDetails[0]?.radius ? tierDetails[0]?.radius : 1500,
        from: 0.0,
        to: (tierDetails[0]?.radius ? tierDetails[0]?.radius : 1500) / 1000,
      },
      {
        key: 2,
        name: "Tier 2",
        radius: tierDetails[1]?.radius ? tierDetails[1]?.radius : 1500,
        from: (tierDetails[0]?.radius ? tierDetails[0]?.radius : 1500) / 1000,
        to:
          ((tierDetails[0]?.radius ? tierDetails[0]?.radius : 1500) +
            (tierDetails[1]?.radius ? tierDetails[1]?.radius : 1500)) /
          1000,
      },
    ];
    setTierData(data);

    dataNight = [
      {
        key: 1,
        name: "Tier 1",
        radius: tierDetailsNight[0]?.radius
          ? tierDetailsNight[0]?.radius
          : 1500,
        from: 0.0,
        to:
          (tierDetailsNight[0]?.radius ? tierDetailsNight[0]?.radius : 1500) /
          1000,
      },
      {
        key: 2,
        name: "Tier 2",
        radius: tierDetailsNight[1]?.radius
          ? tierDetailsNight[1]?.radius
          : 1500,
        from:
          (tierDetailsNight[0]?.radius ? tierDetailsNight[0]?.radius : 1500) /
          1000,
        to:
          ((tierDetailsNight[0]?.radius ? tierDetailsNight[0]?.radius : 1500) +
            (tierDetailsNight[1]?.radius
              ? tierDetailsNight[1]?.radius
              : 1500)) /
          1000,
      },
    ];
    setTierDataNight(dataNight);
  };

  const handleOnChange = (key, value) => {
    if (key == 1) {
      data[key - 1].radius = parseInt(value);
      data[key - 1].to = parseInt(value) / 1000;
      data[key].from = parseInt(value) / 1000;
      data[key].to = (parseInt(value) + parseInt(tierData[key]?.radius)) / 1000;
      data[key].radius = tierData[key]?.radius;

      // data[key - 1].to = value / 1000;
      // data[key].from = value / 1000;
      // data[key].to =  value / 1000  ;
      setTierData(data);
    }
    if (key == 2) {
      data[key - 2].radius = tierData[key - 2]?.radius;
      data[key - 2].to = tierData[key - 2]?.to;
      data[key - 1].from = tierData[key - 2].to;
      data[key - 1].radius = value;
      data[key - 1].to =
        (parseInt(tierData[key - 2].radius) + parseInt(value)) / 1000;
      setTierData(data);
    }
  };
  const handleOnChangeNight = (key, value) => {
    if (key == 1) {
      dataNight[key - 1].radius = parseInt(value);
      dataNight[key - 1].to = parseInt(value) / 1000;
      dataNight[key].from = parseInt(value) / 1000;
      dataNight[key].to =
        (parseInt(value) + parseInt(tierDataNight[key]?.radius)) / 1000;
      dataNight[key].radius = tierDataNight[key]?.radius;

      // data[key - 1].to = value / 1000;
      // data[key].from = value / 1000;
      // data[key].to =  value / 1000  ;

      setTierDataNight(dataNight);
    }
    if (key == 2) {
      dataNight[key - 2].radius = tierDataNight[key - 2]?.radius;
      dataNight[key - 2].to = tierDataNight[key - 2]?.to;
      dataNight[key - 1].from = tierDataNight[key - 2]?.to;
      dataNight[key - 1].radius = value;
      dataNight[key - 1].to =
        (parseInt(tierDataNight[key - 2]?.radius) + parseInt(value)) / 1000;
      setTierDataNight(dataNight);
    }
  };
  const getPoints = (msg) => {
    // const finalPoints = points.map((latLng) => ({
    //   lat: typeof latLng.lat == "function" ? latLng.lat() : latLng.lat,
    //   lng: typeof latLng.lng == "function" ? latLng.lng() : latLng.lng,
    // }));
    // console.log("points", points);

    let finalPoints = points.map((point, index) => {
      return point.map((latLng) => ({
        lat: typeof latLng.lat == "function" ? latLng.lat() : latLng.lat,
        lng: typeof latLng.lng == "function" ? latLng.lng() : latLng.lng,
      }));
      //  console.log("pointttt",point);
    });

    // console.log("finalPoints", finalPoints);
    // var finalArray = points.map(function(obj) {
    //   return [obj.lat, obj.lng];
    // });

    var finalArray = points.map(function (point) {
      return point.map((latLng) => [
        typeof latLng.lat == "function" ? latLng.lat() : latLng.lat,
        typeof latLng.lng == "function" ? latLng.lng() : latLng.lng,
      ]);
    });

    // console.log("finalArray", finalArray);
    if (finalPoints.length == 0) {
      message.error("Minimium 1 area required");
    } else {
      if (msg == "airport") {
        setAirportMap(false);
        setAirportLocationPoints(finalPoints);
        form.setFieldsValue({
          airport_locations: finalArray.toString(),
        });
      } else {
        setVisible(false);
        setRailwayLocationPoints(finalPoints);
        form.setFieldsValue({
          railway_station_locations: finalArray,
        });
      }
    }
  };
  // console.log("poiiiints", points);
  const getSelectedPoints = (data) => {
    // console.log("selected points", data);
    setPoints(data);
  };
  const removeFareRow = (value) => {
    // console.log("values", value);
    let allData = [...masterArrayDayData];
    allData.pop();
    setMasterArrayDayData(allData);
    // let data = fareData.filter((d) => d.key != value.key);
    // setFareData(data);
  };

  const removeFareRowNight = (value) => {
    // console.log("values", value);
    let allData = [...masterArrayNightData];
    allData.pop();
    setMasterArrayNightData(allData);
    // let data = fareData.filter((d) => d.key != value.key);
    // setFareData(data);
  };

  const addRowInFareTable = () => {
    if (dayToKmError) {
      message.error(
        <div>
          You can't add row until <b>To Km</b> value less than <b>From Km</b>{" "}
        </div>
      );
    } else {
      const length = masterArrayDayData.length;
      // console.log("faredaar", fareData);
      const getToKm = masterArrayDayData[length - 1].toKm;

      if (getToKm == 100000) {
        message.info(
          <div>
            You can't add row until<strong> To Km</strong> is less than
            <strong> 100000</strong>
          </div>
        );
      } else {
        setMasterArrayDayData([
          ...masterArrayDayData,
          {
            fromKm: masterArrayDayData[length - 1].toKm,
            toKm: 100000,
            farePerKm: "",
            farePerMinute: "",
            facilityFee: "",
            adminCommission: "",
            captainCommission: "",
          },
        ]);
      }
    }
  };
  const addRowInFareTableNight = () => {
    if (nightToKmError) {
      message.error(
        <div>
          You can't add row until <b>To Km</b> value less than <b>From Km</b>{" "}
        </div>
      );
    } else {
      const length = masterArrayNightData.length;
      // console.log("faredaar", fareData);
      const getToKm = masterArrayNightData[length - 1].toKm;

      if (getToKm == 100000) {
        message.info(
          <div>
            You can't add row until<strong> To Km</strong> is less than
            <strong> 100000</strong>
          </div>
        );
      } else {
        setMasterArrayNightData([
          ...masterArrayNightData,
          {
            fromKm: masterArrayNightData[length - 1].toKm,
            toKm: 100000,
            farePerKm: "",
            farePerMinute: "",
            facilityFee: "",
            adminCommission: "",
            captainCommission: "",
          },
        ]);
      }
    }
  };
  const handleChangeToKm = (data, value, index) => {
    if (value <= 100000) {
      if (value > data.fromKm) {
        setDayToKmErrro(false);
        const allData = [...masterArrayDayData];
        const updateData = allData[index];

        updateData.toKm = parseFloat(value);

        allData[index] = updateData;
        // console.log(fareData.length, data.row);
        if (masterArrayDayData.length - 1 > index) {
          const nextRowData = allData[index + 1];
          nextRowData.fromKm = parseFloat(value);
          allData[index + 1] = nextRowData;
          // console.log("upadte next data", allData);
        }
        setMasterArrayDayData(allData);
      } else {
        setDayToKmErrro(true);
        setDayToKmErrorIndex(index);
        message.info(
          <div>
            <strong>To Km </strong> value should be greather than{" "}
            <strong>{data.fromKm}</strong>
          </div>
        );
      }
    } else {
      setDayToKmErrro(true);
      setDayToKmErrorIndex(index);
      message.info(
        <div>
          <strong>To Km </strong> value should not greather than{" "}
          <strong>100000</strong>
        </div>
      );
    }
  };
  const handleChangeToKmNight = (data, value, index) => {
    if (value <= 100000) {
      if (value > data.fromKm) {
        setNightToKmErrro(false);

        const allData = [...masterArrayNightData];
        const updateData = allData[index];

        updateData.toKm = parseFloat(value);

        allData[index] = updateData;
        // console.log(fareData.length, data.row);
        if (masterArrayNightData.length - 1 > index) {
          const nextRowData = allData[index + 1];
          nextRowData.fromKm = parseFloat(value);
          allData[index + 1] = nextRowData;
          // console.log("upadte next data", allData);
        }
        setMasterArrayNightData(allData);
      } else {
        setNightToKmErrro(true);
        setNightToKmErrorIndex(index);
        message.info(
          <div>
            <strong>To Km </strong> value should be greather than{" "}
            <strong>{data.fromKm}</strong>
          </div>
        );
      }
    } else {
      setNightToKmErrro(true);
      setNightToKmErrorIndex(index);
      message.info(
        <div>
          <strong>To Km </strong> value should not greather than{" "}
          <strong>100000</strong>
        </div>
      );
    }
  };
  const handleChangeCharges = (value, time, index, updatedkey) => {
    // console.log(value, time, index, updatedkey);
    if (time === "day") {
      const allData = [...masterArrayDayData];
      if (updatedkey == "baseFare") allData[index].baseFare = value;
      if (updatedkey == "adminCommission")
        allData[index].adminCommission = value;
      if (updatedkey == "captainCommission")
        allData[index].captainCommission = value;

      if (updatedkey == "facilityFee") allData[index].facilityFee = value;
      if (updatedkey == "farePerKm") allData[index].farePerKm = value;
      if (updatedkey == "farePerMinute") allData[index].farePerMinute = value;

      // console.log("allData", allData);
      setMasterArrayData(allData);
    }
    if (time === "night") {
      const allData = [...masterArrayNightData];
      if (updatedkey == "baseFare") allData[index].baseFare = value;
      if (updatedkey == "adminCommission")
        allData[index].adminCommission = value;
      if (updatedkey == "captainCommission")
        allData[index].captainCommission = value;

      if (updatedkey == "facilityFee") allData[index].facilityFee = value;
      if (updatedkey == "farePerKm") allData[index].farePerKm = value;
      if (updatedkey == "farePerMinute") allData[index].farePerMinute = value;

      // console.log("allData", allData);
      setMasterArrayNightData(allData);
    }
  };
  var columns = [
    {
      title: "Sr.No.",
      key: "_id",
      render: (data, index, id) => {
        return <span>{id + 1}</span>;
      },
      align: "center",
    },
    {
      title: "Tier Label",
      key: "tier",

      render: (data) => {
        return <span> {data.name}</span>;
      },

      align: "center",
    },
    {
      title: "Radius (Meters)",
      key: "radius",
      render: (data) => {
        return (
          <span>
            {" "}
            <Input
              defaultValue={parseInt(data.radius)}
              style={{ width: "15vw", border: "" }}
              onChange={(e) => handleOnChange(data.key, e.target.value)}
              min={1500}
              max={5000}
              step={100}
              type="number"
              onKeyDown={(evnt) => evnt.preventDefault()}
            />
          </span>
        );
      },
      editable: true,
      align: "center",
    },
    {
      title: "Distance ",
      key: "distance",
      render: (data) => {
        return (
          <span>
            {FixedToNDecimals(data.from)} Km <ArrowRightOutlined />{" "}
            {FixedToNDecimals(data.to)} Km
          </span>
        );
      },

      align: "center",
    },
  ];
  var columnsNight = [
    {
      title: "Sr.No.",
      key: "_id",
      render: (data, index, id) => {
        return <span>{id + 1}</span>;
      },
      align: "center",
    },
    {
      title: "Tier Label",
      key: "tier",

      render: (data) => {
        return <span> {data.name}</span>;
      },

      align: "center",
    },
    {
      title: "Radius (Meters)",
      key: "radius",
      render: (data) => {
        return (
          <span>
            {" "}
            <Input
              defaultValue={parseInt(data.radius)}
              style={{ width: "15vw", border: "" }}
              onChange={(e) => handleOnChangeNight(data.key, e.target.value)}
              min={1500}
              max={5000}
              step={100}
              type="number"
              onKeyDown={(evnt) => evnt.preventDefault()}
            />
          </span>
        );
      },
      editable: true,
      align: "center",
    },
    {
      title: "Distance ",
      key: "distance",
      render: (data) => {
        return (
          <span>
            {FixedToNDecimals(data.from)} Km <ArrowRightOutlined />{" "}
            {FixedToNDecimals(data.to)} Km
          </span>
        );
      },

      align: "center",
    },
  ];

  var fareColumn = [
    {
      title: "From Km",
      dataIndex: "fromKm",
      key: "fromKm",
      render: (data, index, index1) => {
        return <Input type="number" disabled value={data} step={"any"} />;
      },
      align: "center",
    },
    {
      title: "To Km",
      // dataIndex:"toKm",

      key: "toKm",
      render: (data, index, index1) => {
        return (
          <Input
            type="number"
            onChange={(e) => {
              handleChangeToKm(data, e.target.value, index1);
            }}
            style={{
              width: "100px",
              border:
                dayToKmError && dayToKmErrorIndex == index1
                  ? "1px solid red"
                  : "",
            }}
            defaultValue={data.toKm}
            min={data.fromKm + 1}
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            max={
              masterArrayDayData[index1 + 1]?.toKm
                ? masterArrayDayData[index1 + 1]?.toKm - 1
                : 100000
            }
            step={"any"}
          />
        );
      },
      align: "center",
    },

    {
      title: "Base Fare",
      key: "baseFare",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            step={"any"}
            onChange={(e) =>
              handleChangeCharges(e.target.value, "day", index, "baseFare")
            }
            // defaultValue={data.dayBaseFare}
            defaultValue={data.baseFare}
            disabled={index >= 1}
            placeholder={index >= 1 ? "Not Applicable" : ""}
            min={0}
            required
          />
        );
      },
      align: "center",
    },
    {
      title: "Rs/Km",
      key: "farePerKm",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            step={"any"}
            onChange={(e) =>
              handleChangeCharges(e.target.value, "day", index, "farePerKm")
            }
            placeholder={index < 1 ? "Not Applicable" : ""}
            disabled={index < 1}
            defaultValue={data.farePerKm}
            min={0}
            required
          />
        );
      },
      align: "center",
    },
    {
      title: "Rs/Min",
      key: "farePerMinute",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            defaultValue={data.farePerMinute}
            onChange={(e) =>
              handleChangeCharges(e.target.value, "day", index, "farePerMinute")
            }
            placeholder={index < 1 ? "Not Applicable" : ""}
            disabled={index < 1}
            min={0}
            required
            step={"any"}
          />
        );
      },
      align: "center",
    },
    {
      title: "Facility Fee (₹)",
      key: "facilityFee",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) =>
              handleChangeCharges(e.target.value, "day", index, "facilityFee")
            }
            defaultValue={data.facilityFee}
            min={0}
            required
            step={"any"}
          />
        );
      },
      align: "center",
    },
    {
      title: (
        <div>
          <span>
            Captain Fee (₹)
            {/* <img src="https://api.iconify.design/mingcute/currency-rupee-fill.svg?color=purple&height=20&width=25" /> */}
          </span>
        </div>
      ),
      key: "captainCommission",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            step={"any"}
            onChange={(e) =>
              handleChangeCharges(
                e.target.value,
                "day",
                index,
                "captainCommission"
              )
            }
            defaultValue={data.captainCommission}
            min={0}
            required
          />
        );
      },
      align: "center",
    },
    {
      title: "G-Auto Commission (%) ",
      key: "adminCommission",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            step={"any"}
            onChange={(e) =>
              handleChangeCharges(
                e.target.value,
                "day",
                index,
                "adminCommission"
              )
            }
            defaultValue={data.adminCommission}
            min={0}
            required
          />
        );
      },
      align: "center",
    },

    {
      title: "",
      key: "actions",
      render: (data, i, index) => {
        return (
          index > 1 &&
          masterArrayDayData.length - 1 == index && (
            <Button type="primary" danger onClick={() => removeFareRow(data)}>
              Remove
            </Button>
          )
        );
      },
    },
  ];
  var fareColumnNight = [
    {
      title: "From Km",
      dataIndex: "fromKm",
      key: "fromKm",
      render: (data, index, index1) => {
        return <Input type="number" disabled value={data} step={"any"} />;
      },
      align: "center",
    },
    {
      title: "To Km",
      // dataIndex:"toKm",

      key: "toKm",
      render: (data, index, index1) => {
        return (
          <Input
            type="number"
            onChange={(e) => {
              handleChangeToKmNight(data, e.target.value, index1);
            }}
            style={{
              width: "100px",
              border:
                nightToKmError && nightToKmErrorIndex == index1
                  ? "1px solid red"
                  : "",
            }}
            defaultValue={data.toKm}
            min={data.fromKm + 1}
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            max={
              masterArrayNightData[index1 + 1]?.toKm
                ? masterArrayNightData[index1 + 1]?.toKm - 1
                : 100000
            }
            step={"any"}
          />
        );
      },
      align: "center",
    },

    {
      title: "Base Fare",
      key: "baseFare",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) =>
              handleChangeCharges(e.target.value, "night", index, "baseFare")
            }
            defaultValue={data.baseFare}
            disabled={index >= 1}
            placeholder={index >= 1 ? "Not Applicable" : ""}
            min={0}
            required
            step={"any"}
          />
        );
      },
      align: "center",
    },
    {
      title: "Rs/Km",
      key: "farePerKm",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) =>
              handleChangeCharges(e.target.value, "night", index, "farePerKm")
            }
            defaultValue={data.farePerKm}
            placeholder={index < 1 ? "Not Applicable" : ""}
            disabled={index < 1}
            min={0}
            step={"any"}
            required
          />
        );
      },
      align: "center",
    },
    {
      title: "Rs/Min",
      key: "farePerMinute",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) =>
              handleChangeCharges(
                e.target.value,
                "night",
                index,
                "farePerMinute"
              )
            }
            defaultValue={data.farePerMinute}
            placeholder={index < 1 ? "Not Applicable" : ""}
            disabled={index < 1}
            min={0}
            required
            step={"any"}
          />
        );
      },
      align: "center",
    },
    {
      title: "Facility Fee (₹)",
      key: "facilityFee",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) =>
              handleChangeCharges(e.target.value, "night", index, "facilityFee")
            }
            defaultValue={data.facilityFee}
            min={0}
            step={"any"}
            required
          />
        );
      },
      align: "center",
    },
    {
      title: (
        <div>
          <span>
            Captain Fee (₹)
            {/* <img src="https://api.iconify.design/mingcute/currency-rupee-fill.svg?color=purple&height=20&width=25" /> */}
          </span>
        </div>
      ),
      key: "captainCommission",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            step={"any"}
            onChange={(e) =>
              handleChangeCharges(
                e.target.value,
                "night",
                index,
                "captainCommission"
              )
            }
            defaultValue={data.captainCommission}
            min={0}
            required
          />
        );
      },
      align: "center",
    },
    {
      title: "G-Auto Commission (%)",
      key: "adminCommission",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            step={"any"}
            onChange={(e) =>
              handleChangeCharges(
                e.target.value,
                "night",
                index,
                "adminCommission"
              )
            }
            defaultValue={data.adminCommission}
            min={0}
            required
          />
        );
      },
      align: "center",
    },
    {
      title: "",
      key: "actions",
      render: (data, i, index) => {
        return (
          index > 1 &&
          masterArrayNightData.length - 1 == index && (
            <Button
              type="primary"
              danger
              onClick={() => removeFareRowNight(data)}
            >
              Remove
            </Button>
          )
        );
      },
    },
  ];
  return (
    <>
      <div className="common_layout content-container ">
        {initialValues._id && !loading && (
          <>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <h1 className="heading">Update Fare</h1>
                <BreadCrumb />
              </Col>

              <Col lg={12} md={12} sm={12} xs={12} style={{ textAlign: "end" }}>
                <span> Service Available </span> :
                <Switch
                  checked={serviceAvailable}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  style={{ margin: "20px" }}
                  disabled
                  // disabled={!checkAction("city", "edit")}
                  onChange={(e) => setServiceAvailable(e)}
                >
                  {" "}
                </Switch>{" "}
              </Col>
            </Row>
            <Divider />

            <Card>
              <Form
                layout="vertical"
                form={form}
                initialValues={initialValues}
                onFinish={handleSubmit}
              >
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item label="City">
                      <Input
                        style={{
                          background: "white",
                          color: "black",
                        }}
                        disabled
                        defaultValue={initialValues?.city_id?.name}
                      ></Input>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Vehicle Type">
                      <Input
                        style={{
                          background: "white",
                          color: "black",
                        }}
                        disabled
                        value={initialValues?.vehicle_type_id?.name}
                      ></Input>
                    </Form.Item>
                  </Col>
                </Row>

                <Collapse defaultActiveKey={"1"}>
                  <Panel
                    header="Day Timing & Charges"
                    key={"1"}
                    style={{ background: "#77bff9" }}
                  >
                    <Row gutter={20}>
                      <Col lg={6} md={6} sm={24}>
                        <Form.Item label="Day Start Time(Hour:Minute)">
                          <Input
                            type="time"
                            style={{
                              width: "70%",
                              textAlign: "center",
                              borderRadius: "70px",
                              maxHeight: "40px",
                            }}
                            defaultValue={nightEndTime ? nightEndTime : "00:00"}
                            onChange={(e) => setNightEndTime(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={6} md={6} sm={24}>
                        <Form.Item
                          label={"End Time"}
                          style={{
                            marginLeft: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            {dayEndTime
                              ? dayEndTime
                              : initialValues.night_start_time}
                          </span>
                        </Form.Item>
                      </Col>
                    </Row>

                    <div>
                      <div style={{ minWidth: "850px" }}>
                        <Card style={{ border: "2px solid black" }}>
                          {/* {console.log("masterArrayData",masterArrayData)} */}
                          <Table
                            columns={fareColumn}
                            dataSource={masterArrayDayData}
                            pagination={false}
                          />
                          <br />
                          <Button
                            style={{ width: "100%", textAlign: "center" }}
                            onClick={() => addRowInFareTable()}
                          >
                            Add New Row
                          </Button>
                        </Card>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
                <br />
                <Collapse defaultActiveKey={"1"}>
                  <Panel
                    header="Night Timing & Charges"
                    key={"1"}
                    style={{ background: "#77bff9" }}
                  >
                    <Row gutter={20}>
                      <Col lg={6} md={6} sm={24}>
                        <Form.Item label="Night Start Time(Hour:Minute)">
                          <Input
                            type="time"
                            style={{
                              width: "70%",
                              textAlign: "center",
                              borderRadius: "70px",
                              maxHeight: "40px",
                            }}
                            defaultValue={dayEndTime ? dayEndTime : "00:00"}
                            onChange={(e) => setDayEndTime(e.target.value)}
                          />
                        </Form.Item>{" "}
                      </Col>{" "}
                      <Col lg={6} md={6} sm={24}>
                        <Form.Item
                          label={"End Time"}
                          style={{
                            marginLeft: "20px",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            {nightEndTime
                              ? nightEndTime
                              : initialValues.day_start_time}
                          </span>
                        </Form.Item>
                      </Col>
                    </Row>

                    <div>
                      <div style={{ minWidth: "850px" }}>
                        <Card style={{ border: "2px solid black" }}>
                          {/* {console.log("masterArrayData",masterArrayData)} */}
                          <Table
                            columns={fareColumnNight}
                            dataSource={masterArrayNightData}
                            pagination={false}
                          />
                          <br />
                          <Button
                            style={{ width: "100%", textAlign: "center" }}
                            onClick={() => addRowInFareTableNight()}
                          >
                            Add New Row
                          </Button>
                        </Card>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
                <br />
                <Collapse defaultActiveKey={"3"}>
                  <Panel
                    header="Additional Charges"
                    key={"3"}
                    style={{ background: "#77bff9" }}
                  >
                    <Row gutter={20}>
                      <Col lg={8} md={8}>
                        <Form.Item
                          label="Free Waiting Time(Hour:Minute:Second)"
                          name="free_waiting_time"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Free Waiting Time(Minute)",
                            },
                          ]}
                        >
                          {/* <TimePicker
                            onChange={(e, timestring) =>
                              setfreeWaitingTime(timestring)
                            }
                            // defaultValue={"00:00"}
                            // value={freeWaitingTime}
                            defaultValue={moment(
                              freeWaitingTime
                                ? freeWaitingTime
                                : initialValues.free_waiting_time,
                              "mm:ss"
                            )}
                            style={{ padding: 8 }}
                            format={"mm:ss"}
                            size={5}
                            showNow={false}
                            suffixIcon={<DownOutlined />}
                          /> */}

                          <Input
                            type="time"
                            style={{
                              width: "70%",
                              textAlign: "center",
                              borderRadius: "70px",
                              maxHeight: "40px",
                            }}
                            defaultValue={
                              freeWaitingTime
                                ? freeWaitingTime
                                : initialValues.free_waiting_time
                            }
                            step={1}
                            onChange={(e) => setfreeWaitingTime(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={8}>
                        <Form.Item
                          label="Minimum Waiting Time(Hour:Minute:Second)"
                          name="waiting_time"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Waiting Time(Minute)",
                            },
                          ]}
                        >
                          {/* <TimePicker
                            onChange={(e, timestring) =>
                              setWaitingTime(timestring)
                            }
                            defaultValue={moment(
                              waitingTime
                                ? waitingTime
                                : initialValues.waiting_time,
                              "mm:ss"
                            )}
                            style={{ padding: 8 }}
                            format={"mm:ss"}
                            size={5}
                            showNow={false}
                            suffixIcon={<DownOutlined />}
                          /> */}

                          <Input
                            type="time"
                            style={{
                              width: "70%",
                              textAlign: "center",
                              borderRadius: "70px",
                              maxHeight: "40px",
                            }}
                            defaultValue={
                              waitingTime
                                ? waitingTime
                                : initialValues.waiting_time
                            }
                            step={1}
                            onChange={(e) => setWaitingTime(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={8}>
                        <Form.Item
                          label={
                            <div>
                              {"Waiting Fare/Minute "}
                              <img src="https://api.iconify.design/mingcute/currency-rupee-fill.svg?color=purple&height=20&width=25" />
                            </div>
                          }
                          name="waiting_charges"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Waiting Fare",
                            },
                          ]}
                        >
                          <Input placeholder="Waiting Fare" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col lg={8} md={8}>
                        <Form.Item
                          label={
                            <div>
                              {"Airport Charge "}
                              <img src="https://api.iconify.design/mingcute/currency-rupee-fill.svg?color=purple&height=20&width=25" />
                            </div>
                          }
                          name={["airport_charges"]}
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Airport Charge",
                            },
                          ]}
                        >
                          <Input placeholder="Airport Charge" />
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={8}>
                        <Form.Item
                          label={
                            <div>
                              {"Railway Station Charge "}
                              <img src="https://api.iconify.design/mingcute/currency-rupee-fill.svg?color=purple&height=20&width=25" />
                            </div>
                          }
                          name={["railway_station_charges"]}
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Railway Station Charge",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Railway Station Charge"
                            type="number"
                            min={0}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={8}>
                        <Form.Item
                          label={
                            <div>
                              {"Update Route Charge"}
                              <img src="https://api.iconify.design/mingcute/currency-rupee-fill.svg?color=purple&height=20&width=25" />
                            </div>
                          }
                          name="update_route_charges"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Update Route Charge",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Update Route Charge"
                            type="number"
                            min={0}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={8}>
                        <Form.Item
                          label={
                            <div>
                              {"Airport Location  "}{" "}
                              <img src="https://api.iconify.design/iconoir/map.svg?height=20&width=25" />{" "}
                              {/* <span style={{color:"blue"}} >{points.length} area selected</span> */}
                            </div>
                          }
                          name={["airport_locations"]}
                          rules={[
                            {
                              required: true,
                              message: "Please Select Airport Location",
                            },
                          ]}
                        >
                          {/* <Button onClick={() => setVisible(true)}>
                        Choose Airport Location
                      </Button> */}
                          <TextArea
                            onClick={() => setAirportMap(true)}
                            onKeyDown={(e) => e.preventDefault()}
                          ></TextArea>
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={8}>
                        <Form.Item
                          label={
                            <div>
                              {"Railway Station Location "}{" "}
                              <img src="https://api.iconify.design/iconoir/map.svg?height=20&width=25" />{" "}
                            </div>
                          }
                          name={["railway_station_locations"]}
                          rules={[
                            {
                              required: true,
                              message: "Please Select Railway Station Location",
                            },
                          ]}
                        >
                          {/* <Button onClick={() => setVisible(true)}>
                        Choose Railway Station Location
                      </Button> */}
                          <TextArea
                            onClick={() => setVisible(true)}
                            onKeyDown={(e) => e.preventDefault()}
                          ></TextArea>
                        </Form.Item>
                      </Col>
                      {/* <Col lg={8} md={8}>
                        <Form.Item
                          label={
                            <div>
                              {"Parking Charge "}
                              <img src="https://api.iconify.design/mingcute/currency-rupee-fill.svg?color=purple&height=20&width=25" />
                            </div>
                          }
                          name="parking_charges"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Parking Charge",
                            },
                          ]}
                        >
                          <Input placeholder="Parking Charge" />
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={8}>
                        <Form.Item
                          label={
                            <div>
                              {"Cancellation Charge "}
                              <img src="https://api.iconify.design/mingcute/currency-rupee-fill.svg?color=purple&height=20&width=25" />
                            </div>
                          }
                          name={"cancellation_charges"}
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Cancellation Charge",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Cancellation Charge"
                            type="number"
                          />
                        </Form.Item>
                      </Col> */}
                    </Row>
                  </Panel>
                </Collapse>
                <br />
                <Collapse defaultActiveKey={"4"}>
                  <Panel
                    header={"Informatic Messages"}
                    key="4"
                    style={{ background: "#77bff9" }}
                  >
                    <Row gutter={20}>
                      <Col lg={8} md={8}>
                        <Form.Item
                          label={<div>{"Fare Calulate Information"}</div>}
                          name={"fareInfo"}
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Fare Policy",
                            },
                          ]}
                        >
                          <TextArea />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
                <br />
                <Collapse defaultActiveKey={"4"}>
                  <Panel
                    header={`Day Time Tier Configuration (${nightEndTime} - ${dayEndTime}) `}
                    key="4"
                    style={{ background: "#77bff9" }}
                  >
                    <Form.Item name={"tierDetails"}>
                      <Table
                        dataSource={tierData}
                        columns={columns}
                        scroll={{ x: 10 }}
                        pagination={false}
                      />
                    </Form.Item>
                  </Panel>
                </Collapse>
                <br />
                <Collapse defaultActiveKey={"5"}>
                  <Panel
                    header={`Night Time Tier Configuration (${dayEndTime} - ${nightEndTime}) `}
                    key="5"
                    style={{ background: "#77bff9" }}
                  >
                    <Form.Item name={"tierDetails"}>
                      <Table
                        dataSource={tierDataNight}
                        columns={columnsNight}
                        scroll={{ x: 10 }}
                        pagination={false}
                      />
                    </Form.Item>
                  </Panel>
                </Collapse>
                <Button
                  htmlType="submit"
                  style={{
                    marginTop: "50px",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                  type="primary"
                >
                  Update Fare
                </Button>
              </Form>
            </Card>
            <Modal
              open={visible}
              width={"60vw"}
              bodyStyle={{ height: "70vh" }}
              onCancel={() => setVisible(false)}
              onOk={() => getPoints("railway")}
              okButtonProps={{ disabled: !showOkButton }}
            >
              <Map
                city={cityLocation}
                points={getSelectedPoints}
                type={"Railway Station"}
                defaultPoints={railwayLoationPoints}
                setShowOkButton={setShowOkButton}
              />
            </Modal>
            <Modal
              open={airportMap}
              width={"60vw"}
              bodyStyle={{ height: "70vh" }}
              onCancel={() => setAirportMap(false)}
              onOk={() => getPoints("airport")}
              okButtonProps={{ disabled: !showOkButton }}
            >
              <Map
                city={cityLocation}
                points={getSelectedPoints}
                type={"Airport"}
                defaultPoints={airportLoationPoints}
                setShowOkButton={setShowOkButton}
              />
            </Modal>
          </>
        )}
      </div>
      {loading && <Loader />}
    </>
  );
};

export default ManageFareUpdate;
