import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const { getSetting } = actions;

export const getSettings = (id) => {
  return async (dispatch) => {
    const resp = await DataService.get(API.settings.get);

    if (!resp.data.error) {
      dispatch(getSetting(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const updateSettngs = (payload, id) => {
  return async (dispatch) => {
    const resp = await DataService.put(API.settings.update + id, payload);
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const updateDocumentStatus = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(API.settings.updateDocument, payload);
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const updateQRtype = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(API.settings.updateQR, payload);
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const createQRType = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.settings.postQR, payload);
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const deleteQRType = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.settings.deleteQR + id);
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const deleteDocument = (type) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.settings.deleteDocument + type);
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
