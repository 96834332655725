import React from "react";
import XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const ExportReports = (data, FileType, FileName) => {
  const exportData =
    data &&
    data.map((elt, index) => {
      return {
        // t("srNo"): index + 1,
        ...elt,
      };
    });
  // Generate headers if not provided
  let headers;

  if (data) {
    headers = Object.keys(data && (data.length > 0) && data[0]);
  }
  // Export PDF
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = FileName;
    const headersPDF = [headers && headers.map((header) => [header])];
    const dataPDF = exportData.map((elt) =>
      headers.map((header) => elt[header])
    );
    let content = {
      startY: 50,
      head: headersPDF,
      body: dataPDF,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`${title}.pdf`);
  };

  // Export Excel
  const exportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, `${FileName}.xlsx`);
  };

  // Export CSV
  const exportCSV = () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += headers.join(",") + "\r\n";
    exportData.forEach((elt) => {
      headers.forEach((header) => {
        csvContent += elt[header] + ",";
      });
      csvContent += "\r\n";
    });
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${FileName}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  const handleExport = () => {
    switch (FileType) {
      case "pdf":
        exportPDF();
        break;
      case "excel":
        exportExcel();
        break;
      case "csv":
        exportCSV();
        break;
      default:
        break;
    }
  };

  return handleExport;
};

export default ExportReports;
