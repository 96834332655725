import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_URL_PREFIX } from "../../config/dataService/dataService";
import { getCityData } from "../../redux/Masters/City/actionCreatore";
import { getVehicleTypesData } from "../../redux/VehicleTypes/actionCreatore";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";

function AddCoupon(props) {
  const [discountType, setDiscountType] = useState("");
  const dispatch = useDispatch();
  const [details,setDetails]=useState({})
  const [uploadimgURL, setUploadimgURL] = useState("");

  useEffect(() => {
    reteriveCityList();
    fetchVehicleTypes();
  }, []);

  const reteriveCityList = async () => {
    await dispatch(getCityData(""));
  };
  let cityList = useSelector((state) => state.city.cityData);
  cityList = cityList.sort((a, b) => a.name.localeCompare(b.name));
  // console.log("cityList", cityList);
  const fetchVehicleTypes = async () => {
    await dispatch(getVehicleTypesData());
  };

  const vehicleTypes = useSelector((state) => state.vehicleType.vehicleData);

  const handleSubmit = async (values) => {
    console.log(values);
  };

  return (
    <div className="common_layout content-container">
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h1 className="heading">Create Coupon</h1>

          {/* <p> Here You Can Add Fare City and Vehicle Type wise</p> */}
          <BreadCrumb />
        </Col>
      </Row>
      <Divider />
      <Card>
        <Form layout="vertical" onFinish={handleSubmit}>
          <Row gutter={20}>
            <Col xxl={8} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="City"
                rules={[{ required: true, message: "Please Select City" }]}
                name="city"
              >
                <Select mode="multiple" placeholder="Choose city">
                  {cityList &&
                    cityList?.map((city) => {
                      return (
                        <Select.Option value={city._id}>
                          {city.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col xxl={8} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="Vehicle Type"
                rules={[{ required: true, message: "Please Select Vehicle " }]}
                name="vehicle"
              >
                <Select mode="multiple" placeholder="Choose Vehicle">
                  {vehicleTypes &&
                    Array.isArray(vehicleTypes) &&
                    vehicleTypes.map((vehicle) => {
                      return (
                        <Select.Option value={vehicle._id}>
                          {vehicle.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col xxl={8} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="Discount Type"
                rules={[
                  { required: true, message: "Please Select Discount Type " },
                ]}
                name="discountType"
              >
                <Select
                  placeholder="Enter Discount Type"
                  onSelect={(value) => setDiscountType(value)}
                >
                  <Select.Option value="flat">Flat Discount</Select.Option>
                  <Select.Option value="percentage">
                    Percentage Discount
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xxl={8} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="Discount Amount (₹)"
                rules={[{ required: true, message: "Please Enter Amount " }]}
                name="amount"
              >
                <Input type={"number"} placeholder="Enter Amount" />
              </Form.Item>
            </Col>
            {discountType == "percentage" && (
              <Col xxl={8} lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Discount Percentage (%)"
                  rules={[
                    {
                      required: discountType == "percentage" ? true : false,
                      message: "Please Enter Discount Percentage ",
                    },
                  ]}
                  name="discountPercentage"
                >
                  <Input
                    type="number"
                    placeholder="Enter Discount Percentage"
                  />
                </Form.Item>
              </Col>
            )}
            <Col xxl={8} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="Minimum Fare Amount (₹)"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Minimum Fare Amount ",
                  },
                ]}
                name="minimumFareAmount"
              >
                <Input type="number" placeholder="Enter Minimum Fare Amount" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xxl={8} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="Start Date & Time"
                rules={[
                  {
                    required: true,
                    message: "Please Select Start Date & Time ",
                  },
                ]}
                name="startTime"
              >
                <DatePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  //   disabledDate={disabledDate}
                  //   disabledTime={disabledDateTime}
                  showTime={{ defaultValue: dayjs("00:00:00", "HH:mm:ss") }}
                  style={{ height: "50px" }}
                />
              </Form.Item>
            </Col>

            <Col xxl={8} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="End Date & Time"
                rules={[
                  {
                    required: true,
                    message: "Please Select End Date & Time ",
                  },
                ]}
                name="endTime"
              >
                <DatePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  //   disabledDate={disabledDate}
                  //   disabledTime={disabledDateTime}
                  showTime={{ defaultValue: dayjs("00:00:00", "HH:mm:ss") }}
                  style={{ height: "50px" }}
                />
              </Form.Item>
            </Col>
            <Col xxl={8} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="Status"
                rules={[{ required: true, message: "Please Select Status " }]}
                name="sttaus"
              >
                <Select placeholder="Choose Status">
                  <Select.Option value="active">Active</Select.Option>
                  <Select.Option value="deactive">Deactive</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xxl={8} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="Coupon Code"
                rules={[
                  { required: true, message: "Please  Enter Coupon Code " },
                ]}
                name="code"
              >
                <Input type="text" placeholder="Enter Coupon Code" />
              </Form.Item>
            </Col>
            <Col xxl={8} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="Coupon Name"
                rules={[
                  { required: true, message: "Please  Enter Coupon Name " },
                ]}
                name="name"
              >
                <Input type="text" placeholder="Enter Coupon Name" />
              </Form.Item>
            </Col>
            <Col xxl={8} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="Coupon Description"
                rules={[
                  {
                    required: true,
                    message: "Please  Enter Coupon Description ",
                  },
                ]}
                name="description"
              >
                <TextArea type="text" placeholder="Enter Coupon Description" />
              </Form.Item>
            </Col>
            <Form.Item
            name="image"
            // getValueFromEvent={normFile}
            label={
              <>
                Coupon Image/Banner
                <span style={{ marginLeft: "3px" }}></span>
              </>
            }
            rules={[
              {
                required: true,
                message: "Please choose Image",
              },
            ]}
          >
            <div className="vehicle_image">
              <label
                htmlFor="input-file"
                style={{
                  padding: details?.couponImage ? "0px" : "38px 26px",
                }}
              >
                {details?.couponImage ? (
                  <>
                    {" "}
                    {console.log("detaisl", details)}
                    {(uploadimgURL !== "") & (uploadimgURL != undefined) ? (
                      <Avatar
                        size={100}
                        src={URL.createObjectURL(uploadimgURL)}
                      />
                    ) : (
                      <Avatar
                        size={100}
                        icon={
                          <img
                            src={IMAGE_URL_PREFIX + details?.couponImage}
                            // crossOrigin="anonymous"
                          />
                        }
                      ></Avatar>
                    )}
                  </>
                ) : (
                  <span>Upload</span>
                )}
              </label>
              <Input
                type="file"
                onChange={(event) => {
                  // setUploadimgURL(URL.createObjectURL(event.target.files[0]));
                  setUploadimgURL(event.target.files[0]);
                  setDetails({ couponImage: event.target.files[0] });
                }}
                accept="image/jpeg,image/png,"
                id="input-file"
                aria-label="Upload File"
              />
            </div>
          </Form.Item>
          </Row>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Button
              type="primary"
              style={{
                width: "20%",
                height: "5%",
                fontSize: "18px",
                fontWeight: "bold",
              }}
              htmlType="submit"
            >
              Create
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
}

export default AddCoupon;
