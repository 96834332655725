import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";
import actions from "./actions";
const { fetchForSuperadmin } = actions;

export const getAllCommission = () => {
  return async (dispatch) => {
    const payload = {
      type: [
        "facilityFee",
        "captainCharges",
        "WaitingCharges",
        "Pickup Location Difference",
        "adminCommission",
      ],
    };
    const resp = await DataService.post(
      API.commission.getForSuperAdmin,
      payload
    );
    if (!resp.data.error) {
      dispatch(fetchForSuperadmin(resp.data.data));
      return true;
    } else {
      return false;
    }
  };
};
export const getCommsionForAdmin = () => {
  return async (dispatch) => {
    const payload = {
      type: [
        "facilityFee",
        "captainCharges",
        "WaitingCharges",
        "Pickup Location Difference",
      ],
    };
    const resp = await DataService.post(API.commission.getForAdmin, payload);
    if (!resp.data.error) {
      dispatch(fetchForSuperadmin(resp.data.data));
      return true;
    } else {
      return false;
    }
  };
};
export const getCommsionForCaptain = (captainId) => {
  return async (dispatch) => {
    const payload = {
      type: [
        "facilityFee",
        "captainCharges",
        "WaitingCharges",
        "Pickup Location Difference",
      ],
    };
    if (captainId) {
      payload.captain_id = captainId;
    }
    const resp = await DataService.post(API.commission.getForCaptain, payload);
    if (!resp.data.error) {
      dispatch(fetchForSuperadmin(resp.data.data));
      return true;
    } else {
      return false;
    }
  };
};
