import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const { getData } = actions;

export const getAppData = ({
  pageNo,
  pageSize,
  device,
  sortKey,
  sortValue,
  user,
}) => {
  return async (dispatch) => {
    const queryParameter =
      "?page=" +
      pageNo +
      "&limit=" +
      pageSize +
      "&device_type=" +
      device +
      "&key=" +
      sortKey +
      "&value=" +
      sortValue +
      "&role=" +
      user;
    const resp = await DataService.get(API.AppData.get + queryParameter);
    // console.log(resp,"dd");
    if (!resp.data.error) {
      dispatch(getData(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
