import actions from "./actions";
const { GET_COMMISSION } = actions;

const initialState = {
  commission: {},
};

const commissionReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_COMMISSION:
      return {
        ...state,
        commission: data,
      };
    default:
      return state;
  }
};

export default commissionReducer;
