import {
  UserOutlined,
  EditOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  ADMIN,
  IMAGE_URL_PREFIX,
  SUPERADMIN,
} from "../../../config/dataService/dataService";
import {
  getDriverCenterList,
  getDriverCenterListWithoutPagination,
} from "../../../redux/deriverCenter/actionCreatore";
import {
  approveDocument,
  approveDriver,
  getDriverById,
  getDriverDocuments,
  rejectDocument,
  rejectDriver,
  updateDriverRequest,
} from "../../../redux/drivers/actionCreatore";
import { getCityData } from "../../../redux/Masters/City/actionCreatore";
import {
  getVehicleModelsData,
  getVehicleTypesData,
} from "../../../redux/VehicleTypes/actionCreatore";
import { getItem } from "../../../utility/localStorageControl";
import BreadCrumb from "../../BreadCrumb.js/BreadCrumb";
import Spinner from "../../loader/Spinner";
import { SocketContext } from "../../socket/socketContext";
import { getDriverRejectReasonsData } from "../../../redux/Masters/deleteReasons/actionCreatore";
const { Panel } = Collapse;
const Request = () => {
  const { driverId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(5);
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [disableApprove, setDisableApprove] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectTitle, setRejectTitle] = useState("");
  const [documentId, setDocumentId] = useState();
  const [selectedCity, setSelectedCity] = useState("");
  const [showApprove, setShowApprove] = useState(true);
  const [showReject, setShowReject] = useState(true);
  const [insuranceDate, setInsuranceDate] = useState("");
  const [insurnaceReuiredError, setInsuranceRequiredError] = useState(false);
  const [editable, setEditable] = useState(false);
  const navigate = useNavigate();
  const socket = useContext(SocketContext);
  const user = getItem("user_details");
  const handleCancel = () => {
    setIsModalOpen(false);
    setRejectModal(false);
    setRejectTitle("");
    // form.resetFields();
    setSelectedCity("");
  };
  useEffect(() => {
    reteriveDocuments();
    reteriveDriverById();

    reteriveCityList();
    reteriveVehicleType();
    // fetchVehicleModels();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (socket) {
      socket.emit("readRecord", {
        userId: user._id,
        recordType: "driver_request",
        driver_id: driverId,
      });

      socket.on("recordReaded", (data) => {
        if (data.isRead) {
          socket.emit("getUnreadRecordCount", { userId: user._id });

          socket.on("unreadRecordCount", (data) => {
            // setLocation(data.location.coordinates);
            // setLoading(false)
          });
        }
      });
    }
  }, [socket]);
  const fetchReasons = async () => {
    setLoading(true);
    const resp = await dispatch(getDriverRejectReasonsData(1, 100));
    if (resp) {
      setLoading(false);
    }
  };

  const reasonsList = useSelector(
    (state) => state.profileDeleteReason.driverRejectResons
  );
  const reteriveDriverCenterList = async () => {
    setPageSize(pageSize + 5);
    await dispatch(getDriverCenterList("", pageNo, pageSize, selectedCity));
  };

  const captainList = useSelector(
    (state) => state.captain.driverCenterListDataWithoutPagination
  );
  // console.log("captainList", captainList);
  const reteriveDriverById = async () => {
    const response = await dispatch(getDriverById(driverId));
    if (response) {
      if (response?.is_driver_approved == true) {
        setShowApprove(false);
      }
      if (response?.is_driver_rejected == true) {
        setShowReject(false);
      }
      setLoading1(false);
    }
  };
  const driverData = useSelector((state) => state.driver.driverById);
  // console.log("driverData", driverData);

  const reteriveVehicleType = async () => {
    setLoading(true);
    const resp = await dispatch(getVehicleTypesData());
    if (resp) {
      setLoading(false);
    }
  };

  const vehicleTypes = useSelector((state) => state.vehicleType.vehicleData);
  const fetchVehicleModels = async (vehicleTypeId) => {
    // setLoading(true);
    const resp = await dispatch(
      getVehicleModelsData({ status: "", vehicleType: vehicleTypeId })
    );
    if (resp) {
      // setLoading(false);
    }
  };

  const vehicleModel = useSelector((state) => state.vehicleType.vehicleModel);

  useEffect(() => {
    form.setFieldsValue({
      firstName: driverData?.firstName,
      lastName: driverData?.lastName,
      email: driverData?.email,
      countryCode: driverData?.countryCode,
      phoneNumber: driverData?.phoneNumber,
      driverRegisteredCity: driverData?.city_id?._id,
      vehicle_number: driverData?.vehicle_number,
      vehicle_type_id: driverData?.vehicle_type_id?._id,
      insurance_valid_till: driverData.insurance_valid_till
        ? dayjs(driverData.insurance_valid_till)
        : "",
      vehicle_model_id: driverData?.vehicle_model_id?._id,
    });
    setInsuranceDate(
      moment(driverData.insurance_valid_till).format("YYYY-MM-DD")
    );
    fetchVehicleModels(
      driverData?.vehicle_type_id?._id ? driverData?.vehicle_type_id?._id : ""
    );
  }, [driverData]);
  const reteriveDocuments = async () => {
    const response = await dispatch(getDriverDocuments(driverId));

    if (response) {
      let count = 0;
      for (const value in response) {
        if (
          response[value].is_verified == false &&
          response[value].type !== "police_verification_card"
        ) {
          count++;
        }
      }
      if (count >= 1) {
        setDisableApprove(true);
      } else {
        setDisableApprove(false);
      }

      if (response.length == 0) {
        setDisableApprove(true);
      }

      setLoading(false);
    }
  };
  const documentsData = useSelector((state) => state.driver.driverDocuments);
  // console.log("documentsData", documentsData);

  const reteriveCityList = async () => {
    await dispatch(getCityData(""));
  };
  let cityList = useSelector((state) => state.city.cityData);
  cityList = cityList?.sort((a, b) => a.name?.localeCompare(b.name));

  const handleSearch = async (value) => {
    setSelectedCity("");
    await dispatch(getCityData(value));
  };

  const handleSelectedCity = async (value) => {
    setSelectedCity(value);
    form.setFieldsValue({
      captain: null,
    });
    await dispatch(
      getDriverCenterListWithoutPagination({
        search: "",
        cityId: value,
        captainId: [],
      })
    );
  };

  const fetchCaptains = async (value) => {
    await dispatch(
      getDriverCenterListWithoutPagination({
        search: "",
        cityId: value,
        captainId: [],
      })
    );
  };

  const handleScroll = async () => {
    await dispatch(getDriverCenterList("", pageNo, pageSize, selectedCity));
  };

  const verifyDocument = async (document) => {
    // console.log("document", document);
    setLoading(false);
    const response = await dispatch(
      approveDocument(document._id, { is_verified: true })
    );
    if (response) {
      // const response1 = await dispatch(getDriverDocuments(driverId));
      // if (response1) {
      //   setLoading(false);
      // }
      message.success("Document Verified Successfully");
      reteriveDocuments();
    }
  };

  const handleSelectedVehicleType = (value) => {
    fetchVehicleModels(value);
    form.setFieldsValue({
      vehicle_model_id: "",
    });
  };

  const handleReject = async (values) => {
    console.log(values);
    if (rejectTitle == "Driver Reject") {
      const resp = await dispatch(
        rejectDriver(driverId, {
          driver_rejected_reason: values.reason,
          is_driver_approved: false,
          driver_rejection_reason_master_id:
            values?.driver_rejection_reason_master_id,
        })
      );
      if (resp) {
        form.resetFields();
        setRejectModal(false);

        message.success("Driver has been rejected");
        navigate(-1);

        setShowReject(false);
        setShowApprove(true);
      }
    }
    if (rejectTitle == "Document Reject") {
      const resp = await dispatch(
        rejectDocument(documentId, {
          rejected_reason: values.reason,
          is_verified: false,
        })
      );
      if (resp) {
        form.resetFields();
        message.success("Document Rejected Successfully");

        setRejectModal(false);
        setRejectTitle("");
        reteriveDocuments();
      }
    }
  };

  const handleDriverApprove = async () => {
    if (insuranceDate == "") {
      setInsuranceRequiredError(true);
      message.error("Please enter insurance validity date");
    } else if (!driverData?.city_id?.name) {
      message.error("Driver's city is missing");
      // navigate.push(`${user.role}/manageDriver/driverRequests/`)
    } else {
      const resp = await dispatch(
        approveDriver(driverId, {
          is_driver_approved: true,
          insurance_valid_till: insuranceDate,
        })
      );
      if (resp) {
        message.success("Driver has been approved");
        navigate(-1);
        form.resetFields();
        setIsModalOpen(false);
        setShowApprove(false);
        setShowReject(true);
      }
    }
  };
  const { t } = useTranslation();

  const handleDateChange = (date, dateString) => {
    setInsuranceDate(dateString);
  };
  const handleEdit = async (values) => {
    values.insurance_valid_till = insuranceDate;
    if (!values.email) {
      delete values.email;
    }
    const resp = await dispatch(updateDriverRequest(driverId, values));
    if (resp) {
      setEditable(false);
    }
  };
  const columns = [
    {
      title: "Sr.No ",
      render: (data, record, index) => {
        return <span style={{ fontWeight: "100" }}>{index + 1}</span>;
      },
    },
    {
      title: "Document Name ",
      render: (data) => {
        return (
          <div style={{ fontWeight: "100" }}>
            <span>{data.type === "adhaar_card" && "Aadhar Card"}</span>
            <span>
              {data.type === "police_verification_card" &&
                "Police Verification Doc"}
            </span>
            <span>{data.type === "insurance" && "Insurance"}</span>
            <span>{data.type === "driving_license" && "Driving Licence"}</span>
            <span>{data.type === "rc_book" && "RC Book"}</span>
          </div>
        );
      },
    },
    {
      title: "Document  ",
      render: (data) => {
        const type = data?.url?.substring(data?.url?.lastIndexOf(".") + 1);
        return (
          <span style={{ fontWeight: "100" }}>
            {data?.url ? (
              type == "pdf" ? (
                <a href={IMAGE_URL_PREFIX + data?.url} target={"_blank"}>
                  <img
                    src="https://cdn4.iconfinder.com/data/icons/CS5/512/ACP_PDF%202_file_document.png"
                    width={50}
                  />
                </a>
              ) : (
                <Image
                  src={IMAGE_URL_PREFIX + data.url}
                  width={60}
                  height={50}
                />
              )
            ) : (
              "Document not uploaded"
            )}
          </span>
        );
      },
    },

    {
      title: "Verification Status",
      render: (data) => (
        <span>
          {(data?.is_rejected == false) & (data?.is_verified == false) ? (
            <span
              style={{
                background: "yellow",
                // color: "#8B8000",
                padding: 5,
                borderRadius: 10,
                fontWeight: 100,
              }}
            >
              {" "}
              {data?.is_reupload == true ? "Reupload" : "Pending"}
            </span>
          ) : data?.is_rejected ? (
            <span
              style={{
                background: "red",
                color: "white",
                padding: 5,
                borderRadius: 10,
              }}
            >
              Rejected
            </span>
          ) : (
            data?.is_verified && (
              <span
                style={{
                  background: "green",
                  color: "white",
                  padding: 5,
                  borderRadius: 10,
                }}
              >
                Verified
              </span>
            )
          )}
        </span>
      ),
    },
    {
      title: t("action"),
      dataIndex: "",
      key: "x",
      render: (data, target) => {
        return (
          <div style={{ fontWeight: "100" }}>
            {(data?.is_rejected == false) & (data?.is_verified == false) ? (
              data?.is_uploaded || data?.is_reupload ? (
                <>
                  <Button
                    style={{ background: "#1cf81c7a" }}
                    onClick={() => verifyDocument(data)}
                  >
                    Valid
                  </Button>
                  <Button
                    style={{ marginLeft: 10, background: "#ff00008c" }}
                    onClick={() => {
                      setRejectModal(true);
                      setRejectTitle("Document Reject");
                      setDocumentId(data._id);
                    }}
                  >
                    Invalid
                  </Button>
                </>
              ) : (
                <></>
              )
            ) : data.is_rejected ? (
              <Button
                style={{ background: "#1cf81c7a" }}
                onClick={() => verifyDocument(data)}
              >
                Valid
              </Button>
            ) : (
              data?.is_verified && (
                <Button
                  style={{ marginLeft: 10, background: "#ff00008c" }}
                  onClick={() => {
                    setRejectModal(true);
                    setRejectTitle("Document Reject");
                    setDocumentId(data._id);
                  }}
                >
                  Invalid
                </Button>
              )
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div
      className="common_layout content-container"
      style={
        {
          // margin: windowWidth > 991 ? "25px 100px 25px 100px" : "25px",
        }
      }
    >
      {loading || loading1 ? (
        <Spinner />
      ) : (
        <>
          <Row>
            <Col lg={20} md={12} sm={12} xs={12}>
              <h1 className="heading">Driver Request</h1>
              <div style={{ display: "flex" }}>
                {/* <ArrowLeftOutlined style={{ marginRight: "20px", fontSize: "20px", border: "2px solid #5a5b5e", padding: "2px 8px 2px 8px", color: 'white', background: "#48484a" }} onClick={() => navigate.goBack()} />  */}
                <BreadCrumb detailpage={driverData?.name} />
              </div>
            </Col>

            <Col lg={2} md={12} sm={12} xs={12}>
              <Button
                style={{ float: "right", display: showApprove ? "" : "none" }}
                className="ADD"
                key="1"
                size="large"
                type="primary"
                onClick={() => {
                  setIsModalOpen(true);
                  if (driverData?.captainDetails?.city_id) {
                    setSelectedCity(driverData?.captainDetails?.city_id);
                    fetchCaptains(driverData?.captainDetails?.city_id);
                  }
                  form.setFieldsValue({
                    name: driverData?.name,
                    city: driverData?.captainDetails?.city_id,
                    captain: driverData?.captainDetails?._id,
                  });
                }}
                // disabled={disableApprove}
              >
                Approve
              </Button>
            </Col>
            <Col lg={2} md={12} sm={12} xs={12}>
              <Button
                style={{
                  marginLeft: "12px",
                  display: showReject ? "" : "none",
                }}
                className="ADD"
                key="1"
                size="large"
                type="primary"
                danger
                onClick={() => {
                  setRejectModal(true);
                  setRejectTitle("Driver Reject");
                  fetchReasons();
                }}
              >
                Reject
              </Button>
            </Col>
          </Row>
          <Divider />
          <div style={{ justifyContent: "center", marginBottom: "20px" }}>
            <Card
              title="Driver Details"
              headStyle={{ background: "#00000047" }}
              extra={
                (user?.role == ADMIN || user?.role == SUPERADMIN) &&
                (!editable ? (
                  <Button onClick={() => setEditable(true)}>
                    <EditOutlined /> Edit
                  </Button>
                ) : (
                  <Button
                    htmlType="submit"
                    onClick={() => form.submit()}
                    type="primary"
                  >
                    Save
                  </Button>
                ))
              }
            >
              <Row gutter={20} style={{ marginBottom: "30px" }}>
                <Col xxl={4}>
                  <Card style={{ border: "none" }}>
                    <Avatar
                      size={180}
                      icon={
                        driverData && driverData.profile_image ? (
                          <img
                            src={IMAGE_URL_PREFIX + driverData.profile_image}
                            // crossOrigin="anonymous"
                            style={{ fontSize: "24px" }}
                            alt={"Driver Image"}
                          />
                        ) : (
                          <UserOutlined />
                        )
                      }
                    />
                  </Card>
                </Col>
              </Row>
              <Form form={form} onFinish={handleEdit}>
                <Row gutter={20} style={{ marginBottom: "30px" }}>
                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">First Name</label>
                      <Form.Item
                        name={"firstName"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter first name",
                          },
                        ]}
                      >
                        <Input
                          // defaultValue={driverData && driverData.name}
                          disabled={!editable}
                          style={{
                            background: "white",
                            cursor: "default",
                            fontWeight: "bold",
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel"> Last Name</label>
                      <Form.Item
                        name={"lastName"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter last name",
                          },
                        ]}
                      >
                        <Input
                          // defaultValue={driverData && driverData.name}
                          disabled={!editable}
                          style={{
                            background: "white",
                            cursor: "default",
                            fontWeight: "bold",
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24}>
                    <div>
                      <label className="userlabel">Email </label>
                      <Form.Item
                        name={"email"}
                        rules={[
                          {
                            pattern: new RegExp(
                              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                            ),
                            message: "Please enter valid email",
                          },
                        ]}
                      >
                        <Input
                          // defaultValue={driverData && driverData.email}
                          disabled={!editable}
                          style={{
                            background: "white",
                            cursor: "default",
                            fontWeight: "bold",
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">{t("phoneNo")}</label>
                      <Input.Group compact style={{ display: "flex" }}>
                        <Form.Item
                          // name={"countryCode"}
                          style={{ width: "15%" }}
                        >
                          <Input
                            defaultValue={
                              driverData && driverData.countryCode
                                ? driverData.countryCode
                                : ""
                            }
                            disabled={true}
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                              // width:"30%"
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          // name={"phoneNumber"}
                          style={{ width: "100%", marginLeft: 10 }}
                        >
                          <Input
                            defaultValue={
                              driverData && driverData.phoneNumber
                                ? driverData.phoneNumber
                                : ""
                            }
                            disabled={true}
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </Form.Item>
                      </Input.Group>
                    </div>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">Joined Date</label>

                      <Input
                        defaultValue={
                          driverData && driverData.created_at
                            ? new Date(
                                driverData.created_at
                              ).toLocaleDateString()
                            : ""
                        }
                        disabled
                        style={{
                          background: "white",
                          cursor: "default",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">Google Id</label>
                      <Input
                        defaultValue={
                          driverData && driverData?.google_id
                            ? driverData?.google_id
                            : ""
                        }
                        disabled
                        style={{
                          background: "white",
                          cursor: "default",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">Facebook Id</label>
                      <Input
                        defaultValue={
                          driverData && driverData?.facebook_id
                            ? driverData?.facebook_id
                            : ""
                        }
                        disabled
                        style={{
                          background: "white",
                          cursor: "default",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">City </label>
                      <Form.Item name={"driverRegisteredCity"}>
                        <Select
                          placeholder="Choose city"
                          // style={{ width: "100vw", marginRight: "10px" }}
                          showSearch
                          filterOption={false}
                          onSearch={(e) => handleSearch(e)}
                          // allowClear
                          // onClear={(e) => {
                          //   handleSearch(""), handleSelectedCity("");
                          // }}
                          onSelect={(value) => handleSelectedCity(value)}
                          disabled={!editable}
                        >
                          {cityList.length > 0 &&
                            cityList?.map((data) => {
                              return (
                                <Select.Option value={data._id} key={data._id}>
                                  {data.name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                        {/* <Input
                          // defaultValue={driverData && driverData?.city_id?.name}
                          disabled={editable}
                          style={{
                            background: "white",
                            cursor: "default",
                            fontWeight: "bold",
                          }}
                        /> */}
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">Vehicle No.</label>
                      <Form.Item name={"vehicle_number"}>
                        <Input
                          // defaultValue={
                          //   driverData && driverData.vehicle_number
                          //     ? driverData.vehicle_number
                          //     : ""
                          // }
                          disabled={!editable}
                          style={{
                            background: "white",
                            cursor: "default",
                            fontWeight: "bold",
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>

                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">Vehicle Type </label>
                      <Form.Item name={"vehicle_type_id"}>
                        <Select
                          disabled={!editable}
                          onChange={handleSelectedVehicleType}
                        >
                          {vehicleTypes &&
                            vehicleTypes.length > 0 &&
                            vehicleTypes.map((vehicle) => (
                              <Select.Option
                                value={vehicle._id}
                                key={vehicle._id}
                                className="selectOptions"
                              >
                                {vehicle.name}
                              </Select.Option>
                            ))}
                        </Select>

                        {/* <Input
                          // defaultValue={
                          //   driverData && driverData?.vehicle_type_id?.name
                          // }
                          disabled={editable}
                          style={{
                            background: "white",
                            cursor: "default",
                            fontWeight: "bold",
                          }}
                        /> */}
                      </Form.Item>
                    </div>
                  </Col>
                  {/* work in progress */}
                  {Array.isArray(vehicleModel) && vehicleModel.length > 0 && (
                    <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                      <div>
                        <label className="userlabel">Vehicle Model Name </label>
                        <Form.Item
                          name={"vehicle_model_id"}
                          rules={[
                            {
                              required: true,
                              message: "Please select vehicle model",
                            },
                          ]}
                        >
                          <Select disabled={!editable}>
                            {vehicleModel &&
                              vehicleModel.length > 0 &&
                              vehicleModel.map((vehicle) => (
                                <Select.Option
                                  value={vehicle._id}
                                  key={vehicle._id}
                                  className="selectOptions"
                                >
                                  {vehicle.name}
                                </Select.Option>
                              ))}
                          </Select>
                          {/* <Input
                          // defaultValue={
                          //   driverData && driverData?.vehicle_type_id?.name
                          // }
                          disabled={editable}
                          style={{
                            background: "white",
                            cursor: "default",
                            fontWeight: "bold",
                          }}
                        /> */}
                        </Form.Item>
                      </div>
                    </Col>
                  )}

                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">
                        Vehicle Insurance Valid Till{" "}
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                      <Form.Item name={"insurance_valid_till"}>
                        <DatePicker
                          // defaultValue={moment()}
                          disabled={!editable}
                          // placeholder="DD/MM/YYYY"
                          // defaultValue={"2023-12-10"}
                          // defaultValue={insuranceDate}
                          // onChange={(event) => {
                          //   setInsuranceDate(event.target.value),
                          //     setInsuranceRequiredError(false);
                          // }}
                          onChange={handleDateChange}
                          style={{
                            background: "white",
                            cursor: "default",
                            fontWeight: "bold",
                            // border:insurnaceReuiredError?"red":"black"
                            // border: insurnaceReuiredError && "1px solid red",
                            // border:"1px solid black",
                            width: "100%",
                            // height:"100%",
                            height: 44,
                            borderRadius: 0,
                          }}
                          // type="date"

                          disabledDate={(current) =>
                            current && current < moment().startOf("day")
                          }
                        />
                      </Form.Item>
                      {insurnaceReuiredError && (
                        <span style={{ color: "red" }}>Please enter date</span>
                      )}
                    </div>
                  </Col>

                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">Last Login</label>

                      <Input
                        // defaultValue={
                        //   driverData && driverData?.location?.location_name
                        //     ? driverData?.location?.location_name
                        //     : "-"
                        // }
                        disabled
                        style={{
                          background: "white",
                          cursor: "default",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">Bank Account Number</label>

                      <Input
                        defaultValue={
                          driverData &&
                          driverData?.driverBankDetails?.account_number
                            ? driverData?.driverBankDetails?.account_number
                            : "-"
                        }
                        disabled
                        style={{
                          background: "white",
                          cursor: "default",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">Bank IFSC Code</label>

                      <Input
                        defaultValue={
                          driverData && driverData?.driverBankDetails?.ifsc_code
                            ? driverData?.driverBankDetails?.ifsc_code
                            : "-"
                        }
                        disabled
                        style={{
                          background: "white",
                          cursor: "default",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                    <div>
                      <label className="userlabel">Cheque</label>
                      <br />{" "}
                      {/* <Image
                        width={60}
                        height={50}
                        src={
                          driverData &&
                          IMAGE_URL_PREFIX +
                            driverData?.driverBankDetails?.cheque
                        }
                        alt="Cheque Image"
                      /> */}
                      {driverData?.driverBankDetails?.cheque ? (
                        driverData?.driverBankDetails?.cheque.split(".pdf")
                          ?.length == 1 ? (
                          <Image
                            src={
                              IMAGE_URL_PREFIX +
                              driverData?.driverBankDetails?.cheque
                            }
                            width={60}
                            height={50}
                            alt="Cheque Image"
                          />
                        ) : (
                          <a
                            href={
                              IMAGE_URL_PREFIX +
                              driverData?.driverBankDetails?.cheque
                            }
                            target="_blank"
                          >
                            <div>
                              <iframe
                                src={
                                  IMAGE_URL_PREFIX +
                                  driverData?.driverBankDetails?.cheque
                                }
                                width={60}
                                height={50}
                              />
                            </div>
                          </a>
                        )
                      ) : (
                        <span>Document not available</span>
                      )}
                    </div>
                  </Col>
                  {driverData?.is_driver_rejected && (
                    <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                      <div>
                        <label className="userlabel">Rejected Reason</label>

                        <TextArea
                          // defaultValue={
                          //   driverData && driverData?.location?.location_name
                          //     ? driverData?.location?.location_name
                          //     : "-"
                          // }
                          disabled
                          value={driverData?.driver_rejected_reason}
                          style={{
                            background: "white",
                            cursor: "default",
                            fontWeight: "bold",
                          }}
                        />
                      </div>
                    </Col>
                  )}
                </Row>
              </Form>
            </Card>
            <br />
            <Collapse defaultActiveKey={"1"} style={{ border: "none" }}>
              <Panel
                header="Documents"
                key={"1"}
                style={{
                  background: "#00000047",
                  borderRadius: 10,
                  fontWeight: 500,
                  fontSize: 16,
                }}
              >
                {/* <Card title="Documents" headStyle={{ background: "#00000047" }}> */}
                <Table
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                  columns={columns}
                  // dataSource={documentsData}
                  dataSource={documentsData}
                  pagination={false}
                />
                {/* </Card> */}
              </Panel>
            </Collapse>
          </div>

          <Modal
            title={"Approve Request"}
            open={isModalOpen}
            onOk={form.submit}
            closable={true}
            // maskClosable={false}
            onCancel={handleCancel}
            // okText={"Approve"}
          >
            <Form form={form} onFinish={handleDriverApprove} layout="vertical">
              <Form.Item name={"city"} label="City">
                <Select
                  placeholder="Choose city"
                  // style={{ width: "100vw", marginRight: "10px" }}
                  showSearch
                  filterOption={false}
                  onSearch={(e) => handleSearch(e)}
                  // allowClear
                  // onClear={(e) => {
                  //   handleSearch(""), handleSelectedCity("");
                  // }}
                  onSelect={(value) => handleSelectedCity(value)}
                >
                  {cityList.length > 0 &&
                    cityList?.map((data) => {
                      return (
                        <Select.Option value={data._id} key={data._id}>
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item label="Assign Captain" name="captain">
                <Select
                  placeholder="Choose Captain"
                  listHeight={100}
                  // onPopupScroll={handleScroll}
                >
                  {selectedCity != "" &&
                    captainList &&
                    captainList?.length > 0 &&
                    captainList.map((captain) => {
                      return (
                        <Select.Option value={captain._id} key={captain._id}>
                          {captain.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item
                label={`Driver Name`}
                // rules={[{ required: true, message: "Please Enter Name" }]}
                name="name"
              >
                <Input type="text" placeholder="Driver Name" disabled></Input>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title={rejectTitle}
            open={rejectModal}
            onOk={form.submit}
            closable={true}
            // maskClosable={false}
            onCancel={handleCancel}
          >
            <Form layout="vertical" onFinish={handleReject} form={form}>
              <Form.Item
                name={"driver_rejection_reason_master_id"}
                label="Rejection Type"
                rules={[
                  {
                    required: true,
                    message: "Please Choose Rejection Type",
                  },
                  // {
                  //   pattern: new RegExp(/^(?!\s*$).+$/i),
                  //   message: "Please Enter Rejection Message",
                  // },
                ]}
              >
                <Select placeholder="Choose Rejection Type">
                  {reasonsList?.list?.map((reason) => (
                    <Select.Option key={reason?._id} value={reason?._id}>
                      {reason?.reason}{" "}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={"reason"}
                label="Rejection Reason"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Rejection Message",
                  },
                  {
                    pattern: new RegExp(/^(?!\s*$).+$/i),
                    message: "Please Enter Rejection Message",
                  },
                ]}
              >
                <Input placeholder="Enter Rejection Message" />
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}
    </div>
  );
};

export default Request;
