const actions = {
  GET_DRIVERCENTER_LIST: "GET_DRIVERCENTER_LIST",
  GET_DRIVERCENTER_BY_ID: "GET_DRIVERCENTER_BY_ID",
  GET_DRIVERCENTER_LIST_WITHOUT_PAGINATION:"GET_DRIVERCENTER_LIST_WITHOUT_PAGINATION",

  fetchDriverCenterList: (data) => {
    return {
      type: actions.GET_DRIVERCENTER_LIST,
      data,
    };
  },

  fetchDriverCenterById: (data) => {
    return {
      type: actions.GET_DRIVERCENTER_BY_ID,
      data,
    };
  },
  fetchDriverCenterListWithoutPagination: (data) => {
    return {
      type: actions.GET_DRIVERCENTER_LIST_WITHOUT_PAGINATION,
      data,
    };
  },
};

export default actions;
