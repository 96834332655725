import { Avatar, Modal } from "antd";
import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  ADMIN,
  CALLCENTER,
  CAPTAIN,
  IMAGE_URL_PREFIX,
  SUPERADMIN,
} from "../../../config/dataService/dataService";
import { getAdminDataById } from "../../../redux/admin/actionCreatore";
import { logOut } from "../../../redux/authentication/actionCreator";
import { getCallCenterById } from "../../../redux/callCenter/actionCreatore";
import { getDriverCenterById } from "../../../redux/deriverCenter/actionCreatore";
import { getProfileDataById } from "../../../redux/SuperAdmin/actionCreatore";
import { WordFormate } from "../../../utility/commonFunction";
import { getItem } from "../../../utility/localStorageControl";
import Heading from "../../heading/heading";
import { Popover } from "../../popup/popup";
import { InfoWraper, UserDropDwon } from "./auth-info-style";
const lngs = [
  { code: "en", native: "English" },
  { code: "hi", native: "Hindi" },
  { code: "gj", native: "Gujarati" },
];
const AuthInfo = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();

  const handleTrans = (code) => {
    console.log("usdh");
    i18n.changeLanguage(code);
  };

  const SignOut = () => {
    // e.preventDefault();
    dispatch(logOut());
  };
  const SignOutModal = () => {
    Modal.confirm({
      title: "Are you sure you want to sign out?",
      okText: "Sign Out",
      okType: "danger",
      onOk: () => {
        // deleteCityFinal(value);
        SignOut();
      },
    });
  };

  const user = getItem("user_details");
  useEffect(() => {
    if (user.role == "super_admin") {
      fetchDetails(getProfileDataById);
    }
    if (user.role == "admin") {
      fetchDetails(getAdminDataById);
    }
    if (user.role == "callCenter") {
      fetchDetails(getCallCenterById);
    }
    if (user.role == "captain") {
      fetchDetails(getDriverCenterById);
    }
  }, []);
  // let data;
  const fetchDetails = async (reduxMethod) => {
    setLoading(true);
    const response = await dispatch(reduxMethod(user?._id));
    if (response) {
      setLoading(false);
    }
  };

  let data = useSelector((state) => {
    if (user?.role == SUPERADMIN) {
      return state.superAdmin.superAdminData;
    } else if (user?.role == ADMIN) {
      return state.admin.adminData;
    } else if (user?.role == CALLCENTER) {
      return state.callCenter.callCenterData;
    } else if (user?.role == CAPTAIN) {
      return state.captain.driverCenterData;
    }
  });

  // user.role == "super_admin" &&
  //   (data = useSelector((state) => state.superAdmin.superAdminData));
  // user.role == "admin" && (
  //   <>{(data = useSelector((state) => state.admin.adminData))}</>
  // );

  // user.role == "callCenter" && (
  //   <>{(data = useSelector((state) => state.callCenter.callCenterData))}</>
  // );

  // user.role == "captain" && (
  //   <>{(data = useSelector((state) => state.captain.driverCenterData))}</>
  // );

  const userContent = (
    <UserDropDwon style={{ padding: "20px" }}>
      <div className="user-dropdwon">
        <figure
          className="user-dropdwon__info"
          style={{ display: "block", textAlign: "center" }}
        >
          <Avatar
            size={50}
            icon={
              <img
                src={
                  data?.profile_image
                    ? IMAGE_URL_PREFIX + data?.profile_image
                    : "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png"
                }
                alt=""
              />
            }
          />
          <figcaption>
            <Heading as="h6">{data?.name ? data?.name : user?.name}</Heading>
            {/* <Heading as="h5">
              {user.role == "super_admin" && "Super Admin"}
              {user.role == "admin" && "Admin"}
              {user.role == "captain" && "Captain"}
              {user.role == "callCenter" && "Call Center"}


            </Heading> */}

            {/* <p>UI Expert</p> */}
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to={`/${user?.role}/profile`}>
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
          <li>
            <Link to={`/${user?.role}/aboutUs`}>
              <FeatherIcon icon="slack" /> About Us
            </Link>
          </li>
          {(user?.role == "super_admin" || user?.role == "captain") && (
            <li>
              <Link to={`/${user?.role}/wallet`}>
                <FeatherIcon icon="briefcase" /> Wallet
              </Link>
            </li>
          )}

          {/* <li>
            <Link to={`/${user.role}/settings`}>
              <FeatherIcon icon="settings" /> Settings
            </Link>
          </li> */}
          {/* <li>
            <Link to="#">
              <FeatherIcon icon="dollar-sign" /> Billing
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="users" /> Activity
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="bell" /> Help
            </Link>
          </li> */}
        </ul>
        <Link
          className="user-dropdwon__bottomAction"
          onClick={SignOutModal}
          to="#"
        >
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <>
      {/* <Select
        style={{ width: "10vw", position: "absolute", top: "20%", left: "30%" }}
        defaultValue={i18n.language}
        onSelect={(value) => handleTrans(value)}
      >
        {lngs.map((lng, i) => {
          const { code, native } = lng;
          return <Select.Option value={code}>{native}</Select.Option>;
        })}
      </Select> */}
      <InfoWraper>
        <div className="nav-author" style={{ textAlign: "center" }}>
          {/* <span style={{paddingTop:"10px", fontWeight:"bold"}}>Super Admin</span> */}

          <Popover
            placement="bottomRight"
            content={userContent}
            style={{ margin: "auto" }}
          >
            <Link to="#" className="head-example">
              <Avatar
                src={
                  data?.profile_image
                    ? IMAGE_URL_PREFIX + data?.profile_image
                    : "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png"
                }
              />
            </Link>
            <span
              style={{ fontSize: "12px", fontWeight: "bold", margin: "auto" }}
            >
              {WordFormate(user?.role)}
            </span>
          </Popover>
        </div>
      </InfoWraper>
    </>
  );
};

export default AuthInfo;
