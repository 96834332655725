const actions = {
  GET_DATA: "GET_DATA",

  getData: (data) => {
    return {
      type: actions.GET_DATA,
      data,
    };
  },
};

export default actions;
