import moment from "moment";
import { getItem } from "./localStorageControl";

export const getDateFormate = (date) => {
  return moment(date).format("DD/MM/YYYY");
};
export const checkAction = (group, value = "") => {
  const userDetails = getItem("user_details");
  if (userDetails.role === "super_admin") {
    return true;
  } else {
    return userDetails?.roleManagement[group] &&
      userDetails?.roleManagement[group][value]
      ? userDetails?.roleManagement[group][value]
      : false;
  }
};
export const CheckRoles = (p = "") => {
  const userDetails = getItem("user_details");
  function checkPermissions(obj) {
    for (const key in obj) {
      if (obj[key]) {
        return true;
      }
    }
    return false;
  }
  let roleObject = userDetails && userDetails.roleManagement;

  if (userDetails?.role === "super_admin") {
    return true;
  } else if (roleObject?.[p] && checkPermissions(roleObject?.[p])) {
    return true;
  } else {
    return false;
  }
};
export const getRandomColor = () => {
  const red = Math.floor(((1 + Math.random()) * 256) / 2);
  const green = Math.floor(((1 + Math.random()) * 256) / 2);
  const blue = Math.floor(((1 + Math.random()) * 256) / 2);
  return "rgb(" + red + ", " + green + ", " + blue + ")";
};

export const FirstLetterCapital = (word = "") => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
export const FixedToNDecimals = (integer) => {
  if (integer) {
    return integer.toFixed(2);
  } else {
    return 0.0;
  }
};
export const WordFormate = (input) => {
  if(input){

    const words = input?.split("_");
    const prefix = words?.shift();
    const prefixWords = prefix?.split(/(?=[A-Z])/);
    const prefixTitle = prefixWords
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
    let suffix = words.join("_");
  if (suffix) {
    suffix = suffix.replace(/([a-z])([A-Z])/g, "$1 $2");
    suffix = suffix.charAt(0).toUpperCase() + suffix.slice(1);
  }
  const title = suffix.length > 0 ? `${prefixTitle}-${suffix}` : prefixTitle;
  return title;
}
};
