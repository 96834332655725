import actions from "./actions";

const { GET_SUPER_ADMIN_DATA } = actions;

const initState = {
  superAdminData: {},
};

const SuperAdminReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_SUPER_ADMIN_DATA:
      return {
        ...state,
        superAdminData: data,
      };
    default:
      return state;
  }
};
export default SuperAdminReducer;
