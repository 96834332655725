import React, { useContext, useEffect, useState } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import {
  GOOGLE_MAP_API_KEY,
  GOOGLE_MAP_API_SUBTYPE_QUOTA_SERVICE,
  GOOGLE_MAP_API_SUBTYPE_VIEW_PORT_INFO_SERVICE,
  GOOGLE_MAP_API_TYPE_MAPS,
  GOOGLE_MAP_MODULE_NAME_RIDER_LOCATION_ON_MAP,
} from "../../config/dataService/dataService";
import bike from "../../static/img/bikeTopView.png";
import Auto from "../../static/img/map/auto.png";
import Auto1 from "../../static/img/auto1.png";

import { useParams } from "react-router-dom";
import Loader from "../loader/loader";
import { Switch } from "antd";
import { getDriverById } from "../../redux/drivers/actionCreatore";
import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from "../socket/socketContext";
import { getItem } from "../../utility/localStorageControl";
import userpickup from "../../static/img/stops/userpickup.png";
import { addMapStatistics } from "../../redux/MapStatistics/actionCreatore";
function LiverDriver(props) {

  const { riderId } = useParams();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const [location, setLocation] = useState({});
  const user = getItem("user_details");
  console.log("socket", socket);
  useEffect(() => {
    window.scrollTo(0, 0);
    reteriveDriverData();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.emit("userCurrentLocation", {
        id: riderId,
        webRoleUserId: user._id,
      });

      socket.on("userCurrentLocationData", (data) => {
        console.log("data", data);
        setLocation(data.location.coordinates);

        // setLoading(false)
      });
    }
  }, [socket]);

  console.log("location", location);
  const reteriveDriverData = async () => {
    const resp = await dispatch(getDriverById(riderId));
    if (resp) {
    }
  };

  const driverData = useSelector((state) => state.driver.driverById);
  //  vehicleType=driverData?.
  // console.log("driverData", driverData);

  useEffect(() => {
    if (driverData) {
      setLoading(false);
    }
  }, [driverData]);
  const handleMapReady = async (mapProps, map) => {
    // Map has loaded successfully
    await dispatch(
      addMapStatistics({
        user_id: user._id,
        googleApiType: GOOGLE_MAP_API_TYPE_MAPS,
        googleSubApiType: GOOGLE_MAP_API_SUBTYPE_QUOTA_SERVICE,
        moduleName: GOOGLE_MAP_MODULE_NAME_RIDER_LOCATION_ON_MAP,
        platformType: "web",
        originFrom: user?.role,
        apiStatus: "success",
      })
    );
    await dispatch(
      addMapStatistics({
        user_id: user._id,
        googleApiType: GOOGLE_MAP_API_TYPE_MAPS,
        googleSubApiType: GOOGLE_MAP_API_SUBTYPE_VIEW_PORT_INFO_SERVICE,
        moduleName: GOOGLE_MAP_MODULE_NAME_RIDER_LOCATION_ON_MAP,
        platformType: "web",
        originFrom: user?.role,
        apiStatus: "success",
      })
    );
  };
  const handleMapError = async (mapProps, map) => {
    // Error occurred while loading the map
    await dispatch(
      addMapStatistics({
        user_id: user._id,
        googleApiType: GOOGLE_MAP_API_TYPE_MAPS,
        googleSubApiType: GOOGLE_MAP_API_SUBTYPE_QUOTA_SERVICE,
        moduleName: GOOGLE_MAP_MODULE_NAME_RIDER_LOCATION_ON_MAP,
        platformType: "web",
        originFrom: user?.role,
        apiStatus: "error",
      })
    );
    await dispatch(
      addMapStatistics({
        user_id: user._id,
        googleApiType: GOOGLE_MAP_API_TYPE_MAPS,
        googleSubApiType: GOOGLE_MAP_API_SUBTYPE_VIEW_PORT_INFO_SERVICE,
        moduleName: GOOGLE_MAP_MODULE_NAME_RIDER_LOCATION_ON_MAP,
        platformType: "web",
        originFrom: user?.role,
        apiStatus: "error",
      })
    );
  };
  return (
    <>
      <div className="common_layout content-container">
        {loading && <Loader />}
        {!loading && (
          <div style={{ height: "450px", width: "450px" }}>
            <Map
              google={props.google}
              zoom={14}
              initialCenter={{
                lat: 23.0746719,
                lng: 72.5255954,
              }}
              onReady={handleMapReady}
              onError={handleMapError}
              // style={{height:'95vh', width:"70%"}}
            >
              <Marker
                onClick={(e) => console.log(e)}
                name={"Current location"}
                icon={{
                  url: userpickup,

                  scaledSize: new window.google.maps.Size(60, 60),
                }}
                position={{
                  lat: location[1],
                  lng: location[0],
                }}
              />
              {/* <Marker
                onClick={(e) => console.log(e)}
                name={"Current location"}
                icon={{
                  url: Auto,

                  // scaledSize: new google.maps.Size(40, 40),
                }}
                position={{ lat: 25.09113, lng: 72.51251 }}
              /> */}

              <InfoWindow
                visible={true}
                // onClose={this.onInfoWindowClose}
              >
                <div>{/* <h1>{this.state.selectedPlace.name}</h1> */}</div>
              </InfoWindow>
            </Map>
          </div>
        )}
      </div>
    </>
  );
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_API_KEY,
})(LiverDriver);
