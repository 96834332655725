const actions = {
  GET_BOOKING_LIST: "GET_BOOKING_LIST",
  GET_BOOKING_BY_ID: "GET_BOOKING_BY_ID",
  GET_RIDER_BOOKING_LIST:"GET_RIDER_BOOKING_LIST",
  GET_DRIVER_BOOKING_LIST:"GET_DRIVER_BOOKING_LIST",

  bookingList: (data) => {
    return {
      type: actions.GET_BOOKING_LIST,
      data,
    };
  },
  bookingDataById:(data) => {
    return {
      type: actions.GET_BOOKING_BY_ID,
      data,
    };
  },
  riderBookingList:(data)=>{
    return {
      type: actions.GET_RIDER_BOOKING_LIST,
      data,
    };
  },
  driverBookingList:(data)=>{
    return {
      type: actions.GET_DRIVER_BOOKING_LIST,
      data,
    };
  }
};

export default actions;
