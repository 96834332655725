import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import paymentComplete from "../../static/img/payment/payemntComplete.gif";
import paymentFailed from "../../static/img/payment/paymentfailed.png";
import paymentProcessing from "../../static/img/payment/paymentProcessing.gif";
import { getItem } from "../../utility/localStorageControl";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";

function WithdrawalRequest(props) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const user = getItem("user_details");
  const [showPayModal, setShowPayModal] = useState(false);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [showProcessing, setShowProcessing] = useState(false);
  const [showSuccessfull, setShowSuccessfull] = useState(false);
  const [showFailed, setShowFailed] = useState(false);

  const dummyData = [
    {
      id: 1,
      name: "John",
      total: 250,
      withdraw: 850,
      time: "02/05/2023 16:46:54",
      status: "pending",
      bank: {
        name: "SBI",
        number: "76678886898",
        ifsc: "SBIN0022344",
      },
      transactionId: "59885895895984",
      orderId: "Ord9885526522",
      settlementId: "SETT689855552",

      role: "Driver",
    },
    {
      id: 2,
      name: "Anny",
      total: 100,
      withdraw: 20,
      time: "02/05/2023 16:46:54",
      status: "success",
      paymentDone: "16/06/2023",
      bank: {
        name: "Axis",
        number: "5478965662",
        ifsc: "AXIS0029745",
      },
      transactionId: "985445565265",
      role: "Captain",
      orderId: "Ord9885597874",
      settlementId: "SETT69745112",
    },
    {
      id: 3,
      name: "Johny",
      total: 5000,
      withdraw: 30,
      time: "02/05/2023 16:46:54",
      status: "failed",
      paymentDone: "19/06/2023",
      bank: {
        name: "ICICI",
        number: "63474895562",
        ifsc: "ICIC0698754",
      },
      transactionId: "65254414585422",
      role: "Driver",
      orderId: "Ord99865282",
      settlementId: "SETT9885222",
      reason: "Insufficient balance",
    },
  ];

  const handlePay = async () => {
    setShowPayModal(false);
    setShowProcessing(true);
    setTimeout(() => {
      setShowProcessing(false);
      setShowSuccessfull(true);
      //   setShowFailed(true)
      hidePaymentComplete();
    }, 2000);
    const hidePaymentComplete = () => {
      setTimeout(() => {
        setShowSuccessfull(false);
        // setShowFailed(false)
      }, 2000);
    };
  };
  const rangePresets = [
    { label: "Today", value: [dayjs(), dayjs()] },
    { label: "Last 7 Days", value: [dayjs().add(-7, "days"), dayjs()] },
    { label: "Last Month", value: [dayjs().add(-1, "month"), dayjs()] },
    { label: "Last 6 Month", value: [dayjs().add(-6, "month"), dayjs()] },
    { label: "Last Year", value: [dayjs().add(-1, "year"), dayjs()] },
  ];

  const columns = [
    {
      title: "Sr. No.",
      key: "sr.",
      dataIndex: "id",
    },
    {
      title: "Name",
      key: "name",
      //   dataIndex: "name",
      render: (data) => {
        return (
          <span
            style={{
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
            }}
            onClick={() =>
              navigate(
                `/${user.role}/manageDriver/driver/driverDetails/${data._id}`
              )
            }
          >
            {data.name}
          </span>
        );
      },
    },
    {
      title: "User Role",
      key: "role",
      //   dataIndex: "name",
      render: (data) => {
        return <span>{data.role}</span>;
      },
    },
    {
      title: "Current Wallet Balance (₹)",
      key: "total",
      //   dataIndex: "total",
      render: (data) => {
        return (
          <span
            style={{
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
            }}
            onClick={() =>
              navigate(
                `/${user.role}/manageDriver/driver/driverWallet/${data._id}`
              )
            }
          >
            {data.total}
          </span>
        );
      },
    },
    {
      title: "Withdraw Request Amount (₹)",
      key: "withdraw",
      // dataIndex: "withdraw",
      render: (data) => {
        return (
          <span
            style={{
              color: data.withdraw > data.total ? "red" : "",
              fontWeight: data.withdraw > data.total ? "800" : "",
            }}
          >
            <Tooltip title="Withdraw Request Amount is more than current wallet amount">
              {data.withdraw}
            </Tooltip>
          </span>
        );
      },
    },
    {
      title: "Request TimeStamp",
      key: "time",
      dataIndex: "time",
    },
    // {
    //   title: "Payment Done TimeStamp",
    //   key: "paymentDone",
    //   dataIndex: "paymentDone",
    // },
    {
      title: "Status",
      key: "status",
      //   dataIndex: "status",
      render: (data) => {
        return (
          <>
            <Tag
              color={
                data.status == "pending"
                  ? "yellow"
                  : data.status == "success"
                  ? "green"
                  : "red"
              }
            >
              {data.status.toUpperCase()}
            </Tag>
            <br />
            <b>{data.paymentDone ? data.paymentDone : " "}</b>
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (data) => {
        return (
          <>
            {data?.status == "success" && (
              <Button
                type="primary"
                //    onClick={() => setShowPayModal(true)}
                disabled
              >
                Already Paid
              </Button>
            )}
            {data?.status != "success" &&
              (data.total > data.withdraw ? (
                <Button
                  type="primary"
                  onClick={() => {
                    setShowPayModal(true);
                    form.setFieldsValue({
                      bankName: data.bank.name,
                      accountNumber: data.bank.number,
                      bankIfsc: data.bank.ifsc,
                      amount: data.withdraw,
                      amountwallet: data.total,
                    });
                  }}
                >
                  Pay
                </Button>
              ) : (
                <Tooltip title="Withdraw Request Amount is more than current wallet amount">
                  <Button type="primary" disabled>
                    {" "}
                    Pay
                  </Button>
                </Tooltip>
              ))}
          </>
        );
      },
    },
  ];

  const [expandedKeys, setExpandedKeys] = useState([]);

  const allAvailableKeys = dummyData.map((e) => e.id);
  const processedColumns = [...columns];
  processedColumns.unshift({
    title: allAvailableKeys.every((e) => expandedKeys.includes(e)) ? (
      <MinusSquareOutlined onClick={() => collapseAll()} />
    ) : (
      <PlusSquareOutlined onClick={() => expandAll()} />
    ),
    key: "expand",
    render: (_, record) =>
      expandedKeys.includes(record.id) ? (
        <MinusSquareOutlined onClick={() => toggleExpandedKeys(record.id)} />
      ) : (
        <PlusSquareOutlined onClick={() => toggleExpandedKeys(record.id)} />
      ),
  });

  const toggleExpandedKeys = (id) => {
    setExpandedKeys((prev) => {
      const outArr = [...prev];
      if (outArr.includes(id)) {
        return outArr.filter((e) => e !== id);
      } else {
        outArr.push(id);
        return outArr;
      }
    });
  };

  const expandAll = () => {
    setExpandedKeys(allAvailableKeys);
  };

  const collapseAll = () => {
    setExpandedKeys([]);
  };
  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
            <h1 className="heading">Payment Withdraw Request </h1>
            <BreadCrumb />
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
            <Card
              style={{ marginTop: 10 }}
              bodyStyle={{
                justifyContent: "center",
                display: "flex",
                // flexDirection:"column",
                padding: 0,
              }}
              className="shadowCardBox totalCard"
            >
              <h1 style={{ fontSize: "20px" }}>
                Razorpay Current Balance: ₹ 0
              </h1>
            </Card>
          </Col>
        </Row>
        <Divider />
        <Card
          style={{ marginBottom: 10 }}
          bodyStyle={{ padding: "10px" }}
          className="shadowCardBox totalCard"
        >
          <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
            <Col xxl={4} lg={4} md={8} sm={24} xs={24} className="total">
              <span className="total">Total 0</span>
            </Col>
            <Col xxl={5} lg={6} md={8} sm={24} xs={24}></Col>
            <Col xxl={5} lg={5} md={8} sm={24} xs={24}>
              <Input placeholder="Search By Name" />
            </Col>
            <Col xxl={5} lg={5} md={8} sm={24} xs={24}>
              <DatePicker.RangePicker
                presets={rangePresets}
                // onChange={onRangeChange}
                // defaultValue={[fromDate, toDate]}
                style={{
                  borderRadius: "0",
                  // marginLeft: "10px",
                  // width: "10vw",
                  width: "100%",
                }}
                format={"DD/MM/YYYY"}
                allowClear={true}
                disabledDate={(current) => current.isAfter(moment())}
              />
            </Col>
            <Col xxl={5} lg={5} md={8} sm={24} xs={24}>
              <Select style={{ width: "100%" }} placeholder="Choose Status">
                <Select.Option value="pending" key="pending">
                  <Tag color={"yellow"} style={{ width: "100%" }}>
                    PENDING
                  </Tag>
                </Select.Option>
                <Select.Option value="success" key="success">
                  <Tag color={"green"} style={{ width: "100%" }}>
                    SUCCESS
                  </Tag>
                </Select.Option>{" "}
                <Select.Option value="failed" key="failed">
                  <Tag color={"red"} style={{ width: "100%" }}>
                    FAILED
                  </Tag>
                </Select.Option>
              </Select>
            </Col>
          </Row>
        </Card>

        <Card>
          <Table
            columns={processedColumns}
            dataSource={dummyData}
            rowKey="id"
            expandable={{
              expandedRowRender: (record) => (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex" }}>
                    <p>
                      <b>Bank Name</b> <br /> {record.bank.name}{" "}
                    </p>
                    <p>
                      <b>Account No.</b> <br />
                      {record.bank.number}
                    </p>
                    <p>
                      <b>IFSC Code</b> <br />
                      {record.bank.ifsc}
                    </p>
                    <p>
                      <b>Transaction Id </b> <br />
                      {record.transactionId}{" "}
                    </p>
                    <p>
                      <b>Order Id </b> <br />
                      {record.orderId}{" "}
                    </p>
                    <p>
                      <b>Settlement Id </b> <br />
                      {record.settlementId}{" "}
                    </p>
                  </div>
                  {record.reason && (
                    <div style={{ display: "flex" }}>
                      <p>Failure Reason: {record.reason} </p>
                    </div>
                  )}
                </div>
              ),
              expandedRowKeys: expandedKeys,
              expandIconColumnIndex: -1,
              //   rowExpandable: (record) => record.name !== "Not Expandable",
            }}
          />
        </Card>

        <Modal
          open={showPayModal}
          onCancel={() => setShowPayModal(false)}
          title={"WithDraw Request"}
          okText={"Pay"}
          onOk={form.submit}
        >
          <Form layout="vertical" onFinish={handlePay} form={form}>
            <Form.Item
              name={"bankName"}
              label={"Bank Name"}
              rules={[
                {
                  required: true,
                  message: "Please Enter Bank Name",
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              name={"accountNumber"}
              label={"Account Number"}
              rules={[
                {
                  required: true,
                  message: "Please Enter Bank Account Number",
                },
                {
                  pattern: /^[0-9]{9,18}$/,
                  message: "Please Enter Correct Account Number",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              name={"bankIfsc"}
              label={"IFSC Code"}
              rules={[
                {
                  required: true,
                  message: "Please Enter Bank IFSC Code",
                },
                {
                  pattern: /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/,
                  message: "Please Enter Correct IFSC Code",
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  name={"amount"}
                  label={"Withdraw Amount (₹)"}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Withdraw Amount",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"amountwallet"}
                  label={"Current Wallet Amount (₹)"}
                >
                  <Input type="number" disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal open={showProcessing} footer={null} closable={false} centered>
          <img
            src={paymentProcessing}
            style={{
              height: "100%",
              width: "100%",
            }}
          />
        </Modal>
        <Modal open={showSuccessfull} footer={null} closable={false} centered>
          <img
            src={paymentComplete}
            style={{
              height: "100%",
              width: "100%",
            }}
          />
        </Modal>
        <Modal open={showFailed} footer={null} closable={false} centered>
          <img
            src={paymentFailed}
            style={{
              height: "100%",
              width: "100%",
            }}
          />
        </Modal>
      </div>
    </>
  );
}

export default WithdrawalRequest;
