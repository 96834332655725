import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStateRef from "react-usestateref";
import { IMAGE_URL_PREFIX } from "../../config/dataService/dataService";
import {
  addNotificationType,
  getNotificationTypesData,
  removeNotificationType,
  updateNotificationType,
} from "../../redux/NotificationTypes/actionCreatore";
import { checkAction } from "../../utility/commonFunction";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import Loader from "../loader/loader";
import "./Vehicle.css";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
const NotificationImage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [ModalTitle, setModalTitle] = useState("Add Notification Type");
  const [details, setDetails] = useState({});
  const [uploadimgURL, setUploadimgURL] = useState("");
  const [notificationTypeList, setNotificationTypeList] = useState([]);
  const [updateFlow, setUpdateFlow, setUpdateFlowFileRef] = useStateRef("");

  const dispatch = useDispatch();
  useEffect(() => {
    // fetchNotificationType();
    window.scrollTo(0, 0);
  }, []);

  const { data: dataCity, isLoading: isLoading } = useQuery(
    "notificationTypeList",
    () => {
      return fetchNotificationType();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchNotificationType = async () => {
    setLoading(true);
    const resp = await dispatch(getNotificationTypesData());
    if (resp) {
      // console.log(resp);
      setLoading(false);
      setNotificationTypeList(resp.list);
    }
  };

  const NotificationTypes = useSelector(
    (state) => state.NotificationTypeReducer.NotificationData
  );
  // console.log(NotificationTypes);

  const deleteNotificationFinal = async (id) => {
    // console.log(id);
    let remove = await dispatch(removeNotificationType(id));

    // console.log("remove", remove);
    if (remove) {
      fetchNotificationType();
    }
  };
  const deleteNotification = (id) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this Notification Type?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteNotificationFinal(id);
      },
    });
  };
  const editNotification = (data) => {
    setModalTitle("Edit Notification Type");
    setIsModalOpen(true);
    setUpdateFlow(data._id);
    // console.log(data);
    form.setFieldsValue({
      notificationType: data.notificationType,
      description: data.description,
    });
    setDetails({ notificationImage: data.notificationImage });
  };
  const handleSubmit = async (values) => {
    setLoading(true);

    // console.log(values);
    let formData = new FormData();
    formData.append("notificationType", values.notificationType);
    formData.append("description", values.description);
    formData.append("notificationImage", details.notificationImage);

    if (setUpdateFlowFileRef.current == "") {
      const resp = await dispatch(addNotificationType(formData));
      if (resp) {
        setDetails({});
        setUploadimgURL("");
        setIsModalOpen(false);
        fetchNotificationType();
      }
    } else {
      const resp = await dispatch(updateNotificationType(formData, updateFlow));
      if (resp) {
        setDetails({});
        setUploadimgURL("");
        setIsModalOpen(false);
        fetchNotificationType();
      }
    }
  };
  const { t } = useTranslation();
  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Image",
      render: (data) => {
        return (
          <span>
            <Avatar
              size={70}
              icon={
                <Image
                  src={data && `${IMAGE_URL_PREFIX}${data.notificationImage}`}
                  // crossOrigin="anonymous"
                />
              }
            />
          </span>
        );
      },
    },
    {
      title: "Notification Type",

      render: (data) => {
        return <span>{data?.notificationType}</span>;
      },
    },

    {
      title: "Description",

      render: (data) => {
        return <span>{data?.description} </span>;
      },
    },

    {
      title: t("action"),
      // dataIndex: "documents",
      key: "action",
      render(data) {
        return (
          <div>
            {checkAction("master_notificateImage", "edit") && (
              <Button
                onClick={() => editNotification(data)}
                type="primary"
                // style={{ cursor: "pointer", color: "blue" }}
              >
                <EditOutlined />
                Edit
              </Button>
            )}
            {/* <Button
              onClick={() => deleteNotification(data._id)}
              style={{ cursor: "pointer", marginLeft: 2 }}
             type="primary"
                danger
            >
              <DeleteOutlined />
              Delete
            </Button> */}
          </div>
        );
      },
    },
  ];
  const showModal = () => {
    form.resetFields();
    setModalTitle("Add Notification Type");
    setIsModalOpen(true);
    // setSelectedId("")
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedId("")
    form.resetFields();
    setDetails({});
    setUploadimgURL("");
    // setSelectDep(0)
  };

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">{t("notificationImage")}</h1>
            <BreadCrumb />
          </Col>
          {/* <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              style={{ float: "right" }}
              className="ADD"
              key="1"
              size="large"
              type="primary"
              onClick={showModal}
            >
              <PlusOutlined /> Add Notification Type
            </Button>
          </Col> */}
        </Row>
        <Divider />
        {!isLoading && (
          <>
            <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                dataSource={notificationTypeList}
                columns={columns}
                searchable
                scroll={{ x: "45vh" }}
                pagination={false}
              />
            </Card>
          </>
        )}
        {isLoading && <Loader />}
      </div>
      <Modal
        title={ModalTitle}
        open={isModalOpen}
        onOk={form.submit}
        // closable={true}
        // maskClosable={false}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          // labelCol={{ span: 5 }}
        >
          <Form.Item
            label={`Notification Type`}
            rules={[
              { required: true, message: "Please Enter Notification Type" },
            ]}
            name="notificationType"
          >
            <Input
              disabled
              type="text"
              placeholder="Enter Notification Type"
            ></Input>
          </Form.Item>

          <Form.Item
            label={`Description`}
            rules={[{ required: true, message: "Please Enter Description" }]}
            name="description"
          >
            <Input disabled type="text" placeholder="Enter Description"></Input>
          </Form.Item>

          <Form.Item
            name="notificationImage"
            // getValueFromEvent={normFile}
            label={
              <>
                Image
                <span style={{ marginLeft: "3px" }}></span>
              </>
            }
            rules={[
              {
                required: false,
                message: "Please choose Image",
              },
            ]}
          >
            <div className="vehicle_image">
              <label
                htmlFor="input-file"
                style={{
                  padding: details?.notificationImage ? "0px" : "38px 26px",
                }}
              >
                {/* {console.log("detaisl", details)} */}

                {details?.notificationImage ? (
                  <>
                    {/* {console.log("detaisl", details)} */}
                    {(uploadimgURL !== "") & (uploadimgURL != undefined) ? (
                      <Avatar
                        size={100}
                        src={URL.createObjectURL(uploadimgURL)}
                      />
                    ) : (
                      <Avatar
                        size={100}
                        icon={
                          <img
                            src={IMAGE_URL_PREFIX + details?.notificationImage}
                            // crossOrigin="anonymous"
                          />
                        }
                      ></Avatar>
                    )}
                  </>
                ) : (
                  <span>Upload</span>
                )}
              </label>
              <Input
                type="file"
                onChange={(event) => {
                  // setUploadimgURL(URL.createObjectURL(event.target.files[0]));
                  setUploadimgURL(event.target.files[0]);
                  setDetails({ notificationImage: event.target.files[0] });
                }}
                accept="image/jpeg,image/png,"
                id="input-file"
                aria-label="Upload File"
              />
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default NotificationImage;
