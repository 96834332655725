import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React from "react";
const dataSource = [
  {
    id: "1",
    driver_name: "Mike",
    status: "Active",
    driver_city: "surat",
    date: "05/02/2022",
  },
  {
    id: "2",
    driver_name: "John",
    status: "Active",
    driver_city: "surat",
    date: "05/12/2022",
  },
  {
    id: "3",
    driver_name: "Nitish",
    status: "Active",
    driver_city: "Vadodara",
    date: "11/12/2022",
  },
  {
    id: "4",
    driver_name: "Yash",
    status: "Active",
    driver_city: "Ahemdabad",
    date: "01/03/2022",
  },
  {
    id: "5",
    driver_name: "Palas",
    status: "Active",
    driver_city: "Vadodara",
    date: "09/12/2022",
  },
];
for (let i = 6; i < 100; i++) {
  dataSource.push({
    key: i,
    driver_name: `Driver name ${i}`,
    status: "Active",
    driver_city: `City ${i}`,
    date: `09/12/2022`,
  });
}

const columns = [
  {
    title: "Driver Name",
    dataIndex: "driver_name",
    key: "driver_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="Search name"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value, record) =>
      record.driver_name.toLowerCase().includes(value.toLowerCase()),
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "City",
    dataIndex: "driver_city",
    key: "driver_city",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="Search name"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value, record) =>
      record.driver_city.toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="Search name"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value, record) =>
      record.date.toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Select
          style={{ width: 188, marginBottom: 8, display: "block" }}
          placeholder="Choose Status"
        >
          <Select.Option value="active">Active</Select.Option>
          <Select.Option value="inactive">InActive</Select.Option>
        </Select>
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value, record) =>
      record.date.toLowerCase().includes(value.toLowerCase()),
  },
];
const { Search } = Input;
const SearchBooking = () => {
  return (
    <>
      <div className="common_layout content-container ">
        <h1 className="heading">Search booking</h1>

        <Divider />
        <Card style={{ marginBottom: 10 }} className="shadowCardBox">
          <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
            <Col lg={12} md={12} sm={24} xs={24} className="total">
              Total 100
            </Col>
            {/* <Col lg={12} md={12} sm={24} xs={24}>
              <Search
                placeholder="input search text"
                enterButton="Search"
                size="large"
                // loading
              />
            </Col> */}
          </Row>
        </Card>
        <Card className="shadowCardBox">
              <Table
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
            dataSource={dataSource}
            columns={columns}
            searchable
            scroll={{ x: "45vh" }}
          />
        </Card>
      </div>
    </>
  );
};

export default SearchBooking;
