import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";
import actions from "./actions";
const { getActivityLogsList } = actions;
export const getActivityLogsData = ({
  page,
  limit,
  originFrom,
  activityType,
  fromDate,
  toDate,
}) => {
  return async (dispatch) => {
    let queryParametere =
      "?page=" +
      page +
      "&limit=" +
      limit +
      (originFrom && "&role=" + originFrom) +
      "&type=" +
      activityType +
      (fromDate && "&from_date=" + fromDate.format("YYYY-MM-DD")) +
      (toDate && "&to_date=" + toDate.format("YYYY-MM-DD"));

    const resp = await DataService.get(API.activityLogs.get + queryParametere);

    if (!resp.data.error) {
      dispatch(getActivityLogsList(resp.data.data));
      return resp.data.data;
    } else {
      return "false";
    }
  };
};
