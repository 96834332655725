import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Tag,
  Tooltip,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { object } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDriverList,
  getRidersList,
  sendSmsNotification,
} from "../../redux/SmsNotification/actionCreatore";
import { getTemplatesList } from "../../redux/templates/actionCreatore";
function SmsNotificationModal({
  modalOpen,
  modalTitle,
  setModalOpen,
  smsNotificationData,
  setSmsNotificationData,
  smsNotificationDataOrg,
  modalKey,
  role,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [inputVisible, setInputVisible] = useState(false);
  const [tempValues, setTempValues] = useState({});
  const [sendCalled, setSendCalled] = useState(false);
  const [showError, setShowError] = useState(false);
  const [template, setTemplate] = useState("");
  const [templateId, setTemplateId] = useState("");

  const [templateLength, setTemplatelength] = useState();
  const [dynamicTemplateLength, setDynamicTemplateLength] = useState(0);
  // console.log("modalkey", modalKey);
  useEffect(() => {
    if (modalKey) fetchTemplateList();
  }, [modalKey]);

  const fetchTemplateList = async () => {
    const resp = await dispatch(getTemplatesList({ type: modalKey }));
    // console.log("resp", resp);
  };

  const templates = useSelector((state) => state.template.templatesList);
  // console.log("templates", templates);

  const driversForSmsNotification = async (search, drivers) => {
    await dispatch(getDriverList(search, drivers));
  };
  let smsNotificationDrivers = [];
  smsNotificationDrivers = useSelector(
    (state) => state.smsNotification.driverListDataWithPagination
  );

  const ridersForSmsNotification = async (search, riders) => {
    await dispatch(getRidersList(search, riders));
  };
  let smsNotificationRiders = [];
  smsNotificationRiders = useSelector(
    (state) => state.smsNotification.riderListDataWithPagination
  );

  // console.log("smsNotificationDrivers", smsNotificationDrivers);
  const showInput = async () => {
    setInputVisible(true);
    let taggedDrivers = [];

    smsNotificationData.map((data) => {
      return taggedDrivers.push(data._id);
    });
    if (role == "driver") await driversForSmsNotification("", taggedDrivers);
    else {
      await ridersForSmsNotification("", taggedDrivers);
    }
  };
  const handleInputChange = (e) => {
    let taggedDrivers = [];
    smsNotificationData.map((data) => {
      return taggedDrivers.push(data._id);
    });
    // console.log(e);
    if (role == "driver") driversForSmsNotification(e, taggedDrivers);
    else {
      ridersForSmsNotification(e, taggedDrivers);
    }
  };

  const handleInputConfirm = (e) => {
    // if (e && smsNotificationData.indexOf(e) === -1) {
    setSmsNotificationData([...smsNotificationData, JSON.parse(e)]);
    // }
    setInputVisible(false);

    // setInputValue("");
  };

  const sendSMSNotification = async (taggedDrivers, finalMessage) => {
    const resp = await dispatch(
      sendSmsNotification(modalKey, taggedDrivers, finalMessage, templateId)
    );
    if (resp && modalKey == "sms") {
      message.success(`SMS has been sent`);
      setModalOpen(false);
      form.resetFields();
      setTemplateId("");
      setShowError(false);
      setTemplate("");
    } else if (resp && modalKey == "notification") {
      message.success(`Notification has been sent`);
      setModalOpen(false);
      form.resetFields();
      setTemplateId("");
      setShowError(false);
      setTemplate("");
    } else {
      message.error("Something wrong");
      setModalOpen(false);
      form.resetFields();
      setTemplateId("");
      setShowError(false);
      setTemplate("");
    }
  };

  const handleNotifcationSMS = async (values) => {
    let taggedDrivers = [];
    smsNotificationData.map((data) => {
      return taggedDrivers.push(data._id);
    });
    setSendCalled(true);
    const keys = Object.keys(tempValues);
    let error = false;
    let finalMessage = template;

    keys.length != 0 &&
      keys.map(async (key) => {
        if (!tempValues[key]) {
          error = true;
          return setShowError(true);
        } else {
          error = false;
          setShowError(false);
          finalMessage = finalMessage.replace(key, tempValues[key]);
        }
      });

    if (!error) sendSMSNotification(taggedDrivers, finalMessage);
    // console.log("senddd2", taggedDrivers, finalMessage);
  };

  // const [dynamicKeys, setDynamicKeys] = useState({});

  const regExp = /\{\{\d+\}\}/g;

  form.setFieldsValue({
    message: template,
  });

  const addKeys = (template) => {
    const matched = template.match(regExp);
    console.log("matched", matched);
    let obj = {};
    let length = 0;
    matched?.map((match) => {
      length = length + match.length;
      return (obj[match] = "");
    });
    setTempValues(obj);
    console.log("template length", template.length);
    console.log("length", length);
    setDynamicTemplateLength(template.length - length);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log("value", value, "tempValues[name]", tempValues[name]);

    if (
      dynamicTemplateLength + (value.length - tempValues[name].length) >
      templateLength
    ) {
      return message.error("Message length reached");
    } else {
      setDynamicTemplateLength(
        dynamicTemplateLength + (value.length - tempValues[name].length)
      );

      const keys = Object.keys(tempValues);
      let allValues = tempValues;
      allValues[name] = value;
      let empyCounts = 0;
      keys.map((key) => {
        if (allValues[key] == "" || value == "") {
          empyCounts++;
        }
      });
      if (empyCounts != 0) {
        setShowError(true);
      } else {
        setShowError(false);
      }
      setTempValues({ ...tempValues, [name]: value });
    }
  };

  const renderTemplate = () => {
    const parts = template.split(/(\{\{.*?\}\})/);
    return parts?.map((part, index) => {
      if (part.match(/\{\{.*?\}\}/)) {
        return (
          <input
            key={index}
            name={part}
            style={{
              width: "20%",

              borderLeft:
                sendCalled && tempValues?.[part] == ""
                  ? "2px solid red"
                  : "none",
              borderRight:
                sendCalled && tempValues?.[part] == ""
                  ? "2px solid red"
                  : "none",
              borderTop:
                sendCalled && tempValues?.[part] == ""
                  ? "2px solid red"
                  : "none",
              textAlign: "center",
              borderBottom:
                sendCalled && tempValues?.[part] == ""
                  ? "2px solid red"
                  : "1px solid black",
            }}
            required
            value={tempValues ? tempValues?.[part] : ""}
            placeholder={part}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        );
      } else {
        return part;
      }
    });
  };

  const handleTemplateSelect = (value) => {
    setTemplate(JSON.parse(value)?.body);
    setTemplateId(JSON.parse(value)?.template_id);
    setTemplatelength(JSON.parse(value)?.sms_body_length);
    setTempValues();
    addKeys(JSON.parse(value)?.body);
  };

  return (
    <div>
      <Modal
        open={modalOpen}
        okText={"Send"}
        title={modalTitle}
        onCancel={() => {
          setModalOpen(false);
          setSmsNotificationData([...smsNotificationDataOrg]);
          setInputVisible(false);
          setTemplate("");
          form.setFieldsValue({
            template: "",
          });
          setShowError(false);
          setSendCalled(false);
        }}
        onOk={form.submit}
        // onOk={form.submit}
      >
        <Form layout="vertical" onFinish={handleNotifcationSMS} form={form}>
          <Form.Item
            name={"drivers"}
            label={"Drivers"}
            rules={[
              {
                required: smsNotificationData.length == 0 ? true : false,
                message: "Please add drivers",
              },
            ]}
          >
            <div
              style={{
                // height: "80px",
                border: "1px solid #d3d6db",
                borderRadius: "5px",
                padding: "10px",
                overflowY: "scroll",
                maxHeight: "150px",
              }}
            >
              {smsNotificationData.map((data) => {
                return (
                  <Tag
                    style={{ marginBottom: "10px" }}
                    closable
                    key={data._id}
                    onClose={() =>
                      setSmsNotificationData(
                        smsNotificationData.filter((e) => e._id != data._id)
                      )
                    }
                  >
                    {data.name}
                  </Tag>
                );
              })}
              {inputVisible ? (
                <Select
                  size="small"
                  // style={{ width: 100, verticalAlign: "top" }}
                  showSearch
                  showArrow={true}
                  onSearch={(e) => handleInputChange(e)}
                  //   value={inputValue}
                  onSelect={handleInputConfirm}
                >
                  {role == "driver" &&
                    Array.isArray(smsNotificationDrivers) &&
                    smsNotificationDrivers.map((option) => {
                      return (
                        <Select.Option value={JSON.stringify(option)}>
                          {option.name}
                        </Select.Option>
                      );
                    })}

                  {role == "rider" &&
                    Array.isArray(smsNotificationRiders) &&
                    smsNotificationRiders.map((option) => {
                      return (
                        <Select.Option value={JSON.stringify(option)}>
                          {option.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              ) : (
                <Tag style={{ borderStyle: "dashed" }} onClick={showInput}>
                  <PlusOutlined /> New Tag
                </Tag>
              )}
            </div>
          </Form.Item>
          <Form.Item
            name={"template"}
            label={"Choose Template"}
            rules={[
              {
                required: true,
                message: "Please choose a template",
              },
            ]}
          >
            <Select onSelect={handleTemplateSelect}>
              {Array.isArray(templates) &&
                templates.map((template) => {
                  return (
                    <Select.Option
                      className="selectOptions"
                      key={template._id}
                      value={JSON.stringify(template)}
                    >
                      <Tooltip title={template?.body}>{template?.name}</Tooltip>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          {template != "" && (
            <>
              <Form.Item label={"Message"}>
                <TextArea
                  value={template}
                  showCount={modalKey == "sms" ? true : false}
                  maxLength={modalKey == "sms" && templateLength}
                  style={{ height: "100px" }}
                  disabled
                ></TextArea>
              </Form.Item>

              <Form.Item style={{ marginBottom: 0 }}>
                <div style={{ border: "1px solid black", padding: "10px" }}>
                  {renderTemplate()}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "initial",
                    justifyContent: "space-between",
                  }}
                >
                  {showError && (
                    <span className="ant-form-item-explain-error">
                      Please fill the fields
                    </span>
                  )}
                  {modalKey == "sms" && (
                    <span style={{ position: "absolute", right: 0 }}>
                      {dynamicTemplateLength + "/" + templateLength}
                    </span>
                  )}
                </div>
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
}

export default SmsNotificationModal;
