import actions from "./actions";
import { message } from "antd";
import { API } from "../../../config/api";
import { DataService } from "../../../config/dataService/dataService";

const { getCancelBookingReasons } = actions;

export const getCancelBookingReasonsData = (page, limit, role) => {
  return async (dispatch) => {
    const queryParamater =
      "?page=" + page + "&limit=" + limit + "&role=" + role;

    const resp = await DataService.get(
      API.cancelBookingReason.get + queryParamater
    );

    if (!resp.data.error) {
      dispatch(getCancelBookingReasons(resp.data.data));
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const addCancelBookingReasons = (payload) => {
  return async () => {
    const resp = await DataService.post(API.cancelBookingReason.add, payload);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const updateCancelBookingReasons = (payload, id) => {
  // console.log("update role payload", id, payload);
  return async (dispatch) => {
    const resp = await DataService.put(
      API.cancelBookingReason.update + id,
      payload
    );
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const deleteCancelBookingReasons = (id) => {
  // console.log("update role payload", id, payload);
  return async (dispatch) => {
    const resp = await DataService.delete(API.cancelBookingReason.delete + id);
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
