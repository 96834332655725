import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  message,
  Modal,
  Row,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";

import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  createSmsTemplate,
  getSmsTemplateFromDbList,
  getSmsTemplateFromList,
} from "../../redux/templates/actionCreatore";
import Processing from "../ProcessModal/Processing";

const AddNewSmsTemplate = () => {
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(50);
  const [selectedSmsTemplate, setSelectedSmsTemplate] = useState();
  const [laoding, setLoading] = useState(false);
  const [allSaveIds, setAllSaveIds] = useState([]);
  const payload = {
    pageNo,
    limit,
    type: "sms",
  };

  const { data: dbData, loading: sbLoading } = useQuery(
    "smsTemplateDbList",
    () => {
      return fetchSmsTemplateDb(payload);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchSmsTemplateDb = async (payload) => {
    const resp = await dispatch(getSmsTemplateFromDbList(payload));
    if (resp) {
      setAllIds(resp?.list);
    }
  };

  const smsTemplateDb = useSelector(
    (state) => state.template.smsTemplateDbData
  );

  const setAllIds = (list) => {
    const allIds = [];
    list.map((temp) => allIds.push(temp.template_id));

    setAllSaveIds(allIds);
  };

  const { data, loading } = useQuery(
    "smsTemplateList",
    () => {
      return fetchSmsTemplate();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchSmsTemplate = async () => {
    const resp = await dispatch(getSmsTemplateFromList());
  };

  const smsTemplate = useSelector((state) => state.template.smsTemplateData);

  const generateRandomLightColor = () => {
    var letters = "BCDEF".split("");
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };

  const addTemplateFinal = async (values) => {
    values.key = "sms";
    delete values.alias;
    values.temp_id = values.id;
    delete values.id;
    values.sms_body_length = values.body_length;
    delete values.body_length;
    values.template_created_at = values.created_at;
    delete values.is_complete;
    delete values.created_at;
    values.content = "";
    delete values.is_english;
    delete values.match_count;
    delete values.percentage;
    delete values.updated_at;
    setLoading(true);
    const resp = await dispatch(createSmsTemplate(values));
    if (resp) {
      message.success("Template added successfully");
      setLoading(false);
      await fetchSmsTemplateDb(payload);
      // await fetchSmsTemplate();
    }
    setLoading(false);
  };

  const handleAdd = async (value) => {
    Modal.confirm({
      title: "Are you sure, you want to add this template?",
      okText: "Yes",
      okType: "primary",
      onOk: () => {
        addTemplateFinal(value);
      },
    });
  };

  return (
    <div className="common_layout content-container">
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h1 className="heading">Add New SMS Templates </h1>
          {/* <BreadCrumb /> */}

          {/* Total 5 */}
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}></Col>
      </Row>
      <Divider />

      <>
        <Card
          className="shadowCardBox"
          title="SMS Templates"
          // extra={
          //   <Button type="primary" onClick={handleAdd}>
          //     Add
          //   </Button>
          // }
        >
          <Row gutter={20}>
            {Array.isArray(smsTemplate) &&
              smsTemplate.map((temp) => (
                <Col xxl={12} lg={12} md={24} sm={24} xs={24} key={temp.id}>
                  <Card
                    style={{
                      marginBottom: "20px",
                      border: `2px solid black`,
                      backgroundColor: allSaveIds.includes(temp.template_id)
                        ? "#b6ebed"
                        : "",
                      // background: selectedSmsTemplate == temp ? "#f2dd8a" : "",
                    }}
                    onClick={() => {
                      setSelectedSmsTemplate(temp);
                      // message.info("Template selected, Now you can add ");
                    }}
                  >
                    <Row style={{ textAlign: "center" }}>
                      <Col span={10}>
                        <div>
                          <p style={{ fontWeight: "bold" }}>Name</p>
                          <p>{temp.name}</p>
                        </div>
                      </Col>
                      <Col span={10}>
                        <div>
                          <p style={{ fontWeight: "bold" }}>Sender</p>
                          <p>{temp.sender}</p>
                        </div>
                      </Col>
                      <Col
                        span={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{ background: "#ecff9b", borderRadius: "50%" }}
                        >
                          {allSaveIds.includes(temp.template_id) ? (
                            <p style={{ padding: "11px 27px 0 27px  " }}>
                              Added
                            </p>
                          ) : (
                            <Button
                              type="primary"
                              onClick={() => handleAdd(temp)}
                            >
                              Add
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                    {/* <Divider /> */}
                    {/* <Row style={{ textAlign: "center" }}>
                      <Col span={10}>{temp.name}</Col>
                      <Col span={10}>{temp.sender}</Col>
                    </Row> */}
                    <Divider />

                    <Row style={{ textAlign: "center" }}>
                      <Col span={24}>{temp.body}</Col>
                    </Row>
                  </Card>
                </Col>
              ))}
          </Row>
        </Card>
      </>
      {laoding && <Processing />}
    </div>
  );
};

export default AddNewSmsTemplate;
