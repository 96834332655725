import { WalletOutlined } from "@ant-design/icons";
import {
  Avatar,
  Card,
  Col,
  DatePicker,
  Divider,
  Pagination,
  Row,
  Select,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ChartjsBarChart } from "../../components/charts/chartjs";
import { H2, H6 } from "../../components/heading/style";
import {
  driverWallet,
  driverWalletChart,
  transactionList,
} from "../../redux/drivers/actionCreatore";
import { getProfileDataById } from "../../redux/SuperAdmin/actionCreatore";
import income from "../../static/img/income.png";
import outcome from "../../static/img/outcome.png";
import { getItem } from "../../utility/localStorageControl";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
const { RangePicker } = DatePicker;
const CaptainWallet = () => {
  const { t } = useTranslation();
  //   const [userData, setUserData] = useState([]);
  const [transactionType, setTransactionType] = useState("");
  const [selectedMonth, setselectedMonth] = useState({ value: "", key: "" });
  const user = getItem("user_details");
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [fromDate, setFromDate] = useState(dayjs().subtract(7, "days"));
  const [toDate, setToDate] = useState(dayjs());
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  console.log("user", user);
  const months = [
    {
      value: "January",
      key: "jan",
      index: 0,
    },
    {
      value: "Feburary",
      key: "feb",
      index: 1,
    },
    {
      value: "March",
      key: "mar",

      index: 2,
    },
    {
      value: "April",
      key: "apr",

      index: 3,
    },
    {
      value: "May",
      key: "may",

      index: 4,
    },
    {
      value: "June",
      key: "june",
      index: 5,
    },
    {
      value: "July",
      key: "july",
      index: 6,
    },
    {
      value: "August",
      key: "aug",

      index: 7,
    },
    {
      value: "September",
      key: "sep",

      index: 8,
    },
    {
      value: "October",
      key: "oct",

      index: 9,
    },
    {
      value: "November",
      key: "nov",

      index: 10,
    },
    {
      value: "December",
      key: "dec",

      index: 11,
    },
  ];
  var mainData = [130, 100, 50, 25, 85, 310, 15, 25, 15, 110, 50, 25];
  const initialYear = 2022;
  let years = [initialYear];
  const date = new Date();
  const fullyear = date.getFullYear();
  let diff = fullyear - initialYear;
  while (diff > 0) {
    years.push(initialYear + diff);
    diff--;
  }
  years = years && years.sort((a, b) => (a < b ? 1 : -1));

  const dispatch = useDispatch();
  useEffect(() => {
    setselectedMonth({ value: "", key: "" });
    fetchWallet();
    // reteriveDriverWalletChart();
    reteriveUserData();

    // fetchTransactionHistory()
  }, []);
  useEffect(() => {
    fetchTransactionHistory();
  }, [fromDate, toDate]);

  const reteriveUserData = async () => {
    setLoading(true);
    const resp = await dispatch(getProfileDataById(user._id));
    if (resp) {
      setLoading(false);
    }
  };
  const userData = useSelector((state) => state.superAdmin.superAdminData);

  const fetchWallet = async () => {
    setLoading(true);
    const resp = await dispatch(driverWallet(user._id));
    if (resp) {
      setLoading(false);
    }
  };

  const wallet = useSelector((state) => state.driver.driverWallet);
  console.log("wallet ", wallet);

  const fetchTransactionHistory = async () => {
    setLoading(true);

    const payload = {
      driver_id: user._id,
      from_date: fromDate.format("YYYY-MM-DD"),
      to_date: toDate.format("YYYY-MM-DD"),
      page: pageNo,
      limit: pageSize,
    };
    if (transactionType != "") {
      payload.transactionType = transactionType;
    }
    const resp = await dispatch(transactionList(payload));
    if (resp) {
      setLoading(false);
    }
  };

  const transactionHistory = useSelector(
    (state) => state.driver.transactionList
  );

  const reteriveDriverWalletChart = async () => {
    console.log("object");
    const resp = await dispatch(driverWalletChart(user?._id, selectedYear));
  };

  const walletChart = useSelector((state) => state.driver.driverWalletChart);

  const handleSelectYear = async (value) => {
    setSelectedYear(value);
    await dispatch(driverWalletChart(user?._id, value));
  };

  const handleTransactionType = async (value) => {
    setTransactionType(value);
    const payload = {
      driver_id: user?._id,
      from_date: fromDate.format("YYYY-MM-DD"),
      to_date: toDate.format("YYYY-MM-DD"),
    };
    if (value != "") {
      payload.transactionType = value;
    }

    const resp = await dispatch(transactionList(payload));
    if (resp) {
      setLoading(false);
    }
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setFromDate(dates[0]);
      setToDate(dates[1]);
    } else {
      console.log("Clear");
    }
  };
  const rangePresets = [
    { label: "Today", value: [dayjs(), dayjs()] },
    { label: "Last 7 Days", value: [dayjs().add(-7, "days"), dayjs()] },
    { label: "Last Month", value: [dayjs().add(-1, "month"), dayjs()] },
    { label: "Last 6 Month", value: [dayjs().add(-6, "month"), dayjs()] },
    { label: "Last Year", value: [dayjs().add(-1, "year"), dayjs()] },
  ];

  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Source",
      render(data, record) {
        return {
          children: <span>{data && data.source ? data.source : "-"}</span>,
        };
      },
    },
    {
      title: "Destination",
      render(data, record) {
        return {
          children: (
            <span>{data && data.destination ? data.destination : "-"}</span>
          ),
        };
      },
    },
    {
      title: "Start Time",
      render(data, record) {
        return {
          children: (
            <span>
              {" "}
              {data && data.starttime
                ? new Date(data.starttime).toLocaleDateString()
                : "-"}
            </span>
          ),
        };
      },
    },
    {
      title: "End Time",
      render(data, record) {
        return {
          children: (
            <span>
              {" "}
              {data && data.endtime
                ? new Date(data.endtime).toLocaleDateString()
                : "-"}
            </span>
          ),
        };
      },
    },

    {
      title: "Amount",
      render(data, record) {
        return {
          children: <span>{data && data.amount ? data.amount : "-"}</span>,
        };
      },
    },
    {
      title: "Vehicle",
      render(data, record) {
        return {
          children: <span>{data && data.vehicle ? data.vehicle : "-"}</span>,
        };
      },
    },
    {
      title: "Coupon Code ",
      render(data, record) {
        return {
          children: <span>{data && data.coupon ? data.coupon : "-"}</span>,
        };
      },
    },
  ];
  return (
    <>
      <div className="common_layout content-container">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">Wallet</h1>

            <BreadCrumb />
          </Col>
        </Row>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col lg={24} md={24} sm={24} xs={24}>
            {/* <Card> */}
            <Row gutter={15}>
              {/* <Col xxl={8}>
                  <Card style={{height:"160px"}} >
                    <div style={{ display: "flex" }}>
                      <Col xxl={8} lg={3}>
                        <Avatar
                          size={120}
                          style={{ background: "lightblue" }}
                          icon={
                            userData && userData.profile_image ? (
                              <img
                                src={IMAGE_URL_PREFIX + userData.profile_image}
                                // crossOrigin="anonymous"
                              />
                            ) : (
                              <UserOutlined />
                            )
                          }
                        />
                      </Col>
                      <Col style={{ margin: "30px" }} lg={16}>
                        <H2 style={{ marginBottom: "0px" }}>{userData?.name}</H2>
  
                        <span className="total">{userData?.email}</span>
                      </Col>
                    </div>
                  </Card>
                </Col> */}
              <Col
                xxl={12}
                lg={12}
                md={12}
                sm={24}
                xs={24}
                className="custom-col"
              >
                <Card style={{ height: "120px" }}>
                  <div style={{ textAlign: "center" }}>
                    <h2>Wallet Balance</h2>

                    <h2>₹ {wallet?.amount ? wallet.amount.toFixed(2) : 0}</h2>
                  </div>
                </Card>
              </Col>
              <Col xxl={12} lg={12} md={12} sm={24} xs={24}>
                <Card style={{ height: "120px" }}>
                  <div style={{ textAlign: "center" }}>
                    <h2>Total Commission</h2>

                    <h2>
                      ₹ {wallet?.commission ? wallet.commission.toFixed(2) : 0}
                    </h2>
                  </div>
                </Card>
              </Col>
              {/* <Col>
                      <H2 style={{ marginBottom: "-4px" }}>
                        <Avatar
                          size={80}
                          style={{ background: "#FFEE4B", marginRight: 10 }}
                          icon={<WalletOutlined />}
                        />
                        GAuto Balance
                      </H2>
                    </Col> */}

              {/* <Col className="marginVerticle_auto" lg={4}>
                  <span
                    className="gautoCash"
                    style={{ float: "right" }}
                  >{`\u20B9${wallet ? wallet?.amount : 0}`}</span>
                </Col> */}
            </Row>

            {/* </Card> */}
            <Card className="histotyTital">
              <Row>
                {/* <Col
                      className="marginVerticle_auto"
                      xxl={10}
                      lg={10}
                      md={12}
                      sm={24}
                      xs={24}
                    >
                      <span>
                        <HistoryOutlined /> Transactions History
                      </span>
                    </Col> */}
                <Col xxl={11} lg={14} md={12} sm={24} xs={24}>
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ float: "right" }}
                  >
                    {/* <Select
                      style={{ marginLeft: 0, width: "15vw" }}
                      placeholder="Transaction Type"
                      onSelect={(value) => handleTransactionType(value)}
                      allowClear
                      onClear={() => handleTransactionType("")}
                      value={transactionType == "" ? null : transactionType}
                    >
                      <Select.Option value="top-up">Added</Select.Option>
                      <Select.Option value="debit from wallet">
                        Withdrawals
                      </Select.Option>
                      <Select.Option value="Commission">
                        Commission
                      </Select.Option>
                      <Select.Option value="received">Recieved</Select.Option>
                      <Select.Option value="Penalty">Penalty</Select.Option>
                    </Select> */}

                    {/* <Button
                          style={{ height: "48px" }}
                          onClick={() => setToggle(!toggle)}
                        >
                          <img src="https://api.iconify.design/noto-v1/bar-chart.svg?width=30&height=40" />
                        </Button> */}
                  </div>
                  <div
                    className="product-list-action d-flex justify-content-between align-items-center"
                    style={{ float: "right" }}
                  >
                    <RangePicker
                      presets={rangePresets}
                      onChange={onRangeChange}
                      defaultValue={[fromDate, toDate]}

                      // style={{display:"flex"}}
                    />
                  </div>
                </Col>
                {/* <Col lg={12}>
                  <Select
                    style={{ width: "10vw", float: "right" }}
                    placeholder={"Select Year"}
                    value={selectedYear}
                    onSelect={(value) => handleSelectYear(value)}
                  >
                    {years.map((year) => {
                      return (
                        <Select.Option value={year}>{year} </Select.Option>
                      );
                    })}
                  </Select>
                </Col> */}
              </Row>
            </Card>
            <Card style={{ height: "600px" }} id="wallet">
              <Row gutter={20}>
                <Col xxl={12} lg={12} md={12} sm={24} xs={24}>
                  <Card style={{ overflowY: "scroll", height: "520px" }}>
                    <>
                      {transactionHistory &&
                        transactionHistory?.list?.map((transaction) => {
                          const date = moment(transaction.created_at).format(
                            "YYYY-MM-DD hh:mm:ss"
                          );
                          // console.log("date", date);
                          return (
                            <Card
                              style={{ marginBottom: 10 }}
                              className="shadowCardBox"
                              key={transaction._id}
                            >
                              <Row>
                                <Col
                                  xxl={2}
                                  xl={2}
                                  lg={2}
                                  md={2}
                                  sm={2}
                                  xs={2}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Avatar
                                    size={50}
                                    style={{
                                      background: "#60B96C",
                                      marginRight: 10,
                                    }}
                                    icon={<WalletOutlined />}
                                  />
                                </Col>
                                <Col
                                  className="marginVerticle_auto"
                                  xxl={11}
                                  xl={11}
                                  lg={11}
                                  md={10}
                                  sm={10}
                                  xs={10}
                                >
                                  <H6
                                    style={{
                                      marginBottom: "0px",
                                      marginTop: "0px",
                                    }}
                                  >
                                    {transaction.transactionType
                                      .charAt(0)
                                      .toUpperCase() +
                                      transaction.transactionType.slice(1)}
                                  </H6>
                                  <span className="timing">{date}</span>
                                  <br />
                                  <Link
                                    to={`/${user.role}/manageBooking/${transaction.rideId}`}
                                  >
                                    <span
                                      className="timing"
                                      style={{
                                        color: "blue",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                      // onClick={() => navigate()}
                                    >
                                      Trip Id {transaction.rideId}
                                    </span>
                                  </Link>
                                </Col>
                                <Col
                                  xxl={8}
                                  xl={8}
                                  lg={8}
                                  md={8}
                                  sm={9}
                                  xs={9}
                                  // xs={11}
                                ></Col>

                                <Col
                                  xxl={3}
                                  xl={3}
                                  lg={3}
                                  md={4}
                                  sm={3}
                                  xs={3}
                                  // style={{
                                  //   alignItems: "center",
                                  //   display: "flex",
                                  //   justifyContent: "flex-end",
                                  // }}
                                >
                                  <span className="gautoCash1">{`+\u20B9${transaction?.amount.toFixed(
                                    2
                                  )}`}</span>
                                </Col>
                              </Row>
                            </Card>
                          );
                        })}

                      {transactionHistory?.list?.length == 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            height: "100px",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "20px",
                          }}
                        >
                          No Transaction History Found
                        </div>
                      )}
                    </>
                  </Card>

                  {transactionHistory?.list?.length > 9 && (
                    <Pagination
                      defaultCurrent={pageNo}
                      total={transactionHistory?.total_counts}
                      style={{ float: "right" }}
                      onChange={(page) => console.log(page)}
                      pageSizeOptions={[10, 20, 50, 100]}
                      showSizeChanger
                      // onShowSizeChange={onShowSizeChange}
                    />
                  )}
                </Col>

                {/* <Col xxl={12} lg={12} md={12} sm={24} xs={24}>
                  <Card>
                    <>
                      <Row
                        style={{ textAlign: "center", marginBottom: "50px" }}
                      >
                    
                        <Col span={12}>
                        
                        </Col>
                      </Row>
                   
                      <ChartjsBarChart
                        id="grossProfit"
                        // labels={[
                        //   "Jan",
                        //   "Feb",
                        //   "Mar",
                        //   "Apr",
                        //   "May",
                        //   "Jun",
                        //   "july",
                        //   "Aug",
                        //   "Sep",
                        //   "Oct",
                        // ]}
                        datasets={[
                          {
                            data: walletChart?.list,
                            // borderRadius:"0px",
                            // backgroundColor: mainData.map((data, index) => {
                            //   return index == selectedMonth.index
                            //     ? "#FFEE4B"
                            //     : "#EAE9ED";
                            // }),
                            // borderWidth: 3,
                            backgroundColor: "#FFEE4B",
                            // fill: true,
                            // pointHoverBackgroundColor: "green",
                            // pointHoverBorderWidth: 0,
                            // pointHoverBorderColor: "red",
                          },
                        ]}
                        // options={
                        //  { xField: 'label',
                        //   yField: 'value'
                        // }
                        // }
                        // options={{max:20}}
                        height={127}
                        options={{
                          maintainAspectRatio: true,
                          responsive: true,
                          legend: {
                            display: false,
                            labels: {
                              display: false,
                            },
                          },
                          scales: {
                            yAxes: [
                              {
                                gridLines: {
                                  color: "#e5e9f2",
                                },
                                ticks: {
                                  beginAtZero: true,
                                  fontSize: 12,
                                  fontColor: "#182b49",
                                  // max: maxValue,
                                },
                              },
                            ],
                            xAxes: [
                              {
                                gridLines: {
                                  display: false,
                                },
                                barPercentage: 0.4,
                                ticks: {
                                  beginAtZero: true,
                                  fontSize: 12,
                                  fontColor: "#182b49",
                                },
                              },
                            ],
                          },
                        }}
                      />
                  

                      <Row
                        gutter={20}
                        style={{ textAlign: "center", marginTop: "30px" }}
                      >
                        <Col span={12}>
                          <p>
                            <img src={income} />
                          </p>
                          <p>
                            Credit
                            <br />
                            <strong> ₹0</strong>
                          </p>
                        </Col>
                        <Col span={12}>
                          {" "}
                          <p>
                            <img src={outcome} />
                          </p>
                          <p>
                            Debit
                            <br />
                            <strong> ₹0</strong>
                          </p>
                        </Col>
                      </Row>
                    </>
                  </Card>
                </Col> */}
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CaptainWallet;
