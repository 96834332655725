import {
  DeleteOutlined,
  EditOutlined,
  FileExcelOutlined,
  FileOutlined,
  FilePdfOutlined,
  PlusOutlined,
  SearchOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import useStateRef from "react-usestateref";
import { SUPERADMIN } from "../../../config/dataService/dataService";
import {
  createCallCenter,
  getCallCenterList,
  removeCallCenter,
  updateCallCenter,
} from "../../../redux/callCenter/actionCreatore";
import { getCityData } from "../../../redux/Masters/City/actionCreatore";
import { checkAction } from "../../../utility/commonFunction";
import { getItem } from "../../../utility/localStorageControl";
import BreadCrumb from "../../BreadCrumb.js/BreadCrumb";
import { downloadReports } from "../../Export Reports/Reports";
import Loader from "../../loader/loader";
import ReportModal from "../../Modal/ReportModal";
import VerifyOtpModal from "../../Modal/VerifyOtpModal";

const ManageCallCenter = () => {
  const { t } = useTranslation();
  const [modalTitle, setModalTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateFlow, setUpdateFlow, setUpdateFlowFileRef] = useStateRef("");
  const [form] = Form.useForm();
  const [pageSize, setPageSize] = useState(50);
  const [pageNo, setPageNo] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [showPasswordField, setshowPasswordField] = useState(true);
  const [search, setSearch] = useState(null);
  const user = getItem("user_details");
  const [selectedCity, setSelectedCity] = useState(
    user.role != "super_admin" ? user?.city_id?._id : ""
  );
  const dispatch = useDispatch();
  const [tableLoading, setTableLoading] = useState(false);
  const [sort, setSort] = useState("DSC");
  const [sortKey, setSortKey] = useState("login_time");
  const [selectedTime, setSelectedTime] = useState("");

  const [showReportModal, setShowReportModal] = useState(false);
  const [docType, setDocType] = useState("");
  const [docName, setDocName] = useState("Call Center Report");
  const [showVerify, setShowVerify] = useState(false);
  const reportKeys = [
    "Name",
    "Mobile No.",
    "Email Address",
    "City",
    "Last Login",
    "Address",
  ];

  const { data, isLoading } = useQuery(
    "callcenterlist",
    () => {
      return reteriveCallCenterList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataCity, isLoading: isLoadingCity } = useQuery(
    "cityList",
    () => {
      return reteriveCityList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedId("")
    form.resetFields();
    setUpdateFlow("");
    setshowPasswordField(true);
    // handleSearch("");

    // setSelectDep(0)
  };
  const deleteCallCenterFinal = async (id) => {
    setLoading(true);
    let remove = await dispatch(removeCallCenter(id));
    if (remove) {
      reteriveCallCenterList();
      setLoading(false);
    }
  };
  const editCallCenter = (data) => {
    // console.log(data);
    setModalTitle("Update Call Center");
    setIsModalOpen(true);
    setUpdateFlow(data._id);
    form.resetFields();
    setshowPasswordField(false);
    form.setFieldsValue({
      city_id: data.city_id?._id,
      name: data.name,
      number: data?.number,
      address: data?.address,
      email: data?.email,
      countryCode: data?.countryCode,
    });
  };

  const deleteCallCenter = (value) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this call center?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteCallCenterFinal(value);
      },
    });
  };
  const handleSort = async (key) => {
    let sorting;
    setSortKey(key);
    if (sort === "ASC") {
      setSort("DSC");
      sorting = "DSC";
    } else {
      sorting = "ASC";
      setSort("ASC");
    }
    await dispatch(
      getCallCenterList(
        searchValue,
        pageNo,
        pageSize,
        selectedCity,
        key,
        sorting,
        selectedTime
      )
    );
  };
  const handleSelectTime = async (value) => {
    let val;
    if (value) val = value.format("YYYY-MM-DD");
    else {
      val = "";
    }
    setSelectedTime(val);
    setPageNo(1);
    await dispatch(
      getCallCenterList(
        searchValue,
        pageNo,
        pageSize,
        selectedCity,
        sortKey,
        sort,
        val
      )
    );
  };
  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("emailAddress"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: "City",
      key: "city",
      render: (data) => {
        return <span>{data?.city_id?.name}</span>;
      },
    },
    {
      title: t("phoneNo"),
      key: "phone",
      render: (data) => {
        return <span>{data?.countryCode + " " + data?.number}</span>;
      },
    },
    // {
    //   title: "Service Charge",
    //   render: (data) => {
    //     return <span>{data?.CallService_charge}</span>;
    //   },
    // },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Created Date",
      key: "created",
      render: (data) => {
        const date = new Date(data?.created_at);
        return <span>{date.toLocaleDateString()}</span>;
      },
    },
    {
      title: (
        <div>
          Last Login{" "}
          <Button
            style={{ background: "none", border: "none" }}
            onClick={() => handleSort("login_time")}
          >
            <SwapOutlined rotate={90} style={{ color: "black" }} />
          </Button>
        </div>
      ),
      key: "lastLogin",
      render: (data, index1, index) => {
        // console.log(data.login_time,index1);
        const date = new Date();
        if (data.login_time) {
          const date2 = new Date(data.login_time);

          const difference = date.getTime() - date2.getTime();
          const differenceInMinutes = difference / (1000 * 60);
          const differenceInHours = difference / (1000 * 60 * 60);
          const differenceInDays = difference / (1000 * 60 * 60 * 24);

          if (differenceInMinutes < 60) {
            return (
              <span>
                {" "}
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInMinutes)} minutes ago
                </Tooltip>
              </span>
            );
          } else if (differenceInHours < 24) {
            return (
              <span>
                {" "}
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInHours)} hours ago
                </Tooltip>
              </span>
            );
          } else {
            return (
              <span>
                {" "}
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInDays)} days ago
                </Tooltip>
              </span>
            );
          }
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      title: t("action"),
      align: "center",
      key: "action",
      render: (data) => {
        return (
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            {checkAction("callCenter", "edit") && (
              <Button onClick={() => editCallCenter(data)} type="primary">
                <EditOutlined />
                Edit
              </Button>
            )}
            {checkAction("callCenter", "delete") && (
              <Button
                onClick={() => deleteCallCenter(data._id)}
                style={{ cursor: "pointer", marginLeft: 3 }}
                type="primary"
                // type="danger"
                danger
                disabled
              >
                <DeleteOutlined />
                Delete
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    // reteriveCallCenterList();
    window.scrollTo(0, 0);
    // reteriveCityList();
  }, []);

  const reteriveCallCenterList = async () => {
    // setLoading(true);

    const resp = await dispatch(
      getCallCenterList(
        searchValue,
        pageNo,
        pageSize,
        selectedCity,
        sortKey,
        sort,
        selectedTime
      )
    );
    if (resp) {
      setExportData(resp?.list);

      // setLoading(false);
    }
  };

  const callCenterList = useSelector(
    (state) => state.callCenter.callCenterListData
  );

  const reteriveCityList = async () => {
    await dispatch(getCityData(""));
  };
  let cityList = useSelector((state) => state.city.cityData);
  // console.log("cityList", cityList);
  cityList = cityList?.sort((a, b) => a.name?.localeCompare(b.name));

  const handleSubmit = async (values) => {
    setLoading(true);
    // console.log(values);
    values.name = values.name.trim();
    values.address = values.address.trim();
    if (values.password == "") {
      delete values["password"];
    }
    delete values["confirmPassword"];
    if (setUpdateFlowFileRef.current == "") {
      const resp = await dispatch(createCallCenter(JSON.stringify(values)));
      if (resp) {
        setIsModalOpen(false);
        reteriveCallCenterList();
        setLoading(false);
        setSearch(null);
      }
    } else {
      const resp = await dispatch(
        updateCallCenter(JSON.stringify(values), updateFlow)
      );
      if (resp) {
        setIsModalOpen(false);
        setUpdateFlow("");
        form.resetFields();
        reteriveCallCenterList();
        setLoading(false);
        setSearch(null);
      }
    }
  };
  const handleChangeCity = async (value) => {
    // console.log(value);
    await dispatch(
      getCallCenterList(
        value,
        pageNo,
        pageSize,
        selectedCity,
        sortKey,
        sort,
        selectedTime
      )
    );
  };
  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    setTableLoading(true);
    const resp = await dispatch(
      getCallCenterList(
        searchValue,
        page,
        pageSizes,
        selectedCity,
        sortKey,
        sort,
        selectedTime
      )
    );
    if (resp) {
      setTableLoading(false);
    }
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    setTableLoading(true);
    const resp = await dispatch(
      getCallCenterList(
        searchValue,
        pageNo,
        pageSizes,
        selectedCity,
        sortKey,
        sort,
        selectedTime
      )
    );
    if (resp) {
      setTableLoading(false);
    }
  };
  const handleSearch = async (value) => {
    setSearch(value);
    await dispatch(getCityData(value));
  };

  const [exportData, setExportData] = useState(callCenterList?.list);

  const modifiedExportData =
    exportData &&
    exportData.map((item, index) => ({
      "Sr. No.": index + 1 + (pageNo - 1) * pageSize,
      Name: item.name || "-",
      "Mobile No.": item?.number || "-",
      City: item?.city_id?.name || "-",
      Address: item?.address || "-",
      "Email Address": item?.email || "-",
      "Last Login": item.login_time ? new Date(item.login_time) : "-",
    }));

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setExportData(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    // getCheckboxProps: (record) => ({
    //   disabled: record.name === 'Disabled User',
    //   // Column configuration not to be checked
    //   name: record.name,
    // }),
  };

  const handleSearchFilter = async (value) => {
    setSearchValue(value);
    await dispatch(
      getCallCenterList(
        value,
        pageNo,
        pageSize,
        selectedCity,
        sortKey,
        sort,
        selectedTime
      )
    );
  };

  const handleSelectCity = async (value) => {
    setSelectedCity(value);
    setTableLoading(true);
    if (search) {
      setSearch("");
      await dispatch(getCityData(""));
    }
    const resp = await dispatch(
      getCallCenterList(
        searchValue,
        pageNo,
        pageSize,
        value,
        sortKey,
        sort,
        selectedTime
      )
    );
    if (resp) {
      setTableLoading(false);
    }
  };

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">{t("callCenter")}</h1>
            <BreadCrumb />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            {checkAction("callCenter", "add") && (
              <Button
                style={{ float: "right" }}
                className="ADD"
                key="1"
                size="large"
                type="primary"
                // onClick={showModal}
                onClick={() => {
                  setIsModalOpen(true);
                  setModalTitle("Add New Call Center");
                  handleSearch("");
                }}
              >
                <PlusOutlined /> Add Call Center
              </Button>
            )}
          </Col>
        </Row>
        <Divider />

        {isLoading & isLoadingCity ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col
                  xxl={5}
                  xl={5}
                  lg={5}
                  md={8}
                  sm={12}
                  xs={24}
                  className="total"
                >
                  Total {callCenterList?.total_counts}
                </Col>

                {user?.role != SUPERADMIN && (
                  <Col xxl={9} xl={7} lg={7} md={16} sm={12} xs={24}></Col>
                )}

                <Col xxl={5} xl={5} lg={5} md={8} sm={12} xs={24}>
                  <Input
                    placeholder="Search By Name, Email Or Phone"
                    // enterButton="Search"
                    style={{
                      width: "100%",
                      // float: "right",
                      // marginLeft: "10px",
                      // float: "right",
                    }}
                    prefix={<SearchOutlined />}
                    value={searchValue}
                    onChange={(event) => handleSearchFilter(event.target.value)}
                  />
                </Col>

                {user?.role == SUPERADMIN && (
                  <Col xxl={5} xl={5} lg={5} md={8} sm={12} xs={24}>
                    <Select
                      placeholder="Choose city"
                      style={{
                        width: "100%",

                        overflowY: "auto",
                        maxHeight: "45px",
                        marginTop: "6px",
                      }}
                      showSearch
                      mode="multiple"
                      filterOption={false}
                      onSearch={(e) => handleSearch(e)}
                      allowClear
                      // onClear={(e) => handleSearch("")}
                      // onSelect={(value) => handleSelectCity(value)}
                      onChange={handleSelectCity}
                    >
                      {cityList &&
                        cityList.map((data) => {
                          return (
                            <Select.Option
                              value={data._id}
                              key={data._id}
                              className="selectOptions"
                            >
                              {data.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Col>
                )}

                <Col xxl={5} xl={5} lg={5} md={8} sm={12} xs={24}>
                  <DatePicker
                    showToday={false}
                    style={{
                      width: "100%",
                      // marginLeft: "20px",
                      borderRadius: 0,
                      // float: "right",
                    }}
                    placeholder={"Last Login Date"}
                    onChange={(e) => handleSelectTime(e)}
                    format={"DD/MM/YYYY"}
                    disabledDate={(current) => current.isAfter(moment())}
                  />
                </Col>

                {user?.role == SUPERADMIN && (
                  <Col xxl={4} xl={5} lg={6} md={8} sm={12} xs={24}>
                    <Select
                      placeholder="Export Report"
                      style={{
                        width: "100%",
                        // marginLeft: "20px",

                        // float: "right",
                      }}
                      value={null}
                      onSelect={(value) => {
                        // setShowReportModal(true);
                        setShowVerify(true);
                        // deleteKeys(value);
                        setDocType(value);
                      }}
                    >
                      <Select.Option
                        value="csv"
                        className="selectOptions"
                        key="csv"
                      >
                        {" "}
                        <FileOutlined /> CSV
                      </Select.Option>
                      <Select.Option
                        value="excel"
                        className="selectOptions"
                        key="excel"
                      >
                        {" "}
                        <FileExcelOutlined /> Excel (XLSX)
                      </Select.Option>
                      <Select.Option
                        value="pdf"
                        className="selectOptions"
                        key="pdf"
                      >
                        {" "}
                        <FilePdfOutlined /> Pdf
                      </Select.Option>
                    </Select>
                  </Col>
                )}
              </Row>
            </Card>
            <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                rowSelection={
                  user.role == SUPERADMIN && {
                    type: Checkbox,
                    ...rowSelection,
                  }
                }
                rowKey={"_id"}
                dataSource={callCenterList?.list}
                loading={tableLoading}
                columns={
                  user.role != "super_admin"
                    ? columns.filter((col) => col.key != "action")
                    : columns
                }
                searchable
                scroll={{ x: "45vh" }}
                pagination={
                  // callCenterList?.total_counts > 10
                  //   ?
                  {
                    showSizeChanger: true,
                    pageSize: pageSize,
                    total: callCenterList?.total_counts,
                    onShowSizeChange: onShowSizeChange,
                    onChange: onPageChange,
                    pageSizeOptions: ["50", "100", "500"],
                  }
                  // : false
                }
              />
            </Card>
          </>
        )}
      </div>
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={form.submit}
        closable={true}
        // maskClosable={false}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            label="City"
            rules={[{ required: true, message: "Please Select City" }]}
            name="city_id"
          >
            <Select
              placeholder="Choose city"
              showSearch
              onSearch={(e) => handleSearch(e)}
              filterOption={false}
              allowClear
              onClear={(e) => handleSearch("")}
              disabled={
                user.role != "super_admin" ? (updateFlow ? true : false) : false
              }
            >
              {cityList.length > 0 &&
                cityList.map((data) => {
                  return (
                    <Select.Option value={data._id} key={data._id}>
                      {data.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item
            label={`Name`}
            rules={[
              {
                required: true,
                message: "Please Enter Name",
                pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
            name="name"
          >
            <Input type="text" placeholder="Enter Name"></Input>
          </Form.Item>
          <Form.Item
            label={t("emailAddress")}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please Enter Email Address",
              },
            ]}
            name="email"
          >
            <Input type="text" placeholder="Enter Email Address"></Input>
          </Form.Item>
          {/* {showPasswordField && ( */}
          <>
            <Form.Item
              label={`Password`}
              rules={[
                {
                  required: updateFlow ? false : true,
                  message: "Please Enter Password",
                },
                {
                  pattern: new RegExp(
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
                  ),
                  message: `Must contain at least one number , one uppercase, lowercase letter, and at least 8 or more characters`,
                },
              ]}
              name="password"
            >
              <Input.Password
                onKeyPress={(event) => {
                  if (event.key == " ") {
                    event.preventDefault();
                  }
                }}
                type="password"
                placeholder="Enter Password"
              ></Input.Password>
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: updateFlow ? false : true,
                  message: "Please enter confirm password",
                },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                onKeyPress={(event) => {
                  if (event.key == " ") {
                    event.preventDefault();
                  }
                }}
                placeholder="Confirm Password"
              />
            </Form.Item>
          </>
          {/* )} */}
          <p style={{ fontWeight: "500" }}>
            {" "}
            <span style={{ color: "red" }}>* </span>
            {t("phoneNo")}
          </p>
          <Row gutter={20}>
            <Col xxl={4}>
              <Form.Item
                name="countryCode"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Country Code",
                    // max: 10,
                  },
                ]}
                initialValue={"+91"}
              >
                <Input
                  placeholder=""
                  // defaultValue={"+91"}
                  maxLength={3}
                  disabled
                ></Input>
              </Form.Item>
            </Col>
            <Col xxl={20}>
              <Form.Item
                name="number"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Number",
                  },
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Please Enter Only Numbers",
                  },
                ]}
              >
                <Input
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Enter Number"
                  maxLength={10}
                ></Input>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={`Address`}
            rules={[
              {
                required: true,
                message: "Please Enter Address",
                pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
            name="address"
          >
            <Input type="text" placeholder="Enter Address"></Input>
          </Form.Item>
        </Form>
      </Modal>
      <ReportModal
        showReportModal={showReportModal}
        setShowReportModal={setShowReportModal}
        modifiedExportData={modifiedExportData}
        docType={docType}
        reportKeys={reportKeys}
        docName={docName}
      />
      <VerifyOtpModal
        showVerify={showVerify}
        setShowVerify={setShowVerify}
        setShowReportModal={setShowReportModal}
        type={"Call Center"}
      />
    </>
  );
};

export default ManageCallCenter;
