import { UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../container/loader/loader";
import {
  getAdminCaptainData,
  getAdminDataById,
} from "../../../redux/admin/actionCreatore";
import { getItem } from "../../../utility/localStorageControl";
import BreadCrumb from "../../BreadCrumb.js/BreadCrumb";

const AdminCaptains = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(50);
  const [pageNo, setPageNo] = useState(1);
  const navigate = useNavigate();
  const user = getItem("user_details");
  const { id } = useParams();
  const [selectedCity, setSelectedCity] = useState(
    user?.role != "super_admin" ? user?.city_id?._id : ""
  );

  useEffect(() => {
    // reteriveAdminCaptainList();
    // reteriveCityList();
    reteriveAdminById();
    window.scrollTo(0, 0);
  }, []);

  const reteriveAdminById = async () => {
    await dispatch(getAdminDataById(id));
  };

  const admindata = useSelector((state) => state.admin.adminData);

  const { data, isLoading } = useQuery("admincaptainlist", () => {
    return reteriveAdminCaptainList();
  });

  //   const { data: dataCity, isLoading: isLoadingCity } = useQuery(
  //     "cityList",
  //     () => {
  //       return reteriveCityList();
  //     }
  //   );

  const reteriveAdminCaptainList = async () => {
    setLoading(true);

    const resp = await dispatch(getAdminCaptainData(pageNo, pageSize, id));
    if (resp) {
      setLoading(false);
    }
  };

  const adminCaptainList = useSelector((state) => state.admin.adminCaptainList);

  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    await dispatch(getAdminCaptainData(page, pageSizes, id));
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    await dispatch(getAdminCaptainData(current, pageSizes, id));
  };

  //   const handleSort = async (key) => {
  //     let sorting;
  //     setSortKey(key);
  //     if (sort === "ASC") {
  //       setSort("DSC");
  //       sorting = "DSC";
  //     } else {
  //       sorting = "ASC";
  //       setSort("ASC");
  //     }
  //     await dispatch(
  //         getAdminCaptainData(

  //         pageNo,
  //         "",
  //         selectedCity,
  //         key,
  //         sorting,
  //         selectedTime
  //       )
  //     );
  //   };

  const columns = [
    {
      title: t("srNo"),
      align: "center",

      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: t("emailAddress"),
      dataIndex: "email",
      key: "email",
      align: "center",
    },

    {
      title: t("phoneNo"),
      align: "center",

      render: (data) => {
        return <span>{data?.countryCode + " " + data?.number}</span>;
      },
    },
    // {
    //   title: "Service Charge",
    //   render: (data) => {
    //     return <span>{data?.CallService_charge}</span>;
    //   },
    // },
    // {
    //   title: "Commisson (₹)",
    //   // dataIndex: "commisson",
    //   key: "commisson",
    //   align: "center",
    //   render: (data) => {
    //     return <span>{data && data?.commission ? data?.commission : 0}</span>;
    //   },
    // },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      align: "center",
    },
    {
      title: "Created At",
      render: (data) => {
        const date = new Date(data?.created_at);
        return <span>{date.toLocaleDateString()}</span>;
      },
    },
    // {
    //   title: (
    //     <div>
    //       Last Login{" "}
    //       <Button
    //         style={{ background: "none", border: "none" }}
    //         onClick={() => handleSort("login_time")}
    //       >
    //         <SwapOutlined rotate={90} style={{ color: "black" }} />
    //       </Button>
    //     </div>
    //   ),
    //   key: "lastLogin",
    //   render: (data, index1, index) => {
    //     // console.log(data.login_time,index1);
    //     const date = new Date();
    //     if (data.login_time) {
    //       const date2 = new Date(data.login_time);
    //       const difference = date.getTime() - date2.getTime();
    //       const differenceInMinutes = difference / (1000 * 60);
    //       const differenceInHours = difference / (1000 * 60 * 60);
    //       const differenceInDays = difference / (1000 * 60 * 60 * 24);

    //       if (differenceInMinutes < 60) {
    //         return (
    //           <span>
    //             {" "}
    //             <Tooltip title={date2.toLocaleString()}>
    //               {parseInt(differenceInMinutes)} minutes ago
    //             </Tooltip>
    //           </span>
    //         );
    //       } else if (differenceInHours < 24) {
    //         return (
    //           <span>
    //             {" "}
    //             <Tooltip title={date2.toLocaleString()}>
    //               {parseInt(differenceInHours)} hours ago
    //             </Tooltip>
    //           </span>
    //         );
    //       } else {
    //         return (
    //           <span>
    //             {" "}
    //             <Tooltip title={date2.toLocaleString()}>
    //               {parseInt(differenceInDays)} days ago
    //             </Tooltip>
    //           </span>
    //         );
    //       }
    //     } else {
    //       return <span>-</span>;
    //     }
    //   },
    // },
    {
      title: t("action"),
      align: "center",

      render: (data) => {
        return (
          <div>
            <Button
              onClick={() => {
                navigate(`/${user?.role}/manageCaptains/drivers/${data._id}`);
              }}
              type="primary"
            >
              <UserOutlined />
              Drivers
            </Button>
            {/* {checkAction("driverCenter", "edit") && (
              <Button
                onClick={() => editDriverCenter(data)}
                type="primary"
                style={{ margin: "0 4px 0px 4px" }}
              >
                <EditOutlined />
                Edit
              </Button>
            )} */}
            {/* {checkAction("driverCenter", "delete") && (
                <Button
                  onClick={() => deleteDriverCenter(data._id)}
                  style={{ cursor: "pointer", marginLeft: 3 }}
                  type="danger"
                  disabled
                >
                  <DeleteOutlined />
                  Delete
                </Button>
              )} */}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading"> Captains Under Admin</h1>
            <BreadCrumb detailpage={admindata?.name} />
          </Col>
          {/* <Col lg={12} md={12} sm={12} xs={12}>
            {checkAction("driverCenter", "add") && (
              <Button
                style={{ float: "right" }}
                className="ADD"
                key="1"
                size="large"
                type="primary"
                // onClick={showModal}
                onClick={() => {
                  setIsModalOpen(true), setModalTitle("Add New Captain");
                  handleSearch("");
                }}
              >
                <PlusOutlined /> Add Captain
              </Button>
            )}
          </Col> */}
        </Row>
        <Divider />

        {isLoading ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col lg={4} className="total">
                  Total {adminCaptainList?.total_counts}
                </Col>
              </Row>
            </Card>
            <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                // rowSelection={{
                //   type: Checkbox,
                //   ...rowSelection,
                // }}
                // rowKey={"_id"}
                dataSource={adminCaptainList?.list}
                columns={columns}
                searchable
                scroll={{ x: "45vh" }}
                pagination={{
                  showSizeChanger: true,
                  pageSize: pageSize,
                  total: adminCaptainList?.total_counts,
                  onShowSizeChange: onShowSizeChange,
                  onChange: onPageChange,
                  pageSizeOptions: ["50", "100", "500"],
                }}
              />
            </Card>
          </>
        )}
      </div>
    </>
  );
};

export default AdminCaptains;
