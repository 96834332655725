import { Button, Form, Input, message, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  sendOTP,
  verifyOTP,
} from "../../redux/ReportDownloadOTp/actionCreatore";

function VerifyOtpModal({
  showVerify,
  setShowVerify,
  setShowReportModal,
  type,
}) {
  const [form] = Form.useForm();
  const [otpCode, setOtpCode] = useState("");
  const [showerror, setshowError] = useState(false);
  const [seconds, setSeconds] = useState(90);
  const intervalRef = useRef(null);
  const dispatch = useDispatch();
  // let intervalRef.current;

  const resendTimer = () => {
    if (showVerify == true) {
      intervalRef.current = setInterval(() => {
        setSeconds((prevCountdown) => {
          if (prevCountdown === 0) {
            clearInterval(intervalRef.current);
            return prevCountdown;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
  };

  const sendOTPFunc = async () => {
    await dispatch(sendOTP({ type: type }));
  };

  useEffect(() => {
    clearInterval(intervalRef.current);
    resendTimer();
    if (showVerify) {
      sendOTPFunc();
    }
  }, [showVerify]);

  const handleverifyOTP = async () => {
    console.log("values", otpCode);
    const payload = { otp: otpCode };
    const resp = await dispatch(verifyOTP(payload));
    if (resp) {
      message.success("OTP verified");
      setShowVerify(false);
      form.resetFields();
      setShowReportModal(true);
    } else {
      // message.error("Invalid OTP");
    }
  };

  const otpValidationRules = {
    required: true,
    message: "",
  };
  const handleInputChange = (digit, value) => {
    setOtpCode((prevCode) => {
      let updatedCode = prevCode;
      updatedCode =
        updatedCode.substr(0, digit) + value + updatedCode.substr(digit + 1);
      if (updatedCode.length != 4) {
        setshowError(true);
      } else {
        setshowError(false);
      }
      return updatedCode;
    });

    if (value && digit < 5) {
      const nextInput = document.querySelector(`[name="digit${digit + 2}"]`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  const handleResendOtp = () => {
    sendOTPFunc();
    // message.success("OTP has been sent");
    setSeconds(90);
    resendTimer();
  };

  return (
    <div>
      <Modal
        open={showVerify}
        title={"Verify OTP"}
        onOk={form.submit}
        okText={"Verify"}
        onCancel={() => {
          setShowVerify(false);
          setSeconds(90);
          form.resetFields();
          clearInterval(intervalRef.current);
        }}
      >
        <Form
          form={form}
          onFinish={handleverifyOTP}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              form.submit();
            }
          }}
        >
          <label>
            <span style={{ color: "red" }}>{"* "} </span>OTP
          </label>
          <Input.Group>
            <Form.Item
              name="digit1"
              rules={[otpValidationRules]}
              style={{ display: "inline-block", marginRight: "5px" }}
            >
              <Input
                maxLength={1}
                value={otpCode.charAt(0)}
                onChange={(e) => handleInputChange(0, e.target.value)}
                style={{ width: "50px" }}
              />
            </Form.Item>
            <Form.Item
              name="digit2"
              rules={[otpValidationRules]}
              style={{ display: "inline-block", marginRight: "5px" }}
            >
              <Input
                maxLength={1}
                name="digit2"
                value={otpCode.charAt(1)}
                onChange={(e) => handleInputChange(1, e.target.value)}
                style={{ width: "50px" }}
              />
            </Form.Item>
            <Form.Item
              name="digit3"
              rules={[otpValidationRules]}
              style={{ display: "inline-block", marginRight: "5px" }}
            >
              <Input
                maxLength={1}
                name="digit3"
                value={otpCode.charAt(2)}
                onChange={(e) => handleInputChange(2, e.target.value)}
                style={{ width: "50px" }}
              />
            </Form.Item>
            <Form.Item
              name="digit4"
              rules={[otpValidationRules]}
              style={{ display: "inline-block", marginRight: "5px" }}
            >
              <Input
                maxLength={1}
                name="digit4"
                value={otpCode.charAt(3)}
                onChange={(e) => handleInputChange(3, e.target.value)}
                style={{ width: "50px" }}
              />
            </Form.Item>
          </Input.Group>
          {showerror && <span style={{ color: "red" }}>OTP is required</span>}
        </Form>
        <p>
          <Button
            style={{
              cursor: seconds == 0 ? "pointer" : "",
              color: seconds == 0 ? "skyBlue" : "black",
              border: seconds != 0 ? "none" : "1px solid balck",
            }}
            // type="primary"
            disabled={seconds != 0}
            onClick={handleResendOtp}
          >
            Resend OTP
          </Button>
          <span style={{ float: "right" }}>
            Time Remaining:{formatTime(seconds)}
          </span>
        </p>
      </Modal>
    </div>
  );
}

export default VerifyOtpModal;
