import Cookies from "js-cookie";
import actions from "./actions";
import { message } from "antd";
import { removeItem, setItem } from "../../utility/localStorageControl";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

const {
  // loginBegin,
  loginSuccess,
  // loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
} = actions;

const login = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.auth.login, payload);

    if (!resp.data.error) {
      Cookies.set("logedIn", true);
      setItem("access_token", resp?.data?.data?.token);
      setItem("user_details", resp?.data?.data);
      window.location.reload();
      return dispatch(loginSuccess(true));
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

const forgotPassword = (payloads) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.auth.forgot, payloads);
    console.log("forgotPassword : ", resp.data.data);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

const verifyUser = (payloads) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.auth.verify, payloads);
    console.log("verifyUser : ", resp.data.data);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

const resetPassword = (id, payloads) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.auth.reset + "/" + id, payloads);
    console.log("resetPassword : ", resp.data.data);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

const logOut = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      removeItem("access_token");
      removeItem("user_details");
      Cookies.remove("logedIn");
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, forgotPassword, verifyUser, resetPassword };
