import { ArrowDownOutlined } from "@ant-design/icons";
import { Card, Col, DatePicker, Row, Spin } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardRideCount,
  getDashboardRideCountCaptains,
  getDashboardRideCountCities,
} from "../../redux/Dashboard/actionCreatore";
import loadingGif from "../loader/loading.gif";

import "./style.css";
const { RangePicker } = DatePicker;
function SuperAdminRidesCount(props) {
  const [status, setStatus] = useState("");

  const [selectedCity, setSelectedCity] = useState("");

  const [selectedAdminName, setSelectedAdminName] = useState("");
  const [cityLoading, setCityLoading] = useState(false);
  const [captainLoading, setCaptainLoading] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState();
  const [loading, setLoading] = useState(true);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const dispatch = useDispatch();

  // const { data, isLoading } = useQuery("dashboardRidesRequestCount", () => {
  //   return fetchRidesRequestCount();
  // })
   const rangePresets = [
    { label: "Today", value: [dayjs(), dayjs()] },
    { label: "Last 7 Days", value: [dayjs().add(-7, "days"), dayjs()] },
    { label: "Last Month", value: [dayjs().add(-1, "month"), dayjs()] },
    { label: "Last 6 Month", value: [dayjs().add(-6, "month"), dayjs()] },
    { label: "Last Year", value: [dayjs().add(-1, "year"), dayjs()] },
  ];

  const payload = {
    fromDate,
    toDate,
    status,
    city: selectedCity,
  };

  useEffect(() => {
    fetchRidesRequestCount(payload);
  }, []);

  const fetchRidesRequestCount = async (payload) => {
    setLoading(true);

    const res = await dispatch(getDashboardRideCount(payload));
    if (res) {
      setLoading(false);
    }
  };
  const ridesCount = useSelector((state) => state.dashboard.ridesRequestCount);

  const fetchRidesRequestCityCount = async (payload) => {
    setCityLoading(true);

    const res = await dispatch(getDashboardRideCountCities(payload));
    if (res) {
      setCityLoading(false);
    }
  };
  const ridesCityCount = useSelector(
    (state) => state.dashboard.ridesRequestCityCount
  );

  const fetchRidesRequestCaptainsCount = async (payload) => {
    setCaptainLoading(true);

    const res = await dispatch(getDashboardRideCountCaptains(payload));
    if (res) {
      setCaptainLoading(false);
    }
  };
  const ridesCaptainCount = useSelector(
    (state) => state.dashboard.ridesRequestCaptainsCount
  );

  const onRangeChange = async (dates, dateStrings) => {
    if (dates) {
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setFromDate(dates[0]);
      setToDate(dates[1]);
      setLoading(true);
      setSelectedCity("");
      setStatus("");
      payload.fromDate = dates[0];
      payload.toDate = dates[1];
      payload.city = "";
      payload.status = "";
      const resp = await dispatch(getDashboardRideCount(payload));
      if (resp) {
        setLoading(false);
      }
    } else {
      setFromDate("");
      setToDate("");
      setLoading(true);
      payload.fromDate = "";
      payload.toDate = "";

      const resp = await dispatch(getDashboardRideCount(payload));
      if (resp) {
        setLoading(false);
      }
    }
  };

  const handleStatusSelected = async (value) => {
    if (ridesCount?.[value].total > 0) {
      setStatus(value);
      setSelectedCity("");
      payload.status = value;

      fetchRidesRequestCityCount(payload);
    } else {
      setStatus("");
      setSelectedCity("");
    }
  };
  const handleCitySelected = async (value) => {
    setSelectedCity(value);
    payload.city = value;
    fetchRidesRequestCaptainsCount(payload);
  };
  if (loading) {
    return (
      <div
        style={{
          height: "200px",
          // width: "100%",
          justifyContent: "center",
          display: "flex",
        }}
      >
        {" "}
        <img src={loadingGif} />{" "}
      </div>
    );
  }

  return (
    <div>
      <Card
        title={
          <span
            style={{
              fontWeight: "bold",
              justifyContent: "center",
              display: "flex",
            }}
          >
            Ride Request Counts {ridesCount?.count ? ridesCount?.count : 0}{" "}
          </span>
        }
        extra={
          <RangePicker
            presets={rangePresets}
            onChange={onRangeChange}
            defaultValue={[fromDate, toDate]}
            style={{
              borderRadius: "0",
              // marginLeft: "10px",
              // width: "10vw",
              // width: "100%",
            }}
            format={"DD/MM/YYYY"}
            allowClear={true}
            disabledDate={(current) => current.isAfter(moment())}

            // style={{display:"flex"}}
          />
        }
      >
        <Row gutter={20}>
          <Col xxl={8} lg={8} md={8} sm={8} xs={8}>
            <>
              <Card bodyStyle={{ background: "yellow" }}>
                <div
                  style={{
                    fontSize: "18px",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "500",
                    fontFamily: "sans-serif",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setBackgroundColor("#fbfc90");
                    handleStatusSelected("expired");
                  }}
                >
                  No Action{" "}
                  {ridesCount?.expired?.total ? ridesCount?.expired?.total : 0}
                </div>
              </Card>
              {status == "expired" && (
                <ArrowDownOutlined
                  style={{ display: "flex", justifyContent: "center" }}
                />
              )}
            </>
          </Col>
          <Col xxl={8} lg={8} md={8} sm={8} xs={8}>
            <>
              <Card bodyStyle={{ background: "skyblue" }}>
                <div
                  style={{
                    fontSize: "18px",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "500",
                    fontFamily: "sans-serif",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setBackgroundColor("#b1edfc");

                    handleStatusSelected("accepted");
                  }}
                >
                  Accepted{" "}
                  {ridesCount?.accepted?.total
                    ? ridesCount?.accepted?.total
                    : 0}
                </div>
              </Card>
              {status == "accepted" && (
                <ArrowDownOutlined
                  style={{ display: "flex", justifyContent: "center" }}
                />
              )}
            </>
          </Col>{" "}
          <Col xxl={8} lg={8} md={8} sm={8} xs={8}>
            <>
              <Card bodyStyle={{ background: "#f75656" }}>
                <div
                  style={{
                    fontSize: "18px",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "500",
                    fontFamily: "sans-serif",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setBackgroundColor("#f75656");
                    handleStatusSelected("rejected");
                  }}
                >
                  Declined{" "}
                  {ridesCount?.rejected?.total
                    ? ridesCount?.rejected?.total
                    : 0}
                </div>
              </Card>
              {status == "rejected" && (
                <ArrowDownOutlined
                  style={{ display: "flex", justifyContent: "center" }}
                />
              )}
            </>
          </Col>
        </Row>

        {status && (
          <>
            <Row
              gutter={20}
              style={{ border: "2px solid black" }}
              // className={`smooth-div ${showPendindData ? "open" : "close"}`}
            >
              {cityLoading ? (
                <Col span={24}>
                  <Spin
                    size={"large"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                      margin: "40px 0px",
                    }}
                  />
                </Col>
              ) : (
                ridesCityCount?.map((data, index) => {
                  return (
                    <Col
                      xxl={4}
                      lg={8}
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                    >
                      <Card
                        bodyStyle={{
                          background: backgroundColor,
                          cursor: "pointer",
                          border:
                            selectedCity == data._id
                              ? "2px solid black"
                              : "none",
                        }}
                        key={data._id}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                          onClick={() => {
                            setSelectedAdminName(data?.admin?.name);
                            handleCitySelected(data._id);
                          }}
                        >
                          <span
                            style={{
                              justifyContent: "center",
                              display: "flex",
                              textAlign: "center",
                            }}
                          >
                            {data.total}
                          </span>

                          <span
                            style={{
                              justifyContent: "center",
                              display: "flex",
                              textAlign: "center",
                            }}
                          >
                            <span style={{ fontWeight: "bold" }}>City:</span>{" "}
                            {data?.name}
                          </span>
                          <span
                            style={{
                              justifyContent: "center",
                              display: "flex",
                              textAlign: "center",
                            }}
                          >
                            {data?.admin?.name}
                          </span>
                        </div>
                      </Card>
                    </Col>
                  );
                })
              )}
            </Row>
            {selectedCity && <ArrowDownOutlined style={{ marginLeft: "5%" }} />}
          </>
        )}

        <br />
        {selectedCity && (
          <div>
            <Row>
              <Col span={24} style={{ fontWeight: "bold" }}>
                Captains Under {selectedAdminName}
              </Col>
            </Row>
            <Row gutter={20}>
              {captainLoading ? (
                <Col span={24}>
                  <Spin
                    size={"large"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                      margin: "40px 0px",
                    }}
                  />
                </Col>
              ) : (
                ridesCaptainCount?.map((data) => {
                  return (
                    <Col xxl={3} lg={8} style={{ marginTop: "20px" }}>
                      <Card bodyStyle={{ background: backgroundColor }}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            {data.total}
                          </span>

                          <span
                            style={{
                              justifyContent: "center",
                              display: "flex",
                              textAlign: "center",
                            }}
                          >
                            {data.name}
                          </span>
                        </div>
                      </Card>
                    </Col>
                  );
                })
              )}
            </Row>
          </div>
        )}
      </Card>
    </div>
  );
}

export default SuperAdminRidesCount;
