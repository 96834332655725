const actions = {
  GET_PARTNERS_LIST: "GET_PARTNERS_LIST",
  GET_PARTNER:"GET_PARTNER",
  getPartnersList: (data) => {
    return {
      type: actions.GET_PARTNERS_LIST,
      data,
    };
  },
  getPartnerData: (data) => {
    return {
      type: actions.GET_PARTNER,
      data,
    };
  },
};

export default actions;
