import { MailOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Table,
  message,
  Tabs,
  Tag,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStateRef from "react-usestateref";

import TextArea from "antd/lib/input/TextArea";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { deleteProfileDeleteReasons } from "../../redux/Masters/deleteReasons/actionCreatore";
import {
  createSubCategoryAnswer,
  getCategoryListWithoutPagination,
  getSubCategoryAnswerList,
} from "../../redux/Support/actionCreatore";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import Loader from "../loader/loader";
import TabPane from "antd/lib/tabs/TabPane";
import { WordFormate } from "../../utility/commonFunction";
const SupportAnswers = () => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [ModalTitle, setModalTitle] = useState("");
  const [updateFlow, setUpdateFlow, setUpdateFlowFileRef] = useStateRef("");
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(50);
  const [pageNo, setPageNo] = useState(1);
  const [details, setDetails] = useState();
  const [activeKey, setActiveKey] = useState("1");
  const [role, setRole] = useState("rider");
  const [inputSearch, setInputSearch] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    // fetchSupportCategoryQuery();
    window.scrollTo(0, 0);
  }, []);

  const payload = {
    page: pageNo,
    limit: pageSize,
    role,
    inputSearch,
    categoryId,
    status,
  };

  const { data: data, isLoading: isLoading } = useQuery(
    "supportCategoryQueryList",
    () => {
      return fetchSupportCategoryQuery(payload);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchSupportCategoryQuery = async (payload) => {
    setLoading(true);
    const resp = await dispatch(getSubCategoryAnswerList(payload));
    if (resp) {
      setLoading(false);
    }
  };

  const answerList = useSelector(
    (state) => state.support.supportSubCategoryAnswerData
  );

  const { data: category, isLoading: isLoadingCategory } = useQuery(
    "supportCategoryListForSupportAnsPage",
    () => {
      return fetchSupportCategoryList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchSupportCategoryList = async () => {
    setLoading(true);
    const resp = await dispatch(
      getCategoryListWithoutPagination({ role: role })
    );
    if (resp) {
      setLoading(false);
    }
  };

  const categoryList = useSelector(
    (state) => state.support.supportCategoryDataWithoutPagination
  );

  const handleSubmit = async (values) => {
    // setLoading(true);
    // form.resetFields();

    values.supportSubCategoryId = details?.supportSubCategoryId?._id;
    values.riderQuery = details?.riderQuery;
    values.status = details?.status;
    values.role = details?.role;

    const resp = await dispatch(createSubCategoryAnswer(values));
    if (resp) {
      setIsModalOpen(false);

      message.success("Email sent successfully");
    }
  };

  const sendMail = (data) => {
    setModalTitle("Reply");
    setIsModalOpen(true);
    setUpdateFlow(data._id);

    setDetails(data);
    form.resetFields();
    form.setFieldsValue({
      email: data.email,
      // body: "Hello \nGreeting of the day,\nWrite you message here \nThanks\nGAuto Team"
    });
  };

  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },
    {
      title: (
        <div>
          Name <br />
          Number
        </div>
      ),

      render: (data, record) => {
        return (
          <div>
            <span>{data?.name ? data?.name : "-"}</span>
            <br />
            <span>{data?.number ? data?.number : "-"}</span>
          </div>
        );
      },
    },
    {
      title: "Email",

      render: (data, record) => {
        return <span>{data?.email}</span>;
      },
    },
    // {
    //   title: "Date",

    //   render: (data, record) => {
    //     const date = new Date(data?.created_at);
    //     return <span>{date.toLocaleDateString()}</span>;
    //   },
    // },
    {
      title: "Category Title",

      render: (data, record) => {
        const str = data?.que.category;
        const str2 = str?.charAt(0).toUpperCase() + str?.slice(1);
        return <span>{str2 ? str2 : "-"}</span>;
      },
    },
    {
      title: "Sub Category Title",

      render: (data, record) => {
        return <span>{data?.que ? data?.que.question : "-"}</span>;
      },
    },
    {
      title: "Answers",

      render: (data, record) => {
        return (
          <div style={{ maxHeight: "43px", overflowY: "auto" }}>
            {Array.isArray(data?.ans) &&
              data?.ans?.map((ans) => {
                return (
                  <span>
                    {ans.answer}
                    <br />
                  </span>
                );
              })}
          </div>
        );
      },
    },
    // {
    //   title: "Status",

    //   render: (data, record) => {
    //     return (
    //       <Tag
    //         color={
    //           data?.isSolved == "pending"
    //             ? "yellow"
    //             : data?.isSolved == "resolved"
    //             ? "green"
    //             : "red"
    //         }
    //       >
    //         {WordFormate(data?.isSolved)}
    //       </Tag>
    //     );
    //   },
    // },
    {
      title: t("action"),
      // dataIndex: "documents",
      key: "action",
      render(data, record) {
        return (
          <div>
            <Button type="primary" onClick={() => sendMail(data)}>
              <MailOutlined />
              Reply
            </Button>
          </div>
        );
      },
    },
  ];
  const showModal = () => {
    form.resetFields();
    setModalTitle("Add New Sub Category");
    setIsModalOpen(true);

    // setSelectedId("")
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedId("")
    form.resetFields();
    setUpdateFlow("");
    // setSelectDep(0)
  };

  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    payload.page = page;
    // await dispatch(getReasonsData(page, pageSizes));
    await dispatch(getSubCategoryAnswerList(payload));
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    payload.limit = pageSizes;
    // await dispatch(getReasonsData(pageNo, pageSizes));
    await dispatch(getSubCategoryAnswerList(payload));
  };

  const handleTabClick = async (key) => {
    setActiveKey(key);
    setPageNo(1);
    setPageSize(50);
    setLoading(true);
    setInputSearch("");
    setCategoryId("");
    setStatus("");
    payload.page = 1;
    payload.limit = 50;
    payload.role = "rider";
    payload.inputSearch = "";

    payload.categoryId = "";

    payload.status = "";

    setRole("rider");
    if (key == "1") {
      await dispatch(getSubCategoryAnswerList(payload));
      await dispatch(getCategoryListWithoutPagination({ role: "rider" }));
    }
    if (key == "2") {
      setRole("driver");
      payload.role = "driver";
      await dispatch(getSubCategoryAnswerList(payload));
      await dispatch(getCategoryListWithoutPagination({ role: "driver" }));
    }
  };

  const handleSearch = async (value) => {
    setInputSearch(value ? value : "");
    payload.inputSearch = value ? value : "";
    fetchSupportCategoryQuery(payload);
  };
  const handleSelectCategory = async (value) => {
    setCategoryId(value ? value : "");
    payload.categoryId = value ? value : "";
    fetchSupportCategoryQuery(payload);
  };
  const handleSelectStatus = async (value) => {
    setStatus(value ? value : "");
    payload.status = value ? value : "";
    fetchSupportCategoryQuery(payload);
  };

  const tabItems = [
    {
      key: "1",
      label: "Rider",
      children: (
        <>
          <Card style={{ marginBottom: 10 }} className="shadowCardBox">
            <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
              <Col xxl={9} xl={9} lg={6} md={12} sm={12} xs={24}>
                <span className="total">Total {answerList?.total_counts}</span>{" "}
              </Col>
              <Col xxl={5} xl={5} lg={6} md={12} sm={12} xs={24}>
                <Input
                  style={{ width: "100%" }}
                  placeholder="Search By Name, Email Or Phone"
                  onChange={(e) => handleSearch(e.target.value)}
                  value={inputSearch}
                  prefix={<SearchOutlined />}
                />
              </Col>
              <Col xxl={5} xl={5} lg={6} md={12} sm={12} xs={24}>
                <Select
                  placeholder="Select Category"
                  style={{ float: "right", width: "100%" }}
                  onSelect={handleSelectCategory}
                  allowClear
                  onClear={handleSelectCategory}
                  value={categoryId ? categoryId : null}
                >
                  {Array.isArray(categoryList) &&
                    categoryList?.map((data) => {
                      return (
                        <Select.Option
                          value={data._id}
                          className="selectOptions"
                          key={data._id}
                        >
                          {WordFormate(data.category)}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Col>
              <Col xxl={5} xl={5} lg={6} md={12} sm={12} xs={24}>
                <Select
                  placeholder="Choose Status"
                  style={{ float: "right", width: "100%" }}
                  onSelect={handleSelectStatus}
                  allowClear
                  onClear={handleSelectStatus}
                  value={status ? status : null}
                >
                  <Select.Option value={"pending"} key={"1"}>
                    <Tag color="yellow"> Pending</Tag>
                  </Select.Option>
                  <Select.Option key={"2"} value={"resolved"}>
                    <Tag color="green"> Resolved</Tag>
                  </Select.Option>
                  <Select.Option key={"3"} value={"notResolved"}>
                    <Tag color="red"> Not Resolved</Tag>
                  </Select.Option>
                </Select>
              </Col>
            </Row>
          </Card>
          <Card className="shadowCardBox">
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              dataSource={answerList?.list}
              columns={columns}
              searchable
              scroll={{ x: "45vh" }}
              pagination={{
                showSizeChanger: true,
                pageSize: pageSize,
                total: answerList?.total_counts,
                onShowSizeChange: onShowSizeChange,
                onChange: onPageChange,
                pageSizeOptions: ["50", "100", "200"],
              }}
            />
          </Card>
        </>
      ),
    },
    {
      key: "2",
      label: "Driver",
      children: (
        <>
          <Card style={{ marginBottom: 10 }} className="shadowCardBox">
            <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
              <Col xxl={9} xl={9} lg={6} md={12} sm={12} xs={24}>
                <span className="total">Total {answerList?.total_counts}</span>{" "}
              </Col>
              <Col xxl={5} xl={5} lg={6} md={12} sm={12} xs={24}>
                <Input
                  style={{ width: "100%" }}
                  placeholder="Search By Name, Email Or Phone"
                  onChange={(e) => handleSearch(e.target.value)}
                  value={inputSearch}
                  prefix={<SearchOutlined />}
                />
              </Col>
              <Col xxl={5} xl={5} lg={6} md={12} sm={12} xs={24}>
                <Select
                  placeholder="Select Category"
                  style={{ float: "right", width: "100%" }}
                  onSelect={handleSelectCategory}
                  allowClear
                  onClear={handleSelectCategory}
                  value={categoryId ? categoryId : null}
                >
                  {Array.isArray(categoryList) &&
                    categoryList?.map((data) => {
                      return (
                        <Select.Option
                          value={data._id}
                          className="selectOptions"
                          key={data._id}
                        >
                          {WordFormate(data.category)}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Col>
              <Col xxl={5} xl={5} lg={6} md={12} sm={12} xs={24}>
                <Select
                  placeholder="Choose Status"
                  style={{ float: "right", width: "100%" }}
                  onSelect={handleSelectStatus}
                  allowClear
                  onClear={handleSelectStatus}
                  value={status ? status : null}
                >
                  <Select.Option value={"pending"} key={"1"}>
                    <Tag color="yellow"> Pending</Tag>
                  </Select.Option>
                  <Select.Option key={"2"} value={"resolved"}>
                    <Tag color="green"> Resolved</Tag>
                  </Select.Option>
                  <Select.Option key={"3"} value={"notResolved"}>
                    <Tag color="red"> Not Resolved</Tag>
                  </Select.Option>
                </Select>
              </Col>
            </Row>
          </Card>
          <Card className="shadowCardBox">
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              dataSource={answerList?.list}
              columns={columns}
              searchable
              scroll={{ x: "45vh" }}
              pagination={{
                showSizeChanger: true,
                pageSize: pageSize,
                total: answerList?.total_counts,
                onShowSizeChange: onShowSizeChange,
                onChange: onPageChange,
                pageSizeOptions: ["50", "100", "200"],
              }}
            />
          </Card>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">Support Answers</h1>
            <BreadCrumb />
          </Col>
          {/* <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              style={{ float: "right" }}
              className="ADD"
              key="1"
              size="large"
              type="primary"
              onClick={showModal}
            >
              <PlusOutlined /> Add New
            </Button>
          </Col> */}
        </Row>

        <Divider />
        <Tabs
          type="card"
          activeKey={activeKey}
          items={!isLoading && tabItems}
          onTabClick={handleTabClick}
        ></Tabs>
        {isLoading && <Loader />}
      </div>
      <Modal
        title={ModalTitle}
        open={isModalOpen}
        onOk={form.submit}
        // closable={true}
        // maskClosable={false}
        onCancel={handleCancel}
        okText="Send"
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            name="email"
            label={"To"}
            rules={[
              {
                required: true,
                message: "Please Enter To",
                // pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <Input type={"email"} />
          </Form.Item>
          <Form.Item
            name="answer"
            label={"Message"}
            rules={[
              {
                required: true,
                message: "Please Enter Message",
                pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <TextArea style={{ height: "200px" }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SupportAnswers;
