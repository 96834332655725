import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { message } from "antd";
import { getItem, removeItem } from "../../utility/localStorageControl";
// export const IMAGE_URL_PREFIX = "http://172.105.41.30:9696";
export const IMAGE_URL_PREFIX = "https://d21zjjdazj97b6.cloudfront.net";

//DEVELOPMENTENDPOINT
const API_ENDPOINT = "https://api.g-auto.in/api/v1/";

//STAGING ENDPOINT
// const API_ENDPOINT = "https://api.g-auto.org/api/v1/";
// export const GOOGLE_MAP_API_KEY = "AIzaSyAinYizI3jBt7e8je5NLDTQGb-i4e5y6ok";
export const GOOGLE_MAP_API_KEY = "AIzaSyCEkQ_KW66M2BjP03QiJ3R4dyzPWcOfuvw";

export const SOCKET_URL_ENDPOINT = "https://socket.g-auto.in";
// const API_ENDPOINT = "http://192.168.1.124:4200/api/"
// const API_ENDPOINT = "http://192.168.1.59:4200/api/"
export const SUPERADMIN = "super_admin";
export const ADMIN = "admin";
export const CAPTAIN = "captain";
export const CALLCENTER = "callCenter";
export const ONDUTY = "on_duty";
export const OFFDUTY = "off_duty";
export const AUTOCLICKER = "auto_clicker";
export const BOOKFORHOME = "book_for_home";

//constants for map API TYPE statistics
export const GOOGLE_MAP_API_TYPE_PLACE = "place";
export const GOOGLE_MAP_API_TYPE_MAPS = "maps";
export const GOOGLE_MAP_API_TYPE_DIRECTIONS = "directions";
export const GOOGLE_MAP_API_TYPE_DISTANCE_MATRIX = "distance_matrix";
export const GOOGLE_MAP_API_TYPE_GEOCODING = "geocoding";
export const GOOGLE_MAP_API_TYPE_GEOLOCATION = "geolocation";

//constants for map API SUB TYPE statistics
export const GOOGLE_MAP_API_SUBTYPE_AUTOCOMPLETE = "autocomplete";
export const GOOGLE_MAP_API_SUBTYPE_DIRECTION_SERVICE_ROUTE =
  "directionsService";
export const GOOGLE_MAP_API_SUBTYPE_VIEW_PORT_INFO_SERVICE =
  "viewportInfoService";
export const GOOGLE_MAP_API_SUBTYPE_QUOTA_SERVICE = "quotaService";

//constants for map MODULE NAME statistics
export const GOOGLE_MAP_MODULE_NAME_CITY_MASTER = "cityMaster";
export const GOOGLE_MAP_MODULE_NAME_RIDE_PATH = "ridePath";
export const GOOGLE_MAP_MODULE_NAME_DRIVER_LOCATION_ON_MAP =
  "driverLocationOnMap";
export const GOOGLE_MAP_MODULE_NAME_RIDER_LOCATION_ON_MAP =
  "riderLocationOnMap";
export const GOOGLE_MAP_MODULE_NAME_MULTIPLE_DRIVER_LOCATION_ON_MAP =
  "multipleDriverLocationOnMap";

export const GOOGLE_MAP_MODULE_NAME_RAILWAY_SELECTION_NEW_FARE =
  "railwaySelectionNewFare";

export const GOOGLE_MAP_MODULE_NAME_RAILWAY_SELECTION_UPDATE_FARE =
  "railwaySelectionUpdateFare";

export const GOOGLE_MAP_MODULE_NAME_AIRPORT_SELECTION_NEW_FARE =
  "airportSelectionNewFare";

export const GOOGLE_MAP_MODULE_NAME_AIRPORT_SELECTION_UPDATE_FARE =
  "airportSelectionUpdateFare";
// const contextValue = useMemo(
//   () => ({
//     name: 'Ant Design',
//   }),
//   [],

// );
const authHeader = () => ({
  Authorization: `Bearer ${getItem("access_token")}`,
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${getItem("access_token")}`,
    "Content-Type": "application/json",
  },
});

class DataService {
  static get(path = "") {
    return client({
      method: "GET",
      url: path,
      headers: { ...authHeader() },
    });
  }

  static post(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "POST",
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "PATCH",
      url: path,
      data: data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static delete(path = "", data = {}) {
    return client({
      method: "DELETE",
      url: path,
      data: data,
      headers: { ...authHeader() },
    });
  }

  static put(path = "", data = {}) {
    return client({
      method: "PUT",
      url: path,
      data: data,
      headers: { ...authHeader() },
    });
  }

  static getPdf(path = "") {
    return client({
      method: "GET",
      url: path,
      headers: { ...authHeader() },
      responseType: "arraybuffer",
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
let flag = false;
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie

  const requestConfig = config;

  const { headers } = config;
  requestConfig.headers = {
    ...headers,
    Authorization: `Bearer ${getItem("access_token")}`,
  };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => {
    if (response.data.statusCode == 200) {
    } else if (
      response.data.message == " Action successfully performed for date"
    ) {
    } else if (
      response.data.message == "Attendance profile updated successfully"
    ) {
    } else if (response.data.message == "Date created successfully") {
    } else {
      if (response.data.message) {
        message.success(`${response.data.messageCode}`);
      }
    }
    return response;
  },
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    // const { response } = error;
    // const originalRequest = error.config;
    // if (response) {
    //   message.error(`${response.data.message}`);
    // }
    // return Promise.reject(error);

    const { response } = error;

    const originalRequest = error.config;
    if (response) {
      if (
        response.data &&
        response.data.message === "access_permission_denied"
      ) {
      }

      // if (response.status === 500) {
      //   message.error({
      //     content: response.data.message,
      //     style: {
      //       float: "center",
      //       marginTop: "2vh",
      //     },
      //   });
      // } else if (response.status === 400) {
      //   message.error({
      //     content: response.data.message,
      //     style: {
      //       float: "center",
      //       marginTop: "2vh",
      //     },
      //   });
      // } else if (response.status === 401) {
      //   removeItem("access_token");
      //   Cookies.remove("logedIn");
      //   window.location.reload();
      //   message.error({
      //     content: response.data.message,
      //     style: {
      //       float: "center",
      //       marginTop: "2vh",
      //     },
      //   });
      // } else if (response.status === 403) {
      //   removeItem("access_token");
      //   Cookies.remove("logedIn");
      //   window.location.reload();
      //   message.error({
      //     content: response.data.Message
      //       ? response.data.Message
      //       : "Something went wrong, Please try again to login",
      //     style: {
      //       float: "center",
      //       marginTop: "2vh",
      //     },
      //   });
      // }
      if (response.status === 406) {
        message.error({
          content: response.data.message,
          style: {
            float: "center",
            marginTop: "2vh",
          },
        });
      } else if (response.status === 500) {
        message.error({
          content: response.data.message,
          style: {
            float: "center",
            marginTop: "2vh",
          },
        });
        return response;
      } else if (response.status === 400) {
        message.error({
          content: response.data.message,

          style: {
            float: "center",
            marginTop: "2vh",
          },
        });
      } else if (response.status === 403) {
        message.error({
          content: "You Are UnAuthorized",

          style: {
            float: "center",
            marginTop: "2vh",
          },
        });
      } else if (response.status === 404) {
        message.error({
          content: response.data.message,

          style: {
            float: "center",
            marginTop: "2vh",
          },
        });
      } else {
        return originalRequest;
      }
      return response.data;
    } else {
      message.error({
        content: `Something went wrong. Please check your internet connection and try again!`,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });
    }
    return Promise.reject(error);
  }
);
export { DataService };
