import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const { getMapStatisticsList, getMapApiTypeList, getMapStatisticsCounts } =
  actions;

export const fetchMapStatistics = ({
  page,
  limit,
  fromDate,
  toDate,
  originFrom,
  googleApiType,
  googleSubApiType,
  sortKey,
  sortValue,
}) => {
  return async (dispatch) => {
    const queryParameter =
      "?page=" +
      page +
      "&limit=" +
      limit +
      (fromDate && "&formDate=" + fromDate.format("YYYY-MM-DD")) +
      (toDate && "&toDate=" + toDate.format("YYYY-MM-DD")) +
      "&originFrom=" +
      originFrom +
      "&googleApiType=" +
      googleApiType +
      "&googleSubApiType=" +
      googleSubApiType +
      "&key=" +
      sortKey +
      "&value=" +
      sortValue;
    const resp = await DataService.get(API.mapStatistics.get + queryParameter);

    if (!resp.data.error) {
      dispatch(getMapStatisticsList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const fetchMapAPiTypes = () => {
  return async (dispatch) => {
    const resp = await DataService.get(API.mapStatistics.apiTypes);

    if (!resp.data.error) {
      dispatch(getMapApiTypeList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const addMapStatistics = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.mapStatistics.add, payload);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const fetchMapStatisticsCounts = ({
  originFrom,

  fromDate,
  toDate,
  selectedUser,
}) => {
  return async (dispatch) => {
    const queryParameter =
      "?originFrom=" +
      originFrom +
      (fromDate && "&fromDate=" + fromDate.format("YYYY-MM-DD")) +
      (toDate && "&toDate=" + toDate.format("YYYY-MM-DD")) +
      (selectedUser && "&user_id=" + selectedUser);

    const resp = await DataService.get(
      API.mapStatistics.getTotalCounts + queryParameter
    );

    if (!resp.data.error) {
      dispatch(getMapStatisticsCounts(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getAllDriverListForStatistics = ({ page, limit }) => {
  return async (dispatch) => {
    const queryParameter =
      "?role=driver" +
      "&is_driver_approved=true" +
      "&page=" +
      page +
      "&limit=" +
      limit;
    const resp = await DataService.get(
      API.mapStatistics.getDriverRider + queryParameter
    );

    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const getAllRiderListForStatistics = ({ page, limit }) => {
  return async (dispatch) => {
    const queryParameter = "?role=rider&page=" + page + "&limit=" + limit;
    const resp = await DataService.get(
      API.mapStatistics.getDriverRider + queryParameter
    );

    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const getAdminDataForStatistics = ({ page, limit }) => {
  return async (dispatch) => {
    let queryParametere = "?page=" + page + "&limit=" + limit;

    const resp = await DataService.get(API.admin.get + queryParametere);
    //  console.log(resp);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      // message.error(resp.data.message);
      // history.push("forbidden");
      return "false";
    }
  };
};

export const getCallCenterListForStaistics = ({ page, limit }) => {
  return async (dispatch) => {
    let queryParametere = "?page=" + page + "&limit=" + limit;

    const resp = await DataService.get(API.callCenter.getAll + queryParametere);

    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getCaptainListForStaistics = ({ page, limit }) => {
  return async (dispatch) => {
    let payload = {
      page: page,
      limit: limit,
    };

    const resp = await DataService.post(API.driverCenter.getAll, payload);

    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
