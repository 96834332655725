import actions from "./actions";

const { GET_TIPS } = actions;

const initState = {
  tipsData: {},
};

const tipsDataReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_TIPS:
      return {
        ...state,
        tipsData: data,
      };

    default:
      return state;
  }
};
export default tipsDataReducer;
