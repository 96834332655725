import React from "react";

const PrivacyPolicyDriverHtml = () => {
  return (
    <>
      <div style={{ marginTop: "5vh" }}>
        <p style={{ fontWeight: "bold", fontSize: "24px" }}>Privacy Policy</p>

        <p>
          We respect your privacy and recognise the need to protect the
          personally identifiable information (any information by which you can
          be identified, such as name, address, and telephone number) you share
          with us. We would like to assure you that we follow appropriate
          standards when it comes to protecting your privacy on our web sites.
          There are times when we may collect personal information from you such
          as name, physical address or telephone number. It is our intent to
          inform you before we do that and to tell you what we intend to do with
          the information. Generally, you will have the option not to provide
          the information, and in the future you will be able to "opt out" of
          certain uses of the information.
        </p>
        <p>
          If you choose not to provide the information we request, you can still
          visit the OneWay.Cab website & App, but you may be unable to access
          certain options, offers and services. OneWay.Cab will not sell, trade
          or disclose to third parties any information derived from the
          registration for, or use of, any online service (including names and
          addresses) without the consent of the user or customer (except as
          required by subpoena, search warrant, or other legal process or in the
          case of imminent physical harm to the user or others).
        </p>
        <p>
          G-Auto will take appropriate steps to protect the information you
          share with us. We have implemented technology and security features
          and strict policy guidelines to safeguard the privacy of your
          personally identifiable information from unauthorized access and
          improper use or disclosure. OneWay.Cab will continue to enhance our
          security procedures as new technology becomes available. If our
          privacy policy changes in the future, it will be posted here and a new
          effective date will be shown.
        </p>

        <p>
          You should access our privacy policy regularly to ensure you
          understand our current policies. If you have any query / doubts,
          kindly feel free to drop & E-mail at info@oneway.cab. Please reference
          the privacy policy in your subject line. OneWay.Cab will attempt to
          respond to all reasonable concerns or inquiries within five business
          days of receipt.
        </p>
        <p>Thank you for using G-Auto.Cab.</p>
        <br />
      </div>

      {/* </AuthWrapper> */}
    </>
  );
};

export default PrivacyPolicyDriverHtml;
