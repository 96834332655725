import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IMAGE_URL_PREFIX } from "../../../config/dataService/dataService";
import { getDriverCenterList } from "../../../redux/deriverCenter/actionCreatore";
import {
  approveDocument,
  approveDriver,
  getDriverById,
  getDriverDocuments,
  rejectDocument,
  rejectDriver,
} from "../../../redux/drivers/actionCreatore";
import { getCityData } from "../../../redux/Masters/City/actionCreatore";
import { getItem } from "../../../utility/localStorageControl";
import BreadCrumb from "../../BreadCrumb.js/BreadCrumb";
import Spinner from "../../loader/Spinner";
import { SocketContext } from "../../socket/socketContext";
const { Panel } = Collapse;
const ReVerficationRequest = () => {
  const { driverId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(5);
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [disableApprove, setDisableApprove] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectTitle, setRejectTitle] = useState("");
  const [documentId, setDocumentId] = useState();
  const [selectedCity, setSelectedCity] = useState("");
  const [showApprove, setShowApprove] = useState(true);
  const [showReject, setShowReject] = useState(true);
  const [insuranceDate, setInsuranceDate] = useState("");
  const [insurnaceReuiredError, setInsuranceRequiredError] = useState(false);
  const socket = useContext(SocketContext);
  const user = getItem("user_details");

  const handleCancel = () => {
    setIsModalOpen(false);
    setRejectModal(false);
    setRejectTitle("");
    form.resetFields();
  };
  useEffect(() => {
    reteriveDocuments();
    reteriveDriverById();
    reteriveDriverCenterList();
    reteriveCityList();
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (socket) {
      socket.emit("readRecord", {
        userId: user._id,
        recordType: "driver_reverification_reuqest",
        driver_id: driverId,
      });

      socket.on("recordReaded", (data) => {
        console.log("data", data);
        if (data.isRead) {
          socket.emit("getUnreadRecordCount", { userId: user._id });

          socket.on("unreadRecordCount", (data) => {
            // setLocation(data.location.coordinates);
            // setLoading(false)
          });
        }
      });
    }
  }, [socket]);
  const reteriveDriverCenterList = async () => {
    setPageSize(pageSize + 5);
    await dispatch(getDriverCenterList("", pageNo, pageSize, selectedCity));
  };

  const captainList = useSelector(
    (state) => state.captain.driverCenterListData
  );
  // console.log("captainList", captainList);
  const reteriveDriverById = async () => {
    const response = await dispatch(getDriverById(driverId));
    if (response) {
      if (response?.is_driver_approved == true) {
        setShowApprove(false);
      }
      if (response?.is_driver_rejected == true) {
        setShowReject(false);
      }
      setLoading1(false);
    }
  };
  const driverData = useSelector((state) => state.driver.driverById);
  // console.log("driverData", driverData);

  const reteriveDocuments = async () => {
    const response = await dispatch(getDriverDocuments(driverId));

    if (response) {
      let count = 0;
      for (const value in response) {
        if (
          response[value].is_verified == false &&
          response[value].type !== "police_verification_card"
        ) {
          count++;
        }
      }
      if (count >= 1) {
        setDisableApprove(true);
      } else {
        setDisableApprove(false);
      }

      if (response.length == 0) {
        setDisableApprove(true);
      }

      setLoading(false);
    }
  };
  const documentsData = useSelector((state) => state.driver.driverDocuments);
  // console.log("documentsData", documentsData);

  const reteriveCityList = async () => {
    await dispatch(getCityData(""));
  };
  let cityList = useSelector((state) => state.city.cityData);
  cityList = cityList?.sort((a, b) => a.name?.localeCompare(b.name));

  const handleSearch = async (value) => {
    setSelectedCity("");
    await dispatch(getCityData(value));
  };

  const handleSelectedCity = async (value) => {
    setSelectedCity(value);
    form.setFieldsValue({
      captain: null,
    });
    await dispatch(getDriverCenterList("", pageNo, pageSize, value));
  };

  const handleScroll = async () => {
    await dispatch(getDriverCenterList("", pageNo, pageSize));
  };

  const verifyDocument = async (document) => {
    // console.log("document", document);
    setLoading(false);
    const response = await dispatch(
      approveDocument(document._id, { is_verified: true })
    );
    if (response) {
      // const response1 = await dispatch(getDriverDocuments(driverId));
      // if (response1) {
      //   setLoading(false);
      // }
      message.success("Document Verified Successfully");
      reteriveDocuments();
    }
  };

  const handleReject = async (values) => {
    console.log(values);
    if (rejectTitle == "Driver Reject") {
      const resp = await dispatch(
        rejectDriver(driverId, {
          driver_rejected_reason: values.reason,
          is_driver_approved: false,
        })
      );
      if (resp) {
        form.resetFields();
        setRejectModal(false);

        message.success("Driver has been rejected");
        setShowReject(false);
        setShowApprove(true);
      }
    }
    if (rejectTitle == "Document Reject") {
      const resp = await dispatch(
        rejectDocument(documentId, {
          rejected_reason: values.reason,
          is_verified: false,
        })
      );
      if (resp) {
        form.resetFields();
        message.success("Document Rejected Successfully");

        setRejectModal(false);
        setRejectTitle("");
        reteriveDocuments();
      }
    }
  };

  const handleDriverApprove = async () => {
    console.log(insuranceDate);
    if (insuranceDate == "") {
      setInsuranceRequiredError(true);
      message.error("Please enter insurance validity date");
    } else if (!driverData?.city_id?.name) {
      message.error("Driver's city is missing");
    } else {
      const resp = await dispatch(
        approveDriver(driverId, { is_driver_approved: true })
      );
      if (resp) {
        message.success("Driver has been approved");
        form.resetFields();
        setIsModalOpen(false);
        setShowApprove(false);
        setShowReject(true);
      }
    }
  };
  const { t } = useTranslation();

  const columns = [
    {
      title: "Sr.No ",
      key: "srno",
      render: (data, record, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "Document Name ",
      key: "docname",
      render: (data) => {
        return (
          <>
            <span>{data.type === "adhaar_card" && "Aadhar Card"}</span>
            <span>
              {data.type === "police_verification_card" &&
                "Police Verification Doc"}
            </span>
            <span>{data.type === "insurance" && "Insurance"}</span>
            <span>{data.type === "driving_license" && "Driving Licence"}</span>
            <span>{data.type === "rc_book" && "RC Book"}</span>
          </>
        );
      },
    },
    {
      title: "Document  ",
      key: "doc",

      render: (data) => {
        const type = data?.url.substring(data?.url.lastIndexOf(".") + 1);
        return (
          <span>
            {data?.url ? (
              type == "pdf" ? (
                <a href={IMAGE_URL_PREFIX + data?.url} target={"_blank"}>
                  <img
                    src="https://cdn4.iconfinder.com/data/icons/CS5/512/ACP_PDF%202_file_document.png"
                    width={50}
                  />
                </a>
              ) : (
                <Image
                  src={IMAGE_URL_PREFIX + data.url}
                  width={60}
                  height={50}
                />
              )
            ) : (
              "Document not uploaded"
            )}
          </span>
        );
      },
    },

    {
      title: "Verification Status",
      key: "status",

      render: (data) => (
        <span>
          {(data?.is_rejected == false) & (data?.is_verified == false) ? (
            <span
              style={{
                background: "yellow",
                // color: "#8B8000",
                padding: 5,
                borderRadius: 10,
              }}
            >
              {" "}
              {data?.is_reupload_required
                ? data?.is_reupload
                  ? "ReUpload"
                  : "Document Not Reuploaded"
                : "ReUpload"}
            </span>
          ) : data?.is_rejected ? (
            <span
              style={{
                background: "red",
                color: "white",
                padding: 5,
                borderRadius: 10,
              }}
            >
              Rejected
            </span>
          ) : (
            data?.is_verified && (
              <span
                style={{
                  background: "green",
                  color: "white",
                  padding: 5,
                  borderRadius: 10,
                }}
              >
                Verified
              </span>
            )
          )}
        </span>
      ),
    },
    {
      title: t("action"),
      dataIndex: "",
      key: "x",
      render: (data, target) => {
        return (
          <div>
            {(data?.is_rejected == false) & (data?.is_verified == false) ? (
              data?.is_reupload_required ? (
                data?.is_reupload ? (
                  <>
                    <Button
                      style={{ background: "#1cf81c7a" }}
                      onClick={() => verifyDocument(data)}
                    >
                      Valid
                    </Button>
                    <Button
                      style={{ marginLeft: 10, background: "#ff00008c" }}
                      onClick={() => {
                        setRejectModal(true);
                        setRejectTitle("Document Reject");
                        setDocumentId(data._id);
                      }}
                    >
                      Invalid
                    </Button>
                  </>
                ) : (
                  ""
                )
              ) : (
                <>
                  <Button
                    style={{ background: "#1cf81c7a" }}
                    onClick={() => verifyDocument(data)}
                  >
                    Valid
                  </Button>
                  <Button
                    style={{ marginLeft: 10, background: "#ff00008c" }}
                    onClick={() => {
                      setRejectModal(true);
                      setRejectTitle("Document Reject");
                      setDocumentId(data._id);
                    }}
                  >
                    Invalid
                  </Button>
                </>
              )
            ) : data.is_rejected ? (
              <Button
                style={{ background: "#1cf81c7a" }}
                onClick={() => verifyDocument(data)}
              >
                Valid
              </Button>
            ) : (
              data?.is_verified && (
                <Button
                  style={{ marginLeft: 10, background: "#ff00008c" }}
                  onClick={() => {
                    setRejectModal(true);
                    setRejectTitle("Document Reject");
                    setDocumentId(data._id);
                  }}
                >
                  Invalid
                </Button>
              )
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div
      className="common_layout content-container"
      style={
        {
          // margin: windowWidth > 991 ? "25px 100px 25px 100px" : "25px",
        }
      }
    >
      {loading || loading1 ? (
        <Spinner />
      ) : (
        <>
          <Row>
            <Col lg={20} md={12} sm={12} xs={12}>
              <h1 className="heading">Driver Reverification Request</h1>
              <BreadCrumb detailpage={driverData?.name} />
            </Col>

            {/* <Col lg={2} md={12} sm={12} xs={12}>
                            <Button
                                style={{ float: "right",
                                //  display: showApprove ? "" : "none" 
                              }}
                                className="ADD"
                                key="1"
                                size="large"
                                type="primary"
                                onClick={() => {
                                    setIsModalOpen(true),
                                        form.setFieldsValue({ name: driverData?.name });
                                }}
                            // disabled={disableApprove}
                            >
                                Approve
                            </Button>
                        </Col>
                        <Col lg={2} md={12} sm={12} xs={12}>
                            <Button
                                style={{
                                    marginLeft: "12px",
                                    // display: showReject ? "" : "none",
                                }}
                                className="ADD"
                                key="1"
                                size="large"
                                type="danger"
                                onClick={() => {
                                    setRejectModal(true), setRejectTitle("Driver Reject");
                                }}
                            >
                                Reject
                            </Button>
                        </Col> */}
          </Row>
          <Divider />
          <div style={{ justifyContent: "center", marginBottom: "20px" }}>
            <Card
              title="Driver Details"
              headStyle={{ background: "#00000047" }}
            >
              <Row gutter={20} style={{ marginBottom: "30px" }}>
                <Col xxl={4}>
                  <Card style={{ border: "none" }}>
                    <Avatar
                      size={180}
                      icon={
                        driverData && driverData.profile_image ? (
                          <img
                            src={IMAGE_URL_PREFIX + driverData.profile_image}
                            // crossOrigin="anonymous"
                          />
                        ) : (
                          <UserOutlined />
                        )
                      }
                    />
                  </Card>
                </Col>
              </Row>
              <Row gutter={20} style={{ marginBottom: "30px" }}>
                <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                  <div>
                    <label className="userlabel"> Name</label>
                    <Input
                      defaultValue={driverData && driverData.name}
                      disabled
                      style={{
                        background: "white",
                        cursor: "default",
                        fontWeight: "bold",
                      }}
                    />
                  </div>
                </Col>
                {/* <Col xxl={8} lg={8} md={8} xs={24}>
                  <div>
                    <label className="userlabel">Email </label>
                    <Input
                      defaultValue={driverData && driverData.email}
                      disabled
                      style={{
                        background: "white",
                        cursor: "default",
                        fontWeight: "bold",
                      }}
                    />
                  </div>
                </Col> */}
                <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                  <div>
                    <label className="userlabel">{t("phoneNo")}</label>
                    <Input
                      defaultValue={
                        driverData && driverData.number ? driverData.number : ""
                      }
                      disabled
                      style={{
                        background: "white",
                        cursor: "default",
                        fontWeight: "bold",
                      }}
                    />
                  </div>
                </Col>
                <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                  <div>
                    <label className="userlabel">Joined Date</label>
                    <Input
                      defaultValue={
                        driverData && driverData.created_at
                          ? new Date(driverData.created_at).toLocaleDateString()
                          : ""
                      }
                      disabled
                      style={{
                        background: "white",
                        cursor: "default",
                        fontWeight: "bold",
                      }}
                    />
                  </div>
                </Col>
                <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                  <div>
                    <label className="userlabel">Google Id</label>
                    <Input
                      defaultValue={
                        driverData && driverData?.google_id
                          ? driverData?.google_id
                          : ""
                      }
                      disabled
                      style={{
                        background: "white",
                        cursor: "default",
                        fontWeight: "bold",
                      }}
                    />
                  </div>
                </Col>
                <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                  <div>
                    <label className="userlabel">Facebook Id</label>
                    <Input
                      defaultValue={
                        driverData && driverData?.facebook_id
                          ? driverData?.facebook_id
                          : ""
                      }
                      disabled
                      style={{
                        background: "white",
                        cursor: "default",
                        fontWeight: "bold",
                      }}
                    />
                  </div>
                </Col>
                <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                  <div>
                    <label className="userlabel">City </label>
                    <Input
                      defaultValue={driverData && driverData?.city_id?.name}
                      disabled
                      style={{
                        background: "white",
                        cursor: "default",
                        fontWeight: "bold",
                      }}
                    />
                  </div>
                </Col>
                <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                  <div>
                    <label className="userlabel">Captain Name </label>
                    <Input
                      defaultValue={
                        driverData && driverData?.captainDetails?.name
                      }
                      disabled
                      style={{
                        background: "white",
                        cursor: "default",
                        fontWeight: "bold",
                      }}
                    />
                  </div>
                </Col>
                <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                  <div>
                    <label className="userlabel">Vehicle No.</label>
                    <Input
                      defaultValue={
                        driverData && driverData.vehicle_number
                          ? driverData.vehicle_number
                          : ""
                      }
                      disabled
                      style={{
                        background: "white",
                        cursor: "default",
                        fontWeight: "bold",
                      }}
                    />
                  </div>
                </Col>

                <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                  <div>
                    <label className="userlabel">Vehicle Type </label>
                    <Input
                      defaultValue={
                        driverData && driverData?.vehicle_type_id?.name
                      }
                      disabled
                      style={{
                        background: "white",
                        cursor: "default",
                        fontWeight: "bold",
                      }}
                    />
                  </div>
                </Col>
                {/* work in progress */}
                <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                  <div>
                    <label className="userlabel">Vehicle Model Name </label>
                    <Input
                      // defaultValue={
                      //   driverData && driverData?.vehicle_type_id?.name
                      // }
                      disabled
                      style={{
                        background: "white",
                        cursor: "default",
                        fontWeight: "bold",
                      }}
                    />
                  </div>
                </Col>
                <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                  <div>
                    <label className="userlabel">
                      Vehicle Insurance Valid Till{" "}
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </label>
                    <DatePicker
                      defaultValue={
                        driverData.insurance_valid_till
                          ? moment(driverData.insurance_valid_till)
                          : ""
                      }
                      disabled
                      style={{
                        background: "white",
                        cursor: "default",
                        fontWeight: "bold",

                        width: "100%",
                        // height:"100%",
                        height: 44,
                        borderRadius: 0,
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            <br />
            <Collapse defaultActiveKey={"1"} style={{ border: "none" }}>
              <Panel
                header="Updated Documents"
                key={"1"}
                style={{
                  background: "#00000047",
                  borderRadius: 10,
                  fontWeight: 500,
                  fontSize: 16,
                }}
              >
                <Table
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                  columns={columns}
                  dataSource={documentsData.filter(
                    (data) =>
                      data.is_reupload == true ||
                      data.is_reupload_required == true
                  )}
                  pagination={false}
                />
                {/* </Card> */}
              </Panel>
            </Collapse>
          </div>

          <Modal
            title={"Approve Request"}
            open={isModalOpen}
            onOk={form.submit}
            closable={true}
            // maskClosable={false}
            onCancel={handleCancel}
            // okText={"Approve"}
          >
            <Form form={form} onFinish={handleDriverApprove} layout="vertical">
              <Form.Item name={"city"} label="City">
                <Select
                  placeholder="Choose city"
                  // style={{ width: "100vw", marginRight: "10px" }}
                  showSearch
                  filterOption={false}
                  onSearch={(e) => handleSearch(e)}
                  // allowClear
                  // onClear={(e) => {
                  //   handleSearch(""), handleSelectedCity("");
                  // }}
                  onSelect={(value) => handleSelectedCity(value)}
                >
                  {cityList.length > 0 &&
                    cityList?.map((data) => {
                      return (
                        <Select.Option value={data._id} key={data._id}>
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item label="Assign Captain" name="captain">
                <Select
                  placeholder="Choose Captain"
                  listHeight={100}
                  onPopupScroll={handleScroll}
                >
                  {selectedCity != "" &&
                    captainList &&
                    captainList?.list?.length > 0 &&
                    captainList?.list.map((captain) => {
                      return (
                        <Select.Option value={captain._id} key={captain._id}>
                          {captain.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item
                label={`Driver Name`}
                // rules={[{ required: true, message: "Please Enter Name" }]}
                name="name"
              >
                <Input type="text" placeholder="Driver Name" disabled></Input>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title={rejectTitle}
            open={rejectModal}
            onOk={form.submit}
            closable={true}
            // maskClosable={false}
            onCancel={handleCancel}
          >
            <Form layout="vertical" onFinish={handleReject} form={form}>
              <Form.Item
                name={"reason"}
                label="Rejection Reason"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Rejection Message",
                  },
                  {
                    pattern: new RegExp(/^(?!\s*$).+$/i),
                    message: "Please Enter Rejection Message",
                  },
                ]}
              >
                <Input placeholder="Enter Rejection Message" />
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}
    </div>
  );
};

export default ReVerficationRequest;
