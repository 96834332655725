import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Table,
} from "antd";
import React, { useState } from "react";

import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getSmsTemplateFromDbList,
  getSmsTemplateFromList,
  removeSmsTemplate,
} from "../../redux/templates/actionCreatore";
import Spinner from "../loader/Spinner";

const SmsTemplate = () => {
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(50);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const payload = {
    pageNo,
    limit,
    type: "sms",
  };

  const { data: dbData, loading: dbLoading } = useQuery(
    "smsTemplateDbList",
    () => {
      return fetchSmsTemplateDb(payload);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchSmsTemplateDb = async (payload) => {
    setLoading(true);
    const resp = await dispatch(getSmsTemplateFromDbList(payload));
    if (resp) {
      setLoading(false);
    }
  };

  const smsTemplateDb = useSelector(
    (state) => state.template.smsTemplateDbData
  );

  const deleteFinal = async (values) => {
    setLoading(true);
    const resp = await dispatch(removeSmsTemplate(values));
    if (resp) {
      message.success("Template deleted successfully");
      setLoading(false);
      await fetchSmsTemplateDb(payload);
    }
    setLoading(false);
  };

  const handleDelete = async (id, e) => {
    // if (selectedSmsTemplate) {
    Modal.confirm({
      title: "Are you sure, you want to delete this template?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteFinal(id);
      },
    });
  };

  const columns = [
    {
      title: "Sr. No.",
      render: (text, object, index) => index + 1 + (pageNo - 1) * limit,
    },
    {
      title: "Template",
      children: [
        {
          title: "Id",
          render: (data) => (
            <span>{data?.template_id ? data?.template_id : "-"}</span>
          ),
        },
        {
          title: "Type",
          render: (data) => (
            <span>{data?.template_type ? data?.template_type : "-"}</span>
          ),
        },
        {
          title: "Name",
          render: (data) => <span>{data?.name ? data?.name : "-"}</span>,
        },
      ],
    },

    {
      title: "Sender",
      render: (data) => <span>{data?.sender ? data?.sender : "-"}</span>,
    },

    {
      title: "Description",
      render: (data) => <span>{data?.body ? data?.body : "-"}</span>,
    },
    {
      title: "Added Date",
      render: (data) => {
        const date = new Date(data?.created_at);
        return (
          <span>{data?.created_at ? date.toLocaleDateString() : "-"}</span>
        );
      },
    },
    {
      title: "Action",
      render: (data) => (
        <span>
          <Button
            type="primary" danger
            disabled
            onClick={(e) => handleDelete(data._id, e)}
          >
            <DeleteOutlined /> Remove
          </Button>
        </span>
      ),
    },
  ];

  if (dbLoading) {
    return <Spinner />;
  }

  return (
    <div className="common_layout content-container">
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h1 className="heading">SMS Templates </h1>
          {/* <BreadCrumb /> */}

          {/* Total 5 */}
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Button
            style={{ float: "right" }}
            className="ADD"
            key="1"
            size="large"
            type="primary"
            onClick={() => navigate("add")}
          >
            <PlusOutlined /> Add SMS template
          </Button>
        </Col>
      </Row>
      <Divider />

      <>
        <Card className="shadowCardBox">
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            rowKey="_id"
            columns={columns}
            dataSource={smsTemplateDb?.list}
            pagination={
              // adminList?.total_counts > pageSize
              //   ?
              {
                showSizeChanger: true,
                pageSize: limit,
                total: smsTemplateDb?.total_counts,
                // onShowSizeChange: onShowSizeChange,
                // onChange: onPageChange,
                pageSizeOptions: ["50", "100", "500"],
              }
              // : false
            }
            scroll={{ x: "45vh" }}
          />
        </Card>
      </>
    </div>
  );
};

export default SmsTemplate;
