import {
  DeleteOutlined,
  EyeOutlined,
  SwapOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  message,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getDriverRequests,
  multiDeleteDriverRequests,
} from "../../../redux/drivers/actionCreatore";
import { getCityData } from "../../../redux/Masters/City/actionCreatore";
import Loader from "../../loader/loader";

import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { WordFormate } from "../../../utility/commonFunction";
import { getItem } from "../../../utility/localStorageControl";
import BreadCrumb from "../../BreadCrumb.js/BreadCrumb";
import { ADMIN, SUPERADMIN } from "../../../config/dataService/dataService";
import moment from "moment";
const DriverRequests = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchCity, setSearchCity] = useState(null);
  const [pageSize, setPageSize] = useState(50);
  const [pageNo, setPageNo] = useState(1);
  const [searchFilter, setSearchFilter] = useState("");
  const [documentStatus, setDocumnetStatus] = useState("");
  const user = getItem("user_details");
  const [selectedCity, setSelectedCity] = useState(
    user?.role == ADMIN ? [user?.city_id?._id] : ""
  );
  const [approvalStatus, setApprovalStatus] = useState("");
  const [tableLoading, setTableLoading] = useState(true);
  const [key, setKey] = useState("created_at");
  const [sort, setSort] = useState("DSC");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");

  useEffect(() => {
    // reteriveDriverList();
    // GetCity();
    window.scrollTo(0, 0);
  }, []);

  const { data, isLoading } = useQuery(
    "driverRequestsList",
    () => {
      return reteriveDriverList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataCity, isLoading: isLoadingCity } = useQuery(
    "cityList",
    () => {
      return GetCity();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const reteriveDriverList = async () => {
    setLoading(true);

    const response = await dispatch(
      getDriverRequests(
        pageNo,
        pageSize,
        searchFilter,
        documentStatus,
        selectedCity,
        approvalStatus,
        key,
        sort,
        "false",
        selectedTime
      )
    );
    if (response) {
      setLoading(false);
    }
  };
  const driversData = useSelector((state) => state.driver.driverRequest);
  // console.log("driverData", driversData);

  const GetCity = async () => {
    setLoading(true);

    const response = await dispatch(getCityData(search));
    if (response) {
      setLoading(false);
    }
  };
  let cityList = [];
  cityList = useSelector((state) => state?.city?.cityData);
  cityList = cityList?.sort((a, b) => a.name?.localeCompare(b.name));
  const { t } = useTranslation();
  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    await dispatch(
      getDriverRequests(
        page,
        pageSizes,
        searchFilter,
        documentStatus,
        selectedCity,
        approvalStatus,
        key,
        sort,
        "false",
        selectedTime
      )
    );
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    await dispatch(
      getDriverRequests(
        current,
        pageSizes,
        searchFilter,
        documentStatus,
        selectedCity,
        approvalStatus,
        key,
        sort,
        "false",
        selectedTime
      )
    );
  };

  const handleSort = async (key) => {
    setLoading(true);
    let sorting;
    if (sort == "ASC") {
      setSort("DSC");
      sorting = "DSC";
    } else {
      setSort("ASC");
      sorting = "ASC";
    }
    setTableLoading(true);
    // sort=sort=="ASC"?setSort("DSC"):setSort("ASC")
    const resp = await dispatch(
      getDriverRequests(
        1,
        pageSize,
        searchFilter,
        documentStatus,
        selectedCity,
        approvalStatus,
        key,
        sorting,
        "false",
        selectedTime
      )
    );
    if (resp) {
      setLoading(false);
      setTableLoading(false);
    }
  };
  let selectedData = [];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );
      selectedData.push(selectedRowKeys);
      setSelectedRows(...selectedData);
    },
  };
  // console.log("selectedRows", selectedRows);

  const handleMultiDelete = async () => {
    setTableLoading(true);
    const resp = await dispatch(
      multiDeleteDriverRequests({ driver_request_ids: selectedRows })
    );
    if (resp) {
      message.success("Driver Requests deleted successfully");
      setSelectedRows([]);
      setTableLoading(false);
      reteriveDriverList();
    }
  };
  useEffect(() => {
    if (!isLoading) {
      setTableLoading(false);
    }
  }, [isLoading]);
  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },
    {
      title: "Driver Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Current Location City",
      key: "city",
      render: (data) => {
        return <span>{data?.city_id?.name ? data?.city_id?.name : "-"}</span>;
      },
    },
    {
      title: "Registered For City",
      key: "registeredcity",
      render: (data) => {
        return (
          <span>
            {data?.driverRegisteredCity?.name
              ? data?.driverRegisteredCity?.name
              : "-"}
          </span>
        );
      },
    },
    {
      title: t("phoneNo"),
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Document Verification Status",
      dataIndex: "document_verification_status",
      key: "status",

      render: (status) => {
        let color = "";
        if (status?.toLowerCase() === "in_progress") {
          color = "blue";
        }
        if (status?.toLowerCase() === "verified") {
          color = "success";
        }
        if (status?.toLowerCase() === "not_applied") {
          color = "volcano";
        }
        if (status?.toLowerCase() === "reverfication_in_progress") {
          color = "green";
        }
        if (status?.toLowerCase() === "rejected") {
          color = "red";
        }
        return (
          <Tag color={color} key={status}>
            {status && WordFormate(status)}
          </Tag>
        );
      },
    },
    {
      title: "Approval Status",
      key: "approvalstatus",
      render: (data) => {
        return (
          <Tag
            color={data && data?.is_driver_rejected == true ? "red" : "#DBD050"}
            key={data}
          >
            {data?.is_driver_rejected == true ? "Rejected" : "Pending"}
          </Tag>
        );
      },
    },
    {
      title: (filters, sortOrder) => (
        <div>
          Requested Time
          <Button
            style={{ background: "none", border: "none" }}
            onClick={() => handleSort("created_at")}
          >
            <SwapOutlined rotate={90} style={{ color: "black" }} />
          </Button>
        </div>
      ),

      key: "timestamp",

      render: (data) => {
        const date = new Date(data?.created_at);
        return (
          <span>
            {data?.created_at ? date.toLocaleString() : "-"}
            {/* {data?.updated_at ? date.toLocaleDateString() : "-"} <br />
            {data?.updated_at ? date.toLocaleTimeString() : "-"}{" "} */}
          </span>
        );
      },
    },
    {
      title: (
        <div>
          Last Login{" "}
          <Button
            style={{ background: "none", border: "none" }}
            onClick={() => handleSort("login_time")}
          >
            <SwapOutlined rotate={90} style={{ color: "black" }} />
          </Button>
        </div>
      ),
      key: "lastLogin",
      render: (data, index1, index) => {
        // console.log(data.login_time,index1);
        const date = new Date();
        if (data.login_time) {
          const date2 = new Date(data.login_time);
          const difference = date.getTime() - date2.getTime();
          const differenceInMinutes = difference / (1000 * 60);
          const differenceInHours = difference / (1000 * 60 * 60);
          const differenceInDays = difference / (1000 * 60 * 60 * 24);

          if (differenceInMinutes < 60) {
            return (
              <span>
                {" "}
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInMinutes)} minutes ago
                </Tooltip>
              </span>
            );
          } else if (differenceInHours < 24) {
            return (
              <span>
                {" "}
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInHours)} hours ago
                </Tooltip>
              </span>
            );
          } else {
            return (
              <span>
                {" "}
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInDays)} days ago
                </Tooltip>
              </span>
            );
          }
        } else {
          return <span>-</span>;
        }
      },
    },
    // {
    //   title: t("action"),
    //   dataIndex: "",
    //   key: "x",
    //   render: (record, target) => {
    //     return (
    //       <>
    //         <div className="action" onClick={() => { }}>
    //           <Button
    //             type="primary"
    //             onClick={() =>
    //               navigate(`driverRequests/request/${record._id}`)
    //             }
    //           >
    //             <EyeOutlined style={{ marginRight: 5 }} />

    //             <strong>View</strong>
    //           </Button>
    //         </div>
    //       </>
    //     );
    //   },
    // },
  ];

  const handleSearchFilter = async (value) => {
    setSearchFilter(value);
    await dispatch(
      getDriverRequests(
        pageNo,
        pageSize,
        value,
        documentStatus,
        selectedCity,
        approvalStatus,
        "updated_at",
        sort,
        "false",
        selectedTime
      )
    );
  };

  const handleSearch = async (value) => {
    setSearchCity(value);
    setSelectedCity("");
    await dispatch(getCityData(value));
    // await dispatch(
    //   getDriverRequests(
    //     pageNo,
    //     pageSize,
    //     searchFilter,
    //     documentStatus,
    //     "",
    //     approvalStatus,
    //     "updated_at",
    //     sort,
    //     "false",
    //     selectedTime
    //   )
    // );
  };

  const handleSelectedCity = async (value) => {
    setSelectedCity(value);
    if (searchCity) {
      setSearchCity("");
      await dispatch(getCityData(""));
    }
    await dispatch(
      getDriverRequests(
        pageNo,
        pageSize,
        searchFilter,
        documentStatus,
        value,
        approvalStatus,
        "updated_at",
        sort,
        "false",
        selectedTime
      )
    );
  };

  const chooseDocument = async (status) => {
    setDocumnetStatus(status);
    await dispatch(
      getDriverRequests(
        pageNo,
        pageSize,
        searchFilter,
        status,
        selectedCity,
        approvalStatus,
        "updated_at",
        sort,
        "false",
        selectedTime
      )
    );
  };
  const chooseApprovalStatus = async (status) => {
    setApprovalStatus(status);
    await dispatch(
      getDriverRequests(
        pageNo,
        pageSize,
        searchFilter,
        documentStatus,
        selectedCity,
        status,
        "updated_at",
        sort,
        "false",
        selectedTime
      )
    );
  };
  const handleSelectTime = async (value) => {
    let val;
    if (value) val = value.format("YYYY-MM-DD");
    else {
      val = "";
    }
    setSelectedTime(val);
    setPageNo(1);

    await dispatch(
      getDriverRequests(
        pageNo,
        pageSize,
        searchFilter,
        documentStatus,
        selectedCity,
        approvalStatus,
        "updated_at",
        sort,
        "false",
        val
      )
    );
  };

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">
              {" "}
              {t("driver")} {t("request")}
            </h1>
            <div style={{ display: "flex" }}>
              <BreadCrumb />
            </div>
          </Col>
          {/* <Col lg={12} md={12} sm={12} xs={12}>
            {user?.role == SUPERADMIN && (
              <Button
                style={{ float: "right" }}
                type="primary"
                danger
              
                // disabled={selectedRows.length == 0 ? true : false}

                disabled
                onClick={handleMultiDelete}
              >
                {" "}
                <DeleteOutlined /> Delete
              </Button>
            )}
          </Col> */}
        </Row>
        <Divider />

        {isLoading & isLoadingCity ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col xxl={4} lg={4} md={8} sm={24} xs={24} className="total">
                  Total {driversData?.total_counts}
                </Col>

                <Col xxl={4} lg={8} md={8} sm={12} xs={24}>
                  <Input
                    placeholder="Search By Name Or Phone"
                    // enterButton="Search"
                    // size="large"
                    style={{ width: "100%", float: "right" }}
                    onChange={(e) => handleSearchFilter(e.target.value)}
                    prefix={<SearchOutlined />}
                  />
                </Col>
                <Col xxl={4} lg={6} md={8} sm={12} xs={24}>
                  {user.role != ADMIN && (
                    <Select
                      placeholder="Choose city"
                      style={{
                        width: "100%",
                        maxHeight: "45px",
                        overflowY: "auto",
                        marginTop: "6px",
                      }}
                      showSearch
                      filterOption={false}
                      mode={"multiple"}
                      onSearch={(e) => handleSearch(e)}
                      allowClear
                      onChange={handleSelectedCity}
                    >
                      {cityList.length > 0
                        ? cityList?.map((data) => {
                            return (
                              <Select.Option
                                value={data._id}
                                key={data._id}
                                className="selectOptions"
                              >
                                {data.name}
                              </Select.Option>
                            );
                          })
                        : ""}
                    </Select>
                  )}
                </Col>
                <Col xxl={4} lg={6} md={8} sm={12} xs={24}>
                  <Select
                    placeholder="Choose Document Status"
                    style={{
                      width: "100%",
                      marginLeft: "10px",
                      float: "right",
                    }}
                    filterOption={false}
                    // onSearch={(e) => handleSearch(e)}
                    allowClear
                    onClear={(value) => chooseDocument("")}
                    onSelect={(value) => chooseDocument(value)}
                  >
                    <Select.Option
                      value={"verified"}
                      key={"verified"}
                      className="selectOptions"
                    >
                      <Tag color="success">Verified</Tag>
                    </Select.Option>
                    <Select.Option
                      value={"in_progress"}
                      key={"in_progress"}
                      className="selectOptions"
                    >
                      <Tag color={"blue"}>In Progress</Tag>
                    </Select.Option>
                    <Select.Option
                      value={"not_applied"}
                      key={"not_applied"}
                      className="selectOptions"
                    >
                      <Tag color="volcano">Not Applied</Tag>
                    </Select.Option>
                    {/* <Select.Option
                      value={"reverfication_in_progress"}
                      key={"reverfication"}
                    >
                      <Tag color="green">Reverification in Progress </Tag>
                    </Select.Option> */}
                    <Select.Option
                      value={"rejected"}
                      key={"rejected"}
                      className="selectOptions"
                    >
                      <Tag color="red">Rejected </Tag>
                    </Select.Option>
                    {/* );
                        })} */}
                  </Select>
                </Col>

                <Col xxl={3} lg={6} md={8} sm={12} xs={24}>
                  <Select
                    placeholder="Choose Approval Status"
                    style={{
                      width: "100%",
                      marginLeft: "10px",
                      float: "right",
                    }}
                    filterOption={false}
                    // onSearch={(e) => handleSearch(e)}
                    allowClear
                    onClear={(value) => chooseApprovalStatus("")}
                    onSelect={(value) => chooseApprovalStatus(value)}
                  >
                    {/* {cityList &&
                        cityList.map((data) => {
                          return ( */}
                    <Select.Option
                      value={false}
                      key={"pending"}
                      className="selectOptions"
                    >
                      <Tag color="#DBD050">Pending</Tag>
                    </Select.Option>
                    <Select.Option
                      value={true}
                      key={"rejected"}
                      className="selectOptions"
                    >
                      <Tag color={"red"}>Rejected</Tag>
                    </Select.Option>

                    {/* );
                        })} */}
                  </Select>
                </Col>

                <Col xxl={5} lg={6} md={8} sm={12} xs={24}>
                  {/* <div
                    className="product-list-action d-flex justify-content-between align-items-center"
                    style={{ float: "left", }}
                  > */}
                  <DatePicker
                    style={{
                      borderRadius: 0,

                      width: "100%",
                    }}
                    showToday={false}
                    format={"DD/MM/YYYY"}
                    onChange={(e) => handleSelectTime(e)}
                    placeholder="Last Login Date"
                    disabledDate={(current) => current.isAfter(moment())}
                  />
                  {/* </div> */}
                </Col>
              </Row>
            </Card>
            <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  record?.isRead ? "read" : "unread"
                }
                // rowClassName={(record, index) =>
                //   console.log("record",record)
                // }
                // rowSelection={
                //   rowSelection

                // }
                dataSource={driversData?.list}
                columns={columns}
                searchable
                scroll={{ x: "45vh" }}
                loading={tableLoading}
                rowKey={"_id"}
                onRow={(record) => {
                  return {
                    onDoubleClick: () => {
                      navigate(`${record._id}`);
                    },
                  };
                }}
                pagination={{
                  showSizeChanger: true,
                  pageSize: pageSize,
                  total: driversData?.total_counts,
                  onShowSizeChange: onShowSizeChange,
                  onChange: onPageChange,
                  pageSizeOptions: ["50", "100", "500"],
                }}
              />
            </Card>
          </>
        )}
      </div>
      {/* {loading && <Loader />} */}
    </>
  );
};

export default DriverRequests;
