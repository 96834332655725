import actions from "./actions";

const {
  GET_DRIVER_LIST,
  GET_DRIVER_BY_ID,
  GET_DRIVER_REQUESTS,
  GET_DRIVER_DOCUMENTS,
  GET_CAPTAIN_DRIVER_LIST,
  GET_ID_CARD,
  GET_DRIVER_WALLET,
  GET_TRANSACTIONLIST,
  GET_DRIVER_WALLET_CHART,
  GET_RIDE_REQUESTS,
  GET_RATING_WISE_RIDES_ACCEPT,
  GET_DRIVER_REVERIFICATION_REQUESTS,
  GET_DRIVER_BANK_DETAILS_VERIFY_REQUESTS,
} = actions;

const initState = {
  driverListData: {},
  driverById: {},
  driverRequest: {},
  driverDocuments: {},
  captainDriverList: {},
  driveridCard: {},
  driverWallet: {},
  transactionList: {},
  driverWalletChart: {},
  rideRequests: {},
  ratingWiseRides: {},
  reverificationRequest: {},
  bankDetailsRequests: {},
};

const driverReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_DRIVER_LIST:
      return {
        ...state,
        driverListData: data,
      };
    case GET_DRIVER_BY_ID:
      return {
        ...state,
        driverById: data,
      };
    case GET_DRIVER_REQUESTS:
      return {
        ...state,
        driverRequest: data,
      };
    case GET_DRIVER_DOCUMENTS:
      return {
        ...state,
        driverDocuments: data,
      };
    case GET_CAPTAIN_DRIVER_LIST:
      return {
        ...state,
        captainDriverList: data,
      };
    case GET_ID_CARD:
      return {
        ...state,
        driveridCard: data,
      };
    case GET_DRIVER_WALLET:
      return {
        ...state,
        driverWallet: data,
      };
    case GET_TRANSACTIONLIST:
      return {
        ...state,
        transactionList: data,
      };
    case GET_DRIVER_WALLET_CHART:
      return {
        ...state,
        driverWalletChart: data,
      };
    case GET_RIDE_REQUESTS:
      return {
        ...state,
        rideRequests: data,
      };
    case GET_RATING_WISE_RIDES_ACCEPT:
      return {
        ...state,
        ratingWiseRides: data,
      };
    case GET_DRIVER_REVERIFICATION_REQUESTS:
      return {
        ...state,
        reverificationRequest: data,
      };
    case GET_DRIVER_BANK_DETAILS_VERIFY_REQUESTS:
      return {
        ...state,
        bankDetailsRequests: data,
      };
    default:
      return state;
  }
};
export default driverReducer;
