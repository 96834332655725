import { Modal, Spin } from "antd";
import React from "react";
const Processing = ({title}) => {
  return (
    <Modal footer={null} centered open={true} closable={false}>
      <div style={{ display: "flex", justifyContent: "center", alignItems:"center" }}>
        <Spin style={{marginRight:"10px"}} />
   
        <span>{title ?  title : "Processing your request..."}</span>
      </div>
    </Modal>
  );
};
export default Processing;
