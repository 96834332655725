import { SwapOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Row,
  Select,
  Table,
  Tag,
  TreeSelect,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMapAPiTypes,
  fetchMapStatistics,
} from "../../redux/MapStatistics/actionCreatore";
import { WordFormate } from "../../utility/commonFunction";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
const { TreeNode } = TreeSelect;

const { RangePicker } = DatePicker;
function MapStatistics(props) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [originFrom, setOriginFrom] = useState("");
  const [googleApiType, setGoogleApiType] = useState("");
  const [googleSubApiType, setGoogleSubApiType] = useState("");
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState("DSC");
  const [tableLoading, setTableLoading] = useState(false);
  const payload = {
    page,
    limit,
    fromDate,
    toDate,
    originFrom,
    googleApiType,
    googleSubApiType,
    sortKey,
    sortValue,
  };
  const rangePresets = [
    { label: "Today", value: [dayjs(), dayjs()] },
    { label: "Last 7 Days", value: [dayjs().add(-7, "days"), dayjs()] },
    { label: "Last Month", value: [dayjs().add(-1, "month"), dayjs()] },
    { label: "Last 6 Month", value: [dayjs().add(-6, "month"), dayjs()] },
    { label: "Last Year", value: [dayjs().add(-1, "year"), dayjs()] },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { data, isLoading: isLoading } = useQuery(
    "mapStatistics",
    () => {
      return retriveMapStatisticsList(payload);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const retriveMapStatisticsList = async (payload) => {
    setTableLoading(true);
    const resp = await dispatch(fetchMapStatistics(payload));
    setTableLoading(false);
  };

  const statistics = useSelector(
    (state) => state.mapStatistics.mapStatisticsData
  );

  const { data: apiTypesData, isLoading: apiTypeLoading } = useQuery(
    "mapApiTypes",
    () => {
      return retriveMapApiTypesList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const retriveMapApiTypesList = async () => {
    await dispatch(fetchMapAPiTypes());
  };

  const apiTypes = useSelector((state) => state.mapStatistics.mapApiTypes);

  const onShowSizeChange = (current, pageSize) => {
    payload.limit = pageSize;
    setLimit(pageSize);

    retriveMapStatisticsList(payload);
  };

  const onPageChange = (page, pageSizes) => {
    payload.page = page;
    setPage(page);
    retriveMapStatisticsList(payload);
  };
  const onRangeChange = async (dates, dateStrings) => {
    if (dates) {
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setFromDate(dates[0]);
      setToDate(dates[1]);
      setPage(1);
      payload.page = 1;
      payload.fromDate = dates[0];
      payload.toDate = dates[1];

      await retriveMapStatisticsList(payload);
    } else {
      setFromDate("");
      setToDate("");
      payload.fromDate = "";
      payload.toDate = "";
      await retriveMapStatisticsList(payload);
    }
  };

  const handleSelectOrigin = async (value) => {
    setOriginFrom(value ? value : "");
    setPage(1);
    payload.page = 1;
    payload.originFrom = value ? value : "";
    await retriveMapStatisticsList(payload);
  };

  const handleSelectServiceType = async (value) => {
    const values = value ? value.split("SUBTYPE") : [];
    setGoogleApiType(values?.[0] ? values?.[0] : "");
    setGoogleSubApiType(values?.[1] ? values[1] : "");
    payload.googleApiType = values?.[0] ? values?.[0] : "";
    payload.googleSubApiType = values?.[1] ? values[1] : "";
    setPage(1);
    payload.page = 1;
    await retriveMapStatisticsList(payload);
  };

  const handleSort = async () => {
    let sorting;

    if (sortValue === "ASC") {
      setSortValue("DSC");
      sorting = "DSC";
    } else {
      sorting = "ASC";
      setSortValue("ASC");
    }
    payload.sortValue = sorting;
    payload.page = 1;
    await retriveMapStatisticsList(payload);
  };
  const columns = [
    {
      title: "Sr. No.",
      render: (data, ind, index) => (
        <span>{index + 1 + (page - 1) * limit}</span>
      ),
    },
    {
      title: "Origin",
      key: "origin",
      render: (data) => (
        <span>{data?.originFrom ? WordFormate(data?.originFrom) : "-"}</span>
      ),
    },
    {
      title: "User",
      key: "user",
      render: (data) => (
        <span>{data?.user_id ? data?.user_id?.name : "-"}</span>
      ),
    },
    {
      title: "Module Name",
      key: "moduleName",
      render: (data) => (
        <span>
          {data?.moduleName
            ? data?.moduleName == "nearestDriverETA"
              ? "Nearest Driver ETA"
              : WordFormate(data?.moduleName)
            : "-"}
        </span>
      ),
    },

    {
      title: "Google Map Api",
      children: [
        {
          title: "Type",
          key: "apitype",
          render: (data) => (
            <span>
              {data?.googleApiType ? WordFormate(data?.googleApiType) : "-"}
            </span>
          ),
        },
        {
          title: "Sub Type",
          key: "subtype",
          render: (data) => (
            <span>
              {data?.googleSubApiType
                ? WordFormate(data?.googleSubApiType)
                : "-"}
            </span>
          ),
        },
      ],
    },
    {
      title: (
        <div>
          Time Stamp
          <Button
            style={{ background: "none", border: "none" }}
            onClick={() => handleSort()}
          >
            <SwapOutlined rotate={90} style={{ color: "black" }} />
          </Button>
        </div>
      ),
      key: "timeStamp",
      render: (data) => {
        const date = new Date(data?.created_at);
        return (
          <span>
            {date
              ? date.toLocaleDateString() + " " + date.toLocaleTimeString()
              : "-"}
          </span>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      render: (data) => (
        <Tag color={data?.apiStatus == "success" ? "green" : "red"}>
          {data?.apiStatus && WordFormate(data?.apiStatus)}
        </Tag>
      ),
    },
  ];

  return (
    <div className="common_layout content-container">
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h1 className="heading">Map Statistics</h1>
          <BreadCrumb />
        </Col>
      </Row>
      <Divider />
      <Card style={{ marginBottom: 10 }} className="shadowCardBox">
        <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
          <Col xxl={6} lg={4} md={8} sm={24} className="total">
            Total {statistics?.total_counts}
          </Col>

          <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
            <Select
              placeholder={"Choose Origin"}
              style={{ width: "100%" }}
              onSelect={handleSelectOrigin}
              allowClear
              onClear={handleSelectOrigin}
            >
              <Select.Option
                value="super_admin"
                key="1"
                className="selectOptions"
              >
                Super Admin
              </Select.Option>
              <Select.Option value="admin" key="2" className="selectOptions">
                Admin
              </Select.Option>
              <Select.Option value="captain" key="3" className="selectOptions">
                Captain
              </Select.Option>
              <Select.Option
                value="callCenter"
                key="4"
                className="selectOptions"
              >
                Call Center
              </Select.Option>
              <Select.Option value="driver" key="5" className="selectOptions">
                Driver
              </Select.Option>
              <Select.Option value="rider" key="6" className="selectOptions">
                Rider
              </Select.Option>
              <Select.Option
                value="backend_api"
                key="7"
                className="selectOptions"
              >
                Backend Api
              </Select.Option>
              <Select.Option
                value="backend_socket"
                key="8"
                className="selectOptions"
              >
                Backend Socket
              </Select.Option>
            </Select>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
            <TreeSelect
              placeholder={"Choose Api Types"}
              style={{ width: "100%" }}
              onSelect={handleSelectServiceType}
              allowClear
              onClear={handleSelectServiceType}
            >
              {Array.isArray(apiTypes) &&
                apiTypes.map((type) => (
                  <TreeNode
                    value={type?.name}
                    title={WordFormate(type?.name)}
                    key={type?.name}
                    className="selectOptions"
                  >
                    {type.subApiTypes.map((subType) => (
                      <TreeNode
                        key={subType.name}
                        value={type.name + "SUBTYPE" + subType.name}
                        title={WordFormate(subType.name)}
                        className="selectOptions"
                      ></TreeNode>
                    ))}
                  </TreeNode>
                ))}
            </TreeSelect>
          </Col>
          <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
            <DatePicker.RangePicker
              presets={rangePresets}
              onChange={onRangeChange}
              // defaultValue={null}
              defaultValue={[fromDate, toDate]}
              // value={[fromDate,toDate]}
              style={{
                borderRadius: "0",
                // marginLeft: "10px",
                // width: "10vw",
                width: "100%",
              }}
              allowClear={true}
              format={"DD/MM/YYYY"}
              disabledDate={(current) => current.isAfter(moment())}

              // style={{display:"flex"}}
            />
          </Col>
        </Row>
      </Card>
      <Card className="shadowCardBox">
        <Table
          dataSource={statistics?.list}
          loading={tableLoading}
          pagination={{
            showSizeChanger: true,
            pageSize: limit,
            total: statistics?.total_counts,
            onShowSizeChange: onShowSizeChange,
            onChange: onPageChange,
            pageSizeOptions: ["50", "100", "500"],
            current: page,
          }}
          columns={columns}
          searchable
          scroll={{ x: "45vh" }}
        />
      </Card>
    </div>
  );
}

export default MapStatistics;
