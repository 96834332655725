import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Image,
  Modal,
  Rate,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import FeatherIcon from "feather-icons-react";

import { MenuUnfoldOutlined, SwapOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom/";
import {
  getDriverCenterById,
  getDriverCenterList,
  getDriverCenterListWithoutPagination,
} from "../../../redux/deriverCenter/actionCreatore";
import {
  captainDriverMigration,
  getCaptainDriversListData,
} from "../../../redux/drivers/actionCreatore";
import { getRandomColor, WordFormate } from "../../../utility/commonFunction";
import { getItem } from "../../../utility/localStorageControl";
import Loader from "../../loader/loader";
import BreadCrumb from "../../BreadCrumb.js/BreadCrumb";
import { H1, H2 } from "../../../components/heading/style";
import Processing from "../../ProcessModal/Processing";
import { getCommsionForCaptain } from "../../../redux/Commission/actionCreator";

const DriversUnderCaptain = () => {
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(50);
  const { captainId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = getItem("user_details");
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [processingModal, setProcessingModal] = useState(false);
  const [sortKey, setSortKey] = useState("login_time");
  const [sort, setSort] = useState("DSC");
  const [charges, setCharges] = useState({
    captain: 0,
  });
  const payload = {
    pageNo,
    pageSize: limit,
    captainId,
    searchFilter: "",
    dutyStatus: "",
    key: sortKey,
    sort,
  };

  useEffect(() => {
    reteriveCaptainById();
    reteriveCaptainDriverList(payload);
    reteriveCaptainCommission(captainId);
    window.scrollTo(0, 0);
  }, []);

  const reteriveCaptainById = async () => {
    await dispatch(getDriverCenterById(captainId));
  };

  const captain = useSelector((state) => state.captain.driverCenterData);
  // console.log("captain", captain);

  const reteriveCaptainDriverList = async (payload) => {
    const resp = await dispatch(getCaptainDriversListData(payload));
    if (resp) {
      setLoading(false);
    }
  };
  const drivers = useSelector((state) => state.driver.captainDriverList);
  let captainPayload = {
    seach: "",
    cityId: captain?.city_id?._id,
    captainId: captainId,
  };

  const fetchCaptainList = async () => {
    const resp = await dispatch(
      getDriverCenterListWithoutPagination(captainPayload)
    );
  };
  const captainList = useSelector(
    (state) => state.captain.driverCenterListDataWithoutPagination
  );

  const reteriveCaptainCommission = async (id) => {
    await dispatch(getCommsionForCaptain(id));
  };

  const commission = useSelector((state) => state.commission.commission);

  useEffect(() => {
    if (Array.isArray(commission) && commission) {
      let obj = {
        captain: 0,
      };
      commission.map((com) => {
        if (com._id == "captainCharges") {
          obj.captain = com.total_amount;
        }
      });
      setCharges(obj);
    }
  }, [commission]);
  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    payload.pageNo = page;
    reteriveCaptainDriverList(payload);
    // await dispatch(getCaptainDriversListData(page, pageSizes, captainId));
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setLimit(pageSizes);
    payload.pageSize = pageSizes;
    reteriveCaptainDriverList(payload);
    // await dispatch(getCaptainDriversListData(current, pageSizes, captainId));
  };
  let selectedData = [];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // setExportData(selectedRows);
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );
      selectedData.push(selectedRowKeys);

      setSelectedRows(...selectedData);
    },
    // getCheckboxProps: (record) => ({
    //   disabled: record.name === 'Disabled User',
    //   // Column configuration not to be checked
    //   name: record.name,
    // }),
  };

  const handleSubmit = async (data) => {
    data.driver_ids = selectedRows;
    setProcessingModal(true);
    const resp = await dispatch(captainDriverMigration(data));
    if (resp) {
      setShowModal(false);
      setProcessingModal(false);
      await reteriveCaptainDriverList(payload);
    }
  };

  const handleSort = async (key) => {
    let sorting;
    setSortKey(key);
    if (sort === "ASC") {
      setSort("DSC");
      payload.sort = "DSC";
      sorting = "DSC";
    } else {
      sorting = "ASC";
      setSort("ASC");
      payload.sort = "ASC";
    }
    await reteriveCaptainDriverList(payload);
  };
  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * limit,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "Image",
      render: (t, r) => {
        if (!t?.profile_image) {
          return (
            <div>
              <Avatar
                icon={<FeatherIcon icon="user" size="30" />}
                style={{
                  backgroundColor: getRandomColor(),
                  height: "40px",
                  width: "40px",
                }}
              />
            </div>
          );
        } else {
          return (
            <>
              <Image
                height={40}
                width={40}
                style={{ borderRadius: "50%" }}
                // crossOrigin="anonymous"
                alt=""
                src={` Image ${t.image}`}
              />
            </>
          );
        }
      },
    },
    {
      title: "Driver Name",
      dataIndex: ["name"],
      key: "driver_name",
    },
    {
      title: "City",
      dataIndex: ["city_id", "name"],
      key: "driver_city",
    },
    {
      title: "Vehicle Type",
      render: (data) => {
        return <span>{data?.vehicle_type_id?.name}</span>;
      },
    },
    {
      title: "Average Rating",

      key: "date",
      render: (data) => {
        return (
          <>
            <Rate value={data?.average_rating} disabled allowHalf />
            <span style={{ color: "#ccc" }}>{`(${data?.average_rating?.toFixed(
              2
            )} )`}</span>
          </>
        );
      },
    },
    {
      title: "Duty Status",
      dataIndex: "duty_status",
      key: "duty_status",
      render: (status) => {
        return (
          <Tag
            color={
              status
                ? status?.toLowerCase() === "auto_clicker"
                  ? "blue"
                  : status?.toLowerCase() === "off_duty"
                  ? "volcano"
                  : "green"
                : ""
            }
            key={status}
          >
            {status && WordFormate(status)}
          </Tag>
        );
      },
    },
    {
      title: (
        <div>
          Last Login
          <Button
            style={{ background: "none", border: "none" }}
            onClick={() => handleSort("login_time")}
          >
            <SwapOutlined rotate={90} style={{ color: "black" }} />
          </Button>
        </div>
      ),
      key: "lastLogin",
      render: (data, index1, index) => {
        // console.log(data.login_time,index1);
        const date = new Date();
        if (data.login_time) {
          const date2 = new Date(data.login_time);
          const difference = date.getTime() - date2.getTime();
          const differenceInMinutes = difference / (1000 * 60);
          const differenceInHours = difference / (1000 * 60 * 60);
          const differenceInDays = difference / (1000 * 60 * 60 * 24);

          if (differenceInMinutes < 60) {
            return (
              <span>
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInMinutes)} minutes ago
                </Tooltip>
              </span>
            );
          } else if (differenceInHours < 24) {
            return (
              <span>
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInHours)} hours ago
                </Tooltip>
              </span>
            );
          } else {
            return (
              <span>
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInDays)} days ago
                </Tooltip>
              </span>
            );
          }
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      title: t("action"),
      align: "center",
      render: (record, target) => {
        return (
          // <>
          //   <Popover
          //     className="Popover"
          //     placement="bottom"
          //     trigger="click"
          //     bodyStyle={{ padding: "0px" }}
          //     content={
          <div>
            <Button
              onClick={() => {
                navigate(
                  `/${user.role}/manageDriver/driver/driverDetails/${record._id}`
                );
              }}
              type="primary"
            >
              <MenuUnfoldOutlined style={{ marginRight: 5 }} />
              <strong>Detail</strong>
            </Button>
            {/* <Button
              onClick={() => {
                navigate("manageDriver/driverWallet");
              }}
              style={{ background: "yellow", margin: "0 4px 0px 4px" }}
            >
              <WalletOutlined style={{ marginRight: 5 }} />
              <strong>Wallet</strong>
            </Button> */}
            {/* {checkAction("driver_drivers", "delete") && (
              <Button
                onClick={() => {
                  deleteDriver(target);
                }}
                type="danger"
              >
                <DeleteOutlined style={{ marginRight: 5 }} />
                <strong>Delete</strong>
              </Button>
            )} */}
          </div>
          //     }
          //   >
          //     <MoreOutlined style={{ fontSize: "20px" }} />
          //   </Popover>
          // </>
        );
      },
    },
  ];
  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">{captain?.name}</h1>
            <BreadCrumb detailpage={captain?.name} />
          </Col>
          {/* <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              style={{ float: "right" }}
              className="ADD"
              key="1"
              size="large"
              type="primary"
              // onClick={showModal}
            >
              <PlusOutlined /> Add Driver
            </Button>
          </Col> */}
          <Col lg={12} md={12} sm={12} xs={12}>
            <Card style={{ marginTop: "20px" }}>
              <div
                style={{
                  // fontWeight: "bold",
                  fontSize: "22px",
                  // color: "green",
                  textAlign: "center",
                }}
              >
                <h4>
                  {" "}
                  Total Commission: <span style={{ color: "green" }}>
                    ₹
                  </span>{" "}
                  {charges.captain}
                </h4>
              </div>
            </Card>
          </Col>
        </Row>
        <Divider />
        {loading && <Loader />}
        {!loading && (
          <>
            <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col lg={12} md={12} sm={24} xs={24} className="total">
                  Total {drivers.total_counts}
                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <div
                    className="product-list-action d-flex justify-content-between align-items-center"
                    style={{ float: "right" }}
                  >
                    <Button
                      type="primary"
                      disabled={
                        Array.isArray(selectedRows) && selectedRows.length == 0
                          ? true
                          : false
                      }
                      onClick={() => {
                        setShowModal(true);
                        fetchCaptainList();
                      }}
                    >
                      Migrate
                    </Button>
                    {/* <div className="product-list-action__tab">
                  <span className="toolbox-text"> Status:</span>
                  <Radio.Group onChange={onSorting} defaultValue="rate">
                    <Radio.Button value="All Booking">All Booking</Radio.Button>
                    <Radio.Button value="Pending Booking">
                      Pending Booking
                    </Radio.Button>
                    <Radio.Button value="On Going Bookings">
                      On Going Bookings
                    </Radio.Button>
                  </Radio.Group>
                </div> */}
                  </div>
                </Col>
              </Row>
            </Card>
            <Card title="Drivers Under Captain">
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                rowSelection={{
                  type: Checkbox,
                  ...rowSelection,
                }}
                rowKey={"_id"}
                dataSource={drivers?.list}
                columns={columns}
                searchable
                scroll={{ x: "45vh" }}
                pagination={{
                  showSizeChanger: true,
                  pageSize: limit,
                  total: drivers?.total_counts,
                  onShowSizeChange: onShowSizeChange,
                  onChange: onPageChange,
                  pageSizeOptions: ["50", "100", "500"],
                }}
              />
            </Card>
            {/* <br/>
            <Card style={{bottom:"0px", position:"fixed", width:"100%"}} >
              <span>Allocate To     </span>
              <Select style={{width:"100px"}} ><Select.Option></Select.Option></Select>
            </Card> */}
          </>
        )}
      </div>
      <Modal
        open={showModal}
        title={"Migrate Drivers To"}
        onCancel={() => setShowModal(false)}
        onOk={form.submit}
      >
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Form.Item
            name={"captain_id"}
            label="Captain"
            rules={[
              {
                required: true,
                message: "Please Choose Captain",
              },
            ]}
          >
            <Select placeholder="Choose Captain">
              {Array.isArray(captainList) &&
                captainList?.map((captain) => (
                  <Select.Option value={captain._id} key={captain._id}>
                    {captain?.name}
                  </Select.Option>
                ))}
              {/* <Select.Option key={"1"} value={"sds"}>
                djkfhkdj
              </Select.Option> */}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      {processingModal && <Processing />}
    </>
  );
};

export default DriversUnderCaptain;
