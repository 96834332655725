import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const {
  fetchDriverList,
  fetchRiderList,
  fetchNotificationList,
  fetchNoticeNotificationList,
} = actions;

export const getDriverList = (search, payload) => {
  return async (dispatch) => {
    let queryParametere = "?search=" + search;

    const resp = await DataService.post(
      API.smsNotification.getDriver + queryParametere,
      { driverIds: payload }
    );

    if (!resp.data.error) {
      dispatch(fetchDriverList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getRidersList = (search, payload) => {
  return async (dispatch) => {
    let queryParametere = "?search=" + search;

    const resp = await DataService.post(
      API.smsNotification.getRider + queryParametere,
      { riderIds: payload }
    );

    if (!resp.data.error) {
      dispatch(fetchRiderList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const sendSmsNotification = (type, usersIds, message, templateId) => {
  return async (dispatch) => {
    let queryParametere = "?key=" + type;

    const payload = {
      ids: usersIds,
      message: message,
    };
    if (type === "sms") {
      payload.template_id = templateId;
    }

    const resp = await DataService.post(
      API.smsNotification.sendSmsNotification + queryParametere,
      payload
    );

    if (!resp.data.error) {
      return true;
    } else {
      // message.error(resp.data.message);
      return false;
    }
  };
};

export const notificationsList = (params) => async (dispatch) => {
  const {
    pageNo,
    pageSize,
    search,
    fromDate,
    toDate,
    role,
    key,
    sort,
    userId,
  } = params;
  let queryParametere =
    "?page=" +
    pageNo +
    "&limit=" +
    pageSize +
    "&search=" +
    search +
    "&role=" +
    role +
    "&key=" +
    key +
    "&value=" +
    sort;

  if (fromDate && toDate) {
    queryParametere += "&from_date=" + fromDate.format("YYYY-MM-DD");
    queryParametere += "&to_date=" + toDate.format("YYYY-MM-DD");
  }
  if (userId) {
    queryParametere += "&userId=" + userId;
  }

  const resp = await DataService.get(
    API.smsNotification.getNotificationList + queryParametere
  );
  if (!resp.data.error) {
    dispatch(fetchNotificationList(resp.data.data));
    return resp.data.data;
  } else {
    message.error(resp.data.message);
    return false;
  }
};
export const sendNoticeNotification = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(
      API.smsNotification.sendNoticeNotification,
      payload
    );

    if (!resp.data.error) {
      return true;
    } else {
      // message.error(resp.data.message);
      return false;
    }
  };
};
export const notificationNoticeList = (params) => async (dispatch) => {
  const {
    pageNo,
    pageSize,
    search,
    fromDate,
    toDate,
    role,
    key,
    sort,
    userId,
  } = params;
  let queryParametere =
    "?page=" +
    pageNo +
    "&limit=" +
    pageSize +
    "&search=" +
    search +
    "&role=" +
    role +
    "&key=" +
    key +
    "&value=" +
    sort;

  if (fromDate && toDate) {
    queryParametere += "&from_date=" + fromDate.format("YYYY-MM-DD");
    queryParametere += "&to_date=" + toDate.format("YYYY-MM-DD");
  }
  if (userId) {
    queryParametere += "&userId=" + userId;
  }

  const resp = await DataService.get(
    API.smsNotification.notificationNoticeList + queryParametere
  );
  if (!resp.data.error) {
    dispatch(fetchNoticeNotificationList(resp.data.data));
    return resp.data.data;
  } else {
    message.error(resp.data.message);
    return false;
  }
};
