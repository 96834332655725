import { Collapse, DatePicker, Rate, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getRidersRatingByDriverId } from "../../../../redux/Ratings/actionCreatore";
const { Panel } = Collapse;
function DriverRatings() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { driverId } = useParams();
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    reteriveRatingList();
  }, []);
  const reteriveRatingList = async () => {
    const resp = await dispatch(
      getRidersRatingByDriverId(pageNo, limit, driverId)
    );
    if (resp) {
      // loading((prev) => [...prev, 4]);
      setLoading(false);
    }
  };
  const ratings = useSelector(
    (state) => state.rating.riderRatingListDataByDriverId
  );

  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    await dispatch(getRidersRatingByDriverId(page, pageSizes, driverId));
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setLimit(pageSizes);
    await dispatch(getRidersRatingByDriverId(current, pageSizes, driverId));
  };

  const ratingColumns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * limit,
    },
    {
      title: "Rider Name",
      key: "name",
      render: (data) => {
        return (
          <span>{data && data?.rider_id ? data?.rider_id.name : "-"}</span>
        );
      },
    },
    {
      title: "Rider Phone Number",
      key: "number",
      render: (data) => {
        return (
          <span>{data && data?.rider_id ? data?.rider_id.number : "-"}</span>
        );
      },
    },
    {
      title: "Rating",
      key: "rating",
      render: (data) => {
        return (
          <span>
            {" "}
            <Rate value={data && data?.rating ? data?.rating : "-"}></Rate>
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <Collapse
        style={{ border: "none" }}
        //  defaultActiveKey={"1"}
      >
        <Panel
          header={`Ratings Given By Rider | Total ${ratings?.total_counts}`}
          key="1"
          style={{
            background: "#00000047",
            // borderRadius: 10,
            fontWeight: 500,
          }}
        >
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            dataSource={ratings?.list}
            columns={ratingColumns}
            scroll={{ x: 10 }}
            loading={loading}
            pagination={
              // rides?.data?.total_counts > 10
              //   ?
              {
                showSizeChanger: true,
                pageSize: limit,
                total: ratings?.total_counts,
                onShowSizeChange: onShowSizeChange,
                onChange: onPageChange,
                pageSizeOptions: ["10", "50", "100"],
              }
              // : false
            }
          />
        </Panel>
      </Collapse>
    </div>
  );
}

export default DriverRatings;
