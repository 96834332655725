import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Table,
  Tabs,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStateRef from "react-usestateref";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import {
  addCancelBookingReasons,
  deleteCancelBookingReasons,
  getCancelBookingReasonsData,
  updateCancelBookingReasons,
} from "../../redux/Masters/cancelBookingReason/actionCreatore";
import { checkAction } from "../../utility/commonFunction";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import Loader from "../loader/loader";
import { useQuery } from "react-query";
import TabPane from "antd/lib/tabs/TabPane";
const CancelBookingReason = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [ModalTitle, setModalTitle] = useState("");
  const [type, setType] = useState("");
  const [updateFlow, setUpdateFlow, setUpdateFlowFileRef] = useStateRef("");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [activeKey, setActiveKey] = useState("Rider");
  //  const [tableLoading,setTableLoading]=useState(false)
  useEffect(() => {
    // fetchReasons();
    window.scrollTo(0, 0);
  }, []);

  const { data: data, isLoading: isLoadingDriver } = useQuery(
    "cancelBookingReasonList",
    () => {
      return fetchReasons();
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: dataRider, isLoading: isLoadingRider } = useQuery(
    "cancelBookingReasonList",
    () => {
      return fetchReasons();
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const fetchReasons = async () => {
    setLoading(true);
    const resp = await dispatch(
      getCancelBookingReasonsData(
        pageNo,
        pageSize,
        activeKey.toLocaleLowerCase()
      )
    );
    if (resp) {
      setLoading(false);
    }
  };

  const reasonsList = useSelector(
    (state) => state.cancelBooking.cancelBookingReasonsData
  );
  // console.log(reasonsList);

  const deleteReasonFinal = async (id) => {
    setLoading(true);

    let remove = await dispatch(deleteCancelBookingReasons(id));
    if (remove) {
      message.success("Reason deleted successfully");
      fetchReasons();
    }
  };
  const deleteReason = (id) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this reason?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteReasonFinal(id);
      },
    });
  };
  const editReason = (data) => {
    setModalTitle(`Edit Reason / ${activeKey}`);
    setIsModalOpen(true);
    setUpdateFlow(data._id);
    // console.log(data);

    form.resetFields();
    form.setFieldsValue({
      reason: data.reason,
      cancellationChange: data.cancellationChange,
      transactionType: data.transactionType,
      is_active: data.is_active,
    });
  };
  const handleSubmit = async (values) => {
    // setLoading(true);
    if (!values.cancellationChange) {
      values.cancellationChange = 0;
    }
    values.role = activeKey.toLocaleLowerCase();
    console.log(values, "vvvvv");
    values.reason = values.reason.trim();
    if (setUpdateFlowFileRef.current == "") {
      const resp = await dispatch(
        addCancelBookingReasons(JSON.stringify(values))
      );
      if (resp) {
        message.success("Reason added successfully");
        setIsModalOpen(false);
        fetchReasons("");
      }
    } else {
      const resp = await dispatch(
        updateCancelBookingReasons(JSON.stringify(values), updateFlow)
      );
      if (resp) {
        message.success("Reason updated successfully");
        setIsModalOpen(false);
        fetchReasons("");
        setUpdateFlow("");
      }
    }
  };
  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      align: "center",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },
    {
      title: "Reason Title",
      align: "center",

      render: (data, record) => {
        return <span>{data?.reason}</span>;
      },
    },
    {
      title: <div>Cancellation Charge (₹)</div>,
      align: "center",

      render: (data, record) => {
        return (
          <span>
            {data?.cancellationChange ? data?.cancellationChange : "-"}
          </span>
        );
      },
    },
    {
      title: "Status",
      align: "center",

      render: (data, record) => {
        return (
          <Tag color={data?.is_active == "active" ? "green" : "volcano"}>
            {data?.is_active ? data?.is_active.toLocaleUpperCase() : "-"}
          </Tag>
        );
      },
    },
    {
      title: "Transaction Type",
      align: "center",

      render: (data, record) => {
        return (
          <Tag
            color={
              data?.transactionType == "credit"
                ? "blue"
                : data?.transactionType == "debit"
                ? "pink"
                : ""
            }
          >
            {data?.transactionType
              ? data?.transactionType.toLocaleUpperCase()
              : "-"}
          </Tag>
        );
      },
    },
    {
      title: t("action"),
      // dataIndex: "documents",
      key: "action",
      align: "center",

      render(data, record) {
        return (
          <div>
            {/* {console.log("data", data)} */}
            {checkAction("manageTips", "edit") && (
              <Button
                onClick={() => editReason(data)}
                type="primary"
                // style={{ cursor: "pointer", color: "blue" }}
              >
                <EditOutlined />
                Edit
              </Button>
            )}
            {checkAction("manageTips", "delete") && (
              <Button
                onClick={() => deleteReason(data._id)}
                style={{ cursor: "pointer", marginLeft: 2 }}
                type="primary"
                danger
                disabled
              >
                <DeleteOutlined />
                Delete
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  const showModal = () => {
    form.resetFields();
    setModalTitle(`Add New Reason For ${activeKey}`);
    setIsModalOpen(true);

    // setSelectedId("")
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedId("")
    form.resetFields();
    setUpdateFlow("");
    // setSelectDep(0)
  };

  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    await dispatch(
      getCancelBookingReasonsData(
        page,
        pageSizes,
        activeKey.toLocaleLowerCase()
      )
    );
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    await dispatch(
      getCancelBookingReasonsData(
        current,
        pageSizes,
        activeKey.toLocaleLowerCase()
      )
    );
  };

  const handleTabClick = async (key) => {
    setActiveKey(key);
    setPageNo(1);
    setPageSize(10);
    setLoading(true);
    if (key == "Rider") {
      const resp = await dispatch(
        getCancelBookingReasonsData(pageNo, pageSize, "rider")
      );
      if (resp) {
        setLoading(false);
      }
    }
    if (key == "Driver") {
      const resp = await dispatch(
        getCancelBookingReasonsData(pageNo, pageSize, "driver")
      );
      if (resp) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">
              {t("booking")} {t("cancelReason")}
            </h1>
            <BreadCrumb />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            {checkAction("deleteProfile", "add") && (
              <Button
                style={{ float: "right" }}
                className="ADD"
                key="1"
                size="large"
                type="primary"
                onClick={showModal}
              >
                <PlusOutlined /> Add New Reason
              </Button>
            )}
          </Col>
        </Row>
        <Divider />
        <Tabs type="card" activeKey={activeKey} onTabClick={handleTabClick}>
          <TabPane key="Rider" tab="Rider">
            <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                dataSource={reasonsList?.list}
                columns={columns}
                searchable
                scroll={{ x: "45vh" }}
                loading={isLoadingRider}
                pagination={
                  reasonsList?.total_counts > 10
                    ? {
                        showSizeChanger: true,
                        pageSize: pageSize,
                        total: reasonsList?.total_counts,
                        onShowSizeChange: onShowSizeChange,
                        onChange: onPageChange,
                        pageSizeOptions: ["10", "30", "50"],
                      }
                    : false
                }
              />
            </Card>
          </TabPane>
          <TabPane key="Driver" tab="Driver">
            <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                dataSource={reasonsList?.list}
                columns={columns}
                searchable
                scroll={{ x: "45vh" }}
                loading={isLoadingDriver}
                pagination={
                  reasonsList?.total_counts > 10
                    ? {
                        showSizeChanger: true,
                        pageSize: pageSize,
                        total: reasonsList?.total_counts,
                        onShowSizeChange: onShowSizeChange,
                        onChange: onPageChange,
                        pageSizeOptions: ["10", "30", "50"],
                      }
                    : false
                }
              />
            </Card>
          </TabPane>
        </Tabs>

        {/* {!loading && ( */}
        <>
          {/* <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col lg={12} md={12} sm={12} xs={12} className="total">
                  Total {reasonsList.total_counts}
                </Col>
              </Row>
            </Card> */}
        </>
        {/* )} */}
        {/* {isLoadingDriver && <Loader />} */}
      </div>
      <Modal
        title={ModalTitle}
        open={isModalOpen}
        onOk={form.submit}
        // closable={true}
        // maskClosable={false}
        onCancel={handleCancel}
        okText="Add"
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            name="reason"
            label={"Reason Title"}
            rules={[
              {
                required: true,
                message: "Please Enter Reason",
                pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <Input type="text" placeholder="Enter Reason" />
          </Form.Item>
          <Form.Item
            name="transactionType"
            label={"Transaction Type"}
            rules={[
              {
                required: true,
                message: "Please Choose Transaction Type",
                // pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <Radio.Group onChange={(e) => setType(e.target.value)}>
              <Radio value={"credit"} key="credit">
                Credit
              </Radio>
              <Radio value={"debit"} key="debit">
                Debit
              </Radio>
              <Radio value={"no-action"} key="noaction">
                No Action
              </Radio>
            </Radio.Group>
          </Form.Item>
          {type === "no-action" ? (
            ""
          ) : (
            <Form.Item
              name="cancellationChange"
              label={"Cancellation Charge (₹)"}
              rules={[
                {
                  required: true,
                  message: "Please Enter Amount",
                  // pattern: new RegExp(/[^\s]+\s*$/i),
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Enter Amount"
                onWheel={(e) => e.currentTarget.blur()}
                min={10}
              />
            </Form.Item>
          )}
          {/* <Form.Item
            name="role"
            label={"Reason For"}
            rules={[
              {
                required: true,
                message: "Please Choose Role",
                // pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <Radio.Group>
              <Radio value={"driver"} key="driver">
                Driver
              </Radio>
              <Radio value={"rider"} key="rider">
                Rider
              </Radio>
              <Radio value={"both"} key="both">
                Both(Driver & Rider)
              </Radio>
            </Radio.Group>
          </Form.Item> */}

          <Form.Item
            name="is_active"
            label={"Status"}
            rules={[
              {
                required: true,
                message: "Please Choose Status",
                // pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <Radio.Group>
              <Radio value={"active"} key="active">
                Active
              </Radio>
              <Radio value={"deactive"} key="deactive">
                Deactive
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CancelBookingReason;
