const actions = {
  GET_DRIVER_LIST_FOR_TAG: "GET_DRIVER_LIST_FOR_TAG",
  GET_RIDER_LIST_FOR_TAG: "GET_RIDER_LIST_FOR_TAG",
  GET_NOTIFICATION_LIST: "GET_NOTIFICATION_LIST",
  GET_NOTIFICATION_NOTICE_LIST: "GET_NOTIFICATION_NOTICE_LIST",
  fetchDriverList: (data) => {
    return {
      type: actions.GET_DRIVER_LIST_FOR_TAG,
      data,
    };
  },
  fetchRiderList: (data) => {
    return {
      type: actions.GET_RIDER_LIST_FOR_TAG,
      data,
    };
  },
  fetchNotificationList: (data) => {
    return {
      type: actions.GET_NOTIFICATION_LIST,
      data,
    };
  },
  fetchNoticeNotificationList: (data) => {
    return {
      type: actions.GET_NOTIFICATION_NOTICE_LIST,
      data,
    };
  },
};

export default actions;
