import actions from "./actions";

const { GET_ACTIVITY_LOGS_LIST } = actions;

const initState = {
  acitivityLogsListdata: {},
};

const ActivityLogsReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_ACTIVITY_LOGS_LIST:
      return {
        ...state,
        acitivityLogsListdata: data,
      };

    default:
      return state;
  }
};
export default ActivityLogsReducer;
