import actions from "./actions";

const {GET_RATING_TAGS } = actions;

const initState = {
  ratinngTags: []
  // newCityData: {},
};

const ratingTagsReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_RATING_TAGS:
      return {
        ...state,
        ratinngTags: data,
      };
  
    default:
      return state;
  }
};
export default ratingTagsReducer;
