import { Button, Col, Divider, message, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  getDoc,
  updateDoc,
} from "../../redux/Police Verification Doc/actionCreatore";
import { useDispatch } from "react-redux";
import { IMAGE_URL_PREFIX } from "../../config/dataService/dataService";
import { checkAction } from "../../utility/commonFunction";

import Loader from "../loader/loader";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

const PoliceVerification = () => {
  const [pdfUrl, setPdfUrl] = useState();
  const [file, setFile] = useState();
  const [ok, setOk] = useState(false);
  const [ID, setID] = useState();
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [uploadPdfUrl, setUploadPdfUrl] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    // getAPI();
    window.scrollTo(0, 0);
  }, []);

  const { data: dataCity, isLoading: isLoading } = useQuery(
    "policeVerificationDoc",
    () => {
      return getAPI();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const getAPI = async () => {
    const resp = await dispatch(getDoc());
    if (resp) {
      // console.log(resp);
      setPdfUrl(resp.docURL);
      setID(resp._id);
      setLoading(false);
    }
  };

  const handleFileUpload = (event) => {
    // console.log("event", event.target.files[0]);

    // setOk(true);
    const file = event.target.files[0];
    const fileUrl = URL.createObjectURL(file);
    // setPdfUrl(fileUrl);
    setFile(file);
    setUploadPdfUrl(fileUrl);
    setModalOpen(true);
  };
  const saveDoc = async () => {
    // if (file === undefined) {
    //   message.success("Change Document Before Save");
    // } else {
    let formData = new FormData();
    formData.append("docURL", file);
    // console.log(file);
    setLoading(true);
    const resp = await dispatch(updateDoc(formData, ID));
    if (resp) {
      setLoading(false);
      // setOk(false);
      setModalOpen(false);
      setUploadPdfUrl("");
      getAPI();
    }
    // }
  };
  const handleCancel = () => {
    setModalOpen(false);
    setUploadPdfUrl("");
    setFile();
  };
  const { t } = useTranslation();
  return (
    <div className="common_layout content-container">
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h1 className="heading">{t("policeVerificationDoc")}</h1>
          <BreadCrumb />
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          {checkAction("master_policeVerificationDoc", "edit") && (
            <>
              {/* <Button
                style={{ float: "right", marginLeft: "10px" }}
                className="ADD"
                size="large"
                type="primary"
                onClick={() => saveDoc()}
              >
                Save
              </Button> */}
              <Button
                style={{ float: "right" }}
                className="ADD"
                // key="1"
                size="large"
                type="primary"
              >
                <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
                  <PlusOutlined /> Change document
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept=".pdf"
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                />
              </Button>
            </>
          )}
        </Col>
      </Row>
      <Divider />
      {isLoading && <Loader />}
      {!isLoading && (
        <Row>
          <Col span={24}>
            <object
              data={IMAGE_URL_PREFIX + pdfUrl}
              type="application/pdf"
              width="100%"
              height="750px"
            >
              <p>
                Your browser does not support PDF previewer. Please
                <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                  download the PDF
                </a>
                to view it.
              </p>
            </object>
          </Col>
        </Row>
      )}
      {/* {console.log("uploadPdfUrl", uploadPdfUrl)} */}
      <Modal
        open={modalOpen}
        title={"Police Verification Document"}
        onCancel={handleCancel}
        onOk={() => saveDoc()}
      >
        {/* <object
          data={uploadPdfUrl}
          // data={"https://www.africau.edu/images/default/sample.pdf"}
          type="application/pdf"
          width="100%"
          height="500px"
        >
          <p>
            Your browser does not support PDF previewer. Please
            <a href={uploadPdfUrl} rel="noopener noreferrer">
              download the PDF
            </a>
            to view it.
          </p>
        </object> */}
        <embed src={uploadPdfUrl} width={"100%"} height="500px" />
      </Modal>
    </div>
  );
};

export default PoliceVerification;
