import {
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Row,
  Select,
  Table,
} from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WordFormate } from "../../utility/commonFunction";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";

import {
  FileExcelOutlined,
  FileOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import { useQuery } from "react-query";
import { SUPERADMIN } from "../../config/dataService/dataService";
import { getActivityLogsData } from "../../redux/Activity Logs/actionCreatore";
import { getItem } from "../../utility/localStorageControl";
import ReportModal from "../Modal/ReportModal";
import VerifyOtpModal from "../Modal/VerifyOtpModal";

const { RangePicker } = DatePicker;
function ActivityLogs(props) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [originFrom, setOriginFrom] = useState("");
  const [activityType, setActivityType] = useState("");
  const userDetails = getItem("user_details");
  const [showReportModal, setShowReportModal] = useState(false);

  const [docType, setDocType] = useState("");
  const [docName, setDocName] = useState("Activity Logs Report");
  const [showVerify, setShowVerify] = useState(false);
  const reportKeys = ["Origin", "Name", "Activity Type", "Activity", "Time"];

  const payload = {
    page,
    limit,
    originFrom,
    activityType,
    fromDate,
    toDate,
  };

  const { data, isLoading } = useQuery(
    "activityLogs",
    () => {
      return fetchActivityLogs(payload);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchActivityLogs = async (payload) => {
    const resp = await dispatch(getActivityLogsData(payload));
  };

  const activityLogs = useSelector(
    (state) => state.activityLogs.acitivityLogsListdata
  );
  const [exportData, setExportData] = useState(activityLogs?.list);
  const modifiedExportData =
    exportData &&
    exportData.map((item, index) => ({
      "Sr. No.": index + 1 + (page - 1) * limit,
      Origin: WordFormate(item?.userId?.role) || "",
      Name: item.userId?.name || "",
      "Activity Type": WordFormate(item?.type) || "",
      Activity: item?.message || "-",
      Time: new Date(item?.created_at).toLocaleString() || "-",
    }));

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setExportData(selectedRows);
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );
    },
  };

  const rangePresets = [
    { label: "Today", value: [dayjs(), dayjs()] },
    { label: "Last 7 Days", value: [dayjs().add(-7, "days"), dayjs()] },
    { label: "Last Month", value: [dayjs().add(-1, "month"), dayjs()] },
    { label: "Last 6 Month", value: [dayjs().add(-6, "month"), dayjs()] },
    { label: "Last Year", value: [dayjs().add(-1, "year"), dayjs()] },
  ];

  const onShowSizeChange = async (current, pageSize) => {
    payload.limit = pageSize;
    setLimit(pageSize);
    await fetchActivityLogs(payload);
  };

  const onPageChange = async (page, pageSizes) => {
    payload.page = page;
    setPage(page);
    await fetchActivityLogs(payload);
  };
  const handleSelectOrigin = async (value) => {
    payload.originFrom = value ? value : "";
    setOriginFrom(value ? value : "");
    payload.page = 1;
    setPage(1);
    await fetchActivityLogs(payload);
  };

  const handleSelectType = async (value) => {
    payload.activityType = value ? value : "";
    setActivityType(value ? value : "");
    await fetchActivityLogs(payload);
  };

  const onRangeChange = async (dates, dateStrings) => {
    if (dates) {
      setFromDate(dates[0]);
      setToDate(dates[1]);
      payload.fromDate = dates[0];
      payload.toDate = dates[1];
      await fetchActivityLogs(payload);
    } else {
      setFromDate("");
      setToDate("");
      payload.fromDate = "";
      payload.toDate = "";
      await fetchActivityLogs(payload);
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      render: (data, ind, index) => (
        <span>{index + 1 + (page - 1) * limit}</span>
      ),
    },
    {
      title: "Origin",
      key: "origin",
      render: (data) => {
        return (
          <span>{data?.userId ? WordFormate(data?.userId?.role) : "- "}</span>
        );
      },
    },
    // {
    //   title: "User",
    //   children: [
    //     {
    //       title: "Image",
    //       key: "image",
    //       render: (data) => {
    //         return (
    //           <Avatar
    //             size={40}
    //             icon={
    //               data?.userId?.profile_image ? (
    //                 <img src={IMAGE_URL_PREFIX + data?.userId?.profile_image} />
    //               ) : (
    //                 <UserOutlined />
    //               )
    //             }
    //           />
    //         );
    //       },
    //     },

    //   ],
    // },
    {
      title: "User Name",
      key: "name",
      render: (data) => {
        return <span>{data?.userId ? data?.userId?.name : "- "}</span>;
      },
    },
    // {
    //   title: "Activity ",
    //   key: "activity",
    //   children: [
    //     {
    //       title: "Module",
    //       key: "module",
    //       render: (data) => {
    //         return (
    //           <span>
    //             {data?.activity_logs_type
    //               ? WordFormate(data?.activity_logs_type)
    //               : "- "}
    //           </span>
    //         );
    //       },
    //     },

    //   ],
    // },
    {
      title: "Activity Type",
      key: "type",
      render: (data) => {
        return <span>{data?.type ? WordFormate(data?.type) : "- "}</span>;
      },
    },
    {
      title: "Message",
      key: "message",

      render: (data) => {
        return <span>{data?.message ? data?.message : "- "}</span>;
      },
    },
    {
      title: "Action TimeStamp",
      key: "timeStamp",
      render: (data) => {
        const date = new Date(data?.created_at);
        return (
          <span>
            {data?.created_at ? (
              <span>
                {" "}
                {date.toLocaleDateString()} <br /> {date.toLocaleTimeString()}{" "}
              </span>
            ) : (
              "-"
            )}
          </span>
        );
      },
    },
  ];

  return (
    <div className="common_layout content-container">
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h1 className="heading">Activity Logs</h1>
          <BreadCrumb />
        </Col>
      </Row>
      <Divider />
      <Card style={{ marginBottom: 10 }} className="shadowCardBox">
        <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
          <Col xxl={6} lg={4} md={8} sm={24} className="total">
            Total
            {" " + activityLogs?.total_counts}
          </Col>

          <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
            <Select
              placeholder={"Choose Origin"}
              style={{ width: "100%" }}
              onSelect={handleSelectOrigin}
              allowClear
              onClear={handleSelectOrigin}
            >
              <Select.Option
                value="super_admin"
                key="1"
                className="selectOptions"
              >
                Super Admin
              </Select.Option>
              <Select.Option value="admin" key="2" className="selectOptions">
                Admin
              </Select.Option>
              <Select.Option value="captain" key="3" className="selectOptions">
                Captain
              </Select.Option>
              <Select.Option
                value="callCenter"
                key="4"
                className="selectOptions"
              >
                Call Center
              </Select.Option>
              <Select.Option value="driver" key="5" className="selectOptions">
                Driver
              </Select.Option>
              <Select.Option value="rider" key="6" className="selectOptions">
                Rider
              </Select.Option>
            </Select>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
            <Select
              placeholder="Choose Activity Type"
              style={{ width: "100%" }}
              onSelect={handleSelectType}
              allowClear
              onClear={handleSelectType}
            >
              <Select.Option value="login" key="1" className="selectOptions">
                Login
              </Select.Option>
              <Select.Option
                value="socialLogin"
                key="2"
                className="selectOptions"
              >
                Social Login
              </Select.Option>{" "}
              <Select.Option value="logout" key="3" className="selectOptions">
                Logout
              </Select.Option>{" "}
              <Select.Option value="register" key="4" className="selectOptions">
                Register
              </Select.Option>{" "}
              <Select.Option
                value="forgotPassword"
                key="5"
                className="selectOptions"
              >
                Forgot Pasword
              </Select.Option>{" "}
              <Select.Option
                value="resetPassword"
                key="6"
                className="selectOptions"
              >
                Reset Password{" "}
              </Select.Option>{" "}
              <Select.Option
                value="changePassword"
                key="7"
                className="selectOptions"
              >
                Change Password
              </Select.Option>{" "}
              <Select.Option value="add" key="8" className="selectOptions">
                Add
              </Select.Option>{" "}
              <Select.Option value="edit" key="9" className="selectOptions">
                Edit
              </Select.Option>{" "}
              <Select.Option value="delete" key="10" className="selectOptions">
                Delete
              </Select.Option>
            </Select>
          </Col>
          {/* <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
            <Select
              placeholder={"Choose Users"}
              style={{ width: "100%" }}
            ></Select>
          </Col> */}
          <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
            <RangePicker
              presets={rangePresets}
              onChange={onRangeChange}
              defaultValue={[fromDate, toDate]}
              style={{
                borderRadius: "0",
                // marginLeft: "10px",
                // width: "10vw",
                width: "100%",
              }}
              allowClear={true}
              format={"DD/MM/YYYY"}
              disabledDate={(current) => current.isAfter(moment())}

              // style={{display:"flex"}}
            />
          </Col>
          {userDetails?.role == SUPERADMIN && (
            <Col xxl={4} xl={4} lg={5} md={8} sm={12} xs={24}>
              <Select
                placeholder="Export Report"
                style={{
                  width: "100%",
                  // marginLeft: "10px",

                  // float: "right",
                }}
                value={null}
                onSelect={(value) => {
                  // setShowReportModal(true);
                  setShowVerify(true);
                  // deleteKeys(value);
                  setDocType(value);
                }}
              >
                <Select.Option value="csv" className="selectOptions">
                  {" "}
                  <FileOutlined /> CSV
                </Select.Option>
                <Select.Option value="excel" className="selectOptions">
                  {" "}
                  <FileExcelOutlined /> Excel (XLSX)
                </Select.Option>
                <Select.Option value="pdf" className="selectOptions">
                  {" "}
                  <FilePdfOutlined /> Pdf
                </Select.Option>
              </Select>
            </Col>
          )}
        </Row>
      </Card>
      <Card className="shadowCardBox">
        <Table
          dataSource={activityLogs?.list}
          // loading={tableLoading}
          rowSelection={{
            type: Checkbox,
            ...rowSelection,
          }}
          pagination={{
            showSizeChanger: true,
            pageSize: limit,
            total: activityLogs?.total_counts,
            onShowSizeChange: onShowSizeChange,
            onChange: onPageChange,
            pageSizeOptions: ["50", "100", "500"],
            current: page,
          }}
          columns={columns}
          searchable
          scroll={{ x: "45vh" }}
        />
      </Card>
      <ReportModal
        showReportModal={showReportModal}
        setShowReportModal={setShowReportModal}
        modifiedExportData={modifiedExportData}
        docType={docType}
        reportKeys={reportKeys}
        docName={docName}
      />
      <VerifyOtpModal
        showVerify={showVerify}
        setShowVerify={setShowVerify}
        setShowReportModal={setShowReportModal}
        type={"Activity Logs"}
      />
    </div>
  );
}

export default ActivityLogs;
