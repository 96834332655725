import actions from "./actions";

const { GET_CALLCENTER_LIST,GET_CALLCENTER_BY_ID } = actions;

const initState = {
  callCenterListData: {},
  callCenterData:{}
};

const callCenterReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_CALLCENTER_LIST:
      return {
        ...state,
        callCenterListData: data,
      };
      case GET_CALLCENTER_BY_ID:
        return {
          ...state,
          callCenterData: data,
        };
    default:
      return state;
  }
};
export default callCenterReducer;
