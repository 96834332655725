import actions from "./actions";

const {
  GET_SMS_TEMPLATE_DB_LIST,
  GET_SMS_TEMPLATE_LIST,
  GET_TEMPLATES_LIST,
} = actions;

const initState = {
  smsTemplateDbData: {},
  smsTemplateData: {},
  templatesList: {},
};

const templateReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_SMS_TEMPLATE_DB_LIST:
      return {
        ...state,
        smsTemplateDbData: data,
      };
    case GET_SMS_TEMPLATE_LIST:
      return {
        ...state,
        smsTemplateData: data,
      };
    case GET_TEMPLATES_LIST:
      return {
        ...state,
        templatesList: data,
      };

    default:
      return state;
  }
};
export default templateReducer;
