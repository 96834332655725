import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const {
  getPartnersList,
  getPartnerData
} = actions;

export const getAllPartnersList = (page,limit,status,search,city) => {
  return async (dispatch) => {
    const queryParameter="?page="+page+"&limit="+limit+"&status="+status+"&search="+search+"&interestedForCity="+city
    const resp = await DataService.get(API.partners.get+queryParameter);

    if (!resp.data.error) {
      dispatch(getPartnersList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};



export const approvePartnerRequest = (id,payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(API.partners.approve + id,payload);

    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const rejectPartnerRequest = (id,payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(API.partners.approve + id,payload);

    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getPartnersDataById = (id) => {
  return async (dispatch) => {

    const resp = await DataService.get(API.partners.get+"/"+id);

    if (!resp.data.error) {
      dispatch(getPartnerData(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
