import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Image,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./Vehicle.css";
import { useDispatch, useSelector } from "react-redux";
import useStateRef from "react-usestateref";

import {
  addVehicleModel,
  addVehicleType,
  getVehicleModelsData,
  getVehicleTypesData,
  removeVehicleModel,
  removeVehicleType,
  updateVehicleModel,
  updateVehicleType,
} from "../../redux/VehicleTypes/actionCreatore";
import useSelection from "antd/lib/table/hooks/useSelection";
import {
  API_ENDPOINT,
  IMAGE_URL_PREFIX,
} from "../../config/dataService/dataService";
import {
  checkAction,
  getRandomColor,
  WordFormate,
} from "../../utility/commonFunction";
import Loader from "../loader/loader";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import FeatherIcon from "feather-icons-react";

const VehicleModal = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [ModalTitle, setModalTitle] = useState("Add Vehicle Type");
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [updateFlow, setUpdateFlow, setUpdateFlowFileRef] = useStateRef("");
  const [status, setStatus] = useState("");
  const [vehicleType, setVehicleTYpe] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    fetchVehicleTypes();
    window.scrollTo(0, 0);
  }, []);

  const fetchVehicleTypes = async () => {
    setLoading(true);
    const resp = await dispatch(getVehicleTypesData());
    if (resp) {
      setVehicleTypeList(resp);
      setLoading(false);
    }
  };
  const payload = {
    status,
    vehicleType,
  };
  const vehicleTypes = useSelector((state) => state.vehicleType.vehicleData);
  // console.log("vehicleTypes", vehicleTypes);
  const { data, isLoading } = useQuery(
    "vehcilelModelList",
    () => {
      return fetchVehicleModels(payload);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchVehicleModels = async (payload) => {
    setLoading(true);
    const resp = await dispatch(getVehicleModelsData(payload));
    if (resp) {
      setLoading(false);
    }
  };

  const vehicleModel = useSelector((state) => state.vehicleType.vehicleModel);

  const deleteVehicleModelFinal = async (id) => {
    // console.log(id);
    setLoading(true);

    let remove = await dispatch(removeVehicleModel(id));

    if (remove) {
      message.success("Vehicle Model removed successfully");
      fetchVehicleModels(payload);
    }
  };
  const deleteVehicleModel = (id) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this Vehicle Model?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteVehicleModelFinal(id);
      },
    });
  };
  const editVehicleModel = (data) => {
    setModalTitle("Edit Vehicle Model");
    setIsModalOpen(true);
    setUpdateFlow(data._id);
    // console.log(data);
    form.setFieldsValue({
      vehicle_type_id: data.vehicle_type_id._id,
      name: data.name,
      status: data.status,
    });
  };
  const handleSubmit = async (values) => {
    setLoading(true);

    // console.log(values);

    if (setUpdateFlowFileRef.current == "") {
      const resp = await dispatch(addVehicleModel(values));
      if (resp) {
        message.success("Vehicle Model added successfully");

        setIsModalOpen(false);
        fetchVehicleModels(payload);
      }
    } else {
      const resp = await dispatch(updateVehicleModel(values, updateFlow));
      if (resp) {
        message.success("Vehicle Model updated successfully");
        setUpdateFlow("");
        setIsModalOpen(false);
        fetchVehicleModels(payload);
      }
    }
  };

  const handleStatusFilter = async (value) => {
    setStatus(value);
    payload.status = value;
    fetchVehicleModels(payload);
  };
  const handleVehicleSelectFilter = async (value) => {
    setVehicleTYpe(value);
    payload.vehicleType = value;
    fetchVehicleModels(payload);
  };

  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      align: "center",

      render: (text, object, index) => index + 1,
    },

    {
      title: "Vehicle Type",
      align: "center",
      render: (data, record) => {
        return <span>{data?.vehicle_type_id?.name}</span>;
      },
    },
    {
      title: "Vehicle Model Name",
      align: "center",

      render: (data, record) => {
        return <span>{data?.name}</span>;
      },
    },
    {
      title: "Seating Capacity",
      align: "center",

      render: (data, record) => {
        return <span>{data?.vehicle_type_id?.person_capacity} </span>;
      },
    },
    {
      title: "Status",
      align: "center",

      render: (data, record) => {
        return <span>{data?.status ? WordFormate(data?.status) : "-"} </span>;
      },
    },
    // {
    //     title: "Vehicle Type Status",
    //     align: "center",

    //     render: (data, record) => {
    //         // return <span>{data?.status}</span>;
    //         return (
    //             <Tag
    //                 color={data.status === "inactive" ? "volcano" : "green"}
    //                 key={data.status}
    //             >
    //                 {data.status.toUpperCase()}
    //             </Tag>
    //         );
    //     },
    // },
    {
      title: t("action"),
      // dataIndex: "documents",
      align: "center",

      key: "action",
      render(data, record) {
        return (
          <div>
            {checkAction("master_vehicleType", "edit") && (
              <Button
                onClick={() => editVehicleModel(data)}
                type="primary"
                // style={{ cursor: "pointer", color: "blue" }}
              >
                <EditOutlined />
                Edit
              </Button>
            )}
            {checkAction("master_vehicleType", "delete") && (
              <Button
                onClick={() => deleteVehicleModel(data._id)}
                style={{ cursor: "pointer", marginLeft: 2 }}
                type="primary"
                danger
                disabled
              >
                <DeleteOutlined />
                Delete
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  const showModal = () => {
    form.resetFields();
    setModalTitle("Add Vehicle Model");
    setIsModalOpen(true);
    // setSelectedId("")
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedId("")
    form.resetFields();

    // setSelectDep(0)
  };

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">Vehicle Model</h1>
            <BreadCrumb />
          </Col>
          {/* Total {vehicleTypeList.length} */}
          <Col lg={12} md={12} sm={12} xs={12}>
            {checkAction("master_vehicleType", "add") && (
              <Button
                style={{ float: "right" }}
                className="ADD"
                key="1"
                size="large"
                type="primary"
                onClick={showModal}
              >
                <PlusOutlined /> Add Vehicle Model
              </Button>
            )}
          </Col>
        </Row>
        <Divider />
        {!isLoading && (
          <>
            <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={8}
                  sm={12}
                  xs={24}
                  className="total"
                >
                  Total {vehicleModel?.length}
                </Col>

                <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                  <Select
                    placeholder="Choose Status"
                    style={{ width: "100%" }}
                    filterOption={false}
                    allowClear
                    onClear={(e) => handleStatusFilter("")}
                    onSelect={(e) => handleStatusFilter(e)}
                  >
                    <Select.Option
                      value={"active"}
                      key={"active"}
                      className="selectOptions"
                    >
                      Active
                    </Select.Option>

                    <Select.Option
                      value={"deactive"}
                      key={"deactive"}
                      className="selectOptions"
                    >
                      Deactive
                    </Select.Option>
                  </Select>
                </Col>

                <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                  <Select
                    placeholder="Choose Vehicle Type"
                    style={{ width: "100%" }}
                    showSearch
                    filterOption={false}
                    allowClear
                    onClear={() => handleVehicleSelectFilter("")}
                    onSelect={(value) => handleVehicleSelectFilter(value)}
                  >
                    {vehicleTypes &&
                      vehicleTypes.length > 1 &&
                      vehicleTypes.map((vehicle) => {
                        return (
                          <Select.Option
                            value={vehicle._id}
                            className="selectOptions"
                            key={vehicle._id}
                          >
                            {vehicle?.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Col>
              </Row>
            </Card>

            <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                dataSource={vehicleModel}
                columns={columns}
                searchable
                pagination={false}
                scroll={{ x: "45vh" }}
              />
            </Card>
          </>
        )}
        {isLoading && <Loader />}
      </div>
      <Modal
        title={ModalTitle}
        open={isModalOpen}
        onOk={form.submit}
        // closable={true}
        // maskClosable={false}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          // labelCol={{ span: 5 }}
        >
          <Form.Item
            label={`Vehicle Type`}
            rules={[
              {
                required: true,
                message: "Please Enter Vehicle Type",
              },
            ]}
            name="vehicle_type_id"
          >
            <Select placeholder={"Choose Vehicle Type"}>
              {vehicleTypes &&
                vehicleTypes.length > 1 &&
                vehicleTypes.map((vehicle) => {
                  return (
                    <Select.Option value={vehicle._id} key={vehicle._id}>
                      {vehicle?.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name={"name"}
            label={`Modal Name`}
            rules={[
              {
                required: true,
                message: "Please Enter Modal Name",

                pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <Input type="text" placeholder="Enter Modal Name"></Input>
          </Form.Item>
          <Form.Item
            name={"status"}
            label={"Status"}
            rules={[
              {
                required: true,
                message: "Please Choose Status",
              },
            ]}
          >
            <Radio.Group>
              <Radio value={"active"}>Active</Radio>
              <Radio value={"deactive"}>Deactive</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default VehicleModal;
