import actions from "./actions";

const { GET_FARE_LIST,GET_FARE_BY_ID } = actions;

const initState = {
  fareListData: {},
  fareById:{}
};

const manageFareReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_FARE_LIST:
      return {
        ...state,
        fareListData: data,
      };
    case GET_FARE_BY_ID:
      return {
        ...state,
        fareById: data,
      };
    default:
      return state;
  }
};
export default manageFareReducer;
