import actions from "./actions";

const { GET_BOOKING_LIST,GET_BOOKING_BY_ID,GET_DRIVER_BOOKING_LIST,GET_RIDER_BOOKING_LIST } = actions;

const initState = {
  bookingListData: {},
  bookingDataById:[],
  driverBookingList:{},
  riderBookingList:{}

};

const bookingReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_BOOKING_LIST:
      return {
        ...state,
        bookingListData: data,
      };
      case GET_BOOKING_BY_ID:
        return {
          ...state,
          bookingDataById: data,
        };
        case GET_DRIVER_BOOKING_LIST:
          return {
            ...state,
            driverBookingList: data,
          };
          case GET_RIDER_BOOKING_LIST:
            return {
              ...state,
              riderBookingList: data,
            };
    default:
      return state;
  }
};
export default bookingReducer;
