import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  GOOGLE_MAP_API_KEY,
  GOOGLE_MAP_API_SUBTYPE_QUOTA_SERVICE,
  GOOGLE_MAP_API_SUBTYPE_VIEW_PORT_INFO_SERVICE,
  GOOGLE_MAP_API_TYPE_MAPS,
  GOOGLE_MAP_MODULE_NAME_MULTIPLE_DRIVER_LOCATION_ON_MAP,
  IMAGE_URL_PREFIX,
} from "../../config/dataService/dataService";
// import bike from "../../static/img/map/bike.png";

import { useDispatch } from "react-redux";
import { addMapStatistics } from "../../redux/MapStatistics/actionCreatore";
import { getItem } from "../../utility/localStorageControl";
import { SocketContext } from "../socket/socketContext";

// import Auto from "../../static/img/map/auto.png";
function MultipleDriverTrack(props) {
  const [activeMarker, setActiveMarker] = useState({});
  const [popup, setPopup] = useState(false);
  const dispatch = useDispatch();
  const [infoWindowData, setInfoWindowData] = useState({});

  const user = getItem("user_details");
  const mapRef = useRef(null);
  const boundsChangedListener = useRef(null);
  const socket = useContext(SocketContext);
  const [driversData, setDriversData] = useState([]);
  const [nePoints, setNePoints] = useState([]);
  const [swPoints, setSwPoints] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    callSocketEvent(swPoints, nePoints);
  }, [props.dutyStatus]);

  const callSocketEvent = (sw, ne) => {
    if (socket) {
      socket.emit("usersInsideBoundry", {
        coordinates: [ne, sw],
        webRoleUserId: user._id,
        dutyStatus: props.dutyStatus,
      });

      socket.on("usersDataInsideBoundry", (data) => {
        setDriversData(data);

        // setLoading(false)
      });
    }
  };

  useEffect(() => {
    const handleBoundsChanged = () => {
      const map = mapRef.current.map;
      const bounds = map.getBounds();

      // console.log("bounds", bounds);
      // Filter markers within the visible bounds

      let ne = bounds.getNorthEast(); // Coords of the northeast corner
      let sw = bounds.getSouthWest(); // Coords of the southwest corner
      let nw = new window.google.maps.LatLng(ne.lat(), sw.lng()); // Coords of the NW corner
      let se = new window.google.maps.LatLng(sw.lat(), ne.lng()); // Coords of the SE corner
      // console.log("ne", ne.toString(), "sw", sw.toString(), "nw", nw.toString(), "se", se.toString());
      setSwPoints([sw?.lng(), sw?.lat()]);
      setNePoints([ne?.lng(), ne?.lat()]);
      callSocketEvent([sw?.lng(), sw?.lat()], [ne?.lng(), ne?.lat()]);

      // console.log("visibleMarkers", visibleMarkers);
      // Limit the number of visible markers if necessary

      // Update the markers to be displayed on the map
      // setMarkers(limitedMarkers)
    };

    const mapReady = () => {
      // Add event listener for bounds_changed and store the listener function
      boundsChangedListener.current = window.google.maps.event.addListener(
        mapRef.current.map,
        "bounds_changed",
        handleBoundsChanged
      );
    };

    mapReady();

    return () => {
      // Remove the event listener when component unmounts
      window.google.maps.event.removeListener(boundsChangedListener.current);
    };
  }, [window.google, socket]);

  const handleMarkerUpdate = (updatedMarkers) => {
    // Update the markers with new positions and other properties
    // setMarkers(updatedMarkers);
  };
  const handleMapReady = async (mapProps, map) => {
    // Map has loaded successfully
    await dispatch(
      addMapStatistics({
        user_id: user._id,
        googleApiType: GOOGLE_MAP_API_TYPE_MAPS,
        googleSubApiType: GOOGLE_MAP_API_SUBTYPE_QUOTA_SERVICE,
        moduleName: GOOGLE_MAP_MODULE_NAME_MULTIPLE_DRIVER_LOCATION_ON_MAP,
        platformType: "web",
        originFrom: user?.role,
        apiStatus: "success",
      })
    );
    await dispatch(
      addMapStatistics({
        user_id: user._id,
        googleApiType: GOOGLE_MAP_API_TYPE_MAPS,
        googleSubApiType: GOOGLE_MAP_API_SUBTYPE_VIEW_PORT_INFO_SERVICE,
        moduleName: GOOGLE_MAP_MODULE_NAME_MULTIPLE_DRIVER_LOCATION_ON_MAP,
        platformType: "web",
        originFrom: user?.role,
        apiStatus: "success",
      })
    );
  };
  const handleMapError = async (mapProps, map) => {
    // Error occurred while loading the map
    await dispatch(
      addMapStatistics({
        user_id: user._id,
        googleApiType: GOOGLE_MAP_API_TYPE_MAPS,
        googleSubApiType: GOOGLE_MAP_API_SUBTYPE_QUOTA_SERVICE,
        moduleName: GOOGLE_MAP_MODULE_NAME_MULTIPLE_DRIVER_LOCATION_ON_MAP,
        platformType: "web",
        originFrom: user?.role,
        apiStatus: "error",
      })
    );
    await dispatch(
      addMapStatistics({
        user_id: user._id,
        googleApiType: GOOGLE_MAP_API_TYPE_MAPS,
        googleSubApiType: GOOGLE_MAP_API_SUBTYPE_VIEW_PORT_INFO_SERVICE,
        moduleName: GOOGLE_MAP_MODULE_NAME_MULTIPLE_DRIVER_LOCATION_ON_MAP,
        platformType: "web",
        originFrom: user?.role,
        apiStatus: "error",
      })
    );
  };
  return (
    <Map
      google={props.google}
      zoom={12}
      initialCenter={{ lat: 23.022505, lng: 72.571365 }}
      style={{ height: "80vh" }}
      ref={mapRef}
      // onZoomChanged={(e)=>console.log(e,a)}
      // onBoundsChanged={(e,a)=>{console.log(e,a);}}
      onReady={handleMapReady}
      onError={handleMapError}
    >
      {/* <Marker
        onClick={(props, marker) => {
          setPopup(true), setActiveMarker(marker);
        }}
        name={"Current location"}
        icon={{
          url: bike,

          scaledSize: new google.maps.Size(50, 50),
        }}
        position={{ lat: 23.09113, lng: 72.51251 }}
      />
      <Marker
        onClick={(props, marker) => {
          setPopup(true), setActiveMarker(marker);
        }}
        name={"Current location"}
        icon={{
          url: Auto,

          scaledSize: new google.maps.Size(60, 60),
        }}
        // position={{
        //     lat: 47.444,
        //     lng: -122.176
        // }}

        position={{ lat: 23.096109, lng: 72.674217 }}
      />
      <Marker
        onClick={(props, marker) => {
          setPopup(true), setActiveMarker(marker);
        }}
        name={"Current location"}
        icon={{
          url: bike,

          scaledSize: new google.maps.Size(50, 50),
        }}
        position={{ lat: 23.05014, lng: 72.66892 }}
      />

      <Marker
        onClick={(props, marker) => {
          setPopup(true), setActiveMarker(marker);
        }}
        name={"Current location"}
        // icon={{
        //   url: Auto,
        //   scaledSize: new window.google.maps.Size(60, 60),
        // }}
        // // position={{
        //     lat: 47.444,
        //     lng: -122.176
        // }}
        icon={{
          path:
            "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",
          scale: 0.7,
          strokeColor: "white",
          strokeWeight: 0.1,
          fillOpacity: 1,
          fillColor: "green",
          offset: "5%",
          // rotation: parseInt(heading[i]),
          anchor: new google.maps.Point(10, 25),
        }}
        position={{ lat: 23.050961, lng: 72.518819 }}
      /> */}

      {/* {user.role !== "captain"
        ? allDriverList &&
        allDriverList?.list?.map((data) => {
          return (
            <Marker
              onClick={(props, marker) => {
                setPopup(true),
                  setActiveMarker(marker),
                  setInfoWindowData(data);
              }}
              name={"Current location"}
              icon={{
                url:
                  data?.vehicle_type_id?.name == "G-Cab | Suv"
                    ? Car
                    : data?.vehicle_type_id?.name == "G-Auto"
                      ? Auto
                      : data?.vehicle_type_id?.name == "G-Bike"
                        ? bike
                        : Pin,

                scaledSize: new window.google.maps.Size(50, 50),
              }}
              position={{
                lat: data?.location?.coordinates[1],
                lng: data?.location?.coordinates[0],
              }}
            />
          );
        })
        : captainDriversList &&
        captainDriversList?.list?.map((data) => {
          return (
            <Marker
              onClick={(props, marker) => {
                setPopup(true),
                  setActiveMarker(marker),
                  setInfoWindowData(data);
              }}
              name={"Current location"}
              icon={{
                url:
                  data?.vehicle_type_id?.name == "G-Cab | Suv"
                    ? Car
                    : data?.vehicle_type_id?.name == "G-Auto"
                      ? Auto
                      : data?.vehicle_type_id?.name == "G-Bike"
                        ? bike
                        : Pin,

                scaledSize: new window.google.maps.Size(50, 50),
              }}
              position={{
                lat: data?.location?.coordinates[1],
                lng: data?.location?.coordinates[0],
              }}
            />
          );
        })} */}

      {driversData.map((data) => {
        return (
          <Marker
            onClick={(props, marker) => {
              setPopup(true);
              setActiveMarker(marker);
              setInfoWindowData(data);
            }}
            name={"Current location"}
            icon={{
              url: IMAGE_URL_PREFIX + data?.vehicle_type_id?.image_top_view,

              scaledSize: new window.google.maps.Size(35, 35),
            }}
            position={{
              lat: data?.location?.coordinates[1],
              lng: data?.location?.coordinates[0],
            }}
          />
        );
      })}

      <InfoWindow
        visible={popup}
        marker={activeMarker}

        // onClose={this.onInfoWindowClose}
      >
        <div
          style={{
            background:
              infoWindowData?.duty_status == "auto_clicker"
                ? "skyblue"
                : infoWindowData?.duty_status == "on_duty"
                ? "lightgreen"
                : infoWindowData?.duty_status == "off_duty"
                ? "red"
                : "orange",
          }}
        >
          <h1>{infoWindowData?.location?.location_name}</h1>
          <p>
            (lat:{infoWindowData?.location?.coordinates[1]}, lng:
            {infoWindowData?.location?.coordinates[0]})
          </p>

          <p>Name: {infoWindowData?.name}</p>
          <p>Vehicle No: {infoWindowData?.vehicle_number}</p>
          <p>Duty Status: {infoWindowData?.duty_status} </p>
        </div>
      </InfoWindow>
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_API_KEY,
})(MultipleDriverTrack);
