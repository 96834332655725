import { Breadcrumb } from "antd";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { getItem } from "../../utility/localStorageControl";

const BreadCrumb = ({ detailpage }) => {
  // const hideId=true
  const location = useLocation();
  const breadCrumbView = () => {
    const { pathname } = location;
    // console.log("pathnames", pathname);
    const user = getItem("user_details");

    const paths = pathname.split("/");

    const pathnames = paths.filter((item) => {
      return item != user.role && item !== "";
    });
    // console.log("pathnames length", pathnames);
    const capatilize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

    const items = [];

    return (
      <div>
        <Breadcrumb items={items}></Breadcrumb>
        <div style={{ display: "none" }}>
          {pathnames.length > 0
            ? items.push({
                title: (
                  <Link
                    to={`/${user.role}/`}
                    style={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    Dashboard
                  </Link>
                ),
              })
            : items.push({
                title: "Dashboard",
              })}
          {pathnames.map((name, index) => {
            const routeTo = `/${user.role}/${pathnames
              .slice(0, index + 1)
              .join("/")}`;
            const isLast = index === pathnames.length - 1;
            // console.log("islast",isLast);
            return isLast
              ? items.push({
                  title: detailpage ? detailpage : capatilize(name),
                })
              : (name != "driver") &
                (name != "master") &
                (name != "request") &
                (name != "update") &
                (name != "driverWallet") &
                (name != "riderWallet") &
                (name != "drivers") &
                (name != "captains") &
                (name != "support")
              ? items.push({
                  title: (
                    <Link
                      to={`${routeTo}`}
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      {capatilize(name)}
                    </Link>
                  ),
                })
              : items.push({ title: capatilize(name) });
          })}
        </div>
      </div>
    );
  };

  return <>{breadCrumbView()}</>;
};

export default BreadCrumb;
