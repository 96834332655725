import actions from "./actions";

const { GET_SETTING } = actions;

const initState = {
  settingsData: {},
};

const settingReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_SETTING:
      return {
        ...state,
        settingsData: data,
      };

    default:
      return state;
  }
};
export default settingReducer;
