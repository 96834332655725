import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const {
  getDashboard,
  getRideCount,
  getRideCountCities,
  getRideCountCaptains,
  getRideCountDrivers,
  fetchRecentTransactions,
} = actions;

export const getDashboardData = (time, appData) => {
  return async (dispatch) => {
    const payload = {
      time: time,
      appData: appData,
    };
    const resp = await DataService.post(API.dashboard.get, payload);

    if (!resp.data.error) {
      dispatch(getDashboard(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const getDashboardRideCount = ({ fromDate, toDate }) => {
  return async (dispatch) => {
    const queryParameter =
      (fromDate && "&fromDate=" + fromDate.format("YYYY-MM-DD")) +
      (toDate && "&toDate=" + toDate.format("YYYY-MM-DD"));

    const resp = await DataService.get(
      API.dashboard.ridesRequestCount + queryParameter
    );

    if (!resp.data.error) {
      dispatch(getRideCount(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const getDashboardRideCountCities = ({ fromDate, toDate, status }) => {
  return async (dispatch) => {
    const queryParameter =
      (fromDate && "&fromDate=" + fromDate.format("YYYY-MM-DD")) +
      (toDate && "&toDate=" + toDate.format("YYYY-MM-DD")) +
      (status && "&status=" + status);

    const resp = await DataService.get(
      API.dashboard.ridesRequestCountCities + queryParameter
    );

    if (!resp.data.error) {
      dispatch(getRideCountCities(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const getDashboardRideCountCaptains = ({
  fromDate,
  toDate,
  status,
  city,
}) => {
  return async (dispatch) => {
    const queryParameter =
      (fromDate && "&fromDate=" + fromDate.format("YYYY-MM-DD")) +
      (toDate && "&toDate=" + toDate.format("YYYY-MM-DD")) +
      (status && "&status=" + status) +
      (city && "&city=" + city);

    const resp = await DataService.get(
      API.dashboard.ridesRequestCountCaptains + queryParameter
    );

    if (!resp.data.error) {
      dispatch(getRideCountCaptains(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getDashboardRideCountDrivers = ({
  fromDate,
  toDate,
  status,
  city,
  captain,
}) => {
  return async (dispatch) => {
    const queryParameter =
      (fromDate && "&fromDate=" + fromDate.format("YYYY-MM-DD")) +
      (toDate && "&toDate=" + toDate.format("YYYY-MM-DD")) +
      (status && "&status=" + status) +
      (city && "&city=" + city) +
      (captain && "&captain=" + captain);

    const resp = await DataService.get(
      API.dashboard.ridesRequestCountDrivers + queryParameter
    );

    if (!resp.data.error) {
      dispatch(getRideCountDrivers(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getRecentTransactions = () => async (dispatch) => {
  const resp = await DataService.get(API.dashboard.getRecentTransaction);
  if (!resp.data.error) {
    dispatch(fetchRecentTransactions(resp.data.data));
    return true;
  } else {
    message.error(resp.data.message);
    return false;
  }
};
