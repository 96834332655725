import {
  HistoryOutlined,
  UserOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Select,
  Table,
  DatePicker,
  Pagination,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { ChartjsBarChart } from "../../components/charts/chartjs";
import { H1, H2, H6 } from "../../components/heading/style";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  driverWallet,
  driverWalletChart,
  getDriverById,
  transactionList,
} from "../../redux/drivers/actionCreatore";
import dayjs from "dayjs";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import Loader from "../loader/loader";
const { RangePicker } = DatePicker;
const RiderWallet = () => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState([]);
  const [transactionType, setTransactionType] = useState("");
  const [selectedMonth, setselectedMonth] = useState({ value: "", key: "" });
  const { riderId } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const months = [
    {
      value: "January",
      key: "jan",
      index: 0,
    },
    {
      value: "Feburary",
      key: "feb",
      index: 1,
    },
    {
      value: "March",
      key: "mar",

      index: 2,
    },
    {
      value: "April",
      key: "apr",

      index: 3,
    },
    {
      value: "May",
      key: "may",

      index: 4,
    },
    {
      value: "June",
      key: "june",
      index: 5,
    },
    {
      value: "July",
      key: "july",
      index: 6,
    },
    {
      value: "August",
      key: "aug",

      index: 7,
    },
    {
      value: "September",
      key: "sep",

      index: 8,
    },
    {
      value: "October",
      key: "oct",

      index: 9,
    },
    {
      value: "November",
      key: "nov",

      index: 10,
    },
    {
      value: "December",
      key: "dec",

      index: 11,
    },
  ];
  var mainData = [130, 100, 50, 25, 85, 310, 15, 25, 15, 110, 50, 25];
  const initialYear = 2022;
  let years = [initialYear];
  const date = new Date();
  const fullyear = date.getFullYear();
  let diff = fullyear - initialYear;
  while (diff > 0) {
    years.push(initialYear + diff);
    diff--;
  }
  years = years && years.sort((a, b) => (a < b ? 1 : -1));

  const dispatch = useDispatch();
  const payload = {
    driver_id: riderId,
    from_date: fromDate?.format("YYYY-MM-DD"),
    to_date: toDate?.format("YYYY-MM-DD"),
    page: pageNo,
    limit: pageSize,
  };
  useEffect(() => {
    setselectedMonth({ value: "", key: "" });
    fetchWallet();
    // reteriveDriverWalletChart();
    reteriveDriverData();

    // fetchTransactionHistory()
  }, []);
  useEffect(() => {
    fetchTransactionHistory(payload);
  }, [fromDate, toDate]);

  const reteriveDriverData = async () => {
    setLoading(true);
    const resp = await dispatch(getDriverById(riderId));
    if (resp) {
      setLoading(false);
    }
  };
  const riderData = useSelector((state) => state.driver.driverById);

  const fetchWallet = async () => {
    setLoading(true);
    const resp = await dispatch(driverWallet(riderId));
    if (resp) {
      setLoading(false);
    }
  };

  const wallet = useSelector((state) => state.driver.driverWallet);
  // console.log("wallet ", wallet);

  const fetchTransactionHistory = async (payload) => {
    setLoading(true);

    if (transactionType != "") {
      payload.transactionType = transactionType;
    }
    const resp = await dispatch(transactionList(payload));
    if (resp) {
      setLoading(false);
    }
  };

  const transactionHistory = useSelector(
    (state) => state.driver.transactionList
  );

  const reteriveDriverWalletChart = async () => {
    // console.log("object");
    await dispatch(driverWalletChart(riderId, selectedYear));
  };

  const walletChart = useSelector((state) => state.driver.driverWalletChart);

  const handleSelectYear = async (value) => {
    setSelectedYear(value);
    await dispatch(driverWalletChart(riderId, value));
  };

  const handleTransactionType = async (value) => {
    setTransactionType(value);
    const payload = {
      driver_id: riderId,
      from_date: fromDate?.format("YYYY-MM-DD"),
      to_date: toDate?.format("YYYY-MM-DD"),
    };
    if (value != "") {
      payload.transactionType = value;
    }

    const resp = await dispatch(transactionList(payload));
    if (resp) {
      setLoading(false);
    }
  };
  const onPageChange = (page) => {
    setPageNo(page);
    payload.page = page;
    fetchTransactionHistory(payload);
  };
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      payload.from_date = dates[0];
      payload.to_date = dates[1];
      setFromDate(dates[0]);
      setToDate(dates[1]);
    } else {
      payload.from_date = "";
      payload.to_date = "";
      setFromDate();
      setToDate();
    }
  };
  const rangePresets = [
    { label: "Today", value: [dayjs(), dayjs()] },
    { label: "Last 7 Days", value: [dayjs().add(-7, "days"), dayjs()] },
    { label: "Last Month", value: [dayjs().add(-1, "month"), dayjs()] },
    { label: "Last 6 Month", value: [dayjs().add(-6, "month"), dayjs()] },
    { label: "Last Year", value: [dayjs().add(-1, "year"), dayjs()] },
  ];
  if(loading)
  {
    return <Loader />
  }

  return (
    <>
      <div className="common_layout content-container">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">Rider's Wallet</h1>

            <BreadCrumb detailpage={riderData?.name} />
          </Col>
        </Row>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Card>
              <Row style={{ display: "flex", alignItems: "center" }}>
                <Col lg={3}>
                  <Avatar
                    size={150}
                    style={{ background: "lightblue" }}
                    icon={
                      userData && userData.profile_image ? (
                        <img
                          src={userData.profile_image}
                          // crossOrigin="anonymous"
                        />
                      ) : (
                        <UserOutlined />
                      )
                    }
                  />
                </Col>
                <Col style={{ margin: "0" }} lg={16}>
                  <h1
                    style={{
                      marginBottom: "0px",
                      marginTop: "0px",
                      fontSize: "30px",
                      fontWeight: 600,
                    }}
                  >
                    {riderData?.name}
                  </h1>
                  <span className="total">{riderData?.city_id?.name}</span>
                  <br />
                  <span className="total">{riderData?.number}</span>
                </Col>

                {/* <Col>
                    <H2 style={{ marginBottom: "-4px" }}>
                      <Avatar
                        size={80}
                        style={{ background: "#FFEE4B", marginRight: 10 }}
                        icon={<WalletOutlined />}
                      />
                      GAuto Balance
                    </H2>
                  </Col> */}
                <Col className="marginVerticle_auto" lg={4}>
                  <span
                    className="gautoCash"
                    style={{ float: "right" }}
                  >{`\u20B9${wallet ? wallet?.amount?.toFixed(2) : 0}`}</span>
                </Col>
              </Row>
            </Card>
            <Card className="histotyTital">
              <Row>
                {/* <Col
                    className="marginVerticle_auto"
                    xxl={10}
                    lg={10}
                    md={12}
                    sm={24}
                    xs={24}
                  >
                    <span>
                      <HistoryOutlined /> Transactions History
                    </span>
                  </Col> */}
                <Col xxl={11} lg={14} md={12} sm={24} xs={24}>
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ float: "right" }}
                  >
                    <Select
                      style={{ marginLeft: 0, width: "15vw" }}
                      placeholder="Transaction Type"
                      onSelect={(value) => handleTransactionType(value)}
                      allowClear
                      onClear={() => handleTransactionType("")}
                      value={transactionType == "" ? null : transactionType}
                    >
                      <Select.Option value="top-up">Added</Select.Option>
                      <Select.Option value="debit from wallet">
                        Withdrawals
                      </Select.Option>

                      <Select.Option value="Penalty">Penalty</Select.Option>
                    </Select>

                    {/* <Button
                        style={{ height: "48px" }}
                        onClick={() => setToggle(!toggle)}
                      >
                        <img src="https://api.iconify.design/noto-v1/bar-chart.svg?width=30&height=40" />
                      </Button> */}
                  </div>
                  <div
                    className="product-list-action d-flex justify-content-between align-items-center"
                    // style={{ float: "right" }}
                  >
                    {/* <Select
                        style={{ marginLeft: 10 }}
                        onSelect={(value) => handleTransactionDate(value)}
                        value={transactionDate}
                      >
                        <Select.Option value="lastweek">Last Week</Select.Option>
                        <Select.Option value="lastmonth">
                          Last Month
                        </Select.Option>
                        <Select.Option value="last6month">
                          Last 6 Month
                        </Select.Option>
                        <Select.Option value="lastyear">Last Year</Select.Option>
                      </Select> */}
                    <RangePicker
                      presets={rangePresets}
                      onChange={onRangeChange}
                      defaultValue={[fromDate, toDate]}

                      // style={{display:"flex"}}
                    />
                  </div>
                  {/* <span
                      className="resetFilter"
                      style={{ float: "right", marginTop: "14px" }}
                      onClick={() => {
                         setTransactionType("");
                      }}
                    >
                      Reset
                    </span> */}
                </Col>
                <Col lg={12}>
                  {/* <Select
                    style={{ width: "10vw", float: "right" }}
                    placeholder={"Select Year"}
                    value={selectedYear}
                    onSelect={(value) => handleSelectYear(value)}
                  >
                    {years.map((year) => {
                      return (
                        <Select.Option value={year} key={year}>
                          {year}{" "}
                        </Select.Option>
                      );
                    })}
                  </Select> */}
                </Col>
              </Row>
            </Card>
            <Card style={{ height: "600px" }} id="wallet">
              <Row gutter={20}>
                <Col xxl={12} lg={12} md={12} sm={24} xs={24}>
                  <Card style={{ overflowY: "scroll", height: "520px" }}>
                    <>
                      {transactionHistory &&
                        transactionHistory?.list?.map((transaction) => {
                          const date = moment(transaction.created_at).format(
                            "YYYY-MM-DD hh:mm:ss"
                          );
                          // console.log("date", date);
                          return (
                            <Card
                              style={{ marginBottom: 10 }}
                              className="shadowCardBox"
                              key={transaction._id}
                            >
                              <Row>
                                <Col
                                  xxl={2}
                                  xl={2}
                                  lg={2}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Avatar
                                    size={50}
                                    style={{
                                      background: "#60B96C",
                                      marginRight: 10,
                                    }}
                                    icon={<WalletOutlined />}
                                  />
                                </Col>
                                <Col
                                  className="marginVerticle_auto"
                                  xxl={18}
                                  xl={18}
                                  lg={13}
                                  md={18}
                                  xs={11}
                                >
                                  <H6
                                    style={{
                                      marginBottom: "0px",
                                      marginTop: "0px",
                                    }}
                                  >
                                    {transaction &&
                                      transaction.transactionType
                                        ?.charAt(0)
                                        .toUpperCase() +
                                        transaction.transactionType?.slice(1)}
                                  </H6>
                                  <span className="timing">{date}</span>
                                  <br />
                                  <span style={{ fontSize: "12px" }}>
                                    {" "}
                                    {transaction?.description}{" "}
                                  </span>
                                </Col>

                                <Col
                                  className="marginVerticle_auto"
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                >
                                  <span
                                    className={
                                      transaction.type == "credit"
                                        ? "gautoCash1"
                                        : "gautoCash2"
                                    }
                                    style={{ float: "right" }}
                                  >{` ${
                                    transaction.type == "credit" ? "+" : "-"
                                  } \u20B9${transaction?.amount.toFixed(
                                    2
                                  )}`}</span>
                                  <br />
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      float: "right",
                                    }}
                                  >
                                    {transaction.afterWalletBalance.toFixed(2)}
                                  </span>
                                </Col>
                              </Row>
                            </Card>
                          );
                        })}
                      {transactionHistory?.total_counts == 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            height: "100px",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "20px",
                          }}
                        >
                          No Transaction History Found
                        </div>
                      )}
                    </>
                  </Card>

                  {transactionHistory?.total_counts > 9 && (
                    <Pagination
                      defaultCurrent={pageNo}
                      total={transactionHistory?.total_counts}
                      style={{ float: "right" }}
                      onChange={(page) => onPageChange(page)}
                      pageSizeOptions={[10, 20, 50, 100]}
                      showSizeChanger
                      // onPageChange={(e)=>onPageChange(e)}
                      // onShowSizeChange={onShowSizeChange}
                    />
                  )}
                </Col>

                <Col xxl={12} lg={12} md={12} sm={24} xs={24}>
                  {/* <Card>
                    <>
                      <Row
                        style={{ textAlign: "center", marginBottom: "50px" }}
                      >
                        <Col span={12}></Col>
                      </Row>

                      <ChartjsBarChart
                        id="grossProfit"
                        datasets={[
                          {
                            label: "Earning",
                            data:
                              Array.isArray(walletChart) && walletChart
                                ? walletChart.map((data) => data.totalEarning)
                                : [],

                            backgroundColor: "#FFEE4B",
                          },
                        ]}
                        height={190}
                      />
                    </>
                  </Card> */}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RiderWallet;
