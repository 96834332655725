import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const { getFareList, getFarebyId } = actions;

export const getAllFareList = (page, limit, city, vehicle, serviceAvailble) => {
  let finaldata = "";
  let finalVehicles = "";
  city !== "" && city?.map((data) => (finaldata = finaldata + "&city=" + data));
  vehicle !== "" &&
    vehicle?.map(
      (data) => (finalVehicles = finalVehicles + "&vehicleType=" + data)
    );
  return async (dispatch) => {
    const queryParameter =
      "?page=" +
      page +
      "&limit=" +
      limit +
      finaldata +
      finalVehicles +
      "&isServiceAvailable=" +
      serviceAvailble;
    const resp = await DataService.get(
      API.mastermanageFare.getAll + queryParameter
    );

    if (!resp.data.error) {
      dispatch(getFareList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getFareListById = (id) => {
  return async (dispatch) => {
    const resp = await DataService.get(API.mastermanageFare.getById + id);

    if (!resp.data.error) {
      dispatch(getFarebyId(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const addFare = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.mastermanageFare.add, payload);
    if (!resp.error) {
      return true;
    } else {
      // message.error(resp.data.message);
      return false;
    }
  };
};

export const removeFare = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.mastermanageFare.delete + id);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const updateFare = (formdata, id) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.mastermanageFare.update + id,
      formdata
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const updateFareServiceAvailable = (formdata, id) => {
  console.log(formdata);
  return async (dispatch) => {
    const resp = await DataService.put(
      API.mastermanageFare.updateSericeAvailable + id,
      formdata
    );
    if (!resp.data.error) {
      message.success("Service Available Updated Succsessfully");
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
