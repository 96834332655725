import actions from "./actions";
import { message } from "antd";
import { API } from "../../../config/api";
import { DataService } from "../../../config/dataService/dataService";

const { getProfileDeleteReasons, getDriverRejectReasons } = actions;

export const getProfileDeleteReasonsData = (page, limit) => {
  return async (dispatch) => {
    const queryParamater = "?page=" + page + "&limit=" + limit;

    const resp = await DataService.get(
      API.profileDeleteReason.get + queryParamater
    );

    if (!resp.data.error) {
      dispatch(getProfileDeleteReasons(resp.data.data));
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const addProfileDeleteReasons = (payload) => {
  return async () => {
    const resp = await DataService.post(API.profileDeleteReason.add, payload);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const updateProfileDeleteReasons = (payload, id) => {
  // console.log("update role payload", id, payload);
  return async (dispatch) => {
    const resp = await DataService.put(
      API.profileDeleteReason.update + id,
      payload
    );
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const deleteProfileDeleteReasons = (id) => {
  // console.log("update role payload", id, payload);
  return async (dispatch) => {
    const resp = await DataService.delete(API.profileDeleteReason.delete + id);
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getDriverRejectReasonsData = (page, limit) => {
  return async (dispatch) => {
    const queryParamater = "?page=" + page + "&limit=" + limit;

    const resp = await DataService.get(
      API.driverRejectReason.get + queryParamater
    );

    if (!resp.data.error) {
      dispatch(getDriverRejectReasons(resp.data.data));
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const addDriverRejectReasons = (payload) => {
  return async () => {
    const resp = await DataService.post(API.driverRejectReason.add, payload);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const updateDriverRejectReasons = (payload, id) => {
  // console.log("update role payload", id, payload);
  return async (dispatch) => {
    const resp = await DataService.put(
      API.driverRejectReason.update + id,
      payload
    );
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const deleteDriverRejectReasons = (id) => {
  // console.log("update role payload", id, payload);
  return async (dispatch) => {
    const resp = await DataService.delete(API.driverRejectReason.delete + id);
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
