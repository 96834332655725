import actions from "./actions";

const { GET_ABOUT_US_CONTENT, UPDATE_CONTENT } = actions;

const initState = {
  content: {},
  newContent: {},
};

const AboutUsReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_ABOUT_US_CONTENT:
      return {
        ...state,
        content: data,
      };
    case UPDATE_CONTENT:
      return {
        ...state,
        newContent: data,
      };
    default:
      return state;
  }
};
export default AboutUsReducer;
