import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useMatch } from "react-router-dom";

import { getCityData } from "../../redux/Masters/City/actionCreatore";
import { getVehicleTypesData } from "../../redux/VehicleTypes/actionCreatore";
import { getItem } from "../../utility/localStorageControl";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";

import { ArrowRightOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { FixedToNDecimals } from "../../utility/commonFunction";
import Map from "./Map";
import { addFare } from "../../redux/manageFare/actionCreatore";
const { Panel } = Collapse;
const ManageFareAdd = () => {
  const [dayEndTime, setDayEndTime] = useState("23:00");
  const [nightEndTime, setNightEndTime] = useState("06:00");
  const [freeWaitingTime, setfreeWaitingTime] = useState("00:00:00");
  const [waitingTime, setWaitingTime] = useState("00:00:00");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_details = getItem("user_details");
  const role = user_details.role;
  // const path = useMatch();
  // console.log(path.path.split("/"), "hhi");
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [cityLocation, setCityLocation] = useState([]);
  const [points, setPoints] = useState([]);
  const [airportMap, setAirportMap] = useState(false);
  const [airportLoationPoints, setAirportLocationPoints] = useState([]);
  const [railwayLoationPoints, setRailwayLocationPoints] = useState([]);
  const [showOkButton, setShowOkButton] = useState(false);
  const [dayToKmError, setDayToKmErrro] = useState(false);
  const [nightToKmError, setNightToKmErrro] = useState(false);
  const [dayToKmErrorIndex, setDayToKmErrorIndex] = useState(null);
  const [nightToKmErrorIndex, setNightToKmErrorIndex] = useState(null);

  useEffect(() => {
    reteriveCityList();
    fetchVehicleTypes();
  }, []);

  const reteriveCityList = async () => {
    await dispatch(getCityData(""));
  };
  let cityList = useSelector((state) => state.city.cityData);
  cityList = cityList.sort((a, b) => a.name.localeCompare(b.name));
  // console.log("cityList", cityList);
  const fetchVehicleTypes = async () => {
    await dispatch(getVehicleTypesData());
  };

  const vehicleTypes = useSelector((state) => state.vehicleType.vehicleData);

  const initialValues = {
    addFareMasterArray: [
      {
        fromKm: "",
        toKm: "",
        dayBaseFare: "",
        dayFarePerKm: "",
        dayFarePerMinute: "",
        nightBaseFare: "",
        nightFarePerKm: "",
        nightFarePerMinute: "",
      },
    ],
    cancellation_charges: "",
    airport_charges: "",
    waiting_charges: "",
    parking_charges: "",
    MinGcashCommission: "",
    // vehicle_type_id: "",
    // city_id: "",
    day_start_time: "06:00",
    night_start_time: "23:00",
    app_service_charge: "",
    call_centre_service_charges: "",
    free_waiting_time: "00:00:00",
    waiting_time: "00:00:00",
    // day_min_km: "",
    // day_min_fare: "",
    // night_min_km: "",
    // night_min_fare: "",
    // day_fare_per_km: "",
    // night_fare_per_km: "",
  };

  const handleSelectCity = (cityData) => {
    const data = JSON.parse(cityData);
    setCityLocation(data.location.coordinates);
  };
  const data = [
    {
      key: 1,
      name: "Tier 1",
      radius: 1500,
      from: 0.0,
      to: 1.5,
    },
    {
      key: 2,
      name: "Tier 2",
      radius: 1500,
      from: 1.5,
      to: 3.0,
    },
  ];
  const [tierData, setTierData] = useState(data);

  const handleOnChange = (key, value) => {
    if (key == 1) {
      data[key - 1].radius = parseInt(value);
      data[key - 1].to = value / 1000;
      data[key].from = value / 1000;
      data[key].to = (parseInt(value) + parseInt(tierData[key].radius)) / 1000;
      data[key].radius = tierData[key].radius;

      // data[key - 1].to = value / 1000;
      // data[key].from = value / 1000;
      // data[key].to =  value / 1000  ;
      setTierData(data);
    }
    if (key == 2) {
      data[key - 2].radius = tierData[key - 2].radius;
      data[key - 2].to = tierData[key - 2].to;
      data[key - 1].from = tierData[key - 2].to;
      data[key - 1].radius = parseInt(value);
      data[key - 1].to =
        (parseInt(tierData[key - 2].radius) + parseInt(value)) / 1000;
      setTierData(data);
    }
  };
  const getPoints = (msg) => {
    // const finalPoints = points.map((latLng) => ({
    //   lat: latLng.lat(),
    //   lng: latLng.lng(),
    // }));
    // let allPoints=[];
    console.log("points", points);
    let finalPoints = points.map((point, index) => {
      return point.map((latLng) => ({
        lat: latLng.lat(),
        lng: latLng.lng(),
      }));
      //  console.log("pointttt",point);
    });
    console.log("allpoints", finalPoints);
    console.log(finalPoints);
    if (finalPoints[0].length < 3) {
      message.error("Minimium 1 area required");
    } else {
      if (msg == "airport") {
        setAirportMap(false);
        setAirportLocationPoints(finalPoints);
        form.setFieldsValue({
          airport_location: points,
        });
      } else {
        setVisible(false);
        setRailwayLocationPoints(finalPoints);
        form.setFieldsValue({
          railway_station_location: points,
        });
      }
    }
  };
  const getSelectedPoints = (data) => {
    // console.log("selected points", data);
    setPoints(data);
  };
  const dataNight = [
    {
      key: 1,
      name: "Tier 1",
      radius: 1500,
      from: 0.0,
      to: 1.5,
    },
    {
      key: 2,
      name: "Tier 2",
      radius: 1500,
      from: 1.5,
      to: 3.0,
    },
  ];
  const [tierDataNight, setTierDataNight] = useState(dataNight);

  const handleOnChangeNight = (key, value) => {
    if (key == 1) {
      dataNight[key - 1].radius = parseInt(value);
      dataNight[key - 1].to = value / 1000;
      dataNight[key].from = value / 1000;
      dataNight[key].to =
        (parseInt(value) + parseInt(tierDataNight[key].radius)) / 1000;
      dataNight[key].radius = tierDataNight[key].radius;

      // data[key - 1].to = value / 1000;
      // data[key].from = value / 1000;
      // data[key].to =  value / 1000  ;
      setTierDataNight(dataNight);
    }
    if (key == 2) {
      dataNight[key - 2].radius = tierDataNight[key - 2].radius;
      dataNight[key - 2].to = tierDataNight[key - 2].to;
      dataNight[key - 1].from = tierDataNight[key - 2].to;
      dataNight[key - 1].radius = parseInt(value);
      dataNight[key - 1].to =
        (parseInt(tierDataNight[key - 2].radius) + parseInt(value)) / 1000;
      setTierDataNight(dataNight);
    }
  };

  let fareColumnData = [
    {
      key: 0,
      fromKm: 0,
      toKm: 3,
      baseFare: "",
      facilityFee: "",
      captainCommission: "",
      adminCommission: "",
      row: 0,
    },
    {
      key: 1,
      fromKm: 3,
      toKm: 100000,
      farePerKm: "",
      farePerMinute: "",
      facilityFee: "",
      captainCommission: "",
      adminCommission: "",
      row: 1,
    },
  ];
  let NightFareColumnData = [
    {
      key: 0,
      fromKm: 0,
      toKm: 3,
      baseFare: "",
      facilityFee: "",
      captainCommission: "",
      adminCommission: "",
      row: 0,
    },
    {
      key: 1,
      fromKm: 3,
      toKm: 100000,
      farePerKm: "",
      farePerMinute: "",
      facilityFee: "",
      captainCommission: "",
      adminCommission: "",
      row: 1,
    },
  ];
  const [fareData, setFareData] = useState(fareColumnData);
  const [nightFareData, setNightFareData] = useState(NightFareColumnData);

  const handleChangeToKm = (data, value, index) => {
    if (value <= 100000) {
      if (value > data.fromKm) {
        setDayToKmErrro(false);
        const allData = [...fareData];
        const updateData = allData[data.row];

        updateData.toKm = parseFloat(value);

        allData[data.row] = updateData;
        if (fareData.length - 1 > data.row) {
          const nextRowData = allData[data.row + 1];
          nextRowData.fromKm = parseFloat(value);
          allData[data.row + 1] = nextRowData;
          // console.log("upadte next data", allData);
        }
        setFareData(allData);
      } else {
        setDayToKmErrro(true);
        setDayToKmErrorIndex(index);

        message.info(
          <div>
            <strong>To Km </strong> value should be greather than{" "}
            <strong>{data.fromKm}</strong>
          </div>
        );
      }
    } else {
      setDayToKmErrro(true);
      setDayToKmErrorIndex(index);
      message.info(
        <div>
          <strong>To Km </strong> value should not greather than{" "}
          <strong>100000</strong>
        </div>
      );
    }
  };
  // console.log("fareData", fareData);
  const removeFareRow = (value) => {
    // console.log("values", value);
    let data = fareData.filter((d) => d.key != value.key);
    setFareData(data);
  };
  const addRowInFareTable = () => {
    const length = fareData.length;
    // console.log("faredaar", fareData);
    const getToKm = fareData[length - 1].toKm;

    if (getToKm == 100000) {
      message.info(
        <div>
          You can't add row until<strong> To Km</strong> is less than
          <strong> 100000</strong>
        </div>
      );
    } else {
      setFareData([
        ...fareData,
        {
          key: length,
          fromKm: fareData[length - 1].toKm,
          toKm: 100000,
          farePerKm: "",
          farePerMinute: "",
          facilityFee: "",
          adminCommission: "",
          captainCommission: "",
          row: length,
        },
      ]);
    }
  };

  const handleChangeToKmNight = (data, value, index) => {
    // console.log("data", data);
    // console.log("fareData", fareData);
    if (value <= 100000) {
      if (value > data.fromKm) {
        setNightToKmErrro(false);

        const allData = [...nightFareData];
        const updateData = allData[data.row];

        updateData.toKm = parseFloat(value);

        allData[data.row] = updateData;
        if (nightFareData.length - 1 > data.row) {
          const nextRowData = allData[data.row + 1];
          nextRowData.fromKm = parseFloat(value);
          allData[data.row + 1] = nextRowData;
          // console.log("upadte next data", allData);
        }
        setNightFareData(allData);
      } else {
        setNightToKmErrro(true);
        setNightToKmErrorIndex(index);

        message.info(
          <div>
            <strong>To Km </strong> value should be greather than{" "}
            <strong>{data.fromKm}</strong>
          </div>
        );
      }
    } else {
      setNightToKmErrro(true);
      setNightToKmErrorIndex(index);
      message.info(
        <div>
          <strong>To Km </strong> value should not greather than{" "}
          <strong>100000</strong>
        </div>
      );
    }
  };
  // console.log("fareData", fareData);
  const removeFareRowNight = (value) => {
    // console.log("values", value);
    let data = nightFareData.filter((d) => d.key != value.key);
    setNightFareData(data);
  };
  const addRowInFareTableNight = () => {
    const length = nightFareData.length;
    // console.log("faredaar", fareData);
    const getToKm = nightFareData[length - 1].toKm;

    if (getToKm == 100000) {
      message.info(
        <div>
          You can't add row until<strong> To Km</strong> is less than
          <strong> 100000</strong>
        </div>
      );
    } else {
      setNightFareData([
        ...nightFareData,
        {
          key: length,
          fromKm: nightFareData[length - 1].toKm,
          toKm: 100000,
          farePerKm: "",
          farePerMinute: "",
          facilityFee: "",
          adminCommission: "",
          captainCommission: "",
          row: length,
        },
      ]);
    }
  };

  const handleChangeCharges = (value, time, index, updatedkey) => {
    // console.log(value, time, index, updatedkey);
    if (time === "day") {
      const allData = [...fareData];
      if (updatedkey == "baseFare") allData[index].baseFare = value;
      if (updatedkey == "adminCommission")
        allData[index].adminCommission = value;
      if (updatedkey == "captainCommission")
        allData[index].captainCommission = value;

      if (updatedkey == "facilityFee") allData[index].facilityFee = value;
      if (updatedkey == "farePerKm") allData[index].farePerKm = value;
      if (updatedkey == "farePerMinute") allData[index].farePerMinute = value;

      // console.log("allData", allData);
      setFareData(allData);
    }
    if (time === "night") {
      const allData = [...nightFareData];
      if (updatedkey == "baseFare") allData[index].baseFare = value;
      if (updatedkey == "adminCommission")
        allData[index].adminCommission = value;
      if (updatedkey == "captainCommission")
        allData[index].captainCommission = value;

      if (updatedkey == "facilityFee") allData[index].facilityFee = value;
      if (updatedkey == "farePerKm") allData[index].farePerKm = value;
      if (updatedkey == "farePerMinute") allData[index].farePerMinute = value;

      // console.log("allData", allData);
      setNightFareData(allData);
    }
  };
  // console.log("fareData", fareData);

  const handleSubmit = async (values) => {
    console.log("faredata");
    console.table(fareData);

    if (fareData[fareData.length - 1].toKm !== 100000) {
      setDayToKmErrro(true);
      message.error(
        <div>
          Last Value Of To<b> Km</b> in Day Charges should be <b>100000</b>
        </div>
      );
      setDayToKmErrro(false);
    }
    if (nightFareData[nightFareData.length - 1].toKm !== 100000) {
      setNightToKmErrro(true);
      message.error(
        <div>
          Last Value Of To<b> Km</b> in Night Charges should be <b>100000</b>
        </div>
      );
      setNightToKmErrro(false);
    }
    if (
      fareData[fareData.length - 1].toKm == 100000 &&
      nightFareData[nightFareData.length - 1].toKm == 100000
    ) {
      const city = JSON.parse(values.city_id);
      const tier1 = (({ name, radius }) => ({ name, radius }))(tierData[0]);
      tier1.shift = "day";
      const tier2 = (({ name, radius }) => ({ name, radius }))(tierData[1]);
      tier2.shift = "day";
      const tier3 = (({ name, radius }) => ({ name, radius }))(
        tierDataNight[0]
      );
      const tier4 = (({ name, radius }) => ({ name, radius }))(
        tierDataNight[1]
      );
      tier3.shift = "night";
      tier4.shift = "night";
      values.tierDetails = [tier1, tier2, tier3, tier4];
      values.day_start_time = nightEndTime;
      values.night_start_time = dayEndTime;
      // values.free_waiting_time = freeWaitingTime;
      // values.waiting_time = waitingTime;
      values.city_id = city._id;
      values.airport = {
        charges: values.airport_charges,
        locations: airportLoationPoints,
      };
      values.railway_station = {
        charges: values.railway_charges,
        locations: railwayLoationPoints,
      };
      let fDayData = fareData;
      const finalFareData = fDayData.map(({ row, key, ...rest }) => {
        return rest;
      });
      let allNightData = nightFareData;
      const finalNightFareData = allNightData.map(({ row, key, ...rest }) => {
        return rest;
      });

      delete values.airport_charges;
      delete values.railway_charges;
      delete values.railway_station_location;
      delete values.airport_location;
      values.dayFareMasterArray = finalFareData;
      values.nightFareMasterArray = finalNightFareData;

      console.log("values", values);
      const resp = await dispatch(addFare(values));
      if (resp) {
        message.success("Fare added successfully");
        form.resetFields();
        navigate(-1);
      }
    }
  };
  var columns = [
    {
      title: "Sr.No.",
      key: "_id",
      render: (data, index, id) => {
        return <span>{id + 1}</span>;
      },
      align: "center",
    },
    {
      title: "Tier Label",
      key: "tier",

      render: (data) => {
        return <span> {data.name}</span>;
      },

      align: "center",
    },
    {
      title: "Radius (Meters)",
      key: "radius",
      render: (data) => {
        return (
          <span>
            {" "}
            <Input
              defaultValue={parseInt(data.radius)}
              style={{ width: "15vw", border: "" }}
              onChange={(e) => handleOnChange(data.key, e.target.value)}
              min={1500}
              max={5000}
              step={100}
              type="number"
              onKeyDown={(evnt) => evnt.preventDefault()}
              onWheel={(e) => e.currentTarget.blur()}
            />
          </span>
        );
      },
      editable: true,
      align: "center",
    },
    {
      title: "Distance ",
      key: "distance",
      render: (data) => {
        return (
          <span>
            {FixedToNDecimals(data.from)} Km <ArrowRightOutlined />{" "}
            {FixedToNDecimals(data.to)} Km
          </span>
        );
      },

      align: "center",
    },
  ];
  var fareColumn = [
    {
      title: "From Km",
      dataIndex: "fromKm",
      key: "fromKm",
      render: (data, index, index1) => {
        return (
          <Input
            type="number"
            style={{ textAlign: "center" }}
            disabled
            value={data}
            step={"any"}
          />
        );
      },
      align: "center",
    },
    {
      title: "To Km",
      key: "toFromKm",
      render: (data, index, index1) => {
        return (
          <Input
            type="number"
            onChange={(e) => {
              handleChangeToKm(data, e.target.value, index1);
            }}
            style={{
              width: "100px",

              textAlign: "center",
              border:
                dayToKmError && dayToKmErrorIndex == index1
                  ? "1px solid red"
                  : "",
            }}
            defaultValue={data?.toKm ? data?.toKm : 100000}
            min={data.fromKm + 1}
            max={
              fareData[index1 + 1]?.toKm
                ? fareData[index1 + 1]?.toKm - 1
                : 100000
            }
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            step={"any"}
          />
        );
      },
      align: "center",
    },

    {
      title: "Base Fare",
      key: "baseFare",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            placeholder={index >= 1 ? "Not Applicable" : ""}
            step={"any"}
            onChange={(e) =>
              handleChangeCharges(e.target.value, "day", index, "baseFare")
            }
            disabled={index >= 1}
            min={0}
            required
          />
        );
      },
      align: "center",
    },
    {
      title: "Rs/Km",
      key: "farePerKm",

      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            step={"any"}
            onChange={(e) =>
              handleChangeCharges(e.target.value, "day", index, "farePerKm")
            }
            style={{ textAlign: "center" }}
            placeholder={index < 1 ? "Not Applicable" : ""}
            disabled={index < 1}
            min={0}
            required
          />
        );
      },
      align: "center",
    },
    {
      title: "Rs/Min",
      key: "farePerMinute",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            step={"any"}
            onChange={(e) =>
              handleChangeCharges(e.target.value, "day", index, "farePerMinute")
            }
            style={{ textAlign: "center" }}
            placeholder={index < 1 ? "Not Applicable" : ""}
            disabled={index < 1}
            min={0}
            required
          />
        );
      },
      align: "center",
    },
    {
      title: (
        <div>
          <span>
            Facility Fee (₹)
            {/* <img src="https://api.iconify.design/mingcute/currency-rupee-fill.svg?color=purple&height=20&width=25" /> */}
          </span>
        </div>
      ),
      key: "facilityFee",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            style={{ textAlign: "center" }}
            step={"any"}
            onChange={(e) =>
              handleChangeCharges(e.target.value, "day", index, "facilityFee")
            }
            min={0}
            required
          />
        );
      },
      align: "center",
    },
    {
      title: (
        <div>
          <span>
            Captain Fee (₹)
            {/* <img src="https://api.iconify.design/mingcute/currency-rupee-fill.svg?color=purple&height=20&width=25" /> */}
          </span>
        </div>
      ),
      key: "captainCommission",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            style={{ textAlign: "center" }}
            step={"any"}
            onChange={(e) =>
              handleChangeCharges(
                e.target.value,
                "day",
                index,
                "captainCommission"
              )
            }
            defaultValue={data.captainCommission}
            min={0}
            required
          />
        );
      },
      align: "center",
    },
    {
      title: "G-Auto Commission (%) ",
      key: "adminCommission",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            style={{ textAlign: "center" }}
            step={"any"}
            onChange={(e) =>
              handleChangeCharges(
                e.target.value,
                "day",
                index,
                "adminCommission"
              )
            }
            defaultValue={data.adminCommission}
            min={0}
            required
          />
        );
      },
      align: "center",
    },

    {
      title: "",
      render: (data, i, index) => {
        return (
          index > 1 &&
          fareData.length - 1 == index && (
            <Button type="primary" danger onClick={() => removeFareRow(data)}>
              Remove
            </Button>
          )
        );
      },
    },
  ];
  var nightFareColumn = [
    {
      title: "From Km",
      dataIndex: "fromKm",
      key: "fromKm",
      render: (data, index, index1) => {
        return (
          <Input
            type="number"
            style={{ textAlign: "center" }}
            disabled
            value={data}
            step={"any"}
          />
        );
      },
      align: "center",
    },
    {
      title: "To Km",
      key: "toFromKm",
      render: (data, index, index1) => {
        return (
          <Input
            type="number"
            onChange={(e) => {
              handleChangeToKmNight(data, e.target.value, index1);
            }}
            style={{
              width: "100px",
              textAlign: "center",
              border:
                nightToKmError && nightToKmErrorIndex == index1
                  ? "1px solid red"
                  : "",
            }}
            defaultValue={data?.toKm ? data?.toKm : 100000}
            min={data.fromKm + 1}
            max={
              nightFareData[index1 + 1]?.toKm
                ? nightFareData[index1 + 1]?.toKm - 1
                : 100000
            }
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            step={"any"}
          />
        );
      },
      align: "center",
    },

    {
      title: "Base Fare",
      key: "baseFare",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            style={{ textAlign: "center" }}
            placeholder={index >= 1 ? "Not Applicable" : ""}
            step={"any"}
            onChange={(e) =>
              handleChangeCharges(e.target.value, "night", index, "baseFare")
            }
            disabled={index >= 1}
            min={0}
            required
          />
        );
      },
      align: "center",
    },
    {
      title: "Rs/Km",
      key: "farePerKm",

      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            step={"any"}
            onChange={(e) =>
              handleChangeCharges(e.target.value, "night", index, "farePerKm")
            }
            style={{ textAlign: "center" }}
            placeholder={index < 1 ? "Not Applicable" : ""}
            disabled={index < 1}
            min={0}
            required
          />
        );
      },
      align: "center",
    },
    {
      title: "Rs/Min",
      key: "farePerMinute",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            step={"any"}
            onChange={(e) =>
              handleChangeCharges(
                e.target.value,
                "night",
                index,
                "farePerMinute"
              )
            }
            style={{ textAlign: "center" }}
            placeholder={index < 1 ? "Not Applicable" : ""}
            disabled={index < 1}
            min={0}
            required
          />
        );
      },
      align: "center",
    },
    {
      title: "Facility Fee (₹)",
      key: "facilityFee",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            style={{ textAlign: "center" }}
            step={"any"}
            onChange={(e) =>
              handleChangeCharges(e.target.value, "night", index, "facilityFee")
            }
            min={0}
            required
          />
        );
      },
      align: "center",
    },
    {
      title: "Captain Fee (₹)",
      key: "captainCommission",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            style={{ textAlign: "center" }}
            step={"any"}
            onChange={(e) =>
              handleChangeCharges(
                e.target.value,
                "night",
                index,
                "captainCommission"
              )
            }
            defaultValue={data.captainCommission}
            min={0}
            required
          />
        );
      },
      align: "center",
    },
    {
      title: "G-Auto Commission (%) ",
      key: "adminCommission",
      render: (data, i, index) => {
        return (
          <Input
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            style={{ textAlign: "center" }}
            step={"any"}
            onChange={(e) =>
              handleChangeCharges(
                e.target.value,
                "night",
                index,
                "adminCommission"
              )
            }
            defaultValue={data.baseFare}
            min={0}
            required
          />
        );
      },
      align: "center",
    },

    {
      title: "",
      render: (data, i, index) => {
        return (
          index > 1 &&
          nightFareData.length - 1 == index && (
            <Button
              type="primary"
              danger
              onClick={() => removeFareRowNight(data)}
            >
              Remove
            </Button>
          )
        );
      },
    },
  ];

  var columnsNight = [
    {
      title: "Sr.No.",
      key: "_id",
      render: (data, index, id) => {
        return <span>{id + 1}</span>;
      },
      align: "center",
    },
    {
      title: "Tier Label",
      key: "tier",

      render: (data) => {
        return <span> {data.name}</span>;
      },

      align: "center",
    },
    {
      title: "Radius (Meters)",
      key: "radius",
      render: (data) => {
        return (
          <span>
            {" "}
            <Input
              defaultValue={parseInt(data.radius)}
              style={{ width: "15vw", border: "" }}
              onChange={(e) => handleOnChangeNight(data.key, e.target.value)}
              min={1500}
              max={5000}
              step={100}
              type="number"
              onKeyDown={(evnt) => evnt.preventDefault()}
              onWheel={(e) => e.currentTarget.blur()}
            />
          </span>
        );
      },
      editable: true,
      align: "center",
    },
    {
      title: "Distance ",
      key: "distance",
      render: (data) => {
        return (
          <span>
            {FixedToNDecimals(data.from)} Km <ArrowRightOutlined />{" "}
            {FixedToNDecimals(data.to)} Km
          </span>
        );
      },

      align: "center",
    },
  ];
  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">Add Fare</h1>

            {/* <p> Here You Can Add Fare City and Vehicle Type wise</p> */}
            <BreadCrumb />
          </Col>
        </Row>
        <Divider />
        <Card>
          <Form
            layout="vertical"
            form={form}
            initialValues={initialValues}
            onFinish={handleSubmit}
          >
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  label="City"
                  name={"city_id"}
                  rules={[
                    {
                      required: true,
                      message: "Please Choose City",
                    },
                  ]}
                >
                  <Select
                    placeholder="Choose city"
                    showSearch
                    onSelect={(e) => handleSelectCity(e)}
                  >
                    {cityList.length > 0 &&
                      cityList.map((data) => {
                        return (
                          <Select.Option
                            value={JSON.stringify(data)}
                            key={data._id}
                          >
                            {data.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Vehicle Type"
                  name={"vehicle_type_id"}
                  rules={[
                    {
                      required: true,
                      message: "Please Choose Vehicle Type",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={"Select Vehicle Type"}
                  >
                    {vehicleTypes &&
                      vehicleTypes.length > 0 &&
                      vehicleTypes.map((type) => {
                        return (
                          <Select.Option value={type._id}>
                            {type.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <br />
            <Collapse defaultActiveKey={"1"}>
              <Panel
                header="Day Timing & Charges"
                key={"1"}
                style={{ background: "#77bff9" }}
              >
                <Row gutter={20}>
                  <Col lg={6} md={6} sm={24}>
                    <Form.Item
                      label="Start Time (HH:MM)"
                      name={"day_start_time"}
                      rules={[
                        {
                          required: true,
                          message: "Please Choose Day Start Time",
                        },
                      ]}
                    >
                      {/* <TimePicker
                        onChange={(e, timestring) =>
                          setNightEndTime(timestring)
                        }
                        format={"HH:mm"}
                        style={{ padding: 8 }}
                        size={5}
                        suffixIcon={<DownOutlined />}
                      /> */}
                      <Input
                        type="time"
                        style={{
                          width: "70%",
                          textAlign: "center",
                          borderRadius: "70px",
                          maxHeight: "40px",
                        }}
                        onChange={(e) => setNightEndTime(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={6} sm={24}>
                    <Form.Item
                      label={"End Time (HH:MM)"}
                      style={{
                        marginLeft: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{dayEndTime}</span>
                    </Form.Item>{" "}
                  </Col>
                </Row>

                <div>
                  <div style={{ minWidth: "850px" }}>
                    <Card style={{ border: "2px solid black" }}>
                      <Table
                        columns={fareColumn}
                        dataSource={fareData}
                        pagination={false}
                      />
                      <br />
                      <Button
                        style={{ width: "100%", textAlign: "center" }}
                        onClick={() => addRowInFareTable()}
                      >
                        Add New Row
                      </Button>
                    </Card>
                  </div>
                </div>
              </Panel>
            </Collapse>
            <br />

            <Collapse defaultActiveKey={"1"}>
              <Panel
                header="Night Timing & Charges"
                key={"1"}
                style={{ background: "#77bff9" }}
              >
                <Row gutter={20}>
                  <Col lg={6} md={6} sm={24}>
                    <Form.Item
                      label="Start Time (HH:MM)"
                      name={"night_start_time"}
                      rules={[
                        {
                          required: true,
                          message: "Please Choose Night Start Time",
                        },
                      ]}
                    >
                      {/* <TimePicker
          onChange={(e, timestring) => setDayEndTime(timestring)}
          style={{ padding: 8 }}
          format={"HH:mm"}
          suffixIcon={<DownOutlined />}
          defaultValue={dayjs("06:00", "HH:mm")}
          onSelect={(e) => console.log(e)}
        /> */}
                      <Input
                        type="time"
                        style={{
                          width: "70%",
                          textAlign: "center",
                          borderRadius: "70px",
                          maxHeight: "40px",
                        }}
                        onChange={(e) => setDayEndTime(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={6} sm={24}>
                    <Form.Item
                      label={"End Time (HH:MM)"}
                      style={{
                        marginLeft: "20px",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{nightEndTime}</span>
                    </Form.Item>{" "}
                  </Col>
                </Row>

                <div>
                  <div style={{ minWidth: "850px" }}>
                    <Card style={{ border: "2px solid black" }}>
                      <Table
                        columns={nightFareColumn}
                        dataSource={nightFareData}
                        pagination={false}
                      />
                      <br />
                      <Button
                        style={{ width: "100%", textAlign: "center" }}
                        onClick={() => addRowInFareTableNight()}
                      >
                        Add New Row
                      </Button>
                    </Card>
                  </div>
                </div>
              </Panel>
            </Collapse>

            <br />
            <Collapse defaultActiveKey={"2"}>
              <Panel
                header="Additional Charges"
                key={"2"}
                style={{ background: "#77bff9" }}
              >
                <Row gutter={20}>
                  <Col lg={8} md={8}>
                    <Form.Item
                      label="Free Waiting Time(Hour:Minute:Second)"
                      name="free_waiting_time"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please Enter Free Waiting Time(Hour:Minute:Second)",
                        },
                      ]}
                    >
                      <Input
                        type="time"
                        style={{
                          width: "70%",
                          textAlign: "center",
                          borderRadius: "70px",
                          maxHeight: "40px",
                        }}
                        step="1"
                        onChange={(e) => setfreeWaitingTime(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8}>
                    <Form.Item
                      label="Minimum Waiting Time(Hour:Minute:Second)"
                      name="waiting_time"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please Enter Minimum Waiting Time(Hour:Minute:Second)",
                        },
                      ]}
                    >
                      <Input
                        type="time"
                        style={{
                          width: "70%",
                          textAlign: "center",
                          borderRadius: "70px",
                          maxHeight: "40px",
                        }}
                        step="1"
                        onChange={(e) => setWaitingTime(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8}>
                    <Form.Item
                      label={
                        <div>
                          {"Waiting Fare/Minute "}
                          <img src="https://api.iconify.design/mingcute/currency-rupee-fill.svg?color=purple&height=20&width=25" />
                        </div>
                      }
                      name="waiting_charges"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Waiting Fare",
                        },
                      ]}
                    >
                      <Input placeholder="Waiting Fare" type="number" min={0} />
                    </Form.Item>
                  </Col>

                  <Col lg={8} md={8}>
                    <Form.Item
                      label={
                        <div>
                          {"Airport Charge "}
                          <img src="https://api.iconify.design/mingcute/currency-rupee-fill.svg?color=purple&height=20&width=25" />
                        </div>
                      }
                      name="airport_charges"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Airport Charge",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Airport Charge"
                        type="number"
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8}>
                    <Form.Item
                      label={
                        <div>
                          {"Railway Station Charge "}
                          <img src="https://api.iconify.design/mingcute/currency-rupee-fill.svg?color=purple&height=20&width=25" />
                        </div>
                      }
                      name="railway_charges"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Railway Station Charge",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Railway Station Charge"
                        type="number"
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8}>
                    <Form.Item
                      label={
                        <div>
                          {"Update Route Charge"}
                          <img src="https://api.iconify.design/mingcute/currency-rupee-fill.svg?color=purple&height=20&width=25" />
                        </div>
                      }
                      name="update_route_charges"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Update Route Charge",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Update Route Charge"
                        type="number"
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8}>
                    <Form.Item
                      label={
                        <div>
                          {"Airport Location  "}{" "}
                          <img src="https://api.iconify.design/iconoir/map.svg?height=20&width=25" />{" "}
                        </div>
                      }
                      name="airport_location"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Airport Location",
                        },
                      ]}
                    >
                      {/* <Button onClick={() => setVisible(true)}>
Choose Airport Location
</Button> */}
                      <TextArea
                        onClick={() => setAirportMap(true)}
                        onKeyDown={(e) => e.preventDefault()}
                      ></TextArea>
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8}>
                    <Form.Item
                      label={
                        <div>
                          {"Railway Station Location "}{" "}
                          <img src="https://api.iconify.design/iconoir/map.svg?height=20&width=25" />{" "}
                        </div>
                      }
                      name="railway_station_location"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Railway Station Location",
                        },
                      ]}
                    >
                      {/* <Button onClick={() => setVisible(true)}>
Choose Railway Station Location
</Button> */}
                      <TextArea
                        onClick={() => setVisible(true)}
                        onKeyDown={(e) => e.preventDefault()}
                      ></TextArea>
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
            <br />

            <Collapse defaultActiveKey={"4"}>
              <Panel
                header={"Informatic Messages"}
                key="4"
                style={{ background: "#77bff9" }}
              >
                <Row gutter={20}>
                  <Col lg={8} md={8}>
                    <Form.Item
                      label={<div>{"Fare Calulate Information"}</div>}
                      name={"fareInfo"}
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Fare Info",
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
            <br />
            <Collapse defaultActiveKey={"3"}>
              <Panel
                header={`Day Time Tier Configuration (${nightEndTime} - ${dayEndTime}) `}
                key="3"
                style={{ background: "#77bff9" }}
              >
                {/* <Row  >
<Col xxl={24}  sm={24} xs={24}> */}
                <Form.Item name={"tierDetails"}>
                  <Table
                    dataSource={tierData}
                    columns={columns}
                    scroll={{ x: 10 }}
                    pagination={false}
                  />
                </Form.Item>
                {/* </Col>
</Row> */}
              </Panel>
            </Collapse>
            <br />
            <Collapse defaultActiveKey={"4"}>
              <Panel
                header={`Night Time Tier Configuration (${dayEndTime} - ${nightEndTime})`}
                key="4"
                style={{ background: "#77bff9" }}
              >
                <Form.Item name={"tierDetails"}>
                  <Table
                    dataSource={tierDataNight}
                    columns={columnsNight}
                    scroll={{ x: 10 }}
                    pagination={false}
                  />
                </Form.Item>
              </Panel>
            </Collapse>

            <Button
              htmlType="submit"
              style={{
                marginTop: "50px",
                justifyContent: "center",
                textAlign: "center",
              }}
              type="primary"
            >
              Add Fare
            </Button>
          </Form>
        </Card>
      </div>
      <Modal
        open={visible}
        width={"60vw"}
        bodyStyle={{ height: "70vh" }}
        onCancel={() => setVisible(false)}
        onOk={() => getPoints("railway")}
        okButtonProps={{ disabled: !showOkButton }}
        okText={
          <Tooltip title={"Once You have Drawn Areas, Press me for save"}>
            Ok
          </Tooltip>
        }
      >
        <Map
          city={cityLocation}
          points={getSelectedPoints}
          type={"Railway Station"}
          setShowOkButton={setShowOkButton}
        />
      </Modal>
      <Modal
        open={airportMap}
        width={"60vw"}
        bodyStyle={{ height: "70vh" }}
        onCancel={() => setAirportMap(false)}
        onOk={() => getPoints("airport")}
        okButtonProps={{ disabled: !showOkButton }}
        okText={
          <Tooltip title={"Once You have Drawn Areas, Press me for save"}>
            Ok
          </Tooltip>
        }
      >
        <Map
          city={cityLocation}
          points={getSelectedPoints}
          type={"Airport"}
          setShowOkButton={setShowOkButton}
        />
      </Modal>
    </>
  );
};

export default ManageFareAdd;
