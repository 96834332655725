// import React, { useContext, useEffect, useState } from "react";
// import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
// import bike from "../../static/img/bikeTopView.png";
// import Auto from "../../static/img/map/auto.png";
// import Auto1 from "../../static/img/auto1.png";
// import Geocode from "react-geocode";
// import { useParams } from "react-router-dom";
// import Loader from "../loader/loader";
// import { Switch } from "antd";
// import { getDriverById } from "../../redux/drivers/actionCreatore";
// import { useDispatch, useSelector } from "react-redux";
// import { SocketContext } from "../socket/socketContext";
// import { getItem } from "../../utility/localStorageControl";
// import Car from '../../static/img/car.jpeg'
// function LiverDriver(props) {
//     const { id } = useParams();
//     const [vehicleType, setVehicleType] = useState(bike);
//     const [loading, setLoading] = useState(false);
//     const dispatch = useDispatch();
//     const socket = useContext(SocketContext);
//     const [location, setLocation] = useState({});
//     const user = getItem("user_details");
//     console.log("socket", socket);
//     const [popup, setPopup] = useState(false);
//     const [activeMarker, setActiveMarker] = useState({});
//     const [selectedPlace, setSelectedPlace] = useState({});
//     const [locationName, setLocationName] = useState("");
//     const [dutyStatus, setDutyStatus] = useState("");
//     const [dutyColor, setDutyColor] = useState("");

//     // Geocode.fromLatLng(location[1], location[0]).then(
//     //   (response) => {
//     //     const address = response.results[0].formatted_address;
//     //     console.log("address", address);
//     //     setLocationName(address)
//     //   },
//     //   (error) => {
//     //     console.error(error);
//     //   }
//     // );
//     return (

//             <div >
//                 {loading && <Loader />}

//                 <Map
//                     google={props.google}
//                     zoom={14}
//                     // initialCenter={{
//                     //   lat: 23.0746719,
//                     //   lng: 72.5255954,
//                     // }}
//                     center={{
//                         lat: 23.0772,
//                         lng: 72.6347,
//                     }}
//                 style={{height:'90%', width:"95%"}}
//                 >

//                     <Marker

//                         position={{
//                             lat: 23.0772,
//                             lng: 72.6347,
//                         }}
//                     />

//                 </Map>
//             </div>

//     );
// }

// export default GoogleApiWrapper({
//     apiKey: GOOGLE_MAP_API_KEY,
// })(LiverDriver);
import { Button, message, Tooltip } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { withGoogleMap, GoogleMap, Polygon } from "react-google-maps";
import { PlusOutlined, CheckOutlined } from "@ant-design/icons";
import {
  GOOGLE_MAP_API_KEY,
  GOOGLE_MAP_API_SUBTYPE_QUOTA_SERVICE,
  GOOGLE_MAP_API_SUBTYPE_VIEW_PORT_INFO_SERVICE,
  GOOGLE_MAP_API_TYPE_MAPS,
  GOOGLE_MAP_MODULE_NAME_AIRPORT_SELECTION_NEW_FARE,
  GOOGLE_MAP_MODULE_NAME_RAILWAY_SELECTION_NEW_FARE,
} from "../../config/dataService/dataService";
import { addMapStatistics } from "../../redux/MapStatistics/actionCreatore";
import { useDispatch } from "react-redux";
import { getItem } from "../../utility/localStorageControl";

const MapWithPolygon = withGoogleMap((props) => {
  const [path, setPath] = useState([[]]);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const user = getItem("user_details");
  const [selectedPolygon, setSelectedPolygon] = useState(0);
  const [polygonSelect, setPolygonSelect] = useState(false);
  const polygonRef = useRef([]);
  const mapRef = useRef(null);
  const isDraggingRef = useRef(false);
  const [pointClick, setPointClick] = useState(true);
  const [showNewButton, setShowNewButton] = useState(false);
  const [dragging, setDragging] = useState(false);
  const handlePolygonClick = (event) => {
    if (pointClick) {
      const clickedLatLng = event.latLng;

      if (polygonSelect) {
        const newPath = [...path[selectedPolygon], clickedLatLng];

        console.log("newpath", newPath);

        const allPaths = [...path];
        console.log("allPaths", allPaths);
        allPaths[selectedPolygon] = newPath;
        setPath([...allPaths]);
      } else {
        const newPath = [...path[count], clickedLatLng];

        // props.city.points(newPath);
        if (count > 0) {
          let paths = [];
          {
            path.map((p, index) => {
              if (index < count) {
                paths.push(p);
              }
            });
          }

          setPath([...paths, newPath]);
          props.city.points([...paths, newPath]);
        } else {
          setPath([newPath]);
          props.city.points([newPath]);
        }
      }
    }
  };
  console.log(path);
  const handleSelctedPolygon = (index) => {
    console.log(index);
    setSelectedPolygon(index);
    setPolygonSelect(true);
    setPointClick(true);
  };

  const handlePolygonMouseDown = () => {
    isDraggingRef.current = true;
  };

  const handlePolygonMouseMove = (event) => {
    console.log("event", event);
    if (isDraggingRef.current) {
      const draggedPath = event.latLngs.getArray()[0].getArray();
      props.city.points(draggedPath);

      setPath(draggedPath);
    }
  };

  const handlePolygonMouseUp = () => {
    isDraggingRef.current = false;
  };

  const handlePolygonDragEnd = (e, index) => {
    console.log("refs", polygonRef);
    console.log("ref", polygonRef.current[index].getPath().getArray());
    const draggedPath = polygonRef.current[index].getPath().getArray();
    // console.log("ref", polygonRef.current.getPath().getArray());
    // console.log("ref", polygonRef.current[index]);

    // const draggedPath = p;

    let points = [...path];
    points[index] = draggedPath;
    props.city.points(points);
    setPath(points);
  };
  const handlePolygonRightClick = (index, polygonIndex) => {
    if (polygonSelect) {
      const newPath = [...path[polygonIndex]];
      if (newPath.length > 3) {
        newPath.splice(index, 1);

        props.city.points(newPath);
        let allpaths = [...path];
        allpaths[polygonIndex] = newPath;
        setPath([...allpaths]);
      } else {
        message.error("Minimum 3 Points is required for area");
      }
    }
  };

  useEffect(() => {
    if (path.length > 0) {
      console.log("Polygon Lat/Lngs:", path);
      props.city.points(path);
    }
  }, [path]);

  useEffect(() => {
    if (mapRef.current && polygonRef.current) {
      const map = mapRef.current;

      const polygon = polygonRef.current;
      console.log("polygonRef.current", polygonRef.current);
      window.google.maps.event.addListener(
        polygon,
        "mousedown",
        handlePolygonMouseDown
      );
      window.google.maps.event.addListener(
        map,
        "mousemove",
        handlePolygonMouseMove
      );
      window.google.maps.event.addListener(
        map,
        "mouseup",
        handlePolygonMouseUp
      );

      return () => {
        window.google.maps.event.clearListeners(polygon, "mousedown");
        window.google.maps.event.clearListeners(map, "mousemove");
        window.google.maps.event.clearListeners(map, "mouseup");
      };
    }
  }, [mapRef, polygonRef]);

  const handleClick = () => {
    if (path[count].length < 3) {
      message.error("minimum 3 points required");
    } else {
      setPath([...path, []]);
      setCount(count + 1);
      setPolygonSelect(false);
      setPointClick(true);
      setShowNewButton(false);
      props.showOkButton(false);
    }
  };
  const handleUpdatePolygonClick = () => {
    setPolygonSelect(false);
    setPointClick(false);
    setShowNewButton(true);
    props.showOkButton(true);
  };

  const handlePointChange = (event, polygonIndex) => {
    console.log("polygonindex", polygonIndex);
    console.log("event", event);
    if (!dragging) {
      const polygons = [...path];
      //  console.log("polygons",polygons);
      const editPolygon = polygons[polygonIndex];
      //  console.log("before edit",editPolygon[event.vertex].lat(),editPolygon[event.vertex].lng());
      editPolygon[event.vertex].lat = event.latLng.lat;
      editPolygon[event.vertex].lng = event.latLng.lng;

      //  console.log("editPolygon",editPolygon[event.vertex].lat(),editPolygon[event.vertex].lng());

      polygons[polygonIndex] = editPolygon;

      setPath([...polygons]);
    }
  };

  const mapStatistics = async () => {
    await dispatch(
      addMapStatistics({
        user_id: user._id,
        googleApiType: GOOGLE_MAP_API_TYPE_MAPS,
        googleSubApiType: GOOGLE_MAP_API_SUBTYPE_QUOTA_SERVICE,
        moduleName:
          props.type == "Airport"
            ? GOOGLE_MAP_MODULE_NAME_AIRPORT_SELECTION_NEW_FARE
            : props.type == "Railway Station"
            ? GOOGLE_MAP_MODULE_NAME_RAILWAY_SELECTION_NEW_FARE
            : "",
        platformType: "web",
        originFrom: user?.role,
        apiStatus: "success",
      })
    );
    await dispatch(
      addMapStatistics({
        user_id: user._id,
        googleApiType: GOOGLE_MAP_API_TYPE_MAPS,
        googleSubApiType: GOOGLE_MAP_API_SUBTYPE_VIEW_PORT_INFO_SERVICE,
        moduleName:
          props.type == "Airport"
            ? GOOGLE_MAP_MODULE_NAME_AIRPORT_SELECTION_NEW_FARE
            : props.type == "Railway Station"
            ? GOOGLE_MAP_MODULE_NAME_RAILWAY_SELECTION_NEW_FARE
            : "",
        platformType: "web",
        originFrom: user?.role,
        apiStatus: "success",
      })
    );
  };

  return (
    <>
      {!showNewButton && path[count].length > 2 && (
        <Button type="primary" onClick={handleUpdatePolygonClick}>
          <Tooltip
            title={
              "Once you have drawn areas press Ok button for save all drawn areas"
            }
          >
            {" "}
            <CheckOutlined /> Done
          </Tooltip>
        </Button>
      )}
      {!polygonSelect && showNewButton && (
        <Button type="primary" onClick={handleClick} style={{ marginLeft: 10 }}>
          <PlusOutlined /> Add New
        </Button>
      )}
      {polygonSelect && (
        <Button
          type="primary"
          //  onClick={handleClick}
          onClick={() => {
            setPolygonSelect(false);
            setPointClick(false);
            props.showOkButton(true);
          }}
          style={{ marginLeft: 10 }}
        >
          <Tooltip
            title={
              "Once you have drawn areas press Ok button for save all drawn areas"
            }
          >
            {" "}
            <CheckOutlined /> Done
          </Tooltip>
        </Button>
      )}
      <GoogleMap
        ref={mapRef}
        defaultZoom={12}
        defaultCenter={{
          lat: props.city.city[0] ? props.city.city[0] : 23.022505,
          lng: props.city.city[1] ? props.city.city[1] : 72.5713621,
        }}
        onClick={handlePolygonClick}
        onTilesLoaded={mapStatistics}
      >
        {/* {path.length > 0 &&   (
        <Polygon
        ref={polygonRef}
        paths={path[count]}
        editable
        draggable
        onDragEnd={handlePolygonDragEnd}
        onRightClick={(event) => handlePolygonRightClick(event.vertex)}
        />
        )} */}
        {path.map((p, index) => {
          // console.log("selected pplygon, index", selectedPolygon, index);
          return (
            <Polygon
              ref={(ref) => (polygonRef.current[index] = ref)}
              paths={p}
              editable={polygonSelect}
              draggable={polygonSelect}
              onDragEnd={(e) => handlePolygonDragEnd(e, index)}
              options={{ fillColor: "red" }}
              onClick={() => handleSelctedPolygon(index)}
              onRightClick={(event) =>
                handlePolygonRightClick(event.vertex, index)
              }
              onDragStart={() => setDragging(true)}
              onMouseUp={(e) => handlePointChange(e, index)}
            />
          );
        })}
      </GoogleMap>
    </>
  );
});

const PolygonMap = (city, points, type, setShowOkButton) => {
  const [isMapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    if (
      typeof window.google === "object" &&
      typeof window.google.maps === "object"
    ) {
      setMapLoaded(true);
    } else {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => setMapLoaded(true);
      document.head.appendChild(script);
    }
  }, []);

  if (!isMapLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Select {city.type} Area</h1>
      <MapWithPolygon
        containerElement={<div style={{ height: "400px" }} />}
        mapElement={<div style={{ height: "60vh" }} />}
        city={city}
        points={points}
        showOkButton={city.setShowOkButton}
        type={city.type}
      />
    </div>
  );
};

export default PolygonMap;
