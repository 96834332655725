import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Row,
  Select,
  Table,
} from "antd";
import moment from "moment";

import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBookingsList } from "../../redux/Bookings/actionCreatore";
import { getCityData } from "../../redux/Masters/City/actionCreatore";
import { FixedToNDecimals } from "../../utility/commonFunction";
import { getItem } from "../../utility/localStorageControl";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import Loader from "../loader/loader";
import {
  getAllCommission,
  getCommsionForAdmin,
  getCommsionForCaptain,
} from "../../redux/Commission/actionCreator";
import {
  ADMIN,
  CAPTAIN,
  SUPERADMIN,
} from "../../config/dataService/dataService";
const { RangePicker } = DatePicker;

const Earning = () => {
  const { t } = useTranslation();
  const user = getItem("user_details");
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(50);
  const [status, setStatus] = useState(["completed"]);
  const [searchFilter, setSearchFilter] = useState("");
  const [selectedCity, setSelectedCity] = useState(
    user.role == "admin" ? [user?.city_id?._id] : ""
  );
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [charges, setCharges] = useState({
    captain: 0,
    superadmin: 0,
    admin: 0,
  });
  //For The Export File as PDF, EXCEL OR CSV
  // const [headerArray, setHeaderArray] = useState([
  //   "id",
  //   "driver_name",
  //   "driver_city",
  //   "status",
  //   "date",
  // ]);
  useEffect(() => {
    // reteriveBookingList();
    // reteriveCityList();
    window.scrollTo(0, 0);
  }, []);

  const { data: commissionData, isLoading: commissionLoading } = useQuery(
    "commissions",
    () => {
      return fetchAllCommission();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data, isLoading } = useQuery(
    "bookingList",
    () => {
      return reteriveBookingList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataCity, isLoading: isLoadingCity } = useQuery(
    "cityList",
    () => {
      return reteriveCityList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchAllCommission = async () => {
    if (user.role == SUPERADMIN) {
      await dispatch(getAllCommission());
    }
    if (user.role == ADMIN) {
      await dispatch(getCommsionForAdmin());
    }
    if (user.role == CAPTAIN) {
      await dispatch(getCommsionForCaptain());
    }
  };

  const commission = useSelector((state) => state.commission.commission);

  useEffect(() => {
    if (Array.isArray(commission) && commission) {
      let obj = {
        captain: 0,
        superadmin: 0,
        admin: 0,
      };
      commission.map((com) => {
        if (com._id == "captainCharges") {
          obj.captain = com.total_amount;
        }
        if (com._id == "facilityFee") {
          obj.superadmin = com.total_amount;
        }
        if (com._id == "adminCommission") {
          obj.admin = com.total_amount;
        }
      });
      setCharges(obj);
    }
  }, [commission]);
  const reteriveBookingList = async () => {
    await dispatch(
      getBookingsList(
        pageNo,
        limit,
        status,
        searchFilter,
        selectedCity,
        "",
        fromDate,
        toDate
      )
    );
  };
  let bookingList = useSelector((state) => state.booking.bookingListData);

  const reteriveCityList = async () => {
    await dispatch(getCityData(""));
  };
  let cityList = useSelector((state) => state.city.cityData);
  cityList = cityList?.sort((a, b) => a.name?.localeCompare(b.name));

  const dispatch = useDispatch();
  const handleSearch = async (value) => {
    setSearch(value);

    await dispatch(getCityData(value));
  };
  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    await dispatch(
      getBookingsList(
        page,
        pageSizes,
        status,
        searchFilter,
        selectedCity,
        "",
        fromDate,
        toDate
      )
    );
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setLimit(pageSizes);
    await dispatch(
      getBookingsList(
        current,
        pageSizes,
        status,
        searchFilter,
        selectedCity,
        "",
        fromDate,
        toDate
      )
    );
  };

  const handleSelectedCity = async (value) => {
    setSelectedCity(value);
    if (search) {
      setSearch("");
      await dispatch(getCityData(""));
    }
    await dispatch(
      getBookingsList(
        1,
        limit,
        status,
        searchFilter,
        value,
        "",
        fromDate,
        toDate
      )
    );
  };

  const rangePresets = [
    { label: "Today", value: [dayjs(), dayjs()] },
    { label: "Last 7 Days", value: [dayjs().add(-7, "days"), dayjs()] },
    { label: "Last Month", value: [dayjs().add(-1, "month"), dayjs()] },
    { label: "Last 6 Month", value: [dayjs().add(-6, "month"), dayjs()] },
    { label: "Last Year", value: [dayjs().add(-1, "year"), dayjs()] },
  ];
  const onRangeChange = async (dates, dateStrings) => {
    // console.log("From: ", dates[0], ", to: ", dates[1]);
    // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
    setFromDate(dates ? dates[0] : "");
    setToDate(dates ? dates[1] : "");
    setTableLoading(true);
    const resp = await dispatch(
      getBookingsList(
        1,
        limit,
        status,
        searchFilter,
        selectedCity,
        "",
        dates ? dates[0] : "",
        dates ? dates[1] : ""
      )
    );
    if (resp) {
      setTableLoading(false);
    }
  };
  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * limit,
    },
    {
      title: "Rider Name",
      // <Checkbox style={{ color: "white" }}>Driver Name</Checkbox>

      key: "Rider_name",
      align: "center",
      render: (data) => {
        return <span>{data?.rider_id ? data?.rider_id.name : "-"}</span>;
      },
    },
    {
      title: "Driver Name",
      // <Checkbox style={{ color: "white" }}>Driver Name</Checkbox>
      // dataIndex: ["driver_id","name"],
      key: "driver_name",
      align: "center",
      render: (data) => {
        return <span>{data?.driver_id ? data?.driver_id.name : "-"}</span>;
      },
    },
    //   {
    //     title: "Pick Up Point",
    //     dataIndex: ["pickup_location", "location_name"],
    //     key: "pickup",
    //   },
    //   {
    //     title: "Destination",
    //     dataIndex: ["destination_location", "location_name"],
    //     key: "Destination",
    //   },
    {
      title: "City",
      dataIndex: ["pickup_city_details", "name"],
      key: "driver_city",
      align: "center",
    },
    {
      title: "Facility Fee (₹)",

      key: "facilityFee",
      align: "center",
      render: (data) => {
        return <span>{data?.facilityFee ? data?.facilityFee : 0}</span>;
      },
    },
    {
      title: "GAuto Commission (₹)",

      key: "adminCommission",
      align: "center",
      render: (data) => {
        return <span>{data?.adminCommission ? data?.adminCommission : 0}</span>;
      },
    },

    {
      title: "Captain Commission (₹)",

      key: "captainCommission",
      align: "center",
      render: (data) => {
        return (
          <span>{data?.captainCommission ? data?.captainCommission : 0}</span>
        );
      },
    },
    {
      title: "Total Fare (₹)",
      // dataIndex: ["total_fare"],
      key: "totalFare",
      align: "center",
      render: (data) => {
        return (
          <span>
            {data?.total_fare ? FixedToNDecimals(data?.total_fare) : "-"}
          </span>
        );
      },
    },
    {
      title: "Ride Date",

      key: "date",
      align: "center",
      render: (data) => {
        const date = new Date(data?.created_at);
        const finaldate = date.toLocaleDateString();
        return <span>{finaldate}</span>;
      },
    },

    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record, target) => {
        return (
          <span
            style={{ cursor: "pointer", padding: "20px" }}
            onClick={() =>
              navigate(`/${user.role}/manageBooking/${record._id}`)
            }
          >
            <Button type="primary">Details</Button>
          </span>
        );
      },
    },
  ];

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <h1 className="heading">Earning</h1>
            {/* <h1 className="heading">Manage Booking</h1> */}
            <BreadCrumb />
          </Col>
          {user?.role == CAPTAIN && (
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Card
                style={{ marginTop: 10 }}
                bodyStyle={{
                  justifyContent: "center",
                  display: "flex",
                  padding: 0,
                }}
                className="shadowCardBox totalCard"
              >
                <h1 style={{ fontSize: "20px" }}>
                  <strong>{"Your Total Commission : "}</strong>
                  <span>
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWGD3_-5BftdXq3w5p5qaUQuN8ISxUwxIJqw&usqp=CAU"
                      style={{ width: "20px", height: "18px" }}
                    />{" "}
                    {charges.captain}
                  </span>
                </h1>
              </Card>
            </Col>
          )}

          {user?.role == ADMIN && (
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Card
                style={{ marginTop: 10 }}
                bodyStyle={{
                  justifyContent: "center",
                  display: "flex",
                  padding: 0,
                }}
                className="shadowCardBox totalCard"
              >
                <h1 style={{ fontSize: "20px" }}>
                  <strong>{"Captain Total Commission : "}</strong>
                  <span>
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWGD3_-5BftdXq3w5p5qaUQuN8ISxUwxIJqw&usqp=CAU"
                      style={{ width: "20px", height: "18px" }}
                    />{" "}
                    {charges.captain}
                  </span>
                </h1>
              </Card>
            </Col>
          )}

          {/* <Col lg={12} md={12} sm={12} xs={12}>
              <Button
                style={{ float: "right" }}
                type="primary"
                danger
                disabled
                disabled={selectedRows.length == 0 ? true : false}
                onClick={handleMultiDelete}
              >
                {" "}
                <DeleteOutlined /> Delete
              </Button>
            </Col> */}
        </Row>
        <Divider />

        {isLoading & isLoadingCity ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <Row gutter={20}>
              {/* <Col xxl={6} lg={6}>
                  <strong>Total </strong>
                  {bookingList.total_counts}
                </Col> */}
              {user?.role == "super_admin" && (
                <>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                    <Card
                      style={{ marginBottom: 10 }}
                      bodyStyle={{ justifyContent: "center", display: "flex" }}
                      className="shadowCardBox totalCard"
                    >
                      <h1 style={{ fontSize: "20px" }}>
                        <strong>{"Total GAuto Commission : "}</strong>
                        <span>
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWGD3_-5BftdXq3w5p5qaUQuN8ISxUwxIJqw&usqp=CAU"
                            style={{ width: "20px", height: "18px" }}
                          />{" "}
                          {charges.admin}
                        </span>
                        <br />
                      </h1>
                    </Card>
                    <br />
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                    <Card
                      style={{ marginBottom: 10 }}
                      bodyStyle={{ justifyContent: "center", display: "flex" }}
                      className="shadowCardBox totalCard"
                    >
                      <h1 style={{ fontSize: "20px" }}>
                        <strong>{"Total Facility Fee : "}</strong>
                        <span>
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWGD3_-5BftdXq3w5p5qaUQuN8ISxUwxIJqw&usqp=CAU"
                            style={{ width: "20px", height: "18px" }}
                          />{" "}
                          {charges.superadmin}
                        </span>
                        <br />
                      </h1>
                    </Card>
                    <br />
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                    <Card
                      style={{ marginBottom: 10 }}
                      bodyStyle={{ justifyContent: "center", display: "flex" }}
                      className="shadowCardBox totalCard"
                    >
                      <h1 style={{ fontSize: "20px" }}>
                        <strong>{"Captains Total Commission : "}</strong>
                        <span>
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWGD3_-5BftdXq3w5p5qaUQuN8ISxUwxIJqw&usqp=CAU"
                            style={{ width: "20px", height: "18px" }}
                          />{" "}
                          {charges.captain}
                        </span>
                      </h1>
                    </Card>
                  </Col>
                </>
              )}
            </Row>
            {/* <Card>
             
            </Card> */}

            <Card className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col xxl={12} xl={4} lg={4} md={12} sm={24} xs={24}>
                  <span className="total">
                    Total {bookingList?.total_counts}
                  </span>
                </Col>
                <Col xxl={12}>
                  <RangePicker
                    presets={rangePresets}
                    onChange={onRangeChange}
                    defaultValue={[fromDate, toDate]}
                    style={{ borderRadius: "0", float: "right" }}
                    format={"DD/MM/YYYY"}
                    disabledDate={(current) => current.isAfter(moment())}

                    // style={{display:"flex"}}
                  />
                  {user?.role == "super_admin" && (
                    <>
                      <Select
                        placeholder="Choose city"
                        style={{
                          width: 300,
                          marginRight: "10px",
                          float: "right",

                          maxHeight: "45px",
                          overflowY: "auto",
                        }}
                        mode={"multiple"}
                        showSearch
                        filterOption={false}
                        onSearch={(e) => handleSearch(e)}
                        allowClear
                        onClear={(e) => {
                          handleSearch("");
                          handleSelectedCity("");
                        }}
                        // onSelect={(value) => handleSelectedCity(value)}
                        onChange={handleSelectedCity}
                      >
                        {cityList.length > 0 &&
                          cityList?.map((data) => {
                            return (
                              <Select.Option
                                value={data._id}
                                key={data._id}
                                className="selectOptions"
                              >
                                {data.name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </>
                  )}
                </Col>
              </Row>
              <br />

              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                dataSource={bookingList?.list}
                columns={
                  user?.role == "super_admin"
                    ? columns
                    : columns.filter((data) => data.key != "adminCommission")
                }
                searchable
                scroll={{ x: "45vh" }}
                loading={tableLoading}
                pagination={{
                  showSizeChanger: true,
                  pageSize: limit,
                  total: bookingList?.total_counts,
                  onShowSizeChange: onShowSizeChange,
                  onChange: onPageChange,
                  pageSizeOptions: ["50", "100", "200"],
                }}
                summary={(pageData) => {
                  let totalfare = 0;
                  let totalfacilityFee = 0;
                  let totalCaptainCommision = 0;
                  let totalAdminCommission = 0;
                  pageData.forEach(
                    ({
                      total_fare,
                      facilityFee,
                      captainCommission,
                      adminCommission,
                    }) => {
                      totalfare += total_fare;
                      totalfacilityFee += facilityFee ? facilityFee : 0;
                      totalCaptainCommision += captainCommission
                        ? captainCommission
                        : 0;
                      totalAdminCommission += adminCommission
                        ? adminCommission
                        : 0;

                      // totalRepayment += repayment;
                    }
                  );
                  return (
                    <Table.Summary.Row
                      style={{ fontWeight: "bold", background: "#d6d4d4" }}
                    >
                      <Table.Summary.Cell></Table.Summary.Cell>

                      <Table.Summary.Cell>Total</Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>

                      {user.role == SUPERADMIN && (
                        <Table.Summary.Cell>
                          {totalfacilityFee ? totalfacilityFee.toFixed(2) : 0}
                        </Table.Summary.Cell>
                      )}
                      {user.role == SUPERADMIN && (
                        <Table.Summary.Cell>
                          {totalAdminCommission
                            ? totalAdminCommission.toFixed(2)
                            : 0}
                        </Table.Summary.Cell>
                      )}
                      <Table.Summary.Cell>
                        {totalCaptainCommision
                          ? totalCaptainCommision.toFixed(2)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        {totalfare ? totalfare.toFixed(2) : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                }}
              />
            </Card>
          </>
        )}
      </div>
    </>
  );
};

export default Earning;
