import React from "react";
import './common.css'
const CustomerSupport = () => {
  return (
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="main" class="site-main">
          <article
            id="post-271"
            class="post-271 page type-page status-publish hentry entry"
          >
            <header class="entry-header alignwide">
              <h1 class="entry-title">Customer Support Policy</h1>
            </header>
            <div class="entry-content refund-padding-left">
              <p>
                <strong>1. Introduction</strong>
                <br />
                At Safewheels Solutions Pvt Ltd, we are committed to providing a
                seamless and enjoyable experience for our users when using our
                mobile application and website to book rides. We understand that
                questions, concerns, or issues may arise, and we are here to
                assist you. This Customer Support Policy outlines the guidelines
                and procedures for getting in touch with our support team and
                how we address your needs.
              </p>
              <p>
                <strong>2. Contacting Customer Support</strong>
                <br />
                You can reach our customer support team through the following
                channels:
                <br />
                • In-App Support: Open our mobile application, and within the
                app, you can find a dedicated support section to contact us.
                <br />
                • Website Support: Visit our website and locate the “Contact
                Support” page.
                <br />• Email: Send an email to info@g-auto.org.
              </p>
              <p>
                <strong>3. Types of Support Queries</strong>
                <br />
                Our customer support team is here to assist with a variety of
                queries, including but not limited to:
                <br />
                • Booking assistance.
                <br />
                • Billing and payment inquiries.
                <br />
                • Technical issues and errors.
                <br />
                • Account-related questions.
                <br />
                • Feedback and suggestions.
                <br />• Safety concerns.
              </p>
              <p>
                <strong>4. Support Hours</strong>
                <br />
                Our customer support team is available 9AM Indian Time to 7PM
                Indian Time to assist you. We strive to respond to all inquiries
                within that time, and we appreciate your patience during busy
                periods.
              </p>
              <p>
                <strong>5. Response and Resolution</strong>
                <br />
                • We aim to respond to your initial inquiry As soon as possible.
                Our team will acknowledge your query and work to resolve it
                promptly.
                <br />
                • For more complex issues, we will keep you informed of our
                progress and expected resolution time.
                <br />• In the case of issues that require additional
                investigation or cooperation from third-party service providers,
                we will communicate the steps and timeline for resolution.
              </p>
              <p>
                <strong>6. Privacy and Data Security</strong>
                <br />
                Your privacy is important to us. We handle your personal and
                sensitive data with the utmost care and in compliance with
                relevant data protection laws. For more information, please
                refer to our Privacy Policy.
              </p>
              <p>
                <strong>7. User Safety</strong>
                <br />
                Safety is a top priority. If you have any safety concerns during
                your ride or experience with our platform, please reach out to
                our support team immediately. We take safety reports very
                seriously and will take appropriate action.
              </p>
              <p>
                <strong>8. Feedback and Suggestions</strong>
                <br />
                We value your feedback and suggestions. Feel free to share your
                ideas on how we can improve our service and user experience.
              </p>
              <p>
                <strong>9. Termination and Suspension</strong>
                <br />
                In cases of policy violations or misconduct, we reserve the
                right to terminate or suspend user accounts as outlined in our
                Terms of Service.
              </p>
              <p>
                <strong>10. Policy Updates</strong>
                <br />
                We may update this Customer Support Policy from time to time to
                reflect changes in our services or to better serve our users. We
                encourage you to review this policy periodically.
              </p>
              <p>
                By using our mobile application and website, you agree to abide
                by the terms and conditions outlined in this Customer Support
                Policy.
                <br />
                If you have any questions or require assistance, please do not
                hesitate to contact our support team.
              </p>
              <p>
                Safewheels Solutions Pvt Ltd
                <br />
                203, Parthmilan Building,
                <br />
                Nr Hotel Antrim, Off CG Road,
                <br />
                Ahmedabad – 380009.
              </p>
            </div>
          </article>
        </main>
      </div>
    </div>
  );
};

export default CustomerSupport;