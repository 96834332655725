const actions = {
  GET_RIDER_LIST: "GET_RIDER_LIST",

  getRiderList: (data) => {
    return {
      type: actions.GET_RIDER_LIST,
      data,
    };
  },
};

export default actions;
