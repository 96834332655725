import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const { fetchCallCenterList, fetchCallCenterById } = actions;

export const getCallCenterList = (
  search,
  pageSize,
  limit,
  cityId,
  key,
  sort,
  selectedTime
) => {
  let finaldata = "";

  cityId !== "" &&
    cityId?.map((data) => (finaldata = finaldata + "&city_id=" + data));
  return async (dispatch) => {
    let queryParametere =
      cityId !== null
        ? "?search=" +
          search +
          finaldata +
          "&page=" +
          pageSize +
          "&limit=" +
          limit +
          "&key=" +
          key +
          "&value=" +
          sort +
          "&login_time=" +
          selectedTime
        : "?search=" +
          search +
          "?page=" +
          pageSize +
          "&limit=" +
          limit +
          "&key=" +
          key +
          "&value=" +
          sort +
          "&login_time=" +
          selectedTime;

    const resp = await DataService.get(API.callCenter.getAll + queryParametere);

    if (!resp.data.error) {
      dispatch(fetchCallCenterList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const createCallCenter = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.callCenter.add, payload);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const updateCallCenter = (payload, id) => {
  return async (dispatch) => {
    const resp = await DataService.patch(API.callCenter.update + id, payload);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const removeCallCenter = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.callCenter.delete + id);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getCallCenterById = (id) => {
  return async (dispatch) => {
    const resp = await DataService.get(API.callCenter.getAll + "/" + id);

    if (!resp.data.error) {
      dispatch(fetchCallCenterById(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
