const actions = {
  GET_VEHICLE_TYPES: "GET_VEHICLE_TYPES",
  GET_VEHICLE_MODELS: "GET_VEHICLE_MODELS",
  GET_VEHICLE_CATEGORIES: "GET_VEHICLE_CATEGORIES",


  PATCH_ROLE_ID: "PATCH_ROLE_ID",

  getVehicleTypes: (data) => {
    return {
      type: actions.GET_VEHICLE_TYPES,
      data,
    };
  },
  updateRoleById: (data) => {
    return {
      type: actions.PATCH_ROLE_ID,
      data,
    };
  },
  getVehicleModel:(data) => {
    return {
      type: actions.GET_VEHICLE_MODELS,
      data,
    };
  },
  getVehicleCategories:(data) => {
    return {
      type: actions.GET_VEHICLE_CATEGORIES,
      data,
    };
  },
};

export default actions;
