import React from "react";
import "./Refundpolicy.css";
import './common.css'

const RefundPolicy = () => {
  return (
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="main" class="site-main">
          <article
            id="post-276"
            class="post-276 page type-page status-publish hentry entry"
          >
            <header class="entry-header alignwide">
              <h1 class="entry-title">
                G-Auto Fare Refund and Cancellation Policy
              </h1>
            </header>
            <div class="entry-content">
              <p className="refund-padding-left">
                Effective Date: [1st October 2023]
              </p>
              <p className="refund-padding-left">
                <strong>1. Cancellation Policy</strong>
              </p>
              <p className="refund-padding-left">
                <strong>1.1 User Cancellation</strong>
              </p>
              <p className="refund-padding-left">
                Users can cancel their booking at any time before the ride
                starts without incurring any cancellation charges.
              </p>
              <p className="refund-padding-left">
                <strong>1.2 Driver Cancellation</strong>
              </p>
              <p className="refund-padding-left">
                &nbsp;In case a driver cancels a confirmed booking, the user
                will not be charged, and G-Auto will make every effort to find
                an alternative driver.
              </p>
              <p className="refund-padding-left">
                <strong>1.3 No-Show</strong>
              </p>
              <p className="refund-padding-left">
                &nbsp;&nbsp; – If a user is not present at the pickup location
                at the scheduled time, the ride may be canceled by the driver,
                and standard cancellation charges may apply.
              </p>
              <p className="refund-padding-left">
                <strong>2. Refund Policy</strong>
              </p>
              <p className="refund-padding-left">
                <strong>2.1 Refund Eligibility</strong>
              </p>
              <p className="refund-padding-left">
                &nbsp; &nbsp; &nbsp;– Refunds are typically issued in the
                following situations:
              </p>
              <p className="refund-padding-left">
                &nbsp;&nbsp;&nbsp;&nbsp; – Overcharging due to a technical
                glitch.
              </p>
              <p className="refund-padding-left">
                &nbsp;&nbsp;&nbsp;&nbsp; – Cancellations by the driver after the
                user has been charged.
              </p>
              <p className="refund-padding-left">
                &nbsp;&nbsp;&nbsp;&nbsp; – Duplicate charges.
              </p>
              <p className="refund-padding-left">
                &nbsp;&nbsp;&nbsp;&nbsp; – Failed or incomplete rides due to
                technical issues.
              </p>
              <p className="refund-padding-left">
                <strong>2.2 Refund Process</strong>
              </p>
              <p className="refund-padding-left">
                Users can request a refund by contacting our customer support
                within 7 days of the issue. Users are required to provide
                necessary information, including booking details, to process the
                refund.
              </p>
              <p className="refund-padding-left">
                <strong>2.3 Refund Amount</strong>
              </p>
              <p className="refund-padding-left">
                Refunds will be issued in the original form of payment. The
                amount refunded will depend on the specific circumstances of the
                refund request.
              </p>
              <p className="refund-padding-left">
                <strong>2.4 Refund Timeframe</strong>
              </p>
              <p className="refund-padding-left">
                Please allow 7-14 business days for us to process and credit the
                refund to your account.
              </p>
              <p className="refund-padding-left">
                <strong>3. Fare Disputes</strong>
              </p>
              <p className="refund-padding-left">
                <strong>3.1 Dispute Resolution</strong>
              </p>
              <p className="refund-padding-left">
                In the event of fare disputes, users can contact our customer
                support to resolve the issue. We will investigate and resolve
                the dispute to the best of our ability.
              </p>
              <p className="refund-padding-left">
                <strong>3.2 Fair Fare Calculation</strong>
              </p>
              <p className="refund-padding-left">
                Our fare calculation is based on a transparent and standardized
                algorithm. Fare breakdown details can be provided to users upon
                request.
              </p>
              <p className="refund-padding-left">
                <strong>4. Changes to this Policy</strong>
              </p>
              <p className="refund-padding-left">
                <strong>4.1 Policy Updates</strong>
              </p>
              <p className="refund-padding-left">
                G-Auto reserves the right to update and modify this policy at
                any time. Users will be notified of any significant changes.
              </p>
              <p className="refund-padding-left">
                <strong>5. Contact Us</strong>
              </p>
              <p className="refund-padding-left">
                For any questions or concerns related to this policy or our
                services, please contact our customer support at
                info@g-auto.org.
              </p>
            </div>
          </article>
        </main>
      </div>
    </div>
  );
};

export default RefundPolicy;
