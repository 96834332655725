import actions from "./actions";

const {
  GET_MAP_STATISTICS_LIST,
  GET_MAP_API_TYPES,
  GET_MAP_STATISTICS_COUNTS,
} = actions;

const initState = {
  mapStatisticsData: {},
  mapApiTypes: {},
  mapCounts: {}
};

const MapStatisticsReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_MAP_STATISTICS_LIST:
      return {
        ...state,
        mapStatisticsData: data,
      };
    case GET_MAP_API_TYPES:
      return {
        ...state,
        mapApiTypes: data,
      };

    case GET_MAP_STATISTICS_COUNTS:
      return {
        ...state,
        mapCounts: data,
      };

    default:
      return state;
  }
};
export default MapStatisticsReducer;
