import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const { getVehicleTypes, getVehicleModel, getVehicleCategories } = actions;

export const getVehicleTypesData = (id) => {
  return async (dispatch) => {
    const resp = await DataService.get(API.vehicleTypes.get);

    if (!resp.data.error) {
      dispatch(getVehicleTypes(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const addVehicleType = (formdata) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.vehicleTypes.add, formdata, {
      "Content-Type": "application/x-www-form-urlencoded",
    });
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const removeVehicleType = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.vehicleTypes.delete + id);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const updateVehicleType = (formdata, id) => {
  return async (dispatch) => {
    const resp = await DataService.put(API.vehicleTypes.update + id, formdata);
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getVehicleModelsData = ({ status, vehicleType }) => {
  return async (dispatch) => {
    let queryParameter = "";
    if (status !== "") {
      queryParameter = queryParameter + "&status=" + status;
    }
    if (vehicleType !== "") {
      queryParameter = queryParameter + "&vehicle_type_id=" + vehicleType;
    }
    const resp = await DataService.get(
      API.vehicleModel.get + "?" + queryParameter
    );

    if (!resp.data.error) {
      dispatch(getVehicleModel(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const addVehicleModel = (formdata) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.vehicleModel.add, formdata);
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const removeVehicleModel = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.vehicleModel.delete + id);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const updateVehicleModel = (formdata, id) => {
  return async (dispatch) => {
    const resp = await DataService.put(API.vehicleModel.update + id, formdata);
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getVehicleCategoriesData = (id) => {
  return async (dispatch) => {
    const resp = await DataService.get(API.vehicleTypes.getCategory);

    if (!resp.data.error) {
      dispatch(getVehicleCategories(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
