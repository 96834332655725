const actions = {
  GET_CALLCENTER_LIST: "GET_CALLCENTER_LIST",
  GET_CALLCENTER_BY_ID:"GET_CALLCENTER_BY_ID",
  fetchCallCenterList: (data) => {
    return {
      type: actions.GET_CALLCENTER_LIST,
      data,
    };
  },
  fetchCallCenterById: (data) => {
    return {
      type: actions.GET_CALLCENTER_BY_ID,
      data,
    };
  },
};

export default actions;
