import { Card, Col, DatePicker, Divider, Row, Select, Table, Tag } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { getAllDriversList } from "../../redux/drivers/actionCreatore";
import {
  fetchMapStatisticsCounts,
  getAdminDataForStatistics,
  getAllDriverAndRiderListForStatistics,
  getAllDriverListForStatistics,
  getAllRiderListForStatistics,
  getCallCenterListForStaistics,
  getCaptainListForStaistics,
} from "../../redux/MapStatistics/actionCreatore";
import { WordFormate } from "../../utility/commonFunction";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import "./loading.css";
const { RangePicker } = DatePicker;
function OverallStatistics(props) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [originFrom, setOriginFrom] = useState("");
  const [users, setUsers] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedUser, setSelectUser] = useState("");
  const payload = { originFrom, fromDate, toDate, selectedUser };

  const userPayload = {
    page,
    limit,
  };

  const rangePresets = [
    { label: "Today", value: [dayjs(), dayjs()] },
    { label: "Last 7 Days", value: [dayjs().add(-7, "days"), dayjs()] },
    { label: "Last Month", value: [dayjs().add(-1, "month"), dayjs()] },
    { label: "Last 6 Month", value: [dayjs().add(-6, "month"), dayjs()] },
    { label: "Last Year", value: [dayjs().add(-1, "year"), dayjs()] },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data, isLoading: isLoading } = useQuery(
    "mapStatisticsCounts",
    () => {
      return retriveMapStatisticsCounts(payload);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const retriveMapStatisticsCounts = async (payload) => {
    await dispatch(fetchMapStatisticsCounts(payload));
  };

  const statisticsCounts = useSelector(
    (state) => state.mapStatistics.mapCounts
  );

  const handleSelectOrigin = async (value) => {
    setOriginFrom(value ? value : "");
    setLoadingMore(false);
    setSelectUser("");
    setPage(1);
    setUsers([]);
    payload.originFrom = value ? value : "";
    await retriveMapStatisticsCounts(payload);
  };
  const onRangeChange = async (dates, dateStrings) => {
    if (dates) {
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setFromDate(dates[0]);
      setToDate(dates[1]);
      payload.fromDate = dates[0];
      payload.toDate = dates[1];

      await retriveMapStatisticsCounts(payload);
    } else {
      setFromDate("");
      setToDate("");
      payload.fromDate = "";
      payload.toDate = "";
      await retriveMapStatisticsCounts(payload);
    }
  };

  const reteriveDriverList = async (userPayload) => {
    setLoadingMore(true);
    const resp = await dispatch(getAllDriverListForStatistics(userPayload));
    if (resp) {
      setTotalPages(resp?.total_pages);
      setUsers([...users, ...resp?.list]);
      setLoadingMore(false);
    }
  };
  const reteriveRiderList = async (userPayload) => {
    setLoadingMore(true);

    const resp = await dispatch(getAllRiderListForStatistics(userPayload));
    if (resp) {
      setTotalPages(resp?.total_pages);

      setUsers([...users, ...resp?.list]);
      setLoadingMore(false);
    }
  };

  const reteriveAdminList = async (userPayload) => {
    setLoadingMore(true);

    const resp = await dispatch(getAdminDataForStatistics(userPayload));
    if (resp) {
      setTotalPages(resp?.total_pages);

      setUsers([...users, ...resp?.list]);
      setLoadingMore(false);
    }
  };
  const reteriveCaptainList = async (userPayload) => {
    setLoadingMore(true);

    const resp = await dispatch(getCaptainListForStaistics(userPayload));
    if (resp) {
      setTotalPages(resp?.total_pages);

      setUsers([...users, ...resp?.list]);
      setLoadingMore(false);
    }
  };

  const reteriveCallCenterList = async (userPayload) => {
    setLoadingMore(true);

    const resp = await dispatch(getCallCenterListForStaistics(userPayload));
    if (resp) {
      setTotalPages(resp?.total_pages);

      setUsers([...users, ...resp?.list]);
      setLoadingMore(false);
    }
  };
  useEffect(() => {
    if (originFrom == "driver") {
      reteriveDriverList(userPayload);
    }
    if (originFrom == "rider") {
      reteriveRiderList(userPayload);
    }
    if (originFrom == "admin") {
      reteriveAdminList(userPayload);
    }
    if (originFrom == "captain") {
      reteriveCaptainList(userPayload);
    }
    if (originFrom == "callCenter") {
      reteriveCallCenterList(userPayload);
    }
  }, [originFrom, page]);

  const handleLoadMore = async (e) => {
    const { target } = e;
    const bottomThreshold = 0; // Adjust this value to define the threshold for reaching the bottom
    if (
      target.scrollTop + target.offsetHeight >=
        target.scrollHeight - bottomThreshold &&
      totalPages > page
    ) {
      setPage(page + 1);
    }
  };
  const handleSelectUser = async (value) => {
    setSelectUser(value ? value : "");
    payload.selectedUser = value ? value : "";
    await retriveMapStatisticsCounts(payload);
  };
  const columns = [
    {
      title: "Sr. No.",
      render: (data, ind, index) => <span>{index + 1}</span>,
    },

    {
      title: "Google Map Api",

      children: [
        {
          title: "Type",
          key: "apitype",
          render: (data) => (
            <span>
              {data?.googleApiType ? WordFormate(data?.googleApiType) : "-"}
            </span>
          ),
        },
        {
          title: "Sub Type",
          key: "subtype",
          render: (data) => (
            <span>
              {data?.googleSubApiType
                ? WordFormate(data?.googleSubApiType)
                : "-"}
            </span>
          ),
        },
      ],
    },
    {
      title: "Service Counts",
      key: "count",
      render: (data) => <span>{data?.count ? data?.count : "-"}</span>,
    },
  ];

  return (
    <div className="common_layout content-container">
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h1 className="heading"> Map Overall Statistics</h1>
          <BreadCrumb />
        </Col>
      </Row>
      <Divider />
      <Card style={{ marginBottom: 10 }} className="shadowCardBox">
        <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
          <Col xxl={6} lg={4} md={8} sm={24} className="total">
            Total{" "}
            {Array.isArray(statisticsCounts) ? statisticsCounts.length : "0"}
            {/* {statistics?.total_counts} */}
          </Col>

          <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
            <Select
              placeholder={"Choose Origin"}
              style={{ width: "100%" }}
              onSelect={handleSelectOrigin}
              allowClear
              onClear={handleSelectOrigin}
            >
              <Select.Option
                value="super_admin"
                key="1"
                className="selectOptions"
              >
                Super Admin
              </Select.Option>
              <Select.Option value="admin" key="2" className="selectOptions">
                Admin
              </Select.Option>
              <Select.Option value="captain" key="3" className="selectOptions">
                Captain
              </Select.Option>
              <Select.Option
                value="callCenter"
                key="4"
                className="selectOptions"
              >
                Call Center
              </Select.Option>
              <Select.Option value="driver" key="5" className="selectOptions">
                Driver
              </Select.Option>
              <Select.Option value="rider" key="6" className="selectOptions">
                Rider
              </Select.Option>
              <Select.Option
                value="backend_api"
                key="7"
                className="selectOptions"
              >
                Backend Api
              </Select.Option>
              <Select.Option
                value="backend_socket"
                key="8"
                className="selectOptions"
              >
                Backend Socket
              </Select.Option>
            </Select>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
            <Select
              placeholder={"Choose Users"}
              style={{ width: "100%" }}
              onPopupScroll={handleLoadMore}
              onSelect={handleSelectUser}
              onClear={handleSelectUser}
              allowClear
              value={selectedUser ? selectedUser : null}
            >
              {Array.isArray(users) &&
                users.map((user) => {
                  return (
                    <Select.Option value={user._id} key={user._id}>
                      {user.name}
                    </Select.Option>
                  );
                })}
              {loadingMore && (
                <Select.Option value="loading">
                  <span className="back">
                    <span>L</span>
                    <span>o</span>
                    <span>a</span>
                    <span>d</span>
                    <span>i</span>
                    <span>n</span>
                    <span>g</span>
                  </span>
                </Select.Option>
              )}
            </Select>
          </Col>
          <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
            <RangePicker
              presets={rangePresets}
              onChange={onRangeChange}
              defaultValue={[fromDate, toDate]}
              style={{
                borderRadius: "0",
                // marginLeft: "10px",
                // width: "10vw",
                width: "100%",
              }}
              allowClear={true}
              format={"DD/MM/YYYY"}
              disabledDate={(current) => current.isAfter(moment())}

              // style={{display:"flex"}}
            />
          </Col>
        </Row>
      </Card>
      <Card className="shadowCardBox">
        <Table
          dataSource={Array.isArray(statisticsCounts) ? statisticsCounts : []}
          // loading={tableLoading}
          pagination={false}
          // pagination={{
          //   showSizeChanger: true,
          //   // pageSize: limit,
          //   total: statisticsCounts?.total_counts,
          //   //   onShowSizeChange: onShowSizeChange,
          //   //   onChange: onPageChange,
          //   pageSizeOptions: ["50", "100", "500"],
          // }}
          columns={columns}
          searchable
          scroll={{ x: "45vh" }}
        />
      </Card>
    </div>
  );
}

export default OverallStatistics;
