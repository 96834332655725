import actions from "./actions";

const {
  GET_SUPPORT_CATEGORY_LIST,
  GET_SUPPORT_SUB_CATEGORY_LIST,
  GET_SUPPORT_SUB_CATEGORY_ANSWER_LIST,
  GET_SUPPORT_CATEGORY_LIST_WITHOUT_PAGINATION,
} = actions;

const initState = {
  supportCategoryData: {},
  supportSubCategoryData: {},
  supportSubCategoryAnswerData: {},
  supportCategoryDataWithoutPagination: {},
};

const supportReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_SUPPORT_CATEGORY_LIST:
      return {
        ...state,
        supportCategoryData: data,
      };
    case GET_SUPPORT_SUB_CATEGORY_LIST:
      return {
        ...state,
        supportSubCategoryData: data,
      };

    case GET_SUPPORT_SUB_CATEGORY_ANSWER_LIST:
      return {
        ...state,
        supportSubCategoryAnswerData: data,
      };
    case GET_SUPPORT_CATEGORY_LIST_WITHOUT_PAGINATION:
      return {
        ...state,
        supportCategoryDataWithoutPagination: data,
      };

    default:
      return state;
  }
};
export default supportReducer;
