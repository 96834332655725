import { message } from "antd";
import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";
import actions from "./actions";
const {
  getAdminList,
  fetchAdminDataById,
  getAdminCaptainList,
  getAdminListWithoutPagination,
} = actions;
export const getAdminData = (
  searchKeyword,
  pageSize,
  limit,
  city,
  key,
  sort,
  selectedTime
) => {
  // const history=useNavigate();
  let finaldata = "";

  city !== "" &&
    city?.map((data) => (finaldata = finaldata + "&city_id=" + data));
  return async (dispatch) => {
    let queryParametere =
      searchKeyword !== null
        ? "?search=" +
          searchKeyword +
          "&page=" +
          pageSize +
          "&limit=" +
          limit +
          finaldata +
          "&key=" +
          key +
          "&value=" +
          sort +
          "&login_time=" +
          selectedTime
        : "?page=" +
          pageSize +
          "&limit=" +
          limit +
          finaldata +
          "&key=" +
          key +
          "&value=" +
          sort +
          "&login_time=" +
          selectedTime;

    const resp = await DataService.get(API.admin.get + queryParametere);
    //  console.log(resp);
    if (!resp.data.error) {
      dispatch(getAdminList(resp.data.data));
      return resp.data.data;
    } else {
      // message.error(resp.data.message);
      // history.push("forbidden");
      return "false";
    }
  };
};
export const createAdminData = (payload) => {
  for (const pair of payload.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return async (dispatch) => {
    const resp = await DataService.post(API.admin.add, payload, {
      "Content-Type": "application/x-www-form-urlencoded",
    });
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const updateAdminData = (content, id) => {
  return async (dispatch) => {
    const resp = await DataService.patch(API.admin.update + "/" + id, content, {
      "Content-Type": "application/x-www-form-urlencoded",
    });
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const deleteAdminData = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.admin.delete + "/" + id);
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getAdminDataById = (id) => {
  // const history=useNavigate();
  return async (dispatch) => {
    const resp = await DataService.get(API.admin.get + "/" + id);
    //  console.log(resp);
    if (!resp.data.error) {
      dispatch(fetchAdminDataById(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return "false";
    }
  };
};

export const changePassword = (id, payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.admin.updatePassword + id, payload);
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getAdminCaptainData = (
  pageNo,
  pageSize,
  id,
  search,
  sort,
  key,
  selectedTime
) => {
  return async (dispatch) => {
    let queryParametere = "?page=" + pageNo + "&limit=" + pageSize;
    if (search) {
      queryParametere += "&search=" + search;
    }
    if (sort) {
      queryParametere += "&value=" + sort;
    }
    if (key) {
      queryParametere += "&key=" + key;
    }
    const resp = await DataService.get(
      API.admin.captains + id + queryParametere
    );
    //  console.log(resp);
    if (!resp.data.error) {
      dispatch(getAdminCaptainList(resp.data.data));
      return resp.data.data;
    } else {
      // message.error(resp.data.message);
      // history.push("forbidden");
      return "false";
    }
  };
};

export const getAdminDataWithPagination = (city) => {
  // const history=useNavigate();
  // let finaldata = "";

  // city != "" &&
  //   city?.map((data) => (finaldata = finaldata + "&city=" + data));
  return async (dispatch) => {
    let queryParametere = "?city=" + city;

    const resp = await DataService.get(
      API.admin.getWithPagination + queryParametere
    );
    //  console.log(resp);
    if (!resp.data.error) {
      dispatch(getAdminListWithoutPagination(resp.data.data));
      return resp.data.data;
    } else {
      // message.error(resp.data.message);
      // history.push("forbidden");
      return "false";
    }
  };
};
