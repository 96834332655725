import actions from "./actions";
import { message } from "antd";
import { API } from "../../../config/api";
import { DataService } from "../../../config/dataService/dataService";

const {
  getCity,
  updateCity,
  createCity,
  deleteCity,
  getCityWithPagination,
  getStates,
} = actions;

export const getCityData = (search) => {
  return async (dispatch) => {
    let queryParamater = "?search=" + search;
    const resp = await DataService.get(API.city.get + queryParamater);

    if (!resp.data.error) {
      dispatch(getCity(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const getCityDataPagination = (
  search,
  page,
  limit,
  serviceAvailable,
  state
) => {
  return async (dispatch) => {
    let queryParamater = serviceAvailable
      ? "?city=" +
        search +
        "&page=" +
        page +
        "&limit=" +
        limit +
        "&serviceAvailable=" +
        serviceAvailable +
        "&state_id=" +
        state
      : "?city=" +
        search +
        "&page=" +
        page +
        "&limit=" +
        limit +
        "&state_id=" +
        state;
    const resp = await DataService.get(
      API.city.getWithPagination + queryParamater
    );

    if (!resp.data.error) {
      dispatch(getCityWithPagination(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const updateCityData = (payload, id) => {
  // console.log("update role payload", id, payload);
  return async (dispatch) => {
    const resp = await DataService.put(API.city.update + "/" + id, payload);
    if (!resp.data.error) {
      dispatch(updateCity(resp.data.data));
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const createCityData = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.city.post, payload);

    if (Object.keys(resp.data).length > 0 && !resp.data.error) {
      dispatch(createCity(resp.data.data));
      message.success(resp.data.message);

      return true;
    } else {
      // message.error(resp..message);
      return false;
    }
  };
};

export const deleteCityData = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.city.delete + "/" + id);

    if (!resp.data.error) {
      dispatch(deleteCity(resp.data.data));
      message.success(resp.data.message);

      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getStateData = () => {
  return async (dispatch) => {
    const resp = await DataService.get(API.state.get);

    if (!resp.data.error) {
      dispatch(getStates(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.message);
      return false;
    }
  };
};
