import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const { getInsuranceList } = actions;

export const getAllInsuranceList = ({
  pageNo,
  pageSize,
  searchFilter,

  selectedCity,

  key,
  sort,
  fromDate,
  toDate,
}) => {
  let finaldata = "";

  selectedCity !== "" &&
    selectedCity?.map((data) => (finaldata = finaldata + "&city_id=" + data));

  return async (dispatch) => {
    const queryParameter =
      "&page=" +
      pageNo +
      "&limit=" +
      pageSize +
      "&search=" +
      searchFilter +
      finaldata +
      "&is_driver_approved=true" +
      (key && "&key=" + key) +
      (sort && "&value=" + sort) +
      (fromDate &&
        "&insurance_valid_till_from=" + fromDate.format("YYYY-MM-DD")) +
      (toDate && "&insurance_valid_till_to=" + toDate.format("YYYY-MM-DD"));

    const resp = await DataService.get(API.drivers.getAll + queryParameter);

    if (!resp.data.error) {
      dispatch(getInsuranceList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
