import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const { getFeedback } = actions;

export const getAppFeedback = (params) => {
  let queryString = "?";
  let equalString = "=";
  Object.keys(params).map((v) => {
    return (queryString += v && v + equalString + params[v] + "&");
  });

  return async (dispatch) => {
    const resp = await DataService.get(API.Feedback.get + queryString);
    // console.log(resp,"dd");
    if (!resp.data.error) {
      dispatch(getFeedback(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
