import actions from "./actions.js";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const { getRole, createRole, updateRole, deleteRole, setRole } = actions;

export const getRoleManagementData = (params) => {
  // console.log("roleManagementResp2")
  let queryString = "?";
  let equalString = "=";
  Object.keys(params).map((v) => {
    return (queryString += v + equalString + params[v] + "&");
  });
  return async (dispatch) => {
    const resp = await DataService.get(API.roleManagement.get + queryString);
    // console.log('roleManagementResp', resp);
    if (!resp.data.error) {
      dispatch(getRole(resp.data));

      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const updateRoleById = (id, payload) => {
  console.log("id : ", id);
  console.log("id : ", payload);
  return async (dispatch) => {
    const resp = await DataService.patch(
      API.roleById.patch + "/" + id,
      payload
    );
    console.log("create role: ", resp);
    if (!resp.data.error) {
      dispatch(createRole(resp.data.data));
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const setRoleData = () => {
  // console.log("create role payload", payload);

  return async (dispatch) => {
    const resp = await DataService.get(API.roleManagement.get);
    if (!resp.data.error) {
      dispatch(setRole(resp.data.data));
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const createRoleData = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.roleManagement.post, payload);
    if (!resp.data.error) {
      dispatch(createRole(resp.data.data));
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const credentialRoleData = (payload, id) => {
  return async (dispatch) => {
    const resp = await DataService.patch(
      API.roleManagement.updateRoleManagement + id,
      payload
    );
    if (!resp.data.error) {
      dispatch(updateRole(resp.data.data));
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const removeRoleData = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.roleManagement.delete + `/${id}`);

    if (!resp.data.error) {
      dispatch(deleteRole(resp.data.data));
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
