const actions = {

  GET_RATING_TAGS: "GET_RATING_TAGS",


  getRatingTags: (data) => {
    return {
      type: actions.GET_RATING_TAGS,
      data,
    };
  },
};

export default actions;
