import actions from "./actions";

const { DELETE_CITY, PUT_CITY, GET_CITY, CREATE_CITY,GET_CITY_WITH_PAGINATION,GET_STATES } = actions;

const initState = {
  cityData: [],
  cityDataWithPagination:{},
  newCityData: {},
  states:[]
};

const cityDataReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_CITY:
      return {
        ...state,
        cityData: data,
      };
      case GET_CITY_WITH_PAGINATION:
        return {
          ...state,
          cityDataWithPagination: data,
        };
    case PUT_CITY:
      return {
        ...state,
        newCityData: data,
      };
    case CREATE_CITY:
      return {
        ...state,
        newCity: data,
      };
    case DELETE_CITY:
      return {
        ...state,
        City: data,
      };
      case GET_STATES:
        return {
          ...state,
          states: data,
        };
    default:
      return state;
  }
};
export default cityDataReducer;
