import { Button, Card, Col, Divider, Modal, Row } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicExtended from "ckeditor5-build-classic-extended";
import {
  getAboutUsContent,
  updateAboutUsContent,
} from "../../redux/About Us/actionCreatore";

import { useDispatch, useSelector } from "react-redux";
import { checkAction } from "../../utility/commonFunction";
import Loader from "../loader/loader";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";

const AboutUs = () => {
  // CKEDITOR FEATURES DEFINE
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    getAPI();
    window.scrollTo(0, 0);
  }, []);
  const getAPI = async () => {
    setLoading(true);
    const resp = await dispatch(getAboutUsContent());
    if (resp) {
      setLoading(false);
    }
  };
  const Content = useSelector((state) => state?.AboutUsReducer?.content);
  const [data, setData] = useState(Content.content);
  const showModal = () => {
    setIsModalOpen(true);
    setData(Content.content);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const saveContent = async () => {
    setIsModalOpen(false);
    setLoading(true);

    const SaveContent = {
      content: data,
    };
    const resp = await dispatch(updateAboutUsContent(SaveContent, Content._id));
    if (resp) {
      getAPI();
    }
  };

  const features = {
    fullPage: true,
    allowedContent: true,
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "blockQuote",
      "ckfinder",
      "|",
      "imageTextAlternative",
      "imageUpload",
      "imageResize",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "mediaEmbed",
      "insertTable",
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "|",
      "undo",
      "redo",
      "fontFamily",
      "fontSize",
    ],
  };
  return (
    <div className="common_layout content-container">
      {!loading && (
        <>
          <Card>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <h1 className="heading">About Us</h1>
                <BreadCrumb />
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                {checkAction("aboutUs", "edit") && (
                  <Button
                    style={{ float: "right", color: "white" }}
                    className="ADD"
                    key="1"
                    size="large"
                    type="primary"
                    onClick={showModal}
                  >
                    Edit Content
                  </Button>
                )}
              </Col>
            </Row>
            <Divider />
            <div
              className="ck-content"
              dangerouslySetInnerHTML={{
                __html: Content.content,
              }}
            />
          </Card>
          <Modal
            title={"Edit About Us Content"}
            open={isModalOpen}
            onOk={saveContent}
            closable={true}
            maskClosable={false}
            onCancel={handleCancel}
            bodyStyle={{ width: "100%" }}
            // centered
            className="aboutModal"
          >
            <CKEditor
              data={data}
              config={features}
              editor={ClassicExtended}
              onReady={false}
              checkAction={true}
              onChange={(event, editor) => {
                const data = editor.getData();
                console.log("data", data);
                setData(data);
              }}
            />
          </Modal>
        </>
      )}
      {loading && <Loader />}
    </div>
  );
};

export default AboutUs;
