const actions = {
  GET_FARE_LIST: "GET_FARE_LIST",
  GET_FARE_BY_ID: "GET_FARE_BY_ID",

  getFareList: (data) => {
    return {
      type: actions.GET_FARE_LIST,
      data,
    };
  },
  getFarebyId: (data) => {
    return {
      type: actions.GET_FARE_BY_ID,
      data,
    };
  },
};

export default actions;
