import {
  Card,
  Col,
  Divider,
  Input,
  Radio,
  Rate,
  Row,
  Select,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import React, { Children, useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { TopPagination } from "../../components/utilities/auth-info/auth-info-style";
import { useDispatch, useSelector } from "react-redux";
import {
  getDriversRating,
  getRidersRating,
} from "../../redux/Ratings/actionCreatore";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import { getContactUsList } from "../../redux/Contactus/actionCreatore";
import { getCityData } from "../../redux/Masters/City/actionCreatore";
import { useTranslation } from "react-i18next";
import { getItem } from "../../utility/localStorageControl";
import { SUPERADMIN } from "../../config/dataService/dataService";
const ContactUs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = getItem("user_details");
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(50);
  const [selectedCity, setSelectedCity] = useState(
    user?.role !== "super_admin" ? user?.city_id?._id : ""
  );
  const [activeKey, setActiveKey] = useState("rider");
  const [search, setSearch] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    reteriveContactUsList();
    reteriveCityList();
  }, [activeKey]);

  const reteriveContactUsList = async () => {
    setLoading(true);
    const resp = await dispatch(
      getContactUsList(pageNo, limit, activeKey, selectedCity, searchValue)
    );
    if (resp) {
      // console.log(resp);
      setLoading(false);
    }
  };
  let rating = [];
  const contacUsList = useSelector(
    (state) => state.contactUs.conatctUsListData
  );
  // console.log("contacUsList", contacUsList);

  const reteriveCityList = async () => {
    setLoading(true);
    const resp = await dispatch(getCityData(""));
    if (resp) {
      setLoading(false);
    }
  };
  let cityList = [];
  cityList = useSelector((state) => state.city.cityData);
  cityList = cityList?.sort((a, b) => a.name?.localeCompare(b.name));

  const handleSearch = async (value) => {
    setSearch(value);

    await dispatch(getCityData(value));
  };
  const handleCitySelected = async (value) => {
    setSelectedCity(value);

    await dispatch(getContactUsList(1, limit, activeKey, value, searchValue));
  };

  const handleSearchFilter = async (value) => {
    setSearchValue(value);

    await dispatch(
      getContactUsList(1, limit, activeKey, selectedCity, value, searchValue)
    );
  };

  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    await dispatch(
      getContactUsList(
        page,
        limit,
        activeKey,
        selectedCity,
        selectedCity,
        searchValue
      )
    );
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setLimit(pageSizes);
    await dispatch(
      getContactUsList(
        1,
        pageSizes,
        activeKey,
        selectedCity,
        selectedCity,
        searchValue
      )
    );
  };

  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1,
    },
    {
      title: t("name"),
      // dataIndex: ["userId[0]", "name"],
      key: "RiderName",
      render: (data) => {
        return <span>{data?.userId[0]?.name} </span>;
      },
    },
    {
      title: "Email",
      dataIndex: ["email"],

      key: "email",
    },
    {
      title: t("phoneNo"),
      dataIndex: ["number"],

      key: "number",
    },
    {
      title: t("city"),

      key: "city",
      render: (data) => (
        <span>{data && data?.city_id ? data?.city_id?.name : "-"}</span>
      ),
    },
    {
      title: "Message",
      dataIndex: ["message"],

      key: "message",
    },
  ];
  const { TabPane } = Tabs;

  const handleTabClick = (key) => {
    setActiveKey(key);
  };
  return (
    <>
      <div className="common_layout content-container ">
        <h1 className="heading">Contact us</h1>
        <BreadCrumb />

        {/* <p>
            Here, You have the ability to see the Ratings given by riders/drivers
            about their fellow drivers/riders, which relates to their behavior
            during a particular ride.
          </p> */}

        <Divider />
        <Tabs type="card" activeKey={activeKey} onTabClick={handleTabClick}>
          <TabPane tab="Riders" key="rider">
            <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col lg={4} className="total">
                  <span className="total">
                    Total {contacUsList?.total_counts}
                  </span>
                </Col>
                <Col lg={20} md={24} sm={24} xs={24}>
                  {user.role == "super_admin" && (
                    <Select
                      placeholder="Choose city"
                      style={{ width: 200, marginLeft: "10px", float: "right" }}
                      showSearch
                      filterOption={false}
                      // onSearch={(e) => handleSearch(e)}
                      allowClear
                      onClear={(e) => {
                        handleSearch("");
                        handleCitySelected("");
                      }}
                      onSelect={(value) => handleCitySelected(value)}
                    >
                      {cityList.length > 0 &&
                        cityList?.map((data) => {
                          return (
                            <Select.Option
                              value={data._id}
                              key={data._id}
                              className="selectOptions"
                            >
                              {data.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  )}
                  <Input
                    placeholder="Search By Phone, Email Or Message"
                    style={{
                      width: "400px",
                      float: "right",
                      marginLeft: "10px",
                      float: "right",
                    }}
                    prefix={<SearchOutlined />}
                    onChange={(event) => handleSearchFilter(event.target.value)}
                  />
                </Col>
              </Row>
            </Card>
            <Card className="shadowCardBox">
              {/* <p className="total">
                  The Ratings given by Drivers about their fellow Riders
                </p> */}

              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                dataSource={contacUsList.list}
                // columns={columns}
                columns={
                  user.role == SUPERADMIN
                    ? columns
                    : columns.filter((key) => key.title != "City")
                }
                searchable
                scroll={{ x: "45vh" }}
                pagination={{
                  showSizeChanger: true,
                  pageSize: limit,
                  total: contacUsList?.total_counts,
                  onShowSizeChange: onShowSizeChange,
                  onChange: onPageChange,
                  pageSizeOptions: ["50", "100", "500"],
                }}
              />
            </Card>
          </TabPane>
          <TabPane tab="Drivers" key="driver">
            <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col lg={4}>
                  <span className="total">
                    Total {contacUsList?.total_counts}
                  </span>
                </Col>

                <Col lg={20} md={24} sm={24} xs={24}>
                  {user.role == SUPERADMIN && (
                    <Select
                      placeholder="Choose city"
                      style={{ width: 200, marginLeft: "10px", float: "right" }}
                      showSearch
                      filterOption={false}
                      // onSearch={(e) => handleSearch(e)}
                      allowClear
                      onClear={(e) => {
                        handleSearch("");
                        handleCitySelected("");
                      }}
                      onSelect={(value) => handleCitySelected(value)}
                    >
                      {cityList.length > 0 &&
                        cityList?.map((data) => {
                          return (
                            <Select.Option
                              value={data._id}
                              key={data._id}
                              className="selectOptions"
                            >
                              {data.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  )}
                  <Input
                    placeholder="Search By Phone, Email Or Message"
                    style={{
                      width: "400px",
                      float: "right",
                      marginLeft: "10px",
                      float: "right",
                    }}
                    prefix={<SearchOutlined />}
                    onChange={(event) => handleSearchFilter(event.target.value)}
                  />
                </Col>
              </Row>
            </Card>
            <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                dataSource={contacUsList.list}
                // columns={columns}
                columns={
                  user.role == SUPERADMIN
                    ? columns
                    : columns.filter((key) => key.title != "City")
                }
                searchable
                scroll={{ x: "45vh" }}
                pagination={{
                  showSizeChanger: true,
                  pageSize: limit,
                  total: contacUsList?.total_counts,
                  onShowSizeChange: onShowSizeChange,
                  onChange: onPageChange,
                  pageSizeOptions: ["50", "100", "500"],
                }}
              />
            </Card>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default ContactUs;
