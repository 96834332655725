import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const { getPLDoc, updatePLDoc } = actions;

export const getDoc = () => {
  return async (dispatch) => {
    const resp = await DataService.get(API.PLDOC.get);

    if (!resp.data.error) {
      dispatch(getPLDoc(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const updateDoc = (content, id) => {
  return async (dispatch) => {
    const resp = await DataService.patch(API.PLDOC.update + id, content, {
      "Content-Type": "application/x-www-form-urlencoded",
    });
    if (!resp.data.error) {
      dispatch(updatePLDoc(resp.data.data));
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
