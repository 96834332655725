const actions = {

  GET_TIPS: "GET_TIPS",


  getTips: (data) => {
    return {
      type: actions.GET_TIPS,
      data,
    };
  },
};

export default actions;
