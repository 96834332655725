import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";
import React, { useContext, useEffect, useState } from "react";
import Geocode from "react-geocode";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  AUTOCLICKER,
  GOOGLE_MAP_API_KEY,
  GOOGLE_MAP_API_SUBTYPE_QUOTA_SERVICE,
  GOOGLE_MAP_API_SUBTYPE_VIEW_PORT_INFO_SERVICE,
  GOOGLE_MAP_API_TYPE_MAPS,
  GOOGLE_MAP_MODULE_NAME_DRIVER_LOCATION_ON_MAP,
  IMAGE_URL_PREFIX,
  OFFDUTY,
  ONDUTY,
} from "../../config/dataService/dataService";
import { getDriverById } from "../../redux/drivers/actionCreatore";
import { addMapStatistics } from "../../redux/MapStatistics/actionCreatore";
import Auto1 from "../../static/img/auto1.png";
import bike from "../../static/img/bikeTopView.png";
import Car from "../../static/img/car.jpeg";
import { getItem } from "../../utility/localStorageControl";
import Loader from "../loader/loader";
import { SocketContext } from "../socket/socketContext";
function LiverDriver(props) {
  const { id } = useParams();
  const [vehicleType, setVehicleType] = useState(bike);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const [location, setLocation] = useState({});
  const user = getItem("user_details");
  console.log("socket", socket);
  const [popup, setPopup] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});
  const [locationName, setLocationName] = useState("");
  const [dutyStatus, setDutyStatus] = useState("");
  const [dutyColor, setDutyColor] = useState("");
  Geocode.setApiKey(GOOGLE_MAP_API_KEY);
  useEffect(() => {
    window.scrollTo(0, 0);
    reteriveDriverData();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.emit("userCurrentLocation", { id: id, webRoleUserId: user._id });

      socket.on("userCurrentLocationData", (data) => {
        // console.log("data", data);
        setLocation(data.location.coordinates);

        // setLoading(false)
      });
    }
  }, [socket]);

  // console.log("location", location);
  const reteriveDriverData = async () => {
    const resp = await dispatch(getDriverById(id));
    if (resp) {
    }
  };

  const driverData = useSelector((state) => state.driver.driverById);
  //  vehicleType=driverData?.
  // console.log(driverData);

  useEffect(() => {
    if (driverData) {
      const string = driverData?.duty_status?.split("_");
      var duty = "";
      string?.map(
        (str) =>
          (duty = duty + str.charAt(0).toUpperCase() + str.slice(1) + " ")
      );
      setDutyStatus(duty.trim());
      if (driverData?.duty_status == AUTOCLICKER) setDutyColor("skyblue");
      if (driverData?.duty_status == ONDUTY) setDutyColor("lightGreen");
      if (driverData?.duty_status == OFFDUTY) setDutyColor("red");

      console.log("dutty ", dutyStatus);
      if (driverData?.vehicle_type_id?.name == "G-Bike") setVehicleType(bike);
      if (driverData?.vehicle_type_id?.name == "G-Auto") setVehicleType(Auto1);
      if (driverData?.vehicle_type_id?.name == "G-Cab | Suv")
        setVehicleType(Car);

      setLoading(false);
    }
  }, [driverData]);

  const getLocationName = async () => {
    const response = await Geocode.fromLatLng(location[1], location[0]);
    if (response) {
      // console.log("address",response.results[0].formatted_address);
      setLocationName(response.results[0].formatted_address);
    }
  };
  // Geocode.fromLatLng(location[1], location[0]).then(
  //   (response) => {
  //     const address = response.results[0].formatted_address;
  //     console.log("address", address);
  //     setLocationName(address)
  //   },
  //   (error) => {
  //     console.error(error);
  //   }
  // );
  const handleMapReady = async (mapProps, map) => {
    // Map has loaded successfully
    await dispatch(
      addMapStatistics({
        user_id: user._id,
        googleApiType: GOOGLE_MAP_API_TYPE_MAPS,
        googleSubApiType: GOOGLE_MAP_API_SUBTYPE_QUOTA_SERVICE,
        moduleName: GOOGLE_MAP_MODULE_NAME_DRIVER_LOCATION_ON_MAP,
        platformType: "web",
        originFrom: user?.role,
        apiStatus: "success",
      })
    );
    await dispatch(
      addMapStatistics({
        user_id: user._id,
        googleApiType: GOOGLE_MAP_API_TYPE_MAPS,
        googleSubApiType: GOOGLE_MAP_API_SUBTYPE_VIEW_PORT_INFO_SERVICE,
        moduleName: GOOGLE_MAP_MODULE_NAME_DRIVER_LOCATION_ON_MAP,
        platformType: "web",
        originFrom: user?.role,
        apiStatus: "success",
      })
    );
  };
  const handleMapError = async (mapProps, map) => {
    // Error occurred while loading the map
    await dispatch(
      addMapStatistics({
        user_id: user._id,
        googleApiType: GOOGLE_MAP_API_TYPE_MAPS,
        googleSubApiType: GOOGLE_MAP_API_SUBTYPE_QUOTA_SERVICE,
        moduleName: GOOGLE_MAP_MODULE_NAME_DRIVER_LOCATION_ON_MAP,
        platformType: "web",
        originFrom: user?.role,
        apiStatus: "error",
      })
    );
    await dispatch(
      addMapStatistics({
        user_id: user._id,
        googleApiType: GOOGLE_MAP_API_TYPE_MAPS,
        googleSubApiType: GOOGLE_MAP_API_SUBTYPE_VIEW_PORT_INFO_SERVICE,
        moduleName: GOOGLE_MAP_MODULE_NAME_DRIVER_LOCATION_ON_MAP,
        platformType: "web",
        originFrom: user?.role,
        apiStatus: "error",
      })
    );
  };
  return (
    <>
      <div className="common_layout content-container">
        {loading && <Loader />}
        {!loading && (
          <div style={{ height: "450px", width: "450px" }}>
            <Map
              google={props.google}
              zoom={14}
              // initialCenter={{
              //   lat: 23.0746719,
              //   lng: 72.5255954,
              // }}
              center={{
                lat: location[1],
                lng: location[0],
              }}
              // style={{height:'95vh', width:"70%"}}
              onReady={handleMapReady}
              onError={handleMapError}
            >
              <Marker
                onClick={(props, marker) => {
                  setPopup(true);
                    setSelectedPlace(props);
                    setActiveMarker(marker);
                  // getLocationName();

                  // console.log("location name", props);
                  // console.log("location marker", marker);
                }}
                // name={"Current location"}
                icon={{
                  url:
                    IMAGE_URL_PREFIX +
                    driverData?.vehicle_type_id?.image_top_view,

                  scaledSize: new window.google.maps.Size(40, 40),
                }}
                onMouseover={() => console.log("hivered")}
                position={{
                  lat: location[1],
                  lng: location[0],
                }}
              ></Marker>

              <InfoWindow visible={popup} marker={activeMarker}>
                <div style={{ background: dutyColor }}>
                  <h1>{driverData?.location?.location_name}</h1>
                  <h4>
                    ({location[1]} , {location[0]})
                  </h4>
                  {/* <h4>Latitude : {location[1]}</h4>
                  <h4>Longitude : </h4> */}
                  <h4 style={{ width: "fit-content" }}>
                    Duty Status : {dutyStatus}{" "}
                  </h4>
                </div>
              </InfoWindow>
            </Map>
          </div>
        )}
      </div>
    </>
  );
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_API_KEY,
})(LiverDriver);
