import { SearchOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Divider,
  Input,
  Rate,
  Row,
  Select,
  Table,
  Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TopPagination } from "../../components/utilities/auth-info/auth-info-style";
import { getAppFeedback } from "../../redux/App Feedback/actionCreatore";
import { FirstLetterCapital } from "../../utility/commonFunction";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
const Feedback = () => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState("rider");
  const [rate, setRate] = useState("");
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("rider");
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesize, setPageSize] = useState(50);
  const [inputSearch, setInputSearch] = useState("");
  const handleTabClick = (key) => {
    setActiveKey(key);
    setRole(key);
    setRate("");
  };
  const handleRating = (rate) => {
    setRate(rate ? rate : "");
  };
  useEffect(() => {
    getFeedbackList(currentPage);
  }, [pagesize, role, rate, currentPage, inputSearch]);

  const getFeedbackList = async (Page) => {
    setLoading(true);

    let params = {
      page: Page,
      limit: pagesize,
      role: role,
      rating: rate,
      search: inputSearch,
    };

    const resp = await dispatch(getAppFeedback(params));
    if (resp) {
      setLoading(false);
    }
  };
  const onPageChange = async (page, pageSizes) => {
    setCurrentPage(page);
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
  };

  const feedbackList = useSelector((state) => state?.FeedbackReducer?.feedback);

  // {
  //   console.log(currentPage, "pageNumber");
  // }
  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (currentPage - 1) * pagesize,
    },
    {
      title: `${FirstLetterCapital(role)}'s name`,
      // dataIndex: ["user_id", "name"],
      key: "name",
      render: (data) => {
        return <span>{data?.user_id?.name ? data?.user_id?.name : "-"}</span>;
      },
    },
    {
      title: "Mobile Number",
      dataIndex: ["user_id", "number"],
      key: "name",
    },
    {
      title: "Ratings",
      key: "rating",
      render(data, record) {
        return (
          <>
            <Rate value={data.rating} allowHalf disabled />
            <span style={{ color: "#ccc" }}>
              {/* {`(${data.rating})`} */}({parseFloat(data.rating).toFixed(2)})
            </span>
          </>
        );
      },
    },
    {
      title: "Feedback",
      // dataIndex: "description",
      key: "feedback",
      render: (data) => {
        return <span>{data?.description ? data?.description : "-"}</span>;
      },
    },
  ];

  const items = [
    {
      key: "rider",
      label: "Rider App",
      children: (
        <>
          <Card style={{ marginBottom: 10 }} className="shadowCardBox">
            <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
              {/* <Col lg={12} md={12} sm={12} xs={12} className="total">
                  <p>The Feedback given by User about Rider App</p>
                </Col> */}
              <Col lg={4} className="total">
                Total {feedbackList?.total_counts}
              </Col>
              <Col lg={20}>
                <Input
                  placeholder="Search By Name Or Phone"
                  style={{
                    width: "400px",
                    float: "right",
                    marginLeft: "20px",
                  }}
                  onChange={(event) => setInputSearch(event.target.value)}
                  prefix={<SearchOutlined />}
                />
                <div style={{ float: "right" }}>
                  {/* Filter By Ratings: */}
                  <Select
                    placeholder="Filter By Ratings"
                    style={{ width: 300, marginLeft: "10px" }}
                    onSelect={handleRating}
                    value={rate ? rate : null}
                    allowClear
                    onClear={handleRating}

                    // filterOption={(input, option) =>
                    //   option.props.children
                    //     .toLowerCase()
                    //     .indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {/* <Select.Option value="" key={""}>
                        All{" "}
                      </Select.Option> */}
                    <Select.Option value="5" key={5}>
                      <Rate value={5} disabled />
                    </Select.Option>
                    <Select.Option value="4" key={4}>
                      <Rate value={4} disabled />
                    </Select.Option>
                    <Select.Option value="3" key={3}>
                      <Rate value={3} disabled />
                    </Select.Option>
                    <Select.Option value="2" key={2}>
                      <Rate value={2} disabled />
                    </Select.Option>
                    <Select.Option value="1" key={1}>
                      <Rate value={1} disabled />
                    </Select.Option>
                    {/* <Select.Option value="0" key={0}>
                        <Rate value={0} disabled />
                      </Select.Option> */}
                  </Select>
                </div>
              </Col>
            </Row>
          </Card>
          <Card className="shadowCardBox">
            <p className="total">The Feedback given by Rider about Rider App</p>
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              style={{ marginTop: "20px" }}
              dataSource={feedbackList.list}
              columns={columns}
              searchable
              pagination={{
                showSizeChanger: true,
                pageSize: pagesize,
                total: feedbackList.total_counts,
                onShowSizeChange: onShowSizeChange,
                onChange: onPageChange,
                pageSizeOptions: ["50", "100", "500"],
              }}
              scroll={{ x: "45vh" }}
            />
          </Card>
        </>
      ),
    },
    {
      key: "driver",
      label: "Driver App",
      children: (
        <>
          <Card style={{ marginBottom: 10 }} className="shadowCardBox">
            <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
              {/* <Col lg={12} md={12} sm={12} xs={12} className="total">
                   <p>The Feedback given by User about Driver App</p>
                </Col> */}
              <Col lg={4} className="total">
                Total {feedbackList?.total_counts}
              </Col>
              <Col lg={20}>
                <Input
                  placeholder="Search By Name Or Phone"
                  style={{
                    width: "400px",
                    float: "right",
                    marginLeft: "20px",
                  }}
                  prefix={<SearchOutlined />}
                />
                <div style={{ float: "right" }}>
                  {/* Filter By Ratings: */}
                  <Select
                    placeholder="Filter By Ratings"
                    style={{ width: 300, marginLeft: "10px" }}
                    onChange={handleRating}
                    value={rate ? rate : null}
                    allowClear
                    onClear={handleRating}

                    // filterOption={(input, option) =>
                    //   option.props.children
                    //     .toLowerCase()
                    //     .indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    <Select.Option value="5" key={5}>
                      <Rate value={5} disabled />
                    </Select.Option>
                    <Select.Option value="4" key={4}>
                      <Rate value={4} disabled />
                    </Select.Option>
                    <Select.Option value="3" key={3}>
                      <Rate value={3} disabled />
                    </Select.Option>
                    <Select.Option value="2" key={2}>
                      <Rate value={2} disabled />
                    </Select.Option>
                    <Select.Option value="1" key={1}>
                      <Rate value={1} disabled />
                    </Select.Option>
                    <Select.Option value="0" key={0}>
                      <Rate value={0} disabled />
                    </Select.Option>
                  </Select>
                </div>
              </Col>
            </Row>
          </Card>
          <Card className="shadowCardBox">
            <p className="total">
              The Feedback given by Driver about Driver App
            </p>

            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              style={{ marginTop: "20px" }}
              dataSource={feedbackList.list}
              pagination={{
                showSizeChanger: true,
                pageSize: pagesize,
                total: feedbackList.total_counts,
                onShowSizeChange: onShowSizeChange,
                onChange: onPageChange,
                pageSizeOptions: ["50", "100", "500"],
              }}
              columns={columns}
              searchable
              scroll={{ x: "45vh" }}
            />
          </Card>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="common_layout content-container ">
        <h1 className="heading">{t("appFeedback")}</h1>
        <BreadCrumb />

        <Divider />
        <Tabs
          type="card"
          activeKey={activeKey}
          onTabClick={handleTabClick}
          items={items}
        >
          {/* <TabPane tab="Rider App" key="rider">
          
          </TabPane>
          <TabPane tab="Driver App" key="driver">
           
          </TabPane> */}
        </Tabs>
      </div>
    </>
  );
};

export default Feedback;
