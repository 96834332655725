import { Button, Card, Checkbox, Col, Divider, Row } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getRoleDataById,
  updateRoleDataById,
} from "../../redux/rolebyid/actionCreatore";
import { WordFormate } from "../../utility/commonFunction";
import { getItem } from "../../utility/localStorageControl";

const Roles = () => {
  const userDetails = getItem("user_details");
  let id = window.location.pathname.split("/").pop();
  const obj = useSelector((state) => state?.roleDataByIdReducer?.roleData);

  const [checkedLists, setCheckedLists] = useState({});
  const [availableOptions, setAvailabeleOption] = useState({});

  useEffect(() => {
    roleDataApi();
  }, []);

  const navigate = useNavigate();
  const roleDataApi = async () => {
    let result = await dispatch(getRoleDataById(id));
    const data = result?.data?.data;
    const newObj = Object.keys(data).reduce((acc, key) => {
      if (typeof data[key] == "object") {
        acc[key] = data[key];
      }
      return acc;
    }, {});
    setAvailabeleOption(newObj);
    setCheckedLists(newObj);
  };
  const dispatch = useDispatch();
  const getOptions = (group) => {
    const options = Object.keys(availableOptions[group]).map((key) => ({
      label: key,
      value: key,
    }));
    return options;
  };
  const saveChanges = async () => {
    const a = await dispatch(updateRoleDataById(checkedLists, id));
    if (a) {
      navigate(`/${userDetails.role}/rolemanagement`);
    }
  };
  function updatePermissions(permissions, keys) {
    for (const key in permissions) {
      permissions[key] = keys.includes(key);
    }
    return permissions;
  }
  const { t } = useTranslation();

  return (
    <>
      <div className="common_layout content-container">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">
              {" "}
              {t("role")} - {obj?.title}
            </h1>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              style={{ float: "right" }}
              className="ADD"
              key="1"
              size="large"
              type="primary"
              onClick={saveChanges}
              // disabled
            >
              Save Changes
            </Button>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[16, 16]}>
          {Object.keys(checkedLists && checkedLists).map((group) => (
            <Fragment key={group}>
              <Col
                className="roleCard"
                xxl={4}
                xl={6}
                lg={8}
                md={8}
                sm={12}
                xs={24}
              >
                <Card title={WordFormate(group)} style={{ height: "242px" }}>
                  <Checkbox
                    // indeterminate={
                    //   checkedLists[group] &&
                    //   Object.keys(checkedLists[group]).some(
                    //     (key) => !checkedLists[group][key]
                    //   )
                    // }
                    onChange={(e) => {
                      const newCheckedLists = {
                        ...checkedLists,
                        [group]: e.target.checked
                          ? Object.fromEntries(
                              Object.entries(checkedLists[group]).map(
                                ([key]) => [key, true]
                              )
                            )
                          : Object.fromEntries(
                              Object.entries(checkedLists[group]).map(
                                ([key]) => [key, false]
                              )
                            ),
                      };
                      setCheckedLists(newCheckedLists);
                    }}
                    checked={
                      checkedLists[group] &&
                      Object.keys(checkedLists[group]).every(
                        (key) => checkedLists[group][key]
                      )
                    }
                  >
                    <strong>Check all</strong>
                  </Checkbox>
                  <Checkbox.Group
                    options={getOptions(group)}
                    onChange={(value) => {
                      console.log(value, "baloeu");
                      const newCheckedLists = {
                        ...checkedLists,
                        [group]: updatePermissions(checkedLists[group], value),
                      };
                      setCheckedLists(newCheckedLists);
                    }}
                    value={Object.keys(checkedLists[group]).filter(
                      (key) => checkedLists[group][key]
                    )}
                  />
                </Card>
              </Col>
            </Fragment>
          ))}
        </Row>
      </div>
    </>
  );
};

export default Roles;
