const actions = {
  GET_SUPPORT_CATEGORY_LIST: "GET_SUPPORT_CATEGORY_LIST",
  GET_SUPPORT_SUB_CATEGORY_LIST:"GET_SUPPORT_SUB_CATEGORY_LIST",
  GET_SUPPORT_SUB_CATEGORY_ANSWER_LIST:"GET_SUPPORT_SUB_CATEGORY_ANSWER_LIST",
  GET_SUPPORT_CATEGORY_LIST_WITHOUT_PAGINATION:"GET_SUPPORT_CATEGORY_LIST_WITHOUT_PAGINATION",


  fetchCategoryList: (data) => {
    return {
      type: actions.GET_SUPPORT_CATEGORY_LIST,
      data,
    };
  },
  fetchSubCategoryList:(data) => {
    return {
      type: actions.GET_SUPPORT_SUB_CATEGORY_LIST,
      data,
    };
  },
  fetchSubCategoryAnswerList:(data) => {
    return {
      type: actions.GET_SUPPORT_SUB_CATEGORY_ANSWER_LIST,
      data,
    };
  },
  fetchCategoryListWithoutPagination:(data) => {
    return {
      type: actions.GET_SUPPORT_CATEGORY_LIST_WITHOUT_PAGINATION,
      data,
    };
  },
};

export default actions;
