import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";
import { getItem } from "../../utility/localStorageControl";
import { saveAs } from "file-saver";
const { bookingList, bookingDataById, driverBookingList, riderBookingList } =
  actions;

export const getBookingsList = (
  page,
  limit,
  statuss,
  search,
  city,
  vehicle_id,
  fromDate,
  toDate
) => {
  return async (dispatch) => {
    // const queryParameter="?page="+page+"&limit="+limit+"&status="+status+"&search="+search+"&interestedForCity="+city
    const user = getItem("user_details");
    // const captainID = user._id;
    var status = "";
    for (let val of statuss) {
      status = "&status=" + val + status;
    }
    let finaldata = "";
    let finalVehicleList = "";
    city !== "" &&
      city?.map((data) => (finaldata = finaldata + "&pickup_city_id=" + data));

    vehicle_id !== "" &&
      vehicle_id?.map(
        (data) => (finalVehicleList = finalVehicleList + "&vehicle_id=" + data)
      );

    const queryParameter =
      statuss[0] === ""
        ? "?page=" +
          page +
          "&limit=" +
          limit +
          finalVehicleList +
          finaldata +
          // (user.role === "captain" ? "&captainId=" + captainID : "") +
          "&search=" +
          search +
          (fromDate && "&fromDate=" + fromDate.format("YYYY-MM-DD")) +
          (toDate && "&toDate=" + toDate.format("YYYY-MM-DD"))
        : "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          search +
          finalVehicleList +
          finaldata +
          status +
          // (user.role === "captain" ? "&captainId=" + captainID : "") +
          (fromDate && "&fromDate=" + fromDate.format("YYYY-MM-DD")) +
          (toDate && "&toDate=" + toDate.format("YYYY-MM-DD"));

    const resp = await DataService.get(API.bookings.get + queryParameter);

    if (!resp.data.error) {
      dispatch(bookingList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const getBookingById = (id) => {
  return async (dispatch) => {
    const resp = await DataService.get(API.bookings.getById + "/" + id);

    if (!resp.data.error) {
      dispatch(bookingDataById(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const getDriverBookingsList = (
  page,
  limit,
  driverId,
  status,
  fromDate,
  toDate
) => {
  return async (dispatch) => {
    var statuss = "";
    for (let val of status) {
      statuss = "&status=" + val + statuss;
    }

    const queryParameter =
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&driver_id=" +
      driverId +
      (status[0] !== "" ? statuss : "") +
      (fromDate && toDate && "&fromDate=" + fromDate + "&toDate=" + toDate);

    const resp = await DataService.get(API.bookings.get + queryParameter);
    if (!resp.data.error) {
      dispatch(driverBookingList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const getRiderBookingsList = (
  page,
  limit,
  riderId,
  fromDate,
  toDate
) => {
  return async (dispatch) => {
    const queryParameter =
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&rider_id=" +
      riderId +
      (fromDate && toDate && "&fromDate=" + fromDate.format("YYYY-MM-DD") + "&toDate=" + toDate.format("YYYY-MM-DD"));

    const resp = await DataService.get(API.bookings.get + queryParameter);

    if (!resp.data.error) {
      dispatch(riderBookingList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const multiDeleteRides = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.bookings.delete, payload);

    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getInvoice = (id) => {
  return async () => {
    const resp = await DataService.getPdf(API.bookings.invoiceDownload + id);
    console.log("resp", resp);
    if (!resp.data.error) {
      // const pdfBlob = await new Blob([resp.data], { type: "application/pdf" });
      return resp;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
