import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";
const {
  getDriverRatingList,
  getRiderRatingList,
  getRiderRatingListByDriverId,
} = actions;

export const getRidersRating = ({
  pageNo,
  limit,
  searchBy,
  ratingFilter,
  city,
  fromDate,
  toDate,
}) => {
  return async (dispatch) => {
    const queryParameter =
      "?page=" +
      pageNo +
      "&limit=" +
      limit +
      "&search=" +
      searchBy +
      "&rating=" +
      ratingFilter +
      "&pickup_city_id=" +
      city +
      (fromDate && "&from_date=" + fromDate.format("YYYY-MM-DD")) +
      (toDate && "&to_date=" + toDate.format("YYYY-MM-DD"));
    const resp = await DataService.get(API.rating.getRider + queryParameter);

    if (!resp.data.error) {
      dispatch(getRiderRatingList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getDriversRating = ({
  pageNo,
  limit,
  searchBy,
  ratingFilter,
  city,
  fromDate,
  toDate,
}) => {
  return async (dispatch) => {
    const queryParameter =
      "?page=" +
      pageNo +
      "&limit=" +
      limit +
      "&search=" +
      searchBy +
      "&rating=" +
      ratingFilter +
      "&pickup_city_id=" +
      city +
      (fromDate && "&from_date=" + fromDate.format("YYYY-MM-DD")) +
      (toDate && "&to_date=" + toDate.format("YYYY-MM-DD"));

    const resp = await DataService.get(API.rating.getDriver + queryParameter);

    if (!resp.data.error) {
      dispatch(getDriverRatingList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getRidersRatingByDriverId = (page, limit, driverId) => {
  return async (dispatch) => {
    const queryParameter =
      "?page=" + page + "&limit=" + limit + "&driverId=" + driverId;

    const resp = await DataService.get(API.rating.getRider + queryParameter);

    if (!resp.data.error) {
      dispatch(getRiderRatingListByDriverId(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
