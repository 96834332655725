import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStateRef from "react-usestateref";
import "./Vehicle.css";

import FeatherIcon from "feather-icons-react";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { getCityData } from "../../redux/Masters/City/actionCreatore";
import {
  addTip,
  getTipsData,
  removeTip,
  updateTip,
} from "../../redux/Masters/tips/actionCreatore";
import { checkAction } from "../../utility/commonFunction";
import Loader from "../loader/loader";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import { getItem } from "../../utility/localStorageControl";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
const TipMaster = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [ModalTitle, setModalTitle] = useState("");
  const [updateFlow, setUpdateFlow, setUpdateFlowFileRef] = useStateRef("");
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [searchCity, setSearchCity] = useState(null);
  const [pageSize, setPageSize] = useState(50);
  const [pageNo, setPageNo] = useState(1);
  const [searchValue, setSearchValue] = useState(null);
  const user = getItem("user_details");
  const [selectedCity, setSelectedCity] = useState(
    user?.role != "super_admin" ? user?.city_id?._id : ""
  );
  const { t } = useTranslation();

  const [cityValueInSelect, setCityValueInSelect] = useState(null);
  useEffect(() => {
    // fetchTips(selectedCity);
    reteriveCityList();
    window.scrollTo(0, 0);
  }, []);

  const { data: data, isLoading: isLoading } = useQuery(
    "tipsList",
    () => {
      return fetchTips(selectedCity);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataCity, isLoading: isLoadingCity } = useQuery(
    "cityList",
    () => {
      return reteriveCityList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchTips = async (selectedCity) => {
    // setLoading(true);
    const resp = await dispatch(
      getTipsData(searchValue, pageNo, pageSize, selectedCity)
    );
    if (resp) {
      setLoading(false);
    }
  };

  const tipsList = useSelector((state) => state.tips.tipsData);
  // console.log(tipsList);

  const reteriveCityList = async () => {
    setLoading(true);
    const resp = await dispatch(getCityData(search));
    if (resp) {
      setLoading(false);
    }
  };
  let cityList = [{}];
  cityList = useSelector((state) => state.city.cityData);
  cityList = cityList?.sort((a, b) => a?.name?.localeCompare(b?.name));

  const deleteTipFinal = async (id) => {
    setLoading(true);

    let remove = await dispatch(removeTip(id));
    if (remove) {
      // message.success("Tip removed successfully");
      fetchTips(user?.role != "super_admin" ? user?.city_id?._id : "");
    }
  };
  const deleteTip = (id) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this Tip?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteTipFinal(id);
      },
    });
  };
  let itemInputs = [];
  const editTip = (data) => {
    setModalTitle("Edit Tip");
    setIsModalOpen(true);
    setUpdateFlow(data._id);
    console.log(data);
    itemInputs = data.tip.map((opt, index) => {
      return {
        tip: opt,
        key: index,
        name: opt,
      };
    });
    form.resetFields();
    form.setFieldsValue({
      city: data.city_id._id,
      tips: itemInputs,
    });
  };
  const handleSubmit = async (values) => {
    // setLoading(true);
    form.resetFields();

    let obj = {};

    obj.city_id = values.city;
    if (values.tips) {
      const tips = [];
      values.tips.map((data) => {
        tips.push(data.tip);
      });
      obj.tip = tips;

      if (setUpdateFlowFileRef.current == "") {
        const resp = await dispatch(addTip(JSON.stringify(obj)));
        if (resp) {
          setIsModalOpen(false);
          fetchTips(user?.role != "super_admin" ? user?.city_id?._id : "");
        }
      } else {
        const resp = await dispatch(updateTip(JSON.stringify(obj), updateFlow));
        if (resp) {
          setIsModalOpen(false);
          fetchTips(user?.role != "super_admin" ? user?.city_id?._id : "");
          setUpdateFlow("");
        }
      }
    } else {
      setError(true);
    }
  };
  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1,
    },
    {
      title: "City",

      render: (data, record) => {
        return <span>{data?.city_id?.name}</span>;
      },
    },
    {
      title: "Tips (₹)",
      render: (data, record) => {
        return (
          <span>
            {data.tip.map((tip, index) => (
              <div
                key={data._id + index}
                style={{
                  border: "1px solid green",
                  marginRight: 10,
                  textAlign: "center",
                  justifyContent: "center",
                  background: "yellow",
                  borderRadius: 10,
                  padding: 12,
                  width: "55px",
                  display: "inline-block",
                }}
              >
                {tip}
              </div>
            ))}
          </span>
        );
      },
    },

    {
      title: t("action"),
      // dataIndex: "documents",
      key: "action",
      render(data, record) {
        return (
          <div>
            {" "}
            {checkAction("manageTips", "edit") && (
              <Button
                onClick={() => editTip(data)}
                type="primary"
                // style={{ cursor: "pointer", color: "blue" }}
              >
                <EditOutlined />
                Edit
              </Button>
            )}
            {checkAction("manageTips", "delete") && (
              <Button
                onClick={() => deleteTip(data._id)}
                style={{ cursor: "pointer", marginLeft: 2 }}
                type="primary"
                danger
                disabled
              >
                <DeleteOutlined />
                Delete
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  const showModal = () => {
    form.resetFields();
    setModalTitle("Add New Tip");
    setIsModalOpen(true);
    handleSearchCityInModal("");

    // setSelectedId("")
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedId("")
    form.resetFields();
    handleSearchCityInModal("");
    setUpdateFlow("");
    // setSelectDep(0)
  };

  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    await dispatch(getTipsData(searchValue, page, pageSizes));
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    await dispatch(getTipsData(searchValue, pageNo, pageSizes));
  };

  const handleSearchCityInModal = async (value) => {
    setSearchCity(value);

    await dispatch(getCityData(value));
  };

  const handleSearchCity = async (value) => {
    console.log(value);
    setCityValueInSelect(value);
    fetchTips(value);
    if (value == "") {
      setCityValueInSelect(null);
    } else {
      setCityValueInSelect(value);
    }
  };
  const handleSelectedCity = (value) => {
    setCityValueInSelect(value);
    fetchTips(value);
  };

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">Tips</h1>
            <BreadCrumb />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            {checkAction("manageTips", "add") && (
              <Button
                style={{ float: "right" }}
                className="ADD"
                key="1"
                size="large"
                type="primary"
                onClick={showModal}
              >
                <PlusOutlined /> Add New Tip
              </Button>
            )}
          </Col>
        </Row>
        <Divider />
        {isLoading & isLoadingCity ? (
          <Loader />
        ) : (
          <>
            <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col lg={12} md={12} sm={12} xs={12} className="total">
                  Total {tipsList.total_counts}
                </Col>

                <Col lg={11} md={12} sm={12} xs={12}>
                  <div
                    className="product-list-action d-flex justify-content-between align-items-center"
                    style={{ float: "right" }}
                  >
                    {user?.role == "super_admin" && (
                      <Select
                        placeholder="Choose city"
                        style={{
                          width: 300,

                          maxHeight: "45px",
                          overflowY: "auto",
                        }}
                        showSearch
                        mode="multiple"
                        filterOption={false}
                        allowClear
                        onChange={handleSelectedCity}
                      >
                        {cityList &&
                          cityList.map((data) => {
                            return (
                              <Select.Option
                                value={data._id}
                                key={data._id}
                                className="selectOptions"
                              >
                                {data.name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    )}
                  </div>
                </Col>
              </Row>
            </Card>
            <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                dataSource={tipsList?.list}
                columns={columns}
                searchable
                scroll={{ x: "45vh" }}
                pagination={{
                  showSizeChanger: true,
                  pageSize: pageSize,
                  total: tipsList?.total_counts,
                  onShowSizeChange: onShowSizeChange,
                  onChange: onPageChange,
                  pageSizeOptions: ["50", "100", "500"],
                }}
              />
            </Card>
          </>
        )}
        {/* {loading && <Loader />} */}
      </div>
      <Modal
        title={ModalTitle}
        open={isModalOpen}
        onOk={form.submit}
        // closable={true}
        // maskClosable={false}
        onCancel={handleCancel}
        okText="Add"
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          // labelCol={{ span: 5 }}
        >
          <Form.Item
            label={`City `}
            rules={[{ required: true, message: "Please Choose City" }]}
            name="city"
            initialValue={user?.role !== "super_admin" ? selectedCity : null}
          >
            <Select
              placeholder="Select City"
              showSearch
              onSearch={(e) => handleSearchCityInModal(e)}
              filterOption={false}
              allowClear
              onClear={(e) => handleSearchCityInModal("")}
              // defaultValue={selectedCity}

              disabled={
                ModalTitle == "Add New Tip"
                  ? user?.role !== "super_admin"
                  : true
              }
            >
              {cityList.length > 0 &&
                cityList.map((data) => {
                  return (
                    <Select.Option value={data._id} key={data._id}>
                      {data.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.List name="tips">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", alignItems: "center" }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      label={`Tip ${name + 1}`}
                      name={[name, "tip"]}
                      rules={[{ required: true, message: "Missing Tip" }]}
                    >
                      <Input
                        placeholder={`Enter Tip ${name + 1}`}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                    <Tooltip title={"delete"}>
                      <FeatherIcon
                        size={18}
                        icon="trash-2"
                        style={{ color: "red" }}
                        onClick={() => {
                          remove(name);
                          remove(key);
                        }}
                      />{" "}
                    </Tooltip>
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                      setError(false);
                    }}
                    style={{
                      fontSize: "17px",
                      borderColor: error ? "red" : "black",
                    }}
                    block
                    icon={
                      <FeatherIcon
                        size={17}
                        icon="plus-circle"
                        style={{ color: "blue", marginRight: "10px" }}
                      />
                    }
                  >
                    Add Tips
                  </Button>
                  {error && (
                    <span style={{ color: "red" }}>Please Add Tips</span>
                  )}
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

export default TipMaster;
