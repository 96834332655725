import actions from "./actions";

const { GET_ROLE_ID ,PATCH_ROLE_ID} = actions;

const initState = {
  roleData: {},
  newRoleData: {},
};

const roleDataByIdReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_ROLE_ID:
      return {
        ...state,
        roleData: data,
      };
    case PATCH_ROLE_ID:
      return {
        ...state,
        newRoleData: data,
      };
    // case CREATE_ROLE:
    //     return {
    //         ...state,
    //         newRole: data,
    //     };
    // case DELETE_ROLE:
    //     return {
    //         ...state,
    //         newRole: data,
    //     }
    default:
      return state;
  }
};
export default roleDataByIdReducer;
