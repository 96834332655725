import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  message,
} from "antd";
import { SearchOutlined, SwapOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import FormItem from "antd/es/form/FormItem";
import { useLocation } from "react-router-dom";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import moment from "moment";
import { useQuery } from "react-query";
import {
  notificationNoticeList,
  notificationsList,
  sendNoticeNotification,
} from "../../redux/SmsNotification/actionCreatore";
import { useDispatch, useSelector } from "react-redux";
import { getCityData } from "../../redux/Masters/City/actionCreatore";
import { useForm } from "antd/es/form/Form";

const { TabPane } = Tabs;

const Notification = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [city, setCity] = useState("");
  const [cityCaptain, setCityCaptain] = useState("");
  const [noticeTo, setNoticeTO] = useState("both");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [activeKey, setActiveKey] = useState("1");
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("");
  const [pageSize, setPageSize] = useState(50);
  const [pageNo, setPageNo] = useState(1);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState("DSC");
  const [form] = useForm();
  const location = useLocation();
  let params = {
    pageNo,
    pageSize,
    search,
    fromDate,
    toDate,
    role,
    key: "created_at",
    sort,
  };
  const handleTabClick = (key) => {
    setActiveKey(key);
    if (key == "2") {
      reteriveNotificationList(key, params);
    }
  };

  const rangePresets = [
    { label: "Today", value: [dayjs(), dayjs()] },
    { label: "Last 7 Days", value: [dayjs().add(-7, "days"), dayjs()] },
    { label: "Last Month", value: [dayjs().add(-1, "month"), dayjs()] },
    { label: "Last 6 Month", value: [dayjs().add(-6, "month"), dayjs()] },
    { label: "Last Year", value: [dayjs().add(-1, "year"), dayjs()] },
  ];

  const citySelected = (e) => {
    setCity(e);
  };
  const noticetowho = (e) => {
    setNoticeTO(e);
  };
  useEffect(() => {
    // const handleResize = () => setWindowWidth(window.innerWidth);
    // window.addEventListener("resize", handleResize);
    // return () => window.removeEventListener("resize", handleResize);
  }, [location]);

  const { data, isLoading } = useQuery(
    "notificationList",
    () => {
      return reteriveNotificationList(activeKey, params);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const reteriveNotificationList = async (activeKey, params) => {
    if (activeKey == "2") {
      setLoading(true);
      const resp = await dispatch(notificationNoticeList(params));
      if (resp) {
        setLoading(false);
      }
    }
  };
  const list = useSelector(
    (state) => state.smsNotification.notificationNoticeList
  );

  const handlePageChange = async (page, pageSize) => {
    setPageNo(page);
    setPageSize(pageSize);
    params.pageNo = page;
    params.pageSize = pageSize;
    await reteriveNotificationList(activeKey, params);
  };

  const handleSearchFilter = (value) => {
    setSearch(value);
    setPageNo(1);
    params.pageNo = 1;
    params.search = value;
    reteriveNotificationList(activeKey, params);
  };

  const handleSelectRole = (value) => {
    setPageNo(1);
    params.pageNo = 1;
    setRole(value ? value : "");
    params.role = value ? value : "";
    reteriveNotificationList(activeKey, params);
  };

  const onRangeChange = (dates) => {
    setPageNo(1);
    params.pageNo = 1;
    setFromDate(dates ? dates[0] : "");
    setToDate(dates ? dates[1] : "");
    params.fromDate = dates ? dates[0] : "";
    params.toDate = dates ? dates[1] : "";
    reteriveNotificationList(activeKey, params);
  };

  const handleSort = () => {
    let sorting;
    if (sort === "ASC") {
      setSort("DSC");
      sorting = "DSC";
    } else {
      sorting = "ASC";
      setSort("ASC");
    }
    params.sort = sorting;
    setPageNo(1);
    params.pageNo = 1;
    reteriveNotificationList(activeKey, params);
  };

  useEffect(() => {
    reteriveCityList();
  }, []);
  const reteriveCityList = async () => {
    // setLoading(true);
    const resp = await dispatch(getCityData(""));
    if (resp) {
      // setLoading(false);
    }
  };
  let cityList = [];
  cityList = useSelector((state) => state.city.cityData);
  cityList = cityList?.sort((a, b) => a.name?.localeCompare(b.name));

  const handleFormSubmit = async (values) => {
    let payload = { ...values };
    if (noticeTo == "both") {
      payload.role = ["driver", "rider"];
    } else {
      payload.role = [noticeTo];
    }
    const resp = await dispatch(sendNoticeNotification(payload));
    if (resp) {
      message.success(
        (values.key == "Notice" ? "Notice" : "Notification") +
          " sent successfully"
      );
      form.resetFields();
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },
    {
      title: "User",
      // dataIndex: "type",
      key: "user",
      children: [
        {
          title: t("name"),
          // dataIndex: "name",
          key: "name",
          render: (data) => {
            return <span>{data?.userId ? data?.userId.name : "-"}</span>;
          },
        },
        {
          title: "City",
          // dataIndex: "city",
          key: "city",
          render: (data) => {
            return (
              <span>
                {data?.userId?.city_id?.name
                  ? data?.userId?.city_id?.name
                  : "-"}
              </span>
            );
          },
        },

        {
          title: "Role",
          // dataIndex: "role",
          key: "role",
          render: (data) => {
            return (
              <span>
                {data?.userId?.role
                  ? data?.userId.role.charAt(0).toUpperCase() +
                    data?.userId.role.slice(1)
                  : "-"}
              </span>
            );
          },
        },
      ],
    },
    {
      title: "Notification",
      // dataIndex: "type",
      key: "notification",
      children: [
        {
          title: "Type",
          // dataIndex: "type",
          key: "type",
          render: (data) => {
            return (
              <span>
                {data.type
                  ? data.type.charAt(0).toUpperCase() + data.type.slice(1)
                  : "-"}
              </span>
            );
          },
        },
        {
          title: "Title",
          // dataIndex: "title",
          key: "title",
          render: (data) => {
            return <span>{data.title ? data.title : "-"}</span>;
          },
        },
        {
          title: "Description",
          // dataIndex: "description",
          key: "description",
          render: (data) => {
            return <span>{data.message ? data.message : "-"}</span>;
          },
        },
      ],
    },

    {
      title: (
        <div>
          Date
          <Button
            style={{ background: "none", border: "none" }}
            onClick={() => handleSort("created_at")}
          >
            <SwapOutlined rotate={90} style={{ color: "black" }} />
          </Button>
        </div>
      ),
      // dataIndex: "date",
      key: "date",
      render: (data) => {
        return (
          <span>
            {data.created_at ? new Date(data.created_at).toLocaleString() : "-"}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">{t("notification")}</h1>
            <BreadCrumb />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}></Col>
        </Row>

        <Divider />

        <Tabs type="card" activeKey={activeKey} onTabClick={handleTabClick}>
          <TabPane tab="Send Notification" key="1">
            <Card
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: windowWidth <= 580 ? "column" : "",
                    }}
                  >
                    <div style={{ width: windowWidth > 580 ? "50%" : "100%" }}>
                      <h1 className="pt-10">Send Notification to</h1>
                    </div>
                    <div style={{ width: windowWidth <= 580 ? "100%" : "50%" }}>
                      <Select
                        style={{ width: "100%" }}
                        defaultValue="both"
                        onChange={noticetowho}
                      >
                        <Select.Option value="both">All</Select.Option>
                        <Select.Option value="rider">Riders</Select.Option>
                        <Select.Option value="driver">Drivers</Select.Option>
                      </Select>
                    </div>
                  </div>
                </>
              }
            >
              <Form layout="vertical" form={form} onFinish={handleFormSubmit}>
                <Form.Item
                  name={"key"}
                  label="Type"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Type",
                    },
                  ]}
                >
                  <Select style={{ width: "50%" }} placeholder="Select Type">
                    <Select.Option value="Notice">Notice</Select.Option>
                    <Select.Option value="Notification">
                      Notification
                    </Select.Option>
                  </Select>
                </Form.Item>
                {/* <Form.Item name={"language"} label="Language">
                  <Select
                    defaultValue={"English"}
                    style={{ width: "50%" }}
                    placeholder="Select Type"
                  >
                    <Select.Option value="English">English</Select.Option>
                    <Select.Option value="Hindi">Hindi</Select.Option>
                    <Select.Option value="Gujrati">Gujrati</Select.Option>
                  </Select>
                </Form.Item> */}
                <Form.Item
                  name={"title"}
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Title",
                    },
                  ]}
                >
                  <Input placeholder="Enter Text" />
                </Form.Item>
                <Form.Item
                  name={"message"}
                  label="Text"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Text",
                    },
                  ]}
                >
                  <TextArea placeholder="Enter Text" />
                </Form.Item>

                {noticeTo === "driver" && (
                  <>
                    <div
                      style={{
                        marginTop: 20,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Form.Item name={"city_id"} label="Cities">
                        <Select
                          style={{ minWidth: "250px", marginRight: 10 }}
                          placeholder="Select Driver Cities"
                          onChange={(e) => citySelected(e)}
                          mode="multiple"
                          allowClear
                        >
                          {cityList.length > 0 &&
                            cityList?.map((data) => {
                              return (
                                <Select.Option
                                  value={data._id}
                                  key={data._id}
                                  className="selectOptions"
                                >
                                  {data.name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      {/* {!city && (
                        <Form.Item
                          name={"checkbox"}
                          rules={[
                            {
                              required: true,
                              message: "Please choose atleast one option",
                            },
                          ]}
                        >
                          <Checkbox.Group
                            style={{
                              display: "block",
                              flexDirection: "column",
                            }}
                          >
                            <Checkbox value={"driver"}>All Drivers</Checkbox>
                          </Checkbox.Group>
                        </Form.Item>
                      )} */}
                    </div>
                  </>
                )}
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                >
                  Send
                </Button>
              </Form>
            </Card>
          </TabPane>
          <TabPane tab="Notification History" key="2">
            <>
              <Card style={{ marginBottom: 10 }} className="shadowCardBox">
                <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                  <Col xxl={9} xl={8} lg={8} md={8} sm={12} xs={24}>
                    <span className="total">Total {list?.total_counts}</span>{" "}
                  </Col>

                  <Col xxl={5} xl={5} lg={5} md={8} sm={12} xs={24}>
                    <Input
                      placeholder="Search By Name, Email Or Phone"
                      style={{
                        width: "100%",
                        float: "right",
                        // marginLeft: "10px",
                        // float: "right",
                      }}
                      prefix={<SearchOutlined />}
                      onChange={(event) =>
                        handleSearchFilter(event.target.value)
                      }
                    />
                  </Col>
                  <Col xxl={5} xl={5} lg={5} md={8} sm={12} xs={24}>
                    <Select
                      placeholder="Choose User Role"
                      style={{ width: "100%" }}
                      allowClear
                      onChange={handleSelectRole}
                    >
                      <Select.Option
                        key={"rider"}
                        value={"rider"}
                        style={{ width: "100%" }}
                      >
                        <Tag title="Rider" style={{ width: "100%" }}>
                          Rider
                        </Tag>
                      </Select.Option>
                      <Select.Option
                        key={"driver"}
                        value={"driver"}
                        style={{ width: "100%" }}
                      >
                        <Tag title="Driver" style={{ width: "100%" }}>
                          Driver
                        </Tag>
                      </Select.Option>
                    </Select>
                  </Col>
                  <Col xxl={5} xl={5} lg={5} md={8} sm={12} xs={24}>
                    <DatePicker.RangePicker
                      presets={rangePresets}
                      onChange={onRangeChange}
                      defaultValue={[fromDate, toDate]}
                      style={{
                        borderRadius: "0",
                        // marginLeft: "10px",
                        // width: "10vw",
                        width: "100%",
                      }}
                      format={"DD/MM/YYYY"}
                      allowClear={true}
                      disabledDate={(current) => current.isAfter(moment())}
                    />
                  </Col>
                </Row>
              </Card>
              <Card>
                <Table
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                  dataSource={list?.list}
                  columns={columns}
                  searchable
                  loading={loading}
                  scroll={{ x: "45vh" }}
                  pagination={{
                    showSizeChanger: true,
                    pageSize: pageSize,
                    current: pageNo,
                    total: list?.total_counts,
                    // onShowSizeChange: onShowSizeChange,
                    onChange: handlePageChange,
                    pageSizeOptions: ["50", "100", "200"],
                  }}
                />
              </Card>
            </>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default Notification;
