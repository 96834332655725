import actions from "./actions";
import { message } from "antd";
import { API } from "../../../config/api";
import { DataService } from "../../../config/dataService/dataService";

const { getTiers } = actions;

export const getTiersData = (page, limit,city) => {
  return async (dispatch) => {
    const queryParamater = "?page=" + page + "&limit=" + limit+"&city_id="+city;

    const resp = await DataService.get(
      API.tier.get + queryParamater
    );
  console.log("resp",resp);
    if (!resp.data.error) {
      dispatch(getTiers(resp.data.data));
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const addTier = (payload) => {
  return async () => {
    const resp = await DataService.post(API.tier.add, payload);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const updateTier = (payload, id) => {
  // console.log("update role payload", id, payload);
  return async (dispatch) => {
    const resp = await DataService.put(
      API.tier.update + id,
      payload
    );
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};


export const deleteTier = (id) => {
  // console.log("update role payload", id, payload);
  return async (dispatch) => {
    const resp = await DataService.delete(API.tier.delete + id);
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

