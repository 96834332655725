import React from "react";
import './common.css'

const Privacy = () => {
  return (
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="main" class="site-main">
          <article
            id="post-3"
            class="post-3 page type-page status-publish hentry entry"
          >
            <header class="entry-header alignwide">
              <h1 class="entry-title">Privacy Policy</h1>
            </header>
            <div class="entry-content  refund-padding-left">
              <p>
                <strong>INTRODUCTION</strong>
                <strong>&nbsp;</strong>
              </p>
              <p>
                Safewheels Solutions Pvt Ltd (“we” or “us” or “our”) respects
                the privacy of our users (“user” or “you”). This Privacy Policy
                explains how we collect, use, disclose, and safeguard your
                information when you visit our website [name of website.com]
                [and our mobile application], including any other media form,
                media channel, mobile website, or mobile application related or
                connected thereto (collectively, the “Site”). Please read this
                privacy policy carefully. If you do not agree with the terms of
                this privacy policy, please do not access the site.
              </p>
              <p>
                We reserve the right to make changes to this Privacy Policy at
                any time and for any reason. We will alert you about any changes
                by updating the “Last Updated” date of this Privacy Policy. Any
                changes or modifications will be effective immediately upon
                posting the updated Privacy Policy on the Site, and you waive
                the right to receive specific notice of each such change or
                modification.
              </p>
              <p>
                You are encouraged to periodically review this Privacy Policy to
                stay informed of updates. You will be deemed to have been made
                aware of, will be subject to, and will be deemed to have
                accepted the changes in any revised Privacy Policy by your
                continued use of the Site after the date such revised Privacy
                Policy is posted.
              </p>
              <p>
                <strong>COLLECTION OF YOUR INFORMATION</strong>
              </p>
              <p>
                We may collect information about you in a variety of ways. The
                information we may collect on the Site includes:
              </p>
              <p></p>
              <p>
                <strong>Personal Data</strong>
              </p>
              <p>
                Personally, identifiable information, such as your name,
                shipping address, email address, and telephone number, and
                demographic information, such as your age, gender, hometown, and
                interests, that you voluntarily give to us [when you register
                with the Site [or our mobile application,] or] when you choose
                to participate in various activities related to the Site [and
                our mobile application], such as online chat and message boards.
                You are under no obligation to provide us with personal
                information of any kind, however your refusal to do so may
                prevent you from using certain features of the Site [and our
                mobile application].
              </p>
              <p>
                <strong>Derivative Data</strong>
              </p>
              <p>
                Information our servers automatically collect when you access
                the Site, such as your IP address, your browser type, your
                operating system, your access times, and the pages you have
                viewed directly before and after accessing the Site. [If you are
                using our mobile application, this information may also include
                your device name and type, your operating system, your phone
                number, your country, your likes and replies to a post, and
                other interactions with the application and other users via
                server log files, as well as any other information you choose to
                provide.]
              </p>
              <p>
                <strong>Financial Data</strong>
              </p>
              <p>
                Financial information, such as data related to your payment
                method (e.g. valid credit card number, card brand, expiration
                date) that we may collect when you purchase, order, return,
                exchange, or request information about our services from the
                Site [or our mobile application]. [We store only very limited,
                if any, financial information that we collect. Otherwise, all
                financial information is stored by our payment processor, [
                <a href="https://pay.amazon.com/us/help/201491260">
                  Amazon Payments,
                </a>
                ] [
                <a href="https://www.authorize.net/company/privacy/">
                  Authornize.Net,
                </a>
                ] [
                <a href="https://www.braintreepayments.com/legal">
                  Braintree Payments,
                </a>
                ] [
                <a href="https://www.chargify.com/privacy-policy/">Chargify,</a>
                ] [<a href="https://www.dwolla.com/legal/privacy/">Dwolla,</a>]
                [
                <a href="https://payments.google.com/payments/apis-secure/get_legal_document?ldo=0&amp;ldt=privacynotice">
                  Google Checkout,]
                </a>{" "}
                [
                <a href="https://www.paypal.com/us/webapps/mpp/ua/privacy-full">
                  Paypal
                </a>
                ,] [
                <a href="https://www.safecharge.com/privacy-cookies-policy/">
                  SafeCharge,
                </a>
                ] [<a href="https://stripe.com/us/privacy/">Stripe,</a>] [
                <a href="https://go.wepay.com/privacy-policy-us">WePay,</a>]{" "}
                <a href="https://www.2checkout.com/policies/privacy-policy/">
                  [2Checkout,
                </a>
                ] [other], and you are encouraged to review their privacy policy
                and contact them directly for responses to your questions.]
              </p>
              <p>
                <strong>Facebook Permissions&nbsp;</strong>
              </p>
              <p>
                The Site [and our mobile application] may by default access your{" "}
                <a href="https://www.facebook.com/about/privacy/">Facebook</a>{" "}
                basic account information, including your name, email, gender,
                birthday, current city, and profile picture URL, as well as
                other information that you choose to make public. We may also
                request access to other permissions related to your account,
                such as friends, checkins, and likes, and you may choose to
                grant or deny us access to each individual permission. For more
                information regarding Facebook permissions, refer to the{" "}
                <a href="https://developers.facebook.com/docs/facebook-login/permissions">
                  Facebook Permissions Reference{" "}
                </a>
                page.
              </p>
              <p>
                <strong>Data From Social Networks</strong>
              </p>
              <p>
                User information from social networking sites, such as [Apple’s
                Game Center, Facebook, Google+, Instagram, Pinterest, Twitter],
                including your name, your social network username, location,
                gender, birth date, email address, profile picture, and public
                data for contacts, if you connect your account to such social
                networks. [If you are using our mobile application, this
                information may also include the contact information of anyone
                you invite to use and/or join our mobile application.]
              </p>
              <p>
                <strong>Mobile Device Data</strong>
              </p>
              <p>
                Device information, such as your mobile device ID, model, and
                manufacturer, and information about the location of your device,
                if you access the Site from a mobile device.
              </p>
              <p>
                <strong>Third-Party Data</strong>
              </p>
              <p>
                Information from third parties, such as personal information or
                network friends, if you connect your account to the third party
                and grant the Site permission to access this information.
              </p>
              <p>
                <strong>Data From Contests, Giveaways, and Surveys</strong>
              </p>
              <p>
                Personal and other information you may provide when entering
                contests or giveaways and/or responding to surveys.
              </p>
              <p>
                <strong>Mobile Application Information</strong>
              </p>
              <p>If you connect using our mobile application:</p>
              <p>&nbsp;</p>
              <ul className="privacy-padding-align">
                <li>
                  <em>Geo-Location Information.</em> We may request access or
                  permission to and track location-based information from your
                  mobile device, either continuously or while you are using our
                  mobile application, to provide location-based services. If you
                  wish to change our access or permissions, you may do so in
                  your device’s settings.
                </li>
                <li>
                  <em>Mobile Device Access.</em> We may request access or
                  permission to certain features from your mobile device,
                  including your mobile device’s [bluetooth, calendar, camera,
                  contacts, microphone, reminders, sensors, SMS messages, social
                  media accounts, storage,] and other features. If you wish to
                  change our access or permissions, you may do so in your
                  device’s settings.
                </li>
                <li>
                  <em>Mobile Device Data.</em> We may collect device information
                  (such as your mobile device ID, model and manufacturer),
                  operating system, version information and IP address.
                </li>
                <li>
                  <em>Push Notifications. </em>We may request to send you push
                  notifications regarding your account or the Application. If
                  you wish to opt-out from receiving these types of
                  communications, you may turn them off in your device’s
                  settings.
                </li>
              </ul>
              <p className="privacy-padding-align">
                <strong>&nbsp;</strong>
              </p>
              <p>
                <strong>USE OF YOUR INFORMATION</strong>
              </p>
              <p>
                Having accurate information about you permits us to provide you
                with a smooth, efficient, and customized experience.
                Specifically, we may use information collected about you via the
                Site [or our mobile application] to:
              </p>
              <ul className="privacy-padding-align">
                <li>Administer sweepstakes, promotions, and contests.</li>
                <li>Assist law enforcement and respond to subpoena.</li>
                <li>
                  Compile anonymous statistical data and analysis for use
                  internally or with third parties.
                </li>
                <li>Create and manage your account.</li>
                <li>
                  Deliver targeted advertising, coupons, newsletters, and other
                  information regarding promotions and the Site [and our mobile
                  application] to you.
                </li>
                <li>Email you regarding your account or order.</li>
                <li>Enable user-to-user communications.</li>
                <li>
                  Fulfill and manage purchases, orders, payments, and other
                  transactions related to the Site [and our mobile application].
                </li>
                <li>
                  Generate a personal profile about you to make future visits to
                  the Site [and our mobile application] more personalized.
                </li>
                <li>
                  Increase the efficiency and operation of the Site [and our
                  mobile application].
                </li>
                <li>
                  Monitor and analyze usage and trends to improve your
                  experience with the Site [and our mobile application].
                </li>
                <li>
                  Notify you of updates to the Site [and our mobile
                  application]s.
                </li>
                <li>
                  Offer new products, services, [mobile applications,] and/or
                  recommendations to you.
                </li>
                <li>Perform other business activities as needed.</li>
                <li>
                  Prevent fraudulent transactions, monitor against theft, and
                  protect against criminal activity.
                </li>
                <li>Process payments and refunds.</li>
                <li>
                  Request feedback and contact you about your use of the Site
                  [and our mobile application] .
                </li>
                <li>Resolve disputes and troubleshoot problems.</li>
                <li>Respond to product and customer service requests.</li>
                <li>Send you a newsletter.</li>
                <li>
                  Solicit support for the Site [and our mobile application].
                </li>
                <li>[Other]</li>
              </ul>
              <p className="privacy-padding-align">
                <strong>&nbsp;</strong>
              </p>
              <p>
                <strong>DISCLOSURE OF YOUR INFORMATION</strong>
              </p>
              <p>
                We may share information we have collected about you in certain
                situations. Your information may be disclosed as follows:
              </p>
              <p>
                <strong>By Law or to Protect Rights</strong>
              </p>
              <p>
                If we believe the release of information about you is necessary
                to respond to legal process, to investigate or remedy potential
                violations of our policies, or to protect the rights, property,
                and safety of others, we may share your information as permitted
                or required by any applicable law, rule, or regulation. This
                includes exchanging information with other entities for fraud
                protection and credit risk reduction.
              </p>
              <p>
                <strong>Third-Party Service Providers</strong>
              </p>
              <p>
                We may share your information with third parties that perform
                services for us or on our behalf, including payment processing,
                data analysis, email delivery, hosting services, customer
                service, and marketing assistance.
              </p>
              <p>
                <strong>Marketing Communications</strong>
              </p>
              <p>
                With your consent, or with an opportunity for you to withdraw
                consent, we may share your information with third parties for
                marketing purposes, as permitted by law.
              </p>
              <p>
                <strong>Interactions with Other Users</strong>
              </p>
              <p>
                If you interact with other users of the Site [and our mobile
                application], those users may see your name, profile photo, and
                descriptions of your activity, including sending invitations to
                other users, chatting with other users, liking posts, following
                blogs.
              </p>
              <p>
                <strong>Online Postings</strong>
              </p>
              <p>
                When you post comments, contributions or other content to the
                Site [or our mobile applications], your posts may be viewed by
                all users and may be publicly distributed outside the Site [and
                our mobile application] in perpetuity.
              </p>
              <p>
                <strong>Third-Party Advertisers</strong>
              </p>
              <p>
                We may use third-party advertising companies to serve ads when
                you visit the Site [or our mobile application]. These companies
                may use information about your visits to the Site [and our
                mobile application] and other websites that are contained in web
                cookies in order to provide advertisements about goods and
                services of interest to you.
              </p>
              <p>
                <strong>Affiliates</strong>
              </p>
              <p>
                We may share your information with our affiliates, in which case
                we will require those affiliates to honor this Privacy Policy.
                Affiliates include our parent company and any subsidiaries,
                joint venture partners or other companies that we control or
                that are under common control with us.
              </p>
              <p>
                <strong>Business Partners</strong>
              </p>
              <p>
                We may share your information with our business partners to
                offer you certain products, services or promotions.
              </p>
              <p>
                <strong>[Offer Wall&nbsp;</strong>
              </p>
              <p>
                Our mobile application may display a third-party hosted “offer
                wall.” Such an offer wall allows third-party advertisers to
                offer virtual currency, gifts, or other items to users in return
                for acceptance and completion of an advertisement offer. Such an
                offer wall may appear in our mobile application and be displayed
                to you based on certain data, such as your geographic area or
                demographic information. When you click on an offer wall, you
                will leave our mobile application. A unique identifier, such as
                your user ID, will be shared with the offer wall provider in
                order to prevent fraud and properly credit your account.]&nbsp;
              </p>
              <p>
                <strong>&nbsp;&nbsp;</strong>
              </p>
              <p>
                <strong>[Social Media Contacts&nbsp;</strong>
              </p>
              <p>
                If you connect to the Site [or our mobile application] through a
                social network, your contacts on the social network will see
                your name, profile photo, and descriptions of your activity.]
              </p>
              <p>
                <strong> Other Third Parties</strong>
              </p>
              <p>
                We may share your information with advertisers and investors for
                the purpose of conducting general business analysis. We may also
                share your information with such third parties for marketing
                purposes, as permitted by law.
              </p>
              <p>
                <strong>Sale or Bankruptcy</strong>
              </p>
              <p>
                If we reorganize or sell all or a portion of our assets, undergo
                a merger, or are acquired by another entity, we may transfer
                your information to the successor entity. If we go out of
                business or enter bankruptcy, your information would be an asset
                transferred or acquired by a third party. You acknowledge that
                such transfers may occur and that the transferee may decline
                honor commitments we made in this Privacy Policy.
              </p>
              <p>
                We are not responsible for the actions of third parties with
                whom you share personal or sensitive data, and we have no
                authority to manage or control third-party solicitations. If you
                no longer wish to receive correspondence, emails or other
                communications from third parties, you are responsible for
                contacting the third party directly.
              </p>
              <p>
                <strong>TRACKING TECHNOLOGIES</strong>
              </p>
              <p>
                <strong>Cookies and Web Beacons</strong>
              </p>
              <p>
                [We may use cookies, web beacons, tracking pixels, and other
                tracking technologies on the Site [and our mobile application]
                to help customize the Site [and our mobile application] and
                improve your experience. When you access the Site [or our mobile
                application], your personal information is not collected through
                the use of tracking technology. Most browsers are set to accept
                cookies by default. You can remove or reject cookies, but be
                aware that such action could affect the availability and
                functionality of the Site [or our mobile application]. You may
                not decline web beacons. However, they can be rendered
                ineffective by declining all cookies or by modifying your web
                browser’s settings to notify you each time a cookie is tendered,
                permitting you to accept or decline cookies on an individual
                basis.]
              </p>
              <p>
                [We may use cookies, web beacons, tracking pixels, and other
                tracking technologies on the Site [and our mobile application]
                to help customize the Site [and our mobile application] and
                improve your experience. For more information on how we use
                cookies, please refer to our Cookie Policy posted on the Site,
                which is incorporated into this Privacy Policy. By using the
                Site, you agree to be bound by our Cookie Policy.]
              </p>
              <p>
                <strong>Internet-Based Advertising</strong>
              </p>
              <p>
                Additionally, we may use third-party software to serve ads on
                the Site [and our mobile application], implement email marketing
                campaigns, and manage other interactive marketing initiatives.
                This third-party software may use cookies or similar tracking
                technology to help manage and optimize your online experience
                with us. For more information about opting-out of interest-based
                ads, visit the{" "}
                <a href="http://www.networkadvertising.org/choices/">
                  Network Advertising Initiative Opt-Out Tool
                </a>{" "}
                or{" "}
                <a href="http://www.aboutads.info/choices/">
                  Digital Advertising Alliance Opt-Out Tool
                </a>
                .
              </p>
              <p>
                <strong> Website Analytics</strong>
              </p>
              <p>
                We may also partner with selected third-party vendors[, such as
                [
                <a href="http://www.adobe.com/privacy/marketing-cloud.html">
                  Adobe Analytics
                </a>
                ,] [
                <a href="https://www.clicktale.com/company/privacy-policy/">
                  Clicktale,
                </a>
                ] [<a href="https://clicky.com/terms">Clicky</a>,] [
                <a href="https://www.cloudflare.com/security-policy/">
                  Cloudfare
                </a>
                ,] [<a href="https://www.crazyegg.com/privacy/">Crazy Egg,</a>]
                [
                <a href="https://policies.yahoo.com/us/en/yahoo/privacy/products/developer/index.htm">
                  Flurry Analytics,
                </a>
                ] [
                <a href="https://support.google.com/analytics/answer/6004245?hl=en">
                  Google Analytics
                </a>
                ,] [
                <a href="https://heapanalytics.com/privacy">Heap Analytics</a>,]
                [
                <a href="https://www.inspectlet.com/legal#privacy">
                  Inspectlet,
                </a>
                ] [
                <a href="https://signin.kissmetrics.com/privacy">
                  Kissmetrics,]
                </a>{" "}
                [<a href="https://mixpanel.com/privacy/">Mixpanel,</a>] [
                <a href="https://piwik.org/privacy/">Piwik,</a>] and others], to
                allow tracking technologies and remarketing services on the Site
                [and our mobile application] through the use of first party
                cookies and third-party cookies, to, among other things, analyze
                and track users’ use of the Site [and our mobile application] ,
                determine the popularity of certain content and better
                understand online activity. By accessing the Site[,our mobile
                application,], you consent to the collection and use of your
                information by these third-party vendors. You are encouraged to
                review their privacy policy and contact them directly for
                responses to your questions. We do not transfer personal
                information to these third-party vendors. However, if you do not
                want any information to be collected and used by tracking
                technologies, you can visit the third-party vendor or the{" "}
                <a href="http://www.networkadvertising.org/choices/">
                  Network Advertising Initiative Opt-Out Tool
                </a>{" "}
                or{" "}
                <a href="http://www.aboutads.info/choices/">
                  Digital Advertising Alliance Opt-Out Tool
                </a>
                .
              </p>
              <p>
                You should be aware that getting a new computer, installing a
                new browser, upgrading an existing browser, or erasing or
                otherwise altering your browser’s cookies files may also clear
                certain opt-out cookies, plug-ins, or settings.
              </p>
              <p>
                <strong>THIRD-PARTY WEBSITES</strong>
              </p>
              <p>
                The Site [and our mobile application] may contain links to
                third-party websites and applications of interest, including
                advertisements and external services, that are not affiliated
                with us. Once you have used these links to leave the Site [or
                our mobile application], any information you provide to these
                third parties is not covered by this Privacy Policy, and we
                cannot guarantee the safety and privacy of your information.
                Before visiting and providing any information to any third-party
                websites, you should inform yourself of the privacy policies and
                practices (if any) of the third party responsible for that
                website, and should take those steps necessary to, in your
                discretion, protect the privacy of your information. We are not
                responsible for the content or privacy and security practices
                and policies of any third parties, including other sites,
                services or applications that may be linked to or from the Site
                [or our mobile application].
              </p>
              <p>
                <strong>SECURITY OF YOUR INFORMATION</strong>
              </p>
              <p>
                We use administrative, technical, and physical security measures
                to help protect your personal information. While we have taken
                reasonable steps to secure the personal information you provide
                to us, please be aware that despite our efforts, no security
                measures are perfect or impenetrable, and no method of data
                transmission can be guaranteed against any interception or other
                type of misuse. Any information disclosed online is vulnerable
                to interception and misuse by unauthorized parties. Therefore,
                we cannot guarantee complete security if you provide personal
                information.
              </p>
              <p>
                <strong>POLICY FOR CHILDREN</strong>
              </p>
              <p>
                We do not knowingly solicit information from or market to
                children under the age of 13. If you become aware of any data we
                have collected from children under age 13, please contact us
                using the contact information provided below.
              </p>
              <p>
                <strong>CONTROLS FOR DO-NOT-TRACK FEATURES&nbsp;</strong>
              </p>
              <p>
                Most web browsers and some mobile operating systems [and our
                mobile applications] include a Do-Not-Track (“DNT”) feature or
                setting you can activate to signal your privacy preference not
                to have data about your online browsing activities monitored and
                collected. No uniform technology standard for recognizing and
                implementing DNT signals has been finalized. As such, we do not
                currently respond to DNT browser signals or any other mechanism
                that automatically communicates your choice not to be tracked
                online. If a standard for online tracking is adopted that we
                must follow in the future, we will inform you about that
                practice in a revised version of this Privacy Policy./Most web
                browsers and some mobile operating systems [and our mobile
                applications] include a Do-Not-Track (“DNT”) feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. If you set the DNT signal on your browser, we will
                respond to such DNT browser signals.
              </p>
              <p>
                <strong>OPTIONS REGARDING YOUR INFORMATION</strong>
              </p>
              <p>[Account Information]</p>
              <p>
                You may at any time review or change the information in your
                account or terminate your account by:
              </p>
              <ul className="privacy-padding-align">
                <li>
                  Logging into your account settings and updating your account
                </li>
                <li>
                  Contacting us using the contact information provided below
                </li>
                <li>[Other]</li>
              </ul>
              <p className="privacy-padding-align">
                Upon your request to terminate your account, we will deactivate
                or delete your account and information from our active
                databases. However, some information may be retained in our
                files to prevent fraud, troubleshoot problems, assist with any
                investigations, enforce our Terms of Use and/or comply with
                legal requirements.]
              </p>
              <p>
                <strong>Emails and Communications</strong>
              </p>
              <p>
                If you no longer wish to receive correspondence, emails, or
                other communications from us, you may opt-out by:
              </p>
              <ul className="privacy-padding-align">
                <li>
                  Noting your preferences at the time you register your account
                  with the Site [or our mobile application]
                </li>
                <li>
                  Logging into your account settings and updating your
                  preferences.
                </li>
                <li>
                  Contacting us using the contact information provided below
                </li>
              </ul>
              <p>
                If you no longer wish to receive correspondence, emails, or
                other communications from third parties, you are responsible for
                contacting the third party directly
              </p>
              <p>
                <strong>CALIFORNIA PRIVACY RIGHTS</strong>
              </p>
              <p>
                California Civil Code Section 1798.83, also known as the “Shine
                The Light” law, permits our users who are California residents
                to request and obtain from us, once a year and free of charge,
                information about categories of personal information (if any) we
                disclosed to third parties for direct marketing purposes and the
                names and addresses of all third parties with which we shared
                personal information in the immediately preceding calendar year.
                If you are a California resident and would like to make such a
                request, please submit your request in writing to us using the
                contact information provided below.
              </p>
              <p>
                If you are under 18 years of age, reside in California, and have
                a registered account with the Site [or our mobile application],
                you have the right to request removal of unwanted data that you
                publicly post on the Site [or our mobile application]. To
                request removal of such data, please contact us using the
                contact information provided below, and include the email
                address associated with your account and a statement that you
                reside in California. We will make sure the data is not publicly
                displayed on the Site [or our mobile application], but please be
                aware that the data may not be completely or comprehensively
                removed from our systems.
              </p>
              <p>
                <strong>CONTACT US</strong>
              </p>
              <p>
                If you have questions or comments about this Privacy Policy,
                please contact us at:
              </p>
              <p>Safewheels Solutions Pvt Ltd</p>
              <p>203, Parthmilan Building,</p>
              <p>Nr Hotel Antrim, Off CG Road,</p>
              <p>Ahmedabad – 380009.</p>
              <p>
                <a href="mailto:info@g-auto.org">info@g-auto.org</a>
              </p>
              <p></p>
            </div>
          </article>
        </main>
      </div>
    </div>
  );
};

export default Privacy;
