import actions from "./actions";

const { GET_DATA } = actions;

const initState = {
  appDataList: {},
 };

const AppDataReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_DATA:
      return {
        ...state,
        appDataList: data,
      };
    default:
      return state;
  }
};
export default AppDataReducer;
