const actions = {
  GET_ACTIVITY_LOGS_LIST: "GET_ACTIVITY_LOGS_LIST",


  getActivityLogsList: (data) => {
    return {
      type: actions.GET_ACTIVITY_LOGS_LIST,
      data,
    };
  },
  
};

export default actions;
