const actions = {
  CREATE_CITY: "CREATE_CITY",
  GET_CITY: "GET_CITY",
  PUT_CITY: "PUT_CITY",
  DELETE_CITY: "DELETE_CITY",
  GET_CITY_WITH_PAGINATION: "GET_CITY_WITH_PAGINATION",
  GET_STATES: "GET_STATES",
  getCity: (data) => {
    return {
      type: actions.GET_CITY,
      data,
    };
  },
  getCityWithPagination: (data) => {
    return {
      type: actions.GET_CITY_WITH_PAGINATION,
      data,
    };
  },
  updateCity: (data) => {
    return {
      type: actions.PUT_CITY,
      data,
    };
  },
  createCity: (data) => {
    return {
      type: actions.CREATE_CITY,
      data,
    };
  },
  deleteCity: (data) => {
    return {
      type: actions.DELETE_CITY,
      data,
    };
  },
  getStates: (data) => {
    return {
      type: actions.GET_STATES,
      data,
    };
  },
};

export default actions;
