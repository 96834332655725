import { ArrowDownOutlined } from "@ant-design/icons";
import { Card, Col, DatePicker, Row, Spin } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardRideCount,
  getDashboardRideCountCaptains,
  getDashboardRideCountDrivers,
} from "../../redux/Dashboard/actionCreatore";
import { getItem } from "../../utility/localStorageControl";
import loadingGif from "../loader/loading.gif";
import "./style.css";

const { RangePicker } = DatePicker;
function AdminRidesCount(props) {
  const [status, setStatus] = useState("");
  const user = getItem("user_details");
  const [selectedCaptainName, setSelectedCaptainName] = useState("");
  const [selectedCaptainKey, setSelectedCaptainkey] = useState("");
  const [selectedCaptain, setSelectedCaptain] = useState("");
  const [loading, setLoading] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState();
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [selectedCity, setSelectedCity] = useState(user?.city_id?._id);
  const [captainLoading, setCaptainLoading] = useState(false);
  const [driverLoading, setDriverLoading] = useState(false);
  const dispatch = useDispatch();

   const rangePresets = [
    { label: "Today", value: [dayjs(), dayjs()] },
    { label: "Last 7 Days", value: [dayjs().add(-7, "days"), dayjs()] },
    { label: "Last Month", value: [dayjs().add(-1, "month"), dayjs()] },
    { label: "Last 6 Month", value: [dayjs().add(-6, "month"), dayjs()] },
    { label: "Last Year", value: [dayjs().add(-1, "year"), dayjs()] },
  ];
  const payload = {
    fromDate,
    toDate,
    status,
    city: selectedCity,
    captain: selectedCaptain,
  };

  useEffect(() => {
    fetchRidesRequestCount(payload);
  }, []);

  const fetchRidesRequestCount = async (payload) => {
    setLoading(true);

    const res = await dispatch(getDashboardRideCount(payload));
    if (res) {
      setLoading(false);
    }
  };
  const ridesCount = useSelector((state) => state.dashboard.ridesRequestCount);

  const fetchRidesRequestCaptainsCount = async (payload) => {
    setCaptainLoading(true);

    const res = await dispatch(getDashboardRideCountCaptains(payload));
    if (res) {
      setCaptainLoading(false);
    }
  };
  const ridesCaptainCount = useSelector(
    (state) => state.dashboard.ridesRequestCaptainsCount
  );

  const fetchRidesRequestDriversCount = async (payload) => {
    setDriverLoading(true);

    const res = await dispatch(getDashboardRideCountDrivers(payload));
    if (res) {
      setDriverLoading(false);
    }
  };
  const ridesDriversCount = useSelector(
    (state) => state.dashboard.ridesRequestDriversCount
  );

  const onRangeChange = async (dates, dateStrings) => {
    setStatus("");
    setSelectedCaptain("");
    if (dates) {
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setFromDate(dates[0]);
      setToDate(dates[1]);

      setLoading(true);
      payload.fromDate = dates[0];
      payload.toDate = dates[1];
      const resp = await dispatch(getDashboardRideCount(payload));
      if (resp) {
        setLoading(false);
      }
    } else {
      setFromDate("");
      setToDate("");
      setLoading(true);

      payload.fromDate = "";
      payload.toDate = "";
      const resp = await dispatch(getDashboardRideCount(payload));
      if (resp) {
        setLoading(false);
      }
    }
  };

  const handleStatusSelected = async (value) => {
    setStatus(value);
    setSelectedCaptain("");

    payload.status = value;
    fetchRidesRequestCaptainsCount(payload);
  };
  const handleCaptainSelect = async (value) => {
    if (value._id) {
      setSelectedCaptainkey(value._id);
      setSelectedCaptainName(value.name);
      setSelectedCaptain(value._id);
      payload.captain = value._id;
      fetchRidesRequestDriversCount(payload);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          height: "200px",
          // width: "100%",
          justifyContent: "center",
          display: "flex",
        }}
      >
        {" "}
        <img src={loadingGif} />{" "}
      </div>
    );
  }

  return (
    <div>
      <Card
        title={
          <span
            style={{
              fontWeight: "bold",
              justifyContent: "center",
              display: "flex",
            }}
          >
            Ride Request Counts {ridesCount?.count ? ridesCount?.count : 0}{" "}
          </span>
        }
        extra={
          <RangePicker
          presets={rangePresets}
            onChange={onRangeChange}
            defaultValue={[fromDate, toDate]}
            style={{
              borderRadius: "0",
              // marginLeft: "10px",
              // width: "10vw",
              // width: "100%",
            }}
            format={"DD/MM/YYYY"}
            allowClear={true}
            disabledDate={(current) => current.isAfter(moment())}

            // style={{display:"flex"}}
          />
        }
      >
        <Row gutter={20}>
          <Col xxl={8} lg={8} md={8} sm={8} xs={8}>
            <>
              <Card bodyStyle={{ background: "yellow" }}>
                <div
                  style={{
                    fontSize: "18px",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "500",
                    fontFamily: "sans-serif",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setBackgroundColor("#fbfc90");
                    setSelectedCaptainkey("");

                    handleStatusSelected("expired");
                  }}
                >
                  No Action {ridesCount?.expired?.total}{" "}
                </div>
              </Card>
              {status == "expired" && (
                <ArrowDownOutlined
                  style={{ display: "flex", justifyContent: "center" }}
                />
              )}
            </>
          </Col>
          <Col xxl={8} lg={8} md={8} sm={8} xs={8}>
            <>
              <Card bodyStyle={{ background: "skyblue" }}>
                <div
                  style={{
                    fontSize: "18px",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "500",
                    fontFamily: "sans-serif",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setBackgroundColor("#b1edfc");
                    setSelectedCaptainkey("");

                    handleStatusSelected("accepted");
                  }}
                >
                  Accepted {ridesCount?.accepted?.total}{" "}
                </div>
              </Card>
              {status == "accepted" && (
                <ArrowDownOutlined
                  style={{ display: "flex", justifyContent: "center" }}
                />
              )}
            </>
          </Col>{" "}
          <Col xxl={8} lg={8} md={8} sm={8} xs={8}>
            <>
              <Card bodyStyle={{ background: "#f75656" }}>
                <div
                  style={{
                    fontSize: "18px",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "500",
                    fontFamily: "sans-serif",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setBackgroundColor("#f75656");
                    setSelectedCaptainkey("");

                    handleStatusSelected("rejected");
                  }}
                >
                  Declined {ridesCount?.rejected?.total}{" "}
                </div>
              </Card>
              {status == "rejected" && (
                <ArrowDownOutlined
                  style={{ display: "flex", justifyContent: "center" }}
                />
              )}
            </>
          </Col>
        </Row>

        {status && (
          <Row
            gutter={20}
            style={{ border: "2px solid black" }}
            // className={`smooth-div ${showPendindData ? "open" : "close"}`}
          >
            {captainLoading ? (
              <Col span={24}>
                <Spin
                  size={"large"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    margin: "40px 0px",
                  }}
                />
              </Col>
            ) : (
              Array.isArray(ridesCaptainCount) &&
              ridesCaptainCount?.map((data, index) => {
                return (
                  <Col
                    xxl={3}
                    lg={8}
                    style={{ marginBottom: "10px", marginTop: "10px" }}
                  >
                    <Card
                      bodyStyle={{
                        background: backgroundColor,
                        cursor: data?._id && "pointer",
                        border:
                          selectedCaptainKey == data._id && data._id
                            ? "2px solid black"
                            : "none",
                      }}
                      key={data._id}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          handleCaptainSelect(data);
                        }}
                        onDoubleClick={() => {
                          // setSelectedCity("");
                        }}
                      >
                        <span
                          style={{ justifyContent: "center", display: "flex" }}
                        >
                          {data.total}
                        </span>

                        <span
                          style={{ justifyContent: "center", display: "flex" }}
                        >
                          {data.name}
                        </span>
                      </div>
                    </Card>
                  </Col>
                );
              })
            )}
          </Row>
        )}

        <br />
        {selectedCaptain && (
          <div>
            <Row>
              <Col span={24} style={{ fontWeight: "bold" }}>
                Drivers Under Captain {selectedCaptainName}
              </Col>
            </Row>
            <Row gutter={20}>
              {driverLoading ? (
                <Col span={24}>
                  <Spin
                    size={"large"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                      margin: "40px 0px",
                    }}
                  />
                </Col>
              ) : (
                Array.isArray(ridesDriversCount) &&
                ridesDriversCount?.map((data, index) => {
                  return (
                    <Col xxl={3} lg={8} style={{ marginTop: "20px" }}>
                      <Card bodyStyle={{ background: backgroundColor }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            {data.total}
                          </span>

                          <span
                            style={{
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            {data.name}
                          </span>
                        </div>
                      </Card>
                    </Col>
                  );
                })
              )}
            </Row>
          </div>
        )}
      </Card>
    </div>
  );
}

export default AdminRidesCount;
