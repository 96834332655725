const actions = {
  GET_SUPER_ADMIN_DATA: "GET_SUPER_ADMIN_DATA",

  getProfileData: (data) => {
    return {
      type: actions.GET_SUPER_ADMIN_DATA,
      data,
    };
  },
 
};

export default actions;
