import React, { useEffect } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { GOOGLE_MAP_API_KEY } from "../../config/dataService/dataService";
import bike from "../../static/img/map/bike.png";
import Auto from "../../static/img/auto1.png";
// import Auto from "../../static/img/map/auto.png";
function OnDutyDrivers(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="common_layout content-container">
      <div style={{ width: "100%" }}>
        <Map
          google={props.google}
          zoom={12}
          initialCenter={{ lat: 23.022505, lng: 72.571365 }}
          // style={{height:'95vh', width:"70%"}}
        >
          <Marker
            onClick={(e) => console.log(e)}
            name={"Current location"}
            icon={{
              url: bike,

              scaledSize: new window.google.maps.Size(37, 37),
            }}
            position={{ lat: 23.09113, lng: 72.51251 }}
          />
          <Marker
            onClick={(e) => console.log(e)}
            name={"Current location"}
            icon={{
              url: Auto,

              scaledSize: new window.google.maps.Size(60, 60),
            }}
            // position={{
            //     lat: 47.444,
            //     lng: -122.176
            // }}

            position={{ lat: 23.096109, lng: 72.674217 }}
          />
          <Marker
            onClick={(e) => console.log(e)}
            name={"Current location"}
            icon={{
              url: bike,

              scaledSize: new window.google.maps.Size(37, 37),
            }}
            position={{ lat: 23.05014, lng: 72.66892 }}
          />

          <Marker
            onClick={(e) => console.log(e)}
            name={"Current location"}
            icon={{
              url: Auto,

              scaledSize: new window.google.maps.Size(60, 60),
            }}
            // position={{
            //     lat: 47.444,
            //     lng: -122.176
            // }}

            position={{ lat: 23.050961, lng: 72.518819 }}
          />

          <InfoWindow
          // onClose={this.onInfoWindowClose}
          >
            <div>{/* <h1>{this.state.selectedPlace.name}</h1> */}</div>
          </InfoWindow>
        </Map>
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  // apiKey:GOOGLE_MAP_API_KEY
})(OnDutyDrivers);
