import { Col, Row } from "antd";
import React from "react";
import { Aside, Content } from "./overview/style";

const AuthLayout = (WraperContent) => {
  return () => {
    return (
      <div style={{ margin: "-8px" }}>
        <Row>
          <Col xxl={8} xl={9} lg={12} md={8} xs={24}>
            <Aside style={{ background: "green" }}>
              <div className="auth-side-content">
                <img
                  src={require("../../../static/img/auth/topShape.png")}
                  alt=""
                  className="topShape"
                />
                <img
                  src={require("../../../static/img/auth/bottomShape.png")}
                  alt=""
                  className="bottomShape"
                />
                <Content>
                  <img
                    style={{ width: "20vw" }}
                    src={require("../../../static/img/GautoLogo.png")}
                    alt=""
                  />
                  {/* <br /> */}
                  {/* <br /> */}
                  <h1
                    as="h1"
                    style={{
                      color: "green",
                      fontSize: "50px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {/* G-Auto <br /> */}
                    <span style={{ color: "white" }}>Application</span>
                  </h1>
                  {/* <img
                  className="auth-content-figure"
                  src="favicon.png"
                  style={{ width: '450px' }}
                  alt=""
                /> */}
                </Content>
              </div>
            </Aside>
          </Col>

          <Col xxl={16} xl={15} lg={12} md={16} xs={24}>
            <WraperContent />
          </Col>
        </Row>
      </div>
    );
  };
};

export default AuthLayout;
