const actions = {
  GET_NOTIFICATION_TYPES: "GET_NOTIFICATION_TYPES",
  PATCH_NOTIFICATION_ID: "PATCH_NOTIFICATION_ID",

  getNotificationTypes: (data) => {
    return {
      type: actions.GET_NOTIFICATION_TYPES,
      data,
    };
  },
  updateRoleById: (data) => {
    return {
      type: actions.PATCH_NOTIFICATION_ID,
      data,
    };
  },
};

export default actions;
