import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const { getContent,updateContent } = actions;

export const getAboutUsContent = () => {
  return async (dispatch) => {
    const resp = await DataService.get(API.aboutus.get);

    if (!resp.data.error) {
      dispatch(getContent(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const updateAboutUsContent = (content,id) => {
  return async (dispatch) => {
    const resp = await DataService.patch(API.aboutus.update +"/"+ id, content);
    if (!resp.data.error) {
      dispatch(updateContent(resp.data.data));
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
