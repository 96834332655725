import actions from "./actions";

const { GET_CONTACTUS_LIST } = actions;

const initState = {
  conatctUsListData: {},
};

const contactUsReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_CONTACTUS_LIST:
      return {
        ...state,
        conatctUsListData: data,
      };

    default:
      return state;
  }
};
export default contactUsReducer;
