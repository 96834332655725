import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const {
  fetchCategoryList,
  fetchSubCategoryList,
  fetchSubCategoryAnswerList,
  fetchCategoryListWithoutPagination,
} = actions;

export const getCategoryList = ({ pageNo, pageSize, role }) => {
  return async (dispatch) => {
    let queryParameter =
      "?page=" + pageNo + "&limit=" + pageSize + "&role=" + role;
    const resp = await DataService.get(
      API.supportCategory.getAll + queryParameter
    );

    if (!resp.data.error) {
      dispatch(fetchCategoryList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const getCategoryListWithoutPagination = ({ role }) => {
  return async (dispatch) => {
    let queryParameter = "?role=" + role;
    const resp = await DataService.get(
      API.supportCategory.getWithoutPagination + queryParameter
    );

    if (!resp.data.error) {
      dispatch(fetchCategoryListWithoutPagination(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const createCategory = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.supportCategory.add, payload);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const updateCategory = (payload, id) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.supportCategory.update + id,
      payload
    );

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const removeCategory = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.supportCategory.delete + id);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getSubCategoryList = ({
  pageNo,
  pageSize,
  selectCategory,
  selectRole,
  selectStatus,
}) => {
  return async (dispatch) => {
    let queryParameter =
      "?page=" +
      pageNo +
      "&limit=" +
      pageSize +
      "&supportCategoryId=" +
      selectCategory +
      "&role=" +
      selectRole +
      "&status=" +
      selectStatus;
    const resp = await DataService.get(
      API.supportSubCategory.getAll + queryParameter
    );

    if (!resp.data.error) {
      dispatch(fetchSubCategoryList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const createSubCategory = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.supportSubCategory.add, payload);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const updateSubCategory = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(API.supportSubCategory.update, payload);

    if (!resp.error) {
      return true;
    } else {
      // message.error(resp.message);
      return false;
    }
  };
};
export const removeSubCategory = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.supportSubCategory.delete + id);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getSubCategoryAnswerList = ({
  page,
  limit,
  role,
  inputSearch,
  categoryId,
  status,
}) => {
  return async (dispatch) => {
    let queryParameter =
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&role=" +
      role +
      "&search=" +
      inputSearch +
      (categoryId && "&supportCategoryId=" + categoryId) +
      (status && "&isSolved=" + status);
    // let queryParameter="?page="+pageNo+"&limit="+pageSize+"&role="+selectRole+"&status="+selectStatus
    const resp = await DataService.get(
      API.supportSubCategoryAnswer.getAll + queryParameter
    );

    if (!resp.data.error) {
      dispatch(fetchSubCategoryAnswerList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const createSubCategoryAnswer = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(
      API.supportSubCategoryAnswer.add,
      payload
    );

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const updateSubCategoryAnswer = (payload, id) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.supportSubCategoryAnswer.update + id,
      payload
    );

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const removeSubCategoryAnswer = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(
      API.supportSubCategoryAnswer.delete + id
    );

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
