import React from "react";
import { CAPTAIN, SUPERADMIN } from "../../config/dataService/dataService";
import { getItem } from "../../utility/localStorageControl";
import CaptainWallet from "./CaptainWallet";
import SuperAdminWallet from "./SuperAdminWallet";

function Wallet(props) {
  const user = getItem("user_details");

  if (user.role === SUPERADMIN) return <SuperAdminWallet />;
  if (user?.role === CAPTAIN) {
    return <CaptainWallet />;
  }
}

export default Wallet;
