import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStateRef from "react-usestateref";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
  createSubCategory,
  getCategoryList,
  getCategoryListWithoutPagination,
  getSubCategoryList,
  removeSubCategory,
  updateSubCategory,
} from "../../redux/Support/actionCreatore";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import Loader from "../loader/loader";
import TabPane from "antd/lib/tabs/TabPane";
import { WordFormate } from "../../utility/commonFunction";
const SupportSubCategory = () => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [ModalTitle, setModalTitle] = useState("");
  const [updateFlow, setUpdateFlow, setUpdateFlowFileRef] = useStateRef("");
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [selectStatus, setSelectStatus] = useState("");
  const [selectRole, setSelectRole] = useState("rider");
  const [selectCategory, setSelectCategory] = useState("");
  const [activeKey, setActiveKey] = useState("1");
  // const [role, setRole] = useState("rider");
  let payload = {
    pageNo,
    pageSize,
    selectCategory,
    selectRole,
    selectStatus,
  };

  useEffect(() => {
    // fetchSupportSubCategoryQuery();
    window.scrollTo(0, 0);
  }, []);

  const { data: category, isLoading: isLoadingCategory } = useQuery(
    "supportCategoryList",
    () => {
      return fetchSupportCategory();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchSupportCategory = async () => {
    setLoading(true);
    const resp = await dispatch(
      getCategoryListWithoutPagination({ role: selectRole })
    );
    if (resp) {
      setLoading(false);
    }
  };

  const categoryList = useSelector(
    (state) => state.support.supportCategoryDataWithoutPagination
  );

  const { data: data, isLoading: isLoading } = useQuery(
    "supportCategoryQueryList",
    () => {
      return fetchSupportSubCategoryQuery(payload);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchSupportSubCategoryQuery = async (payload) => {
    setLoading(true);
    const resp = await dispatch(getSubCategoryList(payload));
    if (resp) {
      setLoading(false);
    }
  };

  const subCategoryList = useSelector(
    (state) => state.support.supportSubCategoryData
  );
  // console.log(reasonsList);

  const deleteSubCategoryFinal = async (id) => {
    setLoading(true);

    let remove = await dispatch(removeSubCategory(id));
    if (remove) {
      message.success("Query deleted successfully");
      fetchSupportSubCategoryQuery(payload);
    }
  };
  const deleteReason = (id) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this query?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteSubCategoryFinal(id);
      },
    });
  };
  const editReason = (data) => {
    setModalTitle(
      `Edit Sub Category For ${activeKey == "1" ? "Rider" : "Driver"} `
    );

    setIsModalOpen(true);
    setUpdateFlow(data._id);

    form.resetFields();
    form.setFieldsValue({
      supportCategoryId: data.supportCategoryId._id,
      subCategoryQuestion: data.subCategoryQuestion,
      status: data.status,
    });
  };
  const handleSubmit = async (values) => {
    // setLoading(true);
    // form.resetFields();
    values.subCategoryQuestion = values.subCategoryQuestion.trim();
    values.role = selectRole;
    if (setUpdateFlowFileRef.current == "") {
      const resp = await dispatch(createSubCategory(JSON.stringify(values)));
      if (resp) {
        message.success("Sub category added successfully");
        setIsModalOpen(false);
        fetchSupportSubCategoryQuery(payload);
      }
    } else {
      values.subCategoryQuestionId = updateFlow;
      delete values.role;
      const resp = await dispatch(updateSubCategory(JSON.stringify(values)));
      if (resp) {
        message.success("Sub category updated successfully");
        setIsModalOpen(false);
        fetchSupportSubCategoryQuery(payload);
        setUpdateFlow("");
      }
    }
  };
  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Category Title",
      key: "title",
      render: (data, record) => {
        const str = data?.supportCategoryId?.category;
        const str2 = str?.charAt(0).toUpperCase() + str?.slice(1);
        return <span>{str2}</span>;
      },
    },
    {
      title: "Sub Category Title",
      key: "subCategoryTitle",
      render: (data, record) => {
        return <span>{data?.subCategoryQuestion}</span>;
      },
    },

    {
      title: "Status",
      key: "status",

      render: (data, record) => {
        const str = data?.status;
        const str2 = str?.charAt(0).toUpperCase() + str?.slice(1);
        return <span>{str2}</span>;
      },
    },
    {
      title: t("action"),
      key: "action",

      // dataIndex: "documents",
      key: "action",
      render(data, record) {
        return (
          <div>
            {" "}
            <Button
              onClick={() => editReason(data)}
              type="primary"
              // style={{ cursor: "pointer", color: "blue" }}
            >
              <EditOutlined />
              Edit
            </Button>
            <Button
              onClick={() => deleteReason(data._id)}
              style={{ cursor: "pointer", marginLeft: 2 }}
              type="primary"
              danger
              disabled
            >
              <DeleteOutlined />
              Delete
            </Button>
          </div>
        );
      },
    },
  ];
  const showModal = () => {
    form.resetFields();
    setModalTitle(
      `Add New Sub Category For ${activeKey == "1" ? "Rider" : "Driver"} `
    );

    setIsModalOpen(true);

    // setSelectedId("")
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedId("")
    form.resetFields();
    setUpdateFlow("");
    // setSelectDep(0)
  };

  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    // await dispatch(getReasonsData(page, pageSizes));
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    // await dispatch(getReasonsData(pageNo, pageSizes));
  };

  const handleSelectCategory = async (value) => {
    setSelectCategory(value);
    payload.pageNo = 1;
    payload.pageSize = 10;
    payload.selectCategory = value;
    await fetchSupportSubCategoryQuery(payload);
  };

  const handleSelectStatus = async (value) => {
    setSelectStatus(value);
    payload.pageNo = 1;
    payload.pageSize = 10;
    payload.selectStatus = value;
    await fetchSupportSubCategoryQuery(payload);
  };
  const handleTabClick = async (key) => {
    setActiveKey(key);
    setPageNo(1);
    setPageSize(10);
    setLoading(true);
    setSelectRole("rider");
    if (key == "1") {
      // fetchSupportSubCategoryQuery(payload);
      payload.pageNo = 1;
      payload.pageSize = 10;
      payload.selectRole = "rider";
      fetchSupportSubCategoryQuery(payload);
      await dispatch(getCategoryListWithoutPagination({ role: "rider" }));
    }
    if (key == "2") {
      setSelectRole("driver");
      payload.pageNo = 1;
      payload.pageSize = 10;
      payload.selectRole = "driver";
      fetchSupportSubCategoryQuery(payload);
      await dispatch(getCategoryListWithoutPagination({ role: "driver" }));
    }
  };

  const tabItems = [
    {
      key: "1",
      label: "Rider",
      children: (
        <>
          <Card style={{ marginBottom: 10 }} className="shadowCardBox">
            <Row gutter={[16, 16]}>
              <Col
                xxl={12}
                xl={12}
                lg={12}
                md={8}
                sm={12}
                xs={24}
                className="total"
              >
                Total {subCategoryList?.total_counts}
              </Col>
              <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                <Select
                  placeholder="Select Category"
                  style={{ width: "100%", float: "right" }}
                  onSelect={(e) => handleSelectCategory(e)}
                  onClear={() => handleSelectCategory("")}
                  allowClear
                >
                  {Array.isArray(categoryList) &&
                    categoryList?.map((data) => {
                      return (
                        <Select.Option
                          value={data._id}
                          className="selectOptions"
                          key={data._id}
                        >
                          {WordFormate(data.category)}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                <Select
                  placeholder="Select Status"
                  style={{
                    float: "right",

                    width: "100%",
                  }}
                  onSelect={(e) => handleSelectStatus(e)}
                  onClear={() => handleSelectStatus("")}
                  allowClear
                >
                  <Select.Option
                    value={"active"}
                    key={"active"}
                    className="selectOptions"
                  >
                    Active
                  </Select.Option>
                  <Select.Option
                    value={"deactive"}
                    key={"deactive"}
                    className="selectOptions"
                  >
                    Deactive
                  </Select.Option>
                </Select>
              </Col>
            </Row>
          </Card>
          <Card className="shadowCardBox">
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              dataSource={subCategoryList?.list}
              columns={columns}
              searchable
              scroll={{ x: "45vh" }}
              loading={loading}
              pagination={
                subCategoryList?.total_counts > 10
                  ? {
                      showSizeChanger: true,
                      pageSize: pageSize,
                      total: subCategoryList?.total_counts,
                      onShowSizeChange: onShowSizeChange,
                      onChange: onPageChange,
                      pageSizeOptions: ["10", "30", "50"],
                    }
                  : false
              }
            />
          </Card>
        </>
      ),
    },
    {
      key: "2",
      label: "Driver",
      children: (
        <>
          <Card style={{ marginBottom: 10 }} className="shadowCardBox">
            <Row gutter={[16, 16]}>
              <Col
                xxl={12}
                xl={12}
                lg={12}
                md={8}
                sm={12}
                xs={24}
                className="total"
              >
                Total {subCategoryList?.total_counts}
              </Col>
              <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                <Select
                  placeholder="Select Category"
                  style={{ width: "100%", float: "right" }}
                  onSelect={(e) => handleSelectCategory(e)}
                  onClear={() => handleSelectCategory("")}
                  allowClear
                >
                  {Array.isArray(categoryList) &&
                    categoryList?.map((data) => {
                      return (
                        <Select.Option value={data._id} key={data._id}>
                          {WordFormate(data.category)}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                <Select
                  placeholder="Select Status"
                  style={{
                    float: "right",

                    width: "100%",
                  }}
                  onSelect={(e) => handleSelectStatus(e)}
                  onClear={() => handleSelectStatus("")}
                  allowClear
                >
                  <Select.Option
                    value={"active"}
                    key={"active"}
                    className="selectOptions"
                  >
                    Active
                  </Select.Option>
                  <Select.Option
                    value={"deactive"}
                    key={"deactive"}
                    className="selectOptions"
                  >
                    Deactive
                  </Select.Option>
                </Select>
              </Col>
            </Row>
          </Card>
          <Card className="shadowCardBox">
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              dataSource={subCategoryList?.list}
              columns={columns}
              searchable
              scroll={{ x: "45vh" }}
              loading={loading}
              pagination={
                subCategoryList?.total_counts > 10
                  ? {
                      showSizeChanger: true,
                      pageSize: pageSize,
                      total: subCategoryList?.total_counts,
                      onShowSizeChange: onShowSizeChange,
                      onChange: onPageChange,
                      pageSizeOptions: ["10", "30", "50"],
                    }
                  : false
              }
            />
          </Card>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">Support Sub Category</h1>
            <BreadCrumb />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              style={{ float: "right" }}
              className="ADD"
              key="1"
              size="large"
              type="primary"
              onClick={showModal}
            >
              <PlusOutlined /> Add New
            </Button>
          </Col>
        </Row>

        <Divider />

        <Tabs
          type="card"
          activeKey={activeKey}
          onTabClick={handleTabClick}
          items={!isLoading && tabItems}
        ></Tabs>

        {isLoading && <Loader />}
      </div>
      <Modal
        title={ModalTitle}
        open={isModalOpen}
        onOk={form.submit}
        // closable={true}
        // maskClosable={false}
        onCancel={handleCancel}
        // okText="Add"
        okText={updateFlow ? "Update" : "Add"}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            name="supportCategoryId"
            label={"Category Title"}
            rules={[
              {
                required: true,
                message: "Please Choose Category Title",
                pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <Select placeholder="Select Category">
              {Array.isArray(categoryList) &&
                categoryList?.map((data) => {
                  return (
                    <Select.Option value={data._id} key={data._id}>
                      {data.category}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name="subCategoryQuestion"
            label={"Sub Category Title"}
            rules={[
              {
                required: true,
                message: "Please Enter Sub Category Title",
                pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <Input type="text" placeholder="Enter Sub Category Title" />
          </Form.Item>

          <Form.Item
            name="status"
            label={"Category Status"}
            rules={[
              {
                required: true,
                message: "Please Choose Category Status",
                pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <Radio.Group>
              <Radio value={"active"} key="active">
                Active
              </Radio>
              <Radio value={"deactive"} key="deactive">
                Deactive
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SupportSubCategory;
