const actions = {
  GET_INSURANCE_LIST: "GET_INSURANCE_LIST",

  getInsuranceList: (data) => {
    return {
      type: actions.GET_INSURANCE_LIST,
      data,
    };
  },
};

export default actions;
