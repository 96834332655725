import actions from './actions';
import { message } from 'antd';
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";


const { getRoleById, updateRoleById} = actions;

export const getRoleDataById = (id) => {
    return async (dispatch) => {
        const resp = await DataService.get(API.roleById.get + `/${id}`);

        if (!resp.data.error) {
            dispatch(getRoleById(resp.data.data));
              return resp;
        } else {
            message.error(resp.data.message);
            return false;
        }
    }
}
export const updateRoleDataById = (payload,id) => {
  // console.log("update role payload", id, payload);
    return async (dispatch) => {
      
      const resp = await DataService.patch(API.roleById.patch + "/" +id,payload)
      if(!resp.data.error) {
        dispatch(updateRoleById(resp.data.data))
        return true
      }
      else {
        message.error(resp.data.message);
        return false;
      }
      
    }
   }
// export const updateRoleDataById = (id,payload) => {
//     console.log("update role payload", id, payload);

//     return async (dispatch) => {
//          await DataService.patch(API.roleById.patch + '/' +id, payload);
//         console.log("update role :------------------------------ ", resp);
//         if (!resp.data.error) {
//             dispatch(updateRoleById(resp.data.data))
//             return true
//         } else {
//             message.error(resp.data.message);
//             return false;
//         }

//     };
// }

// export const createRoleData = (payload) => {
//     console.log("create role payload", payload);

//     return async dispatch => {
//         const resp = await DataService.post(API.roleManagement.createRoleManagement, payload);
//         console.log("create role: ", resp);
//         if (!resp.data.error) {
//             dispatch(createRole(resp.data.data))
//             return true
//         } else {
//             message.error(resp.data.message);
//             return false;
//         }
//     };
// }



// export const removeRoleData = (id) => {
//     console.log('delete row id', id);

//     return async (dispatch) => {
//         const resp = await DataService.delete(API.roleManagement.deleteRoleManagement + id);
//         console.log('delete roleManagement', resp);

//         if (!resp.data.error) {
//             dispatch(deleteRole(resp.data.data))
//             return true
//         } else {
//             message.error(resp.data.message);
//             return false;
//         }
//     }
// }