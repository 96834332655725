import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Table,
  Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStateRef from "react-usestateref";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
  createCategory,
  getCategoryList,
  removeCategory,
  updateCategory,
} from "../../redux/Support/actionCreatore";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import Loader from "../loader/loader";
import TabPane from "antd/lib/tabs/TabPane";
const SupportCategory = () => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [ModalTitle, setModalTitle] = useState("");
  const [updateFlow, setUpdateFlow, setUpdateFlowFileRef] = useStateRef("");
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState("1");
  const [role, setRole] = useState("rider");

  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const payload = {
    pageNo,
    pageSize,
    role,
  };
  useEffect(() => {
    // fetchSupportCategory();
    window.scrollTo(0, 0);
  }, []);

  const { data: data, isLoading: isLoading } = useQuery(
    "supportCategoryList",
    () => {
      return fetchSupportCategory(payload);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchSupportCategory = async (payload) => {
    setLoading(true);
    const resp = await dispatch(getCategoryList(payload));
    if (resp) {
      setLoading(false);
    }
  };

  const categoryList = useSelector(
    (state) => state.support.supportCategoryData
  );
  // console.log(reasonsList);

  const delettCategoryFinal = async (id) => {
    setLoading(true);

    let remove = await dispatch(removeCategory(id));
    if (remove) {
      message.success("Category deleted successfully");
      fetchSupportCategory(payload);
    }
  };
  const deleteCategory = (id) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this category?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        delettCategoryFinal(id);
      },
    });
  };
  const editCategory = (data) => {
    setModalTitle(
      `Edit Category For ${activeKey == "1" ? "Rider" : "Driver"} `
    );

    setIsModalOpen(true);
    setUpdateFlow(data._id);
    console.log(data);

    form.resetFields();
    form.setFieldsValue({
      category: data.category,
      status: data.status,
    });
  };
  const handleSubmit = async (values) => {
    values.category = values.category.trim();
    values.role = role;
    if (setUpdateFlowFileRef.current == "") {
      const resp = await dispatch(createCategory(JSON.stringify(values)));
      if (resp) {
        message.success("Category added successfully");
        setIsModalOpen(false);
        fetchSupportCategory(payload);
      }
    } else {
      const resp = await dispatch(
        updateCategory(JSON.stringify(values), updateFlow)
      );
      if (resp) {
        message.success("Category updated successfully");
        setIsModalOpen(false);
        fetchSupportCategory(payload);
        setUpdateFlow("");
      }
    }
  };

  const handleTabClick = async (key) => {
    setActiveKey(key);
    setPageNo(1);
    setPageSize(10);
    setLoading(true);
    setRole("rider");
    if (key == "1") {
      payload.pageNo = 1;
      payload.pageSize = 10;
      payload.role = "rider";
      fetchSupportCategory(payload);
    }
    if (key == "2") {
      setRole("driver");
      payload.pageNo = 1;
      payload.pageSize = 10;
      payload.role = "driver";
      fetchSupportCategory(payload);
    }
  };
  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },
    {
      title: "Category Title",

      render: (data, record) => {
        const str = data?.category;
        const str2 = str?.charAt(0).toUpperCase() + str?.slice(1);
        return <span>{str2}</span>;
      },
    },
    {
      title: "Category Status",

      render: (data, record) => {
        const str = data?.status;
        const str2 = str?.charAt(0).toUpperCase() + str?.slice(1);
        return <span>{str2}</span>;
      },
    },
    {
      title: t("action"),
      // dataIndex: "documents",
      key: "action",
      render(data, record) {
        return (
          <div>
            {" "}
            <Button
              onClick={() => editCategory(data)}
              type="primary"
              // style={{ cursor: "pointer", color: "blue" }}
            >
              <EditOutlined />
              Edit
            </Button>
            <Button
              onClick={() => deleteCategory(data._id)}
              style={{ cursor: "pointer", marginLeft: 2 }}
              type="primary"
              danger
              disabled
            >
              <DeleteOutlined />
              Delete
            </Button>
          </div>
        );
      },
    },
  ];
  const showModal = () => {
    form.resetFields();
    setModalTitle(
      `Add New Category For ${activeKey == "1" ? "Rider" : "Driver"} `
    );
    setIsModalOpen(true);

    // setSelectedId("")
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedId("")
    form.resetFields();
    setUpdateFlow("");
    // setSelectDep(0)
  };

  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    // await dispatch(getReasonsData(page, pageSizes));
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    // await dispatch(getReasonsData(pageNo, pageSizes));
  };

  const tabItems = [
    {
      key: "1",
      label: "Rider",
      children: (
        <Card className="shadowCardBox">
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            dataSource={categoryList?.list}
            columns={columns}
            searchable
            scroll={{ x: "45vh" }}
            pagination={
              categoryList?.total_counts > 10
                ? {
                    showSizeChanger: true,
                    pageSize: pageSize,
                    total: categoryList?.total_counts,
                    onShowSizeChange: onShowSizeChange,
                    onChange: onPageChange,
                    pageSizeOptions: ["10", "30", "50"],
                  }
                : false
            }
          />
        </Card>
      ),
    },
    {
      key: "2",
      label: "Driver",
      children: (
        <Card className="shadowCardBox">
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            dataSource={categoryList?.list}
            columns={columns}
            searchable
            scroll={{ x: "45vh" }}
            pagination={
              categoryList?.total_counts > 10
                ? {
                    showSizeChanger: true,
                    pageSize: pageSize,
                    total: categoryList?.total_counts,
                    onShowSizeChange: onShowSizeChange,
                    onChange: onPageChange,
                    pageSizeOptions: ["10", "30", "50"],
                  }
                : false
            }
          />
        </Card>
      ),
    },
  ];

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">Support Category</h1>
            <BreadCrumb />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              style={{ float: "right", marginTop: "20px" }}
              className="ADD"
              key="1"
              size="large"
              type="primary"
              onClick={showModal}
            >
              <PlusOutlined /> Add New
            </Button>
          </Col>
        </Row>
        <Divider />
        <Tabs
          type="card"
          activeKey={activeKey}
          onTabClick={handleTabClick}
          items={!isLoading && tabItems}
        ></Tabs>

        <>
          {/* <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col lg={12} md={12} sm={12} xs={12} className="total">
                  Total {reasonsList.total_counts}
                </Col>
              </Row>
            </Card> */}
          {/* <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                dataSource={categoryList?.list}
                columns={columns}
                searchable
                scroll={{ x: "45vh" }}
                pagination={
                  categoryList?.total_counts > 10
                    ? {
                      showSizeChanger: true,
                      pageSize: pageSize,
                      total: categoryList?.total_counts,
                      onShowSizeChange: onShowSizeChange,
                      onChange: onPageChange,
                      pageSizeOptions: ["10", "30", "50"],
                    }
                    : false
                }
              />
            </Card> */}
        </>

        {isLoading && <Loader />}
      </div>
      <Modal
        title={ModalTitle}
        open={isModalOpen}
        onOk={form.submit}
        // closable={true}
        // maskClosable={false}
        onCancel={handleCancel}
        okText={updateFlow ? "Update" : "Add"}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            name="category"
            label={"Category Title"}
            rules={[
              {
                required: true,
                message: "Please Enter Category Title",
                pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <Input type="text" placeholder="Enter Category Title" />
          </Form.Item>
          <Form.Item
            name="status"
            label={"Category Status"}
            rules={[
              {
                required: true,
                message: "Please Choose Category Status",
                pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <Radio.Group>
              <Radio value={"active"}>Active</Radio>
              <Radio value={"deactive"}>Deactive</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SupportCategory;
