import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { checkAction } from "../../utility/commonFunction";
import { getItem } from "../../utility/localStorageControl";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import { SearchOutlined } from "@ant-design/icons";
const ManageCoupons = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [ModalTitle, setModalTitle] = useState("Add Coupon/Voucher");
  const { t } = useTranslation();
  const user = getItem("user_details");
  const history = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dataSource = [
    {
      city: "Ahmedabad",
      couponCode: "NEWYEAR",
      name: "New Year",
      discount: "21%",
      status: "Active",
      fromDate: "03/03/2023",
      toDate: "10/3/2023",
      discountType: "Flat",
    },
    {
      city: "Goa",
      couponCode: "CHRISTMAS",
      name: "Christmas Celebration",
      discount: "21%",
      status: "inactive",
      fromDate: "03/03/2023",
      toDate: "10/3/2023",
      discountType: "Percentage",
    },
  ];
  const deleteCouponFinal = async (values) => {
    // let remove = await dispatch(removeRoleData(values));
    // if (remove) {
    //   setCurrentPage(currentPage);
    //   roleManagementApi(currentPage);
    // }
  };
  const deleteCoupon = (value) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this Coupon?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteCouponFinal(value);
      },
    });
  };
  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Coupons Code",
      dataIndex: "couponCode",
      key: "couponCode",
    },
    {
      title: "Discount Type",
      dataIndex: "discountType",
      key: "discountType",
    },
    // {
    //   title: "Discount",
    //   dataIndex: "discount",
    //   key: "discount",
    // },
    {
      title: "From",
      dataIndex: "fromDate",
      key: "fromDate",
    },
    {
      title: "To",
      dataIndex: "toDate",
      key: "toDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <Tag color={status === "inactive" ? "volcano" : "green"} key={status}>
            {status.toUpperCase()}
          </Tag>
          // <div>
          //   <Button
          //     style={{
          //       fontWeight: "bold",
          //       background: "green",
          //       color: "white",
          //       // padding: "7px",
          //       borderRadius: "28%",
          //       fontSize: "10px",
          //     }}
          //   >
          //     {status.toUpperCase()}
          //   </Button>
          // </div>
        );
      },
    },
    {
      title: t("action"),

      key: "action",
      render(data, record) {
        return (
          <div>
            {checkAction("coupons", "edit") && (
              <Button
                //  onClick={() => editCoupon(data)}
                onClick={() =>
                  history.push(`/${user.role}/manageCoupons/updateCoupon/545`)
                }
                type="primary"
                // style={{ cursor: "pointer", color: "blue" }}
              >
                <EditOutlined />
                Edit
              </Button>
            )}
            {checkAction("coupons", "delete") && (
              <Button
                onClick={() => deleteCoupon(data._id)}
                style={{ cursor: "pointer", marginLeft: 2 }}
                type="primary"
                danger
              >
                <DeleteOutlined />
                Delete
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  const showModal = () => {
    // setModalTitle('Add Role')
    setIsModalOpen(true);
    // setSelectedId("")
    form.resetFields();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedId("")
    form.resetFields();
    // setSelectDep(0)
  };

  const showEditModal = (value) => {
    // setSelectDep(1);
    console.log("edit value : ", value);
    setModalTitle("Edit Role ");
    // setSelectedId(value._id);
    form.setFieldsValue({
      title: value.title,
      login_type: value.login_type,
    });
  };

  // const handleSubmit = async (value) => {
  //   console.log(value, "DW");
  //   // setIsModalOpen(false)
  //   if (setSelectedIdRef.current == "") {
  //     const Add_roleData = await dispatch(createRoleData(value));
  //     if (Add_roleData) {
  //       setCurrentPage(currentPage);
  //       roleManagementApi(currentPage);
  //       setIsModalOpen(false);
  //       form.resetFields();
  //       setSelectedId("");
  //       // setSelectDep(0);
  //     }
  //   } else {
  //     // console.log('edit form')
  //     let editRole = await DataService.patch(
  //       API.roleById.patch + "/" + setSelectedIdRef.current,
  //       value
  //     );
  //     if (editRole) {
  //       setCurrentPage(currentPage);
  //       roleManagementApi(currentPage);
  //       setIsModalOpen(false);
  //       setSelectedId("");
  //       form.resetFields();
  //       // setSelectDep(0);
  //       // setCount();
  //     }
  //   }
  // };
  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">Coupon</h1>
            <BreadCrumb />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            {checkAction("coupons", "add") && (
              <Button
                style={{ float: "right" }}
                className="ADD"
                key="1"
                size="large"
                type="primary"
                onClick={() =>
                  history.push(`/${user.role}/manageCoupons/createCoupon`)
                }
              >
                <PlusOutlined /> Create Coupon
              </Button>
            )}
          </Col>
        </Row>
        <Divider />

        <Card style={{ marginBottom: 10 }} className="shadowCardBox">
          <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
            <Col lg={4} md={12} sm={12} xs={12} className="total">
              Total 2
            </Col>

            <Col lg={20} md={12} sm={12} xs={12}>
              <Select
                placeholder="Choose Status"
                style={{ width: 200, marginLeft: "10px", float: "right" }}
                filterOption={false}
              >
                <Select.Option value={"flat"} key={"flat"}>
                  Active
                </Select.Option>
                <Select.Option value={"Percentage"} key={"Percentage"}>
                  Inactive
                </Select.Option>
              </Select>
              <Select
                placeholder="Choose Discount Type"
                style={{ width: 200, marginLeft: "10px", float: "right" }}
                filterOption={false}
              >
                <Select.Option value={"flat"} key={"flat"}>
                  Flat
                </Select.Option>
                <Select.Option value={"Percentage"} key={"Percentage"}>
                  Percentage
                </Select.Option>
              </Select>
              <Input
                placeholder="Search Coupon"
                style={{ width: 300, marginLeft: "10px", float: "right" }}

                // enterButton="Search"
                size="large"
                prefix={<SearchOutlined />}
              />
            </Col>
          </Row>
        </Card>
        <Card className="shadowCardBox">
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            dataSource={dataSource}
            columns={columns}
            searchable
            scroll={{ x: "45vh" }}
          />
        </Card>
      </div>
      {/* <Modal
        title={ModalTitle}
        open={isModalOpen}
        onOk={form.submit}
        closable={true}
        maskClosable={false}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          // labelCol={{ span: 5 }}
        >
          <Row gutter={20}>
            <Col xxl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="City"
                rules={[{ required: true, message: "Please Select City" }]}
                name="city"
              >
                <Select mode="multiple" placeholder="Choose city"></Select>
              </Form.Item>
            </Col>
            <Col xxl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="Vehicle Type"
                rules={[{ required: true, message: "Please Select Vehicle " }]}
                name="city"
              >
                <Select mode="multiple" placeholder="Choose Vehicle"></Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={`Code`}
            rules={[{ required: true, message: "Please Enter Coupon Code" }]}
            name="couponcode"
          >
            <Input type="text" placeholder="Enter Coupon Code"></Input>
          </Form.Item>

          <Form.Item
            label={`Discount`}
            rules={[{ required: true, message: "Please Enter Discount " }]}
            name="discount"
          >
            <Input type="number" placeholder="Enter Discount %"></Input>
          </Form.Item>
          <Form.Item
            label={`From`}
            rules={[{ required: true, message: "Please Enter starting Date " }]}
            name="fromDate"
          >
            <Input type="Date" placeholder="Enter Discount %"></Input>
          </Form.Item>
          <Form.Item
            label={`To`}
            rules={[{ required: true, message: "Please Enter Ending Date " }]}
            name="toDate"
          >
            <Input type="Date" placeholder="Enter Discount %"></Input>
          </Form.Item>

          <Form.Item
            label="Status"
            rules={[{ required: true, message: "Please Select Status" }]}
            name="status"
          >
            <Select placeholder="Choose Status">
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="inactive">inactive</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal> */}
    </>
  );
};

export default ManageCoupons;
