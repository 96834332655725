import { combineReducers } from "redux";
import AboutUsReducer from "./About Us/reducers";
import ActivityLogsReducer from "./Activity Logs/reducers";
import AdminReducer from "./admin/reducers";
import FeedbackReducer from "./App Feedback/reducers";
import AppDataReducer from "./AppData/reducers";
import authReducer from "./authentication/reducers";
import beOurPartnerReducer from "./BeOurPartners/reducers";
import bookingReducer from "./Bookings/reducers";
import callCenterReducer from "./callCenter/reducers";
import commissionReducer from "./Commission/reducers";
import contactUsReducer from "./Contactus/reducers";
import dashboardReducer from "./Dashboard/reducers";
import driverCenterReducer from "./deriverCenter/reducers";
import driverReducer from "./drivers/reducers";
import InsuranceReducer from "./insurance/reducers";
import manageFareReducer from "./manageFare/reducers";
import MapStatisticsReducer from "./MapStatistics/reducers";
import cancelBookingReasonsReducer from "./Masters/cancelBookingReason/reducers";
import cityDataReducer from "./Masters/City/reducers";
import profileDeleteReasonsReducer from "./Masters/deleteReasons/reducers";
import ratingTagsReducer from "./Masters/feedbackOptions/reducers";
import tierReducer from "./Masters/Tier/reducers";
import tipsDataReducer from "./Masters/tips/reducers";
import NotificationTypeReducer from "./NotificationTypes/reducers";
import ratingReducer from "./Ratings/reducers";
import riderReducer from "./riders/reducers";
import roleDataByIdReducer from "./rolebyid/reducers";
import roleManagementReducer from "./rolemanagement/reducers";
import settingReducer from "./Settings/reducers";
import SmsNotificationReducer from "./SmsNotification/reducers";
import SuperAdminReducer from "./SuperAdmin/reducers";
import supportReducer from "./Support/reducers";
import templateReducer from "./templates/reducers";
import ChangeLayoutMode from "./themeLayout/reducers";
import VehicleTypeReducer from "./VehicleTypes/reducers";
const rootReducers = combineReducers({
  auth: authReducer,
  roleManagementReducer,
  roleDataByIdReducer,
  AboutUsReducer,
  city: cityDataReducer,
  ChangeLayoutMode,
  vehicleType: VehicleTypeReducer,
  NotificationTypeReducer,
  fare: manageFareReducer,
  rider: riderReducer,
  driver: driverReducer,
  callCenter: callCenterReducer,
  tips: tipsDataReducer,
  admin: AdminReducer,
  captain: driverCenterReducer,
  setting: settingReducer,
  FeedbackReducer,
  rating: ratingReducer,
  partner: beOurPartnerReducer,
  superAdmin: SuperAdminReducer,
  profileDeleteReason: profileDeleteReasonsReducer,
  contactUs: contactUsReducer,
  booking: bookingReducer,
  cancelBooking: cancelBookingReasonsReducer,
  tier: tierReducer,
  ratingTags: ratingTagsReducer,
  dashboard: dashboardReducer,
  support: supportReducer,
  appData: AppDataReducer,
  smsNotification: SmsNotificationReducer,
  insurance: InsuranceReducer,
  template: templateReducer,
  mapStatistics: MapStatisticsReducer,
  activityLogs: ActivityLogsReducer,
  commission:commissionReducer
});

export default rootReducers;
