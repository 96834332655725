import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Image,
  Input,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Table,
  TimePicker,
  Switch,
} from "antd";
import {
  SearchOutlined,
  DeleteOutlined,
  MenuUnfoldOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFare,
  getAllFareList,
  removeFare,
  updateFareServiceAvailable,
} from "../../redux/manageFare/actionCreatore";
import { getCityData } from "../../redux/Masters/City/actionCreatore";
import { getVehicleTypesData } from "../../redux/VehicleTypes/actionCreatore";
import Spineer from "../loader/Spinner";
import {
  // Switch,
  useNavigate,
  useMatch,
} from "react-router-dom";
import Loader from "../loader/loader";
import { getItem } from "../../utility/localStorageControl";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { SUPERADMIN } from "../../config/dataService/dataService";

const ManageFare = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const path = useMatch();
  const [pageSize, setPageSize] = useState(50);
  const [pageNo, setPageNo] = useState(1);
  const user = getItem("user_details");

  const [selectedCity, setSelectedCity] = useState(
    user?.role == "admin" ? user?.city_id._id : ""
  );
  const [selectedVehicleType, setSelectedVehicleType] = useState("");
  const [serviceAvailble, setServiceAvailble] = useState("");
  const [searchCity, setSearchCity] = useState(null);

  useEffect(() => {
    // retriveFareList();
    // reteriveCityList();
    // fetchVehicleTypes();
    window.scrollTo(0, 0);
  }, []);

  const { data, isLoading: isLoading } = useQuery(
    "farelist",
    () => {
      return retriveFareList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: vehicleData, isLoading: isLoadingVehicleType } = useQuery(
    "vehicleTypelist",
    () => {
      return fetchVehicleTypes();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataCity, isLoading: isLoadingCity } = useQuery(
    "cityList",
    () => {
      return reteriveCityList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const reteriveCityList = async () => {
    await dispatch(getCityData(""));
  };
  let cityList = useSelector((state) => state.city.cityData);
  cityList = cityList?.sort((a, b) => a?.name?.localeCompare(b?.name));

  const fetchVehicleTypes = async () => {
    await dispatch(getVehicleTypesData());
  };

  const vehicleTypes = useSelector((state) => state.vehicleType.vehicleData);

  const retriveFareList = async () => {
    const resp = await dispatch(
      getAllFareList(pageNo, pageSize, selectedCity, "", "")
    );
    if (resp) {
      setLoading(false);
    }
    // console.log(resp);
  };
  const fareListData = useSelector((state) => state.fare.fareListData);
  // const fareList = fareListData?.list?.filter((fare) => fare.city_id !== null);
  const fareList = fareListData?.list;
  const deleteFareFinal = async (id) => {
    setLoading(true);

    const resp = await dispatch(removeFare(id));
    if (!resp.error) {
      message.success("Fare deleted successfully");
      setLoading(false);
      retriveFareList();
    } else {
      message.success(resp.message);
    }
  };
  const fareDetail = (value) => {
    // console.log(value._id);
    navigate(`update/${value._id}`);
  };
  const deleteFare = (id) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this Fare?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteFareFinal(id);
      },
    });
  };

  const updateSericeAvailable = async (id, value) => {
    const resp = await dispatch(
      updateFareServiceAvailable({ isServiceAvailable: value }, id)
    );
    if (resp) {
      retriveFareList();
    }
  };

  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    await dispatch(
      getAllFareList(
        page,
        pageSizes,
        selectedCity,
        selectedVehicleType,
        serviceAvailble
      )
    );
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    await dispatch(
      getAllFareList(
        current,
        pageSizes,
        selectedCity,
        selectedVehicleType,
        serviceAvailble
      )
    );
  };

  const handleStatusFilter = async (e) => {
    setServiceAvailble(e);
    await dispatch(
      getAllFareList(pageNo, pageSize, selectedCity, selectedVehicleType, e)
    );
  };

  const handleSearchCity = async (value) => {
    setSearchCity(value);

    await dispatch(getCityData(value));
  };

  const handleVehicleFilter = async (e) => {
    setSelectedVehicleType(e);

    await dispatch(
      getAllFareList(pageNo, pageSize, selectedCity, e, serviceAvailble)
    );
  };

  const handleCityFilter = async (e) => {
    // console.log(e);
    setSearchCity("");

    setSelectedCity(e);
    await dispatch(
      getAllFareList(pageNo, pageSize, e, selectedVehicleType, serviceAvailble)
    );
    await dispatch(getCityData(""));
  };

  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },
    {
      title: "Vehicle",
      dataIndex: ["vehicle_type_id", "name"],
    },
    {
      title: "City",
      render: (data, record) => {
        return <span>{data?.city_id?.name}</span>;
      },
    },
    {
      title: "Day Start Time",

      render: (data, record) => {
        return <span>{data.day_start_time}</span>;
      },
    },
    {
      title: "Night Start Time",
      render: (data, record) => {
        return <span>{data.night_start_time}</span>;
      },
    },

    // {
    //   title: "Cancellation Charges",
    //   render: (data, record) => {
    //     return <span>{data.cancellation_charges}</span>;
    //   },
    // },
    {
      title: "Service Available",
      align: "",
      render: (data) => {
        return (
          <Switch
            checked={data?.isServiceAvailable}
            checkedChildren="Yes"
            unCheckedChildren="No"
            // disabled={!checkAction("city", "edit")}
            disabled={true}
            onChange={(e) => updateSericeAvailable(data._id, e)}
          >
            {" "}
          </Switch>
        );
      },
    },

    {
      title: t("action"),
      dataIndex: "",
      key: "x",
      render: (record, target) => {
        return (
          <div>
            <Button
              onClick={() => fareDetail(target)}
              type="primary"
              // style={{ cursor: "pointer", color: "blue" }}
            >
              <MenuUnfoldOutlined />
              Details
            </Button>
            <Button
              onClick={() => deleteFare(target._id)}
              style={{ cursor: "pointer", marginLeft: 2 }}
              type="primary"
              danger
              disabled
            >
              <DeleteOutlined />
              Delete
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">Fare Master</h1>
            <BreadCrumb />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              style={{ float: "right" }}
              className="ADD"
              key="1"
              size="large"
              type="primary"
              onClick={() => {
                navigate("add");
              }}
            >
              <PlusOutlined /> Add Fare
            </Button>
          </Col>
        </Row>
        <Divider />
        {isLoading & isLoadingCity & isLoadingVehicleType ? (
          <Loader />
        ) : (
          <>
            <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col xxl={9} lg={9} md={6} sm={24} xs={12} className="total">
                  Total {fareListData?.total_counts}
                </Col>

                <Col xxl={5} lg={5} md={6} sm={8} xs={12}>
                  {user?.role == SUPERADMIN && (
                    <Select
                      placeholder="Choose city"
                      style={{
                        width: "100%",
                        maxHeight: "45px",
                        overflowY: "auto",
                        marginTop: "6px",
                      }}
                      showSearch
                      filterOption={false}
                      allowClear
                      mode="multiple"
                      onSearch={(e) => handleSearchCity(e)}
                      onChange={handleCityFilter}
                    >
                      {cityList &&
                        cityList.map((data) => {
                          return (
                            <Select.Option
                              value={data._id}
                              key={data._id}
                              className="selectOptions"
                            >
                              {data.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  )}
                </Col>
                <Col xxl={5} lg={5} md={6} sm={8} xs={12}>
                  <Select
                    style={{
                      width: "100%",
                      maxHeight: "45px",
                      overflowY: "auto",
                    }}
                    placeholder="Choose Vehicle"
                    allowClear
                    showSearch={false}
                    mode="multiple"
                    onChange={handleVehicleFilter}
                  >
                    {vehicleTypes &&
                      vehicleTypes.length > 0 &&
                      vehicleTypes.map((vehicle) => (
                        <Select.Option
                          value={vehicle._id}
                          key={vehicle._id}
                          className="selectOptions"
                        >
                          {vehicle.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Col>
                <Col xxl={5} lg={5} md={6} sm={8} xs={12}>
                  <Select
                    style={{ marginBottom: 8, display: "block" }}
                    placeholder="Service Available"
                    onSelect={(e) => handleStatusFilter(e)}
                    value={serviceAvailble == "" ? null : serviceAvailble}
                    onClear={(e) => handleStatusFilter("")}
                    allowClear
                  >
                    <Select.Option
                      value={"true"}
                      key={"available"}
                      className="selectOptions"
                    >
                      Available
                    </Select.Option>
                    <Select.Option
                      value={"false"}
                      key={"notAvailable"}
                      className="selectOptions"
                    >
                      Not Available
                    </Select.Option>
                  </Select>
                </Col>
              </Row>
            </Card>
            <Row style={{ marginTop: "20px" }}>
              <Col span={24}>
                <Card className="shadowCardBox">
                  <Table
                    rowClassName={(record, index) =>
                      index % 2 === 0 ? "table-row-light" : "table-row-dark"
                    }
                    columns={columns}
                    dataSource={fareList}
                    scroll={{ x: "45vh" }}
                    pagination={
                      {
                        showSizeChanger: true,
                        pageSize: pageSize,
                        total: fareListData?.total_counts,
                        onShowSizeChange: onShowSizeChange,
                        onChange: onPageChange,
                        pageSizeOptions: ["50", "100", "500"],
                      }
                      // fareListData?.total_counts > pageSize
                      //   ? {
                      //       showSizeChanger: true,
                      //       pageSize: pageSize,
                      //       total: fareListData?.total_counts,
                      //       onShowSizeChange: onShowSizeChange,
                      //       onChange: onPageChange,
                      //       pageSizeOptions: ["10", "30", "50"],
                      //     }
                      //   : false
                    }
                  />
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default ManageFare;
