import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const { getNotificationTypes } = actions;

export const getNotificationTypesData = () => {
  return async (dispatch) => {
    const resp = await DataService.get(API.notificationMaster.get);

    if (!resp.data.error) {
      dispatch(getNotificationTypes(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const addNotificationType = (formdata) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.notificationMaster.add, formdata);
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const removeNotificationType = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(
      API.notificationMaster.delete + "/" + id
    );

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const updateNotificationType = (formdata, id) => {
  return async (dispatch) => {
    const resp = await DataService.patch(
      API.notificationMaster.update + "/" + id,
      formdata,
      {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    );
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
