import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AuthWrapper } from "./style";
import { login } from "../../../../redux/authentication/actionCreator";
import Heading from "../../../../components/heading/heading";
import { useState } from "react";
import {
  removeItem,
  setItem,
} from "../../../../utility/sessionStrorageControl";
import AuthLayout from "../Index";
const NewPassword = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.loading);
  const [form] = Form.useForm();
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleSubmit = async (value) => {
    value.password = password;
    // const data = await dispatch(newPassword(value));
    removeItem("page");
    window.location.replace("/GAuto-Application/");
  };

  const handleChange = (event) => {
    const input = event.target.value;
    setPassword(input);
  };

  return (
    <>
      <AuthWrapper>
        {/* <p className="auth-notice">
        Don't have an account? <NavLink to="/register">Sign up now</NavLink>
      </p> */}
        <div className="auth-contents">
          <Form
            name="login"
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Heading as="h3">
              Create your <span className="color-secondary">Password</span>
            </Heading>

            <Form.Item
              name="password"
              label=" New password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  pattern: new RegExp(
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
                  ),
                  message: `Must contain at least one number , one uppercase, lowercase letter, and at least 8 or more characters`,
                },
              ]}
              hasFeedback
            >
              <Input.Password
                onKeyPress={(event) => {
                  if (event.key == " ") {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm new password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                onKeyPress={(event) => {
                  if (event.key == " ") {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>

            <Form.Item>
              <Button
                className="btn-signin"
                htmlType="submit"
                type="primary"
                size="large"
              >
                {isLoading ? "Loading..." : "Confirm"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </AuthWrapper>
    </>
  );
};

export default AuthLayout(NewPassword);
