import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Table,
} from "antd";
import React, { useState } from "react";

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import {
  createSmsTemplate,
  getSmsTemplateFromDbList,
  removeSmsTemplate,
  updateNotificationTemplate,
} from "../../redux/templates/actionCreatore";
import Spinner from "../loader/Spinner";
import useStateRef from "react-usestateref";

const NotificationTemplate = () => {
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(50);
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [form] = Form.useForm();
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [updateFlow, setUpdateFlow, setUpdateFlowFileRef] = useStateRef("");

  const payload = {
    pageNo,
    limit,
    type: "notification",
  };

  const { data: dbData, loading: dbLoading } = useQuery(
    "smsTemplateDbList",
    () => {
      return fetchNotificationTemplateDb(payload);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchNotificationTemplateDb = async (payload) => {
    const resp = await dispatch(getSmsTemplateFromDbList(payload));
    if (resp) {
      setLoading(false);
    }
  };

  const notificationTemplateDb = useSelector(
    (state) => state.template.smsTemplateDbData
  );

  const handleModalCancel = () => {
    setOpenModal(false);
    setModalTitle("");
  };

  const handleSubmit = async (values) => {
    values.key = "notification";
    values.type = "notification";
    setLoading(true);

    if (setUpdateFlowFileRef.current == "") {
      const resp = await dispatch(createSmsTemplate(values));
      if (resp) {
        message.success("Template created successfully");
        setLoading(false);
        handleModalCancel();
        await fetchNotificationTemplateDb(payload);
        // await fetchSmsTemplate();
      }
    } else {
      delete values.key;
      delete values.type;
      const resp = await dispatch(
        updateNotificationTemplate(updateFlow, values)
      );
      if (resp) {
        message.success("Template updated successfully");
        setLoading(false);
        handleModalCancel();
        await fetchNotificationTemplateDb(payload);
        // await fetchSmsTemplate();
      }
    }
    setLoading(false);
  };

  const deleteFinal = async (values) => {
    setLoading(true);
    const resp = await dispatch(removeSmsTemplate(values));
    if (resp) {
      message.success("Template deleted successfully");
      setLoading(false);
      await fetchNotificationTemplateDb(payload);
    }
    setLoading(false);
  };

  const handleDelete = async (id, e) => {
    // if (selectedSmsTemplate) {
    Modal.confirm({
      title: "Are you sure, you want to delete this template?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteFinal(id);
      },
    });
  };

  const handleEditModal = (data) => {
    setOpenModal(true);
    setModalTitle("Edit Notification");
    setUpdateFlow(data._id);
    form.resetFields();
    form.setFieldsValue({
      name: data.name,
      title: data.title,
      body: data.body,
    });
  };

  const columns = [
    {
      title: "Sr. No.",
      render: (text, object, index) => index + 1 + (pageNo - 1) * limit,
    },
    {
      title: "Name",
      render: (data) => <span>{data?.name ? data?.name : "-"}</span>,
    },
    {
      title: "Title",
      render: (data) => <span>{data?.title ? data?.title : "-"}</span>,
    },

    {
      title: "Description",
      render: (data) => <span>{data?.body ? data?.body : "-"}</span>,
    },
    {
      title: "Added Date",
      render: (data) => {
        const date = new Date(data?.created_at);
        return (
          <span>{data?.created_at ? date.toLocaleDateString() : "-"}</span>
        );
      },
    },
    {
      title: "Action",
      render: (data) => (
        <span>
          <Button type="primary" onClick={() => handleEditModal(data)}>
            <EditOutlined /> Edit
          </Button>
          <Button
            type="primary"
            danger
            disabled
            onClick={() => handleDelete(data._id)}
          >
            <DeleteOutlined /> Remove
          </Button>
        </span>
      ),
    },
  ];

  if (dbLoading) {
    return <Spinner />;
  }
  return (
    <div className="common_layout content-container">
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h1 className="heading">Notification Templates </h1>
          {/* <BreadCrumb /> */}

          {/* Total 5 */}
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Button
            style={{ float: "right" }}
            className="ADD"
            key="1"
            size="large"
            type="primary"
            onClick={() => {
              setOpenModal(true);
              setModalTitle("Add New Template");
            }}
          >
            <PlusOutlined /> Add Notification template
          </Button>
        </Col>
      </Row>
      <Divider />

      <>
        <Card className="shadowCardBox">
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            rowKey="_id"
            columns={columns}
            dataSource={notificationTemplateDb?.list}
            pagination={
              // adminList?.total_counts > pageSize
              //   ?
              {
                showSizeChanger: true,
                pageSize: limit,
                total: notificationTemplateDb?.total_counts,
                // onShowSizeChange: onShowSizeChange,
                // onChange: onPageChange,
                pageSizeOptions: ["50", "100", "500"],
              }
              // : false
            }
            scroll={{ x: "45vh" }}
          />
        </Card>
      </>
      <Modal
        open={openModal}
        title={modalTitle}
        onCancel={handleModalCancel}
        onOk={form.submit}
      >
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Form.Item
            name={"name"}
            label={"Name"}
            rules={[
              {
                required: true,
                message: "Please Enter Name",
                pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <Input type="text" placeholder="Enter Name" />
          </Form.Item>
          <Form.Item
            name={"title"}
            label={"Title"}
            rules={[
              {
                required: true,
                message: "Please Enter Title",
                pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <Input type="text" placeholder="Enter Title" />
          </Form.Item>{" "}
          <Form.Item
            name={"body"}
            label={"Notiification Body"}
            rules={[
              {
                required: true,
                message: "Please Enter Notiification Body",
                pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <TextArea type="text" placeholder="Enter Notiification Body" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default NotificationTemplate;
