import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Rate,
  Row,
  Select,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStateRef from "react-usestateref";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { checkAction, WordFormate } from "../../utility/commonFunction";
import Loader from "../loader/loader";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";

import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
  addRatingWiseRideAccept,
  deleteRatingWiseRideAccept,
  editRatingWiseRideAccept,
  getRatingWiseRideAccept,
} from "../../redux/drivers/actionCreatore";
const RidesRatingWise = () => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [ModalTitle, setModalTitle] = useState("");
  const [updateFlow, setUpdateFlow, setUpdateFlowFileRef] = useStateRef("");
  const [respStatus, setRespStatus] = useState();
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    // fetchData();
    window.scrollTo(0, 0);
  }, []);

  const { data: data, isLoading: isLoading } = useQuery(
    "ratingWiseRides",
    () => {
      return fetchData();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchData = async () => {
    setLoading(true);
    const resp = await dispatch(getRatingWiseRideAccept(pageNo, pageSize));
    if (resp) {
      setRespStatus(resp.statusCode);
      setLoading(false);
    }
  };

  let ridesRatingWise = useSelector((state) => state.driver.ratingWiseRides);

  const deleteRideRatingFinal = async (id) => {
    setLoading(true);

    let remove = await dispatch(deleteRatingWiseRideAccept(id));
    if (remove) {
      message.success("Record deleted successfully");
      fetchData("");
    }
  };
  const deleteRatingWiseRide = (id) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this record?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteRideRatingFinal(id);
      },
    });
  };
  const editRatingWiseRide = (data) => {
    setModalTitle("Edit");
    setIsModalOpen(true);
    setUpdateFlow(data._id);
    console.log(data);

    form.resetFields();
    form.setFieldsValue({
      driverRating: data.driverRating,
      numberOfTrips: data.numberOfTrips,
    });
  };
  const handleSubmit = async (values) => {
    setLoading(true);
    if (setUpdateFlowFileRef.current == "") {
      const resp = await dispatch(
        addRatingWiseRideAccept(JSON.stringify(values))
      );
      if (resp) {
        message.success("Record added successfully");
        setIsModalOpen(false);
        fetchData("");
      }
    } else {
      values.id = updateFlow;
      const resp = await dispatch(
        editRatingWiseRideAccept(JSON.stringify(values))
      );
      if (resp) {
        message.success("Record updated successfully");
        setIsModalOpen(false);
        fetchData("");
        setUpdateFlow("");
      }
    }
  };
  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Driver Rating",

      render: (data, record) => {
        return <Rate value={data?.driverRating} disabled></Rate>;
      },
    },
    {
      title: "Max Rides Per Day",
      render: (data, record) => {
        return <span>{data?.numberOfTrips}</span>;
      },
    },
    {
      title: "Shift",

      render: (data, record) => {
        return <span>{data?.shift ? WordFormate(data?.shift) : "-"}</span>;
      },
    },

    {
      title: t("action"),
      // dataIndex: "documents",
      key: "action",
      render(data, record) {
        return (
          <div>
            {" "}
            <Button
              onClick={() => editRatingWiseRide(data)}
              type="primary"
              // style={{ cursor: "pointer", color: "blue" }}
            >
              <EditOutlined />
              Edit
            </Button>
            <Button
              onClick={() => deleteRatingWiseRide(data._id)}
              style={{ cursor: "pointer", marginLeft: 2 }}
              type="primary"
              danger
              disabled
            >
              <DeleteOutlined />
              Delete
            </Button>
          </div>
        );
      },
    },
  ];
  const showModal = () => {
    form.resetFields();
    setModalTitle("Add New ");
    setIsModalOpen(true);

    // setSelectedId("")
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedId("")
    form.resetFields();
    setUpdateFlow("");
    // setSelectDep(0)
  };

  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    // await dispatch(getReasonsData(page, pageSizes));
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    // await dispatch(getReasonsData(pageNo, pageSizes));
  };
  if (respStatus == 500) {
    return (
      <div className="common_layout content-container">
        <img
          src="https://websiteredesignexpert.com/wp-content/uploads/2022/06/500-internal-server-error.jpg"
          height={"100%"}
          width={"100%"}
        />

        {/* You can provide additional error handling or display options */}
      </div>
    );
  }

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">Rating Wise Rides</h1>
            <BreadCrumb />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              style={{ float: "right" }}
              className="ADD"
              key="1"
              size="large"
              type="primary"
              onClick={showModal}
            >
              <PlusOutlined /> Add New
            </Button>
          </Col>
        </Row>
        <Divider />
        {!isLoading && (
          <>
            {/* <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col lg={12} md={12} sm={12} xs={12} className="total">
                  Total {ridesRatingWise.total_counts}
                </Col>
              </Row>
            </Card> */}
            <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                dataSource={
                  ridesRatingWise?.list
                    ? ridesRatingWise?.list?.sort(
                        (a, b) => a.driverRating - b.driverRating
                      )
                    : []
                }
                columns={columns}
                searchable
                scroll={{ x: "45vh" }}
                // pagination={{
                //   showSizeChanger: true,
                //   pageSize: pageSize,
                //   total: ridesRatingWise?.total_counts,
                //   onShowSizeChange: onShowSizeChange,
                //   onChange: onPageChange,
                //   pageSizeOptions: ["10", "30", "50"],
                // }}
                pagination={false}
              />
            </Card>
          </>
        )}
        {isLoading && <Loader />}
      </div>
      <Modal
        title={ModalTitle}
        open={isModalOpen}
        onOk={form.submit}
        // closable={true}
        // maskClosable={false}
        onCancel={handleCancel}
        okText={updateFlow ? "Update" : "Add"}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            name="driverRating"
            label={"Driver Rating"}
            rules={[
              {
                required: true,
                message: "Please Choose Rating",
                // pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <Select
              disabled={ModalTitle === "Edit" && true}
              placeholder="Choose Number Of Rating"
            >
              <Select.Option value={0}>
                <Rate value={0} disabled />
              </Select.Option>
              <Select.Option value={1}>
                <Rate value={1} disabled />
              </Select.Option>
              <Select.Option value={2}>
                <Rate value={2} disabled />
              </Select.Option>
              <Select.Option value={3}>
                <Rate value={3} disabled />
              </Select.Option>
              <Select.Option value={4}>
                <Rate value={4} disabled />
              </Select.Option>
              <Select.Option value={5}>
                <Rate value={5} disabled />
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="numberOfTrips"
            label={"Rides/day"}
            rules={[
              {
                required: true,
                message: "Please Enter Number of Rides",
                // pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <Input type="number" placeholder="Enter Number of Rides" min={0} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RidesRatingWise;
