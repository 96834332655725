const actions = {
  GET_PLVERIFICATION_DOC: "GET_PLVERIFICATION_DOC",
  UPDATE_PLVERIFICATION_DOC: "UPDATE_PLVERIFICATION_DOC",

  getPLDoc: (data) => {
    return {
      type: actions.GET_PLVERIFICATION_DOC,
      data,
    };
  },
  updatePLDoc: (data) => {
    return {
      type: actions.UPDATE_PLVERIFICATION_DOC,
      data,
    };
  },
};

export default actions;
