import jsPDF from "jspdf";
import "jspdf-autotable";
import XLSX from "xlsx";

export const exportPDF = (FileName, headers, exportData) => {
  const unit = "pt";
  const size = "A4";
  const orientation = "landscape";
  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);
  const title = FileName;
  const headersPDF = [headers && headers.map((header) => [header])];
  const dataPDF = exportData.map((elt) => headers.map((header) => elt[header]));
  let content = {
    startY: 50,
    head: headersPDF,
    body: dataPDF,
  };
  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`${title}.pdf`);
};

// Export Excel
export const exportExcel = (FileName, data) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
  XLSX.writeFile(workbook, `${FileName}.xlsx`);
};

// Export CSV
export const exportCSV = (FileName, headers, data) => {
  let csvContent = "data:text/csv;charset=utf-8,";
  csvContent += headers.join(",") + "\r\n";
  data.forEach((elt) => {
    headers.forEach((header) => {
      csvContent += elt[header] + ",";
    });
    csvContent += "\r\n";
  });
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${FileName}.csv`);
  document.body.appendChild(link);
  link.click();
};

export const downloadReports = (filename, modifiedExportData, type) => {
  const data =
    modifiedExportData &&
    modifiedExportData.map((elt, index) => {
      return {
        // t("srNo"): index + 1,
        ...elt,
      };
    });
  let headers = Object.keys(data && data.length > 0 && data[0]);

  if (type === "pdf") {
    exportPDF(filename, headers, data);
  }
  if (type === "csv") {
    exportCSV(filename, headers, data);
  }
  if (type === "excel") {
    exportExcel(filename, data);
  }
};
