import actions from "./actions";

const {
  GET_ADMIN_LIST,
  GET_ADMIN_DATA_BY_ID,
  GET_ADMIN_CAPTAIN_DATA,
  GET_ADMIN_LIST_WITHOUT_PAGINATION,
} = actions;

const initState = {
  adminListdata: {},
  adminData: {},
  adminCaptainList: {},
  adminLstWithoutPagination: {},
};

const AdminReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_ADMIN_LIST:
      return {
        ...state,
        adminListdata: data,
      };
    case GET_ADMIN_DATA_BY_ID:
      return {
        ...state,
        adminData: data,
      };
    case GET_ADMIN_CAPTAIN_DATA:
      return {
        ...state,
        adminCaptainList: data,
      };
    case GET_ADMIN_LIST_WITHOUT_PAGINATION:
      return {
        ...state,
        adminLstWithoutPagination: data,
      };
    default:
      return state;
  }
};
export default AdminReducer;
