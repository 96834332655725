import {
  BellOutlined,
  FileExcelOutlined,
  FileOutlined,
  FilePdfOutlined,
  MessageOutlined,
  SearchOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  message,
  Modal,
  Rate,
  Row,
  Select,
  Switch,
  Table,
  Tag,
  Tooltip,
} from "antd";
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ADMIN,
  AUTOCLICKER,
  BOOKFORHOME,
  CAPTAIN,
  IMAGE_URL_PREFIX,
  OFFDUTY,
  ONDUTY,
  SUPERADMIN,
} from "../../../config/dataService/dataService";
import {
  deactivateDriver,
  getAllDriversList,
  getCaptainDriversListData,
  multiDeleteDrivers,
} from "../../../redux/drivers/actionCreatore";
import { getCityData } from "../../../redux/Masters/City/actionCreatore";
import { getRandomColor, WordFormate } from "../../../utility/commonFunction";
import { getItem } from "../../../utility/localStorageControl";
import BreadCrumb from "../../BreadCrumb.js/BreadCrumb";
import Loader from "../../loader/loader";
import ReportModal from "../../Modal/ReportModal";
import SmsNotificationModal from "../../Modal/SmsNotificationModal";
import VerifyOtpModal from "../../Modal/VerifyOtpModal";

const ManageDriver = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const [pageSize, setPageSize] = useState(50);
  const [pageNo, setPageNo] = useState(1);
  const [searchFilter, setSearchFilter] = useState("");
  const [dutyStatus, setDutyStatus] = useState("");
  const user = getItem("user_details");
  const [selectedCity, setSelectedCity] = useState(
    user.role === ADMIN ? [user?.city_id?._id] : ""
  );
  const [sort, setSort] = useState("DSC");
  const [sortKey, setSortKey] = useState("login_time");
  const [tableLoading, setTableLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const captainID = user?._id;
  const [selectedTime, setSelectedTime] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalKey, setModalkey] = useState("");

  const [smsNotificationDataOrg, setSmsNotificationDataOrg] = useState([]);
  const [smsNotificationData, setSmsNotificationData] = useState([]);

  const [showReportModal, setShowReportModal] = useState(false);
  const [docType, setDocType] = useState("");
  const [docName, setDocName] = useState("Drivers Report");
  const [showVerify, setShowVerify] = useState(false);
  const reportKeys = [
    "Name",
    "Mobile No.",
    "Email Address",
    "Last Login",
    "City",
    "Ratings",
    "Duty Status",
    "Approved At",
  ];

  useEffect(() => {
    // reteriveDriverList();
    // GetCity();
    user.role === CAPTAIN && reteriveCaptainDriverList();
    window.scrollTo(0, 0);
  }, []);
  let payload = {
    pageNo,
    pageSize,
    searchFilter,
    dutyStatus,
    selectedCity,
    vehicleType: "",
    key: sortKey,
    sort,
    login_time: selectedTime,
  };

  const captainDriverPayload = {
    pageNo,
    pageSize,
    captainId: captainID,
    searchFilter,
    dutyStatus,
    sort,
    key: sortKey,
    login_time: selectedTime,
  };
  const { data, isLoading } = useQuery(
    "driversList",
    () => {
      return user.role !== CAPTAIN && reteriveDriverList(payload);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataCity, isLoading: isLoadingCity } = useQuery(
    "cityList",
    () => {
      return GetCity();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const reteriveDriverList = async () => {
    setTableLoading(true);
    const resp = await dispatch(getAllDriversList(payload));
    if (resp) {
      setTableLoading(false);
      setExportData(resp.list);
    }
  };
  const driverList = useSelector((state) => state.driver.driverListData);

  const reteriveCaptainDriverList = async () => {
    await dispatch(getCaptainDriversListData(captainDriverPayload));
  };

  const drivers = useSelector((state) => state.driver.captainDriverList);

  const GetCity = async () => {
    await dispatch(getCityData(search));
  };
  let cityList = useSelector((state) => state?.city?.cityData);
  cityList = cityList?.sort((a, b) => a.name?.localeCompare(b.name));
  useEffect(() => {
    if (!isLoading) {
      setTableLoading(false);
    }
  }, [isLoading]);
  const handleSort = async (key) => {
    let sorting;
    setSortKey(key);
    if (sort === "ASC") {
      setSort("DSC");
      sorting = "DSC";
    } else {
      sorting = "ASC";
      setSort("ASC");
    }
    // setTableLoading(true);
    // sort=sort=="ASC"?setSort("DSC"):setSort("ASC")
    if (user.role === "captain") {
      captainDriverPayload.sort = sorting;
      captainDriverPayload.pageNo = 1;
      reteriveCaptainDriverList(captainDriverPayload);
    } else {
      payload.sort = sorting;
      payload.pageNo = 1;
      payload.key = key;
      reteriveDriverList(payload);
    }
  };

  const handleEditStatus = async (value, id) => {
    const displayText = value === true ? "activate" : "deactivate";

    const text = value === true ? "verified" : "deactivated";
    Modal.confirm({
      title: `Are you sure to ${displayText} driver`,
      okText: "Yes",
      onOk: () => {
        handleDeactivateDriver(id, text);
      },
    });
  };

  const handleDeactivateDriver = async (driverId, text) => {
    console.log("driverId on page", driverId, text);
    setTableLoading(true);
    const resp = await dispatch(deactivateDriver(driverId, text));
    if (resp) {
      reteriveDriverList();
      message.success(
        text == "verified"
          ? "Driver Activated successfully"
          : "Driver Deactivated successfully"
      );
      setTableLoading(false);
    }
  };

  const handleMultiDelete = async () => {
    setTableLoading(true);
    const resp = await dispatch(
      multiDeleteDrivers({ driver_ids: selectedRows })
    );
    if (resp) {
      message.success("Drivers deleted successfully");
      setSelectedRows([]);
      setTableLoading(false);
      reteriveDriverList();
    }
  };

  const handleSearch = async (value) => {
    setSearch(value);
    setSelectedCity(value);
    await dispatch(getCityData(value));
    // payload.selectedCity = "";
    // reteriveDriverList(payload);
    // setSelectedCity(value);
  };

  const [exportData, setExportData] = useState([]);

  const modifiedExportData = exportData.map((item, index) => ({
    "Sr. No.": index + 1,
    Name: item.name || "-",
    "Mobile No.": item?.number || "-",
    City: item?.city_id?.name || "-",
    "Email Address": item?.email || "-",
    Ratings: item?.average_rating || "-",
    "Duty Status": item?.duty_status || "-",
    "Approved At": item?.approved_at
      ? new Date(item?.approved_at).toLocaleDateString()
      : "-",
    "Last Login": new Date(item?.login_time) || "-",
  }));
  // console.log("modifiedExportData", modifiedExportData);

  const deleteKeys = (value) => {
    modifiedExportData.map((data) => delete data.City);

    // console.log("modifiedExportDatamodifiedExportData", modifiedExportData);
  };
  let selectedData = [];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setExportData(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      selectedData.push(selectedRowKeys);
      setSelectedRows(...selectedData);
      setSmsNotificationData([...selectedRows]);
      setSmsNotificationDataOrg([...selectedRows]);
    },
    // getCheckboxProps: (record) => ({
    //   disabled: record.name === 'Disabled User',
    //   // Column configuration not to be checked
    //   name: record.name,
    // }),
  };

  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    if (user.role === "captain") {
      captainDriverPayload.pageNo = page;
      reteriveCaptainDriverList(captainDriverPayload);
    } else {
      payload.pageNo = page;
      reteriveDriverList(payload);
    }
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);

    if (user.role === "captain") {
      captainDriverPayload.pageSize = pageSizes;
      reteriveCaptainDriverList(captainDriverPayload);
    } else {
      payload.pageSize = pageSizes;
      reteriveDriverList(payload);
    }
  };

  const handleSearchFilter = async (value) => {
    setSearchFilter(value);
    setPageNo(1);
    if (user.role === "captain") {
      captainDriverPayload.searchFilter = value;
      captainDriverPayload.pageNo = 1;
      reteriveCaptainDriverList(captainDriverPayload);
    } else {
      payload.searchFilter = value;
      payload.pageNo = 1;
      reteriveDriverList(payload);
    }
  };

  const handleCitySelect = async (value) => {
    setSelectedCity(value);
    setPageNo(1);
    if (search) {
      setSearch("");
      await dispatch(getCityData(""));
    }

    payload.selectedCity = value;
    payload.pageNo = 1;
    reteriveDriverList(payload);
  };
  const handleDutyStatus = async (value) => {
    setDutyStatus(value);
    setPageNo(1);

    if (user.role === "captain") {
      captainDriverPayload.dutyStatus = value;
      captainDriverPayload.pageNo = 1;
      reteriveCaptainDriverList(captainDriverPayload);
    } else {
      payload.dutyStatus = value;
      payload.pageNo = 1;
      reteriveDriverList(payload);
    }
  };
  const handleSelectTime = async (value) => {
    let val;
    if (value) val = value.format("YYYY-MM-DD");
    else {
      val = "";
    }
    setSelectedTime(val);
    setPageNo(1);

    if (user.role === "captain") {
      captainDriverPayload.login_time = val;
      captainDriverPayload.pageNo = 1;
      reteriveCaptainDriverList(captainDriverPayload);
    } else {
      payload.login_time = val;
      payload.pageNo = 1;
      reteriveDriverList(payload);
    }
  };

  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },
    {
      title: "Image",

      key: "Image",
      render: (t, r) => {
        if (!r.profile_image) {
          return (
            <div>
              <Avatar
                icon={<FeatherIcon icon="user" size="30" />}
                style={{
                  backgroundColor: getRandomColor(),
                  height: "40px",
                  width: "40px",
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                }}
              />
            </div>
          );
        } else {
          return (
            <>
              <Image
                height={40}
                width={40}
                style={{ borderRadius: "50%" }}
                // crossOrigin="anonymous"
                alt={"image"}
                src={`${IMAGE_URL_PREFIX}${r.profile_image}`}
              />
            </>
          );
        }
      },
    },
    {
      title: " Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Current Location City",
      key: "city",
      render: (data) => {
        return <span>{data?.city_id?.name ? data?.city_id?.name : "-"}</span>;
      },
    },
    {
      title: "Registered For City",
      key: "registeredcity",
      render: (data) => {
        return (
          <span>
            {data?.driverRegisteredCity?.name
              ? data?.driverRegisteredCity?.name
              : "-"}
          </span>
        );
      },
    },
    {
      title: "Last Location",
      render: (data) => {
        return <span>{data?.location?.location_name}</span>;
      },
    },
    {
      title: "Vehicle Type",
      render: (data) => {
        return <span>{data?.vehicle_type_id?.name}</span>;
      },
    },
    {
      title: "Average Rating",
      // dataIndex: "average_rating",
      key: "average_rating",
      render(data) {
        return (
          <>
            <Rate value={data.average_rating} allowHalf disabled />{" "}
            <span style={{ color: "#ccc" }}>
              ({parseFloat(data.average_rating).toFixed(1)})
            </span>
          </>
        );
      },
    },

    {
      title: "Duty Status",
      dataIndex: "duty_status",
      key: "duty_status",
      render: (status) => {
        return (
          <Tag
            color={
              status
                ? status?.toLowerCase() === AUTOCLICKER
                  ? "blue"
                  : status?.toLowerCase() === OFFDUTY
                  ? "volcano"
                  : status?.toLowerCase() === ONDUTY
                  ? "green"
                  : status?.toLowerCase() === BOOKFORHOME
                  ? "orange"
                  : ""
                : ""
            }
            key={status}
          >
            {status && WordFormate(status)}
          </Tag>
        );
      },
    },
    {
      // title: t("approvedAt"),
      title: (filters, sortOrder) => (
        <div>
          {t("approvedAt")}
          <Button
            style={{ background: "none", border: "none" }}
            onClick={() => handleSort("approved_at")}
          >
            <SwapOutlined rotate={90} style={{ color: "black" }} />
          </Button>
        </div>
      ),
      // sorter:((value,key)=>console.log("bjhsdjhjk",key)),
      align: "center",
      render: (data) => {
        const date = new Date(data?.approved_at);
        return (
          <span>
            <Tooltip title={date.toLocaleString()}>
              {data?.approved_at ? date.toLocaleDateString() : "-"}
            </Tooltip>
          </span>
        );
      },
    },
    {
      title: (
        <div>
          Last Login{" "}
          <Button
            style={{ background: "none", border: "none" }}
            onClick={() => handleSort("login_time")}
          >
            <SwapOutlined rotate={90} style={{ color: "black" }} />
          </Button>
        </div>
      ),
      key: "lastLogin",
      render: (data, index1, index) => {
        // console.log(data.login_time,index1);
        const date = new Date();
        if (data.login_time) {
          const date2 = new Date(data.login_time);
          const difference = date.getTime() - date2.getTime();
          const differenceInMinutes = difference / (1000 * 60);
          const differenceInHours = difference / (1000 * 60 * 60);
          const differenceInDays = difference / (1000 * 60 * 60 * 24);

          if (differenceInMinutes < 60) {
            return (
              <span>
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInMinutes)} minutes ago
                </Tooltip>
              </span>
            );
          } else if (differenceInHours < 24) {
            return (
              <span>
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInHours)} hours ago
                </Tooltip>
              </span>
            );
          } else {
            return (
              <span>
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInDays)} days ago
                </Tooltip>
              </span>
            );
          }
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      title: "Current Status",
      // dataIndex: "duty_status",
      key: "duty_status",
      render: (status) => {
        const statuss = status?.rideStatus?.split("_");
        let finalStatus = "";
        Array.isArray(statuss) &&
          statuss?.map(
            (value) =>
              // console.log("val;ue",value.charAt(0).toUpperCase())
              (finalStatus =
                finalStatus +
                value.charAt(0).toUpperCase() +
                value.slice(1) +
                " ")
          );

        return (
          <>
            <Tooltip
              title={
                status?.rideStatus !== "not_assigned"
                  ? `Driver is ${finalStatus} you can't change status`
                  : ""
              }
            >
              <Switch
                checkedChildren="Active"
                unCheckedChildren="DeActive"
                style={{
                  backgroundColor:
                    status.status !== "deactivated" ? "green" : "grey",
                }}
                disabled={status?.rideStatus !== "not_assigned"}
                checked={status.status !== "deactivated" ? true : false}
                onChange={(e) => {
                  handleEditStatus(e, status._id);
                }}
              />
            </Tooltip>
            <br />
            <span>{finalStatus}</span>
          </>
        );
      },
    },
    {
      title: "Wallet (₹)",
      key: "amount",
      render: (data) => {
        return (
          <span
            onClick={() => navigate(`driverWallet/${data._id}`)}
            style={{ cursor: "pointer" }}
          >
            {data?.wallet_amount?.toFixed(2)}
          </span>
        );
      },
    },
    // {
    //   title: t("action"),
    //   align: "center",
    //   render: (record, target) => {
    //     return (

    //       <div>
    //         <Button
    //           onClick={() => {
    //             navigate(`manageDriver/driverDetails/${record._id}`);
    //           }}
    //           type="primary"
    //         >
    //           <MenuUnfoldOutlined style={{ marginRight: 5 }} />
    //           <strong>Detail</strong>
    //         </Button>
    //         <Button
    //           onClick={() => {
    //             navigate(`manageDriver/driverWallet/${record._id}`);
    //           }}
    //           style={{ background: "yellow", margin: "0 4px 0px 4px" }}
    //         >
    //           <WalletOutlined style={{ marginRight: 5 }} />
    //           <strong>Wallet</strong>
    //         </Button>

    //       </div>

    //     );
    //   },
    // },
  ];
  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">{t("driver")}</h1>
            <BreadCrumb />
          </Col>
          {/* <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              style={{ float: "right" }}
              className="ADD"
              key="1"
              size="large"
              type="primary"
              // onClick={showModal}
            >
              <PlusOutlined /> Add Driver
            </Button>
          </Col> */}
          <Col lg={12} md={12} sm={12} xs={12}>
            {/* {user?.role == SUPERADMIN && (
              <Button
                style={{ float: "right" }}
                type="primary"
                danger
                disabled
                // disabled={selectedRows.length === 0 ? true : false}
                onClick={handleMultiDelete}
              >
                {" "}
                <DeleteOutlined /> Delete
              </Button>
            )}
            <br />
            <br /> */}
            {user?.role == SUPERADMIN && (
              <Button
                style={{ float: "right", marginLeft: "20px" }}
                type="primary"
                className="ADD"
                disabled={selectedRows.length === 0 ? true : false}
                // onClick={handleMultiDelete}
                onClick={() => {
                  setModalOpen(true);
                  setModalTitle("Send Notification");
                  setModalkey("notification");
                }}
              >
                {" "}
                <BellOutlined /> Notification
              </Button>
            )}
            {user?.role == SUPERADMIN && (
              <Button
                style={{ float: "right" }}
                type="primary"
                className="ADD"
                disabled={selectedRows.length === 0 ? true : false}
                // onClick={handleMultiDelete}
                onClick={() => {
                  setModalOpen(true);
                  setModalTitle("Send SMS");
                  setModalkey("sms");
                }}
              >
                {" "}
                <MessageOutlined /> SMS
              </Button>
            )}
          </Col>
        </Row>
        <Divider />
        {isLoading & isLoadingCity ? (
          <Loader />
        ) : (
          <>
            <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col
                  xxl={4}
                  xl={8}
                  lg={6}
                  md={8}
                  sm={12}
                  xs={24}
                  className="total"
                >
                  Total{" "}
                  {user.role === "captain"
                    ? drivers?.total_counts
                    : driverList?.total_counts}{" "}
                </Col>

                {user?.role != SUPERADMIN && (
                  <Col xxl={7} xl={0} lg={5} md={3} sm={12} xs={24}></Col>
                )}

                <Col xxl={4} xl={8} lg={6} md={8} sm={12} xs={24}>
                  <Input
                    placeholder="Search By Name Or Phone"
                    // enterButton="Search"
                    style={{ width: "100%" }}
                    // size="large"
                    onChange={(e) => handleSearchFilter(e.target.value)}
                    prefix={<SearchOutlined />}
                  />
                </Col>
                {user.role === SUPERADMIN && (
                  <Col xxl={4} xl={8} lg={5} md={8} sm={12} xs={24}>
                    <Select
                      placeholder="Choose city"
                      style={{
                        width: "100%",
                        maxHeight: "45px",
                        overflowY: "auto",
                        marginTop: "6px",
                      }}
                      showSearch
                      filterOption={false}
                      mode={"multiple"}
                      onSearch={(e) => handleSearch(e)}
                      allowClear
                      onChange={handleCitySelect}
                    >
                      {cityList &&
                        cityList.map((data) => {
                          return (
                            <Select.Option
                              value={data._id}
                              key={data._id}
                              className="selectOptions"
                            >
                              {data.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Col>
                )}
                <Col xxl={4} xl={8} lg={5} md={8} sm={12} xs={24}>
                  <Select
                    placeholder="Choose Duty Status"
                    style={{ width: "100%" }}
                    // showSearch
                    filterOption={false}
                    // onSearch={(e) => handleSearch(e)}
                    allowClear
                    onClear={() => handleDutyStatus("")}
                    onSelect={(value) => handleDutyStatus(value)}
                  >
                    <Select.Option value={AUTOCLICKER} key={AUTOCLICKER}>
                      <Tag color="blue">Auto clicker</Tag>
                    </Select.Option>
                    <Select.Option value={ONDUTY} key={ONDUTY}>
                      <Tag color={"green"}>On duty</Tag>
                    </Select.Option>
                    <Select.Option value={BOOKFORHOME} key={BOOKFORHOME}>
                      <Tag color="orange"> Book for home</Tag>
                    </Select.Option>
                    <Select.Option value={OFFDUTY} key={OFFDUTY}>
                      <Tag color="volcano"> Off duty</Tag>
                    </Select.Option>
                  </Select>
                </Col>

                <Col xxl={5} xl={8} lg={5} md={8} sm={12} xs={24}>
                  <DatePicker
                    showToday={false}
                    style={{
                      borderRadius: 0,
                      width: "100%",
                    }}
                    // size={"large"}
                    wrapperClassName="datepicker"
                    placeholder="Last Login Date"
                    onChange={(e) => handleSelectTime(e)}
                    format={"DD/MM/YYYY"}
                    disabledDate={(current) => current.isAfter(moment())}
                  />
                </Col>

                {user?.role == SUPERADMIN && (
                  <Col xxl={3} xl={8} lg={5} md={8} sm={12} xs={24}>
                    <Select
                      placeholder="Export Report"
                      style={{ width: "100%" }}
                      value={null}
                      onSelect={(value) => {
                        // setShowReportModal(true);
                        setShowVerify(true);
                        // deleteKeys(value);
                        setDocType(value);
                      }}
                    >
                      <Select.Option value="csv" className="selectOptions">
                        {" "}
                        <FileOutlined /> CSV
                      </Select.Option>
                      <Select.Option value="excel" className="selectOptions">
                        {" "}
                        <FileExcelOutlined /> Excel (XLSX)
                      </Select.Option>
                      <Select.Option value="pdf" className="selectOptions">
                        {" "}
                        <FilePdfOutlined /> Pdf
                      </Select.Option>
                    </Select>
                  </Col>
                )}
              </Row>
            </Card>
            <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                rowSelection={
                  user.role == SUPERADMIN && {
                    type: Checkbox,
                    ...rowSelection,
                  }
                }
                rowKey={"_id"}
                dataSource={
                  !tableLoading
                    ? user.role === "captain"
                      ? drivers?.list
                      : driverList?.list
                    : []
                }
                columns={
                  user.role == SUPERADMIN
                    ? columns
                    : columns.filter((key) => key.key != "city")
                }
                searchable
                scroll={{ x: "45vh" }}
                loading={tableLoading}
                onRow={(record) => {
                  return {
                    onDoubleClick: () => {
                      navigate(`driverDetails/${record._id}`);
                    },
                  };
                }}
                pagination={
                  // driverList?.total_counts > pageSize
                  //   ?
                  {
                    showSizeChanger: true,
                    // pageSize: pageSize,
                    current: pageNo,
                    total:
                      user.role === "captain"
                        ? drivers?.total_counts
                        : driverList?.total_counts,
                    pageSize: pageSize,
                    onShowSizeChange: onShowSizeChange,
                    onChange: onPageChange,
                    pageSizeOptions: ["50", "100", "500"],
                  }
                  // : false
                }
              />
            </Card>
          </>
        )}
      </div>

      <ReportModal
        showReportModal={showReportModal}
        setShowReportModal={setShowReportModal}
        modifiedExportData={modifiedExportData}
        docType={docType}
        reportKeys={reportKeys}
        docName={docName}
      />
      <VerifyOtpModal
        showVerify={showVerify}
        setShowVerify={setShowVerify}
        setShowReportModal={setShowReportModal}
        type={"Driver"}
      />
      <SmsNotificationModal
        modalOpen={modalOpen}
        modalTitle={modalTitle}
        setModalOpen={setModalOpen}
        smsNotificationData={smsNotificationData}
        setSmsNotificationData={setSmsNotificationData}
        smsNotificationDataOrg={smsNotificationDataOrg}
        modalKey={modalKey}
        role={"driver"}
      />
    </>
  );
};

export default ManageDriver;
