import { SearchOutlined, SwapOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import dayjs from "dayjs";
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CAPTAIN, SUPERADMIN } from "../../config/dataService/dataService";
import {
  getAllDriversList,
  getCaptainDriversListData,
} from "../../redux/drivers/actionCreatore";
import { getCityData } from "../../redux/Masters/City/actionCreatore";
import { getItem } from "../../utility/localStorageControl";

import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import OnDutyDrivers from "../Drivers/OnDutyDrivers";
import Loader from "../loader/loader";
import { SocketContext } from "../socket/socketContext";
import MultipleDriverTrack from "./MultipleDriverTrack";

const ManageDriver = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const [pageSize, setPageSize] = useState(50);
  const [pageNo, setPageNo] = useState(1);
  const [searchFilter, setSearchFilter] = useState("");
  const [dutyStatus, setDutyStatus] = useState("");
  const user = getItem("user_details");
  const [selectedCity, setSelectedCity] = useState(
    user.role == "admin" ? user?.city_id?._id : ""
  );
  const [activeKey, setActiveKey] = useState("single");
  const [sort, setSort] = useState("DSC");
  const [key, setSortKey] = useState("login_time");
  const [selectedTime, setSelectedTime] = useState("");

  useEffect(() => {
    // reteriveDriverList();
    // GetCity();
    user.role == "captain" && reteriveCaptainDriverList();
    window.scrollTo(0, 0);
  }, []);
  const socket = useContext(SocketContext);
  // console.log("socket", socket);
  // useEffect(() => {
  //   if (socket) {
  //     socket.on("conection", (data) => {
  //       console.log(data);
  //     });
  //   }
  //   window.scrollTo(0, 0);

  // }, [socket]);
  // useEffect(()=>{
  //     if(socket){
  //       // socket.emit("userCurrentLocation",{userId:"64105976dad5045759d54351"})
  //     }
  // },[socket])

  let payload = {
    pageNo,
    pageSize,
    searchFilter,
    dutyStatus,
    selectedCity,
    vehicleType: "",
    key,
    sort,
    login_time: selectedTime,
  };
  const { data, isLoading } = useQuery(
    "driversList",
    () => {
      return reteriveDriverList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataCity, isLoading: isLoadingCity } = useQuery(
    "cityList",
    () => {
      return GetCity();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const reteriveDriverList = async () => {
    setLoading(true);

    const resp = await dispatch(getAllDriversList(payload));
    if (resp) {
      setLoading(false);
    }
  };

  const captainId = user._id;
  const driverList = useSelector((state) => state.driver.driverListData);
  const reteriveCaptainDriverList = async () => {
    const resp = await dispatch(
      getCaptainDriversListData({
        pageNo,
        pageSize,
        captainId,
        searchFilter,
        dutyStatus,
      })
    );
    if (resp) {
      setLoading(false);
    }
  };

  const drivers = useSelector((state) => state.driver.captainDriverList);
  const GetCity = async () => {
    setLoading(true);
    const response = await dispatch(getCityData(search));
    if (response) {
      setLoading(false);
    }
  };
  let cityList = useSelector((state) => state?.city?.cityData);
  cityList = cityList?.sort((a, b) => a.name?.localeCompare(b.name));

  const handleTabClick = (key) => {
    setActiveKey(key);
  };
  const handleSort = async (key) => {
    let sorting;
    setSortKey(key);
    if (sort === "ASC") {
      setSort("DSC");
      sorting = "DSC";
    } else {
      sorting = "ASC";
      setSort("ASC");
    }
    // setTableLoading(true);
    // sort=sort=="ASC"?setSort("DSC"):setSort("ASC")
    if (user.role === "captain") {
      // captainDriverPayload.sort = sorting;
      // captainDriverPayload.pageNo = 1;
      // reteriveCaptainDriverList(captainDriverPayload);
    } else {
      payload.sort = sorting;
      payload.pageNo = 1;
      payload.key = key;
      reteriveDriverList(payload);
    }
  };
  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },
    {
      title: "Driver Name",
      key: "name",
      render: (data) => {
        return <span>{data && data?.name ? data?.name : "-"}</span>;
      },
    },
    {
      title: "Vehicle",
      children: [
        {
          title: "Type",
          key: "type",
          render: (data) => {
            return (
              <span>
                {data && data?.vehicle_type_id?.name
                  ? data?.vehicle_type_id?.name
                  : "-"}
              </span>
            );
          },
        },
        {
          title: "Number",
          key: "vehicleNumber",
          render: (data) => {
            return (
              <span>
                {data && data?.vehicle_number ? data?.vehicle_number : "-"}
              </span>
            );
          },
        },
      ],
    },

    {
      title: "Mobile No.",
      key: "mobile",
      render: (data) => {
        return <span>{data && data?.number ? data?.number : "-"}</span>;
      },
    },
    {
      title: "Device",
      children: [
        {
          title: "Type",
          key: "deviceType",
          render: (data) => {
            return (
              <span>{data && data?.device_type ? data?.device_type : "-"}</span>
            );
          },
        },
        {
          title: "Name",

          key: "deviceName",
          render: (data) => {
            return (
              <span>{data && data?.modelName ? data?.modelName : "-"}</span>
            );
          },
        },
      ],
    },

    {
      title: "App Version",
      // dataIndex: "appVersion",
      key: "apkVersion",
      render: (data) => {
        return <span>{data && data?.appVersion ? data?.appVersion : "-"}</span>;
      },
    },
    {
      title: (
        <div>
          Last Login{" "}
          <Button
            style={{ background: "none", border: "none" }}
            onClick={() => handleSort("login_time")}
          >
            <SwapOutlined rotate={90} style={{ color: "black" }} />
          </Button>
        </div>
      ),
      key: "lastLogin",
      render: (data, index1, index) => {
        // console.log(data.login_time,index1);
        const date = new Date();
        if (data.login_time) {
          const date2 = new Date(data.login_time);
          const difference = date.getTime() - date2.getTime();
          const differenceInMinutes = difference / (1000 * 60);
          const differenceInHours = difference / (1000 * 60 * 60);
          const differenceInDays = difference / (1000 * 60 * 60 * 24);

          if (differenceInMinutes < 60) {
            return (
              <span>
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInMinutes)} minutes ago
                </Tooltip>
              </span>
            );
          } else if (differenceInHours < 24) {
            return (
              <span>
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInHours)} hours ago
                </Tooltip>
              </span>
            );
          } else {
            return (
              <span>
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInDays)} days ago
                </Tooltip>
              </span>
            );
          }
        } else {
          return <span>-</span>;
        }
      },
    },
    // {
    //   title: "Latitude",
    //   // dataIndex: "latitude",
    //   key: "latitude",
    //   render: (data) => {
    //     return (
    //       <span>
    //         {data && data?.location?.coordinates[1]
    //           ? data?.location?.coordinates[1]
    //           : "-"}
    //       </span>
    //     );
    //   },
    // },
    // {
    //   title: "Longitude",
    //   key: "longitude",
    //   render: (data) => {
    //     return (
    //       <span>
    //         {data && data?.location?.coordinates[0]
    //           ? data?.location?.coordinates[0]
    //           : "-"}
    //       </span>
    //     );
    //   },
    // },
    {
      title: "GPS Tracking Address",
      key: "gpsAddress",
      render: (data) => {
        return (
          <span>
            {data && data?.location?.location_name
              ? data?.location?.location_name
              : "-"}
          </span>
        );
      },
    },
    {
      title: "Google Map Address",

      render: (data) => {
        return (
          <span
            onClick={() => navigate(`${data._id}/map`)}
            style={{ cursor: "pointer" }}
          >
            <FeatherIcon icon={"map"} />
          </span>
        );
      },
    },
  ];
  const handleSearch = async (value) => {
    setSearch(value);
    await dispatch(getCityData(value));
    // setSelectedCity(value);
    // payload.selectedCity = value;
    // reteriveDriverList(payload);
  };

  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    payload.pageNo = page;
    reteriveDriverList(payload);
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    payload.pageSize = pageSizes;
    reteriveDriverList(payload);
  };

  const handleSearchFilter = async (value) => {
    setSearchFilter(value);
    payload.pageNo = 1;
    payload.searchFilter = value;
    reteriveDriverList(payload);
  };

  const handleCitySelect = async (value) => {
    setSelectedCity(value);
    if (search) {
      setSearch("");
      await dispatch(getCityData(""));
    }
    payload.pageNo = 1;

    payload.selectedCity = value;
    reteriveDriverList(payload);
  };
  const handleDutyStatus = async (value) => {
    setDutyStatus(value);
    payload.pageNo = 1;
    payload.dutyStatus = value;
    reteriveDriverList(payload);
  };

  const handleSelectTime = async (value) => {
    let val;
    if (value) val = value.format("YYYY-MM-DD");
    else {
      val = "";
    }
    setSelectedTime(val);
    setPageNo(1);

    if (user.role === "captain") {
      // captainDriverPayload.login_time = val;
      // captainDriverPayload.pageNo = 1;
      // reteriveCaptainDriverList(captainDriverPayload);
    } else {
      payload.login_time = val;
      payload.pageNo = 1;
      reteriveDriverList(payload);
    }
  };

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">{t("liveTracking")}</h1>
            <BreadCrumb />
          </Col>
        </Row>
        <Divider />
        {isLoading & isLoadingCity ? (
          <Loader />
        ) : (
          <>
            <Tabs type="card" activeKey={activeKey} onTabClick={handleTabClick}>
              <TabPane tab="Single Driver Track" key="single">
                <Card style={{ marginBottom: 10 }} className="shadowCardBox">
                  <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                    <Col
                      xxl={7}
                      xl={5}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={24}
                      className="total"
                    >
                      Total{" "}
                      {user.role == CAPTAIN
                        ? drivers?.total_counts
                        : driverList?.total_counts}
                    </Col>
                    {user.role != SUPERADMIN && (
                      <Col xxl={4} xl={4} lg={4} md={0}></Col>
                    )}
                    <Col xxl={4} xl={4} lg={4} md={9} sm={12} xs={24}>
                      <Input
                        placeholder="Search By Name Or Phone"
                        // enterButton="Search"
                        style={{ width: "100%" }}
                        // size="large"
                        onChange={(e) => handleSearchFilter(e.target.value)}
                        prefix={<SearchOutlined />}
                      />
                    </Col>

                    {user.role == SUPERADMIN && (
                      <Col xxl={4} xl={4} lg={4} md={9} sm={12} xs={24}>
                        <Select
                          placeholder="Choose city"
                          style={{
                            width: "100%",
                            maxHeight: "45px",
                            overflowY: "auto",
                            marginTop: "6px",
                          }}
                          // size="large"
                          showSearch
                          filterOption={false}
                          allowClear
                          mode="multiple"
                          onSearch={(e) => handleSearch(e)}
                          onChange={handleCitySelect}
                        >
                          {cityList &&
                            cityList.map((data) => {
                              return (
                                <Select.Option
                                  value={data._id}
                                  key={data._id}
                                  className="selectOptions"
                                >
                                  {data.name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Col>
                    )}

                    <Col xxl={4} xl={4} lg={4} md={9} sm={12} xs={24}>
                      <Select
                        placeholder="Choose Duty Status"
                        style={{
                          width: "100%",
                          // , marginLeft: "20px"
                        }}
                        filterOption={false}
                        allowClear
                        onClear={() => handleDutyStatus("")}
                        onSelect={(value) => handleDutyStatus(value)}
                      >
                        <Select.Option
                          value={"auto_clicker"}
                          key={"auto_clicker"}
                        >
                          <Tag color="blue">Auto Clicker</Tag>
                        </Select.Option>
                        <Select.Option value={"on_duty"} key={"on_duty"}>
                          <Tag color={"green"}> on Duty</Tag>
                        </Select.Option>
                        <Select.Option value={"off_duty"} key={"off_duty"}>
                          <Tag color="orange"> Book for home</Tag>
                        </Select.Option>
                        <Select.Option value={"off_duty"} key={"off_duty"}>
                          <Tag color="volcano"> Off-Duty</Tag>
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col xxl={5} xl={6} lg={5} md={9} sm={12} xs={24}>
                      <DatePicker
                        showToday={false}
                        style={{ borderRadius: 0, width: "100%" }}
                        onChange={(e) => handleSelectTime(e)}
                        placeholder={"Last Login "}
                        format={"DD/MM/YYYY"}
                        disabledDate={(current) => current.isAfter(moment())}
                      />
                    </Col>
                  </Row>
                </Card>
                <Card className="shadowCardBox">
                  <Table
                    rowClassName={(record, index) =>
                      index % 2 === 0 ? "table-row-light" : "table-row-dark"
                    }
                    rowKey={"_id"}
                    dataSource={
                      user.role == "captain" ? drivers?.list : driverList?.list
                    }
                    columns={columns}
                    searchable
                    scroll={{ x: 10 }}
                    pagination={{
                      showSizeChanger: true,
                      pageSize: pageSize,
                      total:
                        user.role == "captain"
                          ? drivers?.total_counts
                          : driverList?.total_counts,
                      onShowSizeChange: onShowSizeChange,
                      onChange: onPageChange,
                      pageSizeOptions: ["50", "100", "500"],
                    }}
                  />
                </Card>
              </TabPane>
              <TabPane tab="Multiple Driver Track" key="multiple">
                <Card style={{ marginBottom: 10 }} className="shadowCardBox">
                  <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                    <Col lg={24} className="total">
                      {/* Total
                      {user.role == "captain"
                        ? drivers?.total_counts
                        : driverList?.total_counts} */}
                      <div
                        className="product-list-action d-flex justify-content-between align-items-center"
                        style={{ float: "right", margin: "0 10px" }}
                      >
                        <Select
                          placeholder="Choose Duty Status"
                          style={{ width: "30vw", marginLeft: "20px" }}
                          filterOption={false}
                          allowClear
                          onClear={() => handleDutyStatus("")}
                          onSelect={(value) => handleDutyStatus(value)}
                        >
                          <Select.Option
                            value={"auto_clicker"}
                            key={"auto_clicker"}
                          >
                            <Tag color="blue">Auto Clicker</Tag>
                          </Select.Option>
                          <Select.Option value={"on_duty"} key={"on_duty"}>
                            <Tag color={"green"}> on Duty</Tag>
                          </Select.Option>
                          <Select.Option
                            value={"book_for_home"}
                            key={"book_for_home"}
                          >
                            <Tag color="orange"> Book for home</Tag>
                          </Select.Option>
                          <Select.Option value={"off_duty"} key={"off_duty"}>
                            <Tag color="volcano"> Off-Duty</Tag>
                          </Select.Option>

                          {/* );
                        })} */}
                        </Select>
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Row>
                  <Col xxl={24} lg={24}>
                    <MultipleDriverTrack dutyStatus={dutyStatus} />
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </>
        )}
      </div>
      {/* {loading && <Loader />} */}
    </>
  );
};

export default ManageDriver;
