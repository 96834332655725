const actions = {
  GET_DRIVER_LIST: "GET_DRIVER_LIST",
  GET_DRIVER_BY_ID: "GET_DRIVER_BY_ID",
  GET_DRIVER_REQUESTS: "GET_DRIVER_REQUESTS",
  GET_DRIVER_DOCUMENTS: "GET_DRIVER_DOCUMENTS",
  GET_CAPTAIN_DRIVER_LIST: "GET_CAPTAIN_DRIVER_LIST",
  GET_ID_CARD: "GET_ID_CARD",
  GET_DRIVER_WALLET: "GET_DRIVER_WALLET",
  GET_TRANSACTIONLIST: "GET_TRANSACTIONLIST",
  GET_DRIVER_WALLET_CHART: "GET_DRIVER_WALLET_CHART",
  GET_RIDE_REQUESTS: "GET_RIDE_REQUESTS",
  GET_RATING_WISE_RIDES_ACCEPT: "GET_RATING_WISE_RIDES_ACCEPT",
  GET_DRIVER_REVERIFICATION_REQUESTS: "GET_DRIVER_REVERIFICATION_REQUESTS",
  GET_DRIVER_BANK_DETAILS_VERIFY_REQUESTS:
    "GET_DRIVER_BANK_DETAILS_VERIFY_REQUESTS",

  getDriverList: (data) => {
    return {
      type: actions.GET_DRIVER_LIST,
      data,
    };
  },
  getDriverIdCardDataById: (data) => {
    return {
      type: actions.GET_ID_CARD,
      data,
    };
  },
  getDriverDataById: (data) => {
    return {
      type: actions.GET_DRIVER_BY_ID,
      data,
    };
  },
  getDriverRequestsData: (data) => {
    return {
      type: actions.GET_DRIVER_REQUESTS,
      data,
    };
  },
  getDriverDocumentsData: (data) => {
    return {
      type: actions.GET_DRIVER_DOCUMENTS,
      data,
    };
  },
  getCaptainDriverList: (data) => {
    return {
      type: actions.GET_CAPTAIN_DRIVER_LIST,
      data,
    };
  },
  getDriverWallet: (data) => {
    return {
      type: actions.GET_DRIVER_WALLET,
      data,
    };
  },
  getTransactionList: (data) => {
    return {
      type: actions.GET_TRANSACTIONLIST,
      data,
    };
  },
  getDriverWalletChart: (data) => {
    return {
      type: actions.GET_DRIVER_WALLET_CHART,
      data,
    };
  },

  getRideRequests: (data) => {
    return {
      type: actions.GET_RIDE_REQUESTS,
      data,
    };
  },

  getRatingWiseRide: (data) => {
    return {
      type: actions.GET_RATING_WISE_RIDES_ACCEPT,
      data,
    };
  },
  getDriverReverificationRequestsData: (data) => {
    return {
      type: actions.GET_DRIVER_REVERIFICATION_REQUESTS,
      data,
    };
  },
  getDriverBankDetailsVerifyRequestData: (data) => {
    return {
      type: actions.GET_DRIVER_BANK_DETAILS_VERIFY_REQUESTS,
      data,
    };
  },
};

export default actions;
