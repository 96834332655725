import React, { useContext, useEffect, useState } from "react";
import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  withGoogleMap,
  withScriptjs,
  Polyline,
} from "react-google-maps";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  GOOGLE_MAP_API_SUBTYPE_DIRECTION_SERVICE_ROUTE,
  GOOGLE_MAP_API_SUBTYPE_QUOTA_SERVICE,
  GOOGLE_MAP_API_SUBTYPE_VIEW_PORT_INFO_SERVICE,
  GOOGLE_MAP_API_TYPE_DIRECTIONS,
  GOOGLE_MAP_API_TYPE_MAPS,
  GOOGLE_MAP_MODULE_NAME_RIDE_PATH,
  IMAGE_URL_PREFIX,
} from "../../config/dataService/dataService";
import { getBookingById } from "../../redux/Bookings/actionCreatore";
import { addMapStatistics } from "../../redux/MapStatistics/actionCreatore";
import destinationIcon from "../../static/img/stops/destination.png";
import stop1 from "../../static/img/stops/stop1.png";
import stop2 from "../../static/img/stops/stop2.png";
import stop3 from "../../static/img/stops/stop3.png";
import stop4 from "../../static/img/stops/stop4.png";
import userpickup from "../../static/img/stops/userpickup.png";
import { getItem } from "../../utility/localStorageControl";
import { SocketContext } from "../socket/socketContext";
import { Context } from "../../App";

const stopImages = [stop1, stop2, stop3, stop4];
const Map = ({ latlng, driverId, webRoleId, stoppage, waypoints }) => {
  const [path, setPath] = useState([]);

  const [directions, setDirections] = useState(null);
  const dispatch = useDispatch();
  const user = getItem("user_details");

  const [origin, setorigin] = useState({
    lat: latlng.origin?.coordinates[1],
    lng: latlng.origin?.coordinates[0],
  });
  const [destination, setDestination] = useState({
    lat: latlng.destination?.coordinates[1],
    lng: latlng.destination?.coordinates[0],
  });
  const socket = useContext(SocketContext);
  const [location, setLocation] = useState({});

  const [showInfowWindow, setShowInfowWindow] = useState(false);
  const [vehicleImage, setVehicleImage] = useState("");

  const stops = [
    {
      lat: latlng.origin?.coordinates[1],
      lng: latlng.origin?.coordinates[0],
      id: "origin",
      locationName: latlng?.origin?.location_name,
      image: userpickup,
    },

    {
      lat: latlng.destination?.coordinates[1],
      lng: latlng.destination?.coordinates[0],
      id: "destination",
      locationName: latlng?.destination?.location_name,

      // image: "https://www.google.com/mapfiles/markerB.png",
      image: destinationIcon,
    },
  ];
  useEffect(() => {
    const directionsService = new window.google.maps.DirectionsService();

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          let data = [];
          result.routes[0].overview_path.map((route) => {
            data.push({ lat: route.lat(), lng: route.lng() });
          });

          setPath(data);

          setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }, []);
  useEffect(() => {
    if (socket) {
      socket.emit("userCurrentLocation", {
        id: driverId,
        webRoleUserId: webRoleId,
      });

      socket.on("userCurrentLocationData", (data) => {
        setVehicleImage(data?.vehicle_type_id?.image_top_view);
        setLocation({
          lat: data.location.coordinates[1],
          lng: data.location.coordinates[0],
        });

        // setLoading(false)
      });
    }
  }, [socket]);

  const handleMapLoad = async () => {
    await dispatch(
      addMapStatistics({
        user_id: user._id,
        googleApiType: GOOGLE_MAP_API_TYPE_MAPS,
        googleSubApiType: GOOGLE_MAP_API_SUBTYPE_DIRECTION_SERVICE_ROUTE,
        moduleName: GOOGLE_MAP_MODULE_NAME_RIDE_PATH,
        platformType: "web",
        originFrom: user?.role,
        apiStatus: "success",
      })
    );
    await dispatch(
      addMapStatistics({
        user_id: user._id,
        googleApiType: GOOGLE_MAP_API_TYPE_MAPS,
        googleSubApiType: GOOGLE_MAP_API_SUBTYPE_VIEW_PORT_INFO_SERVICE,
        moduleName: GOOGLE_MAP_MODULE_NAME_RIDE_PATH,
        platformType: "web",
        originFrom: user?.role,
        apiStatus: "success",
      })
    );
    await dispatch(
      addMapStatistics({
        user_id: user._id,
        googleApiType: GOOGLE_MAP_API_TYPE_MAPS,
        googleSubApiType: GOOGLE_MAP_API_SUBTYPE_QUOTA_SERVICE,
        moduleName: GOOGLE_MAP_MODULE_NAME_RIDE_PATH,
        platformType: "web",
        originFrom: user?.role,
        apiStatus: "success",
      })
    );
  };

  return (
    <>
      <GoogleMap
        defaultZoom={16}
        defaultCenter={{
          lat: latlng?.coordinates?.origin[1],
          lng: latlng?.coordinates?.origin[0],
        }}
        onClick={(ev) => {
          console.log("latitide = ", ev.latLng.lat());
          console.log("longitude = ", ev.latLng.lng());
        }}
        onTilesLoaded={handleMapLoad}
      >
        <DirectionsRenderer
          directions={directions}
          // path={[origin, stoppage[1].start_location]}
          options={{
            polylineOptions: {
              strokeOpacity: 0.5,
              strokeWeight: 6,
              strokeColor: "#0f604d",
            },
            suppressMarkers: true,
            icon: { scale: 3 },
          }}
        />
        <Polyline
          traffic={new window.google.maps.TrafficLayer()}
          path={path}
          options={{
            strokeColor: "#0088FF",
            strokeWeight: 6,
            strokeOpacity: 0.6,
          }}
        />
        {stops.map((stop, index) => (
          <>
            <Marker
              // key={stop.id}
              position={{
                lat: stop.lat,
                lng: stop.lng,
              }}
              icon={{
                url: stop.image,
                scaledSize: new window.google.maps.Size(70, 70),
              }}
              title={stop.id}
              onClick={() => setShowInfowWindow(true)}
              // onClick={(props, marker) => {
              //   setPopup(true),
              //     // setSelectedPlace(props),
              //     setActiveMarker(marker);
              //   console.log("marker", marker);
              // }}
              // label={stop.id == "destination" && "B"}
            >
              {showInfowWindow && (
                <InfoWindow onCloseClick={() => setShowInfowWindow(false)}>
                  <div>
                    <h3>{stop.locationName}</h3>
                    {/* <p>Additional information or details about the location.</p> */}
                  </div>
                </InfoWindow>
              )}
            </Marker>
          </>
        ))}
        {stoppage.map((data, index) => {
          if (index > 0 && index < stoppage.length - 1)
            return (
              <Marker
                icon={{
                  url: stopImages[index - 1],
                }}
                position={data?.start_location}
              >
                {/* {showInfowWindow && */}
                <InfoWindow>
                  <div>
                    <h1>{data?.start_address}</h1>
                  </div>
                </InfoWindow>
                {/* } */}
              </Marker>
            );
        })}
        <Marker
          icon={{
            url: IMAGE_URL_PREFIX + vehicleImage,
            scaledSize: new window.google.maps.Size(40, 40),
          }}
          position={location}
        >
          {/* {showInfowWindow && */}
          {/* <InfoWindow>
                  <div>
                    <h1>{data?.start_address}</h1>
                  </div>
                </InfoWindow> */}
          {/* } */}
        </Marker>
        {/* {progress && (
          <>
            {console.log("progressssssss", progress)}
            <Polyline path={progress} options={{ strokeColor: "green" }} />

            <Marker
              icon={icon1}
              // position={progress[progress.length - 1]}
              position={{ lat: location[1], lng: location[0] }}
              // position={{lat: 23.01973,
              //   lng: 72.5549}}
            />
          </>
        )} */}
        {/* <TrafficLayer autoUpdate /> */}
      </GoogleMap>
    </>
  );
};

const DirectionMap = () => {
  const GOOGLE_MAP_API_KEY = useContext(Context);

  const MapComponent = withScriptjs(withGoogleMap(Map));
  const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${GOOGLE_MAP_API_KEY}`;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const user = getItem("user_details");
  useEffect(() => {
    reteriveBookingData();
  }, []);
  const { id } = useParams();
  console.log("id2", id);
  const reteriveBookingData = async () => {
    setLoading(true);
    const resp = await dispatch(getBookingById(id));
    if (resp) {
      setLoading(false);
    }
  };

  const liveData = useSelector((state) => state.booking.bookingDataById);

  let waypoints = [];
  liveData?.rideData?.legs.map((data, index) => {
    if (index > 0 && index < liveData?.rideData?.legs.length - 1) {
      waypoints.push({ location: data.start_location });
    }
  });
  const handleMapLoadError = () => {
    console.log("map load error");
  };
  return (
    <>
      {!loading && (
        // <LoadScript
        //   googleMapsApiKey={GOOGLE_MAP_API_KEY}
        //   onError={handleMapLoadError}
        // >
        <MapComponent
          googleMapURL={mapURL}
          loadingElement={<div style={{ height: "100vh" }} />}
          containerElement={<div style={{ height: `90vh` }} />}
          mapElement={<div style={{ height: `90vh` }} />}
          latlng={{
            origin: liveData?.pickup_location,
            destination: liveData?.destination_location,
          }}
          stoppage={liveData?.rideData?.legs}
          driverId={liveData?.driver_id}
          webRoleId={user._id}
          waypoints={waypoints}

          // latlng={{
          //   origin: [72.4634, 23.03367],
          //   destination: [72.56309, 23.01119],
          // }}
        />
        // </LoadScript>
      )}
    </>
  );
};
export default DirectionMap;
