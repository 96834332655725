import React from "react";
// import loading from "./loading.gif";
import carLoader from "./carLoader.gif";

import "./loading.css";

const Spinner = () => {
  return (
    <div className="loadinggif">
      <img src={carLoader} alt="loading" />
    </div>
  );
};

export default Spinner;
