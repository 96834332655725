import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const { fetchDriverCenterList, fetchDriverCenterById,fetchDriverCenterListWithoutPagination } = actions;

export const getDriverCenterList = (
  search,
  pageNo,
  limit,
  cityId,
  key,
  sort,
  selectedTime
) => {
    return async (dispatch) => {
    // let queryParametere = "?page=" + pageNo + "&limit=" + limit + "&search="search;
    let payload = {
      page: pageNo,
      limit: limit,
      search: search,
      // city_id: cityId,
      key: key,
      value: sort,
      login_time: selectedTime,
    };
    if (cityId.length > 0) {
      payload.city_id = cityId;
    }
    const resp = await DataService.post(API.driverCenter.getAll, payload);

    if (!resp.data.error) {
      dispatch(fetchDriverCenterList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const createDriverCenter = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.driverCenter.add, payload);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const updateDriverCenter = (payload, id) => {
  return async (dispatch) => {
    const resp = await DataService.patch(API.driverCenter.update + id, payload);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const removeDriverCenter = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.driverCenter.delete + id);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getDriverCenterById = (id) => {
  return async (dispatch) => {
    const resp = await DataService.get(API.driverCenter.getAll + "/" + id);

    if (!resp.data.error) {
      dispatch(fetchDriverCenterById(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const getDriverCenterListWithoutPagination = (
  {search,

  cityId,
  captainId}
) => {
  return async (dispatch) => {
    // let queryParametere = "?page=" + pageNo + "&limit=" + limit + "&search="search;
    let payload = {
      search: search,
      city_id: cityId,
      ignoreIds: captainId,
    };
    
    const resp = await DataService.post(API.driverCenter.getWithoutPagination, payload);

    if (!resp.data.error) {
      dispatch(fetchDriverCenterListWithoutPagination(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
