import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const { contactUsList } = actions;

export const getContactUsList = (page, limit, role, city, search) => {
  return async (dispatch) => {
    const queryParameter =
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&role=" +
      role +
      "&city_id=" +
      city +
      "&search=" +
      search;
    const resp = await DataService.get(API.contactUs.get + queryParameter);

    if (!resp.data.error) {
      dispatch(contactUsList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
