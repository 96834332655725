import { Card } from "antd";
import React from "react";
import { H2, H4 } from "../../components/heading/style";
import loadingGif from "./carLoader.gif";
const Loader = () => {
  return (
    <>
      {/* for car loader */}
      <div
        style={{
          position: "absolute",
          top: "30%",
          right: "35%",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <img src={loadingGif} alt="loading" />
      </div>

      {/* for spinner loader */}
      {/* <Card
        style={{
          position: "absolute",
          top: "30%",
          right: "35%",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <div
          style={{ margin: "auto 150px", textAlign: "center" }}
          className="lds-spinner"
        >

          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <H4 style={{ marginTop: "10px" }}>Processing....</H4>
      </Card> */}
    </>
  );
};

export default Loader;
