import actions from "./actions";

const {
  GET_DRIVER_LIST_FOR_TAG,
  GET_RIDER_LIST_FOR_TAG,
  GET_NOTIFICATION_LIST,
  GET_NOTIFICATION_NOTICE_LIST,
} = actions;

const initState = {
  driverListDataWithPagination: {},
  riderListDataWithPagination: {},
  notificationList: {},
  notificationNoticeList: {},
};

const SmsNotificationReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_DRIVER_LIST_FOR_TAG:
      return {
        ...state,
        driverListDataWithPagination: data,
      };
    case GET_RIDER_LIST_FOR_TAG:
      return {
        ...state,
        riderListDataWithPagination: data,
      };
    case GET_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: data,
      };
    case GET_NOTIFICATION_NOTICE_LIST:
      return {
        ...state,
        notificationNoticeList: data,
      };
    default:
      return state;
  }
};
export default SmsNotificationReducer;
