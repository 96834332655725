import { message } from "antd";
import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";

export const sendOTP = ({ type }) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.reportOTP.send, { type: type });

    if (!resp.data.error) {
      message.success(resp.data.message);
      return true;
    } else {
      // message.error(resp.data.message);
      return false;
    }
  };
};

export const verifyOTP = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.reportOTP.verify, payload);
    if (!resp.error) {
      return true;
    } else {
      // message.error(resp.data.message);
      return false;
    }
  };
};
