import { Button, Modal, Spin } from "antd";
import React, { Suspense, useState } from "react";
import { Route, Routes } from "react-router-dom";
import AppData from "../../container/AppData/AppData";
import BeOurParterEdit from "../../container/BeOurPartners.js/BeOurParterEdit";
import BeOurPartner from "../../container/BeOurPartners.js/BeOurPartner";
import ManageBooking from "../../container/Bookings/ManageBooking/ManageBooking";
import ScheduleBooking from "../../container/Bookings/ScheduleBooking/ScheduleBooking";
import SearchBooking from "../../container/Bookings/SearchBooking/SearchBooking";
import DriverRequests from "../../container/Drivers/driverRequests/DriverRequests";
import Request from "../../container/Drivers/driverRequests/Request";
import ManageInsurance from "../../container/Drivers/insurance/ManageInsurance";
import DriverDetails from "../../container/Drivers/manageDriver/DriverDetails";
import DriverWallet from "../../container/Drivers/manageDriver/DriverWallet";
import ManageDriver from "../../container/Drivers/manageDriver/ManageDriver";
import OnDutyDrivers from "../../container/Drivers/OnDutyDrivers";
import Feedback from "../../container/Feedback/Feedback";
import Forbidden from "../../container/Forbidden/Forbidden";
import DirectionMap from "../../container/LiveTrack/DirectionMap";
import LiveDriver from "../../container/LiveTrack/LiveDriverMap";
import LiveDriverTrack from "../../container/LiveTrack/LiveDriverTrack";
import ManageFare from "../../container/Manage fare/ManageFare";
import ManageFareAdd from "../../container/Manage fare/ManageFareAdd";
import ManageFareUpdate from "../../container/Manage fare/ManageFareUpdate";
import ManageAdmin from "../../container/ManageAdminPanelUser/ManageAdmin/ManageAdmin";
import ManageCallCenter from "../../container/ManageAdminPanelUser/ManageCallCenter/ManageCallCenter";
import ManageDriverCenter from "../../container/ManageAdminPanelUser/ManageCaptain/Captains";
import DriversUnderCaptain from "../../container/ManageAdminPanelUser/ManageCaptain/DriversUnderCaptain";
import ManageCoupons from "../../container/ManageCoupons/ManageCoupons";
import ManageRider from "../../container/ManageRider/ManageRider";
import Rider from "../../container/ManageRider/Rider";
import AboutUs from "../../container/Master/AboutUs";
import ManageCity from "../../container/Master/City/City";
import NotificationImage from "../../container/Master/NotificationImage";
import PoliceVerification from "../../container/Master/PoliceVerification";
import TipMaster from "../../container/Master/TipMaster";
import VehicleType from "../../container/Master/VehicleType";
import Notification from "../../container/Notification/Notification";
import YourProfile from "../../container/ProfileSection/YourProfile";
import RoleManagement from "../../container/Rolemanagement/RoleManagement";
import Roles from "../../container/Rolemanagement/Roles";
import Settings from "../../container/Settings/Settings";
import TripRating from "../../container/TripRating/TripRating";
import VehicleList from "../../container/VehicleList/VehicleList";
import withAdminLayout from "../../layout/withAdminLayout";
// import Dashboard from "./dashboard";
import Dashboard from "../../container/dashboard";

import Cookies from "js-cookie";
import IdleTimer from "react-idle-timer";
// import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";

import ActivityLogs from "../../container/ActivityLogs/ActivityLogs";
import BookingDetails from "../../container/Bookings/ManageBooking/BookingDetail";
import Earning from "../../container/Commission/Commission";
import ContactUs from "../../container/ContactUs/ContactUs";
import DriverReVerificationRequests from "../../container/Drivers/driverRequests/DriverReverificationRequest";
import ReVerficationRequest from "../../container/Drivers/driverRequests/ReVerificationRequest";
import RidesRatingWise from "../../container/Drivers/RidesRaingWise";
import Exotelcalls from "../../container/Exotel Calls/Exotelcalls";
import LiveRider from "../../container/LiveTrack/LiveRider";
import AdminCaptains from "../../container/ManageAdminPanelUser/ManageAdmin/AdminCaptains";
import AddCoupon from "../../container/ManageCoupons/AddCoupon";
import UpdateCoupon from "../../container/ManageCoupons/UpdateCoupon";
import MapStatistics from "../../container/MapStatistics/MapStatistics";
import OverallStatistics from "../../container/MapStatistics/OverallStatistics";
import CancelBookingReason from "../../container/Master/CancelBookingReason";
import DeleteProfileReason from "../../container/Master/DeleteProfileReason";
import RatingTags from "../../container/Master/RatingTags";
import ManageTier from "../../container/Master/Tier";
import VehicleModal from "../../container/Master/VehicleModal";
import SupportCategory from "../../container/Support/Category";
import SupportSubCategory from "../../container/Support/SubCategory";
import SupportAnswers from "../../container/Support/SupportAnswers";
import AddNewSmsTemplate from "../../container/Template/AddNewSmsTemplate";
import NotificationTemplate from "../../container/Template/NotificationTemplate";
import SmsTemplate from "../../container/Template/SMStemplate";
import Wallet from "../../container/Wallet/Wallet";
import { logOut } from "../../redux/authentication/actionCreator";
import { getItem } from "../../utility/localStorageControl";
import WithdrawalRequest from "../../container/Payment/WithdrawalRequest";
import RiderWallet from "../../container/ManageRider/RiderWallet";
import DriverBankDetailVerificationRequest from "../../container/Drivers/driverRequests/DriverBankDetailVerificationRequest";
import DriverRejectionReason from "../../container/Master/DriverRejectionReason";

const Admin = () => {
  // const { path } = useMatch();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const userDetails = getItem("user_details");
  const userRole = userDetails?.role;

  const onIdle = () => {
    setShowModal(true);
    Cookies.remove("logedIn");

    // sessionTimeoutRef.current = setTimeout(logOut, 5000);
  };
  const SignOut = (e) => {
    e.preventDefault();
    dispatch(logOut());
  };

  return (
    <>
      <IdleTimer
        timeout={1000 * 60 * userDetails?.sessionExpireTime}
        onIdle={onIdle}
      />

      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        closable={false}
        maskClosable={false}
        title={"Session expired"}
      >
        <h4> You sesssion has timed out. Please log in again.</h4>

        <Button type="primary" onClick={SignOut}>
          Ok
        </Button>
        {/* <button onClick={stayLoggedIn}>Keep me signed in</button> */}
      </Modal>

      <Suspense
        fallback={
          <div className="spin">
            <Spin size="large" />
          </div>
        }
      >
        <Routes>
          <Route path={`${userRole}/`} element={<Dashboard />} />
          <Route
            exact
            path={`${userRole}/rolemanagement`}
            element={<RoleManagement />}
          />
          <Route
            exact
            path={`${userRole}/rolemanagement/:id`}
            element={<Roles />}
          />
          <Route
            exact
            path={`${userRole}/manageAdmin`}
            element={<ManageAdmin />}
          />
          <Route
            exact
            path={`${userRole}/manageAdmin/captains/:id`}
            element={<AdminCaptains />}
          />

          <Route
            exact
            path={`${userRole}/manageCallCenter`}
            element={<ManageCallCenter />}
          />
          <Route
            exact
            path={`${userRole}/manageCaptains`}
            element={<ManageDriverCenter />}
          />
          <Route
            exact
            path={`${userRole}/manageCaptains/drivers/:captainId`}
            element={<DriversUnderCaptain />}
          />
          <Route
            exact
            path={`${userRole}/searchBooking`}
            element={<SearchBooking />}
          />
          <Route
            exact
            path={`${userRole}/manageBooking`}
            element={<ManageBooking />}
          />
          <Route
            exact
            path={`${userRole}/manageBooking/:id`}
            element={<BookingDetails />}
          />
          <Route
            exact
            path={`${userRole}/scheduleBooking`}
            element={<ScheduleBooking />}
          />
          <Route
            exact
            path={`${userRole}/manageDriver/driver`}
            element={<ManageDriver />}
          />
          <Route
            exact
            path={`${userRole}/manageDriver/driver/driverWallet/:driverId`}
            element={<DriverWallet />}
          />
          <Route
            exact
            path={`${userRole}/manageDriver/driverRequests`}
            element={<DriverRequests />}
          />
          <Route
            exact
            path={`${userRole}/manageDriver/driverReverficationRequest`}
            element={<DriverReVerificationRequests />}
          />
          <Route
            exact
            path={`${userRole}/manageDriver/driverBankDetailsVerficationRequest`}
            element={<DriverBankDetailVerificationRequest />}
          />
          <Route
            exact
            path={`${userRole}/manageDriver/driver/driverDetails/:driverId`}
            element={<DriverDetails />}
          />
          <Route
            exact
            path={`${userRole}/manageDriver/driverRequests/:driverId`}
            element={<Request />}
          />
          <Route
            exact
            path={`${userRole}/manageDriver/driverReverficationRequest/request/:driverId`}
            element={<ReVerficationRequest />}
          />
          <Route
            exact
            path={`${userRole}/manageDriver/ratingWiseRideAccept`}
            element={<RidesRatingWise />}
          />

          <Route
            exact
            path={`${userRole}/manageCoupons`}
            element={<ManageCoupons />}
          />
          <Route
            exact
            path={`${userRole}/manageCoupons/createCoupon`}
            element={<AddCoupon />}
          />
          <Route
            exact
            path={`${userRole}/manageCoupons/updateCoupon/:id`}
            element={<UpdateCoupon />}
          />

          <Route
            exact
            path={`${userRole}/manageRider`}
            element={<ManageRider />}
          />
          <Route
            exact
            path={`${userRole}/manageRider/rider/:riderId`}
            element={<Rider />}
          />
          <Route
            exact
            path={`${userRole}/manageRider/rider/:riderId/map`}
            element={<LiveRider />}
          />
          <Route
            exact
            path={`${userRole}/manageRider/riderWallet/:riderId`}
            element={<RiderWallet />}
          />
          <Route
            exact
            path={`${userRole}/master/manageFare`}
            element={<ManageFare />}
          />

          <Route
            exact
            path={`${userRole}/master/manageFare/add`}
            element={<ManageFareAdd />}
          />
          <Route
            exact
            path={`${userRole}/master/manageFare/update/:id`}
            element={<ManageFareUpdate />}
          />
          <Route
            exact
            path={`${userRole}/master/profileDeleteReasons`}
            element={<DeleteProfileReason />}
          />
          <Route
            exact
            path={`${userRole}/master/bookingCancelReasons`}
            element={<CancelBookingReason />}
          />

          <Route
            exact
            path={`${userRole}/master/ratingTags`}
            element={<RatingTags />}
          />

          <Route exact path={`${userRole}/feedbacks`} element={<Feedback />} />
          <Route exact path={`${userRole}/appData`} element={<AppData />} />
          <Route exact path={`${userRole}/reviews`} element={<TripRating />} />

          <Route
            exact
            path={`${userRole}/manageDriver/vehicles`}
            element={<VehicleList />}
          />
          <Route
            exact
            path={`${userRole}/liveDrivers`}
            element={<LiveDriverTrack />}
          />
          <Route
            exact
            path={`${userRole}/liveDrivers/:id/map`}
            element={<LiveDriver />}
          />

          <Route
            exact
            path={`${userRole}/LiveTrip/:id/map`}
            element={<DirectionMap />}
          />

          <Route
            exact
            path={`${userRole}/notification`}
            element={<Notification />}
          />
          {/* Masters */}
          <Route
            exact
            path={`${userRole}/master/vehicletype`}
            element={<VehicleType />}
          />
          <Route
            exact
            path={`${userRole}/master/vehicleModel`}
            element={<VehicleModal />}
          />
          <Route
            exact
            path={`${userRole}/master/notificationImage`}
            element={<NotificationImage />}
          />
          <Route
            exact
            path={`${userRole}/master/manageCity`}
            element={<ManageCity />}
          />
          <Route
            exact
            path={`${userRole}/master/driverRejectionReasons`}
            element={<DriverRejectionReason />}
          />

          <Route exact path={`${userRole}/aboutus`} element={<AboutUs />} />
          <Route exact path={`${userRole}/earning`} element={<Earning />} />

          <Route exact path={`${userRole}/profile`} element={<YourProfile />} />
          <Route exact path={`${userRole}/wallet`} element={<Wallet />} />

          <Route
            exact
            path={`${userRole}/insurance`}
            element={<ManageInsurance />}
          />
          <Route
            exact
            path={`${userRole}/master/policeVerificationDoc`}
            element={<PoliceVerification />}
          />
          <Route
            exact
            path={`${userRole}/master/tips`}
            element={<TipMaster />}
          />
          <Route exact path={`${userRole}/forbidden`} element={<Forbidden />} />
          <Route exact path={`${userRole}/settings`} element={<Settings />} />
          <Route
            exact
            path={`${userRole}/onDutyDrivers`}
            element={<OnDutyDrivers />}
          />
          <Route
            exact
            path={`${userRole}/BeOurAffiliatePartner`}
            element={<BeOurPartner />}
          />
          <Route
            exact
            path={`${userRole}/BeOurAffiliatePartner/detail/:id`}
            element={<BeOurParterEdit />}
          />

          <Route exact path={`${userRole}/contactUs`} element={<ContactUs />} />
          <Route exact path={`${userRole}/tier`} element={<ManageTier />} />

          <Route
            exact
            path={`${userRole}/support/category`}
            element={<SupportCategory />}
          />
          <Route
            exact
            path={`${userRole}/support/queries`}
            element={<SupportSubCategory />}
          />
          <Route
            exact
            path={`${userRole}/support/answers`}
            element={<SupportAnswers />}
          />
          <Route
            exact
            path={`${userRole}/template/sms`}
            element={<SmsTemplate />}
          />
          <Route
            exact
            path={`${userRole}/template/sms/add`}
            element={<AddNewSmsTemplate />}
          />
          <Route
            exact
            path={`${userRole}/template/notification`}
            element={<NotificationTemplate />}
          />
          <Route
            exact
            path={`${userRole}/mapStatistics`}
            element={<MapStatistics />}
          />
          <Route
            exact
            path={`${userRole}/mapOverallStatistics`}
            element={<OverallStatistics />}
          />
          <Route
            exact
            path={`${userRole}/activityLogs`}
            element={<ActivityLogs />}
          />
          <Route
            exact
            path={`${userRole}/exotelCalls`}
            element={<Exotelcalls />}
          />
          <Route
            exact
            path={`${userRole}/paymentRequest`}
            element={<WithdrawalRequest />}
          />

          {/* <Route path='/*'  element={NotFound404} /> */}
        </Routes>
      </Suspense>
    </>
  );
};

export default withAdminLayout(Admin);
