import actions from "./actions";

const { GET_TIERS } = actions;

const initState = {
  tiersData: {},
  // newCityData: {},
};

const tierReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_TIERS:
      return {
        ...state,
        tiersData: data,
      };

    default:
      return state;
  }
};
export default tierReducer;
