import { SearchOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  Rate,
  Row,
  Select,
  Table,
  Tabs,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN } from "../../config/dataService/dataService";
import { getCityData } from "../../redux/Masters/City/actionCreatore";
import {
  getDriversRating,
  getRidersRating,
} from "../../redux/Ratings/actionCreatore";
import { getItem } from "../../utility/localStorageControl";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import Loader from "../loader/loader";
const { RangePicker } = DatePicker;

const Triprating = () => {
  const dispatch = useDispatch();
  const { TabPane } = Tabs;

  const { t } = useTranslation();
  const user = getItem("user_details");

  const [loading, setLoading] = useState(false);
  const [pageNoDriver, setPageNoDriver] = useState(1);
  const [limitDriver, setLimitDriver] = useState(50);

  const [activeKey, setActiveKey] = useState("1");
  const [searchByDriver, setSearchByDriver] = useState("");
  const [ratingFilterDriver, setRatingFilterDriver] = useState("");
  const [selectedCityDriver, setSelectedCityDriver] = useState(
    user.role == ADMIN ? user?.city_id?._id : ""
  );
  const [fromDateDriver, setFromDateDriver] = useState(dayjs());
  const [toDateDriver, setToDateDriver] = useState(dayjs());
  const [pageNoRider, setPageNoRider] = useState(1);
  const [limitRider, setLimitRider] = useState(50);
  const [searchByRider, setSearchByRider] = useState("");
  const [ratingFilterRider, setRatingFilterRider] = useState("");
  const [selectedCityRider, setSelectedCityRider] = useState(
    user.role == ADMIN ? user?.city_id?._id : ""
  );
  const [fromDateRider, setFromDateRider] = useState(dayjs());
  const [toDateRider, setToDateRider] = useState(dayjs());
  const payloadRider = {
    pageNo: pageNoRider,
    limit: limitRider,
    searchBy: searchByRider,
    ratingFilter: ratingFilterRider,
    city: selectedCityRider,
    fromDate: fromDateRider,
    toDate: toDateRider,
  };
  const payloadDriver = {
    pageNo: pageNoDriver,
    limit: limitDriver,
    searchBy: searchByDriver,
    ratingFilter: ratingFilterDriver,
    city: selectedCityDriver,
    fromDate: fromDateDriver,
    toDate: toDateDriver,
  };

  const rangePresets = [
    { label: "Today", value: [dayjs(), dayjs()] },
    { label: "Last 7 Days", value: [dayjs().add(-7, "days"), dayjs()] },
    { label: "Last Month", value: [dayjs().add(-1, "month"), dayjs()] },
    { label: "Last 6 Month", value: [dayjs().add(-6, "month"), dayjs()] },
    { label: "Last Year", value: [dayjs().add(-1, "year"), dayjs()] },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    reteriveCityList();
  }, []);

  const { data, isLoading } = useQuery(
    "riderReviewList",
    () => {
      return reteriveRiderReviews(payloadRider);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataDriver, isLoading: isLoadingDriver } = useQuery(
    "driverReviewList",
    () => {
      return reteriveDriverReviews(payloadDriver);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const reteriveRiderReviews = async (payloadRider) => {
    setLoading(true);
    const resp = await dispatch(getRidersRating(payloadRider));
    if (resp) {
      // console.log(resp);
      setLoading(false);
    }
  };
  let rating = [];
  const riderRating = useSelector((state) => state.rating.riderRatingListData);
  // console.log(riderRating);

  const reteriveDriverReviews = async (payloadDriver) => {
    // setLoading(true);
    const resp = await dispatch(getDriversRating(payloadDriver));
    if (resp) {
      // console.log(resp);
      // setLoading(false);
    }
  };
  const driverRating = useSelector(
    (state) => state.rating.driverRatingListData
  );
  // console.log(driverRating);
  const reteriveCityList = async () => {
    await dispatch(getCityData(""));
  };
  let cityList = useSelector((state) => state.city.cityData);
  cityList = cityList?.sort((a, b) => a.name?.localeCompare(b.name));
  const handleSearchRider = async (value) => {
    setSearchByRider(value);
    payloadRider.searchBy(value);
  };

  const handleSearchDriver = async (value) => {
    setSearchByDriver(value);
    payloadDriver.searchBy = value;
  };

  const handleSelectRatingRider = async (value) => {
    setRatingFilterRider(value);
    payloadRider.ratingFilter = value;
  };

  const handleSelectRatingDriver = async (value) => {
    setRatingFilterDriver(value);
    payloadDriver.ratingFilter = value;
  };

  //CHANGE PAGE  FUNCTION CALL
  const onPageChangeRider = async (page, pageSizes) => {
    setPageNoRider(page);
    payloadRider.pageNo = page;
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChangeRider = async (current, pageSizes, key) => {
    // setPageSize(pageSizes);
    setLimitRider(pageSizes);
    payloadRider.limit = pageSizes;
  };

  //CHANGE PAGE  FUNCTION CALL
  const onPageChangeDriver = async (page, pageSizes) => {
    setPageNoDriver(page);
    payloadDriver.pageNo = page;
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChangeDriver = async (current, pageSizes, key) => {
    // setPageSize(pageSizes);
    setLimitDriver(pageSizes);
    payloadDriver.limit = pageSizes;
  };

  const handleSelectedCityRider = async (value) => {
    setSelectedCityRider(value);
    setPageNoRider(1);
    payloadRider.pageNo = 1;
    payloadRider.city = value;
    // setTableLoading(true);
  };
  const handleSelectedCityDriver = async (value) => {
    setSelectedCityDriver(value);
    setPageNoDriver(1);
    payloadDriver.pageNo = 1;
    payloadDriver.city = value;
    // setTableLoading(true);
  };
  const onRangeChangeRider = async (dates, dateStrings) => {
    if (dates) {
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setFromDateRider(dates[0]);
      setToDateRider(dates[1]);
      payloadRider.fromDate = dates[0];
      payloadRider.toDate = dates[1];
    } else {
      setFromDateRider("");
      setToDateRider("");
      payloadRider.fromDate = "";
      payloadRider.toDate = "";
    }
  };

  const onRangeChangeDriver = async (dates, dateStrings) => {
    if (dates) {
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setFromDateDriver(dates[0]);
      setToDateDriver(dates[1]);
      payloadDriver.fromDate = dates[0];
      payloadDriver.toDate = dates[1];
    } else {
      setFromDateDriver("");
      setToDateDriver("");
      payloadDriver.fromDate = "";
      payloadDriver.toDate = "";
    }
  };
  useEffect(() => {
    reteriveRiderReviews(payloadRider);
  }, [
    pageNoRider,
    limitRider,
    searchByRider,
    selectedCityRider,
    ratingFilterRider,
    fromDateRider,
    toDateRider,
  ]);

  useEffect(() => {
    reteriveDriverReviews(payloadDriver);
  }, [
    pageNoDriver,
    limitDriver,
    searchByDriver,
    selectedCityDriver,
    ratingFilterDriver,
    fromDateDriver,
    toDateDriver,
  ]);

  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) =>
        index + 1 + (pageNoRider - 1) * limitRider,
    },
    {
      // title: "Driver's Name",
      title:
        activeKey == 1 ? (
          <div>
            Driver's Name <br /> (Given By)
          </div>
        ) : (
          <div>
            Driver's Name <br /> (Given To)
          </div>
        ),
      dataIndex: ["driver_id", "name"],

      key: "DriverName",
    },
    {
      title:
        activeKey == 1 ? (
          <div>
            Rider's Name <br /> (Given To)
          </div>
        ) : (
          <div>
            Rider's Name <br /> (Given By)
          </div>
        ),
      dataIndex: ["rider_id", "name"],
      key: "RiderName",
    },
    {
      title: "Feedback",
      key: "feedback",
      render(data, record) {
        return (
          <>
            {data.ratingTags.length > 0 &&
              data.ratingTags.map((tags) => {
                return <p>{`${tags.tag ? tags.tag : "-"}`}</p>;
              })}
            {data.ratingTags == 0 && <p>-</p>}
          </>
        );
      },
    },
    {
      title: "City",
      key: "city",
      render(data, record) {
        return (
          <>
            <span>
              {data?.pickup_city_id?.name ? data?.pickup_city_id?.name : "-"}
            </span>
          </>
        );
      },
    },
    {
      title: "Ratings",
      key: "rating",
      render(data, record) {
        return (
          <>
            <Rate value={data.rating} allowHalf disabled />
            <span style={{ color: "#ccc" }}>{`(${data.rating})`}</span>
          </>
        );
      },
    },
  ];

  const ridersColumn = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) =>
        index + 1 + (pageNoDriver - 1) * limitDriver,
    },
    {
      title:
        activeKey == 1 ? (
          <div>
            Rider's Name <br /> (Given To)
          </div>
        ) : (
          <div>
            Rider's Name <br /> (Given By)
          </div>
        ),
      dataIndex: ["rider_id", "name"],
      key: "RiderName",
    },
    {
      // title: "Driver's Name",
      title:
        activeKey == 1 ? (
          <div>
            Driver's Name <br /> (Given By)
          </div>
        ) : (
          <div>
            Driver's Name <br /> (Given To)
          </div>
        ),
      dataIndex: ["driver_id", "name"],

      key: "DriverName",
    },
    {
      title: "Feedback",
      key: "",
      render(data, record) {
        return (
          <>
            {data.ratingTags.length > 0 &&
              data.ratingTags.map((tags) => {
                return <p>{`${tags.tag ? tags.tag : "-"}`}</p>;
              })}
            {data.ratingTags == 0 && <p>-</p>}
          </>
        );
      },
    },
    {
      title: "City",
      key: "city",
      render(data, record) {
        return (
          <>
            <span>
              {data?.pickup_city_id?.name ? data?.pickup_city_id?.name : "-"}
            </span>
          </>
        );
      },
    },
    {
      title: "Ratings",
      key: "rating",
      render(data, record) {
        return (
          <>
            <Rate value={data.rating} allowHalf disabled />
            <span style={{ color: "#ccc" }}>{`(${data.rating})`}</span>
          </>
        );
      },
    },
  ];

  const handleTabClick = async (key) => {
    setActiveKey(key);
    // setSearchBy("");
    // setRatingFilter("");
    // setSelectedCity("");
    // setFromDate(moment());
    // setToDate(moment());
    // setPageNo(1);
    // setLimit(50);
  };

  const tabItems = [
    !isLoading && {
      key: "1",
      label: "Ratings Of Riders",
      children: (
        <>
          <Card style={{ marginBottom: 10 }} className="shadowCardBox">
            <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
              <Col xxl={5} xl={4} lg={4} md={5} sm={8} xs={24}>
                <p className="total">Total {riderRating?.total_counts}</p>
              </Col>
              <Col xxl={4} xl={4} lg={4} md={6} sm={16} xs={24}>
                <Input
                  placeholder="Search Rider By Name"
                  style={{
                    width: "100%",
                    // float: "right",
                    // marginLeft: "20px",
                  }}
                  prefix={<SearchOutlined />}
                  onChange={(e) => handleSearchRider(e.target.value)}
                  value={searchByRider}
                />
              </Col>
              <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={24}>
                <div>
                  <Select
                    placeholder="Choose city"
                    style={{
                      width: "100%",
                      // marginLeft: "10px",
                      // float: "right",
                      // lineHeight: 3,
                      // overflowY: "scroll",
                      // // maxHeight: "8vh",
                      // height: "50px",
                    }}
                    showSearch
                    filterOption={false}
                    // onSearch={(e) => handleSearch(e)}
                    allowClear
                    onClear={(e) => {
                      handleSelectedCityRider("");
                    }}
                    onSelect={(value) => handleSelectedCityRider(value)}
                    // onChange={handleSelectedCity}
                  >
                    {cityList.length > 0 &&
                      cityList?.map((data) => {
                        return (
                          <Select.Option
                            value={data._id}
                            key={data._id}
                            className="selectOptions"
                          >
                            {data.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>
              </Col>
              <Col xxl={5} xl={7} lg={7} md={5} sm={12} xs={24}>
                <RangePicker
                  presets={rangePresets}
                  onChange={onRangeChangeRider}
                  defaultValue={[fromDateRider, toDateRider]}
                  style={{
                    borderRadius: "0",
                    // marginLeft: "10px",
                    // width: "10vw",
                    width: "100%",
                  }}
                  allowClear={true}
                  format={"DD/MM/YYYY"}
                  disabledDate={(current) => current.isAfter(moment())}
                  // style={{display:"flex"}}
                />
              </Col>

              <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={24}>
                <div>
                  <Select
                    placeholder="Filter By Ratings"
                    style={{ width: "100%" }}
                    onSelect={(value) => handleSelectRatingRider(value)}
                    // value={ratingFilterRider}
                    allowClear
                    onClear={() => handleSelectRatingRider("")}
                  >
                    <Select.Option value="5" key="5">
                      <Rate value={5} disabled />
                    </Select.Option>
                    <Select.Option value="4" key="4">
                      <Rate value={4} disabled />
                    </Select.Option>
                    <Select.Option value="3" key="3">
                      <Rate value={3} disabled />
                    </Select.Option>
                    <Select.Option value="2" key="2">
                      <Rate value={2} disabled />
                    </Select.Option>
                    <Select.Option value="1" key="1">
                      <Rate value={1} disabled />
                    </Select.Option>
                    {/* <Select.Option value="0">
                          <Rate value={0} disabled />
                        </Select.Option> */}
                  </Select>
                </div>
              </Col>
            </Row>
          </Card>
          <Card className="shadowCardBox">
            {/* <TopPagination> */}
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              dataSource={riderRating.list}
              columns={columns}
              searchable
              scroll={{ x: "45vh" }}
              pagination={{
                showSizeChanger: true,
                pageSize: limitRider,
                total: riderRating?.total_counts,
                onShowSizeChange: onShowSizeChangeRider,
                onChange: onPageChangeRider,
                pageSizeOptions: ["50", "100", "500"],
              }}
            />
            {/* </TopPagination> */}
          </Card>
        </>
      ),
    },
    !isLoadingDriver && {
      key: "2",
      label: "Ratings Of Drivers",
      children: (
        <>
          {" "}
          <Card style={{ marginBottom: 10 }} className="shadowCardBox">
            <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
              <Col xxl={5} xl={4} lg={4} md={5} sm={8} xs={24}>
                <p className="total">Total {driverRating?.total_counts}</p>
              </Col>
              <Col xxl={4} xl={4} lg={4} md={6} sm={16} xs={24}>
                <Input
                  placeholder="Search Driver By Name"
                  style={{
                    width: "100%",
                  }}
                  prefix={<SearchOutlined />}
                  onChange={(event) => handleSearchDriver(event.target.value)}
                  value={searchByDriver}
                />
              </Col>
              <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={24}>
                <div>
                  <Select
                    placeholder="Choose city"
                    style={{
                      width: "100%",
                      // marginLeft: "10px",
                      // float: "right",
                      // lineHeight: 3,
                      // overflowY: "scroll",
                      // // maxHeight: "8vh",
                      // height: "50px",
                    }}
                    showSearch
                    filterOption={false}
                    // onSearch={(e) => handleSearch(e)}
                    allowClear
                    onClear={(e) => {
                      handleSelectedCityDriver("");
                    }}
                    onSelect={(value) => handleSelectedCityDriver(value)}
                    // onChange={handleSelectedCity}
                  >
                    {cityList.length > 0 &&
                      cityList?.map((data) => {
                        return (
                          <Select.Option
                            value={data._id}
                            key={data._id}
                            className="selectOptions"
                          >
                            {data.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>
              </Col>
              <Col xxl={5} xl={7} lg={7} md={5} sm={12} xs={24}>
                <RangePicker
                  presets={rangePresets}
                  onChange={onRangeChangeDriver}
                  defaultValue={[fromDateDriver, toDateDriver]}
                  style={{
                    borderRadius: "0",
                    // marginLeft: "10px",
                    // width: "10vw",
                    width: "100%",
                  }}
                  format={"DD/MM/YYYY"}
                  disabledDate={(current) => current.isAfter(moment())}
                  allowClear={true}
                />
              </Col>
              <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={24}>
                <div>
                  <Select
                    placeholder="Filter By Ratings"
                    style={{ width: "100%" }}
                    onSelect={(value) => handleSelectRatingDriver(value)}
                    allowClear
                    onClear={() => handleSelectRatingDriver("")}
                  >
                    <Select.Option value="5" key="5">
                      <Rate value={5} disabled />
                    </Select.Option>
                    <Select.Option value="4" key="4">
                      <Rate value={4} disabled />
                    </Select.Option>
                    <Select.Option value="3" key="3">
                      <Rate value={3} disabled />
                    </Select.Option>
                    <Select.Option value="2" key="2">
                      <Rate value={2} disabled />
                    </Select.Option>
                    <Select.Option value="1" key="1">
                      <Rate value={1} disabled />
                    </Select.Option>
                    {/* <Select.Option value="0">
                <Rate value={0} disabled />
              </Select.Option> */}
                  </Select>
                </div>
              </Col>
            </Row>
          </Card>
          <Card className="shadowCardBox">
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              dataSource={driverRating.list}
              columns={ridersColumn}
              searchable
              scroll={{ x: "45vh" }}
              pagination={{
                showSizeChanger: true,
                pageSize: limitDriver,
                total: driverRating?.total_counts,
                onShowSizeChange: onShowSizeChangeDriver,
                onChange: onPageChangeDriver,
                pageSizeOptions: ["50", "100", "500"],
              }}
            />
          </Card>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="common_layout content-container ">
        <h1 className="heading">{t("tripRating")}</h1>
        <BreadCrumb />

        {/* <p>
          Here, You have the ability to see the Ratings given by riders/drivers
          about their fellow drivers/riders, which relates to their behavior
          during a particular ride.
        </p> */}

        <Divider />
        {isLoading && <Loader />}
        {isLoadingDriver && <Loader />}

        {/* {isLoading & isLoadingDriver && (
          <Loader />
        )} */}
        <Tabs
          type="card"
          activeKey={activeKey}
          onTabClick={handleTabClick}
          items={tabItems}
        ></Tabs>
      </div>
    </>
  );
};

export default Triprating;
