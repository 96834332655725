import {
  CheckOutlined,
  CloseOutlined,
  SearchOutlined,
  EditOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
} from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import React, { useEffect, useState, useTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  approvePartnerRequest,
  getAllPartnersList,
  rejectPartnerRequest,
} from "../../redux/BeOurPartners/actionCreatore";
import { getCityData } from "../../redux/Masters/City/actionCreatore";
import { getItem } from "../../utility/localStorageControl";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import Loader from "../loader/loader";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
const BeOurPartner = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("pending");
  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState(activeKey);
  const [pageSize, setPageSize] = useState(50);
  const [pageNo, setPageNo] = useState(1);
  const [searchBy, setSearchBy] = useState("");
  const [actionLoader, setActionLoader] = useState(false);
  const user = getItem("user_details");

  const [selectedCity, setSelectedCity] = useState(
    user.role != "super_admin" ? user?.city_id?._id : ""
  );

  const navigate = useNavigate();
  useEffect(() => {
    // reteriveCityList();
    // reterivePartnersList();
    window.scrollTo(0, 0);
  }, []);

  const { data, isLoading: isLoading } = useQuery(
    "partnerist",
    () => {
      return reterivePartnersList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataCity, isLoading: isLoadingCity } = useQuery(
    "cityList",
    () => {
      return reteriveCityList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const reteriveCityList = async () => {
    setLoading(true);
    const resp = await dispatch(getCityData(""));
    if (resp) {
      setLoading(false);
    }
  };
  let cityList = useSelector((state) => state.city.cityData);
  cityList = cityList?.sort((a, b) => a.name?.localeCompare(b.name));

  const reterivePartnersList = async () => {
    setLoading(true);
    const resp = await dispatch(
      getAllPartnersList(pageNo, pageSize, status, searchBy, selectedCity)
    );
    if (resp) {
      setLoading(false);
    }
  };

  const partnersData = useSelector((state) => state.partner.partnersListData);
  const handleSearch = async (value) => {
    setSearch(value);
    await dispatch(getCityData(value));
  };

  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    await dispatch(
      getAllPartnersList(page, pageSize, status, searchBy, selectedCity)
    );
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    setActionLoader(true);
    const resp = await dispatch(
      getAllPartnersList(current, pageSizes, status, searchBy, selectedCity)
    );
    if (resp) {
      setActionLoader(false);
    }
  };

  const handleActionModal = async (id, payload) => {
    if (payload.type == "Approve") {
      Modal.confirm({
        title: "Are you sure, Approve this request",
        okText: "Yes",
        okType: "primary",
        onOk: () => {
          handleApprove(id);
        },
      });
    }
    if (payload.type == "Reject") {
      Modal.confirm({
        title: "Are you sure, Reject this request",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          // deleteCityFinal(value);
          handleReject(id);
        },
      });
    }
  };

  const handleApprove = async (id) => {
    setActionLoader(true);
    const resp = await dispatch(approvePartnerRequest(id, { type: "Approve" }));
    if (resp) {
      const data = await dispatch(getAllPartnersList(1, 10, status, "", ""));
      if (data) {
        message.success("Request has been approved successfully");
      }
    }
    setActionLoader(false);
    getAllPartnersList(pageNo, pageSize, status, searchBy, selectedCity)
    
  };
  const handleReject = async (id) => {
    setActionLoader(true);
    const resp = await dispatch(rejectPartnerRequest(id, { type: "Reject" }));

    message.success("Request has been rejected successfully");
    if (resp) {
      const data = await dispatch(
        getAllPartnersList(pageNo, pageSize, status, searchBy, selectedCity)
      );
      if (data) {
      }
    }
    setActionLoader(false);
  };
  const { t } = useTranslation();
  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("phoneNo"),
      render: (data) => {
        return <span>{data?.number}</span>;
      },
    },
    {
      title: "Interested City",
      render: (data) => {
        return <span>{data?.interestedForCity}</span>;
        // return <span>{data?.city_id?.name}</span>;
      },
    },
    {
      title: t("emailAddress"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },

    {
      title: "Rider",
      children: [
        {
          title: "Name",
          dataIndex: ["createdBy", "name"],
          key: "riderName",
        },
        {
          title: "Phone Number",
          dataIndex: ["createdBy", "number"],
          key: "riderNumber",
        },
      ],
    },

    {
      title: t("action"),
      key: "rejected",

      render: (record, target) => {
        return (
          <div>
            <Button
              // onClick={() => {
              //   navigate(`manageDriver/driverDetails/${record._id}`);
              // }}
              onClick={() => handleActionModal(record._id, { type: "Approve" })}
              type="primary"
              style={{ marginRight: 4 }}
            >
              <CheckOutlined style={{ marginRight: 5 }} />
              <strong>Approve</strong>
            </Button>
          </div>
        );
      },
    },
    {
      title: t("action"),
      key: "pending",
      align: "center",
      render: (record, target) => {
        return (
          <div style={{ display: "flex" }}>
            <Button
              type="primary"
              style={{ marginRight: 4 }}
              onClick={() => navigate(`detail/${record._id}`)}
            >
              {/* <EditOutlined /> */}
              <MenuUnfoldOutlined />
              Details
            </Button>

            <Button
              onClick={() => handleActionModal(record._id, { type: "Approve" })}
              type="primary"
              style={{ marginRight: 4, background: "green" }}
            >
              <CheckOutlined style={{ marginRight: 5 }} />
              <strong>Approve</strong>
            </Button>

            <Button
              onClick={() => handleActionModal(record._id, { type: "Reject" })}
              type="primary"
              danger
            >
              <CloseOutlined style={{ marginRight: 5 }} />
              <strong>Reject</strong>
            </Button>
          </div>
        );
      },
    },
  ];

  const handleTabClick = async (key) => {
    setActiveKey(key);
    setStatus(key);
    setSelectedCity("");

    setActionLoader(true);
    const resp = await dispatch(
      getAllPartnersList(pageNo, pageSize, key, "", "")
    );
    if (resp) {
      setActionLoader(false);
    }
  };

  const handleFilter = async (event) => {
    console.log(event.target.value);
    setSearchBy(event.target.value);
    setPageNo(1);
    await dispatch(
      getAllPartnersList("", "", status, event.target.value, selectedCity)
    );
  };

  const handleCitySelect = async (value) => {
    setSelectedCity(value);
    setPageNo(1);

    await dispatch(getAllPartnersList("", "", status, searchBy, value));
  };

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">{t("beOurAffiliatePartner")}</h1>
            <BreadCrumb />
          </Col>
          {/* <Col lg={12} md={12} sm={12} xs={12}>
              <Button
                style={{ float: "right" }}
                className="ADD"
                key="1"
                size="large"
                type="primary"
                // onClick={showModal}
              >
                <PlusOutlined /> Add Driver
              </Button>
            </Col> */}
        </Row>
        <Divider />
        <Tabs type="card" activeKey={activeKey} onTabClick={handleTabClick}>
          {isLoading & isLoadingCity ? (
            <Loader />
          ) : (
            <>
              <TabPane tab="Pending" key="pending">
                <Card style={{ marginBottom: 20 }}>
                  <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                    <Col
                      xxl={12}
                      xl={12}
                      lg={12}
                      md={8}
                      sm={12}
                      xs={24}
                      className="total"
                    >
                      Total {partnersData?.total_counts}
                    </Col>

                    <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                      <Input
                        placeholder="Search By Name, Email Or Phone"
                        // enterButton="Search"
                        // size="large"
                        prefix={<SearchOutlined />}
                        style={{ width: "100%" }}
                        onChange={(value) => handleFilter(value)}
                      />
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                      <Select
                        placeholder="Choose city"
                        style={{ width: "100%" }}
                        showSearch
                        // filterOption={(input, option) =>
                        //   option.props.children
                        //     .toLowerCase()
                        //     .indexOf(input.toLowerCase()) >= 0
                        // }
                        onSearch={(e) => handleSearch(e)}
                        allowClear
                        onClear={(e) => {
                          handleSearch("");
                          handleCitySelect("");
                        }}
                        onSelect={(e) => handleCitySelect(e)}
                        value={selectedCity != "" ? selectedCity : null}
                      >
                        {cityList &&
                          cityList.map((data) => {
                            return (
                              <Select.Option value={data.name} key={data._id}>
                                {data.name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Col>
                  </Row>
                </Card>
                <Card>
                  {!actionLoader && (
                    <Table
                      rowClassName={(record, index) =>
                        record?.isRead ? "read" : "unread"
                      }
                      dataSource={partnersData?.list}
                      columns={columns.filter((col) => col.key != "rejected")}
                      searchable
                      scroll={{ x: "45vh" }}
                      loading={actionLoader}
                      pagination={
                        // partnersData?.total_counts > pageSize
                        //   ? {
                        //       showSizeChanger: true,
                        //       pageSize: pageSize,
                        //       total: partnersData?.total_counts,
                        //       onShowSizeChange: onShowSizeChange,
                        //       onChange: onPageChange,
                        //       pageSizeOptions: ["10", "30", "50"],
                        //     }
                        //   : false
                        {
                          showSizeChanger: true,
                          pageSize: pageSize,
                          total: partnersData?.total_counts,
                          onShowSizeChange: onShowSizeChange,
                          onChange: onPageChange,
                          pageSizeOptions: ["50", "100", "500"],
                        }
                      }
                    />
                  )}
                </Card>
              </TabPane>
              <TabPane tab="Approved" key="Approve">
                <Card style={{ marginBottom: 20 }}>
                  <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                    <Col
                      xxl={12}
                      xl={12}
                      lg={12}
                      md={8}
                      sm={12}
                      xs={24}
                      className="total"
                    >
                      Total {partnersData?.total_counts}
                    </Col>

                    <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                      <Input
                        placeholder="Search By Name, Email Or Phone"
                        // enterButton="Search"
                        // size="large"
                        style={{ width: "100%" }}
                        prefix={<SearchOutlined />}
                        onChange={(value) => handleFilter(value)}
                      />
                    </Col>

                    <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                      <Select
                        placeholder="Choose city"
                        style={{ width: "100%" }}
                        showSearch
                        // filterOption={(input, option) =>
                        //   option.props.children
                        //     .toLowerCase()
                        //     .indexOf(input.toLowerCase()) >= 0
                        // }
                        filterOption={false}
                        onSearch={(e) => handleSearch(e)}
                        allowClear
                        onClear={(e) => {
                          handleSearch("");
                          handleCitySelect("");
                        }}
                        onSelect={(e) => handleCitySelect(e)}
                        value={selectedCity != "" ? selectedCity : null}
                      >
                        {cityList &&
                          cityList.map((data) => {
                            return (
                              <Select.Option value={data.name} key={data._id}>
                                {data.name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Col>
                  </Row>
                </Card>
                <Card>
                  <Table
                    // dataSource={partnersList?.list}
                    dataSource={partnersData?.list}
                    columns={columns.filter((col) => col.title != t("action"))}
                    searchable
                    scroll={{ x: "45vh" }}
                    pagination={
                      // partnersData?.total_counts > pageSize
                      //   ? {
                      //       showSizeChanger: true,
                      //       pageSize: pageSize,
                      //       total: partnersData?.total_counts,
                      //       onShowSizeChange: onShowSizeChange,
                      //       onChange: onPageChange,
                      //       pageSizeOptions: ["10", "30", "50"],
                      //     }
                      //   : false
                      {
                        showSizeChanger: true,
                        pageSize: pageSize,
                        total: partnersData?.total_counts,
                        onShowSizeChange: onShowSizeChange,
                        onChange: onPageChange,
                        pageSizeOptions: ["50", "100", "500"],
                        current: pageNo,
                      }
                    }
                  />
                </Card>
              </TabPane>
              <TabPane tab="Rejected" key="Reject">
                <Card style={{ marginBottom: 20 }}>
                  <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                    <Col
                      xxl={12}
                      xl={12}
                      lg={12}
                      md={8}
                      sm={12}
                      xs={24}
                      className="total"
                    >
                      Total {partnersData?.total_counts}
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                      <Input
                        placeholder="Search By Name, Email Or Phone"
                        // enterButton="Search"
                        // size="large"
                        style={{ width: "100%" }}
                        prefix={<SearchOutlined />}
                        onChange={(value) => handleFilter(value)}
                      />
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                      <Select
                        placeholder="Choose city"
                        style={{ width: "100%" }}
                        showSearch
                        filterOption={false}
                        allowClear
                        onSearch={(e) => handleSearch(e)}
                        onClear={(e) => {
                          handleSearch("");
                          handleCitySelect("");
                        }}
                        onSelect={(e) => handleCitySelect(e)}
                        value={selectedCity != "" ? selectedCity : null}
                      >
                        {cityList &&
                          cityList.map((data) => {
                            return (
                              <Select.Option value={data._id} key={data._id}>
                                {data.name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Col>
                  </Row>
                </Card>
                <Card>
                  {!actionLoader && (
                    <Table
                      // dataSource={partnersList?.list}
                      dataSource={partnersData?.list}
                      columns={columns.filter((col) => col.key != "pending")}
                      searchable
                      scroll={{ x: "45vh" }}
                      loading={actionLoader}
                      // loading={{
                      //   indicator: (
                      //     <div>
                      //       <Spin />
                      //     </div>
                      //   ),
                      //   spinning: actionLoader,
                      // }}
                      pagination={{
                        showSizeChanger: true,
                        pageSize: pageSize,
                        total: partnersData?.total_counts,
                        onShowSizeChange: onShowSizeChange,
                        onChange: onPageChange,
                        pageSizeOptions: ["50", "100", "500"],
                      }}
                    />
                  )}
                </Card>
              </TabPane>
            </>
          )}
        </Tabs>
      </div>
      {/* {loading && <Loader />} */}
    </>
  );
};

export default BeOurPartner;
