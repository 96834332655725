import { EnvironmentOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Card,
  Col,
  Collapse,
  Divider,
  Image,
  Input,
  Modal,
  Row,
  Table,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  AUTOCLICKER,
  BOOKFORHOME,
  IMAGE_URL_PREFIX,
  OFFDUTY,
  ONDUTY,
} from "../../../config/dataService/dataService";
import {
  getDriverById,
  getDriverDocuments,
  getDriverIdCardById,
} from "../../../redux/drivers/actionCreatore";
import idCard from "../../../static/img/driverDetail/id1.gif";
import mapGif from "../../../static/img/driverDetail/map1.gif";
import walletGif from "../../../static/img/driverDetail/wallet1.gif";
import image from "../../../static/img/favicon.png";
import { WordFormate } from "../../../utility/commonFunction";
import { getItem } from "../../../utility/localStorageControl";
import Loader from "../../loader/loader";
import { Main } from "../../styled";
import DriverRatings from "./driverDetails/DriverRatingsTable";
import DriverRides from "./driverDetails/DriverRides";
import NotificationList from "./driverDetails/notificationsList";
import RideRequestTable from "./driverDetails/RideRequestTable";

const { Panel } = Collapse;
// import { Document, Page } from 'react-pdf';
function DriverDetails(props) {
  const { driverId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState([]);
  const navigate = useNavigate();
  const user = getItem("user_details");
  // console.log(driverId);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [phoneModal, setPhoneModal] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    reteriveDriverData();
    reteriveDocuments();
    window.scrollTo(0, 0);
    // reteriveIdcard();
  }, []);

  const reteriveDriverData = async () => {
    // setLoading(true)
    const resp = await dispatch(getDriverById(driverId));
    if (resp) {
      setLoading((prev) => [...prev, 1]);
    }
  };
  const driverData = useSelector((state) => state.driver.driverById);
  // console.log("driverData", driverData);

  const status =
    driverData?.duty_status == ONDUTY
      ? ["On Duty", "lightgreen"]
      : driverData?.duty_status == OFFDUTY
      ? ["Off Duty", "#fc5b42"]
      : driverData?.duty_status == AUTOCLICKER
      ? ["Auto Clicker", "skyblue"]
      : driverData?.duty_status == BOOKFORHOME
      ? ["Book For Home", "orange"]
      : [""];
  const reteriveDocuments = async () => {
    // setLoading(true)
    const response = await dispatch(getDriverDocuments(driverId));
    if (response) {
      setLoading((prev) => [...prev, 2]);
    }
  };
  const reteriveIdcard = async () => {
    // setLoading(true)
    const response = await dispatch(getDriverIdCardById(driverId));
    if (response) {
    }
  };
  const idCardData = useSelector((state) => state.driver.driveridCard);
  // console.log("idCardData id", idCardData);

  const documentsData = useSelector((state) => state.driver.driverDocuments);

  const documentsColumns = [
    {
      title: "Sr.No ",
      render: (data, record, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "Document Name ",
      render: (data) => {
        return (
          <>
            <span>{data.type === "adhaar_card" && "Aadhar Card"}</span>
            <span>
              {data.type === "police_verification_card" &&
                "Police Verification Doc"}
            </span>
            <span>{data.type === "insurance" && "Insurance"}</span>
            <span>{data.type === "driving_license" && "Driving Licence"}</span>
            <span>{data.type === "rc_book" && "RC Book"}</span>
            <span>{data.type === "cheque" && "Cheque"}</span>
          </>
        );
      },
    },
    {
      title: "Document",
      render: (data) => (
        <span>
          {data.url ? (
            data.url.split(".pdf")?.length == 1 ? (
              <Image
                src={IMAGE_URL_PREFIX + data.url}
                width={60}
                height={50}
                style={{ border: "1px solid black" }}
              />
            ) : (
              <a href={IMAGE_URL_PREFIX + data.url} target="_blank">
                <div>
                  <iframe
                    src={IMAGE_URL_PREFIX + data.url}
                    width={60}
                    height={50}
                  />
                </div>
              </a>
            )
          ) : (
            <span>Document not available</span>
          )}
          {/* <iframe
            // src="https://docs.google.com/gview?url=https://path.com/to/your/pdf.pdf&embedded=true"
            src={IMAGE_URL_PREFIX + data.url}
            style={{ width: 60, height: 50, overflow: "hidden" }}
            frameborder="0"
          ></iframe> */}
        </span>
      ),
    },
  ];
  const userDetail = getItem("user_details");
  return (
    <div>
      <Main>
        {loading.length == 0 && <Loader />}
        {loading.includes(1) & loading.includes(2) && (
          <>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              <Card
                style={{
                  // background: "rgb(133,209,143)",
                  background:
                    "linear-gradient(90deg, rgba(133,209,143,0.8127626050420168) 22%, rgba(83,218,198,0.6615021008403361) 56%, rgba(122,194,209,1) 83%)",
                }}
              >
                <p style={{ fontSize: "24px", fontWeight: "700" }}>Profile</p>

                <Avatar
                  size={150}
                  icon={
                    driverData && driverData.profile_image ? (
                      <img
                        src={IMAGE_URL_PREFIX + driverData.profile_image}
                        style={{ fontSize: "24px" }}
                        alt={"Driver image"}
                        // crossOrigin="anonymous"
                      />
                    ) : (
                      <UserOutlined />
                    )
                  }
                />

                {/* <span className="toWallet">
                  <NavLink
                    to={`/${userDetail.role}/manageDriver/manageDriver/driverWallet`}
                  >
                    <WalletOutlined />
                  </NavLink>
                </span> */}
                <span
                  className="status"
                  style={{
                    fontSize: "24px",
                    // border: "2px solid",
                    color: "white",
                    background: [status[1]],
                  }}
                >
                  {" "}
                  {status[0]}
                </span>
                <span
                  className="total toWallet"
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    cursor: "pointer",
                  }}
                >
                  {/* <IdcardOutlined
                    onClick={showModal}
                    style={{ marginRight: "6px", fontSize: "40px" }}
                  /> */}
                  <img
                    src={idCard}
                    width={40}
                    onClick={showModal}
                    alt="Idcard"
                  />
                  <img
                    src={mapGif}
                    width={55}
                    style={{ marginInline: "20px" }}
                    onClick={() =>
                      navigate(
                        `/${user.role}/liveDrivers/${driverData?._id}/map`
                      )
                    }
                    alt={"Map"}
                  />
                  <img
                    src={walletGif}
                    width={40}
                    onClick={() =>
                      navigate(
                        `/${user.role}/manageDriver/driver/driverWallet/${driverData?._id}`
                      )
                    }
                    alt={"wallet"}
                  />
                </span>
              </Card>
            </div>
            <Collapse defaultActiveKey={"1"}>
              <Panel
                key={"1"}
                header="Driver Details"
                style={{
                  background: "#00000047",
                  // borderRadius: 10,
                  fontWeight: 500,
                }}
              >
                <div style={{ justifyContent: "center", marginBottom: "20px" }}>
                  <Card>
                    <Row gutter={20} style={{ marginBottom: "30px" }}>
                      <Col
                        xxl={8}
                        lg={8}
                        md={8}
                        sm={24}
                        xs={24}
                        className="custom-col"
                      >
                        <div>
                          <label className="userlabel"> Name</label>
                          <Input
                            defaultValue={driverData && driverData.name}
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">Email </label>
                          <Input
                            defaultValue={driverData && driverData.email}
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">{t("phoneNo")}</label>
                          <div onClick={() => setPhoneModal(true)}>
                            <Input
                              defaultValue={
                                driverData && driverData.number
                                  ? driverData.number
                                  : ""
                              }
                              disabled
                              style={{
                                background: "white",
                                cursor: "default",
                                fontWeight: "bold",
                              }}
                            />
                          </div>
                          {/* <br/>
                     <div style={{border:"2px solid grey", height:"44px", padding:"10px"}} disabled >
                  
                      <a
                        href={`tel:${
                          driverData && driverData.number
                          ? driverData.number
                          : ""
                        }`}
                        >
                        {driverData && driverData.number
                          ? driverData.number
                          : ""}
                      </a>
                          </div> */}
                        </div>
                      </Col>

                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">Google Id</label>
                          <Input
                            defaultValue={
                              driverData && driverData?.google_id
                                ? driverData?.google_id
                                : ""
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">Facebook Id</label>
                          <Input
                            defaultValue={
                              driverData && driverData?.facebook_id
                                ? driverData?.facebook_id
                                : ""
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">Registered City </label>
                          <Input
                            defaultValue={
                              driverData &&
                              driverData?.driverRegisteredCity?.name
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">
                            Current Location City{" "}
                          </label>
                          <Input
                            defaultValue={
                              driverData && driverData?.city_id?.name
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">Vehicle No.</label>
                          <Input
                            defaultValue={
                              driverData && driverData.vehicle_number
                                ? driverData.vehicle_number
                                : ""
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">Vehicle Type </label>
                          <Input
                            defaultValue={
                              driverData && driverData?.vehicle_type_id?.name
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      {/* work in progress */}
                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">
                            Vehicle Model Name{" "}
                          </label>
                          <Input
                            defaultValue={
                              driverData && driverData?.vehicle_model_id?.name
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">
                            Vehicle Insurance Valid Till
                          </label>

                          <Input
                            defaultValue={
                              driverData && driverData.insurance_valid_till
                                ? new Date(
                                    driverData.insurance_valid_till
                                  ).toLocaleDateString()
                                : ""
                            }
                            placeholder="DD/MM/YYYY"
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>

                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">Joined Date</label>

                          <Input
                            defaultValue={
                              driverData && driverData.created_at
                                ? new Date(
                                    driverData.created_at
                                  ).toLocaleDateString()
                                : ""
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">Captain Name</label>

                          <Input
                            defaultValue={
                              driverData && driverData?.captainDetails?.name
                                ? driverData?.captainDetails?.name
                                : "-"
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>

                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">
                            Home Location Latitude
                          </label>

                          <Input
                            defaultValue={
                              driverData &&
                              driverData?.homeAddress?.coordinates?.[1]
                                ? driverData?.homeAddress?.coordinates?.[1]
                                : "-"
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>

                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">
                            Home Location Longitude
                          </label>

                          <Input
                            defaultValue={
                              driverData &&
                              driverData?.homeAddress?.coordinates?.[0]
                                ? driverData?.homeAddress?.coordinates?.[0]
                                : "-"
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">Home Location</label>

                          <Input
                            defaultValue={
                              driverData &&
                              driverData?.homeAddress?.location_name
                                ? driverData?.homeAddress?.location_name
                                : "-"
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>

                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">Current Latitude</label>

                          <Input
                            defaultValue={
                              driverData &&
                              driverData?.location?.coordinates?.[1]
                                ? driverData?.location?.coordinates?.[1]
                                : "-"
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>

                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">Current Longitude</label>

                          <Input
                            defaultValue={
                              driverData &&
                              driverData?.location?.coordinates?.[0]
                                ? driverData?.location?.coordinates?.[0]
                                : "-"
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">Last Location</label>

                          <Input
                            defaultValue={
                              driverData && driverData?.location?.location_name
                                ? driverData?.location?.location_name
                                : "-"
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">Last Login</label>

                          <Input
                            defaultValue={
                              driverData && driverData?.login_time
                                ? new Date(driverData && driverData.login_time)
                                : "-"
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">Login Type</label>

                          <Input
                            defaultValue={
                              driverData && driverData?.login_type
                                ? driverData &&
                                  WordFormate(driverData.login_type)
                                : "-"
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">Device Id</label>

                          <Input
                            defaultValue={
                              driverData && driverData?.device_id
                                ? driverData?.device_id
                                : "-"
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">Device Type</label>

                          <Input
                            defaultValue={
                              driverData && driverData?.device_type
                                ? driverData?.device_type
                                : "-"
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">Device Model</label>

                          <Input
                            defaultValue={
                              driverData && driverData?.modelName
                                ? driverData?.modelName
                                : "-"
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">
                            Bank Account Number
                          </label>
                          <Input
                            defaultValue={
                              driverData &&
                              driverData?.driverBankDetails?.account_number
                                ? driverData?.driverBankDetails?.account_number
                                : "-"
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={8} lg={8} md={8} xs={24} className="custom-col">
                        <div>
                          <label className="userlabel">Bank IFSC Code</label>

                          <Input
                            defaultValue={
                              driverData &&
                              driverData?.driverBankDetails?.ifsc_code
                                ? driverData?.driverBankDetails?.ifsc_code
                                : "-"
                            }
                            disabled
                            style={{
                              background: "white",
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                      <Divider />
                      <Col
                        xxl={24}
                        lg={24}
                        md={24}
                        sm={24}
                        xs={24}
                        className="custom-col"
                      >
                        <Table
                          scroll={{ x: 10 }}
                          rowClassName={(record, index) =>
                            index % 2 === 0
                              ? "table-row-light"
                              : "table-row-dark"
                          }
                          className="driverDocument"
                          columns={documentsColumns}
                          dataSource={[
                            ...documentsData,
                            {
                              type: "cheque",
                              url: driverData?.driverBankDetails?.cheque,
                            },
                          ]}
                          pagination={false}
                        />
                      </Col>
                    </Row>
                  </Card>
                </div>
              </Panel>
            </Collapse>
            <br />
          </>
        )}

        <DriverRides />
        <br />
        <DriverRatings loading={setLoading} />
        <br />
        <RideRequestTable loading={setLoading} />
        <br />
        <NotificationList id={driverId} role={"driver"} />
        <br />
        {/* <DriverLogs /> */}
        <Modal
          title=""
          open={isModalOpen}
          closable={true}
          // maskClosable={false}
          footer={null}
          onCancel={handleCancel}

          // closeIcon={true}
        >
          {/* <span
            // className="ck-content"
            dangerouslySetInnerHTML={{
              __html: `${idCardData}`,
            }}
          /> */}
          {/* <div
            style={{
              float: "right",
              display: "flex",
              justifyContent: "flex-start",
              cursor: "pointer",
            }}
            onClick={() => onClose()}
          >
            <img src="https://api.iconify.design/material-symbols/cancel-outline.svg?width=30&height=30" />
          </div> */}
          <div style={{ padding: "25px" }}>
            <div style={{ border: "0.5px solid grey" }}>
              <Row
                style={{
                  lineHeight: "50px",
                  background: "#FFEE4B",
                  width: "100%",
                }}
              >
                <Col span={12}>
                  <img
                    src={image}
                    style={{ width: "50px", marginLeft: "30px" }}
                  />
                </Col>
                <Col span={12}>
                  <div
                    style={{
                      right: 0,
                      fontWeight: "bold",
                      float: "right",
                      paddingRight: "30px",
                      fontSize: "20px",
                    }}
                  >
                    <span>ID CARD</span>
                  </div>
                </Col>
              </Row>
              <Divider style={{ background: "#FFEE4B", height: "3px" }} />
              <Row>
                <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    size={100}
                    style={{ marginLeft: "20px" }}
                    icon={
                      driverData && driverData.profile_image ? (
                        <img
                          src={IMAGE_URL_PREFIX + driverData.profile_image}
                          alt={"driver image"}
                          // crossOrigin="anonymous"
                        />
                      ) : (
                        <UserOutlined />
                      )
                    }
                  />
                </Col>
                <Col span={18}>
                  <Row>
                    <Col span={10}>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "#CC2A02",
                        }}
                      >
                        Name :
                      </p>
                    </Col>
                    <Col span={14}>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "black",
                        }}
                      >
                        {driverData && driverData.name}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={10}>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "#CC2A02",
                        }}
                      >
                        Vehicle No. :
                      </p>
                    </Col>
                    <Col span={14}>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "black",
                        }}
                      >
                        {driverData && driverData.vehicle_number
                          ? driverData.vehicle_number
                          : ""}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={10}>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "#CC2A02",
                        }}
                      >
                        Mobile No. :
                      </p>
                    </Col>
                    <Col span={14}>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "black",
                        }}
                      >
                        {driverData && driverData.number
                          ? driverData.number
                          : ""}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "#CC2A02",
                        }}
                      >
                        Valid Upto :
                      </p>
                    </Col>
                    <Col span={14}>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "black",
                        }}
                      >
                        {driverData && driverData.driver_valid_till
                          ? moment(driverData.driver_valid_till).format(
                              "DD MMMM YYYY"
                            )
                          : ""}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider style={{ background: "#FFEE4B", height: "3px" }} />
              <p style={{ textAlign: "center" }}>
                <EnvironmentOutlined />{" "}
                {driverData && driverData?.homeAddress?.location_name
                  ? driverData?.homeAddress?.location_name
                  : "-"}
              </p>
            </div>
          </div>
        </Modal>
      </Main>
      {/* <IDcardModal open={isModalOpen} onClose={() => setIsModalOpen(false)} driverData={driverData}/> */}
      <Modal
        open={phoneModal}
        footer={null}
        centered
        onCancel={() => setPhoneModal(false)}
      >
        <br />
        <div
          // style={{ border: "2px solid grey", height: "44px", padding: "10px" }}
          disabled
        >
          <a
            href={`tel:${
              driverData && driverData.number ? driverData.number : ""
            }`}
          >
            <img src="https://api.iconify.design/flat-color-icons/callback.svg" />{" "}
            {driverData && driverData.number ? driverData.number : ""}
          </a>
          <br />
          <a
            href={`sms:${
              driverData && driverData.number ? driverData.number : ""
            }`}
          >
            <img src="https://api.iconify.design/flat-color-icons/sms.svg" />{" "}
            {driverData && driverData.number ? driverData.number : ""}
          </a>
          <br />
          <a
            href={`https://wa.me/${
              driverData && driverData.number ? driverData.number : ""
            }`}
            // href={`Whatsapp:${
            //   driverData && driverData.number ? driverData.number : ""
            // }`}
          >
            <img src="https://api.iconify.design/logos/whatsapp-icon.svg" />{" "}
            {driverData && driverData.number ? driverData.number : ""}
          </a>
        </div>
      </Modal>
    </div>
  );
}

export default DriverDetails;
