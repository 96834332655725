import React, { useEffect } from "react";
import logo from "../../../../static/img/Applogo.png";
import { setItem } from "../../../../utility/sessionStrorageControl";
import "./aboutushtml.css";
const AboutUsHtml = () => {
  return (
    <>
      {/* <AuthWrapper> */}
      {/* <p className="auth-notice">
        Don't have an account? <NavLink to="/register">Sign up now</NavLink>
      </p> */}
      <div style={{ marginTop: "5vh" }}>
        <div className="imagediv">
          <img src={logo} className="image" />
        </div>
        <p>
          G-Auto is the pioneer of call-An-Auto service in India. It has bagged
          several international and national awards for its innovative approach
          to organizing auto drivers and offering safe and reliable service to
          <span className="text-huge"> </span>commuters
          <span className="text-huge">.</span>
        </p>
        <p>
          The cheapest mode to commute in Indian cities is auto but the cheapest
          and safest is G-Auto.
        </p>
        <p>We give solutions for RATS in autos :</p>

        <p>
          <strong className="strong">R </strong>= Refusal proof auto
          <br />
          <strong className="strong">A </strong>= Accessible in every corner of
          the city
          <br />
          <strong className="strong">T </strong>= Transparent fare
          <br />
          <strong className="strong">S </strong>= Safety assured
        </p>
        <p>&nbsp;</p>
      </div>

      {/* </AuthWrapper> */}
    </>
  );
};

export default AboutUsHtml;
