import actions from "./actions";

const { GET_NOTIFICATION_TYPES } = actions;

const initState = {
  NotificationData: {},
};

const NotificationTypeReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_NOTIFICATION_TYPES:
      return {
        ...state,
        NotificationData: data,
      };
    default:
      return state;
  }
};
export default NotificationTypeReducer;
