import { message } from "antd";
import { API } from "../../../config/api";
import { DataService } from "../../../config/dataService/dataService";
import actions from "./actions";

const { getTips } = actions;

export const getTipsData = (searchValue, page, limit, city) => {
  function getAllCities() {
    if (city !== "") {
      let finaldata = "";

      city?.map((data) => (finaldata = finaldata + "&city_id=" + data));
      return finaldata;
    }
  }

  return async (dispatch) => {
    const queryParamater =
      "?search=" +
      searchValue +
      "&page=" +
      page +
      "&limit=" +
      limit +
      getAllCities();

    const resp = await DataService.get(API.tips.get + queryParamater);

    if (!resp.data.error) {
      dispatch(getTips(resp.data.data));
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
export const addTip = (payload) => {
  return async () => {
    const resp = await DataService.post(API.tips.add, payload);

    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const updateTip = (payload, id) => {
  // console.log("update role payload", id, payload);
  return async (dispatch) => {
    const resp = await DataService.patch(API.tips.update + id, payload);
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const removeTip = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.tips.delete + id);
    if (!resp.data.error) {
      return true;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
