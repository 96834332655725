import { Avatar, Button, Card, Form, Input, message, Tabs } from "antd";
import React, { useState, useEffect } from "react";
import { Main } from "../styled";
import { useDispatch, useSelector } from "react-redux";
// import { getAdminData } from "../../redux/admin/actionCreator";
import { getItem } from "../../utility/localStorageControl";
import "./profile.css";
import {
  ADMIN,
  CALLCENTER,
  CAPTAIN,
  IMAGE_URL_PREFIX,
  SUPERADMIN,
} from "../../config/dataService/dataService";
import {
  changePassword,
  getAdminDataById,
  updateAdminData,
} from "../../redux/admin/actionCreatore";
import {
  getCallCenterById,
  updateCallCenter,
} from "../../redux/callCenter/actionCreatore";
import {
  getDriverCenterById,
  updateDriverCenter,
} from "../../redux/deriverCenter/actionCreatore";
import Loader from "../loader/loader";
import {
  getProfileDataById,
  updateProfileById,
} from "../../redux/SuperAdmin/actionCreatore";
import { useTranslation } from "react-i18next";
const { TabPane } = Tabs;
function YourProfile(props) {
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  //   const userId = getItem("userDetails").id;
  const user = getItem("user_details");
  const [editPassword, setEditPassword] = useState(false);
  const [uploadimgURL, setUploadimgURL] = useState("");
  const [editImage, setEditImage] = useState(false);
  const [details, setdetails] = useState([]);
  const [activeKey, setActiveKey] = useState("1");
  const [imageSizeError, setImageSizeError] = useState(false);

  let userData;
  const handleTabClick = (key) => {
    setActiveKey(key);
  };

  useEffect(() => {
    if (user.role == SUPERADMIN) {
      fetchDetails(getProfileDataById);
    }
    if (user.role == ADMIN) {
      fetchDetails(getAdminDataById);
    }
    if (user.role == CALLCENTER) {
      fetchDetails(getCallCenterById);
    }
    if (user.role == CAPTAIN) {
      fetchDetails(getDriverCenterById);
    }
    window.scrollTo(0, 0);
  }, []);

  const fetchDetails = async (reduxMethod) => {
    setLoading(true);
    const response = await dispatch(reduxMethod(user?._id));
    if (response) {
      setLoading(false);
    }
  };

  let data = useSelector((state) => {
    if (user?.role == SUPERADMIN) {
      return state.superAdmin.superAdminData;
    } else if (user?.role == ADMIN) {
      return state.admin.adminData;
    } else if (user?.role == CALLCENTER) {
      return state.callCenter.callCenterData;
    } else if (user?.role == CAPTAIN) {
      return state.captain.driverCenterData;
    }
  });

  // user.role == "super_admin" && (
  //   <>{(data = useSelector((state) => state.superAdmin.superAdminData))}</>
  // );
  // user.role == "admin" && (
  //   <>{(data = useSelector((state) => state.admin.adminData))}</>
  // );

  // user.role == "callCenter" && (
  //   <>{(data = useSelector((state) => state.callCenter.callCenterData))}</>
  // );

  // user.role == "captain" && (
  //   <>{(data = useSelector((state) => state.captain.driverCenterData))}</>
  // );

  // console.log("data", data);

  const onFinish = async (value) => {
    // console.log(value, uploadimgURL);
    let formdata = new FormData();
    formdata.append("name", value.name);
    {
      uploadimgURL && formdata.append("profile_image", uploadimgURL);
    }

    let response;

    const updateFunction = async (updateMethod) => {
      response = await dispatch(updateMethod(formdata, user._id));
      if (response) {
        if (user.role == "super_admin") {
          fetchDetails(getProfileDataById);
        }
        if (user.role == "admin") {
          fetchDetails(getAdminDataById);
        }
        if (user.role == "callCenter") {
          fetchDetails(getCallCenterById);
        }
        if (user.role == "captain") {
          fetchDetails(getDriverCenterById);
        }
      }
    };

    {
      user.role == "super_admin" && updateFunction(updateProfileById);
    }
    {
      user.role == "admin" && updateFunction(updateAdminData);
    }
    {
      user.role == "callCenter" && updateFunction(updateCallCenter);
    }
    {
      user.role == "captain" && updateFunction(updateDriverCenter);
    }
  };

  const handleEditPassword = async (value) => {
    value.email = user.email;
    console.log(value);
    setLoading(true);
    // let formdata = new FormData();
    // formdata.append("oldPassword", value.oldPassword);
    // formdata.append("newPassword", value.confirmPassword);
    // formdata.append("confirmPassword", value.confirmPassword);

    await dispatch(changePassword(user._id, value));
    setLoading(false);
  };
  const { t } = useTranslation();
  return (
    <div className="common_layout content-container ">
      {/* {loading ? (
        <Spinner />
      ) : (
       
      )} */}
      {/* <Main> */}
      {loading && <Loader />}
      {!loading && (
        <Card style={{ height: "100%" }}>
          <Tabs type="card" activeKey={activeKey} onTabClick={handleTabClick}>
            <TabPane tab="Profile Setting" key="1">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Form
                  name="basic"
                  // labelCol={{ span: 8 }}
                  // wrapperCol={{ span: 16 }}
                  // style={{ maxWidth: 600 }}
                  // initialValues={{ remember: true }}
                  onFinish={onFinish}
                  // onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  requiredMark={"optional"}
                  style={{ width: "40%" }}
                  layout="vertical"
                >
                  <Form.Item
                    name="image"
                    // label={
                    //   <>
                    //     Profile Pic
                    //     <span style={{ marginLeft: "3px" }}></span>{" "}
                    //   </>
                    // }
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please choose image",
                    //   },
                    // ]}
                  >
                    <p style={{ fontSize: "15px", fontWeight: "700" }}>
                      Click On Image To Upload
                    </p>
                    <div
                      className="profile-pic"
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        width: "auto",
                      }}
                    >
                      <label
                        htmlFor="input-file"
                        style={{
                          padding:
                            data?.profile_image || uploadimgURL
                              ? "0px"
                              : "38px 26px",
                        }}
                      >
                        {data?.profile_image || uploadimgURL ? (
                          <>
                            {" "}
                            {(uploadimgURL !== "") &
                            (uploadimgURL != undefined) ? (
                              <Avatar
                                size={100}
                                icon={
                                  <img
                                    src={URL.createObjectURL(uploadimgURL)}
                                    style={{
                                      height: "auto",
                                      paddingTop: "13px",
                                    }}
                                  />
                                }
                              />
                            ) : (
                              <Avatar
                                size={100}
                                icon={
                                  <img
                                    src={IMAGE_URL_PREFIX + data?.profile_image}
                                    // crossOrigin="anonymous"
                                  />
                                }
                              ></Avatar>
                            )}
                          </>
                        ) : (
                          <span>Upload</span>
                        )}
                      </label>
                      <Input
                        type="file"
                        onChange={(event) => {
                          if (
                            parseInt(
                              (event.target.files[0].size / 1048576).toFixed(1)
                            ) <= 5
                          ) {
                            // setUploadimgURL(URL.createObjectURL(event.target.files[0]));
                            setUploadimgURL(event.target.files[0]);
                            setImageSizeError(false);
                          } else {
                            setImageSizeError(true);
                          }
                        }}
                        accept="image/jpeg,image/png,"
                        id="input-file"
                        aria-label="Upload File"
                      />
                    </div>
                    {imageSizeError && (
                      <p style={{ color: "red" }}>
                        Maximum upload file size is 5MB
                      </p>
                    )}
                  </Form.Item>

                  <Form.Item
                    name="name"
                    label={t("name")}
                    rules={[
                      {
                        required: true,
                        message: "Please input your name!",
                      },
                    ]}
                    initialValue={data?.name}
                  >
                    <Input
                      style={{ background: "white", fontWeight: "700" }}
                      // defaultValue={data?.name}
                    />
                  </Form.Item>
                  <label>{t("emailAddress")}</label>
                  {/* <Form.Item
                        label={t("emailAddress")}
                        name="email"
                      > */}
                  <Input
                    // defaultValue={data && data.email}
                    // defaultValue={
                    //   user?.role == "admin" ? data?.email : userData?.data?.email
                    // }
                    defaultValue={data?.email}
                    disabled
                    style={{ background: "white", fontWeight: "700" }}
                  />
                  {/* </Form.Item> */}

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginTop: "40px" }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </TabPane>
            {user?.role != "super_admin" && (
              <TabPane tab="Change Password" key="2">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {/* <Card
                title="Change Password"
                headStyle={{ background: "#d2d2d4" }}
                extra={<EditOutlined onClick={() => setEditPassword(true)} />}
              > */}
                  {!loading && (
                    <Form
                      onFinish={handleEditPassword}
                      // disabled={!editPassword ? true : false}
                      layout="vertical"
                      style={{ width: "40%" }}
                    >
                      <Form.Item
                        name="old_password"
                        label="Old Password"
                        rules={[
                          {
                            required: true,
                            message: "Current Pasword is Required",
                          },
                        ]}
                      >
                        <Input
                          onKeyPress={(event) => {
                            if (event.key == " ") {
                              event.preventDefault();
                            }
                          }}
                          placeholder="Old Password"
                        />
                      </Form.Item>

                      <Form.Item
                        name="new_password"
                        label="New Password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password",
                          },
                          {
                            pattern: new RegExp(
                              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
                            ),
                            message: `Must contain at least one number , one uppercase, lowercase letter, and at least 8 or more characters`,
                          },
                        ]}
                        hasFeedback
                      >
                        <Input.Password
                          onKeyPress={(event) => {
                            if (event.key == " ") {
                              event.preventDefault();
                            }
                          }}
                          placeholder="Password"
                        />
                      </Form.Item>

                      <Form.Item
                        name="confirm_password"
                        label="Confirm Password"
                        dependencies={["new_password"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password",
                          },

                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("new_password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "The two passwords that you entered do not match!"
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          onKeyPress={(event) => {
                            if (event.key == " ") {
                              event.preventDefault();
                            }
                          }}
                          placeholder="Confirm Password"
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Change Password
                        </Button>
                      </Form.Item>
                    </Form>
                  )}

                  {/* </Card> */}
                </div>
              </TabPane>
            )}
          </Tabs>
        </Card>
      )}

      {/* </Main> */}
    </div>
  );
}

export default YourProfile;
