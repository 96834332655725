const actions = {
  
  GET_CANEL_BOOKING_REASONS: "GET_CANEL_BOOKING_REASONS",
 

  getCancelBookingReasons: (data) => {
    return {
      type: actions.GET_CANEL_BOOKING_REASONS,
      data,
    };
  },
};

export default actions;
