const actions = {
  GET_COMMISSION: "GET_COMMISSION",

  fetchForSuperadmin: (data) => {
    return {
      type: actions.GET_COMMISSION,
      data,
    };
  },
};
export default actions;
