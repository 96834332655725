import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import useStateRef from "react-usestateref";
import { useTranslation } from "react-i18next";
import { getItem } from "../../utility/localStorageControl";
import { useQuery } from "react-query";
import Loader from "../loader/loader";
import { getCityData } from "../../redux/Masters/City/actionCreatore";
import {
  addTier,
  deleteTier,
  getTiersData,
  updateTier,
} from "../../redux/Masters/Tier/actionCreatore";

const ManageTier = () => {
  const { t } = useTranslation();
  const [modalTitle, setModalTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateFlow, setUpdateFlow, setUpdateFlowFileRef] = useStateRef("");

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [searchValue, setSearchValue] = useState(null);
  const userDetails = getItem("user_details");

  const [search, setSearch] = useState(null);
  const [citySelected, setCitySelected] = useState("");

  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedId("")

    form.resetFields();
    setUpdateFlow("");
    handleSearch("");
    // setSelectDep(0)
  };
  const deleteTierFinal = async (id) => {
    let remove = await dispatch(deleteTier(id));
    if (remove) {
      message.success("Tier deleted successfully");
      reteriveTierList();
    }
  };
  const removeTier = (id) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this Admin?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteTierFinal(id);
      },
    });
  };

  useEffect(() => {
    // reteriveAdminList();
    // reteriveCityList();
    window.scrollTo(0, 0);
  }, []);

  const { data, isLoading } = useQuery("adminlist", () => {
    return reteriveTierList();
  });

  const { data: dataCity, isLoading: isLoadingCity } = useQuery(
    "cityList",
    () => {
      return reteriveCityList();
    }
  );

  const reteriveTierList = async () => {
    // setLoading(true);
    const resp = await dispatch(getTiersData(pageNo, pageSize, citySelected));
    if (resp) {
      // setLoading(false);
      return resp;
    }
  };
  const tierList = useSelector((state) => state.tier.tiersData);
  console.log("tierList", tierList);

  const reteriveCityList = async () => {
    // setLoading(true);
    const resp = await dispatch(getCityData(""));
    if (resp) {
      // setLoading(false);
    }
  };
  let cityList = [];
  cityList = useSelector((state) => state.city.cityData);
  cityList = cityList?.sort((a, b) => a.name?.localeCompare(b.name));

  const handleSubmit = async (values) => {
    if (setUpdateFlowFileRef.current == "") {
      const resp = await dispatch(addTier(values));
      if (resp) {
        message.success("Tier add successfully");
        setCitySelected("");
        await dispatch(getTiersData(pageNo, pageSize, ""));
        setIsModalOpen(false);
      }
    } else {
      const resp = await dispatch(updateTier(values, updateFlow));
      if (resp) {
        message.success("Tier updated successfully");
        await dispatch(getTiersData(pageNo, pageSize, ""));
        setIsModalOpen(false);
        setUpdateFlow("");
        form.resetFields();
      }
    }
  };

  const editTier = (data) => {
    setIsModalOpen(true);
    // console.log(data);
    setModalTitle("Update Tier");
    setUpdateFlow(data?._id);
    form.setFieldsValue({
      city_id: data?.city_id?._id,
      name: data.name,
      radius: data.radius,
    });
  };
  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    await dispatch(getTiersData(page, pageSizes, citySelected));
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    await dispatch(getTiersData(current, pageSizes, citySelected));
  };

  const handleSearch = async (value) => {
    setSearch(value);

    await dispatch(getCityData(value));
  };

  const handleCitySelected = async (value, from) => {
    setCitySelected(value);
    if (from == "list") {
      setPageNo(1);
      await dispatch(getTiersData(1, pageSize, value));
    }
  };

  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },
    {
      title: t("city"),
      key: "city",
      align: "center",
      render: (data) => {
        return <span>{data.city_id?.name}</span>;
      },
    },
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Radius (Meters)",
      dataIndex: "radius",
      key: "radius",
      align: "center",
    },
    {
      title: t("action"),
      align: "center",
      render: (data) => {
        return (
          <div>
            <Button
              onClick={() => editTier(data)}
              type="primary"
              // style={{ cursor: "pointer", color: "blue" }}
            >
              <EditOutlined />
              Edit
            </Button>

            <Button
              onClick={() => removeTier(data._id)}
              style={{ cursor: "pointer", marginLeft: 2 }}
              type="primary"
              danger
              disabled
            >
              <DeleteOutlined />
              Delete
            </Button>
          </div>
        );
      },
    },
  ];
  // if(isLoading){
  //   return <Loader />
  // }

  return (
    <div className="common_layout content-container">
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h1 className="heading">Tier </h1>

          {/* Total 5 */}
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Button
            style={{ float: "right" }}
            className="ADD"
            key="1"
            size="large"
            type="primary"
            onClick={() => {
              setIsModalOpen(true); setModalTitle("Add New Tier");
              handleSearch("");
            }}
          >
            <PlusOutlined /> Add Tier
          </Button>
        </Col>
      </Row>
      <Divider />
      {isLoading & isLoadingCity ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <Card style={{ marginBottom: 10 }} className="shadowCardBox">
            <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
              <Col lg={24}>
                <span className="total">Total {tierList?.total_counts}</span>

                <Select
                  placeholder="Choose city"
                  style={{ width: 200, marginLeft: "10px", float: "right" }}
                  showSearch
                  filterOption={false}
                  onSearch={(e) => handleSearch(e)}
                  allowClear
                  onClear={(e) => {
                    handleSearch(""); handleCitySelected("", "list");
                  }}
                  onSelect={(value) => handleCitySelected(value, "list")}
                >
                  {cityList.length > 0 &&
                    cityList?.map((data) => {
                      return (
                        <Select.Option value={data._id} key={data._id}>
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Col>
            </Row>
          </Card>
          <Card className="shadowCardBox">
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              rowKey="_id"
              columns={columns}
              dataSource={tierList?.list}
              pagination={
                // tierList?.total_counts > pageSize
                //   ?
                {
                  showSizeChanger: true,
                  pageSize: pageSize,
                  total: tierList?.total_counts,
                  onShowSizeChange: onShowSizeChange,
                  onChange: onPageChange,
                  pageSizeOptions: ["10", "50", "100", "500", "1000"],
                }
                //   : false
              }
              scroll={{ x: "45vh" }}
            />
          </Card>
        </>
      )}
      <Modal
        open={isModalOpen}
        title={modalTitle}
        onCancel={handleCancel}
        onOk={form.submit}
      >
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          form={form}
          autoComplete="off"
        >
          <Form.Item
            label="City"
            name={"city_id"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Choose city"
              //   style={{ width: 200, marginLeft: "10px", float: "right" }}
              showSearch
              filterOption={false}
              onSearch={(e) => handleSearch(e)}
              allowClear
              onClear={(e) => {
                handleSearch(""); handleCitySelected("", "modal");
              }}
              onSelect={(value) => handleCitySelected(value, "modal")}
              disabled={modalTitle == "Update Tier" ? true : false}
            >
              {cityList.length > 0 &&
                cityList?.map((data) => {
                  return (
                    <Select.Option value={data._id} key={data._id}>
                      {data.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Name"
            name={"name"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>
          <Form.Item
            label="Radius"
            name={"radius"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Enter radius" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ManageTier;
