import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const { getRiderList } = actions;

export const getAllRiderList = (
  page,
  limit,
  search,
  city,
  sortKey,
  sort,
  selectedTime
) => {
  let finaldata = "";

  city !== "" &&
    city?.map((data) => (finaldata = finaldata + "&city_id=" + data));
  return async (dispatch) => {
    const queryParameter =
      "&page=" +
      page +
      "&limit=" +
      limit +
      "&search=" +
      search +
      finaldata +
      "&key=" +
      sortKey +
      "&value=" +
      sort +
      "&login_time=" +
      selectedTime;
    const resp = await DataService.get(API.riders.getAll + queryParameter);

    if (!resp.data.error) {
      dispatch(getRiderList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export const multiDeleteRiders = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.riders.delete, payload);

    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};
