import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Image,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./Vehicle.css";
import { useDispatch, useSelector } from "react-redux";
import useStateRef from "react-usestateref";

import {
  addVehicleType,
  getVehicleCategoriesData,
  getVehicleTypesData,
  removeVehicleType,
  updateVehicleType,
} from "../../redux/VehicleTypes/actionCreatore";
import useSelection from "antd/lib/table/hooks/useSelection";
import {
  API_ENDPOINT,
  IMAGE_URL_PREFIX,
} from "../../config/dataService/dataService";
import { checkAction, getRandomColor } from "../../utility/commonFunction";
import Loader from "../loader/loader";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import FeatherIcon from "feather-icons-react";

const VehicleType = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [ModalTitle, setModalTitle] = useState("Add Vehicle Type");
  const [details, setDetails] = useState({});
  const [topDetails, setTopDetails] = useState({});

  const [uploadimgURL, setUploadimgURL] = useState("");
  const [uploadTopImgURL, setUploadTopImgURL] = useState("");

  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [updateFlow, setUpdateFlow, setUpdateFlowFileRef] = useStateRef("");

  const dispatch = useDispatch();
  useEffect(() => {
    // fetchVehicleTypes();
    window.scrollTo(0, 0);
  }, []);

  const { data, isLoading } = useQuery(
    "vehcilelTypeList",
    () => {
      return fetchVehicleTypes();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchVehicleTypes = async () => {
    setLoading(true);
    const resp = await dispatch(getVehicleTypesData());
    if (resp) {
      setVehicleTypeList(resp);
      setLoading(false);
    }
  };

  const vehicleTypes = useSelector((state) => state.vehicleType.vehicleData);
  // console.log(vehicleTypes);
  const { data: vehicleData, isLoading: categoryLoading } = useQuery(
    "vehcilelCategoryList",
    () => {
      return fetchVehicleCategories();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchVehicleCategories = async () => {
    setLoading(true);
    const resp = await dispatch(getVehicleCategoriesData());
    if (resp) {
      setLoading(false);
    }
  };

  const category = useSelector((state) => state.vehicleType.vehicleCategory);

  const deleteVehicleFinal = async (id) => {
    // console.log(id);
    setLoading(true);

    let remove = await dispatch(removeVehicleType(id));

    // console.log("remove", remove);
    if (remove) {
      message.success("Vehicle type removed successfully");
      fetchVehicleTypes();
    }
  };
  const deleteVehicleType = (id) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this Vehicle Type?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteVehicleFinal(id);
      },
    });
  };
  const editVehicleType = (data) => {
    setModalTitle("Edit Vehicle Type");
    setIsModalOpen(true);
    setUpdateFlow(data._id);
    // console.log(data);
    form.setFieldsValue({
      vehicleType: data.name,
      status: data.status,
      description: data.description,
      capacity: data.person_capacity,
      image: data.image,
      image_top_view: data.image_top_view,
      vehicleCategory: data.vehicleCategory,
    });
    setDetails({ vehicleImage: data.image });
    setTopDetails({ vehicleTopImage: data.image_top_view });
  };
  const handleSubmit = async (values) => {
    setLoading(true);

    let formData = new FormData();
    formData.append("name", values.vehicleType);
    formData.append("person_capacity", values.capacity);
    formData.append("description", values.description);
    formData.append("image", details.vehicleImage);
    formData.append("image_top_view", topDetails.vehicleTopImage);
    formData.append("vehicleCategory", values.vehicleCategory);

    formData.append("status", values.status);

    if (setUpdateFlowFileRef.current == "") {
      const resp = await dispatch(addVehicleType(formData));
      if (resp) {
        message.success("Vehicle type added successfully");
        setDetails({});
        setTopDetails({});

        setUploadimgURL("");
        setUploadTopImgURL("");

        setIsModalOpen(false);
        fetchVehicleTypes();
      }
    } else {
      const resp = await dispatch(updateVehicleType(formData, updateFlow));
      if (resp) {
        message.success("Vehicle type updated successfully");
        setDetails({});
        setTopDetails({});

        setUpdateFlow("");
        setUploadimgURL("");
        setUploadTopImgURL("");

        setIsModalOpen(false);
        fetchVehicleTypes();
      }
    }
  };
  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      align: "center",

      render: (text, object, index) => index + 1,
    },
    {
      title: "Side View Image",
      key: "Image",
      render: (t, r) => {
        if (!r.image) {
          return (
            <div>
              <Avatar size={50} icon={<FeatherIcon icon="truck" />} />
            </div>
          );
        } else {
          return (
            <>
              <Image
                style={{ maxWidth: "30%", width: "100%" }}
                // crossOrigin="anonymous"
                alt=""
                src={`${IMAGE_URL_PREFIX}${r.image}`}
              />
            </>
          );
        }
      },
    },
    {
      title: "Top View Image",
      key: "Image",
      render: (t, r) => {
        if (!r.image) {
          return (
            <div>
              <Avatar size={50} icon={<FeatherIcon icon="truck" />} />
            </div>
          );
        } else {
          return (
            <>
              <Image
                style={{
                  maxWidth: "20%",
                  width: "100%",
                }}
                // crossOrigin="anonymous"
                alt=""
                src={`${IMAGE_URL_PREFIX}${r.image_top_view}`}
              />
            </>
          );
        }
      },
    },
    {
      title: "Vehicle Category",
      align: "center",
      render: (data, record) => {
        return (
          <span>
            {data?.vehicleCategory
              ? data.vehicleCategory.charAt(0).toUpperCase() +
                data.vehicleCategory.slice(1)
              : "-"}
          </span>
        );
      },
    },
    {
      title: "Vehicle Type",
      align: "center",
      render: (data, record) => {
        return <span>{data?.name}</span>;
      },
    },
    {
      title: "Vehicle Add Date",
      align: "center",

      render: (data, record) => {
        const date = new Date(data?.created_at);
        return <span>{date.toLocaleDateString()}</span>;
      },
    },
    {
      title: "Seating Capacity",
      align: "center",

      render: (data, record) => {
        return <span>{data?.person_capacity} </span>;
      },
    },
    {
      title: "Status",
      align: "center",

      render: (data, record) => {
        // return <span>{data?.status}</span>;
        return (
          <Tag
            color={data.status === "inactive" ? "volcano" : "green"}
            key={data.status}
          >
            {data.status.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: t("action"),
      // dataIndex: "documents",
      align: "center",

      key: "action",
      render(data, record) {
        return (
          <div style={{ display: "flex" }}>
            {checkAction("master_vehicleType", "edit") && (
              <Button
                onClick={() => editVehicleType(data)}
                type="primary"
                // style={{ cursor: "pointer", color: "blue" }}
                disabled
              >
                <EditOutlined />
                Edit
              </Button>
            )}
            {checkAction("master_vehicleType", "delete") && (
              <Button
                onClick={() => deleteVehicleType(data._id)}
                style={{ cursor: "pointer", marginLeft: 2 }}
                type="primary"
                danger
                disabled
              >
                <DeleteOutlined />
                Delete
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  const showModal = () => {
    form.resetFields();
    setModalTitle("Add Vehicle Type");
    setIsModalOpen(true);
    // setSelectedId("")
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedId("")
    setUpdateFlow("");

    form.resetFields();
    setDetails({});
    setTopDetails({});

    setUploadimgURL("");
    setUploadTopImgURL("");
    // setSelectDep(0)
  };

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">{t("vehicleType")}</h1>
            <BreadCrumb />
          </Col>
          {/* Total {vehicleTypeList.length} */}
          <Col lg={12} md={12} sm={12} xs={12}>
            {checkAction("master_vehicleType", "add") && (
              <Button
                style={{ float: "right" }}
                className="ADD"
                key="1"
                size="large"
                type="primary"
                onClick={showModal}
              >
                <PlusOutlined /> Add Vehicle Type
              </Button>
            )}
          </Col>
        </Row>
        <Divider />
        {!isLoading && (
          <>
            <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                dataSource={vehicleTypeList}
                columns={columns}
                searchable
                pagination={false}
                scroll={{ x: "45vh" }}
              />
            </Card>
          </>
        )}
        {isLoading && <Loader />}
      </div>
      <Modal
        title={ModalTitle}
        open={isModalOpen}
        onOk={form.submit}
        // closable={true}
        // maskClosable={false}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          // labelCol={{ span: 5 }}
        >
          <Form.Item
            label={`Vehicle Category`}
            rules={[
              { required: true, message: "Please Choose Vehicle Category" },
            ]}
            name="vehicleCategory"
          >
            <Select placeholder={"Choose Vehicle Category"}>
              {category &&
                Array.isArray(category) &&
                category.map((cat) => {
                  return (
                    <Select.Option value={cat} key={cat}>
                      {cat.charAt(0).toUpperCase() + cat.slice(1)}
                    </Select.Option>
                  );
                })}
              {/* <Select.Option value="auto">Auto</Select.Option>
              <Select.Option value="bike">Bike</Select.Option>
              <Select.Option value="cab">Cab</Select.Option> */}
            </Select>
          </Form.Item>

          <Form.Item
            label={`Vehicle Type`}
            rules={[{ required: true, message: "Please Enter Vehicle Type" }]}
            name="vehicleType"
          >
            <Input type="text" placeholder="Enter Vehicle Type"></Input>
          </Form.Item>
          <Form.Item
            label={`Seating Capacity`}
            rules={[
              { required: true, message: "Please Enter Seating Capacity" },
            ]}
            name="capacity"
          >
            <Input
              type="number"
              placeholder="Enter Seating Capacity"
              min={0}
              onKeyDown={(event) => {
                if (event.key == "-" || event.key == "+") {
                  event.preventDefault();
                }
              }}
            ></Input>
          </Form.Item>
          <Form.Item
            label={`Description`}
            rules={[{ required: true, message: "Please Enter Description" }]}
            name="description"
          >
            <Input type="text" placeholder="Enter Description"></Input>
          </Form.Item>
          <Form.Item
            label="Status"
            rules={[{ required: true, message: "Please Select Status" }]}
            name="status"
          >
            {/* <Select placeholder="Choose Status">
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="inactive">inactive</Select.Option>
            </Select> */}
            <Radio.Group>
              <Radio value="active">Active</Radio>
              <Radio value="inactive">Inactive</Radio>
            </Radio.Group>
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item
                name="image_top_view"
                // getValueFromEvent={normFile}
                label={
                  <>
                    Top View Image
                    <span style={{ marginLeft: "3px" }}></span>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: "Please choose Image",
                  },
                ]}
              >
                <div className="vehicle_image">
                  <label
                    htmlFor="input-Top-file"
                    style={{
                      padding: topDetails?.vehicleTopImage
                        ? "0px"
                        : "38px 26px",
                      display: topDetails?.vehicleTopImage ? "contents" : "",
                    }}
                  >
                    {topDetails?.vehicleTopImage ? (
                      <>
                        {" "}
                        {/* {console.log("topdetaisl", topDetails)} */}
                        {(uploadTopImgURL !== "") &
                        (uploadTopImgURL != undefined) ? (
                          // <Avatar
                          //   size={100}
                          //   src={URL.createObjectURL(uploadTopImgURL)}
                          // />
                          <img
                            style={{ maxWidth: "30%" }}
                            src={URL.createObjectURL(uploadTopImgURL)}
                          />
                        ) : (
                          // <Avatar
                          //   size={100}
                          //   icon={
                          //   }
                          //   ></Avatar>
                          <img
                            style={{ maxWidth: "30%" }}
                            src={IMAGE_URL_PREFIX + topDetails?.vehicleTopImage}
                            // crossOrigin="anonymous"
                          />
                        )}
                      </>
                    ) : (
                      <span>Upload</span>
                    )}
                  </label>
                  {/* {console.log("top", topDetails)} */}
                  <Input
                    type="file"
                    onChange={(event) => {
                      // setUploadTopImgURL(URL.createObjectURL(event.target.files[0]));
                      // console.log("top");
                      setUploadTopImgURL(event.target.files[0]);
                      setTopDetails({ vehicleTopImage: event.target.files[0] });
                    }}
                    accept="image/jpeg,image/png,"
                    id="input-Top-file"
                    aria-label="Upload File"
                  />
                </div>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="image"
                // getValueFromEvent={normFile}
                label={
                  <>
                    Side View Image
                    <span style={{ marginLeft: "3px" }}></span>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: "Please choose Image",
                  },
                ]}
              >
                <div className="vehicle_image">
                  <label
                    htmlFor="input-file"
                    style={{
                      padding: details?.vehicleImage ? "0px" : "38px 26px",
                      display: details?.vehicleImage ? "contents" : "",
                    }}
                  >
                    {details?.vehicleImage ? (
                      <>
                        {" "}
                        {/* {console.log("detaisl", details)} */}
                        {(uploadimgURL !== "") & (uploadimgURL != undefined) ? (
                          <Avatar
                            size={100}
                            src={URL.createObjectURL(uploadimgURL)}
                          />
                        ) : (
                          // <Avatar
                          //   size={100}
                          //   icon={

                          //   }
                          // ></Avatar>
                          <img
                            style={{ maxWidth: "50%" }}
                            src={IMAGE_URL_PREFIX + details?.vehicleImage}
                            // crossOrigin="anonymous"
                          />
                        )}
                      </>
                    ) : (
                      <span>Upload</span>
                    )}
                  </label>
                  <Input
                    type="file"
                    onChange={(event) => {
                      // setUploadimgURL(URL.createObjectURL(event.target.files[0]));
                      // console.log("side");

                      setUploadimgURL(event.target.files[0]);
                      setDetails({ vehicleImage: event.target.files[0] });
                    }}
                    accept="image/jpeg,image/png,"
                    id="input-file"
                    aria-label="Upload File"
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row></Row>
        </Form>
      </Modal>
    </>
  );
};

export default VehicleType;
