const actions = {
  GET_FEEDBACK: "GET_FEEDBACK",

  getFeedback: (data) => {
    return {
      type: actions.GET_FEEDBACK,
      data,
    };
  },
};

export default actions;
