import { SwapOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getDriverBankDetailsVerifyRequest,
  updateBankDetailRequest,
} from "../../../redux/drivers/actionCreatore";
import { getCityData } from "../../../redux/Masters/City/actionCreatore";
import Loader from "../../loader/loader";

import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
  IMAGE_URL_PREFIX,
  SUPERADMIN,
} from "../../../config/dataService/dataService";
import { getItem } from "../../../utility/localStorageControl";
import BreadCrumb from "../../BreadCrumb.js/BreadCrumb";
const DriverBankDetailVerificationRequest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchCity, setSearchCity] = useState(null);
  const [pageSize, setPageSize] = useState(50);
  const [pageNo, setPageNo] = useState(1);
  const [searchFilter, setSearchFilter] = useState("");

  const user = getItem("user_details");
  const [selectedCity, setSelectedCity] = useState(
    user?.role == "admin" ? [user?.city_id?._id] : ""
  );
  const [approvalStatus, setApprovalStatus] = useState("");
  const [tableLoading, setTableLoading] = useState(true);
  const [sort, setSort] = useState("DSC");
  const [buttonLoader, setButtonLoader] = useState(false);
  useEffect(() => {
    // reteriveDriverBankRequestList();
    // GetCity();
    window.scrollTo(0, 0);
  }, []);

  const { data, isLoading } = useQuery(
    "driveBankDetailsVerificationRequestsList",
    () => {
      return reteriveDriverBankRequestList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataCity, isLoading: isLoadingCity } = useQuery(
    "cityList",
    () => {
      return GetCity();
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    if (!isLoading) {
      setTableLoading(false);
    }
  }, [isLoading]);
  const reteriveDriverBankRequestList = async () => {
    setLoading(true);

    const response = await dispatch(
      getDriverBankDetailsVerifyRequest(
        pageNo,
        pageSize,
        searchFilter,

        selectedCity,
        approvalStatus,

        sort,
        "true"
      )
    );
    if (response) {
      setLoading(false);
    }
  };
  const driversData = useSelector((state) => state.driver.bankDetailsRequests);
  // console.log("driverData", driversData);

  const GetCity = async () => {
    setLoading(true);

    const response = await dispatch(getCityData(search));
    if (response) {
      setLoading(false);
    }
  };
  let cityList = [];
  cityList = useSelector((state) => state?.city?.cityData);
  cityList = cityList?.sort((a, b) => a.name?.localeCompare(b.name));
  const { t } = useTranslation();
  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    await dispatch(
      getDriverBankDetailsVerifyRequest(
        page,
        pageSizes,
        searchFilter,

        selectedCity,
        approvalStatus,

        sort,
        "true"
      )
    );
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    await dispatch(
      getDriverBankDetailsVerifyRequest(
        current,
        pageSizes,
        searchFilter,

        selectedCity,
        approvalStatus,

        sort,
        "true"
      )
    );
  };

  const handleSort = async () => {
    setLoading(true);
    let sorting;
    if (sort == "ASC") {
      setSort("DSC");
      sorting = "DSC";
    } else {
      setSort("ASC");
      sorting = "ASC";
    }
    setTableLoading(true);
    // sort=sort=="ASC"?setSort("DSC"):setSort("ASC")
    const resp = await dispatch(
      getDriverBankDetailsVerifyRequest(
        1,
        pageSize,
        searchFilter,

        selectedCity,
        approvalStatus,

        sorting,
        "true"
      )
    );
    if (resp) {
      setLoading(false);
      setTableLoading(false);
    }
  };
  const handleAction = async (id, status) => {
    setTableLoading(true);

    const resp = await dispatch(updateBankDetailRequest(id, { status }));
    if (resp) {
      setTableLoading(true);
      // sort=sort=="ASC"?setSort("DSC"):setSort("ASC")
      const resp = await dispatch(
        getDriverBankDetailsVerifyRequest(
          1,
          pageSize,
          searchFilter,

          selectedCity,
          approvalStatus,

          sort,
          "true"
        )
      );
      if (resp) {
        setLoading(false);
        setTableLoading(false);
      }
    }
    setTableLoading(false);
  };

  // console.log("selectedRows", selectedRows);
  const handleActionModal = async (id, type) => {
    if (type == "Approve") {
      Modal.confirm({
        title: "Are you sure, Approve this request",
        okText: "Yes",
        okType: "primary",
        onOk: () => {
          handleAction(id, "approve");
        },
      });
    }
    if (type == "Reject") {
      Modal.confirm({
        title: "Are you sure, Reject this request",
        okText: "Yes",

        okType: "danger",
        onOk: () => {
          // deleteCityFinal(value);
          handleAction(id, "reject");
        },
      });
    }
  };
  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },
    {
      title: "Driver Name",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "Current Location City",
    //   key: "city",
    //   render: (data) => {
    //     return <span>{data?.city_id?.name ? data?.city_id?.name : "-"}</span>;
    //   },
    // },
    // {
    //   title: "Registered For City",
    //   key: "registeredcity",
    //   render: (data) => {
    //     return (
    //       <span>
    //         {data?.driverRegisteredCity?.name
    //           ? data?.driverRegisteredCity?.name
    //           : "-"}
    //       </span>
    //     );
    //   },
    // },
    {
      title: t("phoneNo"),
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Bank Details",
      // dataIndex: "updated_document",
      key: "updated_document",
      children: [
        {
          title: "Account Number",
          align: "center",

          render: (data) => {
            // console.log("data", data);
            return (
              <div
                style={{
                  justifyContent: "center",
                  // display: "flex",
                  // alignContent: "space-between",
                }}
              >
                <p>{data?.driverBankDetails?.account_number ?? ""}</p>
              </div>
            );
          },
        },
        {
          title: "IFSC Code",
          align: "center",

          render: (data) => {
            // console.log("data", data);
            return (
              <div
                style={{
                  justifyContent: "center",
                  // display: "flex",
                  // alignContent: "space-between",
                }}
              >
                <p>{data?.driverBankDetails?.ifsc_code ?? ""}</p>
              </div>
            );
          },
        },
        {
          title: "Cheque",
          align: "center",

          render: (data) => {
            // console.log("data", data);
            return (
              <div
                style={{
                  justifyContent: "center",
                  // display: "flex",
                  // alignContent: "space-between",
                }}
              >
                {/* <Image
                  src={IMAGE_URL_PREFIX + data?.driverBankDetails?.cheque}
                  width={60}
                  height={50}
                  style={{ border: "1px solid black" }}
                /> */}

                {data?.driverBankDetails?.cheque ? (
                  data?.driverBankDetails?.cheque.split(".pdf")?.length == 1 ? (
                    <Image
                      src={IMAGE_URL_PREFIX + data?.driverBankDetails?.cheque}
                      style={{ border: "1px solid black" }}
                      width={60}
                      height={50}
                      alt="Cheque Image"
                    />
                  ) : (
                    <a
                      href={IMAGE_URL_PREFIX + data?.driverBankDetails?.cheque}
                      target="_blank"
                    >
                      <div>
                        <iframe
                          src={
                            IMAGE_URL_PREFIX + data?.driverBankDetails?.cheque
                          }
                          width={60}
                          height={50}
                        />
                      </div>
                    </a>
                  )
                ) : (
                  <span>Document not available</span>
                )}
              </div>
            );
          },
        },
      ],
    },
    {
      title: (filters, sortOrder) => (
        <div>
          Requested Timestamp
          <Button
            style={{ background: "none", border: "none" }}
            onClick={() => handleSort()}
          >
            <SwapOutlined rotate={90} />
          </Button>
        </div>
      ),

      key: "timestamp",

      render: (data) => {
        const date = new Date(data?.updated_at);

        return (
          <span>
            <Tooltip title={date.toLocaleString()}>
              {" "}
              {data?.updated_at ? date.toLocaleString() : "-"}{" "}
            </Tooltip>
          </span>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      render: (data) => {
        return (
          <Tag
            color={
              data && data?.bankDetailsStatus == "rejected" ? "red" : "#DBD050"
            }
            key={data}
          >
            {data?.bankDetailsStatus == "rejected" && "Rejected"}
            {data?.bankDetailsStatus == "pending" && "Pending"}
          </Tag>
        );
      },
    },

    {
      title: t("action"),
      dataIndex: "",
      key: "x",
      render: (record, target) => {
        return (
          <>
            <div className="action">
              <Space>
                <Button
                  type="primary"
                  onClick={() => handleActionModal(record._id, "Approve")}
                >
                  <strong>Approve</strong>
                </Button>
                {record?.bankDetailsStatus != "rejected" && (
                  <Button
                    type="primary"
                    danger
                    onClick={() => handleActionModal(record._id, "Reject")}
                  >
                    <strong>Reject</strong>
                  </Button>
                )}
              </Space>
            </div>
          </>
        );
      },
    },
  ];

  const handleSearchFilter = async (value) => {
    setSearchFilter(value);
    await dispatch(
      getDriverBankDetailsVerifyRequest(
        pageNo,
        pageSize,
        value,

        selectedCity,
        approvalStatus,

        sort,
        "true"
      )
    );
  };

  const handleSearch = async (value) => {
    setSearchCity(value);
    setSelectedCity("");
    await dispatch(getCityData(value));
    // await dispatch(
    //   getDriverBankDetailsVerifyRequest(
    //     pageNo,
    //     pageSize,
    //     searchFilter,

    //     "",
    //     approvalStatus,
    //
    //     sort,
    //     "true"
    //   )
    // );
  };

  const handleSelectedCity = async (value) => {
    setSelectedCity(value);
    if (searchCity) {
      setSearchCity("");
      await dispatch(getCityData(""));
    }
    await dispatch(
      getDriverBankDetailsVerifyRequest(
        pageNo,
        pageSize,
        searchFilter,

        value,
        approvalStatus,

        sort,
        "true"
      )
    );
  };

  const chooseApprovalStatus = async (status) => {
    setApprovalStatus(status);
    await dispatch(
      getDriverBankDetailsVerifyRequest(
        pageNo,
        pageSize,
        searchFilter,

        selectedCity,
        status,

        sort,
        "true"
      )
    );
  };

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">
              {" "}
              {t("driver")} Bank Details Verfication {t("request")}
            </h1>
            <BreadCrumb />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            {/* <Button
              style={{ float: "right" }}
              type="primary"
                danger
              
              disabled={selectedRows.length == 0 ? true : false}
              onClick={handleMultiDelete}
            >
              {" "}
              <DeleteOutlined /> Delete
            </Button> */}
          </Col>
        </Row>
        <Divider />

        {isLoading & isLoadingCity ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={8}
                  sm={12}
                  xs={24}
                  className="total"
                >
                  Total {driversData?.total_counts}
                </Col>

                {user.role != SUPERADMIN && (
                  <Col xxl={0} xl={6} lg={6} md={0} sm={12} xs={0}></Col>
                )}

                <Col xxl={12} xl={12} lg={12} md={8} sm={12} xs={24}>
                  <Input
                    placeholder="Search By Name Or Phone"
                    style={{ width: "100%" }}
                    // enterButton="Search"
                    // size="large"
                    onChange={(e) => handleSearchFilter(e.target.value)}
                    prefix={<SearchOutlined />}
                  />
                </Col>

                {/* {user.role == SUPERADMIN && (
                  <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                    <Select
                      placeholder="Choose city"
                      style={{
                        width: "100%",
                        maxHeight: "45px",
                        overflowY: "auto",
                        marginTop: "6px",
                      }}
                      showSearch
                      mode="multiple"
                      filterOption={false}
                      onSearch={(e) => handleSearch(e)}
                      allowClear
                      onChange={handleSelectedCity}
                    >
                      {cityList.length > 0
                        ? cityList?.map((data) => {
                            return (
                              <Select.Option
                                value={data._id}
                                key={data._id}
                                className="selectOptions"
                              >
                                {data.name}
                              </Select.Option>
                            );
                          })
                        : ""}
                    </Select>
                  </Col>
                )} */}
                <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                  <Select
                    placeholder="Choose Status"
                    style={{ width: "100%" }}
                    filterOption={false}
                    // onSearch={(e) => handleSearch(e)}
                    allowClear
                    onClear={(value) => chooseApprovalStatus("")}
                    onSelect={(value) => chooseApprovalStatus(value)}
                  >
                    <Select.Option
                      value={"pending"}
                      key={"pending"}
                      className="selectOptions"
                    >
                      <Tag color="#DBD050">Pending</Tag>
                    </Select.Option>
                    <Select.Option
                      value={"rejected"}
                      key={"rejected"}
                      className="selectOptions"
                    >
                      <Tag color={"red"}>Rejected</Tag>
                    </Select.Option>
                  </Select>
                </Col>
              </Row>
            </Card>
            <Card className="shadowCardBox">
              <Table
                // rowClassName={(record, index) =>
                //   record?.isRead ? "read" : "unread"
                // }
                // rowSelection={
                //   rowSelection

                // }
                dataSource={driversData?.list}
                columns={
                  user.role == SUPERADMIN
                    ? columns
                    : columns.filter((key) => key.title != "City")
                }
                searchable
                scroll={{ x: "45vh" }}
                loading={tableLoading}
                rowKey={"_id"}
                pagination={{
                  showSizeChanger: true,
                  pageSize: pageSize,
                  total: driversData?.total_counts,
                  onShowSizeChange: onShowSizeChange,
                  onChange: onPageChange,
                  pageSizeOptions: ["50", "100", "500"],
                }}
              />
            </Card>
          </>
        )}
      </div>
      {/* {loading && <Loader />} */}
    </>
  );
};

export default DriverBankDetailVerificationRequest;
