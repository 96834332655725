import { Spin } from "antd";
import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import AboutUsHtml from "../container/profile/authentication/overview/AboutUsHtml";
import NewPassword from "../container/profile/authentication/overview/NewPassword";
import TermAndConditionDriver from "../container/profile/authentication/overview/TermAndConditionDriver";
import TermAndConditionRider from "../container/profile/authentication/overview/TermAndConditionRider";

import PrivacyPolicyDriverHtml from "../container/profile/authentication/overview/PrivacyPlocyDriver";
import PrivacyPolicyRiderHtml from "../container/profile/authentication/overview/PrivacyPolicyRider";
import CustomerSupport from "../container/profile/authentication/pages/CustomerSupport";
import Privacy from "../container/profile/authentication/pages/PrivacyPolicy";
import RefundPolicy from "../container/profile/authentication/pages/RefundPolicy";
import Terms from "../container/profile/authentication/pages/Term&Condition";

// import PrivacyPolicyDriverHtml from "../container/profile/authentication/overview/PrivacyPolicyDriver";

const Login = lazy(() =>
  import("../container/profile/authentication/overview/SignIn")
);
const SignUp = lazy(() =>
  import("../container/profile/authentication/overview/Signup")
);
const ForgotPass = lazy(() =>
  import("../container/profile/authentication/overview/ForgotPassword")
);
// const NewPassword = lazy(() =>
//   import("../container/profile/authentication/overview/NewPassword")
// );

const paths = [
  "newPassword",
  "aboutus",
  "driver-termAndCondition",
  "driver-privacyPolicy",
  "rider-privacyPolicy",
  "rider-termAndCondition",
];

const NotFound = () => {
  const array = window.location.href.split("/");

  const found = paths.find(function (val) {
    return val === array[array.length - 1];
  });
  if (found) {
    return <Navigate to={`/${found}`} />;
  } else return <Navigate to={"/"} />;
};

const FrontendRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route exact path="/forgotPassword" element={<ForgotPass />} />
        <Route exact path="/register" element={<SignUp />} />
        <Route exact path="/newPassword" element={<NewPassword />} />
        <Route exact path="/aboutus" element={<AboutUsHtml />} />
        <Route
          exact
          path="/rider-termAndCondition"
          element={<TermAndConditionRider />}
        />
        <Route
          exact
          path="/rider-privacyPolicy"
          element={<PrivacyPolicyRiderHtml />}
        />
        <Route
          exact
          path="/driver-termAndCondition"
          element={<TermAndConditionDriver />}
        />
        <Route
          exact
          path="/driver-privacyPolicy"
          element={<PrivacyPolicyDriverHtml />}
        />
        <Route
          exact
          path="/customer-support-policy/"
          element={<CustomerSupport />}
        />

        <Route
          exact
          path="/refund-and-cancellation-policy/"
          element={<RefundPolicy />}
        />

        <Route exact path="/privacy-policy/" element={<Privacy />} />
        <Route exact path="/terms-of-service/" element={<Terms />} />

        <Route exact path="/" element={<Login />} />
        <Route exact path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

// export default AuthLayout(FrontendRoutes);
export default FrontendRoutes;
