import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Switch,
  Table,
  Tooltip,
} from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createQRType,
  deleteDocument,
  deleteQRType,
  getSettings,
  updateDocumentStatus,
  updateQRtype,
  updateSettngs,
} from "../../redux/Settings/actionCreatore";
import { checkAction } from "../../utility/commonFunction";

import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import Loader from "../loader/loader";
import useStateRef from "react-usestateref";
import { roundToNearestMinutes } from "date-fns";
import key1 from "../../static/img/key1.jpg";
import key2 from "../../static/img/key2.png";
import key3 from "../../static/img/key3.jpg";
import key5 from "../../static/img/key5.png";
import TextArea from "antd/lib/input/TextArea";

const { Panel } = Collapse;
const Settings = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [ModalTitle, setModalTitle] = useState("");
  const [updateFlow, setUpdateFlow, setUpdateFlowFileRef] = useStateRef("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const tootlTipText = "Authentication is not required for this url";
  const dispatch = useDispatch();
  const [saveLoader, setSaveLoader] = useState(false);
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   reteriveSettings();
  // }, []);

  const { data, isLoading } = useQuery(
    "settings",
    () => {
      return reteriveSettings();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const reteriveSettings = async () => {
    setLoading(true);
    const response = await dispatch(getSettings());
    if (response) {
      form.setFieldsValue(response);
      setLoading(false);
      return response;
    }
  };
  let settingData = [];
  settingData = useSelector((state) => state.setting.settingsData);

  const updateSetting = async () => {
    const data = form.getFieldsValue();
    let documents = [];
    let favouriteLocation = [];
    let walletQrType = [];
    data.placeRecentSearchCount = data.placeRecentSearchCount.toString();
    data.rideRequestIdeaTimeout = data.rideRequestIdeaTimeout.toString();
    data.superAdminSessionTimeOut = data.superAdminSessionTimeOut.toString();
    data.adminSessionTimeOut = data.adminSessionTimeOut.toString();
    data.captainSessionTimeOut = data.captainSessionTimeOut.toString();
    data.callCenterSessionTimeOut = data.callCenterSessionTimeOut.toString();
    data.driverPickupLocationConfirmationRadiusCheck =
      data.driverPickupLocationConfirmationRadiusCheck.toString();
    {
      settingData.requiredDocumentsForDriver.map((doc) => {
        documents.push({
          isActive: doc.isActive,
          documentType: doc.documentType,
        });
      });
    }
    {
      settingData.favoriteLocationMaster.map((doc) => {
        favouriteLocation.push({
          isAvailable: doc.isAvailable,
          locationName: doc.locationName,
          locationType: doc.locationType,
        });
      });
    }
    {
      settingData.qr_code_type.map((qr) => {
        walletQrType.push({
          isAvailable: qr.isAvailable,
          type: qr.type,
          wallet_name: qr.wallet_name,
        });
      });
    }
    data.requiredDocumentsForDriver = documents;
    data.favoriteLocationMaster = favouriteLocation;
    data.qr_code_type = walletQrType;
    setSaveLoader(true);
    const response = await dispatch(updateSettngs(data, settingData._id));
    if (response) {
      message.success("Setting saved successfully");
      reteriveSettings();
    }
    setSaveLoader(false);
  };

  const handleDocumentStatus = async (type, value) => {
    const payload = { documentType: type, isActive: value };
    const update = await dispatch(updateDocumentStatus(payload));
    if (update) {
      reteriveSettings();
    }
  };
  const handleDeleteDocument = async (type) => {
    const resp = await dispatch(deleteDocument(type));
    if (resp) {
      message.success("Document Deleted Successfully");
      reteriveSettings();
    }
  };
  const deleteQrDetailsFinal = async (id) => {
    let remove = await dispatch(deleteQRType(id));
    if (remove) {
      message.success("QR Deleted Successfully");

      reteriveSettings();
    }
  };
  const deleteQrDetails = (id) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this QR?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteQrDetailsFinal(id);
      },
    });
  };
  const columns = [
    {
      title: "Document Type",
      key: "type",
      render: (data) => {
        const string = data.documentType.split("_");

        return string.map((str) => (
          <span>{str.charAt(0).toUpperCase() + str.slice(1)} </span>
        ));
      },
    },
    {
      title: "Required",
      key: "required",
      render: (data) => {
        return (
          <span>
            <Switch
              defaultChecked={data.isActive}
              checkedChildren={"Yes"}
              unCheckedChildren={"No"}
              onChange={(e) => handleDocumentStatus(data.documentType, e)}
            />
          </span>
        );
      },
    },
    {
      title: t("action"),
      key: "acton",
      render: (data) => {
        return (
          <span>
            <Button
              type="primary"
              danger
              onClick={() => handleDeleteDocument(data.documentType)}
              disabled
            >
              {" "}
              <DeleteOutlined /> Delete
            </Button>
          </span>
        );
      },
    },
  ];

  const favouriteColumns = [
    {
      title: t("name"),
      key: "name",
      render: (data) => {
        return <span>{data?.locationName}</span>;
      },
    },
    {
      title: "Type",
      key: "type",
      render: (data) => {
        return <span>{data?.locationType}</span>;
      },
    },
    {
      title: "Available",
      key: "available",
      render: (data) => {
        return (
          <span>
            <Switch
              defaultChecked={data.isAvailable}
              checkedChildren={"Yes"}
              unCheckedChildren={"No"}
              // onChange={(e) => handleDocumentStatus(data.documentType, e)}
            />
          </span>
        );
      },
    },
    // {
    //   title: t("action"),
    //   key: "acton",
    //   render: (data) => {
    //     return (
    //       <span>
    //         <Button
    //           type="danger"
    //           onClick={() => handleDeleteDocument(data.documentType)}
    //           disabled
    //         >
    //           {" "}
    //           <DeleteOutlined /> Delete
    //         </Button>
    //       </span>
    //     );
    //   },
    // },
  ];

  const paymentColumn = [
    {
      title: "Wallet Name",
      key: "wallet_name",
      render: (data) => {
        // const string = data.type.split("_");
        // return <span>{data?.type}</span>;
        return (
          <span>{data?.wallet_name}</span>

          // <span>
          //   {data?.type.charAt(0).toUpperCase() + data?.type.slice(1)}{" "}
          // </span>
        );
      },
    },
    {
      title: "Type",
      key: "type",
      render: (data) => {
        // const string = data.type.split("_");
        // return <span>{data?.type}</span>;
        return <span>{data?.type}</span>;
      },
    },

    {
      title: "Required",
      key: "required",
      render: (data) => {
        return (
          <span>
            {/* <Switch
              defaultChecked={data.isAvailable}
              checkedChildren={"Yes"}
              unCheckedChildren={"No"}
              // onChange={(e) => handleDocumentStatus(data.documentType, e)}
            /> */}
            {data.isAvailable === true ? "Yes" : "No"}
          </span>
        );
      },
    },
    {
      title: t("action"),
      key: "acton",
      render: (data) => {
        return (
          <span>
            <Button
              // type="danger"
              onClick={() => editQrDetails(data)}
              // disabled
              style={{ marginRight: "5px" }}
            >
              {" "}
              <EditOutlined /> Edit
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => deleteQrDetails(data._id)}
              disabled
            >
              {" "}
              <DeleteOutlined /> Delete
            </Button>
          </span>
        );
      },
    },
  ];
  const handleSubmit = async (values) => {
    // setLoading(true);
    if (setUpdateFlowFileRef.current == "") {
      console.log(values);
      const payload = {
        wallet_name: values.wallet_name,
        type: values.type,
        isAvailable: values.isAvailable ? values.isAvailable : true,
      };

      const resp = await dispatch(createQRType(JSON.stringify(payload)));
      if (resp) {
        setIsModalOpen(false);
        reteriveSettings();
      }
    } else {
      const payload = {
        wallet_name: values.wallet_name,
        type: values.type,
        isAvailable: values.isAvailable,
      };
      // console.log(updateFlow);
      // payload.qrCodeTypeId = updateFlow;
      console.log(payload);
      const resp = await dispatch(updateQRtype(JSON.stringify(payload)));
      if (resp) {
        setIsModalOpen(false);
        setUpdateFlow("");
        reteriveSettings();
      }
    }
  };
  const editQrDetails = (data) => {
    setModalTitle("Edit Wallet Qr Details");
    setIsModalOpen(true);
    console.log(data);
    setUpdateFlow(data._id);

    form.resetFields();
    form.setFieldsValue({
      isAvailable: data.isAvailable,
      type: data.type,
      wallet_name: data.wallet_name,
    });
  };
  const showModal = () => {
    form.resetFields();
    setModalTitle("Add New Wallet QR");
    setIsModalOpen(true);

    // setSelectedId("")
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedId("")
    form.resetFields();
    // setSelectDep(0)
  };
  const paymentDataSource = [
    {
      type: "Paytm",
      isActive: true,
    },
    {
      type: "FreeCharge",
      isActive: true,
    },
    {
      type: "Mobikwik",
      isActive: true,
    },
  ];

  return (
    <>
      <div className="common_layout content-container ">
        {isLoading && <Loader />}
        {!isLoading && (
          <>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <h1 className="heading">Settings</h1>
                <BreadCrumb />
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                {checkAction("manageTips", "add") && (
                  <Button
                    style={{ float: "right" }}
                    className="ADD"
                    key="1"
                    size="large"
                    type="primary"
                    loading={saveLoader}
                    onClick={updateSetting}
                    // disabled
                  >
                    Save
                  </Button>
                )}
              </Col>
            </Row>
            <Divider />
            <Card>
              <Form layout="vertical" form={form}>
                <Collapse
                  defaultActiveKey={["1"]}
                  // onChange={onChange}
                >
                  <Panel
                    header="General Settings"
                    key={"1"}
                    style={{ background: "#77bff9" }}
                  >
                    <Row gutter={20}>
                      <Col xxl={6} lg={6}>
                        <Form.Item
                          label="Partners"
                          name={"valueAddedServicesMasterLink"}
                        >
                          <Input
                            type="text"
                            defaultValue={
                              settingData?.valueAddedServicesMasterLink
                            }
                            suffix={
                              <a
                                href={settingData?.valueAddedServicesMasterLink}
                                target="_blank"
                              >
                                {" "}
                                <img
                                  src="https://api.iconify.design/ci/external-link.svg"
                                  style={{ cursor: "pointer" }}
                                />{" "}
                              </a>
                            }
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col xxl={6} lg={6}>
                        <Form.Item
                          label="Searching Ride Auto Retry"
                          name={"auto_retry"}
                        >
                          <Input
                            type="number"
                            defaultValue={settingData?.auto_retry}
                            min={0}
                          />
                        </Form.Item>
                      </Col> */}
                      {/* <Col xxl={6} lg={6}>
                        <Form.Item
                          label="Web Role Image Upload File Size Limit (MB) "
                          name={"webRoleFileSizeLimit"}
                        >
                          <Input
                            type="number"
                            defaultValue={settingData?.webRoleFileSizeLimit}
                            min={0}
                          />
                        </Form.Item>
                      </Col> */}
                      <Col xxl={6} lg={6}>
                        <Form.Item
                          label="Vehicle Image Preview Height (Px) "
                          name={"vehicleImageHeight"}
                        >
                          <Input
                            type="number"
                            defaultValue={settingData?.vehicleImageHeight}
                            min={0}
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} lg={6}>
                        <Form.Item
                          label="Vehicle Image Preview Width (Px) "
                          name={"vehicleImageWidth"}
                        >
                          <Input
                            type="number"
                            defaultValue={settingData?.vehicleImageWidth}
                            min={0}
                          />
                        </Form.Item>
                      </Col>

                      <Col xxl={6} lg={6}>
                        <Form.Item label="Razorpay Key" name={"razorpayKey"}>
                          <Input
                            type="text"
                            defaultValue={settingData?.razorpayKey}
                            // min={0}
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} lg={6}>
                        <Form.Item
                          label="Payment Failed Retry Counts "
                          name={"paymentFailedRetryCount"}
                        >
                          <Input
                            type="number"
                            defaultValue={settingData?.paymentFailedRetryCount}
                            // min={0}
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} lg={6}>
                        <Form.Item
                          label="Ride Cancellation Commission of Admin (%) "
                          name={"cancellationCommissionAdmin"}
                        >
                          <Input
                            type="number"
                            defaultValue={
                              settingData?.cancellationCommissionAdmin
                            }
                            // min={0}
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} lg={6}>
                        <Form.Item
                          label="Ride Cancellation Commission of Rider/Driver (%) "
                          name={"cancellationCommissionDriverRider"}
                        >
                          <Input
                            type="number"
                            defaultValue={
                              settingData?.cancellationCommissionDriverRider
                            }
                            // min={0}
                          />
                        </Form.Item>
                      </Col>

                      {/* <Col xxl={6} lg={6}>
                        <Form.Item
                          label={
                            <div style={{ display: "contents" }}>
                              Privacy Policy{" "}
                              <Tooltip placement="top" title={tootlTipText}>
                                <img
                                  width={24}
                                  style={{ transform: "rotate(90deg)" }}
                                  src={key5}
                                />
                              </Tooltip>
                            </div>
                          }
                          // name={"privacyPolicyForDriver"}
                        >
                          <Input
                            type="text"
                            // defaultValue={
                            //   settingData?.privacyPolicyForDriver
                            // }
                            suffix={
                              <a
                                // href={settingData?.privacyPolicyForDriver}
                                target="_blank"
                              >
                                {" "}
                                <img
                                  src="https://api.iconify.design/ci/external-link.svg"
                                  style={{ cursor: "pointer" }}
                                />{" "}
                              </a>
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col xxl={6} lg={6}>
                        <Form.Item
                          label={
                            <div style={{ display: "contents" }}>
                              Term of Service{" "}
                              <Tooltip placement="top" title={tootlTipText}>
                                <img
                                  width={24}
                                  style={{ transform: "rotate(90deg)" }}
                                  src={key5}
                                />
                              </Tooltip>
                            </div>
                          }
                          // name={"privacyPolicyForDriver"}
                        >
                          <Input
                            type="text"
                            // defaultValue={
                            //   settingData?.privacyPolicyForDriver
                            // }
                            suffix={
                              <a
                                // href={settingData?.privacyPolicyForDriver}
                                target="_blank"
                              >
                                {" "}
                                <img
                                  src="https://api.iconify.design/ci/external-link.svg"
                                  style={{ cursor: "pointer" }}
                                />{" "}
                              </a>
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col xxl={6} lg={6}>
                        <Form.Item
                          label={
                            <div style={{ display: "contents" }}>
                              Customer Support Policy{" "}
                              <Tooltip placement="top" title={tootlTipText}>
                                <img
                                  width={24}
                                  style={{ transform: "rotate(90deg)" }}
                                  src={key5}
                                />
                              </Tooltip>
                            </div>
                          }
                          // name={"privacyPolicyForDriver"}
                        >
                          <Input
                            type="text"
                            // defaultValue={
                            //   settingData?.privacyPolicyForDriver
                            // }
                            suffix={
                              <a
                                // href={settingData?.privacyPolicyForDriver}
                                target="_blank"
                              >
                                {" "}
                                <img
                                  src="https://api.iconify.design/ci/external-link.svg"
                                  style={{ cursor: "pointer" }}
                                />{" "}
                              </a>
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col xxl={6} lg={6}>
                        <Form.Item
                          label={
                            <div style={{ display: "contents" }}>
                              Refund and Cancellation Policy{" "}
                              <Tooltip placement="top" title={tootlTipText}>
                                <img
                                  width={24}
                                  style={{ transform: "rotate(90deg)" }}
                                  src={key5}
                                />
                              </Tooltip>
                            </div>
                          }
                          // name={"privacyPolicyForDriver"}
                        >
                          <Input
                            type="text"
                            // defaultValue={
                            //   settingData?.privacyPolicyForDriver
                            // }
                            suffix={
                              <a
                                // href={settingData?.privacyPolicyForDriver}
                                target="_blank"
                              >
                                {" "}
                                <img
                                  src="https://api.iconify.design/ci/external-link.svg"
                                  style={{ cursor: "pointer" }}
                                />{" "}
                              </a>
                            }
                          />
                        </Form.Item>
                      </Col> */}
                    </Row>
                  </Panel>
                </Collapse>
                <br />
                <Row gutter={20}>
                  <Col xxl={12} lg={12} md={12}>
                    <Collapse
                      defaultActiveKey={["2"]}
                      accordion // onChange={onChange}
                    >
                      <Panel
                        header="Driver Required Documents For Admin Approval"
                        key="2"
                        style={{ background: "#77bff9" }}
                      >
                        {/* <Card> */}

                        <div style={{ border: "1px solid black" }}>
                          <Form.Item name="requiredDocumentsForDriver">
                            <Table
                              columns={columns}
                              dataSource={
                                settingData?.requiredDocumentsForDriver
                              }
                              scroll={{ x: "45vh" }}
                              // dataSource={[]}

                              pagination={false}
                            />
                          </Form.Item>

                          {/* <Divider /> */}
                        </div>
                        {/* </Card> */}
                      </Panel>
                    </Collapse>
                  </Col>
                  <Col xxl={12} lg={12} md={12}>
                    <Collapse
                      defaultActiveKey={["5"]}
                      accordion // onChange={onChange}
                    >
                      <Panel
                        header="Default Favourite Locations for Rider"
                        key="5"
                        style={{ background: "#77bff9" }}
                      >
                        <div style={{ border: "1px solid black" }}>
                          <Form.Item name="defaultFavouriteLocations">
                            <Table
                              columns={favouriteColumns}
                              dataSource={settingData?.favoriteLocationMaster}
                              // dataSource={[]}
                              pagination={false}
                            />
                          </Form.Item>
                        </div>
                      </Panel>
                    </Collapse>
                  </Col>
                  {/* <Button style={{float:"right"}} type="primary" ><PlusOutlined /> Add New</Button> */}
                </Row>

                <br />
                <Row>
                  <Col xxl={24} lg={24}>
                    <Collapse
                      defaultActiveKey={["4"]}
                      accordion // onChange={onChange}
                    >
                      <Panel
                        header="Session Management"
                        key="4"
                        style={{ background: "#77bff9" }}
                      >
                        <Row gutter={20}>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Super Admin Session TimeOut (Minutes)"
                              name={"superAdminSessionTimeOut"}
                            >
                              <Input
                                type="number"
                                defaultValue={
                                  settingData?.superAdminSessionTimeOut
                                }
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Admin Session TimeOut (Minutes)"
                              name="adminSessionTimeOut"
                            >
                              <Input
                                type="number"
                                defaultValue={settingData?.adminSessionTimeOut}
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Call Center Session TimeOut (Minutes)"
                              name="callCenterSessionTimeOut"
                            >
                              <Input
                                type="number"
                                defaultValue={
                                  settingData?.callCenterSessionTimeOut
                                }
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Captain Session TimeOut (Minutes)"
                              name="captainSessionTimeOut"
                            >
                              <Input
                                type="number"
                                defaultValue={
                                  settingData?.captainSessionTimeOut
                                }
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col xxl={24} lg={24}>
                    <Collapse
                      defaultActiveKey={["4"]}
                      accordion // onChange={onChange}
                    >
                      <Panel
                        header="Billing Management"
                        key="4"
                        style={{ background: "#77bff9" }}
                      >
                        <Row gutter={20}>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Driver IGST (%)"
                              name="driver_IGST"
                            >
                              <Input
                                type="number"
                                defaultValue={settingData?.driver_IGST}
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Driver CGST (%)"
                              name={"driver_CGST"}
                            >
                              <Input
                                type="number"
                                defaultValue={settingData?.driver_CGST}
                                min={0}
                              />
                            </Form.Item>
                          </Col>

                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Driver SGST (%)"
                              name="driver_SGST"
                            >
                              <Input
                                type="number"
                                defaultValue={settingData?.driver_SGST}
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Driver SAC Code"
                              name="driver_SACCode"
                            >
                              <Input
                                type="number"
                                defaultValue={settingData?.driver_SACCode}
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Driver Sevice Tax Category"
                              name="driver_SeviceTaxCategory"
                            >
                              <Input
                                type="text"
                                defaultValue={
                                  settingData?.driver_SeviceTaxCategory
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Super Admin IGST (%)"
                              name="superAdmin_IGST"
                            >
                              <Input
                                type="number"
                                defaultValue={settingData?.superAdmin_IGST}
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Super Admin CGST (%)"
                              name={"superAdmin_CGST"}
                            >
                              <Input
                                type="number"
                                defaultValue={settingData?.superAdmin_CGST}
                                min={0}
                              />
                            </Form.Item>
                          </Col>

                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Super Admin SGST (%)"
                              name="superAdmin_SGST"
                            >
                              <Input
                                type="number"
                                defaultValue={settingData?.superAdmin_SGST}
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Super Admin SAC Code"
                              name="superAdmin_SACCode"
                            >
                              <Input
                                type="number"
                                defaultValue={settingData?.superAdmin_SACCode}
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Super Admin Sevice Tax Category"
                              name="superAdmin_SeviceTaxCategory"
                            >
                              <Input
                                type="text"
                                defaultValue={
                                  settingData?.superAdmin_SeviceTaxCategory
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Super Admin GSTIN"
                              name="superAdminGSTIN"
                            >
                              <Input
                                type="text"
                                defaultValue={settingData?.superAdminGSTIN}
                              />
                            </Form.Item>
                          </Col>

                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Service Tax (%)"
                              name="serviceTax"
                            >
                              <Input
                                type="number"
                                defaultValue={settingData?.serviceTax}
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item label="Invoice Logo" name="invoiceLogo">
                              <Input
                                type="text"
                                defaultValue={settingData?.invoiceLogo}
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Service Provider Registered Address"
                              name="serviceProviderRegisteredAddress"
                            >
                              <TextArea
                                type="text"
                                defaultValue={
                                  settingData?.serviceProviderRegisteredAddress
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Super Admin Invoice Terms And Conditions"
                              name="superAdmin_InvoiceTermsAndConditions"
                            >
                              <TextArea
                                type="text"
                                defaultValue={
                                  settingData?.superAdmin_InvoiceTermsAndConditions
                                }
                              />
                            </Form.Item>
                          </Col>

                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Driver Invoice Terms And Conditions"
                              name="driver_InvoiceTermsAndConditions"
                            >
                              <TextArea
                                type="text"
                                defaultValue={
                                  settingData?.driver_InvoiceTermsAndConditions
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col xxl={24} lg={24}>
                    <Collapse
                      defaultActiveKey={["4"]}
                      accordion // onChange={onChange}
                    >
                      <Panel
                        header="Driver App Configuration"
                        key="4"
                        style={{ background: "#77bff9" }}
                      >
                        <Row gutter={20}>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Android App Version"
                              name={"androidVersionForDriver"}
                            >
                              <Input
                                type="text"
                                defaultValue={
                                  settingData?.androidVersionForDriver
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Android App Force Update"
                              name="androidForceUpdateForDriver"
                            >
                              {/* <Input
                            // type="number"
                            defaultValue={settingData?.androidForceUpdate}
                          /> */}
                              <Switch
                                defaultChecked={
                                  settingData?.androidForceUpdateForDriver
                                    ? true
                                    : false
                                }
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                              ></Switch>
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Ios App Version"
                              name="iosVersionForDriver"
                            >
                              <Input
                                // type="number"
                                defaultValue={settingData?.iosVersionForDriver}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Ios App Force Update"
                              name="iosForceUpdateForDriver"
                            >
                              <Switch
                                defaultChecked={
                                  settingData?.iosForceUpdateForDriver
                                    ? true
                                    : false
                                }
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                              ></Switch>
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Driver Current Location Event Emit Interval"
                              name={"driverLocationEventInterval"}
                            >
                              <Input
                                type="number"
                                defaultValue={
                                  settingData?.driverLocationEventInterval
                                }
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Driver Validity Once Approved By Admin (Months)"
                              name="driver_valid_till"
                            >
                              <Input
                                type="number"
                                defaultValue={settingData?.driver_valid_till}
                                min={0}
                              />
                            </Form.Item>
                          </Col>

                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Schedule Ride Driver Search and Assign Before Time (Minutes)"
                              name="assignDriverTime"
                            >
                              <Input
                                type="number"
                                defaultValue={settingData?.assignDriverTime}
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Pickup Location OTP Confirmation Allowed within Radius for Driver (Meters)"
                              name="driverPickupLocationConfirmationRadiusCheck"
                            >
                              <Input
                                type="number"
                                defaultValue={
                                  settingData?.driverPickupLocationConfirmationRadiusCheck
                                }
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Insurance Expire Notification Before Expiry Date (Days)"
                              name="insuranceExpCheckDays"
                            >
                              <Input
                                type="number"
                                defaultValue={
                                  settingData?.insuranceExpCheckDays
                                }
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Trip Request Accept/Decline Ideal Timeout (Seconds)"
                              name="rideRequestIdeaTimeout"
                            >
                              <Input
                                type="number"
                                defaultValue={
                                  settingData?.rideRequestIdeaTimeout
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Ride Expire Time (Seconds)"
                              name="rideExpireTime"
                            >
                              <Input
                                type="number"
                                defaultValue={settingData?.rideExpireTime}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label={
                                <div>
                                  Terms and Condition For Driver{" "}
                                  <Tooltip placement="top" title={tootlTipText}>
                                    <img
                                      width={24}
                                      style={{ transform: "rotate(90deg)" }}
                                      src={key5}
                                    />{" "}
                                  </Tooltip>
                                </div>
                              }
                              name={"t$cForDriver"}
                            >
                              <Input
                                type="text"
                                defaultValue={settingData?.t$cForDriver}
                                suffix={
                                  <a
                                    href={settingData?.t$cForDriver}
                                    target="_blank"
                                  >
                                    <img
                                      src="https://api.iconify.design/ci/external-link.svg"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </a>
                                }
                              />
                            </Form.Item>
                          </Col>

                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label={
                                <div>
                                  Privacy Policies For Driver{" "}
                                  <Tooltip placement="top" title={tootlTipText}>
                                    <img
                                      width={24}
                                      style={{ transform: "rotate(90deg)" }}
                                      src={key5}
                                    />
                                  </Tooltip>
                                </div>
                              }
                              name={"privacyPolicyForDriver"}
                            >
                              <Input
                                type="text"
                                defaultValue={
                                  settingData?.privacyPolicyForDriver
                                }
                                suffix={
                                  <a
                                    href={settingData?.privacyPolicyForDriver}
                                    target="_blank"
                                  >
                                    {" "}
                                    <img
                                      src="https://api.iconify.design/ci/external-link.svg"
                                      style={{ cursor: "pointer" }}
                                    />{" "}
                                  </a>
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Support Number For Driver"
                              name="supportNumberForDriver"
                            >
                              <Input
                                defaultValue={
                                  settingData?.supportNumberForDriver
                                }
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>
                          {/* <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Image Upload File Size Limit (MB)"
                              name="driverFileSizeLimit"
                            >
                              <Input
                                defaultValue={settingData?.driverFileSizeLimit}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col> */}
                          {/* <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Maximum Rides Limit In An Hour"
                              name="driverMaxRideLimit"
                            >
                              <Input
                                defaultValue={settingData?.driverMaxRideLimit}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col> */}
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Invoive Prefix"
                              name="driverInvoicePrefix"
                            >
                              <Input
                                defaultValue={settingData?.driverInvoicePrefix}
                                type="text"
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Invoice Sequence Number"
                              name="driverInvoiceSequenceNumber"
                            >
                              <Input
                                defaultValue={
                                  settingData?.driverInvoiceSequenceNumber
                                }
                                type={"text"}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Driver Wallet Threshold Amount "
                              name="minimumWalletAmountForCashRide"
                            >
                              <Input
                                prefix={<div>₹</div>}
                                defaultValue={
                                  settingData?.minimumWalletAmountForCashRide
                                }
                                type={"number"}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col xxl={24} xl={24} lg={24} sm={24}>
                            <Collapse
                              defaultActiveKey={["2"]}
                              accordion={true}
                              collapsible="icon"
                              // onChange={onChange}
                            >
                              <Panel
                                header="Wallet QR Upload Options"
                                key="2"
                                // style={{ background: "#77bff9" }}
                                extra={
                                  <PlusCircleOutlined
                                    onClick={showModal}
                                    color="white"
                                  />
                                }
                              >
                                {/* <Card> */}

                                <div style={{ border: "1px solid black" }}>
                                  <Form.Item name="qr_code_type">
                                    <Table
                                      columns={paymentColumn}
                                      dataSource={settingData?.qr_code_type}
                                      scroll={{ y: "48vh" }}
                                      // dataSource={[]}

                                      pagination={false}
                                    />
                                  </Form.Item>

                                  {/* <Divider /> */}
                                </div>
                                {/* </Card> */}
                              </Panel>
                            </Collapse>
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col xxl={24} lg={24}>
                    <Collapse
                      defaultActiveKey={["6"]}
                      accordion // onChange={onChange}
                    >
                      <Panel
                        header="Rider App Configuration"
                        key="6"
                        style={{ background: "#77bff9" }}
                      >
                        <Row gutter={20}>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Android App Version"
                              name={"androidVersionForRider"}
                            >
                              <Input
                                type="text"
                                defaultValue={
                                  settingData?.androidVersionForRider
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Android App Force Update"
                              name="androidForceUpdateForRider"
                            >
                              {/* <Input
                            // type="number"
                            defaultValue={settingData?.androidForceUpdate}
                          /> */}
                              <Switch
                                defaultChecked={
                                  settingData?.androidForceUpdateForRider
                                    ? true
                                    : false
                                }
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                              ></Switch>
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Ios App Version"
                              name="iosVersionForRider"
                            >
                              <Input
                                // type="number"
                                defaultValue={settingData?.iosVersionForRider}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Ios App Force Update"
                              name="iosForceUpdateForRider"
                            >
                              <Switch
                                defaultChecked={
                                  settingData?.iosForceUpdateForRider
                                    ? true
                                    : false
                                }
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                              ></Switch>
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Rider App Recent Place Searching Counts"
                              name="placeRecentSearchCount"
                            >
                              <Input
                                type="number"
                                defaultValue={
                                  settingData?.placeRecentSearchCount
                                }
                              />
                            </Form.Item>
                          </Col>

                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Rider Pickup Location Difference Charges (Rs/Meters) "
                              name="pickupLocationDifferenceCharges"
                            >
                              <Input
                                type="number"
                                defaultValue={
                                  settingData?.pickupLocationDifferenceCharges
                                }
                              />
                            </Form.Item>
                          </Col>

                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Rider App Max Place Searches Allowed in an Hour"
                              name="maxSearchesAllowed"
                            >
                              <Input
                                type="number"
                                defaultValue={settingData?.maxSearchesAllowed}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Get Near By Drivers Within Radius (Meters)"
                              name="getNearbyDriversOnMapRadius"
                            >
                              <Input
                                type="number"
                                defaultValue={
                                  settingData?.getNearbyDriversOnMapRadius
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Allowed Max Pickup Location Difference for a Rider(Meters) "
                              // label={
                              //   <div>
                              //     Allowed Max Pickup Location Difference for a
                              //     Rider(Meters){" "}
                              //     <img width={30} style={{}} src={key3} />{" "}
                              //   </div>
                              // }
                              name="maxPickupLocationDifference"
                            >
                              <Input
                                type="number"
                                defaultValue={
                                  settingData?.maxPickupLocationDifference
                                }
                              />
                            </Form.Item>
                          </Col>

                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Intercity Fare Multiply"
                              name="intercityFareMultiply"
                            >
                              <Input
                                type="number"
                                defaultValue={
                                  settingData?.intercityFareMultiply
                                }
                              />
                            </Form.Item>
                          </Col>

                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label={
                                <div>
                                  Terms and Condition For Rider{" "}
                                  <Tooltip placement="top" title={tootlTipText}>
                                    <img
                                      width={24}
                                      style={{ transform: "rotate(90deg)" }}
                                      src={key5}
                                    />{" "}
                                  </Tooltip>
                                </div>
                              }
                              name={"t$cForRider"}
                            >
                              <Input
                                type="text"
                                defaultValue={settingData?.t$cForRider}
                                suffix={
                                  <a
                                    href={settingData?.t$cForRider}
                                    target="_blank"
                                  >
                                    {" "}
                                    <img
                                      src="https://api.iconify.design/ci/external-link.svg"
                                      style={{ cursor: "pointer" }}
                                    />{" "}
                                  </a>
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label={
                                <div>
                                  Privacy Policies For Rider{" "}
                                  <Tooltip placement="top" title={tootlTipText}>
                                    <img
                                      width={24}
                                      style={{ transform: "rotate(90deg)" }}
                                      src={key5}
                                    />{" "}
                                  </Tooltip>
                                </div>
                              }
                              name={"privacyPolicyForRider"}
                            >
                              <Input
                                type={"url"}
                                defaultValue={
                                  settingData?.privacyPolicyForRider
                                }
                                suffix={
                                  <a
                                    href={settingData?.privacyPolicyForRider}
                                    target="_blank"
                                  >
                                    {" "}
                                    <img
                                      src="https://api.iconify.design/ci/external-link.svg"
                                      style={{ cursor: "pointer" }}
                                    />{" "}
                                  </a>
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Support Number For Rider"
                              name="supportNumberForRider"
                            >
                              <Input
                                maxLength={10}
                                defaultValue={
                                  settingData?.supportNumberForRider
                                }
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Retry Socket Expire time (seconds)"
                              // label={
                              //   <div>
                              //     Allowed Max Pickup Location Difference for a
                              //     Rider(Meters){" "}
                              //     <img width={30} style={{}} src={key3} />{" "}
                              //   </div>
                              // }
                              name="retryTimeout"
                            >
                              <Input
                                type="number"
                                defaultValue={settingData?.retryTimeout}
                              />
                            </Form.Item>
                          </Col>
                          {/* <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Image Upload File Size Limit (MB)"
                              name="riderFileSizeLimit"
                            >
                              <Input
                                type="number"
                                defaultValue={settingData?.riderFileSizeLimit}
                              />
                            </Form.Item>
                          </Col> */}
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Invoive Prefix"
                              name="riderInvoicePrefix"
                            >
                              <Input
                                defaultValue={settingData?.riderInvoicePrefix}
                                type="text"
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={6} lg={6}>
                            <Form.Item
                              label="Invoice Sequence Number"
                              name="riderInvoiceSequenceNumber"
                            >
                              <Input
                                defaultValue={
                                  settingData?.riderInvoiceSequenceNumber
                                }
                                type={"text"}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
              </Form>
            </Card>
            <Modal
              title={ModalTitle}
              open={isModalOpen}
              onOk={form.submit}
              closable={true}
              maskClosable={false}
              onCancel={handleCancel}
              // okText={updateFlow ? "Update" : "Add"}
            >
              <Form form={form} onFinish={handleSubmit} layout="vertical">
                <Form.Item
                  name="type"
                  label={"Type"}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Type of QR",
                      // pattern: new RegExp(/[^\s]+\s*$/i),
                    },
                  ]}
                >
                  <Input type="text" placeholder="Enter Type of QR" />
                </Form.Item>
                <Form.Item
                  name="wallet_name"
                  label={"Wallet Name"}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Name of Wallet",
                      // pattern: new RegExp(/[^\s]+\s*$/i),
                    },
                  ]}
                >
                  <Input type="text" placeholder="Enter Name of Wallet" />
                </Form.Item>
                <Form.Item name="isAvailable" label={"Required"}>
                  <Radio.Group defaultValue={true}>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Modal>
          </>
        )}
      </div>
    </>
  );
};

export default Settings;
