const actions = {
  GET_SMS_TEMPLATE_DB_LIST: "GET_SMS_TEMPLATE_DB_LIST",
  GET_SMS_TEMPLATE_LIST:"GET_SMS_TEMPLATE_LIST",
  
  GET_TEMPLATES_LIST:"GET_TEMPLATES_LIST",


  fetchSmsTemplateDbList: (data) => {
    return {
      type: actions.GET_SMS_TEMPLATE_DB_LIST,
      data,
    };
  },
  fetchSmsTemplateList:(data) => {
    return {
      type: actions.GET_SMS_TEMPLATE_LIST,
      data,
    };
  },
  fetchTemplatesList:(data) => {
    return {
      type: actions.GET_TEMPLATES_LIST,
      data,
    };
  },
};

export default actions;
