import { Button, Card, Col, Divider, Input, Row } from "antd";
import FeatherIcon from "feather-icons-react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPartnersDataById } from "../../redux/BeOurPartners/actionCreatore";
import { getItem } from "../../utility/localStorageControl";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
import Loader from "../loader/loader";
import { SocketContext } from "../socket/socketContext";

const BeOurParterEdit = () => {
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const socket = useContext(SocketContext);
  const { t } = useTranslation();
  const user = getItem("user_details");

  useEffect(() => {
    getBeoOurPartnerData();
  }, []);

  const getBeoOurPartnerData = async () => {
    setLoading(true);
    const resp = await dispatch(getPartnersDataById(id));
    if (resp) {
      setLoading(false);
    }
  };

  const partnerData = useSelector((state) => state.partner.partnerData);
  // console.log("partnerData", partnerData);

  useEffect(() => {
    if (socket) {
  

      socket.emit("readRecord", {
        userId: user._id,
        recordType: "be_our_affiliate_partner",
        captainRequestId: id,
      });

      socket.on("recordReaded", (data) => {
        if (data.isRead) {
          socket.emit("getUnreadRecordCount", { userId: user._id });

          socket.on("unreadRecordCount", (data) => {
            console.log("data", data);

            // setLocation(data.location.coordinates);

            // setLoading(false)
          });
        }
      });
    }
  }, [socket]);

  return (
    <>
      <div className="common_layout content-container">
        {!loading && (
          <>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <h1 className="heading">Captain Request</h1>
              <BreadCrumb detailpage={partnerData?.name}   />
                

              </Col>
              {/* <Col lg={12} md={12} sm={12} xs={12}>
                <Button
                  style={{ marginLeft: "12px", float: "right" }}
                  className="ADD"
                  key="1"
                  size="large"
                  type="danger"
                  // onClick={() => {
                  //   setRejectModal(true), setRejectTitle("Driver Reject");
                  // }}
                >
                  Reject
                </Button>
                <Button
                  style={{ float: "right", background: "green" }}
                  className="ADD"
                  key="1"
                  size="large"
                  type="primary"
                  // onClick={() => {
                  //   setIsModalOpen(true),
                  //     form.setFieldsValue({ name: driverData?.name });
                  // }}
                  // onClick={() => {
                  //   setDisable(!disable);
                  // }}
                  // disabled={disableApprove}
                >
                  Approve
                </Button>
              </Col> */}
            </Row>
            <Divider />
            <Card
              style={{ minHeight: "400px" }}
              title={
                <>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={24}>
                      Captain Details
                    </Col>
                    {/* <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={24}
                      // style={{ color: "white" }}
                    >
                      <Button
                        style={{ float: "right" }}
                        key="1"
                        // type="primary"
                        onClick={() => {
                          setDisable(!disable);
                        }}
                      >
                        <EditOutlined />
                        Edit Details
                      </Button>
                    </Col> */}
                  </Row>
                </>
              }
            >
              {/* <Row gutter={20} style={{ marginBottom: "30px" }}>
          <Col xxl={4}>
            <Card style={{ border: "none" }}>
              <Avatar
                size={180}
                icon={
                  driverData && driverData.profile_image ? (
                    <img
                      src={driverData.profile_image}
                      // crossOrigin="anonymous"
                    />
                  ) : (
                    <UserOutlined />
                  )
                }
              />
            </Card>
          </Col>
        </Row> */}
              <Row gutter={[20, 20]} style={{ marginBottom: "30px" }}>
                <Col xxl={8} lg={8} md={8} xs={24}>
                  <div>
                    <label className="userlabel"> Name</label>
                    <Input
                      defaultValue={partnerData?.name}
                      disabled={disable}
                      style={{
                        background: "white",
                        cursor: "default",
                        fontWeight: "bold",
                        color: "#4f5d73",
                      }}
                      prefix={<FeatherIcon icon="user" />}
                    />
                  </div>
                </Col>
                <Col xxl={8} lg={8} md={8} xs={24}>
                  <div>
                    <label className="userlabel">Interested City </label>
                    <Input
                      defaultValue={partnerData?.interestedForCity}
                      disabled={disable}
                      style={{
                        background: "white",
                        cursor: "default",
                        fontWeight: "bold",
                        color: "#4f5d73",
                      }}
                      prefix={<FeatherIcon icon="map-pin" />}
                    />
                  </div>
                </Col>
                <Col xxl={8} lg={8} md={8} xs={24}>
                  <div>
                    <label className="userlabel">{t("phoneNo")}</label>
                    <Input
                      defaultValue={partnerData?.number}
                      disabled={disable}
                      style={{
                        background: "white",
                        cursor: "default",
                        fontWeight: "bold",
                        color: "#4f5d73",
                      }}
                      prefix={<FeatherIcon icon="phone" />}
                    />
                  </div>
                </Col>
              </Row>
              <Row gutter={[20, 20]} style={{ marginBottom: "30px" }}>
                <Col xxl={8} lg={8} md={8} xs={24}>
                  <div>
                    <label className="userlabel">Email </label>
                    <Input
                      defaultValue={partnerData?.email}
                      disabled={disable}
                      style={{
                        background: "white",
                        cursor: "default",
                        fontWeight: "bold",
                        color: "#4f5d73",
                      }}
                      prefix={<FeatherIcon icon="mail" />}
                    />
                  </div>
                </Col>
                <Col xxl={12} lg={12} md={8} xs={24}>
                  <div>
                    <label className="userlabel">Address </label>
                    <Input.TextArea
                      defaultValue={partnerData?.address}
                      disabled={disable}
                      style={{
                        background: "white",
                        cursor: "default",
                        fontWeight: "bold",
                        color: "#4f5d73",
                      }}
                      type="textarea"
                      prefix={<FeatherIcon icon="navigation" />}
                    />
                  </div>
                </Col>
                <Col xxl={4} lg={8} md={8} xs={24}>
                  {!disable && (
                    <Button
                      onClick={() => {
                        setDisable(true);
                      }}
                      style={{ float: "right", marginTop: "40px" }}
                      type="primary"
                    >
                      Sava Changes
                    </Button>
                  )}
                </Col>
              </Row>
            </Card>
          </>
        )}
      </div>
      {loading && <Loader />}
    </>
  );
};

export default BeOurParterEdit;
