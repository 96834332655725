const actions={
    GET_ROLE:'GET_ROLE',
    CREATE_ROLE:'CREATE_ROLE',
    SET_ROLE:'SET_ROLE',
    DELETE_ROLE:'DELETE_ROLE',
    UPDATE_ROLE:'UPDATE_ROLE',
  
  
    getRole: data => {
        return {
          type: actions.GET_ROLE,
          data,
        };
      },
      setRole: data => {
        return {
          type: actions.SET_ROLE,
          data,
        };
      },
      createRole: data => {
        return {
          type: actions.CREATE_ROLE,
          data,
        };
      },
      updateRole: data => {
        return {
          type: actions.UPDATE_ROLE,
          data,
        };
      },
      deleteRole: data => {
        return {
          type: actions.DELETE_ROLE,
          data,
        };
      },
  };
  
  
  export default actions;
  