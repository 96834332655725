import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Rate,
  Row,
  Select,
  Switch,
  Table,
  Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStateRef from "react-usestateref";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import TabPane from "antd/lib/tabs/TabPane";
import { useTranslation } from "react-i18next";

import { useQuery } from "react-query";
import {
  addRatingTags,
  getRatingTagsData,
  removeRatingTags,
  updateRatingTags,
} from "../../redux/Masters/feedbackOptions/actionCreatore";
import { checkAction } from "../../utility/commonFunction";
import BreadCrumb from "../BreadCrumb.js/BreadCrumb";
const RatingTags = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [ModalTitle, setModalTitle] = useState("");
  const [updateFlow, setUpdateFlow, setUpdateFlowFileRef] = useStateRef("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [status, setStatus] = useState("");
  const [rating, setRating] = useState("");

  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [activeKey, setActiveKey] = useState("1");
  const [role, setRole] = useState("rider");

  //  const [tableLoading,setTableLoading]=useState(false)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let payload = {
    role,
    status: "",
    rating: "",
  };
  const { data, isLoading } = useQuery(
    "feedbackOption",
    () => fetchFeedbackRatingTags(payload),
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchFeedbackRatingTags = async (payload) => {
    await dispatch(getRatingTagsData(payload));
  };

  const feedbackOptions = useSelector((state) => state.ratingTags.ratinngTags);
  // console.log("ratingTags", feedbackOptions);
  const deleteRatingTagFinal = async (id) => {
    setLoading(true);

    let remove = await dispatch(removeRatingTags(id));
    if (remove) {
      message.success("Rating Tag deleted successfully");
      fetchFeedbackRatingTags(payload);
    }
  };
  const deleteRatingTag = (id) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this Rating Tag?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteRatingTagFinal(id);
      },
    });
  };
  const editRatingTag = (data) => {
    setModalTitle(
      `Edit Rating Tag For ${activeKey == "1" ? "Rider" : "Driver"} `
    );
    setIsModalOpen(true);
    setUpdateFlow(data._id);
    // console.log(data);

    form.resetFields();
    form.setFieldsValue({
      tag: data.tag,
      rating: data.rating,
      status: data.status,
    });
  };
  const handleSubmit = async (values) => {
    // setLoading(true);

    values.tag = values.tag.trim();
    values.role = role;
    console.log(values);
    if (setUpdateFlowFileRef.current == "") {
      const resp = await dispatch(addRatingTags(JSON.stringify(values)));
      if (resp) {
        message.success("Feedback added successfully");
        setIsModalOpen(false);
        fetchFeedbackRatingTags(payload);
      }
    } else {
      const resp = await dispatch(
        updateRatingTags(JSON.stringify(values), updateFlow)
      );
      if (resp) {
        message.success("Feedback updated successfully");
        setIsModalOpen(false);
        fetchFeedbackRatingTags(payload);
        setUpdateFlow("");
      }
    }
  };

  // const updateStatus = async (id, value) => {
  //   const resp = await dispatch(
  //     updateFeedbackOptions(
  //       { is_active: value == true ? "active" : "deactive" },
  //       id
  //     )
  //   );
  //   if (resp) {
  //     message.success("Rating Tag updated successfully");
  //     fetchFeedbackRatingTags(payload);
  //   }
  // };

  const handleStatusFilter = async (value) => {
    setStatus(value);
    payload.status = value;
    fetchFeedbackRatingTags(payload);
  };
  const handleRatingFilter = async (value) => {
    setRating(value);
    payload.rating = value;
    fetchFeedbackRatingTags(payload);
  };

  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      align: "center",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },
    {
      title: "Rating Tag Title",
      align: "center",

      render: (data, record) => {
        return <span>{data && data?.tag ? data?.tag : "-  "}</span>;
      },
    },
    {
      title: "Rating",
      align: "center",

      render: (data, record) => {
        return <span>{<Rate disabled value={data?.rating} />}</span>;
      },
    },
    {
      title: "Status",
      align: "center",

      render: (data, record) => {
        return (
          <Switch
            checkedChildren={"Active"}
            unCheckedChildren={"Deactive"}
            checked={data?.status == "active" ? true : false}
            // onChange={(value) => updateStatus(data._id, value)}
          />
        );
      },
    },
    {
      title: t("action"),
      // dataIndex: "documents",
      key: "action",
      align: "center",

      render(data, record) {
        return (
          <div>
            {/* {console.log("data", data)} */}

            <Button
              onClick={() => editRatingTag(data)}
              type="primary"
              // style={{ cursor: "pointer", color: "blue" }}
            >
              <EditOutlined />
              Edit
            </Button>

            <Button
              onClick={() => deleteRatingTag(data._id)}
              style={{ cursor: "pointer", marginLeft: 2 }}
              type="primary"
              danger
              disabled
            >
              <DeleteOutlined />
              Delete
            </Button>
          </div>
        );
      },
    },
  ];
  const showModal = () => {
    form.resetFields();
    setModalTitle(
      `Add New Rating Tag For ${activeKey == 1 ? "Rider" : "Driver"}`
    );
    setIsModalOpen(true);

    // setSelectedId("")
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedId("")
    form.resetFields();
    setUpdateFlow("");
    // setSelectDep(0)
  };

  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);

    fetchFeedbackRatingTags(payload);
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    fetchFeedbackRatingTags(payload);
  };

  const handleTabClick = async (key) => {
    setActiveKey(key);

    setLoading(true);
    setRole("rider");
    if (key == "1") {
      fetchFeedbackRatingTags(payload);

      payload.role = "rider";
      fetchFeedbackRatingTags(payload);
    }
    if (key == "2") {
      setRole("driver");

      payload.role = "driver";
      fetchFeedbackRatingTags(payload);
    }
  };

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading">Rating Tags</h1>
            <BreadCrumb />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            {checkAction("deleteProfile", "add") && (
              <Button
                style={{ float: "right" }}
                className="ADD"
                key="1"
                size="large"
                type="primary"
                onClick={showModal}
              >
                <PlusOutlined /> Add New
              </Button>
            )}
          </Col>
        </Row>
        <Divider />
        <Tabs type="card" activeKey={activeKey} onTabClick={handleTabClick}>
          <TabPane key="1" tab="Rider">
            <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col lg={4} className="total">
                  Total {feedbackOptions?.length}
                </Col>
                <Col lg={20} md={24} sm={24} xs={24}>
                  <div
                    className="product-list-action d-flex justify-content-between align-items-center"
                    style={{ float: "right", margin: "0 10px" }}
                  >
                    <Select
                      placeholder="Choose Status"
                      style={{ width: "10vw", marginLeft: "10px" }}
                      filterOption={false}
                      allowClear
                      onClear={(e) => handleStatusFilter("")}
                      onSelect={(e) => handleStatusFilter(e)}
                    >
                      <Select.Option
                        value={"active"}
                        key={"active"}
                        className="selectOptions"
                      >
                        Active
                      </Select.Option>

                      <Select.Option
                        value={"inactive"}
                        key={"inactive"}
                        className="selectOptions"
                      >
                        Inactive
                      </Select.Option>
                    </Select>

                    <Select
                      placeholder="Choose Rating"
                      style={{ width: "10vw", marginLeft: "20px" }}
                      filterOption={false}
                      allowClear
                      onClear={() => handleRatingFilter("")}
                      onSelect={(value) => handleRatingFilter(value)}
                    >
                      {/* <Select.Option value={0} ><Rate value={0} disabled /></Select.Option> */}
                      <Select.Option value={1}>
                        <Rate value={1} disabled />
                      </Select.Option>
                      <Select.Option value={2}>
                        <Rate value={2} disabled />
                      </Select.Option>
                      <Select.Option value={3}>
                        <Rate value={3} disabled />
                      </Select.Option>
                      <Select.Option value={4}>
                        <Rate value={4} disabled />
                      </Select.Option>
                      <Select.Option value={5}>
                        <Rate value={5} disabled />
                      </Select.Option>
                    </Select>
                  </div>
                </Col>
              </Row>
            </Card>

            <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                dataSource={feedbackOptions}
                columns={columns}
                searchable
                scroll={{ x: "45vh" }}
                loading={isLoading}
                pagination={false}
                // pagination={{
                //   showSizeChanger: true,
                //   pageSize: pageSize,
                //   total: feedbackOptions?.total_counts,
                //   onShowSizeChange: onShowSizeChange,
                //   onChange: onPageChange,
                //   pageSizeOptions: ["10", "30", "50"],
                // }}
              />
            </Card>
          </TabPane>
          <TabPane key="2" tab="Driver">
            <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                dataSource={feedbackOptions}
                columns={columns}
                searchable
                scroll={{ x: "45vh" }}
                loading={isLoading}
                pagination={false}
                // pagination={{
                //   showSizeChanger: true,
                //   pageSize: pageSize,
                //   total: feedbackOptions?.total_counts,
                //   onShowSizeChange: onShowSizeChange,
                //   onChange: onPageChange,
                //   pageSizeOptions: ["10", "30", "50"],
                // }}
              />
            </Card>
          </TabPane>
        </Tabs>

        {/* {!loading && ( */}
        <>
          {/* <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col lg={12} md={12} sm={12} xs={12} className="total">
                  Total {reasonsList.total_counts}
                </Col>
              </Row>
            </Card> */}
        </>
        {/* )} */}
        {/* {isLoadingDriver && <Loader />} */}
      </div>
      <Modal
        title={ModalTitle}
        open={isModalOpen}
        onOk={form.submit}
        // closable={true}
        // maskClosable={false}
        onCancel={handleCancel}
        okText={setUpdateFlowFileRef.current == "" ? "Add" : "Update"}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            name="tag"
            label={"Rating Tag Title"}
            rules={[
              {
                required: true,
                message: "Please Enter Rating Tag Title",
                pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <Input type="text" placeholder="Enter Rating Tag Title" />
          </Form.Item>
          {/* Api in progress */}
          <Form.Item
            name="rating"
            label={"Rating"}
            rules={[
              {
                required: true,
                message: "Please Choose Rating",
                // pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <Select placeholder={"Choose Rating"}>
              {/* <Select.Option value={0} ><Rate value={0} disabled /></Select.Option> */}
              <Select.Option value={1}>
                <Rate value={1} disabled />
              </Select.Option>
              <Select.Option value={2}>
                <Rate value={2} disabled />
              </Select.Option>
              <Select.Option value={3}>
                <Rate value={3} disabled />
              </Select.Option>
              <Select.Option value={4}>
                <Rate value={4} disabled />
              </Select.Option>
              <Select.Option value={5}>
                <Rate value={5} disabled />
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="status"
            label={"Status"}
            rules={[
              {
                required: true,
                message: "Please Choose Status",
                // pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
          >
            <Radio.Group>
              <Radio value={"active"} key="active">
                Active
              </Radio>
              <Radio value={"deactive"} key="deactive">
                Deactive
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RatingTags;
