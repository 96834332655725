import actions from "./actions";

const { GET_PROFILE_REASONS, GET_DRIVER_REJECT_REASONS } = actions;

const initState = {
  profileDeleteReasonsData: {},
  driverRejectResons: {},
  // newCityData: {},
};

const profileDeleteReasonsReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_PROFILE_REASONS:
      return {
        ...state,
        profileDeleteReasonsData: data,
      };
    case GET_DRIVER_REJECT_REASONS:
      return {
        ...state,
        driverRejectResons: data,
      };

    default:
      return state;
  }
};
export default profileDeleteReasonsReducer;
