const actions = {
  GET_ABOUT_US_CONTENT: "GET_ABOUT_US_CONTENT",
  UPDATE_CONTENT: "UPDATE_CONTENT",

  getContent: (data) => {
    return {
      type: actions.GET_ABOUT_US_CONTENT,
      data,
    };
  },
  updateContent: (data) => {
    return {
      type: actions.UPDATE_CONTENT,
      data,
    };
  },
};

export default actions;
