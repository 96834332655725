import { ConfigProvider } from "antd";
import Cookies from "js-cookie";
import React, { useEffect, useState, createContext } from "react";
import { hot } from "react-hot-loader/root";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Navigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import config from "./config/config";
import { SocketProvider } from "./container/socket/socketContext";
import store from "./redux/store";
import Admin from "./routes/admin";
import FrontendRoutes from "./routes/auth";
import { getSettings } from "./redux/Settings/actionCreatore";
import "./static/css/style.css";
import { getItem } from "./utility/localStorageControl";
const { theme } = config;
const userDetails = getItem("user_details");
const queryClient = new QueryClient();
// import { createContext } from "react";
export const Context = createContext("Default Value");

export const useBeforeRender = (callback, deps) => {
  const [isRun, setIsRun] = useState(false);

  if (!isRun) {
    callback();
    setIsRun(true);
  }

  useEffect(() => () => setIsRun(false), deps);
};

const ProviderConfig = () => {
  const dispatch = useDispatch();
  const { rtl, isLoggedIn, topMenu, darkMode, userRole } = useSelector(
    (state) => {
      return {
        darkMode: state.ChangeLayoutMode.data,
        rtl: state.ChangeLayoutMode.rtlData,
        topMenu: state.ChangeLayoutMode.topMenu,
        isLoggedIn: state.auth.login,
        userRole: userDetails ? userDetails?.role : "",
      };
    }
  );

  const [path, setPath] = useState(window.location.pathname);
  const pathArray = window.location.pathname.split("/");
  const mainPath = pathArray[2];

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => (unmounted = true);
  }, [setPath]);

  useEffect(() => {
    if (userDetails == undefined) {
      Cookies.remove("logedIn");
    }
  }, []);
  useBeforeRender(() => {
    window.addEventListener("error", (e) => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr)
          resizeObserverErr.className = "hide-resize-observer";
        if (resizeObserverErrDiv)
          resizeObserverErrDiv.className = "hide-resize-observer";
      }
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      reteriveSettings();
    }
  }, [isLoggedIn]);

  const reteriveSettings = async () => {
    await dispatch(getSettings());
  };
  let settingData = {};
  settingData = useSelector((state) => state.setting.settingsData);
  return (
    <ConfigProvider direction={rtl ? "rtl" : "ltr"}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={`/`}>
          {/* {!isLoggedIn ? (
              <Routes>

              <Route path="/" element={ <Auth />} />
              </Routes>
            ) : (
            <ProtectedRoute path={`/${userRole}`} component={Admin} />
          )} */}
          {!isLoggedIn ? (
            <FrontendRoutes />
          ) : (
            // <ProtectedRoute path={`/${userRole}`} component={ManageCallCenter} />
            <Context.Provider
              value={settingData?.googleAPIkey ? settingData?.googleAPIkey : ""}
            >
              <Admin />
            </Context.Provider>
            // <Routes>
            //   <Route path={`/${userRole}/admin`} element={<ManageAdmin />} ></Route>

            // </Routes>
          )}
          {isLoggedIn &&
            (path === "/" ||
              path === `/${mainPath}`) &&
            (userRole === "" ? (
              <Navigate to={`/login`} />
            ) : (
              <Navigate to={`/${userRole}/`} />
            ))}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <SocketProvider>
          {/* <CleanupComponent /> */}
          <ProviderConfig />
        </SocketProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default hot(App);
