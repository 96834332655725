import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  FileOutlined,
  ExportOutlined,
  UserOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useStateRef from "react-usestateref";
import Loader from "../../loader/loader";
import {
  createDriverCenter,
  getDriverCenterList,
  removeDriverCenter,
  updateDriverCenter,
} from "../../../redux/deriverCenter/actionCreatore";
import { getCityData } from "../../../redux/Masters/City/actionCreatore";
import { checkAction } from "../../../utility/commonFunction";
import { getItem } from "../../../utility/localStorageControl";
import BreadCrumb from "../../BreadCrumb.js/BreadCrumb";
import ExportReports from "../../Export Reports/ExportReports";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { downloadReports } from "../../Export Reports/Reports";
import { ADMIN, SUPERADMIN } from "../../../config/dataService/dataService";
import {
  getAdminCaptainData,
  getAdminDataWithPagination,
} from "../../../redux/admin/actionCreatore";
import ReportModal from "../../Modal/ReportModal";
import VerifyOtpModal from "../../Modal/VerifyOtpModal";
import moment from "moment";

const ManageCallCenter = () => {
  const { t } = useTranslation();
  const [modalTitle, setModalTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateFlow, setUpdateFlow, setUpdateFlowFileRef] = useStateRef("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(50);
  const [pageNo, setPageNo] = useState(1);
  const [searchValue, setSearchValue] = useState(null);
  const navigate = useNavigate();
  const [showPasswordField, setshowPasswordField] = useState(true);
  const [search, setSearch] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const user = getItem("user_details");
  const [adminId, setAdminId] = useState(user?._id);
  const [selectedCity, setSelectedCity] = useState(
    user?.role != "super_admin" ? user?.city_id?._id : ""
  );
  const [sort, setSort] = useState("DSC");
  const [sortKey, setSortKey] = useState("login_time");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectCityInModal, setSelectCityInModal] = useState("");
  const [showReportModal, setShowReportModal] = useState(false);
  const [docType, setDocType] = useState("");
  const [docName, setDocName] = useState("Captains Report");
  const [showVerify, setShowVerify] = useState(false);
  const reportKeys = [
    "Name",
    "Mobile No.",
    "Email Address",
    "City",
    "Last Login",
    "Address",
    "Admin Name",
  ];
  useEffect(() => {
    // reteriveDriverCenterList();
    // reteriveCityList();
    window.scrollTo(0, 0);
  }, []);

  const { data, isLoading } = useQuery(
    "drivercenterlist",
    () => {
      if (user.role == ADMIN) {
        return reteriveAdminCaptainList();
      } else {
        return reteriveDriverCenterList();
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleSelectCityInModal = (value) => {
    setSelectCityInModal(value);
    form.setFieldsValue({
      admin_id: null,
    });
    getAdminList(value);
  };

  const { data: dataCity, isLoading: isLoadingCity } = useQuery(
    "cityList",
    () => {
      return reteriveCityList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  // const { data: dataAdmin, isLoading: isLoadingAdmin } = useQuery(
  //   "adminList",
  //   () => {
  //     return getAdminList();
  //   }
  // );

  const getAdminList = async (value) => {
    await dispatch(getAdminDataWithPagination(value));
  };
  const adminList = useSelector(
    (state) => state.admin.adminLstWithoutPagination
  );

  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedId("")
    form.resetFields();
    // setSelectDep(0)
    setSelectCityInModal("");
    setUpdateFlow("");
    setshowPasswordField(true);
    // handleSearch("");
  };
  const deleteDriverCenterFinal = async (id) => {
    setLoading(true);
    let remove = await dispatch(removeDriverCenter(id));
    if (remove) {
      reteriveDriverCenterList();
      setLoading(false);
    }
  };
  const editDriverCenter = (data) => {
    console.log(data);
    setModalTitle("Update Captain");
    setIsModalOpen(true);
    setUpdateFlow(data._id);
    setshowPasswordField(false);
    form.resetFields();
    form.setFieldsValue({
      city_id: data.city_id?._id,
      admin_id: data?.admin_id?.name,
      name: data.name,
      number: data?.number,
      address: data?.address,
      email: data?.email,
      countryCode: data?.countryCode,
      ifscCode: data?.ifscCode,
      accountNumber: data?.accountNumber,
    });
    setSelectCityInModal(data.city_id?._id);
  };

  const deleteDriverCenter = (value) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this captain?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteDriverCenterFinal(value);
      },
    });
  };

  const reteriveDriverCenterList = async () => {
    setLoading(true);

    const resp = await dispatch(
      getDriverCenterList(
        searchFilter,
        pageNo,
        pageSize,
        selectedCity,
        sortKey,
        sort,
        selectedTime
      )
    );
    if (resp) {
      setExportData(resp?.list);

      setLoading(false);
    }
  };
  // let driverCenterList = {};

  // user.role != ADMIN &&
  //   (driverCenterList = useSelector(
  //     (state) => state.captain.driverCenterListData
  //   ));

  const reteriveAdminCaptainList = async () => {
    setLoading(true);

    const resp = await dispatch(
      getAdminCaptainData(
        pageNo,
        pageSize,
        adminId,
        searchFilter,
        sort,
        sortKey,
        selectedTime
      )
    );
    if (resp) {
      setLoading(false);
    }
  };
  let driverCenterList = useSelector((state) => {
    if (user?.role == ADMIN) {
      return state.admin.adminCaptainList;
    } else {
      return state.captain.driverCenterListData;
    }
  });

  // user.role == ADMIN &&
  //   (driverCenterList = useSelector((state) => state.admin.adminCaptainList));

  const reteriveCityList = async () => {
    await dispatch(getCityData(""));
  };
  let cityList = useSelector((state) => state.city.cityData);
  cityList = cityList?.sort((a, b) => a.name?.localeCompare(b.name));

  const handleSubmit = async (values) => {
    setLoading(true);
    values.name = values?.name.trim();
    if (values.name.length < 4)
      return form.setFields([
        {
          name: "name",
          errors: ["Name must be at least 4 characters"],
        },
      ]);
    values.admin_id = adminId;
    values.city_id = selectCityInModal;
    delete values["confirmPassword"];
    console.log(values);
    if (values.password == "") {
      delete values["password"];
    }
    if (setUpdateFlowFileRef.current == "") {
      const resp = await dispatch(createDriverCenter(JSON.stringify(values)));
      if (resp) {
        setIsModalOpen(false);
        user.role == SUPERADMIN
          ? reteriveDriverCenterList()
          : reteriveAdminCaptainList();
        setLoading(false);
        setSearch(null);
        form.resetFields();
      }
    } else {
      delete values["ifscCode"];
      delete values["accountNumber"];

      const resp = await dispatch(
        updateDriverCenter(JSON.stringify(values), updateFlow)
      );
      if (resp) {
        setIsModalOpen(false);
        setUpdateFlow("");
        form.resetFields();
        user.role == SUPERADMIN
          ? reteriveDriverCenterList()
          : reteriveAdminCaptainList();
        setLoading(false);
        setSearch(null);
      }
    }
  };
  const handleChangeCity = async (value) => {
    console.log(value);
    await dispatch(getDriverCenterList(value));
  };
  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    await dispatch(
      getDriverCenterList(
        searchValue,
        page,
        pageSizes,
        selectedCity,
        sortKey,
        sort,
        selectedTime
      )
    );
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    await dispatch(
      getDriverCenterList(
        searchValue,
        current,
        pageSizes,
        selectedCity,
        sortKey,
        sort,
        selectedTime
      )
    );
  };
  const handleSearch = async (value) => {
    setSearch(value);
    await dispatch(getCityData(value));
  };

  const [exportData, setExportData] = useState(driverCenterList?.list);

  const modifiedExportData =
    exportData &&
    exportData.map((item, index) => ({
      "Sr. No.": index + 1 + (pageNo - 1) * pageSize,
      Name: item.name || "-",
      "Mobile No.": item?.number || "-",
      City: item?.city_id?.name || "-",
      Address: item?.address || "-",
      "Email Address": item?.email || "-",
      "Last Login": item?.login_time ? new Date(item.login_time) : "-",

      Address: item?.address,
      "Admin Name": item?.admin_id?.name ? item?.admin_id?.name : "-",
    }));

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setExportData(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    // getCheckboxProps: (record) => ({
    //   disabled: record.name === 'Disabled User',
    //   // Column configuration not to be checked
    //   name: record.name,
    // }),
  };

  const handleSearchFilter = async (value) => {
    setSearchFilter(value);
    if (user.role == ADMIN) {
      await dispatch(
        getAdminCaptainData(
          pageNo,
          pageSize,
          adminId,
          value,
          sort,
          sortKey,
          selectedTime
        )
      );
    } else {
      await dispatch(
        getDriverCenterList(
          value,
          pageNo,
          "",
          selectedCity,
          sortKey,
          sort,
          selectedTime
        )
      );
    }
  };

  const handleSelectedCity = async (value) => {
    setSelectedCity(value);
    if (search) {
      setSearch("");
      await dispatch(getCityData(""));
    }
    await dispatch(
      getDriverCenterList(
        searchFilter,
        pageNo,
        "",
        value,
        sortKey,
        sort,
        selectedTime
      )
    );
  };
  const handleSort = async (key) => {
    let sorting;
    setSortKey(key);
    if (sort === "ASC") {
      setSort("DSC");
      sorting = "DSC";
    } else {
      sorting = "ASC";
      setSort("ASC");
    }
    if (user.role == ADMIN) {
      await dispatch(
        getAdminCaptainData(
          pageNo,
          pageSize,
          adminId,
          searchFilter,
          sorting,
          key,
          selectedTime
        )
      );
    } else {
      await dispatch(
        getDriverCenterList(
          searchFilter,
          pageNo,
          "",
          selectedCity,
          key,
          sorting,
          selectedTime
        )
      );
    }
  };
  const handleSelectTime = async (value) => {
    let val;
    if (value) val = value.format("YYYY-MM-DD");
    else {
      val = "";
    }
    setSelectedTime(val);
    setPageNo(1);
    if (user.role == ADMIN) {
      await dispatch(
        getAdminCaptainData(
          pageNo,
          pageSize,
          adminId,
          searchFilter,
          sort,
          sortKey,
          val
        )
      );
    } else {
      await dispatch(
        getDriverCenterList(
          searchFilter,
          pageNo,
          "",
          selectedCity,
          sortKey,
          sort,
          val
        )
      );
    }
  };
  const columns = [
    {
      title: t("srNo"),
      align: "center",

      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: t("emailAddress"),
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: "City",
      align: "center",

      render: (data) => {
        return <span>{data?.city_id?.name}</span>;
      },
    },
    {
      title: t("phoneNo"),
      align: "center",

      render: (data) => {
        return <span>{data?.countryCode + " " + data?.number}</span>;
      },
    },
    // {
    //   title: "Service Charge",
    //   render: (data) => {
    //     return <span>{data?.CallService_charge}</span>;
    //   },
    // },
    // {
    //   title: "Commisson (₹)",
    //   // dataIndex: "commisson",
    //   key: "commisson",
    //   align: "center",
    //   render: (data) => {
    //     return <span>{data && data?.commission ? data?.commission : 0}</span>;
    //   },
    // },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      align: "center",
    },
    {
      title: "Admin Name",
      // dataIndex: "address",
      key: "adminName",
      align: "center",
      render: (data) => {
        return <span>{data?.admin_id?.name ? data?.admin_id?.name : "-"}</span>;
      },
    },
    {
      title: "Created Date",
      render: (data) => {
        const date = new Date(data?.created_at);
        return <span>{date.toLocaleDateString()}</span>;
      },
    },
    {
      title: (
        <div>
          Last Login{" "}
          <Button
            style={{ background: "none", border: "none" }}
            onClick={() => handleSort("login_time")}
          >
            <SwapOutlined rotate={90} style={{ color: "black" }} />
          </Button>
        </div>
      ),
      key: "lastLogin",
      render: (data, index1, index) => {
        // console.log(data.login_time,index1);
        const date = new Date();
        if (data.login_time) {
          const date2 = new Date(data.login_time);
          const difference = date.getTime() - date2.getTime();
          const differenceInMinutes = difference / (1000 * 60);
          const differenceInHours = difference / (1000 * 60 * 60);
          const differenceInDays = difference / (1000 * 60 * 60 * 24);

          if (differenceInMinutes < 60) {
            return (
              <span>
                {" "}
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInMinutes)} minutes ago
                </Tooltip>
              </span>
            );
          } else if (differenceInHours < 24) {
            return (
              <span>
                {" "}
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInHours)} hours ago
                </Tooltip>
              </span>
            );
          } else {
            return (
              <span>
                {" "}
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInDays)} days ago
                </Tooltip>
              </span>
            );
          }
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      title: t("action"),
      align: "center",

      render: (data) => {
        return (
          <div style={{ justifyContent: "space-around", display: "flex" }}>
            <Button
              onClick={() => {
                navigate(`drivers/${data._id}`);
              }}
              type="primary"
            >
              <UserOutlined />
              Drivers
            </Button>
            {checkAction("driverCenter", "edit") && (
              <Button
                onClick={() => editDriverCenter(data)}
                type="primary"
                style={{ margin: "0 4px 0px 4px" }}
              >
                <EditOutlined />
                Edit
              </Button>
            )}
            {/* {checkAction("driverCenter", "delete") && (
              <Button
                onClick={() => deleteDriverCenter(data._id)}
                style={{ cursor: "pointer", marginLeft: 3 }}
                type="primary"
                danger
                disabled
              >
                <DeleteOutlined />
                Delete
              </Button>
            )} */}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="common_layout content-container ">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className="heading"> Captain</h1>
            <BreadCrumb />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            {checkAction("driverCenter", "add") && (
              <Button
                style={{ float: "right" }}
                className="ADD"
                key="1"
                size="large"
                type="primary"
                // onClick={showModal}
                onClick={() => {
                  setIsModalOpen(true);
                  setModalTitle("Add New Captain");
                  handleSearch("");

                  if (user.role !== SUPERADMIN) {
                    form.setFieldsValue({
                      city_id: selectedCity,
                      admin_id: user._id,
                    });
                    setSelectCityInModal(selectedCity);
                  }
                }}
              >
                <PlusOutlined /> Add Captain
              </Button>
            )}
          </Col>
        </Row>
        <Divider />

        {isLoading & isLoadingCity ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <Card style={{ marginBottom: 10 }} className="shadowCardBox">
              <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
                <Col
                  xxl={5}
                  xl={5}
                  lg={5}
                  md={8}
                  sm={12}
                  xs={24}
                  className="total"
                >
                  Total {driverCenterList?.total_counts}
                </Col>

                {user?.role != SUPERADMIN && (
                  <Col xxl={9} xl={7} lg={7} md={16} sm={12} xs={24}></Col>
                )}

                <Col xxl={5} xl={5} lg={5} md={8} sm={12} xs={24}>
                  <Input
                    placeholder="Search By Name, Email Or Phone"
                    // enterButton="Search"
                    style={{
                      width: "100%",
                      // float: "right",
                      // marginLeft: "10px",
                      // float: "right",
                    }}
                    prefix={<SearchOutlined />}
                    onChange={(event) => handleSearchFilter(event.target.value)}
                  />
                </Col>

                {user?.role == SUPERADMIN && (
                  <Col xxl={5} xl={5} lg={5} md={8} sm={12} xs={24}>
                    <Select
                      placeholder="Choose city"
                      style={{
                        width: "100%",
                        maxHeight: "45px",
                        overflowY: "auto",
                        marginTop: "6px",
                      }}
                      showSearch
                      mode="multiple"
                      filterOption={false}
                      onSearch={(e) => handleSearch(e)}
                      allowClear
                      onChange={handleSelectedCity}
                    >
                      {/* {console.log("cityList", cityList)} */}
                      {cityList &&
                        cityList.map((data) => {
                          return (
                            <Select.Option
                              value={data._id}
                              key={data._id}
                              className="selectOptions"
                            >
                              {data.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Col>
                )}

                <Col xxl={5} xl={5} lg={5} md={10} sm={12} xs={24}>
                  <DatePicker
                    showToday={false}
                    style={{
                      width: "100%",
                      // marginLeft: "20px",
                      borderRadius: 0,
                      // float: "right",
                    }}
                    placeholder={"Last Login Date"}
                    onChange={(e) => handleSelectTime(e)}
                    format={"DD/MM/YYYY"}
                    disabledDate={(current) => current.isAfter(moment())}
                  />
                </Col>

                {user?.role == SUPERADMIN && (
                  <Col xxl={4} xl={5} lg={6} md={8} sm={12} xs={24}>
                    <Select
                      placeholder="Export Report"
                      style={{
                        width: "100%",
                        // marginLeft: "20px",

                        // float: "right",
                      }}
                      value={null}
                      onSelect={(value) => {
                        // setShowReportModal(true);
                        setShowVerify(true);
                        // deleteKeys(value);
                        setDocType(value);
                      }}
                    >
                      <Select.Option
                        value="csv"
                        key="csv"
                        className="selectOptions"
                      >
                        {" "}
                        <FileOutlined /> CSV
                      </Select.Option>
                      <Select.Option
                        value="excel"
                        key="excel"
                        className="selectOptions"
                      >
                        {" "}
                        <FileExcelOutlined /> Excel (XLSX)
                      </Select.Option>
                      <Select.Option
                        value="pdf"
                        key="pdf"
                        className="selectOptions"
                      >
                        {" "}
                        <FilePdfOutlined /> Pdf
                      </Select.Option>
                    </Select>
                  </Col>
                )}
              </Row>
            </Card>
            <Card className="shadowCardBox">
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                rowSelection={
                  user.role == SUPERADMIN && {
                    type: Checkbox,
                    ...rowSelection,
                  }
                }
                rowKey={"_id"}
                dataSource={driverCenterList?.list}
                // columns={columns}
                columns={
                  user.role == SUPERADMIN
                    ? columns
                    : columns.filter((key) => key.key != "adminName")
                }
                searchable
                scroll={{ x: "45vh" }}
                pagination={{
                  showSizeChanger: true,
                  pageSize: pageSize,
                  total: driverCenterList?.total_counts,
                  onShowSizeChange: onShowSizeChange,
                  onChange: onPageChange,
                  pageSizeOptions: ["50", "100", "500"],
                }}
              />
            </Card>
          </>
        )}
      </div>
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={form.submit}
        closable={true}
        // maskClosable={false}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          autoComplete="off"
        >
          {user.role == SUPERADMIN && (
            <>
              <Form.Item
                label="City"
                rules={[{ required: true, message: "Please Select City" }]}
                name="city_id"
              >
                <Select
                  placeholder="Choose city"
                  showSearch
                  onSearch={(e) => handleSearch(e)}
                  filterOption={false}
                  allowClear
                  onClear={() => handleSearch("")}
                  disabled={
                    user.role != SUPERADMIN || setUpdateFlowFileRef.current
                      ? true
                      : false
                  }
                  // disabled={setUpdateFlowFileRef.current ? true : false}
                  // onSelect={(value)=>setSelectCityInModal(value)}
                  onSelect={(value) => handleSelectCityInModal(value)}
                >
                  {cityList.length > 0 &&
                    cityList.map((data) => {
                      return (
                        <Select.Option value={data._id} key={data._id}>
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Admin"
                rules={[{ required: false, message: "Please Select Admin" }]}
                name="admin_id"
              >
                <Select
                  placeholder="Choose Admin"
                  showSearch
                  onSearch={(e) => handleSearch(e)}
                  filterOption={false}
                  allowClear
                  onClear={() => handleSearch("")}
                  disabled={
                    user.role != SUPERADMIN || setUpdateFlowFileRef.current
                      ? true
                      : false
                  }
                  onChange={(value) => setAdminId(value)}
                >
                  {selectCityInModal &&
                    adminList.length > 0 &&
                    adminList.map((data) => {
                      return (
                        <Select.Option value={data._id} key={data._id}>
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </>
          )}
          <Form.Item
            label={`Name`}
            rules={[
              {
                required: true,
                message: "Please Enter Name",
                pattern: new RegExp(/[^\s]+\s*$/i),
              },
              {
                min: 4,
              },
            ]}
            name="name"
          >
            <Input type="text" placeholder="Enter Name"></Input>
          </Form.Item>
          <Form.Item
            label={t("emailAddress")}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please Enter Email Address",
              },
            ]}
            name="email"
          >
            <Input type="text" placeholder="Enter Email Address"></Input>
          </Form.Item>
          {/* {showPasswordField && ( */}
          <>
            <Form.Item
              label={`Password`}
              rules={[
                {
                  required: updateFlow ? false : true,
                  message: "Please Enter Password",
                },
                {
                  pattern: new RegExp(
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
                  ),
                  message: `Must contain at least one number , one uppercase, lowercase letter, and at least 8 or more characters`,
                },
              ]}
              name="password"
            >
              <Input.Password
                onKeyPress={(event) => {
                  if (event.key == " ") {
                    event.preventDefault();
                  }
                }}
                type="password"
                placeholder="Enter Password"
              ></Input.Password>
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: updateFlow ? false : true,
                  message: "Please enter confirm password",
                },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                onKeyPress={(event) => {
                  if (event.key == " ") {
                    event.preventDefault();
                  }
                }}
                placeholder="Confirm Password"
              />
            </Form.Item>
          </>
          {/* )} */}
          <p style={{ fontWeight: "500" }}>
            <span style={{ color: "red" }}>* </span>
            {t("phoneNo")}
          </p>
          <Row gutter={20}>
            <Col xxl={4}>
              <Form.Item
                name="countryCode"
                rules={[
                  {
                    // required: true,
                    // message: "Please Enter Country Code",
                    // max: 10,
                  },
                ]}
                initialValue={"+91"}
              >
                <Input
                  placeholder=""
                  value={"+91"}
                  maxLength={3}
                  disabled
                ></Input>
              </Form.Item>
            </Col>
            <Col xxl={20}>
              <Form.Item
                name="number"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Number",
                    // max: 10,
                  },
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Please Enter Only Numbers",
                  },
                ]}
              >
                <Input
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Enter Number"
                  maxLength={10}
                ></Input>
              </Form.Item>
            </Col>
          </Row>
          {/* <Form.Item
            label={`Service Charge`}
            // rules={[{ required: true, message: "Please Enter Phone Number" }]}
            name="charge"
          >
            <Input type="number" placeholder="Enter Service Charge"></Input>
          </Form.Item> */}
          <Form.Item
            label={`Address`}
            rules={[
              {
                required: true,
                message: "Please Enter Address",
                pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
            name="address"
          >
            <Input type="text" placeholder="Enter Address"></Input>
          </Form.Item>

          <Col xxl={24}>
            <Form.Item
              label={`Bank Account Number`}
              name="accountNumber"
              rules={[
                {
                  required: updateFlow ? false : true,
                  message: "Please Enter Bank Account Number",
                  // max: 10,
                },
                {
                  pattern: new RegExp(/^[0-9]{9,18}$/),
                  message: "Please Enter Valid Account Number",
                },
              ]}
            >
              <Input
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                disabled={updateFlow}
                placeholder="Enter Bank Account Number"
                // maxLength={10}
              ></Input>
            </Form.Item>
          </Col>
          <Col xxl={24}>
            <Form.Item
              label={`IFSC Code`}
              name="ifscCode"
              rules={[
                {
                  required: updateFlow ? false : true,
                  message: "Please Enter IFSC Code",
                  // max: 10,
                },
                {
                  pattern: new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/),

                  message: "Please Enter Valid Ifsc Code",
                },
              ]}
            >
              <Input
                autoCapitalize
                placeholder="Enter IFSC Code"
                disabled={updateFlow}
                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                // maxLength={10}
              ></Input>
            </Form.Item>
          </Col>
        </Form>
      </Modal>
      <ReportModal
        showReportModal={showReportModal}
        setShowReportModal={setShowReportModal}
        modifiedExportData={modifiedExportData}
        docType={docType}
        reportKeys={reportKeys}
        docName={docName}
      />
      <VerifyOtpModal
        showVerify={showVerify}
        setShowVerify={setShowVerify}
        setShowReportModal={setShowReportModal}
        type={"Captain"}
      />
    </>
  );
};

export default ManageCallCenter;
