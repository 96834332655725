import React from "react";
import io from "socket.io-client";
import { getItem } from "../../utility/localStorageControl";
const socketUrl = require("../../config/dataService/dataService")
  .SOCKET_URL_ENDPOINT;

const SocketContext = React.createContext();

const SocketProvider = ({ children }) => {
  const socket = io(socketUrl, {
    extraHeaders: {
      userid: getItem("user_details")?._id,
    },
  });
  socket.on("connection")
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
