import actions from "./actions";

const { GET_RIDER_LIST } = actions;

const initState = {
  riderListData: {},
};

const riderReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_RIDER_LIST:
      return {
        ...state,
        riderListData: data,
      };
    default:
      return state;
  }
};
export default riderReducer;
