/* eslint-disable no-shadow */
import { Badge, Button, Card, Col, Dropdown, Layout, Menu, Row } from "antd";
import FeatherIcon from "feather-icons-react";
import propTypes from "prop-types";
import React, { Component, useContext, useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MenueItems from "./MenueItems";
import { Div, TopMenuSearch } from "./style";
// import HeaderSearch from '../components/header-search/header-search';
import { useNavigate } from "react-router-dom";
import AuthInfo from "../components/utilities/auth-info/info";
import {
  changeLayoutMode,
  changeMenuMode,
  changeRtlMode,
} from "../redux/themeLayout/actionCreator";
import Logo from "../static/img/GautoLogo.png";
import { getItem } from "../utility/localStorageControl";
// const { darkTheme } = require('../config/theme/themeVariables');
import { CAPTAIN } from "../config/dataService/dataService";
import { SocketContext } from "../container/socket/socketContext";
import handsJoin from "../static/img/notification counts/handshake.gif";
import bell from "../static/img/notification counts/notification.gif";
import driverReverificationRequest from "../static/img/notification counts/reverification.gif";
import cab from "../static/img/notification counts/taxi.gif";
import driverRequest from "../static/img/notification counts/user.gif";

const { Header, Footer, Sider, Content } = Layout;
const user = getItem("user_details");

const Notification = () => {
  const navigate = useNavigate();
  const socket = useContext(SocketContext);
  const [counts, setCounts] = useState({});

  const items = [
    {
      key: "driverNewRequestMenu",
      label: (
        <Card
          onClick={() => navigate(`/${user?.role}/manageDriver/driverRequests`)}
        >
          <span style={{ fontWeight: "bold", cursor: "pointer" }}>
            Driver Request{" "}
            <Badge
              count={counts?.driverRequestCount}
              // color="blue"
              color={counts?.driverRequestCount > 0 && "blue"}
              overflowCount={counts?.driverRequestCount}
            ></Badge>
          </span>
        </Card>
      ),
    },
    {
      key: "bookingsMenu",
      label: (
        <Card onClick={() => navigate(`/${user?.role}/manageBooking`)}>
          <span style={{ fontWeight: "bold", cursor: "pointer" }}>
            New Bookings{" "}
            <Badge
              count={counts?.ridesCount}
              color={counts?.ridesCount > 0 && "blue"}
              overflowCount={counts?.ridesCount}
            ></Badge>
          </span>
        </Card>
      ),
    },
    {
      key: "driverReverificationRequestMenu",
      label: (
        <Card
          onClick={() =>
            navigate(`/${user?.role}/manageDriver/driverReverficationRequest`)
          }
        >
          <span style={{ fontWeight: "bold", cursor: "pointer" }}>
            Driver Reverification Request{" "}
            <Badge
              count={counts?.driverReverificationRequestCount}
              color={counts?.driverReverificationRequestCount > 0 && "blue"}
              overflowCount={counts?.driverReverificationRequestCount}
            ></Badge>
          </span>
        </Card>
      ),
    },
    {
      key: "beOurPartnerRequestMenu",
      label: (
        <Card onClick={() => navigate(`/${user?.role}/BeOurAffiliatePartner`)}>
          <span style={{ fontWeight: "bold", cursor: "pointer" }}>
            Be our Affiliate Partner Request{" "}
            <Badge
              count={counts?.beOurAffiliatePartnerCount}
              color={counts?.beOurAffiliatePartnerCount > 0 && "blue"}
              overflowCount={counts?.beOurAffiliatePartnerCount}
            ></Badge>
          </span>
        </Card>
      ),
    },
  ];

  useEffect(() => {
    if (socket) {
      socket.emit("getUnreadRecordCount", { userId: user?._id });

      socket.on("unreadRecordCount", (data) => {
        // console.log("data",data);
        if (data?.userId === user?._id) {
          setCounts(data);
        }
        // setLocation(data.location.coordinates);

        // setLoading(false)
      });
    }
  }, [socket]);
  return (
    <>
      <div
        style={{
          float: "right",
          paddingTop: "24px",
          marginLeft: "33px",
          cursor: "pointer",
        }}
      >
        <Dropdown
          menu={{
            items: items.filter(
              (item) => item.key == "driverReverificationRequestMenu"
            ),
          }}
        >
          <Badge count={counts?.driverReverificationRequestCount}>
            <img
              src={driverReverificationRequest}
              width={35}
              alt="driverReverification"
            />
          </Badge>
        </Dropdown>
      </div>
      <div
        style={{
          float: "right",
          paddingTop: "24px",
          marginLeft: "33px",
          cursor: "pointer",
        }}
      >
        <Dropdown
          menu={{
            items: items.filter((item) => item.key == "driverNewRequestMenu"),
          }}
          trigger={["hover"]}
        >
          <Badge count={counts?.driverRequestCount}>
            <img src={driverRequest} width={40} alt={"driverRequest"} />
          </Badge>
        </Dropdown>
      </div>

      <div
        style={{
          float: "right",
          paddingTop: "22px",
          marginLeft: "33px",
          cursor: "pointer",
        }}
      >
        <Dropdown
          menu={{
            items: items.filter((item) => item.key == "bookingsMenu"),
          }}
          trigger={["hover"]}
        >
          <Badge count={counts?.ridesCount}>
            <img src={cab} width={40} alt={"booking"} />
          </Badge>
        </Dropdown>
      </div>

      {user?.role !== CAPTAIN && (
        <div
          style={{
            float: "right",
            paddingTop: "23px",
            marginLeft: "33px",
            cursor: "pointer",
          }}
        >
          <Dropdown
            menu={{
              items: items.filter(
                (item) => item.key == "beOurPartnerRequestMenu"
              ),
            }}
            trigger={["hover"]}
            style={{ height: "20px" }}
          >
            <Badge count={counts?.beOurAffiliatePartnerCount}>
              <img src={handsJoin} width={38} alt={"beourpartner"} />
            </Badge>
          </Dropdown>
        </div>
      )}
    </>
  );
};

const NotificationForMobile = () => {
  const navigate = useNavigate();
  const socket = useContext(SocketContext);
  const [counts, setCounts] = useState({});
  useEffect(() => {
    if (socket) {
      socket.emit("getUnreadRecordCount", { userId: user?._id });

      socket.on("unreadRecordCount", (data) => {
        // console.log("data",data);
        if (data?.userId === user?._id) {
          setCounts(data);
        }
        // setLocation(data.location.coordinates);

        // setLoading(false)
      });
    }
  }, [socket]);
  const items = [
    {
      key: 1,
      label: (
        <div
          onClick={() => navigate(`/${user?.role}/manageDriver/driverRequests`)}
        >
          <span>
            Driver Request{" "}
            <Badge
              count={counts?.driverRequestCount}
              color={counts?.driverRequestCount > 0 && "blue"}
              overflowCount={counts?.driverRequestCount}
            ></Badge>
          </span>
        </div>
      ),
    },
    {
      key: 2,
      label: (
        <div onClick={() => navigate(`/${user?.role}/manageBooking`)}>
          <span>
            New Bookings{" "}
            <Badge
              count={counts?.ridesCount}
              color={counts?.ridesCount > 0 && "blue"}
              overflowCount={counts?.ridesCount}
            ></Badge>
          </span>
        </div>
      ),
    },
    {
      key: 3,
      label: (
        <div
          onClick={() =>
            navigate(`/${user?.role}/manageDriver/driverReverficationRequest`)
          }
        >
          <span>
            Driver Reverification Request{" "}
            <Badge
              count={counts?.driverReverificationRequestCount}
              color={counts?.driverReverificationRequestCount > 0 && "blue"}
              overflowCount={counts?.driverReverificationRequestCount}
            ></Badge>
          </span>
        </div>
      ),
    },
    user?.role !== CAPTAIN && {
      key: 4,
      label: (
        <div onClick={() => navigate(`/${user?.role}/BeOurAffiliatePartner`)}>
          <span>
            Be our Affiliate Partner Request{" "}
            <Badge
              count={counts?.beOurAffiliatePartnerCount}
              color={counts?.beOurAffiliatePartnerCount > 0 && "blue"}
              overflowCount={counts?.beOurAffiliatePartnerCount}
            ></Badge>
          </span>
        </div>
      ),
    },
  ];
  return (
    <div
      style={{
        float: "right",
        paddingTop: "20px",
        marginLeft: "33px",
        cursor: "pointer",
      }}
    >
      <Dropdown
        menu={{ items }}
        placement="bottomLeft"
        arrow={{ pointAtCenter: true }}
      >
        <Button style={{ border: "none" }}>
          <img src={bell} width={35} alt={"notification"} />
        </Button>
      </Dropdown>
    </div>
  );
};

const ThemeLayout = (WrappedComponent) => {
  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: false,
        hide: true,
        searchHide: true,
        activeSearch: false,
      };
      this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener("resize", this.updateDimensions);
      this.updateDimensions();
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
      this.setState({
        collapsed: window.innerWidth <= 1200 && true,
      });
    }

    render() {
      const { collapsed, hide, searchHide, activeSearch } = this.state;
      const {
        ChangeLayoutMode,
        rtl,
        changeRtl,
        changeLayout,
        topMenu,
        changeMenuMode,
      } = this.props;

      const left = !rtl ? "left" : "right";
      const darkMode = ChangeLayoutMode;
      const toggleCollapsed = () => {
        this.setState({
          collapsed: !collapsed,
        });
      };

      const toggleCollapsedMobile = () => {
        if (window.innerWidth <= 990) {
          this.setState({
            collapsed: !collapsed,
          });
        }
      };

      // const onShowHide = () => {
      //   this.setState({
      //     hide: !hide,
      //     // searchHide: true,
      //   });
      // };

      const toggleSearch = () => {
        this.setState({
          activeSearch: !activeSearch,
        });
      };

      const handleSearchHide = (e) => {
        e.preventDefault();
        this.setState({
          searchHide: !searchHide,
          hide: true,
        });
      };

      const footerStyle = {
        padding: "20px 30px 18px",
        color: "rgba(0, 0, 0, 0.65)",
        fontSize: "14px",
        background: "rgba(255, 255, 255, .90)",
        width: "100%",
        boxShadow: "0 -5px 10px rgba(146,153,184, 0.05)",
      };

      const SideBarStyle = {
        margin: "63px 0 0 0",
        padding: "15px 15px 55px 15px",
        overflowY: "auto",
        height: "100vh",
        position: "fixed",
        [left]: 0,
        zIndex: 998,
      };

      const renderView = ({ style, ...props }) => {
        const customStyle = {
          marginRight: "auto",
          [rtl ? "marginLeft" : "marginRight"]: "-17px",
        };
        return <div {...props} style={{ ...style, ...customStyle }} />;
      };

      const renderThumbVertical = ({ style, ...props }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? "#ffffff16" : "#F1F2F6",
          [left]: "2px",
        };
        return <div style={{ ...style, ...thumbStyle }} props={props} />;
      };

      const renderTrackVertical = () => {
        const thumbStyle = {
          position: "absolute",
          width: "6px",
          transition: "opacity 200ms ease 0s",
          opacity: 0,
          [rtl ? "left" : "right"]: "2px",
          bottom: "2px",
          top: "2px",
          borderRadius: "3px",
        };
        return <div style={thumbStyle} />;
      };

      const renderThumbHorizontal = ({ style, ...props }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? "#ffffff16" : "#F1F2F6",
        };
        return <div style={{ ...style, ...thumbStyle }} props={props} />;
      };

      const onRtlChange = () => {
        const html = document.querySelector("html");
        html.setAttribute("dir", "rtl");
        changeRtl(true);
      };

      const onLtrChange = () => {
        const html = document.querySelector("html");
        html.setAttribute("dir", "ltr");
        changeRtl(false);
      };

      const modeChangeDark = () => {
        changeLayout(true);
      };

      const modeChangeLight = () => {
        changeLayout(false);
      };

      const modeChangeTopNav = () => {
        changeMenuMode(true);
      };

      const modeChangeSideNav = () => {
        changeMenuMode(false);
      };

      const onEventChange = {
        onRtlChange,
        onLtrChange,
        modeChangeDark,
        modeChangeLight,
        modeChangeTopNav,
        modeChangeSideNav,
      };

      return (
        <Div darkMode={darkMode}>
          <Layout className="layout">
            <Header
              style={{
                position: "fixed",
                width: "100%",
                top: 0,
                height: "80px",
                [!rtl ? "left" : "right"]: 0,
              }}
            >
              <Row>
                <Col
                  lg={!topMenu ? 4 : 3}
                  sm={6}
                  xs={12}
                  className="align-center-v navbar-brand"
                >
                  {!topMenu || window.innerWidth <= 991 ? (
                    <Button
                      className="togglebtn"
                      type="link"
                      onClick={toggleCollapsed}
                    >
                      <img
                        src={require(`../static/img/icon/${
                          collapsed ? "right.svg" : "left.svg"
                        }`)}
                        alt="menu"
                      />
                    </Button>
                  ) : null}
                  <Link
                    style={{
                      color: "#4f5d73",
                      fontWeight: "bold",
                      display: "flex",
                    }}
                    className={
                      topMenu && window.innerWidth > 991
                        ? "striking-logo top-menu"
                        : "striking-logo"
                    }
                    to={`/${user?.role}`}
                  >
                    <div
                      style={{
                        background: "#15a467",

                        width: "150px",

                        height: "65px",
                        padding: "inherit",
                        borderRadius: "8px",
                      }}
                    >
                      <img
                        src={Logo}
                        alt="logo"
                        height="28px"
                        style={{
                          marginRight: 5,
                          objectFit: "cover",
                          width: "145px",
                          height: "-webkit-fill-available",
                          maxWidth: "145px",
                        }}
                      />
                      {/* <strong style={{ fontSize: 20, width: "100vw" }}>
                      {" "}
                      G-Auto
                    </strong> */}
                    </div>
                  </Link>
                </Col>

                <Col
                  xxl={18}
                  xl={18}
                  lg={!topMenu ? 17 : 15}
                  md={15}
                  sm={0}
                  xs={0}
                >
                  <Notification />
                </Col>

                <Col xxl={0} xl={0} lg={!topMenu ? 0 : 0} md={0} sm={0} xs={7}>
                  <NotificationForMobile />
                </Col>

                <Col xxl={2} xl={2} lg={3} md={3} sm={0} xs={4}>
                  {topMenu && window.innerWidth > 991 ? (
                    <TopMenuSearch>
                      <div className="top-right-wrap d-flex">
                        <Link
                          className={`${
                            activeSearch
                              ? "search-toggle active"
                              : "search-toggle"
                          }`}
                          onClick={() => {
                            toggleSearch();
                          }}
                          to="#"
                        >
                          <FeatherIcon icon="search" />
                          <FeatherIcon icon="x" />
                        </Link>
                        <div
                          className={`${
                            activeSearch
                              ? "topMenu-search-form show"
                              : "topMenu-search-form"
                          }`}
                        >
                          <form action="">
                            <span className="search-icon">
                              <FeatherIcon icon="search" />
                            </span>
                            <input type="text" name="search" />
                          </form>
                        </div>
                        <AuthInfo />
                      </div>
                    </TopMenuSearch>
                  ) : (
                    <>
                      <AuthInfo />
                    </>
                  )}
                </Col>

                <Col md={0} sm={14} xs={0}>
                  <>
                    <div className="mobile-action">
                      {/* <Link
                        className="btn-search"
                        onClick={handleSearchHide}
                        to="#"
                      >
                        {searchHide ? (
                          <FeatherIcon icon="search" />
                        ) : (
                          <FeatherIcon icon="x" />
                        )}
                      </Link> */}
                      {/* <div style={{ float: "right", paddingTop: "10px" }}>
                      </div> */}
                      <Notification />
                    </div>
                  </>
                </Col>
                <Col md={0} sm={4} xs={0}>
                  <>
                    <div className="mobile-action">
                      {/* <Link
                        className="btn-search"
                        onClick={handleSearchHide}
                        to="#"
                      >
                        {searchHide ? (
                          <FeatherIcon icon="search" />
                        ) : (
                          <FeatherIcon icon="x" />
                        )}
                      </Link> */}
                      <AuthInfo />
                    </div>
                  </>
                </Col>
              </Row>
            </Header>
            {/* <div className="header-more">
              <Row>
                <Col md={0} sm={24} xs={24}>
                  <div className="small-screen-headerRight">
                    <SmallScreenSearch hide={searchHide} darkMode={darkMode}>
                      <HeaderSearch rtl={rtl} />
                    </SmallScreenSearch>
                    <SmallScreenAuthInfo hide={hide} darkMode={darkMode}>
                      <AuthInfo rtl={rtl} />
                    </SmallScreenAuthInfo>
                  </div>
                </Col>
              </Row>
            </div> */}
            <Layout>
              {!topMenu || window.innerWidth <= 991 ? (
                <Sider
                  width={280}
                  style={SideBarStyle}
                  collapsed={collapsed}
                  theme={!darkMode ? "light" : "dark"}
                >
                  <Scrollbars
                    className="custom-scrollbar"
                    autoHide
                    autoHideTimeout={500}
                    autoHideDuration={200}
                    renderThumbHorizontal={renderThumbHorizontal}
                    renderThumbVertical={renderThumbVertical}
                    renderView={renderView}
                    renderTrackVertical={renderTrackVertical}
                  >
                    <p className="sidebar-nav-title"></p>
                    <MenueItems
                      topMenu={topMenu}
                      rtl={rtl}
                      toggleCollapsed={toggleCollapsedMobile}
                      darkMode={darkMode}
                      events={onEventChange}
                    />
                  </Scrollbars>
                </Sider>
              ) : null}
              <Layout className="atbd-main-layout">
                <Content>
                  <WrappedComponent {...this.props} />
                  {/* <Footer className="admin-footer" style={footerStyle}>
                    <Row>
                      <Col md={12} xs={24}>
                        <span
                          style={{ color: "#5F63F2" }}
                          className="admin-footer__copyright"
                        >
                      
                        </span>
                      </Col>
                    </Row>
                  </Footer> */}
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </Div>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      ChangeLayoutMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  };

  const mapStateToDispatch = (dispatch) => {
    return {
      changeRtl: (rtl) => dispatch(changeRtlMode(rtl)),
      changeLayout: (show) => dispatch(changeLayoutMode(show)),
      changeMenuMode: (show) => dispatch(changeMenuMode(show)),
    };
  };

  LayoutComponent.propTypes = {
    ChangeLayoutMode: propTypes.bool,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
    changeRtl: propTypes.func,
    changeLayout: propTypes.func,
    changeMenuMode: propTypes.func,
  };

  return connect(mapStateToProps, mapStateToDispatch)(LayoutComponent);
};
export default ThemeLayout;
