import actions from "./actions";

const { GET_ROLE, CREATE_ROLE, DELETE_ROLE, UPDATE_ROLE, SET_ROLE } = actions;

const initState = {
  setRole: {},
  role: {},
  newRole: {},
};

const roleManagementReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case GET_ROLE:
      return {
        ...state,
        role: data,
      };
    case SET_ROLE:
      return {
        ...state,
        setRole: data,
      };
    case CREATE_ROLE:
      return {
        ...state,
        newRole: data,
      };
    case DELETE_ROLE:
      return {
        ...state,
        role: data,
      };
    case UPDATE_ROLE:
      return {
        ...state,
        newRole: data,
      };
    default:
      return state;
  }
};
export default roleManagementReducer;
